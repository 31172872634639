import {
  Backdrop,
  Breadcrumbs,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory, useLocation } from "react-router";
import { Page, PrimaryButton } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  myinput: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "11px",
      paddingLeft: "12px",
      paddingRight: "12px",
      paddingBottom: "11px",
    },
  },
  backdrop1: {
    zIndex: 100,
    color: "#fff",
  },
  mydateinput: {
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px",
      paddingBottom: "0px",
      height: "44px",
    },
  },
  inputNum: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px 6px 6px 0px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderLeft: "1px solid #d1d5dc",
      borderRight: "1px solid #d1d5dc",
      borderTop: "1px solid #d1d5dc",
      borderBottom: "1px solid #d1d5dc",
    },
    "& .MuiOutlinedInput-input": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
    "& input.Mui-disabled": {
      color: "black",
    },
  },
  activeChip: {
    backgroundColor: "#8DE5AB",
    color: "#34774C",
    fontSize: "14px",
    fontWeight: "500",
  },
  nonActiveChip: {
    backgroundColor: "#D1D5DC",
    color: "#364052",
    fontSize: "14px",
    fontWeight: "500",
  },
  activeChipIndicator: {
    color: "#34774C",
    fontSize: "12px",
  },
  nonActiveChipIndicator: {
    color: "#364052",
    fontSize: "12px",
  },
  font24: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  font16: {
    fontSize: "16px",
  },
  textStyle: {
    marginTop: "5px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  cardParent: {
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
  },
  btnBack: {
    marginLeft: "10px",
    backgroundColor: "#111827",
    borderRadius: "6px",
    width: "84px",
    height: "40px",
    color: "#FFFFFF",
    textTransform: "none",
  },
  textBack: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "500",
    fontStyle: "normal",
    textTransform: "none",
  },
  mt32: {
    marginTop: "32px",
  },
}));

const ViewExRate = ({ userLogin }) => {
  const currentUrl = window.location.href;
  const partsUrl = currentUrl.split("/");
  const customerId = partsUrl[partsUrl.length - 1];
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const currencyName = queryParams.get("currency");
  const [client, setClient] = useState([]);
  const submiting = false;
  const status = true;

  const getDateTimeFormat = date => moment(date).format("DD MMMM YYYY hh:mm A");
  const getPreciseDateTimeFormat = date =>
    moment(date).format("DD MMMM YYYY hh:mm:ss A");

  const getClientList = async () => {
    let token = localStorage.getItem("token");

    const urlClientList = `${hardBaseUrl}/exchange-rate/detail/${customerId}`;

    await axios
      .get(urlClientList, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setClient(res?.data?.data);
      })
      .catch(() => {});
  };

  const handleBack = async () => {
    if (userLogin.role.type === "publisher") {
      history.push({
        pathname: "/admin/konfigurasi/exchange-rate",
        state: { selectedCurrency: history.location.state.selectedCurrency },
      });
    } else {
      history.push({
        pathname: "/admin/parameter/exchange-rate",
        state: { selectedCurrency: history.location.state.selectedCurrency },
      });
    }
  };

  useEffect(() => {
    getClientList();
  }, []);

  return (
    <Page className={classes.root} title="View Currency Data">
      <Backdrop className={classes.backdrop1} open={submiting}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography className={classes.font24}>
                View Currency Data
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>
              <Typography className={classes.breadCrumbs}>
                Exchanges Rate
              </Typography>
              <Typography className={classes.breadCrumbs}>
                View Currency Data
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <span className={classes.font16}>
                <b>View Currency Data</b>
              </span>
            </ThemeProvider>
          </div>
        </Grid>

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <p className={classes.textStyle}>
                Features for view detail of currency data
              </p>
            </ThemeProvider>
          </div>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card id="card-of-success" className={classes.cardParent}>
              <CardContent>
                {status === true ? (
                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Currency
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              {history.location.state.currencyName ||
                                currencyName}
                            </Typography>
                          </ThemeProvider>
                        </div>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Start Date
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              {getDateTimeFormat(client?.start_date)}
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div
                          style={{
                            marginTop: "12px",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              End Date
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              {client?.end_date === "0001-01-01T00:00:00Z"
                                ? "-"
                                : getDateTimeFormat(client?.end_date)}
                            </Typography>
                          </ThemeProvider>
                        </div>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Exchanges Rate
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              <NumberFormat
                                value={client?.value}
                                displayType={"text"}
                                thousandSeparator="."
                                decimalSeparator=","
                                thousandsGroupStyle="thousand"
                                prefix={"Rp "}
                                suffix=",-"
                              />
                            </Typography>
                          </ThemeProvider>
                        </div>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Created At
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              {getPreciseDateTimeFormat(client?.created_at)}
                            </Typography>
                          </ThemeProvider>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ) : null}

                <Grid container justifyContent="space-between"></Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Grid container justifyContent="flex-end">
              <div className={classes.mt32}>
                <PrimaryButton label="Back" onClick={handleBack} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default ViewExRate;
