import { UserOutlined } from "@ant-design/icons";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Facebook, Instagram, Twitter, YouTube } from "@material-ui/icons";
import { Button, Col, Input, Row, Tooltip } from "antd";
import Axios from "axios";
import { InnoImage } from "inno-ui";
import { useEffect, useState } from "react";
import "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import "../assets/css/layout.css";
import companyLogo from "../assets/img/company_logo.png";
import logoLight from "../assets/img/newMpisLogo.webp";
import supported from "../assets/img/supported-img.png";
import {
  hardBaseUrl,
  hardSubDomain,
  hardTypeWeb,
} from "../services/urlConstant";
import MobileNavbar from "./MobileNavbar";

import searchImage from "../assets/img/search.png";
import CARIS from "../assets/img/newCarisLogo.webp";

const GenerateButtonTop = () => {
  return (
    <>
      <Button
        style={{
          backgroundColor: "black",
          color: "white",
          border: "none",
          width: 106,
          height: 44,
          borderRadius: 5,
          marginLeft: 20,
        }}
        size="large"
      >
        Login
      </Button>
    </>
  );
};
export default function Layout(params) {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { children } = params;
  const [search, setSearch] = useState(false);
  const [dataSearch, setDataSearch] = useState({
    artists: [],
    composers: [],
    songs: [],
  });
  const [input, setInput] = useState("");
  const [afterCallLogo, setAfterCallLogo] = useState(false);
  const [imageChoosen, setImageChoosen] = useState("");
  useEffect(() => {
    checkLogo();
  }, []);
  const handleSearch = async val => {
    setInput(val);
    try {
      const response = await Axios.get(
        hardBaseUrl +
        `/search/song/suggestion?search=${val}&subdomain=${hardSubDomain}`
      );
      setDataSearch(response.data.data);
    } catch (error) {
      new Error(error);
    }
  };
  const handleRedirect = (a, b) => {
    setSearch(false);
    history.push({
      pathname: "/detail-lagu",
      search: `?search=${b}?field=${a}`,
      state: {
        search: b,
        field: a,
      },
    });
    setInput("");
    window.location.reload();
  };
  const checkLogo = async () => {
    try {
      let newUrl =
        hardBaseUrl +
        "/landing/publisher/detail?subdomain=" +
        hardSubDomain +
        "&type=" +
        hardTypeWeb;
      let result = await Axios.get(newUrl, {
        headers: {},
      });
      setImageChoosen(result.data.data);
      setAfterCallLogo(true);
    } catch (error) {
      new Error(error);
    }
  };
  const getLogo = item => {
    if (hardTypeWeb === "caris") {
      if (hardSubDomain === "nadaku") {
        return (
          <InnoImage
            src={item}
            alt={"Logo"}
            styleImage={{ padding: "auto", width: "100%", height: "100%" }}
            styleImageContainer={{
              maxHeight: document.getElementById("container-navbar")
                .offsetHeight,
            }}
            fill={true}
          />
        );
      } else if (hardSubDomain === "aquarius") {
        return (
          <InnoImage
            src={item}
            alt={"Logo"}
            styleImage={{
              padding: "auto",
              maxWidth: "174px",
              width: "100%",
              height: "auto",
            }}
            styleImageContainer={{
              maxHeight: document.getElementById("container-navbar")
                .offsetHeight,
            }}
            fill={true}
          />
        );
      } else if (hardSubDomain === null) {
        return (
          <InnoImage
            src={item}
            alt={"Logo"}
            styleImage={{ padding: "auto", width: "100%", height: "100%" }}
            styleImageContainer={{
              maxHeight: document.getElementById("container-navbar")
                .offsetHeight,
              maxWidth: "265px",
            }}
            fill={true}
          />
        );
      } else {
        return (
          <InnoImage
            src={item}
            alt={"Logo"}
            styleImage={{ padding: "auto", width: "100%", height: "100%" }}
            styleImageContainer={{
              maxHeight: document.getElementById("container-navbar")
                .offsetHeight,
            }}
            fill={true}
          />
        );
      }
    } else if (hardTypeWeb === "mpis") {
      return <InnoImage src={companyLogo} alt={"logo"} />;
    }
  };
  const getLogoMobile = item => {
    if (hardTypeWeb === "caris") {
      if (hardSubDomain === "nadaku") {
        return <img src={item} alt="logo" width="76px" />;
      } else if (hardSubDomain === "aquarius") {
        return <img src={item} alt="logo" className="logo" />;
      } else if (hardSubDomain === null) {
        return (
          <img
            src={item}
            alt="logo"
            className="logo"
            style={{
              width: "100px",
            }}
          />
        );
      } else {
        return <img src={item} alt="logo" />;
      }
    } else if (hardTypeWeb === "mpis") {
      return <img src={companyLogo} alt="logo" className="logo" />;
    }
  };
  const getLogoBottom = () => {
    if (hardTypeWeb === "caris") {
      return (
        <div
          style={{ backgroundColor: "white", width: "209px", padding: "10px" }}
        >
          <img
            src={CARIS}
            alt=""
            style={{
              height: "32px",
            }}
          />
        </div>
      );
    } else {
      return (
        <>
          <div>
            <img src={logoLight} alt="" />
          </div>
        </>
      );
    }
  };
  const getTextBottom = () => {
    return "CARIS";
  };
  const getNavbarSearch = () => {
    return (
      <Tooltip
        onClick={() => {
          setSearch(!search);
          setInput("");
        }}
      >
        <Button style={{ border: "none", marginLeft: 10 }}>
          <img src={searchImage} alt="" />
        </Button>
      </Tooltip>
    );
  };
  return (
    <div className={isMobile ? null : "fill-window"} id="fill-window">
      {isMobile ? (
        <MobileNavbar logo={getLogoMobile(imageChoosen)} />
      ) : (
        <div className="navbar">
          <Row
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
            className="container"
            id="container-navbar"
          >
            {afterCallLogo ? (
              <>
                <Col span={6} className="company-container">
                  <Link to="/home" style={{ paddingRight: "20px" }}>
                    {getLogo(imageChoosen)}
                  </Link>
                </Col>
              </>
            ) : (
              <>{""}</>
            )}
            <Col span={10} style={{ display: "flex", alignItems: "center" }}>
              <div className="menu">
                <Link to="/home" className="cl-dark2">
                  Home
                </Link>
              </div>
              <div className="menu">
                <Link to="/berita" className="cl-dark2">
                  News
                </Link>
              </div>
              <div className="menu">
                <Link to="/edukasi" className="cl-dark2">
                  Education
                </Link>
              </div>
              <div className="menu">
                <Link to="/tentang-kami" className="cl-dark2">
                  About Us
                </Link>
              </div>
            </Col>
            <Col
              span={8}
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {getNavbarSearch()}
                <Link
                  to={"/login"}
                  state={{
                    noFooter: true,
                  }}
                >
                  <GenerateButtonTop />
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      )}
      {isMobile ? null : (
        <div
          className="search-bar"
          style={{ marginTop: search ? "90px" : "0px" }}
        >
          <div
            className="container content"
            style={{
              height: "100%",
              marginLeft: "170px",
              marginRight: "180px",
            }}
          >
            <Input
              className="input-search"
              style={{ height: "100%", borderRadius: "6px", color: "black" }}
              color="red"
              onChange={e => handleSearch(e.target.value)}
              value={input}
              prefix={
                <Button type="text">
                  <img src={searchImage} alt="" />
                </Button>
              }
              size="large"
              placeholder="Search for a song, singer or composer"
            />
            {input !== "" ? (
              <hr style={{ marginTop: "20px", marginBottom: "20px" }} />
            ) : (
              ""
            )}
            <div
              className="search-wrapper"
              style={{
                height: input !== "" ? "400px" : "0px",
              }}
            >
              {input !== "" && search && (
                <>
                  <div style={{ color: "white" }}>
                    <h4 style={{ color: "white", marginTop: "20px" }}>Song</h4>
                    {dataSearch.songs.map((res, index) => {
                      return (
                        <div
                          className="result-search"
                          onClick={() => handleRedirect("songs", res)}
                          key={`${index}`}
                        >
                          <i
                            className="fa fa-music"
                            style={{ marginRight: "10px" }}
                          />{" "}
                          {res}
                        </div>
                      );
                    })}
                    <h4 style={{ color: "white", marginTop: "20px" }}>
                      Composer
                    </h4>
                    {dataSearch.composers.map((res, index) => {
                      return (
                        <div
                          className="result-search"
                          onClick={() => handleRedirect("composers", res)}
                          key={`${index}`}
                        >
                          <UserOutlined
                            style={{ marginRight: "10px", color: "white" }}
                          />{" "}
                          {res}
                        </div>
                      );
                    })}
                    <h4 style={{ color: "white", marginTop: "20px" }}>
                      Artist
                    </h4>
                    {dataSearch.artists.map((res, index) => {
                      return (
                        <div
                          className="result-search"
                          onClick={() => handleRedirect("artist", res)}
                          key={`${index}`}
                        >
                          <UserOutlined
                            style={{ marginRight: "10px", color: "white" }}
                          />{" "}
                          {res}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="children" id="children">
        {children}
      </div>
      {isMobile ? (
        <div
          className="footer"
          style={{
            height: "200px",
            backgroundColor: "#111827",
          }}
        >
          <div className=" content">
            <div style={{ paddingTop: "10px", paddingLeft: "10px" }}>
              {getLogoBottom()}
              <div
                style={{
                  marginTop: "10px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Contact Support
              </div>{" "}
              <div style={{ color: "white" }}>developer@asaba.co.id</div>
              <div
                style={{ display: "flex", marginTop: "10px" }}
                className="sosmed"
              >
                <Link to="">
                  <Twitter style={{ color: "white" }} />
                </Link>
                <Link to="">
                  <YouTube style={{ color: "white", marginLeft: "10px" }} />
                </Link>
                <Link to="">
                  <Facebook style={{ color: "white", marginLeft: "10px" }} />
                </Link>
                <Link to="">
                  <Instagram style={{ color: "white", marginLeft: "10px" }} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="footer"
          style={{
            height: "auto",
          }}
        >
          <div className="container content">
            <Row>
              <Col span={18}>
                {getLogoBottom()}
                <div style={{ display: "flex", marginTop: "20px" }}>
                  <Link to="/home" style={{ color: "white" }}>
                    Home
                  </Link>
                  <Link
                    to="/berita"
                    style={{ color: "white", marginLeft: "20px" }}
                  >
                    News
                  </Link>
                  <Link
                    to="/edukasi"
                    style={{ color: "white", marginLeft: "20px" }}
                  >
                    Education
                  </Link>
                  <Link
                    to="/tentang-kami"
                    style={{ color: "white", marginLeft: "20px" }}
                  >
                    About Us
                  </Link>
                  <Link
                    to="/privacy-policy"
                    style={{ color: "white", marginLeft: "20px" }}
                  >
                    Privacy Policy
                  </Link>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Contact Support
                </div>{" "}
                <div>developer@asaba.co.id</div>
              </Col>
              <Col span={6} style={{ textAlign: "right" }}>
                <p>Supported by:</p>
                <img src={supported} alt="" />
              </Col>
              <hr
                style={{
                  borderColor: "white",
                  width: "100%",
                  marginTop: "30px",
                  marginBottom: "10px",
                }}
              />
            </Row>
            <Row>
              <Col span={18}>
                <div>{`Copyright © 2021 ${getTextBottom()}, Inc.`}</div>
              </Col>
              <Col span={6}>
                <div
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  className="sosmed"
                >
                  <Link to="">
                    <Twitter style={{ color: "white" }} />
                  </Link>
                  <Link to="">
                    <YouTube style={{ color: "white", marginLeft: "10px" }} />
                  </Link>
                  <Link to="">
                    <Facebook style={{ color: "white", marginLeft: "10px" }} />
                  </Link>
                  <Link to="">
                    <Instagram style={{ color: "white", marginLeft: "10px" }} />
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
}
