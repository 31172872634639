import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogEditUserPencipta = ({
  open,
  onClose,
  onSubmit,
  handleChangeSelectRole,
  handleChange,
  role,
  name,
  email,
  phone,
  roleItems,
  composer,
  handleChangeSelectPencipta,
  penciptaItems,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <form onSubmit={onSubmit}>
          <DialogTitle id="responsive-dialog-title">
            {"Edit User Composer"}
          </DialogTitle>

          <DialogContent>
            <DialogContentText>User Name</DialogContentText>
            <div>
              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <OutlinedInput
                  id="outlined-adornment-amount"
                  onChange={handleChange}
                  name="name"
                  type="text"
                  fullWidth={false}
                  value={name}
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogContent>
            <DialogContentText>Composer</DialogContentText>
            <div>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth={true}
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  shrink={false}
                >
                  {composer !== null ? null : "Composer"}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={handleChangeSelectPencipta}
                  autoWidth={true}
                  fullWidth={true}
                  defaultValue=""
                  value={composer}
                >
                  <MenuItem value={null}>Composer</MenuItem>
                  {penciptaItems.map(item => {
                    return (
                      <MenuItem key={item.composer_id} value={item.composer_id}>
                        {item.sure_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </DialogContent>
          <DialogContent>
            <DialogContentText>Role</DialogContentText>
            <div>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth={true}
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  shrink={false}
                >
                  {role !== null ? null : "Role"}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={handleChangeSelectRole}
                  autoWidth={true}
                  fullWidth={true}
                  value={role}
                  defaultValue=""
                >
                  <MenuItem value={null}>Role</MenuItem>
                  {roleItems.map(item => {
                    return (
                      <MenuItem key={item.role_id} value={item.role_id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </DialogContent>

          <DialogContent>
            <DialogContentText>Phone Number</DialogContentText>
            <div>
              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <OutlinedInput
                  id="outlined-adornment-amount"
                  onChange={handleChange}
                  name="phone"
                  type="number"
                  fullWidth={true}
                  value={phone}
                />
              </FormControl>
            </div>
          </DialogContent>

          <DialogContent>
            <DialogContentText>Email</DialogContentText>
            <div>
              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <OutlinedInput
                  id="outlined-adornment-amount"
                  onChange={handleChange}
                  name="email"
                  type="text"
                  fullWidth={true}
                  value={email}
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={onClose}
              variant="outlined"
              style={{
                textTransform: "none",
                backgroundColor: "black",
                color: "white",
              }}
            >
              <ThemeProvider theme={fontInter}>
                <Typography
                  style={{
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  Cancel
                </Typography>
              </ThemeProvider>
            </Button>
            <Button
              type="submit"
              autoFocus
              variant="outlined"
              style={{
                textTransform: "none",
                backgroundColor: "black",
                color: "white",
                marginRight: "16px",
              }}
            >
              <ThemeProvider theme={fontInter}>
                <Typography
                  style={{
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  Save
                </Typography>
              </ThemeProvider>
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default DialogEditUserPencipta;
