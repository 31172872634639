import {
  Card,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import NotifSign from "../../assets/img/notif-sign.svg";
import { hardBaseUrl } from "../../services/urlConstant";
import gradients from "../../theme/gradients";

const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: "24px",
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {
    width: "200px",
  },
  menuItem: {
    width: "200px",
  },
  listItem: {
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
    borderBottom: "1px solid #e1e1e1",
    cursor: "default",
  },
  avatarBlue: {
    backgroundImage: gradients.blue,
  },
  avatarGreen: {
    backgroundImage: gradients.green,
  },
  avatarOrange: {
    backgroundImage: gradients.orange,
  },
  avatarIndigo: {
    backgroundImage: gradients.indigo,
  },
}));

const NotificationPage = () => {
  const classes = useStyles();
  const [selectedFilterValue, setSelectedFilterValue] = useState(1);
  const [notificationList, setNotificationList] = useState([]);

  const handleChangeSelectFIlterValue = e => {
    setSelectedFilterValue(e.target.value);
  };

  const getNotificationList = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/notification`;
    try {
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          setNotificationList(res.data.data);
        });
    } catch (error) {
      new Error(error);
    }
  };

  useEffect(() => {
    getNotificationList();
  }, []);
  return (
    <div className={classes.root}>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Notification
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <FormControl
              size="small"
              variant="outlined"
              className={classes.formControl}
              fullWidth={true}
            >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                onChange={handleChangeSelectFIlterValue}
                autoWidth={true}
                value={selectedFilterValue}
              >
                <MenuItem value={0} className={classes.menuItem}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1} className={classes.menuItem}>
                  Latest
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />
        {notificationList < 1 ? (
          <Card
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <Typography component="span" variant="h6">
              There&apos;s nothing here...
            </Typography>
          </Card>
        ) : (
          <Card>
            <List disablePadding>
              {notificationList.map(notification => (
                <ListItem
                  className={classes.listItem}
                  component={RouterLink}
                  key={notification.notification_id}
                  to="#"
                >
                  <img
                    src={NotifSign}
                    alt="notif sign"
                    style={{
                      width: "20px",
                      marginRight: "10px",
                      marginTop: "-60px",
                    }}
                  />

                  <Grid container>
                    { }

                    <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                      <Grid container>
                        <Grid item xs={9} md={9} sm={9} lg={11} xl={9}>
                          <Typography component="span" variant="subtitle1">
                            {notification.title}{" "}
                            <span>
                              {notification.is_new ? (
                                <Chip
                                  label="NEW"
                                  size="small"
                                  style={{
                                    backgroundColor: "#FCF3F2",
                                    color: "#A63124",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    borderRadius: "11px",
                                    border: "1px solid #A63124",
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={3} md={3} sm={3} lg={1} xl={3}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Typography component="span" variant="body2">
                              {moment(notification.created_at).fromNow()}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                          <Typography component="span" variant="body2">
                            {notification.content}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
          </Card>
        )}
      </Container>
    </div>
  );
};

export default NotificationPage;
