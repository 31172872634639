export const topMenuButtonCaris = [
  {
    id: 0,
    title: "Personalization",
    link: "/admin/konfigurasi/personalisasi",
    selected: true,
  },
  {
    id: 1,
    title: "Payment",
    link: "/admin/konfigurasi/pembayaran",
    selected: false,
  },
  {
    id: 2,
    title: "Original Publisher",
    link: "/admin/konfigurasi/original-publisher",
    selected: false,
  },
  {
    id: 3,
    title: "Activity",
    link: "/admin/konfigurasi/aktifitas",
    selected: false,
  },
  {
    id: 4,
    title: "Invoice",
    link: "/admin/konfigurasi/invoice",
    selected: false,
  },
  {
    id: 5,
    title: "Reminder",
    link: "/admin/konfigurasi/contract-reminder",
    selected: false,
  },
  {
    id: 6,
    title: "Tax",
    link: "/admin/konfigurasi/tax",
    selected: false,
  },
  {
    id: 7,
    title: "Exchanges Rate",
    link: "/admin/konfigurasi/exchange-rate",
    selected: false,
  },
];

export const topMenuButtonMPIS = role => [
  ...(role === "society" || role === "association"
    ? [
        {
          id: 0,
          title: "Activity",
          link: "/admin/konfigurasi/aktifitas",
          selected: true,
        },
      ]
    : [
        {
          id: 0,
          title: "Personalization",
          link: "/admin/konfigurasi/personalisasi",
          selected: true,
        },
        {
          id: 1,
          title: "Payment",
          link: "/admin/konfigurasi/pembayaran",
          selected: false,
        },
        {
          id: 2,
          title: "Original Publisher",
          link: "/admin/konfigurasi/original-publisher",
          selected: false,
        },
        {
          id: 3,
          title: "Activity",
          link: "/admin/konfigurasi/aktifitas",
          selected: false,
        },
        {
          id: 4,
          title: "Sub Reporting",
          link: "/admin/konfigurasi/sub-reporting",
          selected: false,
        },
        {
          id: 5,
          title: "Sub Reporting Approval",
          link: "/admin/konfigurasi/sub-reporting-approval",
          selected: false,
        },
      ]),
];
