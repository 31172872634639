import {
  Backdrop,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import { TreeItem, TreeView } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Swal from "sweetalert2";
import { Page } from "components";
import { hardBaseUrl } from "../../../../../services/urlConstant";
import { useParams } from "react-router";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: ["Helvetica Neue"].join(","),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  tree: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  formTitle: {
    fontFamily: ["Helvetica Neue"].join(","),
    marginBottom: 5,
  },
  formControl: { marginBottom: 10 },
  inputFields: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingLeft: "14px",
      height: "44px",
    },
  },
  pageTitle: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
    textTransform: "capitalize",
  },
  breadcrumbs: {
    marginRight: "16px",
    padding: "12px",
    textTransform: "capitalize",
  },
  title: {
    fontSize: "18px",
    fontWeight: 700,
  },
  cardContainer: {
    border: "1px solid #9AA2B1",
    borderRadius: "8px",
    padding: "16px",
    marginTop: "16px",
    marginRight: "15px",
  },
  btnSubmit: {
    textTransform: "none",
    backgroundColor: "black",
    color: "white",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
    marginTop: 15,
  },
  expandIcon: {
    color: "white",
    backgroundColor: "black",
    borderRadius: 5,
    marginLeft: 10,
    height: 20,
    width: 20,
  },
  flexContainer: {
    display: "flex",
  },
  flexCenterContainer: {
    display: "flex",
    alignItems: "center",
  },
  dragIconsParent: {
    marginRight: 10,
    padding: "6px 0 7px",
  },
}));

function FormRole(props) {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: "Bearer " + token,
  };
  const { role_id, role_type, type, selected_app } = useParams();
  const typeRole = props?.role;
  const pageTitle = props?.title;
  const listApplication = ["MPIS", "CARIS"];
  const [loadingPage, setLoadingPage] = useState(false);
  const [payload, setPayload] = useState({
    role_name: "",
    role_type: role_type,
    application: "",
    menus: [],
  });

  const toTitleCase = str => str.replace(/\b\w/g, char => char.toUpperCase());

  const modalError = content =>
    Swal.fire({
      title: "Oops...",
      icon: "error",
      text: content,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });

  const DragIcons = () => (
    <svg
      width="6"
      height="18"
      viewBox="0 0 10 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="2" r="2" fill="#D1D5DC" />
      <circle cx="8" cy="2" r="2" fill="#D1D5DC" />
      <circle cx="2" cy="8" r="2" fill="#D1D5DC" />
      <circle cx="8" cy="8" r="2" fill="#D1D5DC" />
      <circle cx="2" cy="14" r="2" fill="#D1D5DC" />
      <circle cx="8" cy="14" r="2" fill="#D1D5DC" />
      <circle cx="2" cy="20" r="2" fill="#D1D5DC" />
      <circle cx="8" cy="20" r="2" fill="#D1D5DC" />
    </svg>
  );
  const handleChangePayload = (key, value) => {
    setPayload({ ...payload, [key]: value });
  };

  const handleSubmit = async () => {
    const hasActiveChildren = payload?.menus?.some(permission =>
      permission.children?.some(child => child?.is_active)
    );
    let modifiedPayload = {
      role_name: payload.role_name,
      role_type: payload.role_type,
      application_menus: [
        {
          menus: payload?.menus,
          application: payload?.application.toLocaleLowerCase(),
        },
      ],
    };
    if (type === "edit") {
      modifiedPayload = {
        role_id: Number(role_id),
        role_name: payload.role_name,
        role_type: payload.role_type,
        application_menus: [
          {
            menus: payload?.menus,
            application: payload?.application.toLocaleLowerCase(),
          },
        ],
      };
    }

    if (payload.nameOfRole === "") {
      modalError("User role can't be empty.");
    } else if (!hasActiveChildren) {
      modalError("Access can't be empty.");
    } else {
      setLoadingPage(true);
      try {
        props?.handleSubmitData(modifiedPayload);
        setLoadingPage(false);
      } catch (error) {
        setLoadingPage(false);
      }
    }
  };

  const handleParentMenuTextChange = (parentIndex, newText) => {
    setPayload(prevPayload => {
      const updatedMenus = [...prevPayload.menus];
      updatedMenus[parentIndex] = {
        ...updatedMenus[parentIndex],
        menu_text: newText,
      };
      return { ...prevPayload, menus: updatedMenus };
    });
  };

  const handleChildMenuTextChange = (parentIndex, childIndex, newText) => {
    setPayload(prevPayload => {
      const updatedMenus = [...prevPayload.menus];
      const updatedChildren = [...updatedMenus[parentIndex].children];
      updatedChildren[childIndex] = {
        ...updatedChildren[childIndex],
        menu_text: newText,
      };
      updatedMenus[parentIndex] = {
        ...updatedMenus[parentIndex],
        children: updatedChildren,
      };
      return { ...prevPayload, menus: updatedMenus };
    });
  };

  const handleChildCheckboxChange = (permission, child) => {
    const updatedPermissions = payload?.menus?.map(p => {
      if (p.menu_text === permission.menu_text) {
        const updatedChildren = p.children.map(c => {
          if (c.menu_text === child.menu_text) {
            return {
              ...c,
              is_active: !c.is_active,
            };
          }
          return c;
        });
        return {
          ...p,
          children: updatedChildren,
          is_active: updatedChildren.every(c => c.is_active),
        };
      }
      return p;
    });
    handleChangePayload("menus", updatedPermissions);
  };

  const handleParentDragEnd = result => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;
    const isParent = draggableId.startsWith("draggable-parent");
    const isChild = draggableId.startsWith("draggable-child");

    if (isParent) {
      const newPermissions = Array.from(payload?.menus);
      const movedPermission = newPermissions.splice(source.index, 1)[0];
      newPermissions.splice(destination.index, 0, movedPermission);
      newPermissions.forEach((menu, index) => {
        menu.order = index + 1;
      });
      handleChangePayload("menus", newPermissions);
    } else if (isChild) {
      const parentIndex = parseInt(source.droppableId.split("-")[2]);
      const newPermissions = [...payload?.menus];
      const updatedParentPermission = { ...newPermissions[parentIndex] };
      updatedParentPermission.children = updatedParentPermission.children || [];
      const movedChild = updatedParentPermission.children.splice(
        source.index,
        1
      )[0];

      updatedParentPermission.children.splice(destination.index, 0, movedChild);
      updatedParentPermission.children.forEach((child, index) => {
        if (child) {
          child.order = index + 1;
        }
      });
      newPermissions[parentIndex] = updatedParentPermission;
      handleChangePayload("menus", newPermissions);
    }
  };

  const renderPermission = (permission, parentIndex) => {
    const parentKey = permission.id !== 0 ? permission.id : permission.order;
    return (
      <Draggable
        key={`draggable-parent-${parentKey}`}
        draggableId={`draggable-parent-${parentKey}`}
        index={parentIndex}
        type="PARENT"
      >
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className={classes?.flexContainer}>
              <div className={classes?.dragIconsParent}>
                <DragIcons />
              </div>
              <TreeView
                defaultCollapseIcon={<IndeterminateCheckBoxIcon />}
                defaultExpandIcon={<AddBox />}
              >
                <TreeItem
                  nodeId={`tree-parent-${parentKey}`}
                  label={
                    <TextField
                      value={permission.menu_text}
                      onChange={e =>
                        handleParentMenuTextChange(parentIndex, e.target.value)
                      }
                    />
                  }
                >
                  {permission.children?.length > 1 ? (
                    <Droppable
                      droppableId={`droppable-child-${parentIndex}`}
                      type="CHILD"
                      direction="vertical"
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {permission.children &&
                            permission.children.map((child, childIndex) =>
                              renderChild(
                                child,
                                childIndex,
                                permission,
                                parentIndex
                              )
                            )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  ) : (
                    <>
                      <Checkbox
                        checked={permission.children[0].is_active}
                        onChange={() =>
                          handleChildCheckboxChange(
                            permission,
                            permission.children[0]
                          )
                        }
                        color="primary"
                        size="small"
                      />
                      <TextField
                        value={permission.children[0].menu_text}
                        onChange={e =>
                          handleChildMenuTextChange(
                            parentIndex,
                            0,
                            e.target.value
                          )
                        }
                      />
                    </>
                  )}
                </TreeItem>
              </TreeView>
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  const renderChild = (child, childIndex, parentPermission, parentIndex) => {
    if (!child) {
      return null;
    }

    const parentKey =
      parentPermission.id !== 0 ? parentPermission.id : parentPermission.order;
    const childrenKey = child.id !== 0 ? child.id : child.order;
    return (
      <Draggable
        key={`draggable-child-${parentKey}-${childrenKey}`}
        draggableId={`draggable-child-${parentKey}-${childrenKey}`}
        index={childIndex}
        type="CHILD"
      >
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={classes?.flexCenterContainer}
          >
            <DragIcons />
            <Checkbox
              checked={child.is_active}
              onChange={() =>
                handleChildCheckboxChange(parentPermission, child)
              }
              color="primary"
              size="small"
            />
            <TextField
              value={child.menu_text}
              onChange={e =>
                handleChildMenuTextChange(
                  parentIndex,
                  childIndex,
                  e.target.value
                )
              }
            />
          </div>
        )}
      </Draggable>
    );
  };

  const getDropDownAppList = async () => {
    const url = `${hardBaseUrl}/role-menu`;
    const { role_type } = payload;
    let params = {
      role_type,
    };
    if (role_id) {
      params = {
        role_id: role_id,
        role_type,
      };
    }
    const options = {
      headers,
      params,
    };

    const response = await axios.get(url, options);
    const { application_menus } = response?.data?.data;
    if (type === "edit") {
      return setPayload({
        ...payload,
        application: selected_app.toUpperCase(),
      });
    }
    if (application_menus.length === 1) {
      setPayload({
        ...payload,
        application: application_menus[0].application.toUpperCase(),
      });
    }
  };

  const getAccessList = async () => {
    setLoadingPage(true);
    try {
      let listMenus = null;
      const url = `${hardBaseUrl}/role-menu`;
      const { role_type, application } = payload;
      let params = {
        role_type,
      };
      if (role_id) {
        params = {
          role_id: role_id,
          role_type,
        };
      }
      const options = {
        headers,
        params,
      };
      const response = await axios.get(url, options);
      const { role_name, application_menus } = response?.data?.data;
      application_menus.map(value => {
        if (value.application.toUpperCase() === application) {
          value.menus.forEach((menu, index) => {
            menu.order = index + 1;
            if (menu.children && menu.children.length > 0) {
              menu.children.forEach((child, childIndex) => {
                child.order = childIndex + 1;
              });
            }
          });
          listMenus = value.menus;
        }
      });
      setPayload({
        ...payload,
        role_name,
        menus: listMenus,
      });
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getAccessList();
  }, [payload.application]);

  useEffect(() => {
    getDropDownAppList();
  }, []);

  return (
    <Page
      className={classes.root}
      title={`${toTitleCase(pageTitle)} ${toTitleCase(typeRole)} Role`}
    >
      <Backdrop className={classes.backdrop} open={loadingPage}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography className={classes?.pageTitle}>
              {`${pageTitle} ${typeRole} Role`}
            </Typography>
          </Grid>
          <Breadcrumbs aria-label="breadcrumb" className={classes?.breadcrumbs}>
            <Typography color="textPrimary">Configuration</Typography>
            <Typography color="textPrimary">
              <a href="/admin/konfigurasi/role-user">{typeRole} Role</a>
            </Typography>
            <Typography color="textPrimary">
              {`${pageTitle} ${typeRole} Role`}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sm={12} lg={6} xl={6}>
            <Typography component="span" className={classes?.title}>
              User
            </Typography>
            <div className={classes?.cardContainer}>
              <Typography className={classes.formTitle}>
                Role Username
              </Typography>
              <FormControl
                fullWidth
                className={classes.formControl}
                variant="outlined"
              >
                <OutlinedInput
                  value={payload?.role_name}
                  className={classes.inputFields}
                  onChange={event =>
                    handleChangePayload("role_name", event?.target?.value)
                  }
                  fullWidth
                />
              </FormControl>
              <Typography className={classes.formTitle}>
                Aplikasi Tujuan
              </Typography>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  size="small"
                  className={classes.select}
                  value={payload?.application.toUpperCase()}
                  onChange={event =>
                    handleChangePayload("application", event?.target?.value)
                  }
                  select
                  variant="outlined"
                  placeholder="Pilih Aplikasi Tujuan"
                  disabled={role_type !== "publisher"}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: value =>
                      value ? value : "Pilih Aplikasi Tujuan",
                  }}
                >
                  {listApplication?.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <Button
              variant="contained"
              onClick={handleSubmit}
              className={classes?.btnSubmit}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={12} md={6} sm={12} lg={6} xl={6}>
            <Typography component="span" className={classes?.title}>
              Access Rights
            </Typography>
            <div className={classes?.cardContainer}>
              <DragDropContext onDragEnd={handleParentDragEnd}>
                <Droppable droppableId="droppable-parent" direction="vertical">
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {payload?.menus?.map((permission, parentIndex) =>
                        renderPermission(permission, parentIndex)
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
export default FormRole;
