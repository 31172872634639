import {
  Backdrop,
  Breadcrumbs,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Box,
  TextField,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  DatePicker,
  FormLabel,
  ModalError,
  Page,
  PrimaryButton,
  SecondaryButton,
} from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import {
  FormLayout,
  SelectionsLayout,
  SingleFormLayout,
  SongDialog,
  SwitchFormLayout,
  TableForm,
  TableLayout,
} from "./ContractFormLayout";
import SupportingDocumentForm from "./SupportingDocumentForm";
import { getTotalPage } from "lib";
import moment from "moment-timezone";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  card: {
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
    marginTop: 20,
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  pageTitle: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  subTitle: {
    margin: "5px 0 14px",
    color: "#8F8F8F",
    fontSize: "14px",
  },
  title: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  tambahLagu: {
    backgroundColor: "black",
    color: "white",
    width: "32px",
    height: "29px",
    borderRadius: "6px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  textTambahLagu: {
    marginLeft: "10px",
    marginTop: "5px",
    fontSize: "14px",
    color: "#687083",
  },
  btnDialog: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    textDecoration: "none",
  },
  btnSubmit: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    height: "40px",
    borderRadius: "6px",
    fontSize: "14px",
    fontWeight: 400,
    alignSelf: "flex-end",
    lineHeight: "20px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
    marginTop: "10px",
    marginBottom: "10px",
  },
  rootSwitch: {
    marginLeft: "10px",
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",
    marginRight: "8px",
    position: "relative",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
  },
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#E4E7EB",
    borderRadius: "25px",
    border: "0px solid #606060",
    height: "auto",
    opacity: 1,
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
    margin: "7px 10px 0 0",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  btnPeriod: {
    padding: "10px 28px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "black",
    },
    backgroundColor: "#FFFFFF",
    marginRight: "10px",
    borderColor: "black",
    border: "1px solid #9AA2B1",
    textTransform: "none",
    color: "black",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    textDecoration: "none",
  },
  btnPeriodActive: {
    padding: "10px 28px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
    backgroundColor: "black",
    marginRight: "10px",
    borderColor: "#FFFFFF",
    border: "1px solid #9AA2B1",
    textTransform: "none",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    textDecoration: "none",
  },
  required: { color: "red" },
  formContainer: {
    marginTop: "-5px",
    marginBottom: "10px",
  },
  paperAddSong: {
    padding: "10px 24px",
    backgroundColor: "#F9FAFB",
    cursor: "pointer",
  },
  emptyData: {
    fontWeight: 400,
    color: "#687083",
    fontSize: "14px",
  },
  tableChip: {
    marginRight: 5,
  },
  sectionContainer: {
    marginTop: 20,
  },
  containerSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  tableSongContainer: {
    maxHeight: 250,
  },
  containerFlexRow: {
    display: "flex",
  },
  selectContainer: {
    maxWidth: 300,
    marginTop: 5,
  },
  checkbox: {
    marginLeft: 10,
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#111827",
    },
  },
  uploadIcon: { color: "grey", fontSize: "40px" },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 150,
  },
  singleFileContainer: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: "6px",
    border: "1px solid #E5E5E5",
    marginBottom: 10,
  },
  singleFileIcon: {
    color: "#9AA2B1",
    width: "13.33px",
    height: "15px",
    marginTop: "3px",
  },
  singleFileCaptions: {
    fontSize: "14px",
    fontWeight: 400,
    marginLeft: "5px",
  },
  singleFileRemoveIcon: {
    color: "#364052",
    width: "12px",
    height: "12px",
    marginTop: "3px",
    cursor: "pointer",
  },
  tableFormContainer: {
    marginTop: 10,
  },
  counterTableFormContainer: {
    marginTop: "-10px",
  },
  currecnyPrefix: {
    width: "48px",
    height: "37px",
    marginRight: "-2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "6px 0px 0px 6px",
    backgroundColor: "#F9FAFB",
    border: " 1px solid #D1D5DC",
    color: "#9AA2B",
    borderRight: "none",
  },
  tableFormAlignCenter: {
    marginTop: -15,
  },
}));
function ContractForm(props) {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: "Bearer " + token,
  };
  const preload = props?.preload;
  const preloadContractInformation = preload?.contract;
  const preloadSongs = preload?.songs;
  const preloadComposers = preload?.composers;
  const preloadAgents = preload?.agents;
  const isActiveContract = preloadContractInformation?.is_active_flag;
  const isPreloadApproved =
    preloadContractInformation?.approval_status === "approved";
  const isEditMode = Boolean(props?.contractId);

  const ComposerSongList = composers => {
    const noComposer =
      composers === null ||
      composers === undefined ||
      composers === "" ||
      composers?.length === 0;
    let sortedComposer = composers?.slice(0, 3);
    let restComposer = composers?.slice(3, composers?.length);
    return !noComposer ? (
      <Box>
        {sortedComposer?.map((songComposer, index) => (
          <Chip
            key={index}
            label={songComposer?.composer?.sure_name}
            className={useStyles?.tableChip}
            size="small"
          />
        ))}
        {composers?.length > 3 && (
          <Chip
            label={"+" + restComposer?.length}
            className={useStyles?.tableChip}
            size="small"
          />
        )}
      </Box>
    ) : (
      "-"
    );
  };

  const CheckedSongList = checked => (checked ? <CheckIcon /> : "");
  const ComposerSelectedSong = composers => {
    const noComposer =
      composers === null ||
      composers === undefined ||
      composers === "" ||
      composers?.length === 0;
    return (
      <Box>
        {!noComposer
          ? composers?.map((songComposer, idx) => (
            <Chip
              key={idx}
              label={songComposer?.composer?.sure_name}
              className={useStyles?.tableChip}
              size="small"
              onDelete={
                composers?.length > 1
                  ? e =>
                    handleDeleteComposer(
                      e,
                      songComposer?.composer_id,
                      songComposer?.song_id
                    )
                  : null
              }
            />
          ))
          : "-"}
      </Box>
    );
  };
  const [contractType, setContractType] = useState("Song Contract");
  const [contractInformationForm, setContractInformationForm] = useState({
    type_period: "unlimited",
    contract_number: "",
    date_period: 0,
    start_date: moment(),
    end_date: moment(),
    description: "",
    is_endless_period: false,
  });

  const [modalSongLoading, setmodalSongLoading] = useState(false);
  const [modalSongTable, setModalSongTable] = useState([]);
  const [modalSongVisiblity, setModalSongVisiblity] = useState(false);
  const [modalSongParams, setModalSongParams] = useState({
    page: 1,
    size: 10,
    search: "",
  });
  const [modalSongListPageCount, setModalSongListPageCount] = useState(1);

  const [selectedSongTable, setSelectedSongTable] = useState([]);
  const [selectedSongLoading, setSelectedSongLoading] = useState(false);
  const [paginatedSelectedSongTable, setPaginatedSelectedSongTable] = useState(
    []
  );
  const [selectedSongParams, setSelectedSongParams] = useState({
    page: 1,
    size: 10,
  });
  const [selectedSongPageCount, setSelectedSongPageCount] = useState(0);
  const [contractComposerForm, setContractComposerForm] = useState({
    selectionsList: [],
    selectedFilter: {},
  });
  const [contractComposerParams, setContractComposerParams] = useState({
    page: 1,
    size: 100,
  });

  const [composerSelectionsList, setComposerSelectionsList] = useState([]);

  const [advanceValueForm, setAdvanceValueForm] = useState({
    status: false,
    selectedFilter: 0,
    mainContractForm: {
      advance_balance: 0,
      start_period: moment(),
      end_period: moment(),
    },
  });
  const [advanceValueTable, setAdvanceValueTable] = useState([]);

  const [ownershipShareForm, setOwnershipShareForm] = useState({
    selectedFilter: 0,
  });
  const [ownershipShareTable, setOwnershipShareTable] = useState([]);

  const [publisherShareForm, setPublisherShareForm] = useState({
    value: 0,
    selectedFilter: 0,
  });
  const [publisherShareTable, setPublisherShareTable] = useState([]);

  const [agentForm, setAgentForm] = useState({
    status: false,
    selectionsList: [],
    selectedFilter: 0,
    mainContractForm: {
      agent_id: 0,
      agent_percentage_value: 0,
      agent_fee_type: "sales_amount",
    },
  });
  const [agentTable, setAgentTable] = useState([]);

  const getValueSongComposer = (song, filter, key) => {
    const composer = song?.song_composer?.find(
      composer => composer?.composer_id === filter
    );
    return composer ? composer[key] || "" : "";
  };

  const [supportingDocumentFiles, setSupportingDocumentFiles] = useState([]);
  const isSongContract = contractType === "Song Contract";
  const isPerpetual = contractInformationForm?.is_endless_period === true;
  const formSkeleton = {
    contractType: {
      title: "Contract Type",
      subTitle:
        "Feature to select type of contract between main or song contract",
      selectionsList: [{ name: "Song Contract" }, { name: "Main Contract" }],
    },
    contractInformation: {
      title: `${isSongContract ? "Song" : "Main"} Contract Information`,
      subTitle:
        "Form to fill out information data related to the contract to be made",
      buttonPeriod: [
        { value: "unlimited", label: "Day" },
        { value: "year", label: "Year" },
      ],
      optionsTitle: "This contract will perpetuity",
    },
    songList: {
      modalButtonList: ["Cancel", "Choose"],
      columnTable: [
        {
          name: "iswc_code",
          title: "ISWC Code",
        },
        {
          name: "title_song",
          title: "Song Title",
        },
        {
          name: "all",
          title: "Composer/Author Name",
          renderText: item => ComposerSongList(item?.song_composer),
        },
        {
          name: "all",
          title: "",
          renderText: item => CheckedSongList(item?.checked),
        },
      ],
      emptyPlaceHolder: "No Data",
    },
    song: {
      title: "Song",
      subTitle:
        "This feature can add multiple songs with multiple composer/author in contract",
      optionsTitle: "Click to add song",
      columnTable: [
        {
          name: "title_song",
          title: "Song",
        },
        {
          name: "all",
          title: "Composer/Author Name",
          renderText: item => ComposerSelectedSong(item?.song_composer),
        },
        {
          name: "iswc_code",
          title: "ISWC Code",
        },
      ],
      emptyPlaceHolder: "No Song Selected Yet",
    },
    composer: {
      title: "Composer",
      subTitle: "This feature can add composer in one contract",
      selectionPlaceholder: "Search Composer/Author",
    },
    advanceValue: {
      title: "Advance Value",
      subTitle: "Feature to set Advance Payment",
      optionsTitle: "Set Advance Value For Composer/Author",
      selectionPlaceholder: "Select Composer",
      columnTable: [
        ...(isSongContract
          ? [
            {
              name: "title_song",
              title: "Song",
            },
          ]
          : []),
        {
          name: "all",
          title: "Advance Value",
          renderText: song => (
            <TableForm
              value={
                isSongContract
                  ? getValueSongComposer(
                    song,
                    advanceValueForm?.selectedFilter,
                    "advance_balance"
                  ) || 0
                  : song?.advance_balance
              }
              handleChangeValue={value =>
                isSongContract
                  ? handleChangeValueAdvance(
                    Number(value),
                    song,
                    "advance_balance"
                  )
                  : handleChangeAdvanceValueMainContractForm(
                    Number(value),
                    "mainContractForm",
                    "advance_balance"
                  )
              }
              type="currency"
              classes={classes}
            />
          ),
          width: 300,
        },
        {
          name: "all",
          title: "Start Period",
          renderText: song => (
            <TableForm
              value={
                isSongContract
                  ? getValueSongComposer(
                    song,
                    advanceValueForm?.selectedFilter,
                    "start_period"
                  )
                  : song?.start_period
              }
              handleChangeValue={value =>
                isSongContract
                  ? handleChangeValueAdvance(value, song, "start_period")
                  : handleChangeAdvanceValueMainContractForm(
                    value,
                    "mainContractForm",
                    "start_period"
                  )
              }
              type="date"
              classes={classes}
            />
          ),
          width: 250,
        },
        {
          name: "all",
          title: "End Period",
          renderText: song => (
            <TableForm
              value={
                isSongContract
                  ? getValueSongComposer(
                    song,
                    advanceValueForm?.selectedFilter,
                    "end_period"
                  )
                  : song?.end_period
              }
              handleChangeValue={value =>
                isSongContract
                  ? handleChangeValueAdvance(value, song, "end_period")
                  : handleChangeAdvanceValueMainContractForm(
                    value,
                    "mainContractForm",
                    "end_period"
                  )
              }
              type="date"
              classes={classes}
            />
          ),
          width: 250,
        },
      ],
      emptyPlaceHolder: "No Composer Selected Yet",
    },
    ownershipSharePercentage: {
      title: "Ownership Share Percentage",
      subTitle: "Feature to set Ownership Share Percentage",
      selectionPlaceholder: "Select Song",
      columnTable: [
        {
          name: "all",
          title: "Composer/Author Name",
          renderText: composer => composer?.composer?.sure_name,
        },
        {
          name: "all",
          title: "Share Percentage",
          renderText: composer => (
            <TableForm
              value={composer?.percentage_ownership}
              handleChangeValue={value =>
                handleChangeOwnershipShareValue(value, composer)
              }
              classes={classes}
            />
          ),
          width: 200,
        },
      ],
      emptyPlaceHolder: "No Song Selected Yet",
    },
    publisherSharePercentage: {
      title: "Publisher Share Percentage",
      subTitle: "Feature to set Publisher Share Percentage",
      selectionPlaceholder: "Select Composer",
      columnTable: [
        {
          name: "title_song",
          title: "Song",
        },
        {
          name: "all",
          title: "Share Percentage",
          renderText: song => (
            <TableForm
              value={getValueSongComposer(
                song,
                publisherShareForm?.selectedFilter,
                "publisher_share"
              )}
              handleChangeValue={value =>
                handleChangePublisherShareValue(value, song)
              }
              classes={classes}
            />
          ),
          width: 200,
        },
      ],
      emptyPlaceHolder: "No Composer Selected Yet",
    },
    agent: {
      title: "Agent",
      subTitle: "Feature to set Agent & Fee Agent",
      selectionPlaceholder: "Select Composer",
      optionsTitle: "Is Agent engaged ?",
      columnTable: [
        ...(isSongContract
          ? [
            {
              name: "title_song",
              title: "Song",
            },
          ]
          : []),
        {
          name: "all",
          title: "Agent",
          renderText: song => (
            <TableForm
              value={
                isSongContract
                  ? getValueSongComposer(
                    song,
                    agentForm?.selectedFilter,
                    "agent_id"
                  ) || 0
                  : song?.agent_id
              }
              handleChangeValue={value =>
                isSongContract
                  ? handleChangeAgentValue(value, song, "agent_id")
                  : handleChangeAgentMainContractForm(
                    value,
                    "mainContractForm",
                    "agent_id"
                  )
              }
              type="select"
              selectionKey="agent_id"
              selectionLabel="name"
              selectionsList={agentForm?.selectionsList}
              selectionPlaceHolder="Select Agent"
              disabled={isPreloadApproved}
              classes={classes}
            />
          ),
          width: 300,
        },
        {
          name: "all",
          title: "Agent Fee",
          renderText: song => (
            <TableForm
              value={
                isSongContract
                  ? getValueSongComposer(
                    song,
                    agentForm?.selectedFilter,
                    "agent_percentage_value"
                  )
                  : song?.agent_percentage_value
              }
              handleChangeValue={value =>
                isSongContract
                  ? handleChangeAgentValue(
                    Number(value),
                    song,
                    "agent_percentage_value"
                  )
                  : handleChangeAgentMainContractForm(
                    Number(value),
                    "mainContractForm",
                    "agent_percentage_value"
                  )
              }
              disabled={isPreloadApproved}
              classes={classes}
            />
          ),
          width: 200,
        },
        {
          name: "all",
          title: "Fee Calculation Basis",
          renderText: song => (
            <TableForm
              value={
                isSongContract
                  ? getValueSongComposer(
                    song,
                    agentForm?.selectedFilter,
                    "agent_fee_type"
                  ) || "sales_amount"
                  : song?.agent_fee_type || "sales_amount"
              }
              handleChangeValue={value =>
                isSongContract
                  ? handleChangeAgentValue(value, song, "agent_fee_type")
                  : handleChangeAgentMainContractForm(
                    value,
                    "mainContractForm",
                    "agent_fee_type"
                  )
              }
              type="select"
              selectionKey="value"
              selectionLabel="name"
              selectionsList={[
                { name: "Sales Amount", value: "sales_amount" },
                { name: "Net Revenue Amount", value: "net_revenue_amount" },
              ]}
              disabled={isPreloadApproved}
              classes={classes}
            />
          ),
          width: 200,
        },
      ],
      emptyPlaceHolder: "No Composer Selected Yet",
    },
    supportingDocument: {
      title: "Supporting Document",
      subTitle:
        "Upload supporting documents, such as scanned agreement documents or can be in the form of pdf files",
      status: false,
      emptyPlaceHolder: "No File Selected Yet",
    },
  };
  const paginatedTable = (array, page, pageSize) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return array?.slice(startIndex, endIndex);
  };
  const filterSelectedSongsByComposerId = (songList, composerId) => {
    return songList?.filter(song =>
      song?.song_composer?.some(
        composer => composer?.composer_id === composerId
      )
    );
  };
  const changeDate = (init, value) => moment(init).add(value, "days");
  const changeYear = (init, value) => moment(init).add(value, "years");
  const handleCloseModalSong = () => {
    setModalSongVisiblity(false);
  };
  const handleChangeContractInformationForm = (value, key) => {
    setContractInformationForm(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };

  const handleChangeModalSongParams = (value, key) => {
    setModalSongParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeModalSongPageSize = value => {
    setModalSongParams(currentState => ({
      ...currentState,
      page: 1,
      size: value,
    }));
  };

  const handleChangeSelectedSongParams = (value, key) => {
    setSelectedSongParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeSelectedSongPage = newPage => {
    setSelectedSongLoading(true);
    handleChangeSelectedSongParams(newPage, "page");
    setPaginatedSelectedSongTable(
      paginatedTable(selectedSongTable, newPage, selectedSongParams?.size)
    );
    setSelectedSongLoading(false);
  };
  const handleChangeSelectedSongPageSize = value => {
    setSelectedSongParams({
      page: 1,
      size: value,
    });
    setPaginatedSelectedSongTable(paginatedTable(selectedSongTable, 1, value));
    setSelectedSongPageCount(getTotalPage(selectedSongTable?.length, value));
    setSelectedSongLoading(false);
  };

  const handleChangeContractComposerForm = (value, key) => {
    setContractComposerForm(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeComposerParams = (value, key) => {
    setContractComposerParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };

  const handleChangeAdvanceValueForm = (value, key) => {
    setAdvanceValueForm(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleSelectAdvanceValue = value => {
    handleChangeAdvanceValueForm(value, "selectedFilter");
    setAdvanceValueTable(
      filterSelectedSongsByComposerId(selectedSongTable, value)
    );
  };
  const handleChangeValueAdvance = (value, selectedSong, key) => {
    const { selectedFilter } = advanceValueForm;
    setSelectedSongTable(prevList =>
      prevList.map(song =>
        song?.song_id === selectedSong?.song_id
          ? {
            ...song,
            song_composer: song?.song_composer?.map(composer =>
              composer?.composer_id === selectedFilter
                ? {
                  ...composer,
                  [key]: value,
                }
                : composer
            ),
          }
          : song
      )
    );
    setAdvanceValueTable(prevList =>
      prevList.map(song =>
        song?.song_id === selectedSong?.song_id
          ? {
            ...song,
            song_composer: song?.song_composer?.map(composer =>
              composer?.composer_id === selectedFilter
                ? { ...composer, [key]: value }
                : composer
            ),
          }
          : song
      )
    );
  };
  const handleChangeAdvanceValueMainContractForm = (value, key, subKey) => {
    setAdvanceValueForm(currentState => ({
      ...currentState,
      [key]: {
        ...currentState?.[key],
        [subKey]: value,
      },
    }));
  };

  const handleChangeOwnershipShareForm = (value, key) => {
    setOwnershipShareForm(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleSelectOnwershipShare = value => {
    handleChangeOwnershipShareForm(value, "selectedFilter");
    const selectedSong = selectedSongTable?.find(
      song => song?.song_id === value
    );
    const selectedSongComposerList = selectedSong?.song_composer || [];
    setOwnershipShareTable(selectedSongComposerList);
  };
  const handleChangeOwnershipShareValue = (value, selectedComposer) => {
    const { selectedFilter } = ownershipShareForm;

    setSelectedSongTable(prevList =>
      prevList.map(song =>
        song?.song_id === selectedFilter
          ? {
            ...song,
            song_composer: song?.song_composer?.map(composer =>
              composer?.composer_id === selectedComposer?.composer_id
                ? { ...composer, percentage_ownership: Number(value) }
                : composer
            ),
          }
          : song
      )
    );
    setOwnershipShareTable(prevList =>
      prevList.map(composer =>
        composer?.composer_id === selectedComposer?.composer_id
          ? { ...composer, percentage_ownership: Number(value) }
          : composer
      )
    );
  };

  const handleChangePublisherShareForm = (value, key) => {
    setPublisherShareForm(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleSelectPublisherShare = async value => {
    await handleChangePublisherShareForm(value, "selectedFilter");
    setPublisherShareTable(
      filterSelectedSongsByComposerId(selectedSongTable, value)
    );
  };
  const handleChangePublisherShareValue = (value, selectedSong) => {
    const { selectedFilter } = publisherShareForm;
    setSelectedSongTable(prevList =>
      prevList.map(song =>
        song?.song_id === selectedSong?.song_id
          ? {
            ...song,
            song_composer: song?.song_composer?.map(composer =>
              composer?.composer_id === selectedFilter
                ? { ...composer, publisher_share: Number(value) }
                : composer
            ),
          }
          : song
      )
    );

    setPublisherShareTable(prevTable =>
      filterSelectedSongsByComposerId(prevTable, selectedFilter).map(song =>
        song?.song_id === selectedSong?.song_id
          ? {
            ...song,
            song_composer: song?.song_composer?.map(composer =>
              composer?.composer_id === selectedFilter
                ? { ...composer, publisher_share: Number(value) }
                : composer
            ),
          }
          : song
      )
    );
  };

  const handleChangeAgentForm = (value, key) => {
    setAgentForm(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleSelectAgent = async value => {
    await handleChangeAgentForm(value, "selectedFilter");
    setAgentTable(filterSelectedSongsByComposerId(selectedSongTable, value));
  };
  const handleChangeAgentValue = (value, selectedSong, key) => {
    const { selectedFilter } = agentForm;
    setSelectedSongTable(prevList =>
      prevList.map(song =>
        song?.song_id === selectedSong?.song_id
          ? {
            ...song,
            song_composer: song?.song_composer?.map(composer =>
              composer?.composer_id === selectedFilter
                ? {
                  ...composer,
                  [key]: value,
                }
                : composer
            ),
          }
          : song
      )
    );
    setAgentTable(prevList =>
      prevList.map(song =>
        song?.song_id === selectedSong?.song_id
          ? {
            ...song,
            song_composer: song?.song_composer?.map(composer =>
              composer?.composer_id === selectedFilter
                ? { ...composer, [key]: value }
                : composer
            ),
          }
          : song
      )
    );
  };
  const handleChangeAgentMainContractForm = (value, key, subKey) => {
    setAgentForm(currentState => ({
      ...currentState,
      [key]: {
        ...currentState?.[key],
        [subKey]: value,
      },
    }));
  };

  const handleChangePeriodType = value => {
    handleChangeContractInformationForm(value, "type_period");
    if (value === "unlimited") {
      handleChangeContractInformationForm(
        changeDate(
          contractInformationForm?.start_date,
          contractInformationForm?.date_period
        ),
        "end_date"
      );
    } else {
      handleChangeContractInformationForm(
        changeYear(
          contractInformationForm?.start_date,
          contractInformationForm?.date_period
        ),
        "end_date"
      );
    }
  };

  const handleChangeTimePeriod = event => {
    const value = parseInt(event?.target?.value) || 0;

    handleChangeContractInformationForm(value, "date_period");
    if (contractInformationForm?.type_period === "unlimited") {
      handleChangeContractInformationForm(
        changeDate(contractInformationForm?.start_date, value),
        "end_date"
      );
    } else {
      handleChangeContractInformationForm(
        changeYear(contractInformationForm?.start_date, value),
        "end_date"
      );
    }
  };
  const handleAddSong = async song => {
    const selectedId = song?.song_id;
    const isSongSelected = selectedSongTable.some(
      item => item.song_id === selectedId
    );

    setSelectedSongTable(prevSelectedSongTable => {
      let updatedSelectedSongTable;

      if (isSongSelected) {
        updatedSelectedSongTable = prevSelectedSongTable.filter(
          item => item.song_id !== selectedId
        );
      } else {
        const updatedSongComposers = song.song_composer?.map(composer => ({
          ...composer,
          advance_balance: 0,
          start_period: moment(),
          end_period: moment(),
          agent_id: 0,
          agent_percentage_value: 0,
          agent_fee_type: "sales_amount",
        }));

        updatedSelectedSongTable = [
          ...prevSelectedSongTable,
          {
            ...song,
            song_composer: updatedSongComposers,
          },
        ];
      }

      const paginatedTableData = paginatedTable(
        updatedSelectedSongTable,
        selectedSongParams?.page,
        selectedSongParams?.size
      );
      setPaginatedSelectedSongTable(paginatedTableData);

      const pageCount = getTotalPage(
        updatedSelectedSongTable?.length,
        selectedSongParams?.size
      );
      setSelectedSongPageCount(pageCount);

      const newDataAdvanceValueList = updatedSelectedSongTable?.flatMap(item =>
        item?.song_composer?.map(composer => ({
          sure_name: composer?.composer?.sure_name,
          song_id: composer?.song_id,
          composer_id: composer?.composer_id,
        }))
      );
      const uniqueSongComposersMap = new Map();
      newDataAdvanceValueList.forEach(item => {
        uniqueSongComposersMap.set(item.sure_name, item);
      });

      const uniqueSongComposersList = Array.from(
        uniqueSongComposersMap.values()
      );
      setComposerSelectionsList(uniqueSongComposersList);

      return updatedSelectedSongTable;
    });

    const updatedModalSongTable = modalSongTable?.map(item =>
      item?.song_id === selectedId ? { ...item, checked: !item.checked } : item
    );
    setModalSongTable(updatedModalSongTable);
  };

  const handleDeleteComposer = async (event, composerId, songId) => {
    event?.stopPropagation();

    const updatedSelectedSongTable = selectedSongTable?.map(item => {
      if (item.song_id === songId) {
        return {
          ...item,
          song_composer: item.song_composer?.filter(
            songComposer => songComposer?.composer?.composer_id !== composerId
          ),
        };
      }
      return item;
    });

    const paginatedTableData = paginatedTable(
      updatedSelectedSongTable,
      selectedSongParams?.page,
      selectedSongParams?.size
    );
    setPaginatedSelectedSongTable(paginatedTableData);

    const pageCount = getTotalPage(
      updatedSelectedSongTable?.length,
      selectedSongParams?.size
    );
    setSelectedSongPageCount(pageCount);

    const isComposerUsedInOtherSongs = updatedSelectedSongTable?.some(item =>
      item.song_composer?.some(
        songComposer => songComposer?.composer?.composer_id === composerId
      )
    );

    if (!isComposerUsedInOtherSongs) {
      const updatedComposerSelectionsList = composerSelectionsList.filter(
        composer => composer?.composer_id !== composerId
      );
      setComposerSelectionsList(updatedComposerSelectionsList);
    }

    setAdvanceValueTable(
      filterSelectedSongsByComposerId(
        updatedSelectedSongTable,
        advanceValueForm?.selectedFilter
      )
    );

    const selectedSong = updatedSelectedSongTable.find(
      song => song.song_id === ownershipShareForm?.selectedFilter
    );
    const selectedSongComposerList = selectedSong?.song_composer || [];
    setOwnershipShareTable(selectedSongComposerList);

    setPublisherShareTable(
      filterSelectedSongsByComposerId(
        updatedSelectedSongTable,
        publisherShareForm?.selectedFilter
      )
    );

    setAgentTable(
      filterSelectedSongsByComposerId(
        updatedSelectedSongTable,
        agentForm?.selectedFilter
      )
    );

    setSelectedSongTable(updatedSelectedSongTable);
  };

  const getDateFormatForFilter = date => {
    if (!date) return "";
    return moment(date).format("YYYY-MM-DD");
  };
  const validatePayload = () => {
    const errors = [];
    const errorEmpty = err => `${err} can't be empty.`;

    Object.entries(contractInformationForm).forEach(([key, value]) => {
      switch (key) {
        case "contract_number":
          if (!value) errors.push(errorEmpty("Contract Number"));
          break;
        case "date_period":
          if (!value) errors.push(errorEmpty("Time Period"));
          break;
        default:
          break;
      }
    });
    if (
      !isSongContract &&
      Object.keys(contractComposerForm?.selectedFilter).length === 0
    ) {
      errors.push(errorEmpty("Composer"));
    } else if (isSongContract && selectedSongTable.length === 0) {
      errors.push(errorEmpty("Song"));
    }

    return errors;
  };
  const handleSubmit = event => {
    event?.preventDefault();

    const payloadAdvanceValueList = selectedSongTable?.flatMap(song => {
      return song?.song_composer?.map(composer => ({
        composer_id: composer?.composer_id,
        song_id: composer?.song_id,
        balance: composer?.advance_balance,
        start_period: getDateFormatForFilter(composer?.start_period),
        end_period: getDateFormatForFilter(composer?.end_period),
      }));
    });
    const payloadAdvanceValueMainContract = {
      balance: advanceValueForm?.mainContractForm?.advance_balance,
      start_period: getDateFormatForFilter(
        advanceValueForm?.mainContractForm?.start_period
      ),
      end_period: getDateFormatForFilter(
        advanceValueForm?.mainContractForm?.end_period
      ),
    };
    const payloadOwnershipPercentage = selectedSongTable?.map(song => ({
      song_id: song?.song_id,
      composer_share_percentages: song?.song_composer?.map(composer => ({
        composer_id: composer?.composer_id,
        ownership_percentage: Number(composer?.percentage_ownership) || 0,
      })),
    }));
    const payloadPublisherShare = (() => {
      const composerMap = {};
      selectedSongTable?.forEach(item => {
        item.song_composer?.forEach(composer => {
          const { composer_id, song_id, publisher_share } = composer;
          if (!composerMap[composer_id]) {
            composerMap[composer_id] = [];
          }
          composerMap[composer_id].push({
            song_id,
            percentage: publisher_share,
          });
        });
      });
      return Object.entries(composerMap).map(
        ([composer_id, song_percentages]) => ({
          composer_id: Number(composer_id),
          song_percentages,
        })
      );
    })();
    const payloadAgentList = selectedSongTable?.flatMap(song => {
      return song?.song_composer?.map(composer => ({
        song_id: song?.song_id,
        composer_id: composer?.composer_id,
        agent_id: composer?.agent_id,
        agent_fee: composer?.agent_percentage_value,
        fee_type: composer?.agent_fee_type,
      }));
    });
    const payloadAgentMainContract = {
      ...agentForm?.mainContractForm,
      agent_fee: agentForm?.mainContractForm?.agent_percentage_value,
      fee_type: agentForm?.mainContractForm?.agent_fee_type,
    };

    const payload = {
      contract_number: contractInformationForm?.contract_number,
      date_periode: contractInformationForm?.date_period,
      start_date: getDateFormatForFilter(contractInformationForm?.start_date),
      end_date: getDateFormatForFilter(contractInformationForm?.end_date),
      description: contractInformationForm?.description,
      is_endless_period: isPerpetual,
      type_period: contractInformationForm?.type_period,
      ...(isSongContract
        ? {
          ...(advanceValueForm?.status && {
            contract_composer_balances: payloadAdvanceValueList,
          }),
          ownership_percentages: payloadOwnershipPercentage,
          publisher_share_percentages: payloadPublisherShare,
          ...(agentForm?.status && {
            contract_composer_agents: payloadAgentList,
          }),
        }
        : {
          ...(advanceValueForm?.status && {
            contract_composer_balance: payloadAdvanceValueMainContract,
          }),
          composer_id: contractComposerForm?.selectedFilter?.composer_id,
          publisher_share_percentage: Number(publisherShareForm?.value),
          ...(agentForm?.status && {
            contract_composer_agent: payloadAgentMainContract,
          }),
        }),
    };

    const errors = validatePayload();

    if (errors?.length) {
      ModalError(errors[0]);
      return;
    }
    props?.handlSubmitData(payload, supportingDocumentFiles, contractType);
  };

  const getSongList = async () => {
    setmodalSongLoading(true);
    try {
      const url = hardBaseUrl + "/publisher/contract-get-song";
      const params = {
        page: modalSongParams?.page,
        size: modalSongParams?.size,
        search: modalSongParams?.search,
        ...(isEditMode && {
          id: preloadContractInformation?.contract_id,
        }),
      };
      const options = {
        headers,
        params,
      };
      const response = await axios.get(url, options);
      if (Array.isArray(response?.data?.data)) {
        const dataWithChecked = response?.data?.data?.map(item => ({
          ...item,
          checked: selectedSongTable?.some(
            song => song?.song_id === item?.song_id
          ),
        }));
        const pageCount = getTotalPage(
          response?.data?.meta?.total,
          params?.size
        );
        setModalSongTable(dataWithChecked);
        setModalSongListPageCount(pageCount);
      }
      setmodalSongLoading(false);
    } catch {
      setmodalSongLoading(false);
    }
  };
  const getAgentList = () => {
    const url = `${hardBaseUrl}/publisher/agent-on-transaction`;
    axios.get(url, { headers }).then(res => {
      handleChangeAgentForm(res?.data?.data, "selectionsList");
    });
  };
  const getComposerList = async () => {
    const url = `${hardBaseUrl}/publisher/contract-get-composer`;
    const params = contractComposerParams;
    await axios.get(url, { headers, params }).then(res => {
      handleChangeContractComposerForm(res?.data?.data, "selectionsList");
    });
  };

  const handlePreloadSongs = preloadSongs => {
    const selectedSongData = [];

    preloadSongs.forEach(song => {
      const { song_id, title, composers } = song;
      const selectedSongComposers = composers.map(composer => {
        const composerData = preloadAgents.find(
          agent => agent.composer_id === composer.composer_id
        );
        const agent = composerData
          ? composerData.agents.find(agent => agent.song_id === song_id)
          : null;

        const { agent_id, fee, fee_type } = agent || {};

        return {
          composer_id: composer.composer_id,
          percentage_ownership: composer.ownership_percentage,
          publisher_share: composer.publisher_share,
          composer: {
            sure_name: composer.composer_name,
          },
          ContractComposerID: composer.song_balance.ContractComposerID,
          song_id: composer.song_balance.song_id,
          song_title: composer.song_balance.song_title,
          advance_balance: composer.song_balance.balance,
          balance_formatted: composer.song_balance.balance_formatted,
          start_period: getPreloadDate(composer.song_balance.start_period),
          end_period: getPreloadDate(composer.song_balance.end_period),
          contract_advance_flag: composer.contract_advance_flag,
          contract_publisher_share_flag: composer.contract_publisher_share_flag,
          agent_id: agent_id || 0,
          agent_percentage_value: fee || 0,
          agent_fee_type: fee_type || "sales_amount",
        };
      });

      selectedSongData.push({
        song_id,
        title_song: title,
        song_composer: selectedSongComposers,
      });

      const paginatedTableData = paginatedTable(
        selectedSongData,
        selectedSongParams?.page,
        selectedSongParams?.size
      );
      setPaginatedSelectedSongTable(paginatedTableData);

      const pageCount = getTotalPage(
        selectedSongData?.length,
        selectedSongParams?.size
      );
      setSelectedSongPageCount(pageCount);
    });

    setSelectedSongTable(selectedSongData);
  };

  const getPreload = async () => {
    setContractType(props?.stateContractType);
    setContractInformationForm(prevState => ({
      ...prevState,
      type_period: preloadContractInformation?.type_period,
      contract_number: preloadContractInformation?.contract_number || "",
      date_period: preloadContractInformation?.date_period || 0,
      start_date: preloadContractInformation?.start_date
        ? getPreloadDate(preloadContractInformation?.start_date)
        : moment(),
      end_date: preloadContractInformation?.end_date
        ? getPreloadDate(preloadContractInformation?.end_date)
        : moment(),
      description: preloadContractInformation?.description || "",
      is_endless_period: preloadContractInformation?.is_endless_period || false,
    }));
    setComposerSelectionsList(preloadComposers);
    if (preloadSongs) {
      handlePreloadSongs(preloadSongs);
    }

    if (!isSongContract) {
      const preloadAdvanceValue =
        preloadContractInformation?.contract_composer_balance;
      const preloadAgent = preloadContractInformation?.contract_composer_agent;

      const preloadContractComposer = {
        composer_id: preloadContractInformation?.composer?.composer_id,
        composer_name: preloadContractInformation?.composer?.sure_name,
        phone_number: preloadContractInformation?.composer?.phone_number,
      };
      const preloadAdvanceValueForm = {
        advance_balance: preloadAdvanceValue?.balance,
        start_period: preloadAdvanceValue?.start_period
          ? getPreloadDate(preloadAdvanceValue?.start_period)
          : moment(),
        end_period: preloadAdvanceValue?.end_period
          ? getPreloadDate(preloadAdvanceValue?.end_period)
          : moment(),
      };
      const preloadAgentForm = {
        agent_id: preloadAgent?.agent_id,
        agent_percentage_value: Number(preloadAgent?.agent_fee_formatted),
        agent_fee_type: preloadAgent?.fee_type,
      };
      handleChangeContractComposerForm(
        preloadContractComposer,
        "selectedFilter"
      );

      handleChangeAdvanceValueForm(
        preloadContractInformation?.contract_composer_balance?.is_have_advance,
        "status"
      );
      handleChangeAdvanceValueForm(
        preloadAdvanceValue?.is_have_advance,
        "status"
      );
      handleChangeAdvanceValueForm(preloadAdvanceValueForm, "mainContractForm");

      handleChangePublisherShareForm(
        preloadContractInformation?.publisher_share_percentage,
        "value"
      );

      handleChangeAgentForm(!preloadAgent?.is_agent_deleted, "status");
      handleChangeAgentForm(preloadAgentForm, "mainContractForm");
    }
  };

  useEffect(() => {
    getAgentList();
  }, []);
  useEffect(() => {
    if (isEditMode && !props?.loadingContract) {
      getPreload();
    }
  }, [preload]);
  useEffect(() => {
    getSongList();
  }, [modalSongParams]);
  useEffect(() => {
    getComposerList();
  }, [contractComposerParams]);

  const ContractTypeOptions = () => (
    <SelectionsLayout
      onChange={event => setContractType(event?.target?.value)}
      selectionValue={contractType}
      selectionsList={formSkeleton?.contractType?.selectionsList}
      selectionKey="name"
      selectionLabel="name"
      fullWidth
      disabled={isEditMode}
      classes={classes}
    />
  );
  const PeriodTypeOptions = () => (
    <Grid container spacing={1}>
      {formSkeleton?.contractInformation?.buttonPeriod?.map(item =>
        contractInformationForm?.type_period === item?.value ? (
          <Grid item key={item?.value}>
            <PrimaryButton
              label={item?.label}
              onClick={() => handleChangePeriodType(item?.value)}
              width={100}
            />
          </Grid>
        ) : (
          <Grid item key={item?.value}>
            <SecondaryButton
              label={item?.label}
              onClick={() => handleChangePeriodType(item?.value)}
              width={100}
            />
          </Grid>
        )
      )}
    </Grid>
  );

  const ContractInformationForm = () => (
    <Card className={classes?.card}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SingleFormLayout
              label="Contract Number"
              required
              placeholder="Contract Number"
              onChange={event =>
                handleChangeContractInformationForm(
                  event?.target?.value,
                  "contract_number"
                )
              }
              value={contractInformationForm?.contract_number}
              disabled={isEditMode && isPreloadApproved}
              classes={classes}
            />
          </Grid>
          <Grid item xs={6}>
            <SingleFormLayout
              label="Time Period"
              required
              placeholder={0}
              onChange={event => handleChangeTimePeriod(event)}
              disabled={
                isPerpetual ||
                (isEditMode && isPreloadApproved && !isActiveContract)
              }
              customProps={
                contractInformationForm?.type_period === "unlimited"
                  ? "Days"
                  : "Years"
              }
              value={contractInformationForm?.date_period}
              classes={classes}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormLabel label="Start Date" required />
            <DatePicker
              disabled={isEditMode && isPreloadApproved}
              value={contractInformationForm?.start_date}
              onChange={date => {
                handleChangeContractInformationForm(date, "start_date");
                if (contractInformationForm?.type_period === "unlimited") {
                  handleChangeContractInformationForm(
                    changeDate(
                      date,
                      Number(contractInformationForm?.date_period)
                    ),
                    "end_date"
                  );
                } else {
                  handleChangeContractInformationForm(
                    changeYear(
                      date,
                      Number(contractInformationForm?.date_period)
                    ),
                    "end_date"
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel label="End Date" required />
            <DatePicker
              label={isPerpetual ? "Perpetual" : null}
              disabled
              value={isPerpetual ? null : contractInformationForm?.end_date}
            />
            <SwitchFormLayout
              isChecked={contractInformationForm?.is_endless_period}
              onChange={() =>
                handleChangeContractInformationForm(
                  !contractInformationForm?.is_endless_period,
                  "is_endless_period"
                )
              }
              label={formSkeleton?.contractInformation?.optionsTitle}
              classes={classes}
            />
          </Grid>
        </Grid>
        <SingleFormLayout
          label="Description"
          placeholder="Description"
          onChange={event =>
            handleChangeContractInformationForm(
              event?.target?.value,
              "description"
            )
          }
          value={contractInformationForm?.description}
          multiline
          classes={classes}
        />
      </CardContent>
    </Card>
  );

  const SongOptions = () => (
    <>
      <Paper
        className={classes?.paperAddSong}
        onClick={() => setModalSongVisiblity(true)}
      >
        <Box className={classes?.containerFlexRow}>
          <AddIcon className={classes?.tambahLagu} />
          <Typography variant="subtitle2" className={classes?.textTambahLagu}>
            {formSkeleton?.song?.optionsTitle}
          </Typography>
        </Box>
      </Paper>
      <SongDialog
        visiblity={modalSongVisiblity}
        handleClose={handleCloseModalSong}
        isLoading={modalSongLoading}
        columnTable={formSkeleton?.songList?.columnTable}
        data={modalSongTable}
        rowClick={value => handleAddSong(value)}
        search={modalSongParams?.search}
        page={modalSongParams?.page}
        size={modalSongParams?.size}
        pageCount={modalSongListPageCount}
        emptyPlaceHolder={formSkeleton?.songList?.emptyPlaceHolder}
        handleSearch={search => handleChangeModalSongParams(search, "search")}
        handleChangePage={page =>
          modalSongParams?.page !== page &&
          handleChangeModalSongParams(page, "page")
        }
        handleChangePageSize={pageSize =>
          handleChangeModalSongPageSize(pageSize)
        }
        buttonList={formSkeleton?.songList?.modalButtonList}
        classes={classes}
      />
    </>
  );
  const SongTable = () => (
    <TableLayout
      columnTable={formSkeleton?.song?.columnTable}
      data={paginatedSelectedSongTable}
      emptyPlaceHolder={formSkeleton?.song?.emptyPlaceHolder}
      page={selectedSongParams?.page}
      pageSize={selectedSongParams?.size}
      pageCount={selectedSongPageCount}
      onChangePage={(_, newPage) => handleChangeSelectedSongPage(newPage)}
      onChangePageSize={event =>
        handleChangeSelectedSongPageSize(event?.target?.value)
      }
      isLoading={selectedSongLoading}
      classes={classes}
    />
  );

  const ComposerOptions = () => {
    return (
      <Autocomplete
        onChange={(_, value) => {
          if (value) {
            handleChangeContractComposerForm(value, "selectedFilter");
          } else {
            handleChangeContractComposerForm("", "selectedFilter");
          }
        }}
        options={contractComposerForm?.selectionsList}
        value={contractComposerForm?.selectedFilter}
        getOptionLabel={option => option?.composer_name}
        renderInput={params => (
          <TextField
            {...params}
            value={contractComposerForm?.selectedFilter?.composer_name}
            onChange={event => {
              handleChangeComposerParams(event?.target?.value, "search");
            }}
            variant="outlined"
            placeholder={formSkeleton?.composer?.selectionPlaceholder}
          />
        )}
      />
    );
  };

  const AdvanceValueOptions = () => (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            className={classes?.checkbox}
            checked={advanceValueForm?.status}
            onChange={() =>
              handleChangeAdvanceValueForm(!advanceValueForm?.status, "status")
            }
          />
        }
        label={formSkeleton?.advanceValue?.optionsTitle}
      />
    </Box>
  );
  const AdvanceValueSelections = () =>
    isSongContract && (
      <SelectionsLayout
        onChange={event => handleSelectAdvanceValue(event?.target?.value)}
        selectionValue={advanceValueForm?.selectedFilter}
        emptyPlaceHolder="Select Composer"
        selectionsList={composerSelectionsList}
        selectionKey="composer_id"
        selectionLabel="sure_name"
        classes={classes}
      />
    );
  const AdvanceValueTable = () =>
    advanceValueTable && (
      <TableLayout
        columnTable={formSkeleton?.advanceValue?.columnTable}
        data={
          isSongContract
            ? advanceValueTable
            : [advanceValueForm?.mainContractForm]
        }
        emptyPlaceHolder={formSkeleton?.advanceValue?.emptyPlaceHolder}
        classes={classes}
      />
    );

  const OwnershipSharePercentageSelections = () => (
    <SelectionsLayout
      onChange={event => handleSelectOnwershipShare(event?.target?.value)}
      selectionValue={ownershipShareForm?.selectedFilter}
      emptyPlaceHolder={
        formSkeleton?.ownershipSharePercentage?.selectionPlaceholder
      }
      selectionsList={selectedSongTable}
      selectionKey="song_id"
      selectionLabel="title_song"
      classes={classes}
    />
  );
  const OwnershipSharePercentageTable = () => (
    <TableLayout
      columnTable={formSkeleton?.ownershipSharePercentage?.columnTable}
      data={ownershipShareTable}
      emptyPlaceHolder={
        formSkeleton?.ownershipSharePercentage?.emptyPlaceHolder
      }
      classes={classes}
    />
  );

  const PublisherSharePercentageSelections = () => (
    <SelectionsLayout
      onChange={event => handleSelectPublisherShare(event?.target?.value)}
      selectionValue={publisherShareForm?.selectedFilter}
      emptyPlaceHolder={
        formSkeleton?.publisherSharePercentage?.selectionPlaceholder
      }
      selectionsList={composerSelectionsList}
      selectionKey="composer_id"
      selectionLabel="sure_name"
      classes={classes}
    />
  );
  const PublisherSharePercentageForm = () => (
    <Box className={classes?.selectContainer}>
      <SingleFormLayout
        value={publisherShareForm?.value}
        placeholder={0}
        onChange={event =>
          handleChangePublisherShareForm(event?.target?.value, "value")
        }
        customProps="%"
        classes={classes}
      />
    </Box>
  );
  const PublisherSharePercentageTable = () => (
    <TableLayout
      columnTable={formSkeleton?.publisherSharePercentage?.columnTable}
      data={publisherShareTable}
      emptyPlaceHolder={
        formSkeleton?.publisherSharePercentage?.emptyPlaceHolder
      }
      classes={classes}
    />
  );

  const AgentOptions = () => (
    <SwitchFormLayout
      isChecked={agentForm?.status}
      onChange={() => handleChangeAgentForm(!agentForm?.status, "status")}
      label={formSkeleton?.agent?.optionsTitle}
      classes={classes}
    />
  );
  const AgentSelections = () =>
    isSongContract && (
      <SelectionsLayout
        onChange={event => handleSelectAgent(event?.target?.value)}
        selectionValue={agentForm?.selectedFilter}
        emptyPlaceHolder={formSkeleton?.agent?.selectionPlaceholder}
        selectionsList={composerSelectionsList}
        selectionKey="composer_id"
        selectionLabel="sure_name"
        classes={classes}
      />
    );
  const AgentTable = () =>
    agentTable && (
      <TableLayout
        columnTable={formSkeleton?.agent?.columnTable}
        data={isSongContract ? agentTable : [agentForm?.mainContractForm]}
        emptyPlaceHolder={formSkeleton?.agent?.emptyPlaceHolder}
        classes={classes}
      />
    );

  return (
    <Page className={classes?.root} title={props?.title}>
      <Container maxWidth={false}>
        <Backdrop className={classes?.backdrop} open={props?.loadingContract}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box className={classes?.containerSpaceBetween}>
          <Typography className={classes?.pageTitle}>{props?.title}</Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography className={classes?.breadCrumbs}>Home</Typography>
            <Typography className={classes?.breadCrumbs}>Contract</Typography>
            <Typography className={classes?.breadCrumbsActive}>
              {props?.title}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Divider className={classes?.divider} />
        <FormLayout
          title={formSkeleton?.contractType?.title}
          subTitle={formSkeleton?.contractType?.subTitle}
          options={ContractTypeOptions}
          classes={classes}
        />
        <Divider className={classes?.divider} />
        <FormLayout
          title={formSkeleton?.contractInformation?.title}
          subTitle={formSkeleton?.contractInformation?.subTitle}
          options={PeriodTypeOptions}
          content={ContractInformationForm}
          classes={classes}
        />
        {isSongContract ? (
          <FormLayout
            title={formSkeleton?.song?.title}
            subTitle={formSkeleton?.song?.subTitle}
            options={SongOptions}
            content={SongTable}
            classes={classes}
          />
        ) : (
          <FormLayout
            title={formSkeleton?.composer?.title}
            subTitle={formSkeleton?.composer?.subTitle}
            content={ComposerOptions}
            classes={classes}
          />
        )}
        <FormLayout
          title={formSkeleton?.advanceValue?.title}
          subTitle={formSkeleton?.advanceValue?.subTitle}
          status={advanceValueForm?.status}
          selections={AdvanceValueSelections}
          options={AdvanceValueOptions}
          content={advanceValueForm?.status && AdvanceValueTable}
          classes={classes}
        />
        {isSongContract && (
          <FormLayout
            title={formSkeleton?.ownershipSharePercentage?.title}
            subTitle={formSkeleton?.ownershipSharePercentage?.subTitle}
            status
            selections={OwnershipSharePercentageSelections}
            content={OwnershipSharePercentageTable}
            classes={classes}
          />
        )}
        <FormLayout
          title={formSkeleton?.publisherSharePercentage?.title}
          subTitle={formSkeleton?.publisherSharePercentage?.subTitle}
          status
          selections={
            isSongContract
              ? PublisherSharePercentageSelections
              : PublisherSharePercentageForm
          }
          content={isSongContract && PublisherSharePercentageTable}
          classes={classes}
        />
        <FormLayout
          title={formSkeleton?.agent?.title}
          subTitle={formSkeleton?.agent?.subTitle}
          options={AgentOptions}
          status={agentForm?.status}
          selections={agentForm?.status && AgentSelections}
          content={agentForm?.status && AgentTable}
          classes={classes}
        />
        <Divider className={classes?.divider} />
        {!isEditMode && (
          <>
            <FormLayout
              title={formSkeleton?.supportingDocument?.title}
              subTitle={formSkeleton?.supportingDocument?.subTitle}
              classes={classes}
            />
            <SupportingDocumentForm
              supportingDocumentFiles={supportingDocumentFiles}
              setSupportingDocumentFiles={setSupportingDocumentFiles}
              classes={classes}
            />
            <Divider className={classes?.divider} />
          </>
        )}
        <Box display="flex" justifyContent="flex-end">
          <PrimaryButton
            onClick={e => handleSubmit(e)}
            label={isEditMode ? "Save Contract" : props?.title}
          />
        </Box>
      </Container>
    </Page>
  );
}

const getPreloadDate = date => moment(date, "YYYY-MM-DD");

export default ContractForm;
