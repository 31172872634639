import { Input } from "antd";
import Axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import logoMpis from "../../../assets/img/big-logo-mpis.svg";
import CARIS from "../../../assets/img/newCarisLogo.webp";
import LayoutNoHeader from "../../../mpisLandingComponent/LayoutNoHeader";
import { hardBaseUrl, hardTypeWeb } from "../../../services/urlConstant";
import "./Otp.css";
import Timer from "./Timer";

export default function Otp() {
  let history = useHistory();
  const { state } = useLocation();

  const refTwo = useRef(null);
  const refThree = useRef(null);
  const refFour = useRef(null);
  const refFive = useRef(null);
  const refSix = useRef(null);

  const message = useSelector(state => state.auth.message);
  const [inOne, setInOne] = useState("");
  const [inTwo, setInTwo] = useState("");
  const [inThree, setInThree] = useState("");
  const [inFour, setInFour] = useState("");
  const [inFive, setInFive] = useState("");
  const [inSix, setInSix] = useState("");

  const [focusOne] = useState(true);
  const [resendBtn, setResendBtn] = useState(false);
  const [disableResend, setDisableResend] = useState(true);
  const [time, setTime] = useState(120);

  const [loginDisabled, setLoginDisabled] = useState(false);
  useEffect(() => {
    if (message !== null) {
      window.location.reload();
    }
  }, [message]);

  const handleLogin = async () => {
    if (
      inOne !== "" &&
      inTwo !== "" &&
      inThree !== "" &&
      inFour !== "" &&
      inFive !== "" &&
      inSix !== ""
    ) {
      let joinNumber = inOne + inTwo + inThree + inFour + inFive + inSix;
      let payload = {
        otp: joinNumber,
      };
      try {
        let resultLogin = await Axios.post(
          hardBaseUrl + "/auth/verify-otp",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        history.push("/redirect/" + resultLogin.data.data.access_token);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: err.response.data.errors[0].message,
          showCloseButton: true,
        }).then(res => {
          if (res.isConfirmed) {
            setInOne("");
            setInTwo("");
            setInThree("");
            setInFour("");
            setInFive("");
            setInSix("");
            setLoginDisabled(false);
          }
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "Masukkan OTP.",
        showCloseButton: true,
      });
    }
  };

  const getLogo = () => {
    if (hardTypeWeb === "mpis") {
      return (
        <img
          src={logoMpis}
          alt="logo"
          className="logo"
          width={"200px"}
          style={{
            marginBottom: "50px",
          }}
        />
      );
    } else {
      return (
        <img
          src={CARIS}
          alt=""
          width="265px"
          style={{
            marginBottom: "50px",
          }}
        />
      );
    }
  };

  const onFillOne = async e => {
    setInOne(e);

    refTwo.current.focus();
  };
  const onFillTwo = e => {
    setInTwo(e);
    refThree.current.focus();
  };
  const onFillThree = e => {
    setInThree(e);
    refFour.current.focus();
  };
  const onFillFour = e => {
    setInFour(e);
    refFive.current.focus();
  };
  const onFillFive = e => {
    setInFive(e);
    refSix.current.focus();
  };
  const onFillSix = e => {
    setInSix(e);
    setLoginDisabled(true);
  };

  useEffect(() => {
    if (
      inOne !== "" &&
      inTwo !== "" &&
      inThree !== "" &&
      inFour !== "" &&
      inFive !== "" &&
      inSix !== ""
    ) {
      handleLogin();
    }
  }, [inOne, inTwo, inThree, inFour, inFive, inSix]);
  const onResend = async () => {
    setResendBtn(!resendBtn);
    try {
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "OTP has been sent to your email.",
        showCloseButton: true,
      }).then(() => {
        window.location.reload();
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: err.response.data.errors[0].message,
        showCloseButton: true,
      }).then(res => {
        if (res.isConfirmed) {
          window.location.reload();
        }
      });
    }
  };

  const onTimeEnd = () => {
    setTime(0);
    setDisableResend(false);
  };
  return (
    <LayoutNoHeader>
      <div className="login">
        <div className="content">
          <div className="header">{getLogo()}</div>
          <div
            style={{
              border: "0px solid #687083",
              boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
              borderRadius: "6px",
              padding: "30px",
            }}
          >
            <h1
              style={{
                fontSize: "30px",
                marginTop: "5px",
              }}
            >
              {"Confirmation"}
            </h1>
            <h3 style={{ color: "#687083" }}>
              {"Enter the OTP code that has been sent to email " + state.email}
            </h3>

            <form onSubmit={handleLogin}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Input
                  onChange={e => onFillOne(e.target.value)}
                  value={inOne}
                  placeholder=""
                  data
                  data-testid="data-test-input-otp-1"
                  maxLength={1}
                  autoFocus={focusOne}
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "5px",
                    marginTop: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "24px",
                    textAlign: "center",
                  }}
                  disabled={loginDisabled}
                ></Input>

                <Input
                  ref={refTwo}
                  onChange={e => onFillTwo(e.target.value)}
                  value={inTwo}
                  data-testid="data-test-input-otp-2"
                  placeholder=""
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "5px",
                    marginTop: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "24px",
                    textAlign: "center",
                  }}
                  disabled={loginDisabled}
                ></Input>

                <Input
                  ref={refThree}
                  onChange={e => onFillThree(e.target.value)}
                  value={inThree}
                  data-testid="data-test-input-otp-3"
                  placeholder=""
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "5px",
                    marginTop: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "24px",
                    textAlign: "center",
                  }}
                  disabled={loginDisabled}
                ></Input>

                <Input
                  ref={refFour}
                  onChange={e => onFillFour(e.target.value)}
                  value={inFour}
                  data-testid="data-test-input-otp-4"
                  placeholder=""
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "5px",
                    marginTop: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "24px",
                    textAlign: "center",
                  }}
                  disabled={loginDisabled}
                ></Input>

                <Input
                  ref={refFive}
                  onChange={e => onFillFive(e.target.value)}
                  value={inFive}
                  data-testid="data-test-input-otp-5"
                  placeholder=""
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "5px",
                    marginTop: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "24px",
                    textAlign: "center",
                  }}
                  disabled={loginDisabled}
                ></Input>

                <Input
                  ref={refSix}
                  onChange={e => onFillSix(e.target.value)}
                  value={inSix}
                  data-testid="data-test-input-otp-6"
                  placeholder=""
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "5px",
                    marginTop: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "24px",
                    textAlign: "center",
                  }}
                  disabled={loginDisabled}
                ></Input>
              </div>

              {loginDisabled ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <h3></h3>
                  </div>
                </>
              ) : (
                <>{""}</>
              )}

              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Timer time={time} onTimeEnd={() => onTimeEnd()} />

                { }
                { }
                <div></div>
                <button
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    cursor: disableResend ? "not-allowed" : "pointer",
                  }}
                  onClick={() => onResend()}
                  disabled={disableResend}
                >
                  Resend
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </LayoutNoHeader>
  );
}
