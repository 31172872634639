import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Description, Visibility } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { DateTimeDisplay, Page } from "components";
import { wordCapitalize } from "utils";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    padding: "10px 28px 10px 28px",
    backgroundColor: "black",
    color: "white",
    width: "213px",
    height: "40px",
    borderRadius: "6px",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  selectPadding: {
    height: "80px",
    "& .MuiInputBase-fullWidth": {
      height: "55px",
    },
  },
  buttonDetail: {
    backgroundColor: "black",
    minWidth: "0px",
  },
  icon: {
    color: "white",
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",
    marginRight: "8px",
    position: "relative",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
  },
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#E4E7EB",
    borderRadius: "25px",
    border: "0px solid #606060",
    height: "auto",
    opacity: 1,
  },
}));

const DetailAnnouncementManagement = () => {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const theToken = localStorage.getItem("token");
  const [state, setState] = useState({
    detailAnnouncement: {},
    loading: false,
  });

  const toggleLoading = () =>
    setState(({ loading, ...state }) => ({
      ...state,
      loading: !loading,
    }));

  const getDetail = async id => {
    const url = `${hardBaseUrl}/admin/annoucement/detail/${id}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${theToken}`,
      },
    };
    toggleLoading();
    try {
      const response = await axios.get(url, headers);
      setState(state => ({
        ...state,
        detailAnnouncement: response.data.data || {},
        loading: false,
      }));
    } catch (err) {
      toggleLoading();
      new Error(err);
    }
  };

  useEffect(() => {
    const idFromParamUrl = parseInt(id);
    getDetail(idFromParamUrl);
  }, []);
  const { detailAnnouncement } = state;
  const isEmail = detailAnnouncement.type === "email";
  const files = detailAnnouncement?.announcement_files;
  return (
    <Page className={classes.root} title="Detail Announcement">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Detail Announcement
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>
                Administration
              </Typography>

              <Typography className={classes.breadCrumbs}>
                Announcement Management
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Detail Announcement
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />
        <Grid container direction="column">
          <Box sx={{ marginBottom: "24px" }} gridGap={10}>
            <Typography
              style={{
                fontSize: 13,
                margin: "16px 0px 5px",
              }}
            >
              Announcement Management Type
            </Typography>
            <Typography>
              {detailAnnouncement?.type === "email"
                ? "Email Announcement"
                : "Announcement Website Content"}
            </Typography>
          </Box>

          <Grid
            container
            style={{
              border: "1px solid #D1D5DC",
              padding: "16px",
              rowGap: "24px",
              borderRadius: "8px",
            }}
          >
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <Box>
                <Typography style={{ marginBottom: 8 }} variant="subtitle1">
                  {isEmail ? "Email Delivery Time" : "Start Date"}
                </Typography>
                <DateTimeDisplay
                  date={
                    isEmail
                      ? detailAnnouncement?.email_delivery_time
                      : detailAnnouncement?.web_start_date
                  }
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <Typography style={{ marginBottom: 8 }} variant="subtitle1">
                {isEmail ? "Recipient" : "End Date"}
              </Typography>
              <Typography>
                {isEmail ? (
                  wordCapitalize(detailAnnouncement.email_recipient)
                ) : (
                  <DateTimeDisplay date={detailAnnouncement?.web_end_date} />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <Box display="flex" flexDirection="column">
                <Typography style={{ marginBottom: 8 }} variant="subtitle1">
                  Subject
                </Typography>
                <Typography>{detailAnnouncement?.subject}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
              <Box display="flex" flexDirection="column" gridColumnGap={16}>
                <Typography style={{ marginBottom: 8 }} variant="subtitle1">
                  Upload File / Image
                </Typography>
                {(files || []).map(({ file_name, file_path, size }, id) => (
                  <Grid
                    key={id}
                    container
                    justifyContent="space-between"
                    style={{ marginBottom: "0.5rem" }}
                    spacing={2}
                  >
                    <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
                      <Box display="flex" alignItems="center" gridGap={5}>
                        <Description />
                        <Box
                          display="flex"
                          flexDirection="column"
                          textOverflow="ellipsis"
                          style={{ wordBreak: "break-all" }}
                        >
                          <Typography>{file_name}</Typography>
                          <Typography
                            fontSize="12px"
                            style={{ color: "#687083" }}
                          >
                            {size}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                      <Button
                        onClick={() => window.open(file_path)}
                        className={classes.buttonDetail}
                      >
                        <Visibility className={classes.icon} />
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <Box display="flex" flexDirection="column" gridRowGap={"10px"}>
                <Typography variant="subtitle1">Email Content</Typography>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: detailAnnouncement?.content,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Button
            style={{
              borderRadius: "6px",
              width: "129px",
              height: "40px",
              border: "1px solid #D1D5DC",
              textTransform: "none",
              marginTop: 10,
              alignSelf: "flex-end",
            }}
            onClick={() => history.goBack()}
          >
            Close
          </Button>
        </Grid>
      </Container>
    </Page>
  );
};

export default DetailAnnouncementManagement;
