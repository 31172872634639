import {
  Breadcrumbs,
  Container,
  Divider,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
  makeStyles,
} from "@material-ui/core";
import { useState } from "react";
import { ButtonGroupTop } from "../../../components/atoms/Button";
import { Page, SkeletonComponent } from "components";
import ComponentUsageProcess from "./ComponentUsageProcess";
import ComponentUsageProcessMonitoring from "./ComponentUsageProcessMonitoring";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  content: {
    paddingTop: 15,
  },
}));
const listMenu = [
  {
    id: 1,
    title: "Usage Process",
    selected: true,
  },
  {
    id: 2,
    title: "Usage Process Monitoring",
    selected: false,
  },
];

function UsageProcess() {
  const classes = useStyles();
  const [selectedPage, setSelectedPage] = useState(1);
  return (
    <Page className={classes.root} title="Usage Process">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                DSP Usage Process
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>
              <Typography className={classes.breadCrumbsActive}>
                DSP Usage Process
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <ButtonGroupTop
          items={listMenu}
          selectedPage={selectedPage}
          onSelected={value => {
            setSelectedPage(value);
          }}
        />
        <Grid container className={classes.content}>
          <Grid item xs={12}>
            {selectedPage === 1 ? (
              <ComponentUsageProcess />
            ) : (
              <ComponentUsageProcessMonitoring />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
export default UsageProcess;
