import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Box,
  Select,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { Page, SkeletonComponent, PrimaryButton } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import Results from "./Results";
import { topMenuButtonMPIS, topMenuButtonCaris } from "constants";
import { HeaderTitle } from "layouts";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function Pembayaran() {
  const history = useHistory();
  const publisherId = useSelector(state => state.publisherId);
  const [loadingPageSkeleton, setLoadingPageSkeleton] = useState(false);
  const typeWeb = localStorage.getItem("typeWeb");

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [response, setResponse] = useState([]);
  const [page, setPage] = useState(1);
  const [bank, setBank] = useState("");
  const [rekening, setRekening] = useState(null);
  const [nama, setNama] = useState("");
  const [id, setId] = useState(null);
  const [getColor, setGetColor] = useState("");
  const [listOfBank, setListOfBank] = useState([]);
  const handleOpen = () => {
    setOpen(true);
    setNama("");
    setRekening("");
    setBank("");
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
    setNama("");
    setRekening("");
    setBank("");
  };

  const handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "bank") {
      setBank(value);
    } else if (name === "rekening") {
      setRekening(value);
    } else if (name === "nama") {
      setNama(value);
    }
  };

  const handleChangeSelectBank = e => setBank(e.target.value);

  const handleOnSelectedButton = value =>
    history.push(value.data[value.newButtonSelected].link);

  useEffect(() => {
    const getDataListBank = async () => {
      try {
        const theToken = localStorage.getItem("token");
        const url = `${hardBaseUrl}/publisher/composer/bank`;
        let resListBank = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${theToken}`,
          },
        });
        setListOfBank(resListBank.data.data);
      } catch (err) {
        new Error(err);
      }
    };
    getDataListBank();
  }, []);

  const getResponse = () => {
    try {
      setLoadingPageSkeleton(true);
      const theToken = localStorage.getItem("token");
      const url = `${hardBaseUrl}/publisher/bank`;
      axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${theToken}`,
          },
        })
        .then(res => {
          setResponse(res.data.data);
          setPage(res.data.meta.page);
          setLoadingPageSkeleton(false);
        });
    } catch (err) {
      setLoadingPageSkeleton(false);
      new Error(err);
    }
  };

  const createBank = e => {
    e.preventDefault();
    const url = `${hardBaseUrl}/publisher/bank`;
    const data = {
      bank_id: bank,
      publisher_id: publisherId.publisherId,
      account_number: rekening,
      account_name: nama,
    };

    let theToken = localStorage.getItem("token");
    axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Data berhasil ditambahkan",
        });
        getResponse();
        handleClose();
      })
      .catch(error => {
        new Error(error);
      });
  };

  const handleDelete = id => {
    const url = `${hardBaseUrl}/publisher/bank/${id}`;
    let theToken = localStorage.getItem("token");
    axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        if (res.data.message === "success") {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Data berhasil dihapus",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        }
      })
      .catch(error => {
        new Error(error);
      });
  };

  const handleEditMode = (e, id, item) => {
    e.preventDefault();
    setId(id);
    setBank(item.bank_id);
    setRekening(item.account_number);
    setNama(item.account_name);
    handleOpenEdit();
  };

  const handleUpdate = e => {
    e.preventDefault();
    let theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/bank/${id}`;
    const data = {
      bank_id: bank,
      publisher_id: publisherId.publisherId,
      account_number: rekening,
      account_name: nama,
    };
    axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Data berhasil diubah",
        });
        getResponse();
        handleCloseEdit();
      })
      .catch(error => {
        new Error(error);
      });
  };

  const getMainDsp = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/me`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setGetColor(res.data.data.publisher.theme_color);
    } catch (e) {
      new Error(e);
    }
  };

  useEffect(() => {
    getMainDsp();
    getResponse();
  }, [page]);

  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    divider: {
      margin: theme.spacing(2, 0),
      borderTop: "1px solid #e1e1e1",
    },
    results: {
      marginTop: theme.spacing(3),
    },
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    button: {
      padding: "10px 28px 10px 28px",
      backgroundColor: "black",
      color: "white",
      width: "213px",
      height: "40px",
      borderRadius: "6px",
      textTransform: "none",
      whiteSpace: "nowrap",
      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    subtitle: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "28px",
    },
    breadcrumbs: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#111827",
    },
    breadCrumbsActive: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#687083",
    },
    font24: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "32px",
    },
    cardParent: {
      border: "1px solid #D1D5DC",
      borderRadius: "6px",
      boxShadow: "none",
    },

    adorment: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      height: 55,
      width: 110,
      marginLeft: 10,
      border: "0px solid green",
      paddingRight: "20px",
      marginRight: "10px",
      color: "#9AA2B1",
    },

    adorment2: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      backgroundColor: "#F9FAFB",
      height: 40,
      width: 50,
      borderTopLeftRadius: "5px",
      borderBottomLeftRadius: "5px",
    },
    images: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      padding: "5px",
    },
    textStyle: {
      marginTop: "5px",
      color: "#8f8f8f",
      fontSize: "14px",
    },
    colorWhite: {
      color: "white",
    },
    typgraphBtn: {
      backgroundColor: getColor,
      color: "white",
      textTransform: "none",
    },
    btnSave: {
      backgroundColor: getColor,
      color: "white",
      textTransform: "none",
      marginRight: "16px",
    },
  }));
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Payment">
      {loadingPageSkeleton ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Configuration" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop
            items={
              typeWeb === "mpis" ? topMenuButtonMPIS() : topMenuButtonCaris
            }
            selectedButton={handleOnSelectedButton}
            selectedIndex={0}
          />
          <Divider className={classes.divider} />
          <Grid container>
            <Grid item xs={4} md={12} sm={4} lg={12} xl={12}>
              <ThemeProvider theme={theme}>
                <Typography variant="h3" className={classes.subtitle}>
                  Bank Account
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p className={classes.textStyle}>
                  Add bank account data to use for payment options in
                  composer/author
                </p>
              </ThemeProvider>
              <Box margin="5px 0px" justifyContent="flex-end" display="flex">
                <PrimaryButton label="Add Bank Account" onClick={handleOpen} />
              </Box>
              <Box margin="10px 0px">
                {response && (
                  <Results
                    className={classes.results}
                    items={response}
                    handleDelete={handleDelete}
                    handleEditMode={handleEditMode}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={8} md={8} sm={8} lg={4} xl={4}>
              <Box margin="10px 0px">
                <Box display="flex" justifyContent="center">
                  <Dialog
                    fullWidth={true}
                    maxWidth="xs"
                    open={openEdit}
                    onClose={handleCloseEdit}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <form onSubmit={handleUpdate}>
                      <DialogTitle id="responsive-dialog-title">
                        Edit Bank Account
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>Bank *</DialogContentText>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth={true}
                        >
                          <InputLabel
                            id="demo-simple-select-outlined-label"
                            shrink={false}
                          >
                            {bank !== null ? null : "Bank"}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={bank}
                            onChange={handleChangeSelectBank}
                            autoWidth={true}
                            fullWidth={true}
                            required
                          >
                            <MenuItem value="">Choose Bank</MenuItem>
                            {listOfBank.map((item, index) => {
                              return (
                                <MenuItem
                                  key={`${item.bank_id}-${index}`}
                                  value={item.bank_id}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </DialogContent>
                      <DialogContent>
                        <DialogContentText>Account Number *</DialogContentText>
                        <FormControl
                          fullWidth
                          className={classes.margin}
                          variant="outlined"
                          required
                        >
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            onChange={handleChange}
                            name="rekening"
                            type="number"
                            value={rekening}
                            fullWidth={false}
                          />
                        </FormControl>
                      </DialogContent>
                      <DialogContent>
                        <DialogContentText>Name *</DialogContentText>
                        <FormControl
                          fullWidth
                          className={classes.margin}
                          variant="outlined"
                          required
                        >
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            onChange={handleChange}
                            name="nama"
                            value={nama}
                            fullWidth={true}
                          />
                        </FormControl>
                      </DialogContent>
                      <DialogActions>
                        <PrimaryButton
                          label="Cancel"
                          onClick={handleCloseEdit}
                        />
                        <PrimaryButton label="Update" onClick={handleUpdate} />
                      </DialogActions>
                    </form>
                  </Dialog>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <form onSubmit={createBank}>
            <DialogTitle id="responsive-dialog-title">
              Add Bank Account
            </DialogTitle>
            <DialogContent>
              <DialogContentText>Bank *</DialogContentText>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth={true}
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  shrink={false}
                >
                  {bank !== null ? null : "Bank"}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={bank}
                  onChange={handleChangeSelectBank}
                  autoWidth={true}
                  fullWidth={true}
                  required
                >
                  <MenuItem value="">Choose Bank</MenuItem>
                  {listOfBank.map((item, index) => {
                    return (
                      <MenuItem
                        key={`${item.bank_id}-${index}`}
                        value={item.bank_id}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogContent>
              <DialogContentText>Account Number *</DialogContentText>
              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
                required
              >
                <OutlinedInput
                  id="outlined-adornment-amount"
                  onChange={handleChange}
                  name="rekening"
                  type="number"
                  value={rekening}
                  fullWidth={false}
                />
              </FormControl>
            </DialogContent>
            <DialogContent>
              <DialogContentText>Name *</DialogContentText>
              <FormControl
                fullWidth
                className={classes.margin}
                variant="outlined"
                required
              >
                <OutlinedInput
                  id="outlined-adornment-amount"
                  onChange={handleChange}
                  name="nama"
                  value={nama}
                  fullWidth={true}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <PrimaryButton label="Cancel" onClick={handleClose} />
              <PrimaryButton label="Save" onClick={createBank} />
            </DialogActions>
          </form>
        </Dialog>
      </div>
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Configuration",
    link: "/admin/konfigurasi/pembayaran",
  },
  {
    label: "Payment",
    active: true,
  },
];
export default Pembayaran;
