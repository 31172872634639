import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import CustomModal from "../../../../components/molecules/Modal/CustomModal";
import { Page } from "components";

import { hardBaseUrl } from "../../../../services/urlConstant";

import CloseIcon from "@material-ui/icons/Close";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import html2canvas from "html2canvas";
import { InnoImage } from "inno-ui";
import { jsPDF } from "jspdf";
import NumberFormat from "react-number-format";
import TrashVectorGrey from "../../../../assets/img/trashVectorGrey.svg";
import { formatFileSize } from "../../../../utils";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  buttonBlack: {
    backgroundColor: " #111827",
    color: "white",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    borderRadius: "6px",
    textTransform: "none",
    marginLeft: "16px",
    "&:hover, &:focus": {
      backgroundColor: " #111827",
    },
  },
  buttonWhite: {
    backgroundColor: "white",
    color: "black",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    borderRadius: "6px",
    border: "1px solid",
    textTransform: "none",
  },
  buttonGray: {
    backgroundColor: "gray",
    color: "white",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    borderRadius: "6px",
    textTransform: "none",
    marginLeft: "16px",
    "&:hover, &:focus": {
      backgroundColor: "gray",
    },
  },
}));

const DetailPayment = () => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const [state, setState] = useState({});
  const [, setPaymentTransactionDetail] = useState(null);
  const { id } = useParams();
  const [approveModal, setApproveModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [reject, setReject] = useState(false);
  const [modalPayment, setModalPayment] = useState(false);
  const [files, setFiles] = useState([]);
  const [dataProfile, setDataProfile] = useState([]);
  const [logo, setLogo] = useState("");
  const [costAllocationValue, setCostAllocationValue] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [submiting, setSubmiting] = useState(false);
  const [dataTransaction, setDataTransaction] = useState([]);

  const getDataDetail = () => {
    const token = localStorage.getItem("token");

    const url = `${hardBaseUrl}/publisher/transaction/detail/${id}`;
    axios
      .get(hardBaseUrl + "/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setDataProfile(response.data.data);

        setLogo(response.data.data.publisher.profile_image);
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(response => {
            setState(response.data.data);
            setPaymentTransactionDetail(
              response.data.data.transaction_payment_detail
            );
            setCostAllocationValue(response.data.data.cost_imposition);
            setTaxes(response.data.data.transaction_payment_detail.taxes);
            const listTransaction = response?.data?.data?.usage_transactions;
            const transformedData = listTransaction.flatMap(item =>
              item.songs.map(song => ({
                transaction_id: item.transaction_id,
                transaction_number: item.transaction_number,
                client_name: item.client_name,
                ...song,
              }))
            );

            setDataTransaction(transformedData);
          })
          .catch(err => {
            new Error(err);
          });
      })
      .catch(err => {
        new Error(err);
      });
  };

  const updateStatus = ({ reject }) => {
    const token = localStorage.getItem("token");
    setSubmiting(true);
    const formData = new FormData();
    formData.append("file_name", "");
    formData.append("status", reject ? "rejected" : "approved");
    const url = `${hardBaseUrl}/transaction/proof/${id}`;
    axios
      .put(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setApproveModal(false);
        Swal.fire({
          icon: response?.data?.message === "success" ? "success" : "error",
          title: response?.data?.message === "success" ? "Success" : "Error",
          text: response?.data?.message,
        }).then(() => (window.location.href = "/finance/payment"));
      })
      .catch(err => {
        setSubmiting(false);
        new Error(err);
      });
  };

  useEffect(() => {
    getDataDetail();
  }, []);

  const handleFiles = e => {
    const data = [];
    Array.from(e.target.files).forEach(file => {
      data.push(file);
    });
    setFiles(data);
  };

  const handleClickFiles = e => {
    const { target = {} } = e || {};
    target.value = "";
  };

  const handleUpload = () => {
    const token = localStorage.getItem("token");
    setSubmiting(true);
    const formData = new FormData();
    formData.append("status", "paid");
    files.map(val => {
      return formData.append("file_name", val);
    });
    const url = `${hardBaseUrl}/transaction/proof/${id}`;
    axios
      .put(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setModalPayment(false);
        Swal.fire({
          icon: response?.data?.message === "success" ? "success" : "error",
          title: response?.data?.message === "success" ? "Success" : "Error",
          text: response?.data?.message,
        }).then(() => (window.location.href = "/finance/payment"));
      })
      .catch(err => {
        setSubmiting(false);
        new Error(err);
      });
  };

  const handlePrintPDF = () => {
    html2canvas(inputRef.current, { useCORS: true }).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "px", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  const renderMoney = item => {
    return (
      <NumberFormat
        displayType="text"
        value={item}
        thousandSeparator={true}
        decimalScale={2}
        fixedDecimalScale={true}
        prefix="Rp "
      />
    );
  };

  const renderPercentage = item => {
    return <>{item} %</>;
  };
  const columnTable = [
    {
      name: "client_name",
      title: "Client",
    },
    {
      name: "title",
      title: "Song",
    },
    {
      name: "publisher_percentage",
      title: "Publisher Percentage",
      renderText: "percentage",
    },
    {
      name: "fee",
      title: "Fee",
      renderText: "money",
    },
    {
      name: "value",
      title: "Value",
      renderText: "money",
    },
    {
      name: "description",
      title: "Description",
    },
  ];

  return (
    <Page className={classes.root} title="Payment Detail">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Payment Transaction
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Detail Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Grid container>
              <div>
                <ThemeProvider theme={theme}>
                  <Typography
                    component="h1"
                    variant="h3"
                    className={classes.subTitle}
                  >
                    Payment Detail
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    This is detail credit note information, in advance
                    transaction
                  </p>
                </ThemeProvider>
              </div>
              <Grid
                style={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "flex-end",
                  flex: 1,
                }}
                item
              >
                <CustomChip status={state?.status} />
              </Grid>
            </Grid>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
              ref={inputRef}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    {logo && (
                      <InnoImage
                        src={logo}
                        alt="img"
                        styleImage={{
                          width: "155.52px",
                          height: "50px",
                        }}
                        styleImageContainer={{
                          boxShadow: "none",
                          padding: 0,
                        }}
                        fill={true}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <div>
                        <ThemeProvider theme={theme}>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "20px",
                            }}
                          >
                            {state?.publisher?.name}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "17px",
                              color: "#6F6F84",
                              wordWrap: "break-word",
                            }}
                          >
                            {state?.publisher?.address}
                          </Typography>
                        </ThemeProvider>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Divider className={classes.divider} />

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "16.94px",
                        }}
                      >
                        Payment To:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "16.94px",
                        }}
                      >
                        {state?.composer_name}
                      </Typography>
                    </ThemeProvider>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Date :
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          {moment(state?.invoice_date).format("DD MMM YYYY")}
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Composer/Author :
                        </Typography>

                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          {state?.composer_name}
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Advance Value :
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          {state?.composer_advance?.total_format}
                        </Typography>
                      </div>
                    </ThemeProvider>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />

                {state?.type === "usage" ? (
                  <Card
                    style={{
                      border: "1px solid #9AA2B1",
                      borderRadius: "6px",
                      boxShadow: "none",
                      marginTop: "32px",
                    }}
                  >
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <ThemeProvider theme={theme}>
                            {columnTable?.map(column => (
                              <TableCell key={column?.name}>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  {column?.title}
                                </Typography>
                              </TableCell>
                            ))}
                          </ThemeProvider>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataTransaction?.length > 0 ? (
                          dataTransaction?.map((item, index) => (
                            <TableRow key={index}>
                              {columnTable?.map(column => (
                                <TableCell key={column?.name}>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.headText}
                                  >
                                    {column.renderText === "percentage"
                                      ? renderPercentage(item?.[column?.name])
                                      : column.renderText === "money"
                                      ? renderMoney(item?.[column?.name])
                                      : item?.[column?.name] || "-"}
                                  </Typography>
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={12}>
                              <center>
                                <ThemeProvider theme={theme}>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      fontWeight: 400,
                                      color: "#687083",
                                      fontSize: "14px",
                                    }}
                                  >
                                    No Data
                                  </Typography>
                                </ThemeProvider>
                              </center>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Card>
                ) : (
                  <Grid container>
                    <div>
                      <ThemeProvider theme={theme}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          Notes :
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      {" " + state?.note}
                    </div>
                  </Grid>
                )}

                <div
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <div>
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Period :{" "}
                        {state.period !== "" ? <>{state.period}</> : <>{"-"}</>}
                      </Typography>
                    </ThemeProvider>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderRadius: "10px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #e1e1e1",
                        paddingBottom: "20px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        Value
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          marginRight: "10px",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        <NumberFormat
                          displayType="text"
                          value={`${state?.revenue}`}
                          thousandSeparator={true}
                          decimalScale={2}
                          prefix="Rp "
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        borderBottom: "0px solid #e1e1e1",
                      }}
                    >
                      {costAllocationValue?.length > 0
                        ? costAllocationValue.map(item => (
                            <>
                              <Grid container>
                                <Grid item xs={4} md={4} lg={4} xl={4}>
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {item.fee_type_name}
                                  </div>
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      marginLeft: "10px",
                                      color: "#9b9b9b",
                                    }}
                                  >
                                    {item.additional_description !==
                                      undefined ||
                                    item.additional_description !== ""
                                      ? item.additional_description
                                      : null}
                                  </div>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4} xl={4}></Grid>
                                <Grid item xs={4} md={4} lg={4} xl={4}>
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      marginRight: "10px",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      textAlign: "right",
                                    }}
                                  >
                                    {item.value_cost !== undefined
                                      ? formatter.format(item.value_cost) + ",-"
                                      : null}
                                  </div>
                                </Grid>
                              </Grid>
                            </>
                          ))
                        : null}

                      {state.transaction_payment_detail?.cost_allocation
                        .cost_allocation_value !== "" &&
                        state.transaction_payment_detail?.cost_allocation
                          .cost_allocation_percentage !== "" && (
                          <Grid container>
                            <Grid item xs={4} md={4} lg={4} xl={4}>
                              <div
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "10px",
                                }}
                              >
                                Cost Allocation
                              </div>
                            </Grid>
                            <Grid item xs={4} md={4} lg={4} xl={4}>
                              <div
                                style={{
                                  marginTop: "10px",
                                  textAlign: "center",
                                }}
                              >
                                {state.transaction_payment_detail
                                  ?.cost_allocation
                                  .cost_allocation_percentage !== "" ? (
                                  <>{`${state.transaction_payment_detail?.cost_allocation.cost_allocation_percentage}`}</>
                                ) : (
                                  <>{"-"}</>
                                )}
                              </div>
                            </Grid>
                            <Grid item xs={4} md={4} lg={4} xl={4}>
                              <div
                                style={{
                                  marginTop: "10px",
                                  marginRight: "10px",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textAlign: "right",
                                }}
                              >
                                {state.transaction_payment_detail
                                  ?.cost_allocation.cost_allocation_value !==
                                "" ? (
                                  <>
                                    {
                                      state.transaction_payment_detail
                                        ?.cost_allocation.cost_allocation_value
                                    }
                                  </>
                                ) : (
                                  <>{"-"}</>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        )}
                    </div>

                    {state.transaction_payment_detail?.agent_fee?.length > 0 ? (
                      <>
                        {state.transaction_payment_detail?.agent_fee.map(
                          (customer, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  borderBottom: "0px solid #e1e1e1",
                                  paddingBottom: "20px",
                                }}
                              >
                                <Grid container>
                                  <Grid item xs={4} md={4} lg={4} xl={4}>
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      Agent Fee
                                    </div>
                                  </Grid>
                                  <Grid item xs={4} md={4} lg={4} xl={4}>
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {customer.agent_percentage}
                                    </div>
                                  </Grid>
                                  <Grid item xs={4} md={4} lg={4} xl={4}>
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        marginRight: "10px",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        textAlign: "right",
                                      }}
                                    >
                                      {customer.agent_value}
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            );
                          }
                        )}
                      </>
                    ) : (
                      <>{""}</>
                    )}

                    { }
                    <div
                      style={{
                        display: "block",
                        borderBottom: "1px solid #e1e1e1",
                        paddingBottom: "20px",
                      }}
                    >
                      {taxes !== null && taxes?.length > 0 ? (
                        <>
                          {taxes.map(tax => (
                            <div
                              key={`${tax.id}`}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Grid container>
                                <Grid item xs={3} md={3} lg={3} xl={3}>
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {`${tax.code}`}
                                  </div>
                                </Grid>
                                <Grid item xs={3} md={3} lg={3} xl={3}>
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      textAlign: "right",
                                    }}
                                  >
                                    {`${tax.rate}% `}
                                  </div>
                                </Grid>
                                <Grid item xs={3} md={3} lg={3} xl={3}>
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      textAlign: "left",
                                    }}
                                  >
                                    &nbsp;{`(${tax.method})`}
                                  </div>
                                </Grid>
                                <Grid item xs={3} md={3} lg={3} xl={3}>
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      marginRight: "10px",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      textAlign: "right",
                                    }}
                                  >
                                    <NumberFormat
                                      displayType="text"
                                      value={`${tax.value}`}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      prefix="Rp "
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          ))}
                        </>
                      ) : null}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "0px solid #e1e1e1",
                        paddingBottom: "20px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        Total Value
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          marginRight: "10px",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        <NumberFormat
                          displayType="text"
                          value={`${state.transaction_payment_detail?.total_revenue_numeric}`}
                          thousandSeparator={true}
                          decimalScale={2}
                          prefix="Rp "
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    marginTop: "20px",
                    display: "none",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "right",
                      }}
                    >
                      Value :
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        textAlign: "right",
                      }}
                    >
                      Rp{state?.revenue},-
                    </Typography>
                  </ThemeProvider>
                </div>
              </CardContent>
            </Card>
            {dataProfile?.role?.roles_for === "finance" && (
              <Grid
                container
                justifyContent="flex-end"
                style={{ marginTop: "24px", marginBottom: "24px" }}
              >
                {state?.status === "waiting_for_approval" ? (
                  <>
                    <Button
                      onClick={() => {
                        setApproveModal(true);
                        setReject(true);
                      }}
                      className={classes.buttonWhite}
                    >
                      Reject
                    </Button>
                    <Button
                      onClick={() => setApproveModal(true)}
                      disabled={submiting}
                      className={
                        submiting ? classes.buttonGray : classes.buttonBlack
                      }
                    >
                      Approve
                    </Button>
                  </>
                ) : state?.status === "approved" ? (
                  <>
                    <Button
                      onClick={() => setConfirmModal(true)}
                      disabled={submiting}
                      className={
                        submiting ? classes.buttonGray : classes.buttonBlack
                      }
                    >
                      Confirm
                    </Button>
                  </>
                ) : state?.status === "unpaid" ? (
                  <>
                    <Button
                      onClick={() => {
                        setApproveModal(true);
                        setReject(true);
                      }}
                      className={classes.buttonWhite}
                    >
                      Reject
                    </Button>
                    <Button
                      onClick={() => setConfirmModal(true)}
                      disabled={submiting}
                      className={
                        submiting ? classes.buttonGray : classes.buttonBlack
                      }
                    >
                      Confirm
                    </Button>
                  </>
                ) : null}
              </Grid>
            )}

            {state?.status === "paid" ? (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#111827",
                    color: "white",
                    padding: "10px 28px 10px 28px",
                    borderRadius: "6px",
                  }}
                  variant="contained"
                  size="large"
                  onClick={handlePrintPDF}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      component="span"
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "500",
                        fontStyle: "normal",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Download As PDF
                    </Typography>
                  </ThemeProvider>
                </Button>
              </div>
            ) : null}
          </Grid>
        </Grid>
      </Container>
      {approveModal && (
        <CustomModal
          open={approveModal}
          handleClose={() => {
            setApproveModal(false);
            setReject(false);
          }}
          width="460px"
        >
          <h3
            style={{
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "24px",
              marginBottom: "16px",
            }}
          >
            {reject ? "Reject Payment Request" : "Approve Payment Request"}
          </h3>
          <p
            style={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              marginBottom: "16px",
            }}
          >
            {reject
              ? "Are You sure to approve this payment request??"
              : "Are You sure to confirm this payment ?"}
          </p>
          <Grid container justifyContent="flex-end">
            <Button
              onClick={() => {
                setApproveModal(false);
                setReject(false);
              }}
              className={classes.buttonWhite}
            >
              Cancel
            </Button>
            <Button
              onClick={() => updateStatus({ reject: reject })}
              className={submiting ? classes.buttonGray : classes.buttonBlack}
              disabled={submiting}
            >
              {reject ? "Reject" : "Approve"}
            </Button>
          </Grid>
        </CustomModal>
      )}
      {confirmModal && (
        <CustomModal
          open={confirmModal}
          handleClose={() => {
            setConfirmModal(false);
          }}
          width="460px"
        >
          <h3
            style={{
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "24px",
              marginBottom: "16px",
            }}
          >
            Confirm Payment
          </h3>
          <p
            style={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              marginBottom: "16px",
            }}
          >
            Are You sure to confirm this payment ?
          </p>
          <Grid container justifyContent="flex-end">
            <Button
              onClick={() => {
                setConfirmModal(false);
              }}
              className={classes.buttonWhite}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setConfirmModal(false);
                setModalPayment(true);
              }}
              className={classes.buttonBlack}
            >
              Confirm
            </Button>
          </Grid>
        </CustomModal>
      )}
      {modalPayment && (
        <CustomModal
          open={modalPayment}
          handleClose={() => {
            setModalPayment(false);
          }}
          width="460px"
          noPadding
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #E4E7EB",
              padding: "20px",
            }}
          >
            <h1
              style={{
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              Upload Payment Proof
            </h1>
            <IconButton
              style={{ padding: 0 }}
              onClick={() => setModalPayment(false)}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            style={{
              padding: "24px",
            }}
          >
            <Grid
              style={{
                border: "1px dashed #9AA2B1",
                width: "100%",
                paddingTop: "16px",
                paddingBottom: "16px",
                textAlign: "center",
                backgroundColor: "#F9FAFB",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <input
                type="file"
                multiple
                onChange={handleFiles}
                onClick={event => {
                  handleClickFiles(event);
                }}
                style={{
                  opacity: 0.0,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  width: "100%",
                  height: "100%",
                }}
              />
              <img
                alt="Logo"
                src={
                  require("assets/image-public/images/vector-upload-trans.png")
                    .default
                }
              />
              <h3
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                  marginTop: "8px",
                }}
              >
                Upload Proof Of Payment, <strong>Search File</strong>{" "}
              </h3>
            </Grid>
          </Grid>
          <Grid
            style={{
              padding: "24px",
            }}
          >
            <h3>Document Attached</h3>
            {files.map((res, i) => {
              return (
                <Grid
                  key={i}
                  style={{
                    marginTop: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="Logo"
                    src={
                      require("assets/image-public/images/vector-doc-icon.png")
                        .default
                    }
                    style={{
                      width: "24px",
                      height: "24px",
                      objectFit: "contain",
                    }}
                  />
                  <Grid
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      width: "100%",
                      paddingLeft: "16px",
                    }}
                  >
                    <p
                      style={{
                        lineHeight: "20px",
                        margin: 0,
                        marginBottom: "4px",
                      }}
                    >
                      {res?.name}
                    </p>
                    <span
                      style={{
                        color: "#687083",
                        lineHeight: "8px",
                      }}
                    >
                      {formatFileSize(res?.size)}
                    </span>
                  </Grid>
                  <Grid
                    container
                    style={{
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <IconButton
                      onClick={() =>
                        setFiles([...files].filter(val => val !== res))
                      }
                    >
                      <img alt="LogoTrash" src={TrashVectorGrey} />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid
            style={{
              padding: "24px",
              borderTop: "1px solid #E4E7EB",
            }}
            container
            justifyContent="flex-end"
          >
            <Button
              onClick={() => {
                setModalPayment(false);
              }}
              className={classes.buttonWhite}
            >
              Cancel
            </Button>
            <Button
              disabled={submiting}
              onClick={handleUpload}
              className={submiting ? classes.buttonGray : classes.buttonBlack}
            >
              Upload
            </Button>
          </Grid>
        </CustomModal>
      )}
    </Page>
  );
};

export default DetailPayment;

const CustomChip = ({ status }) => {
  let label =
    status === "waiting_for_approval"
      ? "Waiting For Approval"
      : status === "approved"
      ? "Approved"
      : status === "rejected"
      ? "Rejected"
      : status === "paid"
      ? "Paid"
      : "Unpaid";
  const border =
    status === "waiting_for_approval"
      ? "#F6C962"
      : status === "approved" || status === "paid"
      ? "#8DE5AB"
      : status === "rejected" || status === "unpaid"
      ? "#F1A69E"
      : "";
  return (
    <Chip
      label={label}
      style={{
        border: `1px solid ${border}`,
        height: "28px",
        color:
          status === "waiting_for_approval"
            ? "#A84D20"
            : status === "approved" || status === "paid"
            ? "#34774C"
            : status === "rejected" || status === "unpaid"
            ? "#A63124"
            : "",
        backgroundColor:
          status === "waiting_for_approval"
            ? "#FEF8E7"
            : status === "approved" || status === "paid"
            ? "#EFFCF4"
            : status === "rejected" || status === "unpaid"
            ? "#FCF3F2"
            : "",
      }}
    />
  );
};
