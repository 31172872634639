import { Chip, Container, Divider, Grid, Typography } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTableV2 } from "inno-ui";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ButtonGroupTop } from "../../../components/atoms/Button";
import HeaderPage from "../../../layouts/Header/HeaderPage";
import { Page } from "components";
import { hardBaseUrl } from "../../../services/urlConstant";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  content: {
    marginTop: "20px",
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formTitle: {
    fontSize: 18,
    fontWeight: 700,
  },
  formSubTitle: {
    fontSize: 14,
    margin: "8px 0 16px",
  },
  formCard: {
    border: "1px solid #9AA2B1",
    borderRadius: "",
    padding: 16,
    marginTop: 16,
  },
  activeChip: {
    color: "#34774C",
    backgroundColor: "#8DE5AB",
    border: "1px thin #8DE5AB",
  },
  nonActiveChip: {
    color: "#364052",
    backgroundColor: "#F9FAFB",
    border: "1px solid #D1D5DC",
  },
  formValueText: {
    fontSize: 16,
    fontFamily: "Inter",
  },
  formValueContainer: {
    marginTop: 8,
  },
}));

const FormHeader = ({ title, subTitle }) => (
  <div>
    <Typography className={useStyles()?.formTitle}>{title}</Typography>
    <Typography className={useStyles()?.formSubTitle}>{subTitle}</Typography>
  </div>
);
function DetailKontrak(props) {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const masterMenu = useSelector(state => state.masterMenu);
  const [contractDetails, setContractDetails] = useState(null);
  const [afterCheckTopMenu, setAfterCheckTopMenu] = useState(true);
  const [theItems, setTheItems] = useState([]);
  let theTopMenu = [
    {
      id: 0,
      title: "Detail",
      link: "/pencipta/detail-kontrak/" + props.match.params.id,
      selected: true,
    },
    {
      id: 1,
      title: "Supporting Documents",
      link: "/pencipta/detail-kontrak-dokumen/" + props.match.params.id,
      selected: false,
    },
    {
      id: 2,
      title: "Activity",
      link: "/pencipta/detail-kontrak-aktifitas/" + props.match.params.id,
      selected: false,
    },
  ];

  const handleOnSelectedButton = value => {
    history.push(value.data[value.newButtonSelected].link);
  };
  const getResponse = () => {
    const url = `${hardBaseUrl}/composer/contract/${props.match.params.id}`;
    axios
      .get(url, { headers })
      .then(res => {
        setContractDetails(res.data.data);
        setTheItems(res.data.data.songs[0]?.composers);
      })
      .catch(() => { });
  };

  useEffect(() => {
    getResponse();
  }, []);
  useEffect(() => {
    if (masterMenu.masterMenu !== null) {
      setAfterCheckTopMenu(true);
    }
  }, [masterMenu]);

  const breadcrumbData = [
    {
      name: "Home",
      url: null,
    },
    {
      name: "Contract",
      url: "/pencipta/kontrak",
    },
    {
      name: "Contract Details",
      url: null,
    },
  ];
  const columnTable = [
    { name: "composer_name", title: "Composer Name" },
    { name: "iswc_code", title: "ISWC Code" },
    { name: "ownership_percentage", title: "Ownership Percentage" },
  ];
  const contractInformationData = contractDetails?.contract;
  const contractInformationForm = [
    {
      title: "Contract Number",
      name: contractInformationData?.contract_number,
    },
    {
      name: contractInformationData?.is_active_flag,
      title: "Status",
      type: "chip",
    },
    { name: contractInformationData?.start_date, title: "Start Date" },
    { name: contractInformationData?.end_date, title: "End Date" },
    { name: contractInformationData?.publisher, title: "Publisher" },
    { name: contractInformationData?.id_publisher, title: "Publisher ID" },
  ];
  const songInformationData = contractDetails?.songs?.[0];
  const songInformationForm = [
    { name: songInformationData?.title, title: "Title" },
    { name: songInformationData?.iswc_code, title: "ISWC Code" },
    { name: songInformationData?.ownership, title: "Ownership" },
    { name: songInformationData?.performer, title: "Performer" },
  ];

  return (
    <Fragment>
      <Page className={classes.root} title="Contract Details">
        <Container maxWidth={false}>
          <HeaderPage title="Contract Details" breadcrumbs={breadcrumbData} />
          {afterCheckTopMenu && (
            <ButtonGroupTop
              items={theTopMenu}
              selectedButton={handleOnSelectedButton}
              selectedIndex={0}
            />
          )}
          <div className={classes.content}>
            <hr />
            <div style={{ marginTop: "30px" }}>
              <Grid container>
                <Grid item xs={8}>
                  <FormHeader
                    title="Contract Information"
                    subTitle="Details of your contract information."
                  />
                  <div className={classes?.formCard}>
                    <Grid container spacing={2}>
                      {contractInformationForm?.map((item, index) => (
                        <Grid item key={index} xs={6}>
                          <Typography>{item?.title}</Typography>
                          <div className={classes?.formValueContainer}>
                            {item?.type === "chip" ? (
                              <Chip
                                size="small"
                                label={item.name ? "Active" : "Non Active"}
                                avatar={
                                  <FiberManualRecord
                                    style={{
                                      width: "10px",
                                      color: item.name ? "#54B371" : "#9AA2B1",
                                    }}
                                  />
                                }
                                className={
                                  item.name
                                    ? classes?.activeChip
                                    : classes?.nonActiveChip
                                }
                              />
                            ) : item?.name ? (
                              <Typography className={classes?.formValueText}>
                                {item?.name}
                              </Typography>
                            ) : (
                              "-"
                            )}
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                  <Divider className={classes.divider} />

                  <FormHeader
                    title="Song Information"
                    subTitle="Details of your song information."
                  />
                  <div className={classes?.formCard}>
                    <Grid container>
                      {songInformationForm?.map((item, index) => (
                        <Grid item key={index} xs={6}>
                          <Typography>{item?.title}</Typography>
                          <div className={classes?.formValueContainer}>
                            {!item?.name ? (
                              "-"
                            ) : item?.type === "chip" ? (
                              <Chip
                                size="small"
                                label={item ? "Active" : "Non Active"}
                                avatar={
                                  <FiberManualRecord
                                    style={{
                                      width: "10px",
                                      color: item ? "#54B371" : "#9AA2B1",
                                    }}
                                  />
                                }
                                className={
                                  item
                                    ? classes?.activeChip
                                    : classes?.nonActiveChip
                                }
                              />
                            ) : (
                              <Typography className={classes?.formValueText}>
                                {item?.name}
                              </Typography>
                            )}
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                  <Divider className={classes.divider} />

                  <FormHeader
                    title="Composer"
                    subTitle="Details of your Composer."
                  />
                  <InnoTableV2
                    columns={columnTable}
                    items={theItems}
                    isLoading={false}
                  />
                  <Divider className={classes.divider} />
                </Grid>
              </Grid>
            </div>
          </div>
        </Container>
      </Page>
    </Fragment>
  );
}
export default DetailKontrak;
