import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import "./LoginCaris.css";
import mpisLogo from "../../../assets/img/newMpisLogo.webp";
import carisLogo from "../../../assets/img/newCarisLogo.webp";
import {
  MailOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import Axios from "axios";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../../services/urlConstant";
import * as Sentry from "@sentry/react";
import { getErrors, getSubdomain } from "../../../utils";
import {
  setSessionLogin,
  setRememberMeLogin,
  getCookie,
} from "../../../utils/constants";
import { Box, Button, Grid, styled, Typography } from "@mui/material";
import backgroundLogin from "../../../assets/background/background_login.webp";
import { CheckboxInput, PrimaryButton, TextInput } from "components";
import { LockOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

export default function LoginCaris() {
  const classes = useStyles();
  let history = useHistory();
  const local_dsp_selected = localStorage.getItem("local_dsp_selected");
  const token = localStorage.getItem("token");
  const message = useSelector(state => state.auth.message);
  const isMpis = getSubdomain() === "mpis";
  const [forgotPassword, setForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);
  const [remember, setRemember] = useState(false);
  const emailData = getCookie(
    "authRemember",
    `${process.env.REACT_APP_NAME}_email`
  );
  const passwordData = getCookie(
    "authRemember",
    `${process.env.REACT_APP_NAME}_password`
  );
  const [dataLogin, setDataLogin] = useState({
    email: emailData,
    password: passwordData,
  });
  const [loginDisabled, setLoginDisabled] = useState(false);

  const toggleShowPassword = () =>
    setShowPassword(showPassword => !showPassword);

  const handleLogin = async e => {
    e.preventDefault();
    setLoginDisabled(true);
    let newDataLogin = {
      email: dataLogin.email,
      password: dataLogin.password,
      remember_me: remember,
      subdomain: "publisher",
    };
    try {
      let resultLogin = await Axios.post(
        hardBaseUrl + "/auth/login",
        newDataLogin,
        {
          headers: {},
        }
      );

      if (remember) {
        setRememberMeLogin({
          email: dataLogin.email,
          password: dataLogin.password,
        });
      }

      if (
        resultLogin.data.meta.http_status === 200 &&
        resultLogin.data.message === "success"
      ) {
        if (typeof resultLogin.data.data !== "object") {
          Sentry.captureMessage("Error catch undefined : undefined");
          localStorage.setItem("url_login", history.location.pathname);
          history.push("/otp", { email: dataLogin.email });
        } else {
          localStorage.setItem("url_login", history.location.pathname);
          localStorage.setItem("reminder", "reminder");

          let resultMe = await Axios.get(hardBaseUrl + "/me", {
            headers: {
              Authorization: "Bearer " + resultLogin.data.data.access_token,
            },
          });

          if (
            resultMe.data.meta.http_status === 200 &&
            resultMe.data.message === "success"
          ) {
            const payload = resultMe.data.data;
            setSessionLogin(payload);
            if (resultMe.data.data.role.type === "publisher") {
              if (resultMe.data.data.publisher.theme_color === "") {
                localStorage.setItem("themeColor", "#9545eb");
              } else {
                localStorage.setItem(
                  "themeColor",
                  resultMe.data.data.publisher.theme_color
                );
              }
              history.push("/redirect/" + resultLogin.data.data.access_token);
            } else if (resultMe.data.data.role.type === "association") {
              localStorage.setItem("url_login", history.location.pathname);
              history.push("/redirect/" + resultLogin.data.data.access_token);
            } else {
              if (localStorage.getItem("typeWeb") === "caris") {
                localStorage.setItem("themeColor", "#111827");
              } else if (localStorage.getItem("typeWeb") === "mpis") {
                localStorage.setItem("themeColor", "#9545eb");
              }
              history.push("/redirect/" + resultLogin.data.data.access_token);
            }
          } else {
            Sentry.captureMessage(
              "Error response /me resultMe.data.message is not success"
            );
          }
        }
      } else {
        Sentry.captureMessage(
          "Error response /auth/login ../../../pages/LandingPage/LoginCaris/LoginCaris.js"
        );
      }
    } catch (error) {
      let errMessage = error.response
        ? getErrors(error.response)
        : "The connection server is having an error / check your internet connection";
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: errMessage || "Login Failed.",
        showCloseButton: false,
      }).then(() => {
        setLoginDisabled(false);
      });
    }
  };

  const handleForgot = async e => {
    e.preventDefault();
    try {
      await Axios.post(hardBaseUrl + "/auth/forgot", {
        email: dataLogin.email,
      });
    } catch (error) {
      Sentry.captureMessage(
        "Error response /auth/forgot src/viewsMpis/LandingPage/LoginCaris/LoginCaris.js"
      );
    }
    setTimeout(() => {
      setForgotPassword(false);
      setSentEmail(true);
    }, 500);
  };

  useEffect(() => {
    if (local_dsp_selected || token) {
      localStorage.removeItem("local_dsp_selected");
      localStorage.removeItem("token");
    }
    localStorage.removeItem("auth");
    localStorage.removeItem("role");
    localStorage.removeItem("role_id");
  }, []);
  useEffect(() => {
    if (message !== null) {
      window.location.reload();
    }
  }, [message]);
  return (
    <Grid container columns={5}>
      <Grid item xs={5} md={2} data-testid="data-test-page-login-mpis">
        <FormContainer>
          <Box maxWidth="600px" mx="auto">
            <Box
              mb="24px"
              textAlign={forgotPassword || sentEmail ? "center" : "left"}
            >
              <img
                src={isMpis ? mpisLogo : carisLogo}
                alt="logo"
                className="logo"
                width={isMpis ? "140px" : "200px"}
              />
            </Box>
            <Box>
              <Typography
                fontFamily="inter"
                fontSize="36px"
                fontWeight={700}
                textAlign={forgotPassword || sentEmail ? "center" : "left"}
              >
                {sentEmail
                  ? "Email Successfully Sent"
                  : forgotPassword
                  ? "Forgot the password"
                  : "Welcome Back !"}
              </Typography>
              <Typography
                fontFamily="inter"
                color="#687083"
                textAlign={forgotPassword || sentEmail ? "center" : "left"}
                mb="24px"
              >
                {sentEmail
                  ? "You will receive a password recovery link at your email address."
                  : forgotPassword
                  ? "Enter the registered e-mail or mobile number. We will send you a verification code to reset your password."
                  : "Let's start manage and setting your music, composer and label easily with us "}
              </Typography>

              {sentEmail ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography fontFamily="inter" color="#687083">
                    Haven&apos;t received an email yet
                  </Typography>
                  <TextButton
                    onClick={() => setSentEmail(false)}
                    variant="text"
                  >
                    Resend
                  </TextButton>
                </Box>
              ) : (
                <Fragment>
                  <Box my="10px">
                    {!forgotPassword && (
                      <Typography
                        fontFamily="inter"
                        fontSize="14px"
                        fontWeight={600}
                        mb="8px"
                      >
                        Email/ Phone Number
                      </Typography>
                    )}
                    <TextInput
                      placeholder="Email/ Phone Number"
                      onChange={e =>
                        setDataLogin({ ...dataLogin, email: e.target.value })
                      }
                      value={dataLogin.email}
                      startAdornment={<MailOutlined />}
                      disabled={loginDisabled}
                      width="100%"
                    />
                  </Box>
                  {!forgotPassword && (
                    <Box my="10px">
                      <Typography
                        fontFamily="inter"
                        fontSize="14px"
                        fontWeight={600}
                        mb="8px"
                      >
                        Password
                      </Typography>
                      <TextInput
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        onChange={e =>
                          setDataLogin({
                            ...dataLogin,
                            password: e.target.value,
                          })
                        }
                        value={dataLogin.password}
                        startAdornment={
                          <LockOutlined className={classes?.lockIcon} />
                        }
                        endAdornment={
                          showPassword ? (
                            <EyeTwoTone onClick={toggleShowPassword} />
                          ) : (
                            <EyeInvisibleOutlined
                              onClick={toggleShowPassword}
                            />
                          )
                        }
                        disabled={loginDisabled}
                        width="100%"
                      />
                    </Box>
                  )}
                  <Box my="10px">
                    {!forgotPassword && (
                      <Grid
                        container
                        justifyContent={isMpis ? "right" : "space-between"}
                        alignItems="center"
                      >
                        {!isMpis && (
                          <Grid item>
                            <CheckboxInput
                              disabled={loginDisabled}
                              label="Remember Me"
                              checked={remember}
                              onChange={e => setRemember(e.target.checked)}
                            />
                          </Grid>
                        )}
                        <Grid item>
                          <TextButton
                            disabled={loginDisabled}
                            onClick={() => setForgotPassword(true)}
                            variant="text"
                          >
                            Forgot password?
                          </TextButton>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                  <Box my="10px">
                    <PrimaryButton
                      label={
                        loginDisabled
                          ? "Logging In"
                          : forgotPassword
                          ? "Continue"
                          : "Login"
                      }
                      disabled={loginDisabled}
                      loading={loginDisabled}
                      onClick={forgotPassword ? handleForgot : handleLogin}
                      width="100%"
                    />
                  </Box>
                  <Box mt="10px">
                    {forgotPassword && (
                      <TextButton
                        onClick={() => setForgotPassword(false)}
                        variant="text"
                        customColor="#D1D5DC"
                        width="100%"
                      >
                        Return
                      </TextButton>
                    )}
                  </Box>
                </Fragment>
              )}
            </Box>
          </Box>
        </FormContainer>
      </Grid>
      <Grid xs={0} md={3}>
        <ImageContainer />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  lockIcon: {
    width: "16px",
  },
}));
const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "100vh",
  padding: "0 100px",
  backgroundColor: "#f5f5f5",
});
const ImageContainer = styled(Box)({
  width: "100%",
  height: "100%",
  backgroundImage: `url(${backgroundLogin})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
});
const TextButton = styled(Button)(({ customColor, width }) => ({
  color: customColor || "#9545EB",
  textTransform: "none",
  width,
}));
