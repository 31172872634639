import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Container,
  Divider,
  Grid,
  Breadcrumbs,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Chip,
  TableRow,
  Button,
} from "@material-ui/core";
import axios from "axios";
import { Page, PrimaryButton } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import NumberFormat from "react-number-format";
import { InnoImage } from "inno-ui";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  font24: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  textStyle: {
    marginTop: "5px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  grid1: {
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
  },
  chip1: {
    border: "1px solid #F1A69E",
    height: "28px",
    color: "#A63124",
    backgroundColor: "#FCF3F2",
  },
  card1: {
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
  },
  styleImage: {
    maxWidth: "155.52px",
    width: "100%",
    height: "100%",
    maxHeight: "50px",
    objectFit: "contain",
  },
  font14: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
  },
  font14Color: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "17px",
    color: "#6F6F84",
    wordWrap: "break-word",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  mt5: {
    marginTop: "5px",
  },
  mt20: {
    marginTop: "20px",
  },
  border1: {
    border: "1px solid #9AA2B1",
    borderRadius: "18px",
  },
  bgF9FAFB: {
    backgroundColor: "#F9FAFB",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
  },
  textRight: {
    textAlign: "right",
  },
  divAction: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "end",
  },
}));

const DetailAdvancedFinance = () => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const classes = useStyles();
  const [state, setState] = useState();
  const [currencyPrefix, setCurrencyPrefix] = useState("Rp");
  const { id } = useParams();
  const inputRef = useRef(null);

  const getDataTable = () => {
    const url = `${hardBaseUrl}/publisher/transaction/detail/${id}`;
    axios
      .get(url, {
        headers,
      })
      .then(response => {
        const resData = response.data.data;
        setState(resData);
        getClientList(resData?.dsp_id);
      })
      .catch(err => {
        new Error(err);
      });
  };

  const getClientList = async dspId => {
    const urlClientList = `${hardBaseUrl}/master/all-client`;
    await axios
      .get(urlClientList, { headers })
      .then(res => {
        const resClientList = res?.data?.data;
        const selectedDSP = resClientList?.find(el => el?.dsp_id === dspId);
        setCurrencyPrefix(selectedDSP?.dsp_currency?.currency_symbol_code);
      })
      .catch(err => {
        new Error(err);
      });
  };

  useEffect(() => {
    getDataTable();
  }, []);
  const handlePrintPDF = () => {
    html2canvas(inputRef.current, { useCORS: true }).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "px", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };

  return (
    <Page className={classes.root} title="User">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography className={classes.font24}>
                Advance Transaction
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Detail Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Grid container>
              <div>
                <ThemeProvider theme={theme}>
                  <Typography
                    component="h1"
                    variant="h3"
                    className={classes.subTitle}
                  >
                    Detail Credit Note
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <p className={classes.textStyle}>
                    This is detail credit note information, in advance
                    transaction
                  </p>
                </ThemeProvider>
              </div>
              <Grid className={classes.grid1} item>
                <Chip label={state?.status} className={classes.chip1} />
              </Grid>
            </Grid>
            <Card className={classes.card1} ref={inputRef}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <InnoImage
                      src={state?.publisher?.profile_image}
                      alt="img"
                      fill={true}
                      styleImage={{
                        maxWidth: "155.52px",
                        width: "100%",
                        height: "100%",
                        maxHeight: "50px",
                        objectFit: "contain",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div className={classes.textRight}>
                      <div>
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.font14}>
                            {state?.publisher.name}
                          </Typography>
                          <Typography className={classes.font14Color}>
                            {state?.publisher?.address}
                          </Typography>
                        </ThemeProvider>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Divider className={classes.divider} />

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <Typography className={classes.font14}>
                        Advance DSP:
                      </Typography>
                      <Typography className={classes.font14}>
                        {state?.dsp_name}
                      </Typography>
                    </ThemeProvider>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <div className={classes.flexBetween}>
                        <Typography className={classes.font14}>
                          Transaction Number :
                        </Typography>

                        <Typography className={classes.font14}>
                          {state?.transaction_number}
                        </Typography>
                      </div>

                      <div className={(classes.flexBetween, classes.mt5)}>
                        <Typography className={classes.font14}>
                          Type :
                        </Typography>

                        <Typography className={classes.font14}>
                          {state?.type}
                        </Typography>
                      </div>
                    </ThemeProvider>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />

                <Grid container>
                  <div>
                    <ThemeProvider theme={theme}>
                      <Typography className={classes.font14}>
                        Song Detail
                      </Typography>
                    </ThemeProvider>
                  </div>
                </Grid>
                <div className={classes.mt20}>
                  <ThemeProvider theme={theme}>
                    <Table size="small" className={classes.border1}>
                      <TableHead className={classes.bgF9FAFB}>
                        <TableRow>
                          <TableCell
                            // variant="subtitle2"
                            className={classes.headText}
                          >
                            Song
                          </TableCell>
                          <TableCell
                            // variant="subtitle2"
                            className={classes.headText}
                          >
                            Composer/Author
                          </TableCell>
                          <TableCell
                            // variant="subtitle2"
                            align="right"
                            className={classes.headText}
                          >
                            Traffic
                          </TableCell>
                          <TableCell
                            // variant="subtitle2"
                            align="right"
                            className={classes.headText}
                          >
                            Revenue
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {state?.songs?.length > 0 ? (
                          state?.songs.map(songs => (
                            <TableRow
                              hover
                              key={`${songs.publisher_transaction_id}`}
                            >
                              <ThemeProvider theme={theme}>
                                <TableCell>
                                  <Typography
                                    variant="body2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {songs.song_title}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="body2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {songs.composer_names?.map(
                                      val => val + " "
                                    )}
                                  </Typography>
                                </TableCell>

                                <TableCell align="right">
                                  <Typography
                                    variant="body2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {songs.traffic}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    variant="body2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {songs.revenue}
                                  </Typography>
                                </TableCell>
                              </ThemeProvider>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <div className={classes.flexCenter}>
                                <Typography variant="subtitle1">
                                  No data available
                                </Typography>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </ThemeProvider>
                </div>

                <div className={classes.mt20}>
                  <ThemeProvider theme={theme}>
                    <Typography className={(classes.font14, classes.textRight)}>
                      Recoupment Total :
                    </Typography>
                    <Typography className={(classes.font14, classes.textRight)}>
                      <NumberFormat
                        displayType="text"
                        value={state?.revenue}
                        thousandSeparator={true}
                        decimalScale={2}
                        prefix={`${currencyPrefix} `}
                      />
                    </Typography>
                  </ThemeProvider>
                </div>
              </CardContent>
            </Card>
            <div className={classes.divAction}>
              {state?.type === "flat" && state?.status === "paid" ? (
                <PrimaryButton
                  onClick={handlePrintPDF}
                  label="Download As PDF"
                />
              ) : (
                <></>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DetailAdvancedFinance;
