import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import moment from "moment";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import {
  Page,
  MonthYearRangePicker,
  PrimaryButton,
  SearchTextInput,
  ModalError,
  SkeletonComponent,
} from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import { getErrors } from "utils";
import { HeaderTitle } from "layouts";
import { InnoTableV2 } from "inno-ui";
import { debounce } from "lodash";
import { getTotalPage } from "lib";
import { Container, Divider, Grid } from "@mui/material";
import NumberFormat from "react-number-format";
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  inner: {
    minWidth: 700,
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
    marginTop: 16,
  },
  button: {
    marginBottom: "20px",
    backgroundColor: "black",
    color: "white",
    width: "auto",
    height: "35px",
  },
  colorBox: {
    width: "30px",
    height: "30px",
  },
  tableArea: {
    marginTop: "20px",
  },
  textNoData: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "400",
    fontStyle: "normal",
    color: "#687083",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },

  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  inputFields: {
    "& .MuiOutlinedInput-input": {
      padding: "0px",
      height: "44px",
      width: 200,
    },
  },
  selectInput: {
    "& .MuiInputBase-root": {
      height: 44,
    },
  },
  buttonDownload: {
    textTransform: "none",
    backgroundColor: "#000",
    color: "white",
    boxShadow: "none",
    width: "140px",
    height: "44px",
    borderRadius: "6px",
  },
  textDownload: {
    color: "white",
    whiteSpace: "nowrap",
    fontSize: "14px",
    fontWeight: "500",
  },
  ib: {
    padding: "4px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
  },
  ibRefresh: {
    padding: "4px",
    color: "white",
    marginLeft: "8px",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
  },
  btnRincian: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    borderRadius: "6px",
    width: "140px",
    height: "44px",
  },
  inputFilter: {
    width: "211px",
    height: "44px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    marginRight: "10px",
  },
  btnSubmit: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    height: "40px",
    borderRadius: "6px",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },

  actions: {
    justifyContent: "flex-end",
  },
  p0: {
    padding: 0,
  },
  backdropStyle: {
    color: "#fff",
    zIndex: 100,
    position: "absolute",
  },
}));
function Report() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const filterSearch = params.get("q");
  const filterPage = params.get("page");
  const filterSize = params.get("size");
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: filterPage || 1,
    size: filterSize || 10,
    search: filterSearch || "",
    startDate: moment().subtract(1, "months"),
    endDate: moment(),
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const previewReportRef = useRef(null);
  const [isLoadingDownloadFile, setIsLoadingDownloadFile] = useState({
    pdf: false,
    xlsx: false,
  });
  const { startDate, endDate, ...otherParams } = queryParams;

  const formatParamsMonth = date => date.format("M");
  const formatParamsYear = date => date.format("YYYY");
  const handleChangeQueryParams = (value, key) => setQueryParams(currentState => ({
    ...currentState,
    [key]: value,
  }));
  const handleResetPage = () => handleChangeQueryParams(1, "page");
  const handleChangePageSize = e => {
    handleResetPage();
    handleChangeQueryParams(e?.target?.value, "size");
  };
  const handleChangeSearch = event => {
    const { value } = event?.target;
    handleResetPage();
    handleChangeQueryParams(value, "search");
    params.set("q", value);
    history.push({ search: params.toString() });
  };
  const handleDetail = item => {
    history.push({
      pathname: "/admin/reports/" + item,
      state: {
        month: formatParamsMonth(startDate),
        year: formatParamsYear(startDate),
      },
    });
  };

  const getDataTable = async () => {
    setLoadingPage(true);
    const url = `${hardBaseUrl}/publisher/report`;
    const params = {
      ...otherParams,
      start_month: formatParamsMonth(startDate),
      end_month: formatParamsMonth(endDate),
      year: formatParamsYear(startDate),
    };
    try {
      const res = await axios.get(url, { headers, params });
      const { data, meta } = res?.data;
      const modifiedData = (data || [])?.map(item => ({
        ...item,
        usage_on_dsp: (item?.usage_on_dsp || [])?.map(dsp => ({
          ...dsp,
          product_type: dsp?.summaries[0]?.product_type || "",
        })),
      }));
      setDataTable(modifiedData);
      const pageCount = getTotalPage(meta?.total, queryParams?.size);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleDownloadXlsx = async () => {
    try {
      setIsLoadingDownloadFile({ ...isLoadingDownloadFile, xlsx: true });
      const { data } = await axios(`${hardBaseUrl}/publisher/report/download`, {
        headers,
        params: {
          page: queryParams?.page,
          size: queryParams?.size,
          search: queryParams?.search,
          start_month: formatParamsMonth(startDate),
          end_month: formatParamsMonth(endDate),
          year: formatParamsYear(startDate),
        },
      });
      const link = document.createElement("a");
      link.href = data.data;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setIsLoadingDownloadFile({ ...isLoadingDownloadFile, xlsx: false });
    }
  };
  const handleDownloadPdf = () => {
    setIsLoadingDownloadFile({ ...isLoadingDownloadFile, pdf: true });
    html2canvas(previewReportRef?.current)
      .then(canvas => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "p",
          unit: "px",
          format: "a4",
        });
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const widthRatio = pageWidth / canvas.width;
        const heightRatio = pageHeight / canvas.height;
        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
        const canvasWidth = canvas.width * ratio;
        const canvasHeight = canvas.height * ratio;
        const marginX = (pageWidth - canvasWidth) / 2;
        const marginY = (pageHeight - canvasHeight) / 2;
        pdf.addImage(
          imgData,
          "PNG",
          marginX,
          marginY,
          canvasWidth,
          canvasHeight
        );
        pdf.save("download.pdf");
      })
      .catch(error => {
        ModalError(error);
      })
      .finally(() => {
        setIsLoadingDownloadFile({ ...isLoadingDownloadFile, pdf: false });
      });
  };

  useEffect(() => {
    debounceDataTable();
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);
  const RenderDoubleColumn = (list, key, type, prefix) => {
    const getDefaultValue = value => (typeof value === "string" ? "-" : 0);
    return (
      <div className="double-column">
        {list?.length > 0 &&
          (list || []).map((item, index) => (
            <div key={index}>
              {index == 1 && <div className="tab-dotted" />}
              {type === "number" ? (
                <NumberFormat
                  displayType="text"
                  value={item?.[key] || 0}
                  thousandSeparator={true}
                  prefix={prefix}
                />
              ) : (
                <span>{item?.[key] || getDefaultValue(item?.[key])}</span>
              )}
            </div>
          ))}
      </div>
    );
  };
  const columnTable = [
    {
      name: "all",
      title: "Action",
      renderText: item => (
        <PrimaryButton
          label="See Details"
          onClick={() => handleDetail(item.composer_id)}
          size="small"
        />
      ),
    },
    {
      name: "composer_name",
      title: "Composer/Author",
    },
    {
      name: "composer_alias_name",
      title: "Alias Name",
    },
    {
      name: "usage_on_dsp",
      title: "DSP",
      renderText: item => RenderDoubleColumn(item, "dsp_name"),
    },
    {
      name: "usage_on_dsp",
      title: "Traffic",
      renderText: item => RenderDoubleColumn(item, "traffic", "number"),
    },
    {
      name: "usage_on_dsp",
      title: "Income",
      renderText: item => RenderDoubleColumn(item, "revenue", "number", "Rp"),
    },
    {
      name: "usage_on_dsp",
      title: "Market Share (Traffic)",
      renderText: item => RenderDoubleColumn(item, "traffic_percentage"),
    },
    {
      name: "usage_on_dsp",
      title: "Market Share (Revenue)",
      renderText: item => RenderDoubleColumn(item, "revenue_percentage"),
    },
    {
      name: "usage_on_dsp",
      title: "Product Type",
      renderText: item => RenderDoubleColumn(item, "product_type"),
    },
  ];
  return (
    <Page className={classes.root} title="Report">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <div ref={previewReportRef} id={"previewReport"}>
            <HeaderTitle title="Report" breadcrumbData={breadcrumbData} />
            <Divider sx={{ my: 2, borderTop: "1px solid #e1e1e1" }} />
            <Grid container justifyContent="space-between" my={4}>
              <Grid item>
                <SearchTextInput
                  placeholder="Search"
                  value={queryParams?.search}
                  onChange={handleChangeSearch}
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  columnSpacing={1}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <MonthYearRangePicker
                      label="Period"
                      startDate={startDate}
                      handleChangeStartDate={date =>
                        handleChangeQueryParams(date, "startDate")
                      }
                      endDate={endDate}
                      handleChangeEndDate={date =>
                        handleChangeQueryParams(date, "endDate")
                      }
                      syncYear
                      width={250}
                    />
                  </Grid>
                  <Grid item>
                    <PrimaryButton
                      height={40}
                      label="Download PDF"
                      onClick={handleDownloadPdf}
                    />
                  </Grid>
                  <Grid item>
                    <PrimaryButton
                      height={40}
                      label="Download XLSX"
                      onClick={handleDownloadXlsx}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <InnoTableV2
              isLoading={loadingPage}
              columns={columnTable}
              items={dataTable}
              handleChangePage={(_, data) =>
                handleChangeQueryParams(data, "page")
              }
              handleChangeRowsPerPage={handleChangePageSize}
              page={queryParams?.page}
              rowsPerPage={queryParams?.size}
              totalPage={tableTotalPage}
            />
          </div>
        </Container>
      )}
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Report",
    active: true,
  },
];
export default Report;
