import { Autocomplete, styled, TextField } from "@mui/material";

const AutoCompleteWithSearch = ({
  loading,
  size,
  label,
  value,
  options,
  optionLabel,
  onChange,
  inputValue,
  onInputChange,
  width,
  height,
  ...rest
}) => {
  return (
    <AutoCompleteComponent
      size={size || "small"}
      loading={loading}
      options={options}
      customWidth={width}
      customHeight={height}
      getOptionLabel={option => option?.[optionLabel] || ""}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        onInputChange(newInputValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          slotProps={{
            input: {
              ...params.InputProps,
              type: "search",
            },
          }}
        />
      )}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue); 
      }}
      {...rest}
    />
  );
};

const AutoCompleteComponent = styled(Autocomplete)(
  ({ customWidth, customHeight }) => ({
    width: customWidth || 300,
    height: customHeight,
  })
);

export default AutoCompleteWithSearch;
