import { DatePicker } from "components";
import {
  Card,
  CardActions,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
import { Autocomplete, Pagination } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React from "react";
import NumberFormat from "react-number-format";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props?.name,
            value: values?.value,
          },
        });
      }}
      isNumericString
    />
  );
}

export const SwitchFormLayout = ({
  isChecked,
  onChange,
  label,
  disabled,
  classes,
}) => (
  <FormControlLabel
    control={
      <Switch
        checked={isChecked}
        onChange={onChange}
        color="primary"
        classes={{
          root: classes?.rootSwitch,
          switchBase: classes?.switchBase,
          thumb: classes?.thumb,
          track: classes?.track,
        }}
        disabled={disabled}
      />
    }
    label={label}
  />
);

export const SelectionsLayout = ({
  onChange,
  selectionValue,
  emptyPlaceHolder,
  selectionsList,
  selectionKey,
  selectionLabel,
  fullWidth,
  disabled,
  classes,
}) => (
  <div className={!fullWidth && classes?.selectContainer}>
    <TextField
      fullWidth={true}
      size="small"
      className={classes?.select}
      onChange={onChange}
      value={selectionValue}
      select
      variant="outlined"
      disabled={disabled}
    >
      {emptyPlaceHolder && <MenuItem value={0}>{emptyPlaceHolder}</MenuItem>}
      {selectionsList?.length > 0 &&
        selectionsList?.map((option, index) => (
          <MenuItem key={index} value={option?.[selectionKey]}>
            {option?.[selectionLabel]}
          </MenuItem>
        ))}
    </TextField>
  </div>
);

export const AutocompleteSelectionsLayout = ({
  onChange,
  value,
  selectionsList,
  selectionLabel,
  searchPlaceHolder,
  searchValue,
  handleSearch,
}) => (
  <FormControl variant="outlined" fullWidth>
    <Autocomplete
      onChange={(event, newValue) => onChange(newValue)}
      options={selectionsList}
      value={value}
      getOptionLabel={option => option?.[selectionLabel]}
      inputValue={searchValue}
      onInputChange={handleSearch}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={searchPlaceHolder}
        />
      )}
    />
  </FormControl>
);

const TextFormLayout = ({
  placeholder,
  onChange,
  value,
  disabled,
  multiline,
  customProps,
  dense,
  classes,
}) => (
  <TextField
    className={!dense && classes?.formContainer}
    placeholder={placeholder}
    value={value !== 0 ? value : disabled && 0}
    variant="outlined"
    onChange={onChange}
    margin="dense"
    required
    fullWidth
    disabled={disabled}
    multiline={multiline}
    minRows={multiline ? 10 : 1}
    InputProps={
      customProps && {
        inputComponent: NumberFormatCustom,
        endAdornment: (
          <InputAdornment position="end">{customProps}</InputAdornment>
        ),
      }
    }
  />
);

const DateFormLayout = ({
  placeholder,
  onChange,
  value,
  disabled,
  noValue,
  classes,
}) => (
  <KeyboardDatePicker
    id="outlined-basic"
    className={classes?.formContainer}
    variant="outlined"
    onChange={onChange}
    value={noValue ? null : value || new Date()}
    name={value}
    required
    format="DD MMMM YYYY"
    inputVariant="outlined"
    fullWidth
    margin="dense"
    disabled={disabled}
    placeholder={placeholder}
  />
);

export const SingleFormLayout = ({
  label,
  required,
  placeholder,
  onChange,
  value,
  disabled,
  type,
  multiline,
  customProps,
  noValue,
  classes,
}) => (
  <>
    {label && (
      <Typography variant="subtitle2" className={classes?.label}>
        {label} {required && <span className={classes?.required}>*</span>}
      </Typography>
    )}
    {type === "date" ? (
      <DateFormLayout
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        disabled={disabled}
        noValue={noValue}
        classes={classes}
      />
    ) : (
      <TextFormLayout
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        disabled={disabled}
        multiline={multiline}
        customProps={customProps}
        classes={classes}
      />
    )}
  </>
);

export const TableForm = ({
  value,
  handleChangeValue,
  type,
  selectionsList,
  selectionKey,
  selectionLabel,
  selectionPlaceHolder,
  disabled,
  classes,
}) => (
  <div className={classes?.tableFormContainer}>
    {type === "date" ? (
      <div className={classes?.counterTableFormContainer}>
        <DatePicker
          disabled={disabled}
          value={value || null}
          onChange={handleChangeValue}
        />
      </div>
    ) : type === "currency" ? (
      <div className={classes?.tableFormAlignCenter}>
        <div className={classes?.containerFlexRow}>
          <div className={classes?.currecnyPrefix}>Rp</div>
          <NumberFormat
            customInput={TextField}
            variant="outlined"
            thousandSeparator={true}
            allowLeadingZeros={false}
            getInputRef={inputRef =>
              inputRef && inputRef.setAttribute("inputmode", "numeric")
            }
            onValueChange={values => handleChangeValue(values.value)}
            autoComplete="off"
            value={value !== undefined && value !== 0 ? value.toString() : ""}
            placeholder={0}
            className={classes?.input}
            size="small"
            disabled={disabled}
            fullWidth
          />
        </div>
      </div>
    ) : type === "select" ? (
      <div className={classes?.tableFormAlignCenter}>
        <SelectionsLayout
          onChange={event => handleChangeValue(event?.target?.value)}
          selectionValue={value}
          emptyPlaceHolder={selectionPlaceHolder}
          selectionsList={selectionsList}
          selectionKey={selectionKey}
          selectionLabel={selectionLabel}
          disabled={disabled}
          fullWidth
        />
      </div>
    ) : (
      <TextFormLayout
        onChange={event => handleChangeValue(event?.target?.value)}
        value={value}
        customProps={!type && "%"}
        classes={classes}
        disabled={disabled}
        placeholder={0}
      />
    )}
  </div>
);

const pageSizeList = [{ name: 10 }, { name: 20 }, { name: 50 }];
export const TableLayout = ({
  columnTable,
  data,
  rowClick,
  emptyPlaceHolder,
  page,
  pageSize,
  pageCount,
  onChangePage,
  onChangePageSize,
  isLoading,
  classes,
}) => (
  <Card className={classes?.card}>
    {isLoading ? (
      <div className={classes?.loadingContainer}>
        <CircularProgress className={classes?.tableLoading} />
      </div>
    ) : (
      <>
        <TableContainer className={classes?.tableSongContainer}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {columnTable?.map((item, index) => (
                  <TableCell key={index} width={item?.width}>
                    <Typography
                      variant="subtitle2"
                      className={classes?.headText}
                    >
                      {item?.title}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length > 0 ? (
                data?.map((value, index) => (
                  <TableRow
                    hover
                    key={index}
                    style={
                      rowClick && {
                        cursor: "pointer",
                      }
                    }
                    onClick={event => {
                      if (rowClick) {
                        event.preventDefault();
                        rowClick(value);
                      }
                    }}
                  >
                    {columnTable?.map((item, idx) => (
                      <TableCell key={idx} width={item?.width}>
                        <Typography
                          variant="subtitle2"
                          className={
                            !columnTable?.renderText && classes?.bodyTextBlack
                          }
                        >
                          {item?.renderText
                            ? item?.renderText(value)
                            : value?.[item?.name] || "-"}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <center>
                      <Typography variant="h6" className={classes?.emptyData}>
                        {emptyPlaceHolder}
                      </Typography>
                    </center>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {pageCount > 0 && (
          <CardActions className={classes?.actions}>
            <Grid
              alignContent="space-between"
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item>
                <div className={classes?.containerFlexRow}>
                  <Typography className={classes?.paginationText}>
                    Item per page:
                  </Typography>
                  <SelectionsLayout
                    onChange={onChangePageSize}
                    selectionsList={pageSizeList}
                    selectionValue={pageSize}
                    selectionKey="name"
                    selectionLabel="name"
                  />
                </div>
              </Grid>
              <Grid item>
                <Pagination
                  count={pageCount}
                  shape="rounded"
                  className={classes?.pagination}
                  page={page}
                  onChange={onChangePage}
                />
              </Grid>
            </Grid>
          </CardActions>
        )}
      </>
    )}
  </Card>
);

export const FormLayout = ({
  title,
  subTitle,
  status,
  selections,
  options,
  content,
  classes,
}) => (
  <div className={classes?.sectionContainer}>
    <div className={classes?.containerSpaceBetween}>
      <div>
        <Typography variant="h3" className={classes?.title}>
          {title}
        </Typography>
        <Typography className={classes?.subTitle}>{subTitle}</Typography>
      </div>
      {status && selections()}
    </div>
    {options && options()}
    {content && content()}
  </div>
);

export const SongDialog = ({
  visiblity,
  handleClose,
  isLoading,
  columnTable,
  data,
  rowClick,
  search,
  page,
  size,
  pageCount,
  emptyPlaceHolder,
  handleSearch,
  handleChangePage,
  handleChangePageSize,
  buttonList,
  classes,
}) => (
  <Dialog
    fullWidth={true}
    maxWidth={"md"}
    open={visiblity}
    onClose={handleClose}
    aria-labelledby="max-width-dialog-title"
  >
    <DialogTitle id="max-width-dialog-title">Search Song</DialogTitle>
    <DialogContent>
      <FormControl variant="outlined" fullWidth={true}>
        <OutlinedInput
          id="outlined-adornment-password"
          placeholder="Search"
          value={search}
          onChange={e => {
            handleSearch(e?.target?.value);
          }}
        />
      </FormControl>
      <Divider className={classes?.divider} />
      {data && (
        <TableLayout
          columnTable={columnTable}
          data={data}
          rowClick={value => rowClick(value)}
          emptyPlaceHolder={emptyPlaceHolder}
          page={page}
          pageSize={size}
          pageCount={pageCount}
          onChangePage={(_, newPage) => handleChangePage(newPage)}
          onChangePageSize={event => handleChangePageSize(event?.target?.value)}
          isLoading={isLoading}
          classes={classes}
        />
      )}
    </DialogContent>
    <DialogActions>
      {buttonList?.map((item, index) => (
        <Button
          key={index}
          onClick={handleClose}
          className={classes?.btnDialog}
        >
          {item}
        </Button>
      ))}
    </DialogActions>
  </Dialog>
);
