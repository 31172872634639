import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import PencilVector from "../../../../assets/img/pencilVector.svg";
import { ButtonWithModalPerItem } from "../../../../components/atoms/Button";
import TableEditBar from "../../../../components/molecules/Table/TableEditBar";
import TableLoading from "../../../../components/molecules/Table/TableLoading";

const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
  },
  nameCell: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  textField: {
    width: "150px",
  },
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
  },
  ib: {
    padding: "4px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      boxShadow: "none",
    },
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important"
    },
  },
  ibRefresh: {
    padding: "4px",
    color: "white",
    marginLeft: "8px",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",

      boxShadow: "none",
    },
  },
}));

function Results({
  newLoading,
  className,
  handleChangePageSet,
  handleChangeRowsPerPage,
  pageSizePagination,
  handleDelete,
  handleEdit,
  items,
  page,
  onChangePage,
  pagesCount,
  totalData,
  onRefresh,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();

  const selectedCustomers = [];

  const handleEditSelect = item => {
    let payload = {
      revenue: item,
    };
    history.push({
      pathname: `/admin/parameter/admin-revenue-type/edit/${item.revenue_type_id}`,
      state: payload,
    });
  };

  const onDelete = item => {
    handleDelete(item.revenue_type_id);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {newLoading ? (
        <>
          <TableContainer component={Paper} className={classes.inner}>
            <TableLoading
              open={newLoading}
              backdropStyle={{
                color: "#fff",
                zIndex: 100,
                position: "relative",
              }}
            />
          </TableContainer>
        </>
      ) : (
        <>
          <TableContainer component={Paper} className={classes.inner}>
            <Table
              style={{
                border: "0px solid black",
              }}
            >
              <TableHead
                style={{
                  border: "0px solid black",
                }}
              >
                <TableRow
                  style={{
                    border: "0px solid black",
                  }}
                >
                  <TableCell>Action</TableCell>
                  <TableCell>Revenue Type Name</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items?.length > 0 ? (
                  items?.map((revenue, index) => (
                    <TableRow
                      hover
                      key={revenue.revenue_type_id}
                      selected={
                        selectedCustomers.indexOf(revenue.publisher_id) !== -1
                      }
                    >
                      <TableCell style={{ width: "20px" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",

                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            classes={{
                              root: classes.ib,
                            }}
                            onClick={() => {
                              handleEditSelect(revenue);
                            }}
                          >
                            <img src={PencilVector} alt={"edit"} />
                          </IconButton>
                          <ButtonWithModalPerItem
                            item={revenue}
                            title={"Delete Revenue Type"}
                            dialogTitle={"Delete Revenue Type"}
                            subTitle={
                              "Are you sure you want to delete this data"
                            }
                            handleDelete={onDelete}
                          />
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          width: "20%",
                        }}
                      >
                        {revenue.name}
                      </TableCell>
                      <TableCell>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            {revenue.description}
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        Data not found
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <Grid
        alignContent="space-between"
        container
        justifyContent="space-between"
        style={{
          marginTop: "16px",
        }}
      >
        <Grid item>
          <div style={{ display: "flex" }}>
            <div style={{ marginTop: "7px" }}>
              <ThemeProvider theme={theme}>
                <Typography className={classes.paginationText}>
                  Items per Page
                </Typography>
              </ThemeProvider>
            </div>{" "}
            <div style={{ marginLeft: "24px" }}>{pageSizePagination}</div>
          </div>
        </Grid>

        <Grid item>
          <Pagination
            count={totalData}
            shape="rounded"
            className={classes.pagination}
            handlechangepage={onChangePage}
            page={page}
            onChange={handleChangePageSet}
          />
        </Grid>
      </Grid>
      <TableEditBar selected={selectedCustomers} />
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array,
};

Results.defaultProps = {
  customers: [],
};

export default Results;
