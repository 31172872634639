import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import {
  AutoCompleteComponent,
  ButtonGroupTop,
  DateRangePicker,
  ModalError,
  MultipleSelectInput,
  Page,
  ReportModalWithTable,
  SearchTextInput,
  SkeletonComponent,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import {
  arrayToCommaSeparatedString,
  formatDate,
  getErrors,
  handleChangeFilterAll,
} from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { breadcrumbData, topMenuButton } from "../Components/SongClaimMenu";
import { Grid } from "@mui/material";

function SongUnclaim() {
  const classes = useStyles();
  let token = localStorage.getItem("token");
  const headers = {
    Authorization: "Bearer " + token,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [publisherId, setPublisherId] = useState("");
  const [optionDsp, setOptionDsp] = useState([]);
  const [optionProcessInfo, setOptionProcessInfo] = useState([]);
  const [processInfoParams, setProcessInfoParams] = useState({
    start_date: moment().startOf("year"),
    end_date: moment().endOf("month"),
  });
  const [selectedDsp, setSelectedDsp] = useState({
    dsp_id: 0,
    name: "None",
  });
  const [selectedProcessInfo, setSelectedProcessInfo] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
    search: "",
    header_id: "",
  });
  const [dataTable, setDataTable] = useState([]);
  const [tablePageCount, setTablePageCount] = useState(0);
  const [selectedSongClaim, setSelectedSongClaim] = useState([]);
  const [modalReportVisible, setModalReportVisible] = useState(false);
  const [detailReport, setDetailReport] = useState({});

  const addFilterAll = key => ({ [key]: "all", name: "All" });
  const handleChangeProcessInfoParams = (value, key) => {
    setProcessInfoParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeFilterDSP = value => {
    setSelectedDsp(optionDsp?.find(item => item?.dsp_id === Number(value)));
  };
  const handleChangeFilterProcessInfo = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedProcessInfo(
      handleChangeFilterAll({
        selectedList: selectedProcessInfo,
        checked,
        option,
        key: "header_id",
        list: optionProcessInfo,
        handleChangeQueryParams,
      })
    );
  };
  const handleResetPage = () => handleChangeQueryParams(1, "page");
  const handleChangePageSize = event => {
    const pageSize = event?.target?.value;
    handleResetPage();
    handleChangeQueryParams(pageSize, "per_page");
  };
  const handleSelectSong = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedSongClaim(prev => {
      const selectedSong = checked
        ? [...prev, option]
        : prev.filter(song => song.id !== option.id);
      return selectedSong;
    });
  };
  const handleShowReportDetail = async data => {
    setDetailReport(data);
    setModalReportVisible(true);
  };
  const handleCloseModalReport = () => {
    setModalReportVisible(false);
    debounceGetTableData();
    handleResetFilter();
  };
  const handleResetFilter = type => {
    if (type === "filter") {
      setSelectedProcessInfo([]);
      handleChangeQueryParams("", "header_id");
    }
    setSelectedSongClaim([]);
  };

  const getMe = async () => {
    setLoadingPage(true);
    try {
      const res = await axios.get(`${hardBaseUrl}/me`, {
        headers,
      });
      const { data } = res?.data;
      setPublisherId(data?.publisher.publisher_id);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getOptionDSP = async () => {
    setLoadingPage(true);
    try {
      const url = `${hardBaseUrl}/publishers/list/${publisherId}/dsps`;
      const res = await axios.get(url, { headers });
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        id: item?.dsp_id,
        label: item?.name,
      }));
      setOptionDsp(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getProcessInfoList = async () => {
    setLoadingPage(true);
    const formatFilterDate = date => date?.format("YYYY-MM-DD");
    try {
      const params = {
        start_date: formatFilterDate(processInfoParams?.start_date),
        end_date: formatFilterDate(processInfoParams?.end_date),
      };
      const options = { headers, params };
      const res = await axios.get(
        `${hardBaseUrl}/unclaim/header/${selectedDsp?.dsp_id}`,
        options
      );
      const { data } = res?.data;
      const updatedList = [addFilterAll("header_id"), ...data];
      setOptionProcessInfo(updatedList);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getDataTable = async () => {
    setLoadingPage(true);
    try {
      const res = await axios.get(`${hardBaseUrl}/unclaim/datatable`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      setDataTable(data);
      const pageCount = getTotalPage(meta?.found, queryParams?.per_page);
      setTablePageCount(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceGetTableData = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const postClaimSong = async () => {
    setLoadingButton(true);
    const modifiedSelectedSong = selectedSongClaim?.map(item => ({
      ...item,
      publisher_id: publisherId,
    }));
    let payload = {
      dsp_id: Number(selectedDsp?.dsp_id),
      t_unclaim_detail: modifiedSelectedSong,
    };
    try {
      const res = await axios.post(`${hardBaseUrl}/unclaim/claim`, payload, {
        headers,
      });
      handleShowReportDetail(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
    }
  };

  useEffect(() => {
    getMe();
  }, []);
  useEffect(() => {
    if (publisherId) {
      getOptionDSP();
    }
  }, [publisherId]);
  useEffect(() => {
    if (selectedDsp?.dsp_id) {
      getProcessInfoList();
    }
  }, [processInfoParams, selectedDsp]);
  useEffect(() => {
    if (selectedProcessInfo?.length > 0) {
      debounceGetTableData();
      return () => {
        debounceGetTableData.cancel();
      };
    }
  }, [queryParams, debounceGetTableData]);
  useEffect(() => {
    handleResetFilter("filter");
  }, [selectedDsp]);

  const columnTable = [
    {
      name: "all",
      title: "",
      renderText: item => (
        <Checkbox
          className={classes?.selectedCheckbox}
          checked={selectedSongClaim?.some(
            selected => selected?.id === item?.id
          )}
          onChange={e => handleSelectSong(e, item)}
        />
      ),
    },
    {
      name: "song_title",
      title: "Song Title",
    },
    {
      name: "isrc_code",
      title: "ISRC Code",
    },
    {
      name: "iswc",
      title: "ISWC Code",
    },
    {
      name: "composer_names",
      title: "Composer/Author",
    },
    {
      name: "artist",
      title: "Artist",
    },
    {
      name: "number_of_streams",
      title: "Number of Stream",
    },
    {
      name: "all",
      title: "DSP Name",
      renderText: () => selectedDsp?.name,
    },
    {
      name: "process_date",
      title: "Process Date",
      renderText: date => formatDate(date),
    },
    {
      name: "all",
      title: "Period Date",
      renderText: item =>
        `${formatDate(item?.start_date)} - ${formatDate(item?.end_date)}`,
    },
  ];

  return (
    <Page className={classes?.root} title="Song Claim">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Song Claim" breadcrumbData={breadcrumbData} />
          <Divider className={classes?.divider} />
          <ButtonGroupTop items={topMenuButton(true)} />
          <Grid
            container
            justifyContent={
              selectedProcessInfo.length > 0 ? "space-between" : "right"
            }
            spacing={1}
            direction="row-reverse"
          >
            <Grid item>
              <Grid container columnSpacing={1} direction="row">
                <Grid item>
                  <DateRangePicker
                    label="Date Range"
                    startDate={processInfoParams?.start_date}
                    handleChangeStartDate={date =>
                      handleChangeProcessInfoParams(date, "start_date")
                    }
                    endDate={processInfoParams?.end_date}
                    handleChangeEndDate={date =>
                      handleChangeProcessInfoParams(date, "end_date")
                    }
                  />
                </Grid>
                <Grid item>
                  <AutoCompleteComponent
                    label="Select DSP"
                    options={optionDsp}
                    value={
                      optionDsp?.find(
                        option => option?.id === Number(selectedDsp?.dsp_id)
                      ) || null
                    }
                    onChange={handleChangeFilterDSP}
                    width={300}
                    size="small"
                  />
                </Grid>
                {optionProcessInfo.length > 1 && (
                  <Grid item>
                    <MultipleSelectInput
                      label="Process Info"
                      placeholder="Process Info"
                      value={selectedProcessInfo}
                      textValue={
                        selectedProcessInfo.length > 0 &&
                        selectedProcessInfo.some(
                          item => item?.header_id === "all"
                        )
                          ? "All"
                          : arrayToCommaSeparatedString(
                              selectedProcessInfo,
                              "header_id"
                            )
                      }
                      options={optionProcessInfo}
                      optionKey={"header_id"}
                      onChange={handleChangeFilterProcessInfo}
                      checkBoxLabel={item =>
                        optionProcessInfo?.length
                          ? item?.header_id === "all"
                            ? "All"
                            : `${item?.header_id} : (${formatDate(
                                item?.start_date
                              )} - ${formatDate(item?.end_date)})`
                          : null
                      }
                      width={300}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {selectedProcessInfo?.length > 0 && (
              <Grid item>
                <SearchTextInput
                  label="Search"
                  placeholder="Song Title, Composer, Artist, ISWC, ISRC"
                  value={queryParams?.search}
                  onChange={event => {
                    handleChangeQueryParams(event?.target?.value, "search");
                    handleResetPage();
                  }}
                  width={300}
                />
              </Grid>
            )}
          </Grid>

          {selectedProcessInfo.length > 0 ? (
            <div className={classes?.tableContainer}>
              <InnoTableV2
                isLoading={loadingPage}
                columns={columnTable}
                items={dataTable}
                page={queryParams?.page}
                rowsPerPage={queryParams?.per_page}
                totalPage={tablePageCount}
                handleChangePage={(_, data) =>
                  handleChangeQueryParams(data, "page")
                }
                handleChangeRowsPerPage={event => handleChangePageSize(event)}
              />
              {selectedSongClaim?.length > 0 && (
                <Grid
                  container
                  justifyContent="flex-end"
                  className={classes?.butonClaimContainer}
                >
                  <Grid item>
                    <Button
                      startIcon={
                        loadingButton && (
                          <CircularProgress
                            size={16}
                            className={classes?.circularButton}
                          />
                        )
                      }
                      disabled={loadingButton}
                      className={classes?.buttonBlack}
                      onClick={postClaimSong}
                    >
                      {`${
                        loadingButton
                          ? "Processing Claim ..."
                          : "Claim This Song"
                      }`}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </div>
          ) : (
            <Typography className={classes?.tablePlaceholder}>
              Select Date Range and DSP to view song unclaim&apos;s data
            </Typography>
          )}
          <ReportModalWithTable
            detailReport={detailReport}
            modalVisible={modalReportVisible}
            onClose={handleCloseModalReport}
          />
        </Container>
      )}
    </Page>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  tablePlaceholder: {
    margin: 40,
    fontSize: 18,
    fontWeight: 400,
    color: "#687083",
    textAlign: "center",
  },
  buttonBlack: {
    backgroundColor: "#111827",
    color: "white",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "#111827",
      color: "white",
    },
    "&.MuiButton-root.Mui-disabled": {
      backgroundColor: "#9ca3af",
      color: "white",
    },
  },
  butonClaimContainer: {
    padding: "16px 0",
  },
  selectedCheckbox: {
    "&.MuiCheckbox-root": {
      color: "black",
      "&.Mui-checked": {
        color: "black",
      },
    },
  },
  circularButton: {
    color: "white",
  },
}));
export default SongUnclaim;
