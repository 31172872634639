import { Chip, Grid } from "@material-ui/core";

const ArrayChip = ({ list, ...props }) => {
  if (!list || list?.length === 0) {
    return "-";
  } else {
    const maxList = list?.slice(0, 4);
    const restList = list?.length > 3;
    const rest = list?.slice(3);
    return (
      <Grid container spacing={1}>
        {maxList?.map((item, index) => (
          <Grid item key={index} {...props}>
            <Chip label={item?.name || item} size="small" className={item?.className || ""} />
          </Grid>
        ))}
        {restList && (
          <Grid item {...props}>
            <Chip label={`+${rest.length}`} size="small" />
          </Grid>
        )}
      </Grid>
    );
  }
};

export default ArrayChip;
