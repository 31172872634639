import { Container, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  AutoCompleteComponent,
  ModalError,
  MonthPicker,
  Page,
  SearchTextInput,
  SkeletonComponent,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { debounce } from "lodash";
import moment from "moment-timezone";
import { useCallback, useEffect, useState } from "react";
import { formatDate, getErrors, wordCapitalize } from "utils";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { hardBaseUrl } from "../../../../services/urlConstant";

const BillingDsp = () => {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [optionDSP, setOptionDSP] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [tablePageCount, setTablePageCount] = useState(1);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
    search: "",
    month: "",
    dsp_id: "",
  });

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleResetPage = () => handleChangeQueryParams(1, "page");
  const handleChangeFilterMonth = date => {
    handleChangeQueryParams(date, "month");
  };
  const handleChangeRowsPerPage = event => {
    const value = event?.target?.value;
    handleChangeQueryParams(value, "per_page");
    handleResetPage();
  };
  const handleChangeFilterDSP = value => {
    handleChangeQueryParams(value, "dsp_id");
  };
  const handleChangeSearch = event => {
    const value = event?.target?.value;
    handleChangeQueryParams(value, "search");
    handleResetPage();
  };

  const getOptionDSP = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/dsp`, {
        headers,
      });
      const { data } = res?.data;
      const modifiedData = data?.map(item => ({
        label: item?.name,
        id: item?.dsp_id,
      }));
      setOptionDSP(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getDataTable = async () => {
    setLoadingPage(true);
    try {
      const res = await axios.get(`${hardBaseUrl}/billing/dsp`, {
        headers,
        params: queryParams,
      });
      const { data } = res?.data;
      setDataTable(data?.data);
      setTablePageCount(data?.total_page || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debouncedGetDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );

  useEffect(() => {
    getOptionDSP();
  }, []);
  useEffect(() => {
    debouncedGetDataTable();
    return () => {
      debouncedGetDataTable.cancel();
    };
  }, [queryParams, debouncedGetDataTable]);

  return (
    <Page className={classes.root} title="Billing DSP">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Billing" breadcrumbData={breadcrumbData} />
          <Divider className={classes.divider} />
          <div className={classes?.spacingContainer}>
            <ButtonGroupTop items={topMenuButton} />
          </div>

          <Grid container justifyContent="space-between">
            <Grid item>
              <SearchTextInput
                placeholder="Search"
                value={queryParams?.search}
                onChange={handleChangeSearch}
              />
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1}>
                <Grid item>
                  <MonthPicker
                    value={queryParams?.month}
                    onChange={handleChangeFilterMonth}
                  />
                </Grid>
                <Grid item>
                  <AutoCompleteComponent
                    options={optionDSP}
                    label="Publisher"
                    value={
                      optionDSP.find(
                        option => option.id === queryParams?.dsp_id
                      ) || null
                    }
                    onChange={value => handleChangeFilterDSP(value)}
                    width={220}
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes?.spacingContainer}>
            <InnoTableV2
              columns={columnTable}
              handleChangePage={(_, value) =>
                handleChangeQueryParams(value, "page")
              }
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              isLoading={loadingPage}
              items={dataTable || []}
              page={queryParams?.page}
              rowsPerPage={queryParams?.per_page}
              totalPage={tablePageCount}
            />
          </div>
        </Container>
      )}
    </Page>
  );
};
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  spacingContainer: { marginTop: 24 },
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Billing",
    active: true,
  },
];
const topMenuButton = [
  {
    id: 1,
    title: "Publisher",
    link: "/admin/billing/publisher",
  },
  {
    id: 2,
    title: "DSP",
    link: "/admin/billing/dsp",
  },
];
const columnTable = [
  {
    name: "transaction_number",
    title: "Invoice Number",
  },
  {
    name: "publisher",
    title: "Publisher",
  },
  {
    name: "date",
    title: "Date",
    renderText: date => formatDate(moment(date, "DD MMMM YYYY")),
  },
  {
    name: "period",
    title: "Period",
  },
  {
    name: "value",
    title: "Value",
  },
  {
    name: "billing_value",
    title: "Billing Value",
  },
  {
    name: "status",
    title: "Status",
    renderText: item => wordCapitalize(item),
  },
];

export default BillingDsp;
