import {
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import RateReviewIcon from "@material-ui/icons/RateReview";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTable } from "inno-ui";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import HeaderPage from "../../../../layouts/Header/HeaderPage";
import { Page, SkeletonComponent } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {
    width: "180px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterContainer: {
    marginBottom: 20,
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  inputFields: {
    "& .MuiOutlinedInput-input": {
      padding: "0px",
      height: "44px",
      width: "268px",
    },
  },
  selectPadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
  },
  ib: {
    padding: "4px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",

      boxShadow: "none",
    },
  },
}));

function FinalisasiBeritaIklan() {
  const classes = useStyles();
  const [breadcrumbs] = useState(["Home", "News & Ads Finalization"]);

  const [response, setResponse] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalData] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [type, setType] = useState("");
  const [uploadTime, setUploadTime] = useState("-created_at");
  const [loadingTable, setLoadingTable] = useState(false);

  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const handleChangeSelectUploadTime = event => {
    setUploadTime(event.target.value);
  };

  const getResponse = () => {
    setLoadingTable(true);
    let theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/article/pending?page=${page}&size=${rowsPerPage}&search=${searchKey}&type=${type}&sort=${uploadTime}`;

    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        setResponse(res.data.data);

        setLoadingTable(false);
      })
      .catch(() => {
        setLoadingTable(false);
      });
  };

  const handleChangeSelect = event => {
    setType(event.target.value);
    setPage(1);
  };

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };
  const handleChangeSearch = event => {
    setPage(1);
    setSearchKey(event.target.value);
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };

  useEffect(() => {
    getResponse();
    localStorage.removeItem("title");
    localStorage.removeItem("content");
    localStorage.removeItem("image_logo");
    localStorage.removeItem("raw_img");
    localStorage.removeItem("article_id");
    localStorage.removeItem("type");
    localStorage.removeItem("raw_content");
    localStorage.removeItem("recentDate");
  }, [rowsPerPage, page, searchKey, type, uploadTime]);
  return (
    <Page className={classes.root} title="News & Ads Finalization">
      {loadingTable ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderPage
            title="News & Ads Finalization"
            breadcrumbs={breadcrumbs}
          />
          <Divider className={classes.divider} />
          <Grid container className={classes.filterContainer}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl className={classes.margin} variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-amount"
                  className={classes.inputFields}
                  value={searchKey}
                  onChange={event => handleChangeSearch(event)}
                  startAdornment={
                    <InputAdornment position="start">
                      <img
                        alt="Logo"
                        src={
                          require("assets/image-public/images/search.svg")
                            .default
                        }
                      />
                    </InputAdornment>
                  }
                  fullWidth={false}
                  placeholder="Search"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Select
                      displayEmpty={true}
                      className={classes.selectPadding}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={type}
                      onChange={handleChangeSelect}
                      autoWidth={true}
                    >
                      <MenuItem value={""}>All Type</MenuItem>
                      <MenuItem value={"berita"}>News</MenuItem>
                      <MenuItem value={"edukasi"}>Education</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Select
                      displayEmpty={true}
                      className={classes.selectPadding}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={uploadTime}
                      onChange={handleChangeSelectUploadTime}
                      autoWidth={true}
                    >
                      <MenuItem value="-created_at">Latest</MenuItem>
                      <MenuItem value={"created_at"}>Oldest</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {response && (
            <InnoTable
              columns={[
                {
                  name: "title",
                  title: "Title",
                  renderText: item => {
                    return item || "-";
                  },
                },
                {
                  name: "type",
                  title: "Type",
                  renderText: item => {
                    return item || "-";
                  },
                },
                {
                  name: "publisher",
                  title: "Publisher",
                  renderText: item => {
                    return item.name || "-";
                  },
                },
                {
                  name: "date",
                  title: "Date",
                  renderText: item => {
                    return item || "-";
                  },
                },
              ]}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              renderAction={customer => (
                <IconButton
                  component={RouterLink}
                  classes={{
                    root: classes.ib,
                  }}
                  to={{
                    pathname: `/admin/finalisasi-berita/view/${customer.article_id}`,
                    state: {
                      customer: customer,
                    },
                  }}
                >
                  <RateReviewIcon />
                </IconButton>
              )}
              idColumnName={"id"}
              isLoading={loadingTable}
              isHaveAction={true}
              items={response || []}
              loadingColor={""}
              page={page}
              rowsPerPage={rowsPerPage}
              totalPage={pagesCount}
            />
          )}
        </Container>
      )}
    </Page>
  );
}

export default FinalisasiBeritaIklan;
