import { Container, Divider } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SelectInput,
  SkeletonComponent,
  StatusChip,
} from "components";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { getErrors, textCapitalization } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";

function BeritaIklanPublisher() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");
  const paramsType = urlParams.get("type");
  const paramsStatus = urlParams.get("status");

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: Number(paramsPage) || 1,
    size: Number(paramsSize) || 10,
    search: paramsSearch || "",
    type: paramsType || "",
    is_approved: paramsStatus || "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleChangeSearch = event => {
    const value = event?.target?.value;
    handleChangeQueryParams(value, "search");
    handleChangePageParams(value, "search");
  };
  const handleChangePage = page => {
    handleChangeQueryParams(page, "page");
    handleChangePageParams(page, "page");
  };
  const handleChangePageSize = size => {
    handleChangeQueryParams(size, "size");
    handleChangePageParams(size, "size");
  };
  const handleChangeType = type => {
    handleChangeQueryParams(type, "type");
    handleChangePageParams(type, "type");
  };
  const handleChangeStatus = item => {
    const status = item === 1 ? true : item === 0 ? false : "";
    handleChangeQueryParams(status, "is_approved");
    handleChangePageParams(status?.toString(), "status");
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/article`, {
        headers,
        params: queryParams,
      });

      const { data, meta } = res?.data;
      setDataTable(data);
      const pageCount = getTotalPage(meta?.total, queryParams?.size || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );

  useEffect(() => {
    debounceDataTable();
    if (queryParams?.page !== paramsPage) {
      handleChangePageParams(queryParams?.page, "page");
    }
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  const handleDelete = async item => {
    try {
      setLoadingPage(true);
      const res = await axios.delete(
        `${hardBaseUrl}/article/${item?.article_id}`,
        { headers }
      );

      const { message } = res?.data;
      if (message === "success") {
        ModalSuccess("Data Deleted Successfully").then(result => {
          if (result.isConfirmed === true) {
            getDataTable();
          }
        });
      }
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  return (
    <Page className={classes.root} title="News & Ads">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="News" breadcrumbData={breadcrumbData} />
          <Divider className={classes.divider} />
          <Grid container justifyContent="space-between" mb="16px">
            <Grid item>
              <SearchTextInput
                placeholder="Search"
                value={queryParams?.search}
                onChange={handleChangeSearch}
              />
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1} alignItems="center">
                <Grid item>
                  <SelectInput
                    label="Type"
                    options={optionType}
                    optionKey="key"
                    optionLabel="label"
                    value={queryParams?.type || ""}
                    onChange={e => handleChangeType(e?.target?.value)}
                    placeholder="All Type"
                    width={200}
                  />
                </Grid>
                <Grid item>
                  <SelectInput
                    label="Status"
                    options={optionStatus}
                    optionKey="key"
                    optionLabel="label"
                    value={
                      queryParams?.is_approved === ""
                        ? ""
                        : queryParams?.is_approved
                        ? 1
                        : 0
                    }
                    onChange={e => handleChangeStatus(e?.target?.value)}
                    placeholder="All Status"
                    width={200}
                  />
                </Grid>
                <Grid item>
                  <PrimaryButton
                    label="Create News/ Ads"
                    startIcon={<Add />}
                    onClick={() =>
                      history.push("/admin/berita-publisher/tambah")
                    }
                    size="large"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <InnoTable
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) => handleChangePage(page)}
            handleChangeRowsPerPage={e =>
              handleChangePageSize(e?.target?.value)
            }
            isHaveAction={true}
            handleEdit={item => {
              history.push(`/admin/berita-publisher/edit/${item.article_id}`, {
                customer: item,
              });
            }}
            handleDelete={handleDelete}
            isUsingDeleteConfirmation={true}
            deleteName={"title"}
          />
        </Container>
      )}
    </Page>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "News",
    active: true,
  },
];
const optionType = [
  {
    key: "berita",
    label: "News",
  },
  {
    key: "edukasi",
    label: "Education",
  },
];
const optionStatus = [
  {
    key: 1,
    label: "Approved",
  },
  {
    key: 0,
    label: "In Submission",
  },
];
const columnTable = [
  {
    name: "title",
    title: "Title",
  },
  {
    name: "type",
    title: "Type",
    renderText: item => textCapitalization(item),
  },
  {
    name: "approved_by",
    title: "Status",
    renderText: item => {
      return item === 0 ? (
        <StatusChip label={"In Submission"} />
      ) : (
        <StatusChip type="success" label={"Approved"} />
      );
    },
  },
  {
    name: "date",
    title: "Date",
    renderText: item => moment(item).format("DD MMM YYYY"),
  },
];

export default BeritaIklanPublisher;
