import { Container, Divider } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import {
  ModalError,
  Page,
  PrimaryButton,
  SearchTextInput,
  SkeletonComponent,
} from "components";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { StatusChip } from "../../../components";
import { hardBaseUrl } from "../../../services/urlConstant";
import { emptyText, formatDate, getErrors } from "../../../utils";
import { Grid } from "@mui/material";

function KontrakOriginalPublisher() {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    search: "",
    offset: 0,
    composer: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/contract/original-publisher`,
        {
          headers,
          params: queryParams,
        }
      );

      const { data, meta } = res?.data;
      setDataTable(data?.contracts);
      setTableTotalPage(meta?.total);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );

  useEffect(() => {
    debounceDataTable();
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Contract">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Contract Original Publisher"
            breadcrumbData={breadcrumbData}
          />
          <Divider className={classes.divider} />
          <Grid container justifyContent="space-between" mb="16px">
            <SearchTextInput
              placeholder="Search"
              value={queryParams?.search}
              onChange={e =>
                handleChangeQueryParams(e?.target?.value, "search")
              }
            />
            <PrimaryButton
              label="Create Contract"
              startIcon={<AddIcon />}
              onClick={() =>
                history.push("/admin/kontrak-original-publisher/tambah")
              }
            />
          </Grid>
          <InnoTable
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.limit}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "limit")
            }
            isHaveAction={true}
            handleView={item =>
              history.push({
                pathname: `/admin/kontrak-original-publisher/${item?.contract_id}`,
                state: { customer: item },
              })
            }
          />
        </Container>
      )}
    </Page>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: "16px 0",
    borderTop: "1px solid #e1e1e1",
  },
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Contract Original Publisher",
    active: true,
  },
];
const columnTable = [
  {
    name: "contract_id",
    title: "Contract Number",
    renderText: item => emptyText(item?.contract_id),
  },
  {
    name: "songs",
    title: "Song Title",
    componentType: "text",
    selectedKeyInside: "song_title",
  },
  {
    name: "original_publishers",
    title: "Publisher",
    selectedKeyInside: "name",
    componentType: "chip",
  },
  {
    name: "start_date",
    title: "Start date",
    renderText: item => formatDate(moment(item, "DD-MM-YYYY HH:mm")),
  },
  {
    name: "end_date",
    title: "End Date",
    renderText: item => formatDate(moment(item, "DD-MM-YYYY HH:mm")),
  },
  {
    name: "is_active_flag",
    title: "Status",
    renderText: status => (
      <StatusChip
        type={status && "success"}
        label={status ? "Active" : "Inactive"}
      />
    ),
  },
];

export default KontrakOriginalPublisher;
