import { TextInput } from "components";

const CurrencyNumberInput = ({
  label,
  required,
  value,
  onChange,
  startAdornment,
  width,
  placeholder,
  disabled,
}) => {
  return (
    <TextInput
      value={value ? value : ""}
      onChange={onChange}
      width={width}
      placeholder={placeholder || "0"}
      disabled={disabled}
      startAdornment={startAdornment || "Rp"}
      inputMode="numeric"
      label={label}
      required={required}
    />
  );
};

export default CurrencyNumberInput;
