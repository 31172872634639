import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../../services/urlConstant";
import ContractForm from "./Components/ContractForm";

function EditContract() {
  const history = useHistory();
  const { state } = useLocation();
  const contractId = state?.id;
  const contractType =
    state?.customer?.category === "song" ? "Song Contract" : "Main Contract";
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: "Bearer " + token,
  };
  const isSongContract = contractType === "Song Contract";
  const urlContract = `${hardBaseUrl}/publisher/contract`;
  const [loadingContract, setLoadingContract] = useState(false);
  const [contractDetailPreload, setContractDetailPreload] = useState({});

  const modalError = err =>
    Swal.fire({
      title: "Error",
      text: err?.response?.data?.errors?.message,
      icon: "error",
      confirmButtonText: "OK",
    });

  const modalSuccess = () =>
    Swal.fire({
      title: "Success",
      text: "Data has been saved",
      icon: "success",
      confirmButtonText: "OK",
    });

  const getDetailContract = async () => {
    const mainContractFormEndpoint = !isSongContract ? "/composer-main" : "";
    setLoadingContract(true);

    await axios
      .get(`${urlContract}${mainContractFormEndpoint}/${contractId}`, {
        headers,
      })
      .then(res => {
        setContractDetailPreload(res?.data?.data);
        setLoadingContract(false);
      })
      .catch(() => {
        setLoadingContract(false);
      });
  };
  useEffect(() => {
    getDetailContract();
  }, []);

  const handlSubmitData = payload => {
    setLoadingContract(true);
    const mainContractFormEndpoint = !isSongContract ? "/composer-main" : "";

    axios
      .put(`${urlContract}${mainContractFormEndpoint}/${contractId}`, payload, {
        headers,
      })
      .then(() => {
        setLoadingContract(false);
        modalSuccess().then(result => {
          if (result.isConfirmed) {
            history.push("/admin/kontrak-composer");
          }
        });
      })
      .catch(err => {
        modalError(err);
        setLoadingContract(false);
      });
  };

  return (
    <ContractForm
      title="Edit Contract"
      handlSubmitData={handlSubmitData}
      loadingContract={loadingContract}
      stateContractType={contractType}
      preload={contractDetailPreload}
      contractId={contractId}
    />
  );
}

export default EditContract;
