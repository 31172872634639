import React, { useState, useEffect } from "react";
import { Router } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { createBrowserHistory } from "history";
import MomentUtils from "@date-io/moment";
import { Provider as StoreProvider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.css";
import { theme, themeWithRtl } from "./theme";
import config from "./redux/config";
import routes from "./routes";
import mpisRoutes from "./routes/mpisRoutes";
import carisRoutes from "./routes/carisRoutes";
import axios from "axios";
import "./mixins/moment";
import "./mixins/validate";
import "./mixins/prismjs";
// import "./mock";
import "./assets/scss/main.scss";
import {
  getErrors,
  getSubdomain,
  getCookie,
  setVersion,
  clearCookie,
} from "utils";
import "boxicons/css/boxicons.min.css";
import "antd/dist/antd.min.css";
import { API_ENDPOINTS, TOKEN } from "constants/endpoint";
import Swal from "sweetalert2";
import { carisHosts } from "constants/subMenu";
import ScrollReset from "./layouts/ScrollReset/ScrollReset";

const history = createBrowserHistory();
const store = config();

function App() {
  const [direction] = useState("ltr");
  const [faviconMpis, setFaviconMpis] = useState("");
  const [selectRoutes, setSelectRoutes] = useState(routes);
  const getFaviconEl = () => {
    return document.getElementById("favicon");
  };
  const feVersion = getCookie(
    "authVersion",
    `${process.env.REACT_APP_NAME}_fe`
  );

  const beVersion = getCookie(
    "authVersion",
    `${process.env.REACT_APP_NAME}_be`
  );

  const fetchFavicon = async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.GET_FAVICON);
      setFaviconMpis(response.data);
    } catch (error) {
      getErrors(error.response);
      throw error;
    }
  };
  console.log(feVersion, "feVersion");
  const fetchVersion = async () => {
    try {
      const { data } = await axios.get(API_ENDPOINTS.GET_VERSION);
      const { fe } = data.data;
      const feVersionData = localStorage.getItem("feVersion");

      if (!feVersionData) {
        setVersion(data.data);
        localStorage.setItem("feVersion", fe);
      } else if (fe !== feVersionData) {
        if (localStorage.getItem("token")) {
          Swal.fire({
            icon: "success",
            title: "Update Successful!",
            text: "Your application has been updated to the latest version.",
            didClose: clearCookie,
          });
        }
      }
    } catch (error) {
      getErrors(error.response);
      throw error;
    }
  };

  useEffect(() => {
    fetchVersion();
    const intervalId = setInterval(() => {
      fetchVersion();
    }, 5 * 60 * 1000); // 5 menit * 60 detik * 1000 milidetik
    return () => clearInterval(intervalId);
  }, []); // dependencies array kosong agar useEffect dijalankan sekali saja

  const fetchStageApp = () => {
    const favicon = getFaviconEl();
    const hostname = window.location.hostname.split(".")[0];

    if (carisHosts.includes(hostname)) {
      setSelectRoutes(carisRoutes);
      document.title = "CARIS";
      localStorage.setItem("themeColor", "#111827");
    } else {
      setSelectRoutes(mpisRoutes);
      if (faviconMpis) favicon.href = faviconMpis;
      document.title = "MPIS";
      localStorage.setItem("themeColor", "#9545eb");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getSubdomain();
      await fetchFavicon();
      await fetchStageApp();
    };

    fetchData();
  }, []);

  return (
    <>
      <StoreProvider store={store}>
        <ThemeProvider theme={direction === "rtl" ? themeWithRtl : theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <ScrollReset />
              {renderRoutes(selectRoutes, { someProp: "vvvnnnmmm" })}
            </Router>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </StoreProvider>
    </>
  );
}

export default App;
