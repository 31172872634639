import {
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { ButtonGroupTop } from "../../../components/atoms/Button";
import HeaderPage from "../../../layouts/Header/HeaderPage";
import { Page } from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import DialogDokumen from "./DialogDokumen";
import ResultsDokumen from "./ResultsDokumen";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  content: {
    marginTop: "20px",
  },
  contentAdd: {
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    fontFamily: ["Helvetica Neue"].join(","),
  },

  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",

    "& .MuiOutlinedInput-root": {
      width: "312px",
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  selectPadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
      width: "160px",
    },
  },
  inputFilter: {
    width: "160px",
    height: "44px",

    borderRadius: "4px",
  },
  listMenu: {
    "& .MuiMenu-list": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  myCheckBox: {
    "&.MuiCheckbox-root": {
      color: "black",

      "&.Mui-checked": {
        color: "black",
      },
    },
  },
  ib: {
    paddingRight: "7px",
    paddingLeft: "6px",
    paddingTop: "1px",
    paddingBottom: "2px",

    backgroundColor: "#FCF3F2",
    border: "1px solid #F1A69E",

    "&:focus": {
      color: "blue",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "pink",
      borderColor: "#005cbf",
    },
    "&:hover": {
      boxShadow: "none",
    },
  },
}));

function DokumenPendukung(props) {
  const classes = useStyles();
  const history = useHistory();
  const [response, setResponse] = useState([]);
  const type = "";
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [selectDoc, setSelectDoc] = useState(null);

  let theTopMenu = [
    {
      id: 0,
      title: "Detail",
      link: "/pencipta/detail-kontrak/" + props.match.params.id,
      selected: false,
    },
    {
      id: 1,
      title: "Supporting Documents",
      link: "/pencipta/detail-kontrak-dokumen/" + props.match.params.id,
      selected: true,
    },
    {
      id: 2,
      title: "Activity",
      link: "/pencipta/detail-kontrak-aktifitas/" + props.match.params.id,
      selected: false,
    },
  ];
  const topMenuButton = [
    {
      id: 0,
      title: "Detail",
      link: "/pencipta/detail-kontrak/0",

      selected: false,
    },
    {
      id: 1,
      title: "Supporting documents",

      link: "/pencipta/detail-kontrak-dokumen/0",
      selected: true,
    },
    {
      id: 2,
      title: "Activity",

      link: "/pencipta/detail-kontrak-aktifitas/0",
      selected: false,
    },
  ];

  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const handleOnSelectedButton = value => {
    history.push(value.data[value.newButtonSelected].link);
  };

  const getResponse = () => {
    let theToken = localStorage.getItem("token");

    const url = `${hardBaseUrl}/composer/contract-document/${props.match.params.id}?page=${page}&size=${rowsPerPage}&search=${searchKey}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${theToken}`,
      },
    };

    axios
      .get(url, headers)
      .then(res => {
        setResponse(res.data.data);
        setTotalData(res.data.meta.total);
        setPage(res.data.meta.page);
      })
      .catch(() => {});
  };

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };

  const handleChangeSearch = event => {
    setPage(1);
    setSearchKey(event.target.value);
  };

  const onEdit = () => {};

  const handleDelete = id => {
    const url = `${hardBaseUrl}/publisher/${id}`;
    let theToken = localStorage.getItem("token");

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${theToken}`,
      },
    };

    axios
      .delete(url, headers)
      .then(res => {
        if (res.data.message === "success") {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Data berhasil dihapus",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        }
      })
      .catch(() => {});
  };

  const onClickOpen = item => {
    setSelectDoc(item);
    setOpenEdit(true);
  };

  useEffect(() => {
    getResponse();
  }, [rowsPerPage, page, searchKey]);

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  return (
    <Fragment>
      <Page className={classes.root} title="Dokumen Pendukung">
        <Container maxWidth={false}>
          <HeaderPage
            title="Supporting Documents"
            breadcrumbs={[
              {
                name: "Home",
                url: null,
              },
              {
                name: "Contract",
                url: "/pencipta/kontrak",
              },
              {
                name: "Supporting Documents",
                url: null,
              },
            ]}
          />
          {topMenuButton.length > 0 ? (
            <ButtonGroupTop
              items={theTopMenu}
              selectedButton={handleOnSelectedButton}
              selectedIndex={0}
            />
          ) : (
            <>{""}</>
          )}
          <div className={classes.content}>
            <hr />
            <div style={{ display: "flex", marginTop: "20px" }}>
              <FormControl className={classes.margin} variant="outlined">
                <TextField
                  id="input-with-icon-textfield"
                  variant="outlined"
                  style={{
                    marginRight: "8px",
                  }}
                  className={classes.inputFields}
                  onChange={handleChangeSearch}
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          alt="Logo"
                          src={
                            require("assets/image-public/images/search.svg")
                              .default
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>

              <div
                style={{
                  display: "flex",
                }}
              >
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth={true}
                >
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    shrink={false}
                    style={{
                      marginTop: "-5px",
                    }}
                  >
                    {type !== "" ? null : "All Type"}
                  </InputLabel>
                  <Select
                    style={{
                      width: "180px",
                      height: "44px",

                      borderRadius: "4px",
                    }}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={type}
                    onChange={() => {}}
                    autoWidth={true}
                  >
                    <MenuItem value={""}>Latest</MenuItem>
                    <MenuItem value={"terlama"}>Oldest</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div style={{ marginTop: "30px" }}>
              <ResultsDokumen
                className={classes.results}
                items={response}
                pageSizePagination={pageSizePagination()}
                handleEdit={onEdit}
                totalData={pagesCount}
                page={page}
                handleChangePageSet={handleChangePage}
                handleDelete={handleDelete}
                onClickOpen={onClickOpen}
              />
            </div>
            {selectDoc !== null ? (
              <>
                <DialogDokumen
                  open={openEdit}
                  onClose={handleCloseEdit}
                  item={selectDoc}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </Container>
      </Page>
    </Fragment>
  );
}

export default DokumenPendukung;
