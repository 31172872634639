import { Chip, Grid } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import { stringTruncate } from "utils";

const ArrayTagChip = ({ selectionsList, value }) => {
  return (
    <Grid container spacing={1}>
      {selectionsList?.map((item, index) => {
        return (
          <Grid key={index} item>
            <Chip
              icon={
                <FiberManualRecord
                  style={{
                    height: 8,
                    width: 8,
                    color: item?.color,
                  }}
                />
              }
              label={stringTruncate(item?.[value], 18)}
              size="small"
              style={{
                color: item?.color,
                backgroundColor:
                  item?.color === "#FFF" ? "#6b7280" : `${item?.color}10`,
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ArrayTagChip;
