import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from "@mui/material";

const MenuProps = height => ({
  PaperProps: {
    style: {
      maxHeight: height || 250,
    },
  },
});

const SelectInput = ({
  label,
  placeholder,
  value,
  onChange,
  options = [],
  optionKey,
  optionLabel,
  width,
  height,
  ...rest
}) => {
  const getNestedValue = (obj, path) => {
    return path?.split(".")?.reduce((acc, part) => acc && acc[part], obj);
  };
  return (
    <CustomFormControl customWidth={width}>
      <InputLabel id="select-label" size="small">
        {label}
      </InputLabel>
      <Select
        labelId="select-label"
        label={label}
        value={value}
        onChange={onChange}
        size="small"
        MenuProps={MenuProps(height)}
        {...rest}
      >
        {placeholder && (
          <MenuItem value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options?.map((option, index) => (
          <MenuItem key={index} value={option?.[optionKey]}>
            {getNestedValue(option, optionLabel)}
          </MenuItem>
        ))}
      </Select>
    </CustomFormControl>
  );
};

const CustomFormControl = styled(FormControl)(({ customWidth }) => ({
  width: customWidth,
}));

export default SelectInput;
