import React, { Suspense, useState, useEffect } from "react";
import { renderRoutes } from "react-router-config";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import { LinearProgress } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { useDispatch } from "react-redux";
import { hardBaseUrl } from "../../services/urlConstant";
import axios from "axios";
import Swal from "sweetalert2";
import navConfig from "./navConfig";
import navConfigPublisher from "../DashboardPublisher/navConfig";
import navConfigAssoc from "../DashboardPublisher/navConfig/navConfigAssoc";
import navConfigPencipta from "./navConfigPencipta";
import { setKonfigurasiMenu } from "../../constants/landingPage/konfigurasiMenu";
import { setMasterMenu } from "../../constants/landingPage/masterMenu";
import { setPublisherId } from "../../constants/landingPage/publisherId";
import * as Sentry from "@sentry/react";
import { getErrors, createSideBarMenu, Debugging } from "utils";

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: "white",
    minHeight: "100vh",
    display: "flex",
    "@media all and (-ms-high-contrast:none)": {
      height: 0, // IE11 fix
    },
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 56,
    },
  },
  content2: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 56,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    container:
      "swal2-container swal2-center swal2-backdrop-show swal2-container-me",
  },
  showClass: {
    backdrop: "swal2-backdrop-show",
  },
  buttonsStyling: true,
});

function DashboardAdmin(props) {
  const classes = useStyles();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [loadingComp, setLoadingComp] = useState(false);
  const [theSelectedItems, setTheSelectedItems] = useState([]);
  const [userLetter, setUserLetter] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [notificationUpdate, setNotificationUpdate] = useState(false);
  const [notificationTotal, setNotificationTotal] = useState(0);
  const [userLogin, setUserLogin] = useState(null);
  const [afterGetMe, setAfterGetMe] = useState(false);
  const [calculateMenus, setCalculateMenus] = useState(false);
  const [aterCalculateMenus, setAterCalculateMenus] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const roleId = localStorage?.getItem("role_id");
        const token = localStorage.getItem("token");

        setLoadingComp(true);

        const urlMe = `${hardBaseUrl}/me${roleId ? `/${roleId}` : ""}`;
        const resultMe = await axios.get(urlMe, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const userData = resultMe?.data?.data;
        setUserLogin(userData);
        setAfterGetMe(true);

        if (!roleId) {
          localStorage.setItem("role_id", userData?.role_id);
        }

        const urlNotifications = `${hardBaseUrl}/notification?page=1&size=10`;
        const responseNotifications = await axios.get(urlNotifications, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setNotifications(responseNotifications.data.data);

        if (notificationUpdate) {
          const responseUpdatedNotifications = await axios.get(
            urlNotifications,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          setNotifications(responseUpdatedNotifications.data.data);
          setNotificationUpdate(false);
        }

        const urlNotificationTotal = `${hardBaseUrl}/notification/unread`;
        const responseNotificationTotal = await axios.get(
          urlNotificationTotal,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        setNotificationTotal(responseNotificationTotal.data.data);
      } catch (error) {
        handleFetchError(error);
      }
    };

    fetchData();
  }, [location.pathname]);

  const handleFetchError = error => {
    swalWithBootstrapButtons
      .fire({
        icon: "error",
        title: "Failed!",
        text:
          error?.response?.data?.errors[0]?.message || "Something went wrong",
        showCloseButton: false,
      })
      .then(result => {
        if (result?.isDismissed) {
          history.push("/");
        }
      });
  };

  useEffect(() => {
    if (afterGetMe) {
      try {
        handleUserLoginRole();
      } catch (error) {
        handleRoleError(error);
      }
    }
  }, [afterGetMe]);

  const handleUserLoginRole = () => {
    if (
      userLogin.role.type === "publisher" ||
      userLogin.role.type === "composer"
    ) {
      dispatch(setPublisherId(userLogin.publisher.publisher_id));
    }

    Debugging.devLog(
      userLogin.role.type,
      "../../layouts/DashboardAdmin/index.js",
      true
    );

    setUserLetter("M");
    setCalculateMenus(true);
    setAfterGetMe(false);
  };

  const handleRoleError = error => {
    Sentry.captureMessage("Error afterGetMe: " + error.message);
    swalWithBootstrapButtons
      .fire({
        icon: "error",
        title: "Failed After Get Me!",
        text: error?.response || "Something went wrong",
        showCloseButton: false,
      })
      .then(result => {
        if (result.isConfirmed) {
          setCalculateMenus(true);
          setAfterGetMe(false);
        }
      });
  };

  useEffect(() => {
    if (calculateMenus) {
      try {
        configureMenus();
      } catch (err) {
        handleMenuError(err);
      }

      setLoadingComp(false);
      setCalculateMenus(false);
      setAterCalculateMenus(true);
    }
  }, [calculateMenus]);

  const configureMenus = () => {
    let navLocal = null;

    switch (userLogin.role.type) {
      case "publisher":
      case "society":
        navLocal = navConfigPublisher;
        handleNavLocalPermissions(navLocal, "Konfigurasi", "Master");
        break;
      case "association":
        navLocal = navConfigAssoc;
        handleNavLocalPermissions(navLocal, "Konfigurasi", "Master");
        break;
      case "composer":
        navLocal = navConfigPencipta;
        break;
      default:
        navLocal = navConfig;
        handleNavLocalPermissions(navLocal, "Configuration", "Master");
    }

    const sideMenus = createSideBarMenu(userLogin.permissions, navLocal);
    setTheSelectedItems(sideMenus);
  };

  const handleNavLocalPermissions = (navLocal, configText, masterText) => {
    const configPermission = findPermission(userLogin.permissions, configText);
    if (configPermission) {
      dispatch(setKonfigurasiMenu(configPermission));
      setFirstHref(navLocal, configPermission, "Konfigurasi");
    }

    const masterPermission = findPermission(userLogin.permissions, masterText);
    if (masterPermission) {
      masterPermission.display_groupname = "Parameter";
      masterPermission.group_text = "Parameter";
      dispatch(setMasterMenu(masterPermission));
      setFirstHref(navLocal, masterPermission, "Master");
    }
  };

  const findPermission = (permissions, groupText) => {
    return _.find(permissions, o => o.group_text === groupText);
  };

  const setFirstHref = (navLocal, permission, title) => {
    const firstMenu = permission.child[0].display_name;
    let firstHref = getHrefByMenu(firstMenu);

    navLocal[0].items.forEach(item => {
      if (item.title === title) {
        item.href = firstHref;
      }
    });
  };

  const getHrefByMenu = menu => {
    const hrefMap = {
      Personalization: "/admin/konfigurasi/personalisasi",
      "Cost Type": "/admin/konfigurasi/cost-type",
      Payment: "/admin/konfigurasi/pembayaran",
      "Original Publisher": "/admin/konfigurasi/original-publisher",
      "Sub-Publisher": "/admin/konfigurasi/original-publisher",
      Activity: "/admin/konfigurasi/aktifitas",
      System: "/admin/konfigurasi/sistem",
      "Notification Template": "/admin/konfigurasi/template-notifikasi",
      Tax: "/admin/konfigurasi/pajak",
      Role: "/admin/konfigurasi/role-user",
      "Activity Admin": "/admin/konfigurasi/aktifitas-admin",
      Composer: "/admin/parameter/pencipta",
      Agent: "/admin/parameter/agent",
      "Client Name": "/admin/parameter/dsp",
      Performer: "/admin/parameter/performer",
      Lagu: "/admin/parameter/lagu",
      DSP: "/admin/parameter/dsp",
      User: "/admin/parameter/user",
      Publisher: "/admin/parameter/publisher",
      "User Admin": "/admin/parameter/user-admin",
      "Publisher User": "/admin/parameter/user-publisher",
      Song: "/admin/parameter/lagu-admin",
      Bank: "/admin/parameter/bank-admin",
    };

    return hrefMap[menu] || null;
  };

  const handleMenuError = err => {
    Sentry.captureMessage("Error calculateMenus: " + err.message);
    swalWithBootstrapButtons.fire({
      icon: "error",
      title: "Failed Calculate Menu!",
      text: err?.message || "Error",
      showCloseButton: false,
    });
  };

  const handleMarkAsRead = async (e, id) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/notification/${id}`;

    try {
      await axios.put(
        url,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setNotificationUpdate(true);
    } catch (error) {
      let errMessage = getErrors(error.response);
      Sentry.captureMessage("Error catch update notif: " + errMessage);
    }
  };

  const navigationCondition =
    window.location.pathname === "/admin/berita-publisher/tambah/preview" ||
    window.location.pathname === "/admin/berita-publisher/edit/preview" ||
    window.location.pathname === "/admin/berita/tambah/preview" ||
    window.location.pathname === "/admin/berita/edit/preview" ||
    window.location.pathname === "/admin/finalisasi-berita/view/preview" ||
    window.location.pathname !== "/admin/manajemen-web/preview";
  return (
    <>
      {navigationCondition && (
        <>
          <TopBar
            userProfileImage={userLogin?.display_picture_url}
            theRole={userLogin?.role?.type}
            nameOfRole={userLogin?.role?.name}
            theName={userLogin?.publisher?.name}
            imgOfPublisher={userLogin?.publisher?.profile_image}
            onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
            loadingComponent={loadingComp}
            userFirstLetter={userLetter}
            notifications={notifications}
            markAsRead={handleMarkAsRead}
            notificationStatus={notificationTotal}
            userLogin={userLogin}
          />
          <NavBar
            theRole={userLogin?.role?.type}
            theName={userLogin?.publisher?.name}
            theColor={userLogin?.publisher?.theme_color}
            onMobileClose={() => setOpenNavBarMobile(false)}
            openMobile={openNavBarMobile}
            loadingComponent={loadingComp}
            selectedNavItems={theSelectedItems}
            userLogin={userLogin}
          />
        </>
      )}

      {aterCalculateMenus && (
        <div className={classes.container}>
          {[
            "/admin/berita-publisher/tambah/preview",
            "/admin/berita-publisher/edit/preview",
            "/admin/berita/tambah/preview",
            "/admin/berita/edit/preview",
            "/admin/finalisasi-berita/view/preview",
            "/admin/manajemen-web/preview",
          ].includes(window.location.pathname) ? (
            <div className={classes.content2}>
              <Suspense fallback={<LinearProgress />}>
                {renderRoutes(props.route.routes, { userLogin })}
              </Suspense>
            </div>
          ) : (
            <div className={classes.content}>
              <Suspense fallback={<LinearProgress />}>
                {renderRoutes(props.route.routes, {
                  userLogin,
                  userRole: userLogin?.role?.type,
                  rolesFor: userLogin?.role?.roles_for,
                  publisherChoosen: userLogin?.publisher,
                })}
              </Suspense>
            </div>
          )}
        </div>
      )}
    </>
  );
}

DashboardAdmin.propTypes = {
  route: PropTypes.object,
};

export default DashboardAdmin;
