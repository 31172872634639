import { Container } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { makeStyles } from "@material-ui/styles";
import { Button, Card, Divider, Grid, styled, Typography } from "@mui/material";
import axios from "axios";
import {
  AutoCompleteComponent,
  DateRangePicker,
  ModalError,
  Page,
  PrimaryButton,
  SearchTextInput,
  SelectInput,
  SkeletonComponent,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory, useLocation } from "react-router";
import { formatDate, getErrors } from "utils";
import DollarIcon from "../../../assets/image-public/images/icon_images/ri_money-dollar-circle-fill.svg";
import ExcelLogo from "../../../assets/img/excel-logo.png";
import { hardBaseUrl } from "../../../services/urlConstant";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import isEqual from "lodash/isEqual";

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
const PaymentPage = () => {
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");
  const paramsStatus = urlParams.get("status");
  const paramsComposer = urlParams.get("composer");
  const paramsStart = urlParams.get("startPeriod");
  const paramsEnd = urlParams.get("endPeriod");

  const [loadingTable, setLoadingTable] = useState(false);
  const [optionComposer, setOptionComposer] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [tableParams, setTableParams] = useState({
    page: Number(paramsPage) || 1,
    size: Number(paramsSize) || 10,
    search: paramsSearch || "",
    status: paramsStatus || "",
  });
  const [queryParams, setQueryParams] = useState({
    composer_id: Number(paramsComposer) || "",
    startDate: paramsStart
      ? getFilterDate(paramsStart)
      : moment().subtract(3, "months"),
    endDate: paramsEnd ? getFilterDate(paramsEnd) : moment(),
  });
  const prevQueryParams = usePrevious(queryParams);
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [summaryDetail, setSummaryDetail] = useState({
    composer_balance: 0,
    composer_advance: 0,
  });

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeTableParams = (value, key) => {
    setTableParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeSummaryDetail = (value, key) => {
    setSummaryDetail(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangePage = page => {
    handleChangeTableParams(page, "page");
    handleChangePageParams(page, "page");
  };
  const handleResetPage = () => handleChangePage(1);
  const handleChangePageSize = size => {
    handleResetPage();
    handleChangeTableParams(size, "size");
    handleChangePageParams(size, "size");
  };
  const handleChangeSearch = ({ target }) => {
    let value = target.value || "";
    handleResetPage();
    handleChangeTableParams(value, "search");
    handleChangePageParams(value, "search");
  };
  const handleChangeStatusFilter = ({ target }) => {
    let value = target.value || "";
    handleChangeTableParams(value, "status");
    handleChangePageParams(value, "status");
  };
  const handleChangeComposerFilter = value => {
    handleChangeQueryParams(Number(value), "composer_id");
    handleChangePageParams(Number(value), "composer");
  };
  const handleChangeStartDate = date => {
    handleChangeQueryParams(date, "startDate");
    handleChangePageParams(formatFilterDate(date), "startPeriod");
  };
  const handleChangeEndDate = date => {
    handleChangeQueryParams(date, "endDate");
    handleChangePageParams(formatFilterDate(date), "endPeriod");
  };

  const getComposerList = async () => {
    setLoadingTable(true);
    try {
      const res = await axios.get(
        `${hardBaseUrl}/publisher/transaction-composer`,
        { headers }
      );
      const { data } = res?.data;
      setOptionComposer(data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingTable(false);
    }
  };
  const getSummaryBalance = async () => {
    setLoadingTable(true);
    try {
      const res = await axios.get(`${hardBaseUrl}/balance/composer`, {
        headers,
        params: {
          composer_id: queryParams?.composer_id,
          start_date: formatFilterDate(queryParams?.startDate),
          end_date: formatFilterDate(queryParams?.endDate),
        },
      });
      const { data } = res?.data;
      handleChangeSummaryDetail(data?.total, "composer_balance");
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingTable(false);
    }
  };
  const getSummaryAdvance = async () => {
    setLoadingTable(true);
    try {
      const res = await axios.get(`${hardBaseUrl}/payment/composer/advance`, {
        headers,
        params: {
          composer_id: queryParams?.composer_id,
          start_date: formatFilterDate(queryParams?.startDate),
          end_date: formatFilterDate(queryParams?.endDate),
        },
      });
      const { data } = res?.data;
      handleChangeSummaryDetail(data?.total, "composer_advance");
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingTable(false);
    }
  };
  const getDataTable = async () => {
    setLoadingTable(true);

    const params = {
      ...tableParams,
      composer_id: queryParams?.composer_id,
      filter_start_date: formatFilterDate(queryParams?.startDate),
      filter_end_date: formatFilterDate(queryParams?.endDate),
    };
    try {
      const res = await axios.get(
        `${hardBaseUrl}/publisher/transaction/payment`,
        {
          headers,
          params,
        }
      );
      const { data, meta } = res?.data;
      setDataTable(data);
      const pageCount = getTotalPage(meta?.total, queryParams?.size || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingTable(false);
    }
  };

  const downloadTemplate = () => {
    const url = `${hardBaseUrl}/publisher/transaction-payment/export`;
    axios
      .get(url, {
        responseType: "blob",
        headers,
      })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "payment-transaction.csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        new Error(err);
      });
  };

  useEffect(() => {
    getComposerList();
    getSummaryBalance();
    getSummaryAdvance();
  }, []);

  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams, tableParams]
  );

  useEffect(() => {
    if (prevQueryParams && !isEqual(prevQueryParams, queryParams)) {
      getSummaryBalance();
      getSummaryAdvance();
    }
    debounceDataTable();
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, tableParams, debounceDataTable]);
  const columnTable = [
    {
      name: "composer_name",
      title: "Composer/Author Name",
    },
    {
      name: "invoice_date",
      title: "Date",
      renderText: item => formatDate(item, "DD MMMM YYYY"),
    },
    {
      name: "base_currency_revenue",
      title: "Value",
      renderText: item => {
        return (
          <NumberFormat
            displayType="text"
            value={item}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix="Rp "
          />
        );
      },
    },
    {
      name: "notes",
      title: "Notes",
    },
    {
      name: "status",
      title: "Status",
      renderText: item =>
        listStatus?.find(value => value?.id === item)?.name || "-",
    },
  ];
  return (
    <Page className={classes.root} title="Payment">
      {loadingTable ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Payment Transaction"
            breadcrumbData={breadcrumbData}
          />
          <Divider
            sx={{
              my: 2,
              borderTop: "1px solid #e1e1e1",
            }}
          />
          <AutoCompleteComponent
            label="Composer/ Author"
            options={optionComposer}
            value={
              optionComposer.find(
                option => option.id === queryParams?.composer_id
              ) || null
            }
            onChange={value => handleChangeComposerFilter(value)}
            size="small"
          />
          <Grid container spacing={1} my={2}>
            <Grid item>
              <BalanceCard>
                <Grid container alignItems="center" columnSpacing={2}>
                  <Grid item>
                    <img src={DollarIcon} alt="$" />
                  </Grid>
                  <Grid item>
                    <BalanceTitle>Composer/ Author Balance</BalanceTitle>
                    <BalanceValue>
                      <NumberFormat
                        displayType="text"
                        value={summaryDetail?.composer_balance}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix="Rp "
                      />
                    </BalanceValue>
                  </Grid>
                </Grid>
              </BalanceCard>
            </Grid>
            <Grid item>
              <BalanceCard>
                <Grid container alignItems="center" columnSpacing={2}>
                  <Grid item>
                    <img src={DollarIcon} alt="$" />
                  </Grid>
                  <Grid item>
                    <BalanceTitle>Composer/ Author Advance</BalanceTitle>
                    <BalanceValue>
                      <NumberFormat
                        displayType="text"
                        value={summaryDetail.composer_advance}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix="Rp "
                      />
                    </BalanceValue>
                  </Grid>
                </Grid>
              </BalanceCard>
            </Grid>
          </Grid>

          <TextButton
            variant="text"
            endIcon={<ArrowForwardIcon />}
            onClick={() =>
              history.push({ pathname: "/admin/payment-mutation" })
            }
          >
            See Balance Mutation
          </TextButton>
          <Grid container justifyContent="space-between" mt={1} mb={3}>
            <Grid item>
              <Grid container columnSpacing={1}>
                <Grid item>
                  <SearchTextInput
                    placeholder="Search"
                    onChange={handleChangeSearch}
                    value={tableParams?.search}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1}>
                <Grid item>
                  <DateRangePicker
                    startDate={queryParams?.startDate}
                    handleChangeStartDate={handleChangeStartDate}
                    endDate={queryParams?.endDate}
                    handleChangeEndDate={handleChangeEndDate}
                  />
                </Grid>
                <Grid item>
                  <SelectInput
                    value={tableParams?.status || 0}
                    onChange={handleChangeStatusFilter}
                    options={listStatus}
                    optionKey="id"
                    optionLabel="name"
                    width={150}
                  />
                </Grid>
                <Grid item>
                  <PrimaryButton
                    height={40}
                    size="large"
                    label="Download CSV"
                    color="#34774C"
                    startIcon={<img src={ExcelLogo} alt="logo" />}
                    onClick={downloadTemplate}
                  />
                </Grid>
                <Grid item>
                  <PrimaryButton
                    height={40}
                    size="large"
                    label="Create Transaction"
                    startIcon={<AddIcon />}
                    onClick={() =>
                      history.push({ pathname: "/admin/payment/add" })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          {dataTable && (
            <InnoTableV2
              columns={columnTable}
              handleChangePage={(_, data) => handleChangePage(data)}
              handleChangeRowsPerPage={handleChangePageSize}
              isLoading={loadingTable}
              isHaveAction={true}
              items={dataTable}
              page={tableParams?.page}
              rowsPerPage={tableParams?.size}
              totalPage={tableTotalPage}
              handleView={customer => {
                history.push(
                  `/admin/payment/${customer.publisher_transaction_id}`,
                  {
                    customer: customer,
                    from: "payment",
                  }
                );
              }}
            />
          )}
        </Container>
      )}
    </Page>
  );
};

const formatFilterDate = date => date.format("YYYY-MM-DD");
const getFilterDate = date => moment(date, "YYYY-MM-DD");
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
}));
const TextButton = styled(Button)(() => ({
  textTransform: "none",
  color: "black",
  "& .MuiSvgIcon-root": {
    color: "#9545EB",
  },
}));
const BalanceCard = styled(Card)(() => ({
  backgroundColor: "#111827",
  width: "fit-content",
  padding: "16px",
  borderRadius: "10px",
}));
const BalanceTitle = styled(Typography)(() => ({
  fontFamily: "Inter",
  color: "#9AA2B1",
  fontSize: 14,
}));
const BalanceValue = styled(Typography)(() => ({
  fontFamily: "Inter",
  color: "white",
  fontSize: 24,
  fontWeight: 600,
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Transaction",
    link: "/admin/payment",
  },
  {
    label: "Payment",
    active: true,
  },
];
const listStatus = [
  {
    id: 0,
    name: "All",
  },
  {
    id: "approved",
    name: "Approved",
  },
  {
    id: "paid",
    name: "Paid",
  },
  {
    id: "unpaid",
    name: "Unpaid",
  },
  {
    id: "waiting_for_approval",
    name: "Waiting For Approval",
  },
];

export default PaymentPage;
