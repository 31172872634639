import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  subTitle: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
  },
  label: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "500px",
    fontSize: "14px",
    lineHeight: "20px",
    width: "140px",
    height: "20px",
  },
  fixedHeight: {
    height: 240,
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
    boxFlex: "1",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  inputFilter: {
    width: "211px",
    height: "44px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    marginRight: "10px",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
    fontStyle: "normal",
  },
  tableRow: {
    height: 20,
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  dropdown: {
    color: "black",
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  btnRincian: {
    backgroundColor: "black",

    color: "white",
    padding: "10px 28px",
    borderRadius: "6px",
    width: "140px",
    height: "36px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "#000",
      color: "white",
    },
  },
  ib: {
    paddingRight: "7px",
    paddingLeft: "6px",
    paddingTop: "1px",
    paddingBottom: "2px",
    backgroundColor: "#FCF3F2",
    border: "1px solid #F1A69E",
    "&:focus": {
      color: "blue",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "pink",
      borderColor: "#005cbf",
    },
    "&:hover": {
      boxShadow: "none",
    },
  },
  myCheckBox: {
    "&.MuiCheckbox-root": {
      color: "black",
      "&.Mui-checked": {
        color: "black",
      },
    },
  },
  scrollingCard: {
    "&::-webkit-scrollbar": {
      width: 1,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "lightgrey",
      outline: "1px solid slategrey",
    },
  },

  dialogPaper: {
    width: "420px",
    maxWidth: "420px",
  },
  filterContainer: {
    display: "flex",
  },
}));
