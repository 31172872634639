import React, { useState, useRef, useEffect } from "react";
import { Page, PrimaryButton } from "components";
import { makeStyles } from "@material-ui/styles";
import {
  Container,
  Grid,
  Divider,
  TextField,
  InputAdornment,
  Button,
  Typography,
  IconButton,
  Box,
  Breadcrumbs,
  Checkbox,
  Backdrop,
  CircularProgress,
  FormControl,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import deleteIcon from "assets/img/trashVector.svg";
import PublishIcon from "@material-ui/icons/Publish";
import { PhotoshopPicker } from "react-color";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ExpandMore from "@material-ui/icons/ExpandMore";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import axios from "axios";
import { hardBaseUrl } from "../../../../services/urlConstant";
import Swal from "sweetalert2";

const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
    h5: {
      fontWeight: 700,
    },
  },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MockListStatusMember = [
  {
    title: "Member",
    value: true,
  },
  {
    title: "Non-Member",
    value: false,
  },
];

const initialSociety = {
  territory: "",
  society: [],
};

const AddPublisher = props => {
  const [color, setColor] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [IpiNameNumber, setIpiNameNumber] = useState("");
  const [IpiBaseNumber, setIpiBaseNumber] = useState("");
  const [url, setUrl] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [valueSociety, setValueSociety] = useState([initialSociety]);
  const [listTerritory, setListTerritory] = useState([]);
  const [listSociety, setListSociety] = useState([]);
  const [association, setAssociation] = useState(null);
  const [associationList, setAssociationList] = useState([]);
  const [country, setCountry] = useState(null);
  const [status, setStatus] = useState("");
  const [countryList, setCountryList] = useState([]);
  const userRole = localStorage?.getItem("role");
  const isAssociation = userRole === "association";
  const isSociety = userRole === "society";
  const isAssosateAndIsSociety = isAssociation || isSociety;
  let token = localStorage.getItem("token");
  const inputFile = useRef(null);

  const theme = createTheme({
    typography: {
      fontFamily: ["Helvetica Neue"].join(","),
    },
  });

  const colorPreview = makeStyles(() => ({
    changeColor: {
      backgroundColor: color,
      padding: "10px",
      width: "48px",
      height: "24px",
    },
  }));

  const colorPrev = colorPreview();

  const addSociety = () =>
    setValueSociety(currentSociety => [...currentSociety, initialSociety]);
  const removeSociety = index =>
    setValueSociety(currentSociety =>
      [...currentSociety].filter((_, key) => index !== key)
    );
  const getCountryList = () => {
    const url = `${hardBaseUrl}/country/table?page=1&per_page=99&search=&sort=`;

    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios.get(url, headers).then(res => {
      setCountryList(res.data.data);
    });
  };

  const getAssociationList = () => {
    const url = `${hardBaseUrl}/association/all`;
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios.get(url, headers).then(res => {
      setAssociationList(res.data.data || []);
    });
  };

  const getListTerritory = async () => {
    const url = `${hardBaseUrl}/admin/territory`;
    const params = {
      page: 1,
      size: -1,
    };
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      });
      setListTerritory(response?.data?.data || []);
    } catch (err) {
      new Error(err);
    }
  };

  const getlistSociety = async () => {
    const url = `${hardBaseUrl}/admin/society/`;
    const params = {
      page: 1,
      size: -1,
    };
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value)
    );
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filteredParams,
      });
      setListSociety(response?.data?.data || []);
    } catch (err) {
      Swal.fire({
        title: "Error",
        text:
          err?.response?.data?.errors[0]?.message ||
          err?.message ||
          "Something Wrong",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  useEffect(() => {
    getCountryList();
    if (!isAssosateAndIsSociety) {
      getlistSociety();
      getListTerritory();
      getAssociationList();
    }
  }, []);

  const onChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "name") {
      setName(value);
    } else if (name === "address") {
      setAddress(value);
    } else if (name === "url") {
      setUrl(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "IpiBaseNumber") {
      setIpiBaseNumber(value);
    } else if (name === "IpiNameNumber") {
      setIpiNameNumber(value);
    }
  };

  const getBase64 = file => {
    if (file) {
      return new Promise(resolve => {
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          baseURL = reader.result;
          resolve(baseURL);
        };
        reader.onerror = error => {
          console.error("Error converting file to Base64:", error);
        };
      });
    }
  };

  const onChangeFile = async e => {
    const data = e.target.files[0];
    if (data) {
      const tesRes = await getBase64(data);
      setImagePreview(tesRes);
      setFile(data);
    }
  };
  const onSubmit = e => {
    e.preventDefault();
    setLoadingPage(true);
    const urlBase = `${hardBaseUrl}/publisher`;
    const token = localStorage.getItem("token");
    const headers = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    const formData = new FormData();
    formData.append("name", name);
    formData.append("address", address);
    if (url) {
      formData.append("subdomain", url + ".caris.id");
    }
    formData.append("phone", phone ? "+62" + phone : phone);
    formData.append("email", email);
    formData.append("is_member", status);
    formData.append("image_logo", file);
    formData.append("ipi_base_number", IpiBaseNumber);
    formData.append("ipi_name_number", IpiNameNumber);
    formData.append("theme_color", color);
    formData.append("association_id", association?.association_id || "");
    formData.append("country_id", country?.id || "");
    valueSociety.forEach(({ territory, society }) => {
      formData.append(
        "publisher_affiliations",
        JSON.stringify({
          territory_id: territory?.id,
          society_id: society?.map(({ id }) => id),
        })
      );
    });
    axios
      .post(urlBase, formData, headers)
      .then(res => {
        setLoadingPage(false);
        if (
          res.data.message === "success" &&
          res.data.meta.http_status === 200
        ) {
          Swal.fire({
            title: "Success.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(result => {
            if (result.isConfirmed === true) {
              props.history.push("/admin/parameter/publisher");
            }
          });
        }
      })
      .catch(err => {
        setLoadingPage(false);
        Swal.fire({
          title: "Oops…",
          icon: "error",
          text: `${err.response.data.errors[0].message}`,
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      })
      .finally(() => setLoadingPage(false));
  };
  const handleChangePhotoButton = e => {
    e.preventDefault();
    inputFile.current.click();
  };

  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    flex: {
      display: "flex",
    },
    fieldColor: {
      textAlign: "center",
      height: "120px",
      borderRadius: "6px",
      marginTop: "6px",
      marginRight: "20px",
    },
    divider: {
      margin: theme.spacing(2, 0),
      borderTop: "1px solid #e1e1e1",
    },
    button: {
      textTransform: "none",
      marginTop: "16px",
      backgroundColor: "black",
      color: "white",
      width: "135px",
      height: "40px",
      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    ib: {
      padding: "4px",
      color: "white",
      backgroundColor: "black",
      borderRadius: "6px",
      "&:focus": {
        color: "white",
        backgroundColor: "black",
      },
      "&:active": {
        boxShadow: "none",
        color: "white",
        backgroundColor: "black",
      },
      "&:hover, &.MuiIconButton": {
        background: "#374151 !important",
      },
    },
    buttonAddSociety: {
      display: "flex",
      alignItems: "center",
      color: "#1890ff",
      cursor: "pointer",
    },
    textAddSociety: {
      fontSize: "14px",
    },
    wrapperAddSociety: {
      border: "1px solid #9AA2B1",
      padding: "16px",
      borderRadius: "8px",
      marginTop: "16px",
    },
    colorPicker: {
      width: "400px !important",
      "& .photoshop-picker": {
        width: "200px !important",
      },
    },
    textAdd: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "32px",
    },
    imgPreview: {
      maxHeight: "120px",
    },
    fileUploadArea: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    checkboxIcon: {
      "&.MuiCheckbox-root": {
        color: "black",
        "&.Mui-checked": {
          color: "black",
        },
      },
    },
    required: {
      color: "red",
      marginLeft: 5,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    title: {
      fontFamily: "Helvetica Neue",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "28px",
    },
    subTitle: {
      fontFamily: "Helvetica Neue",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#6e6e6e",
    },
    inputFields: {
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    inputFieldsUrl: {
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
      borderRight: "1px solid #D1D5DC",
    },
    buttonSave: {
      color: "white",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
    },
    inputFieldsTlp: {
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
      borderLeft: "1px solid #D1D5DC",
      paddingLeft: "14px",
    },
    myTextField: {
      height: "44px",
      paddingBottom: "0px",
      paddingTop: "0px",
    },
    formRequired: {
      fontSize: "14px",
      marginTop: "16px",
    },
    label: {
      fontStyle: "normal",
      fontWeight: "500px",
      fontSize: "14px",
      lineHeight: "20px",
      width: "140px",
      height: "20px",
    },
    autoComplete: {
      "& .MuiOutlinedInput-root": {
        padding: "0px",
        height: "44px",
      },
      "& .MuiOutlinedInput-root input.MuiInputBase-input.MuiOutlinedInput-input": {
        paddingLeft: "14px",
      },
    },
    font24: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "32px",
    },
    textStyle: {
      marginTop: "5px",
      color: "#8f8f8f",
      fontSize: "14px",
    },
    card: {
      border: "1px solid #9AA2B1",
      borderRadius: "6px",
      boxShadow: "none",
    },
    mt5: {
      marginTop: "5px",
    },
    mtMin5: {
      marginTop: "-5px",
    },
    mt10: {
      marginTop: "10px",
    },
    mt15: {
      marginTop: "15px",
    },
    mt20: {
      marginTop: "20px",
    },
    mtMin10: {
      marginTop: "-10px",
    },
    mb5: {
      marginBottom: "5px",
    },
    mbMin5: {
      marginBottom: "-5px",
    },
    mb10: {
      marginBottom: "10px",
    },
    mbMin10: {
      marginBottom: "-10px",
    },
    mb15: {
      marginBottom: "15px",
    },
    mr5: {
      marginRight: "5px",
    },
    mr10: {
      marginRight: "10px",
    },
    mr15: {
      marginRight: "15px",
    },
    ml5: {
      marginLeft: "5px",
    },
    ml10: {
      marginLeft: "10px",
    },
    ml15: {
      marginLeft: "15px",
    },
    flex: {
      display: "flex",
    },
    between: {
      justifyContent: "space-between",
    },
    center: {
      justifyContent: "center",
    },
    color687083: {
      color: "#687083",
    },
    outline: {
      width: "100%",
      marginRight: "20px",
      height: "44px",
      border: "1px solid #D1D5DC",
      borderRadius: "6px",
    },
    pointer: {
      cursor: "pointer",
    },
    paper: {
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      display: "flex",
      flexDirection: "column",
      background: "white",
      position: "absolute",
      "& > *": {
        margin: theme.spacing(1),
        width: theme.spacing(16),
        height: theme.spacing(16),
      },
      zIndex: "1000",
    },
    containerForm: {
      width: "300px",
      minHeight: "100px",
      maxHeight: "300px",
      marginTop: "40px",
      borderTop: "1px solid #E4E7EB",
      overflow: "scroll",
    },
    gap4: {
      display: "flex",
      justifyContent: "right",
      paddingRight: "24px",
    },
    font18: {
      fontSize: "18px",
      lineHeight: "28px",
    },
    font14: {
      fontSize: "14px",
      lineHeight: "20px",
    },
    container: {
      border: "1px solid #9AA2B1",
      padding: "16px",
      borderRadius: "8px",
      marginTop: "16px",
    },
    colorRed: {
      color: "red",
    },
    displayNone: {
      display: "none",
    },
    height38: {
      height: "38px",
    },
    mt100: {
      marginTop: "100px",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
    },
    width300: {
      width: "300px",
    },
    flexCenter: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
    },
    flexBetween: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "8px",
    },
    displayBlock: {
      display: "block",
      width: "415px",
    },
    color687083: {
      color: "#687083",
    },
  }));
  const classes = useStyles();

  const TextInputRequired = ({ text, className }) => {
    const theme = createTheme({
      typography: {
        fontFamily: ["Helvetica Neue"].join(","),
      },
    });
    return (
      <ThemeProvider theme={theme}>
        <Box className={classes.flex}>
          <Typography className={`${className} ${classes.flex}`}>
            {text}
            <Typography className={classes.required}>*</Typography>
          </Typography>
        </Box>
      </ThemeProvider>
    );
  };

  return (
    <Page className={classes.root} title="Add Publisher">
      <Backdrop className={classes.backdrop} open={loadingPage}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography className={classes.textAdd}>Add Publisher</Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <a href="/admin/parameter/publisher">Parameter</a>
              <a href="/admin/parameter/publisher">Publisher</a>
              <Typography>Add Publisher</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />
        <form onSubmit={onSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ThemeProvider theme={theme}>
                <Typography variant="h5" className={classes.title}>
                  Profile Publisher
                </Typography>
                <Typography variant="caption" className={classes.subTitle}>
                  Customize publisher profile and URL to share
                </Typography>
              </ThemeProvider>
              <Box
                border="1px solid #9AA2B1"
                padding="16px"
                borderRadius="8px"
                marginTop="16px"
              >
                <Grid container>
                  <Grid item xs={12}>
                    <TextInputRequired text="Profile Publisher Name" />
                    <TextField
                      className={classes.myTextField}
                      fullWidth
                      onChange={e => onChange(e)}
                      id="name"
                      name="name"
                      type="text"
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.inputFields },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ThemeProvider theme={fontInter}>
                      <Typography className={classes.formRequired}>
                        Status
                      </Typography>
                    </ThemeProvider>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                    >
                      <Autocomplete
                        onChange={(_, newValue) => setStatus(newValue?.value)}
                        options={MockListStatusMember}
                        className={classes.autoComplete}
                        getOptionLabel={option => option?.title}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Status"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <ThemeProvider theme={fontInter}>
                      <Typography className={classes.formRequired}>
                        URL
                      </Typography>
                    </ThemeProvider>
                    <TextField
                      className={classes.myTextField}
                      fullWidth
                      onChange={onChange}
                      id="url"
                      name="url"
                      type="text"
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.inputFieldsUrl },
                        endAdornment: (
                          <InputAdornment position="end">
                            .caris.id
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInputRequired
                      text="Email"
                      className={classes.formRequired}
                    />
                    <TextField
                      className={classes.myTextField}
                      fullWidth
                      onChange={onChange}
                      id={"email"}
                      name="email"
                      type="text"
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.inputFields },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextInputRequired
                      text="Country"
                      className={classes.formRequired}
                    />
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                    >
                      <Autocomplete
                        onChange={(event, newValue) => {
                          setCountry(newValue);
                        }}
                        options={countryList}
                        getOptionLabel={option => option?.country_name}
                        className={classes.autoComplete}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Country"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {!isAssosateAndIsSociety && (
                    <Grid item xs={12}>
                      <ThemeProvider theme={fontInter}>
                        <Typography className={classes.formRequired}>
                          Association
                        </Typography>
                      </ThemeProvider>

                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth={true}
                      >
                        <Autocomplete
                          onChange={(_, newValue) => {
                            setAssociation(newValue);
                          }}
                          options={associationList}
                          getOptionLabel={option => option?.name}
                          className={classes.autoComplete}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Search Association"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <TextInputRequired
                      text="IPI Name Number"
                      className={classes.formRequired}
                    />

                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                    >
                      <TextField
                        variant="outlined"
                        placeholder="IPI Name Number"
                        name="IpiNameNumber"
                        value={IpiNameNumber}
                        onChange={onChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <ThemeProvider theme={fontInter}>
                      <Typography className={classes.formRequired}>
                        IPI Base Number
                      </Typography>
                    </ThemeProvider>

                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                    >
                      <TextField
                        variant="outlined"
                        placeholder="IPI Base Number"
                        name="IpiBaseNumber"
                        onChange={onChange}
                        value={IpiBaseNumber}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextInputRequired
                      text="Phone Number"
                      className={classes.formRequired}
                    />
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                    >
                      <TextField
                        className={classes.myTextField}
                        fullWidth
                        onChange={onChange}
                        id="phone"
                        name="phone"
                        type="number"
                        variant="outlined"
                        InputProps={{
                          classes: { input: classes.inputFieldsTlp },
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.mr15}
                            >
                              +62
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextInputRequired
                      text="Address"
                      className={classes.formRequired}
                    />
                    <TextField
                      fullWidth
                      onChange={onChange}
                      id="address"
                      name="address"
                      type="text"
                      variant="outlined"
                      rows={4}
                      multiline
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <ThemeProvider theme={theme}>
                <Typography variant="h5" className={classes.title}>
                  Tampilan
                </Typography>
                <Typography variant="caption" className={classes.subTitle}>
                  Choose your company logo and customize the color theme.
                </Typography>
              </ThemeProvider>
              <Box
                border="1px solid #9AA2B1"
                padding="16px"
                borderRadius="8px"
                marginTop="16px"
                marginBottom="16px"
              >
                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <TextInputRequired
                      text="Publisher Logo"
                      className={classes.label}
                    />
                    <Box
                      textAlign="center"
                      display="flex"
                      justifyContent="center"
                      border="1px solid #D1D5DC"
                      height="120px"
                      borderRadius="6px"
                      marginRight="20px"
                      marginTop="6px"
                    >
                      {imagePreview ? (
                        <img
                          src={imagePreview}
                          alt="Preview"
                          className={classes.imgPreview}
                        />
                      ) : (
                        <Box
                          alignItems="center"
                          justifyContent="center"
                          display="flex"
                          flexDirection="column"
                        >
                          <PublishIcon />
                          <Typography>Upload Logo</Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box marginTop="22px">
                      <input
                        onChange={onChangeFile}
                        ref={inputFile}
                        className={classes.displayNone}
                        type="file"
                        name="logo"
                        accept="image/*"
                      />

                      <PrimaryButton
                        onClick={handleChangePhotoButton}
                        label="Change Logo"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <TextInputRequired
                      text="Theme Color"
                      className={classes.label}
                      required
                    />
                    <Box>
                      <TextField
                        fullWidth={true}
                        name="color"
                        onChange={onChange}
                        value={color}
                        variant="outlined"
                        disabled
                        onClick={() =>
                          setShowColorPicker(
                            showColorPicker => !showColorPicker
                          )
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <div className={colorPrev.changeColor}></div>
                              <Divider
                                className={classes.colorDivider}
                                orientation="vertical"
                              />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <ExpandMore />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {showColorPicker && (
                        <PhotoshopPicker
                          className={classes.colorPicker}
                          color={color}
                          onAccept={() =>
                            setShowColorPicker(
                              showColorPicker => !showColorPicker
                            )
                          }
                          onCancel={() =>
                            setShowColorPicker(
                              showColorPicker => !showColorPicker
                            )
                          }
                          onChange={updateColor => setColor(updateColor.hex)}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {!isAssosateAndIsSociety && (
                <Box>
                  <ThemeProvider theme={theme}>
                    <Box display="flex" justifyContent="space-between">
                      <Box>
                        <Typography variant="h5" className={classes.title}>
                          Society
                        </Typography>
                        <Typography
                          variant="caption"
                          className={classes.subTitle}
                        >
                          Input your society fee for the data requirement
                        </Typography>
                      </Box>
                      {valueSociety.length < 5 && (
                        <Box
                          className={classes.buttonAddSociety}
                          onClick={addSociety}
                        >
                          + add society
                        </Box>
                      )}
                    </Box>
                  </ThemeProvider>
                  {valueSociety.map(({ territory, society }, key) => {
                    return (
                      <Box className={classes.wrapperAddSociety} key={key}>
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <ThemeProvider theme={fontInter}>
                              <Typography className={classes.textAddSociety}>
                                Territory
                              </Typography>
                            </ThemeProvider>
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                              fullWidth
                            >
                              <Autocomplete
                                onChange={(_, newValue) => {
                                  setValueSociety(currentSociety => {
                                    const updatedSociety = currentSociety.map(
                                      (item, index) =>
                                        index === key
                                          ? { ...item, territory: newValue }
                                          : item
                                    );
                                    return updatedSociety;
                                  });
                                }}
                                options={listTerritory}
                                className={classes.autoComplete}
                                getOptionSelected={(option, value) =>
                                  option?.id === value?.id
                                }
                                getOptionLabel={option =>
                                  option?.territory_code || ""
                                }
                                value={territory || ""}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Territory"
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <ThemeProvider theme={fontInter}>
                              <Typography className={classes.textAddSociety}>
                                Society
                              </Typography>
                            </ThemeProvider>
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                              fullWidth={true}
                            >
                              <Autocomplete
                                getOptionSelected={(option, value) =>
                                  option?.id === value?.id
                                }
                                onChange={(_, value) => {
                                  setValueSociety(currentSociety => {
                                    const updatedSociety = currentSociety.map(
                                      (item, index) =>
                                        index === key
                                          ? { ...item, society: value }
                                          : item
                                    );
                                    return updatedSociety;
                                  });
                                }}
                                options={listSociety}
                                limitTags={19}
                                multiple
                                getOptionLabel={option =>
                                  option?.society_name || ""
                                }
                                value={society}
                                renderOption={(option, { selected }) => {
                                  return (
                                    <Box>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        checked={selected}
                                        value={option?.society_name}
                                        className={classes.checkboxIcon}
                                      />
                                      {option.society_name}
                                    </Box>
                                  );
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Society"
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          {valueSociety.length > 1 && (
                            <Grid item md={12}>
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                onClick={() => removeSociety(key)}
                              >
                                <IconButton classes={{ root: classes.ib }}>
                                  <img
                                    src={deleteIcon}
                                    alt={`delete society ${key}`}
                                  />
                                </IconButton>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Grid>
            <Grid item sm={12}>
              <Box justifyContent="flex-end" display="flex">
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.button}
                >
                  <ThemeProvider theme={fontInter}>
                    <Typography className={classes.buttonSave}>Save</Typography>
                  </ThemeProvider>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Page>
  );
};

export default AddPublisher;
