import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Divider,
  Typography,
  IconButton,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Add, Create, VisibilityOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import moment from "moment";
import { InnoTableV2 } from "inno-ui";
import { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  ButtonGroupTop,
  DateTimeDisplay,
  Page,
  SkeletonComponent,
} from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  button: {
    padding: "5px",
    color: "white",
    width: "28px",
    height: "28px",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  icon: {
    color: "white",
  },
  content: {
    marginTop: "20px",
  },
  contentAdd: {
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    fontFamily: ["Helvetica Neue"].join(","),
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  buttonAdd: {
    height: "44px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    backgroundColor: "black",
    color: "white",
    textTransform: "none",
  },
  textAdd: {
    color: "white",
    fontSize: "14px",
  },
  textField: {
    backgroundColor: "white",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    height: "44px",
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    "& .MuiOutlinedInput-root": {
      width: "312px",
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  autoComplete: {
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      height: "44px",
    },
    "& .MuiOutlinedInput-root input.MuiInputBase-input.MuiOutlinedInput-input": {
      paddingLeft: "14px",
    },
  },
}));

const topMenuButton = [
  {
    id: 0,
    title: "System",
    link: "/admin/konfigurasi/sistem",
    selected: false,
  },
  {
    id: 1,
    title: "Notification Template",
    link: "/admin/konfigurasi/template-notifikasi",
    selected: false,
  },
  {
    id: 2,
    title: "Tax",
    link: "/admin/konfigurasi/pajak",
    selected: false,
  },
  {
    id: 3,
    title: "Role",
    link: "/admin/konfigurasi/role-user",
    selected: false,
  },
  {
    id: 4,
    title: "Activity",
    link: "/admin/konfigurasi/aktifitas-admin",
    selected: false,
  },
  {
    id: 5,
    title: "Billing Configuration",
    link: "/admin/konfigurasi/billing-configuration",
    selected: false,
  },
  {
    id: 6,
    title: "DSP Type Revenue",
    link: "/admin/konfigurasi/dsp-type-revenue",
    selected: true,
  },
  {
    id: 7,
    title: "Announcement Management",
    link: "/admin/konfigurasi/announcement-management",
    selected: false,
  },
];

const optionsType = [
  {
    type: "web",
    title: "Website Announcement Content",
  },
  {
    type: "email",
    title: "Email Announcement",
  },
];

const detailColumn = {
  name: "all",
  title: "Action",
  align: "left",
  width: "10px",
  renderText: ({ id, delivery_time, end_date }) => {
    const history = useHistory();
    const classes = useStyles();
    const targetDate = new Date(delivery_time || end_date);
    const currentDate = moment().toString();
    const isAvailableToEdit = targetDate >= currentDate;
    return (
      <Grid direction="row" container spacing={1}>
        <Grid item>
          <IconButton
            onClick={() =>
              history.push(
                `/admin/konfigurasi/announcement-management/view/${id}`
              )
            }
            className={classes.button}
          >
            <VisibilityOutlined />
          </IconButton>
        </Grid>
        {isAvailableToEdit && (
          <Grid item>
            <IconButton
              onClick={() =>
                history.push(
                  `/admin/konfigurasi/announcement-management/edit/${id}`
                )
              }
              className={classes.button}
            >
              <Create className={classes.icon} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    );
  },
};

const columnsEmail = [
  detailColumn,
  {
    name: "type",
    title: "Type",
  },
  {
    name: "delivery_time",
    title: "Delivery Time",
    renderText: date => <DateTimeDisplay date={date} />,
  },
  {
    name: "subject",
    title: "Subject",
  },
];

const columnsWeb = [
  detailColumn,
  {
    name: "type",
    title: "Type",
  },
  {
    name: "start_date",
    title: "Start Date",
    renderText: date => <DateTimeDisplay date={date} />,
  },
  {
    name: "end_date",
    title: "End Date",
    renderText: date => <DateTimeDisplay date={date} />,
  },
  {
    name: "subject",
    title: "Subject",
  },
];

function MasterRevenueType() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [response, setResponse] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [type, setType] = useState("email");
  const [loadingTable, setLoadingTable] = useState(false);
  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const handleOnSelectedButton = value => {
    history.push(value.data[value.newButtonSelected].link);
  };
  const getResponse = (page, per_page, search = "", sort = "") => {
    setLoadingTable(true);
    let theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/admin/annoucement/table/${type}`;
    const params = {
      page,
      per_page,
      search,
      sort,
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${theToken}`,
      },
      params,
    };
    axios
      .get(url, headers)
      .then(res => {
        setTotalData(res.data.meta.total);
        setResponse(res.data.data);
        setLoadingTable(false);
      })
      .catch(err => {
        new Error(err);
        setLoadingTable(false);
      });
  };
  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleChangePage = (_, data) => {
    setPage(data);
  };

  const handleChangeSearch = event => {
    setPage(1);
    setSearchKey(event.target.value);
  };

  useEffect(() => {
    getResponse(page, rowsPerPage, searchKey);
  }, [rowsPerPage, page, searchKey, type]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filterParam = params.get("type");
    if (filterParam) {
      setType(filterParam);
    }
  }, [location.search]);

  return (
    <Fragment>
      <Page className={classes.root} title="DSP Type Revenue">
        {loadingTable ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <Container maxWidth={false}>
            <Grid
              alignItems="flex-end"
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item>
                <ThemeProvider theme={fontInter}>
                  <Typography
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      lineHeight: "32px",
                    }}
                  >
                    Configuration
                  </Typography>
                </ThemeProvider>
              </Grid>
              <Grid item>
                <Breadcrumbs aria-label="breadcrumb">
                  <ThemeProvider theme={fontInter}>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        color: "#111827",
                      }}
                    >
                      Configuration
                    </Typography>
                  </ThemeProvider>
                  <ThemeProvider theme={fontInter}>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        color: "#687083",
                      }}
                    >
                      DSP Type Value
                    </Typography>
                  </ThemeProvider>
                </Breadcrumbs>
              </Grid>
            </Grid>
            <ButtonGroupTop
              items={topMenuButton}
              selectedButton={handleOnSelectedButton}
              selectedIndex={6}
            />
            <Divider className={classes.divider} />
            <Box className={classes.content}>
              <Box
                display="flex"
                marginTop="20px"
                justifyContent="space-between"
                alignItems="center"
              >
                <FormControl variant="outlined">
                  <TextField
                    id="input-with-icon-textfield"
                    variant="outlined"
                    className={classes.inputFields}
                    onChange={handleChangeSearch}
                    placeholder="Search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            alt="Logo"
                            src={
                              require("assets/image-public/images/search.svg")
                                .default
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <Box
                  display="grid"
                  alignItems="center"
                  gridTemplateColumns="1fr 1fr"
                  gridGap=".5rem"
                >
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      displayEmpty
                      className={classes.textField}
                      value={type}
                      onChange={({ target }) => setType(target.value)}
                      defaultValue={""}
                    >
                      {optionsType.map(({ title, type }) => (
                        <MenuItem key={type} value={type}>
                          {title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    onClick={() =>
                      history.push(
                        `/admin/konfigurasi/announcement-management/add/${type}`
                      )
                    }
                    variant="contained"
                    className={classes.buttonAdd}
                    startIcon={<Add />}
                  >
                    <ThemeProvider theme={fontInter}>
                      <Typography className={classes.textAdd}>
                        {type === "web"
                          ? "Create Website Announcement"
                          : "Create Email Announcement"}
                      </Typography>
                    </ThemeProvider>
                  </Button>
                </Box>
              </Box>
              <Box marginTop="30px">
                <InnoTableV2
                  columns={type === "email" ? columnsEmail : columnsWeb}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  idColumnName={"user_id"}
                  deleteName={"name"}
                  isLoading={loadingTable}
                  items={response}
                  loadingColor={""}
                  page={page}
                  rowsPerPage={Number(rowsPerPage)}
                  totalPage={pagesCount}
                />
              </Box>
            </Box>
          </Container>
        )}
      </Page>
    </Fragment>
  );
}
export default MasterRevenueType;
