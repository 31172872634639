import { Checkbox, FormControlLabel, styled } from "@mui/material";
import { getCookie } from "utils";

const CheckboxInput = ({ checked, label, onChange, color, ...rest }) => {
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  return (
    <FormControlLabel
      control={
        <CustomCheckbox
          customColor={color || userLogin?.publisher?.theme_color}
          checked={checked}
          onChange={onChange}
        />
      }
      {...rest}
      label={label}
    />
  );
};

const CustomCheckbox = styled(Checkbox)(({ customColor }) => ({
  "&.MuiCheckbox-root": {
    color: customColor || "#111827",
    "&.Mui-checked": {
      color: customColor || "#111827",
    },
  },
}));

export default CheckboxInput;
