import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { ModalError, ModalSuccess } from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import FormInvoice from "./Components/FormInvoice";

function AddInvoice() {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);

  const handlSubmitData = payload => {
    setLoadingPage(true);
    const url = `${hardBaseUrl}/publisher/transaction`;
    axios
      .post(url, payload, { headers })
      .then(res => {
        if (res.status === 200) {
          setLoadingPage(false);
          ModalSuccess("Invoice has been created").then(() =>
            history.push("/admin/invoice")
          );
        }
      })
      .catch(error => {
        setLoadingPage(false);
        if (error?.response?.data?.errors?.length) {
          ModalError(error?.response?.data?.errors?.[0]?.message);
        }
      });
  };

  return (
    <FormInvoice
      pageTitle="Create Invoice"
      pageSubTitle="Create your transaction here"
      loadingPage={loadingPage}
      onSubmitPayload={handlSubmitData}
    />
  );
}

export default AddInvoice;
