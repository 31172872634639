import { Button, CircularProgress, styled } from "@mui/material";
import { getCookie } from "utils";

const PrimaryButton = ({
  label,
  onClick,
  startIcon,
  endIcon,
  loading,
  disabled,
  color,
  width,
  height,
  size,
  textColor,
  children,
  ...rest
}) => {
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);

  return (
    <CustomButton
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      customColor={color || userLogin?.publisher?.theme_color}
      customWidth={width}
      textColor={textColor}
      customHeight={height}
      size={size}
      startIcon={loading ? <CircularProgress size={16} /> : startIcon}
      endIcon={endIcon}
      {...rest}
    >
      {label || children}
    </CustomButton>
  );
};
const CustomButton = styled(Button)(
  ({ customColor, customWidth, customHeight, textColor }) => ({
    width: customWidth || "auto",
    minWidth: 80,
    height: customHeight || "auto",
    color: textColor,
    backgroundColor: `${customColor || "#111827"}`,
    textTransform: "none",
    "&:hover": {
      backgroundColor: `${customColor || "#111827"}`,
    },
  })
);

export default PrimaryButton;
