import {
  Breadcrumbs,
  Button,
  Container,
  FormControl,
  Grid,
  Box,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTable } from "inno-ui";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { Page, SkeletonComponent } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  content: {
    marginTop: "20px",
  },
  contentAdd: {
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    fontFamily: ["Helvetica Neue"].join(","),
  },
  buttonAdd: {
    height: "44px",
    width: "auto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    backgroundColor: "black",
    color: "white",
    textTransform: "none",
  },
  textAdd: {
    color: "white",
    fontSize: "14px",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",

    "& .MuiOutlinedInput-root": {
      width: "312px",
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
}));

function MasterRevenueType() {
  const classes = useStyles();
  const history = useHistory();
  const [response, setResponse] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");

  const topMenuButton = [
    {
      id: 0,
      title: "System",
      link: "/admin/konfigurasi/sistem",
      selected: false,
    },
    {
      id: 1,
      title: "Notification Template",
      link: "/admin/konfigurasi/template-notifikasi",
      selected: false,
    },
    {
      id: 2,
      title: "Tax",
      link: "/admin/konfigurasi/pajak",
      selected: false,
    },
    {
      id: 3,
      title: "Role",
      link: "/admin/konfigurasi/role-user",
      selected: false,
    },
    {
      id: 4,
      title: "Activity",
      link: "/admin/konfigurasi/aktifitas-admin",
      selected: false,
    },
    {
      id: 5,
      title: "Billing Configuration",
      link: "/admin/konfigurasi/billing-configuration",
      selected: false,
    },
    {
      id: 6,
      title: "DSP Type Revenue",
      link: "/admin/konfigurasi/dsp-type-revenue",
      selected: true,
    },
    {
      id: 7,
      title: "Announcement Management",
      link: "/admin/konfigurasi/announcement-management",
      selected: false,
    },
  ];
  const [loadingTable, setLoadingTable] = useState(false);

  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const handleOnSelectedButton = value => {
    history.push(value.data[value.newButtonSelected].link);
  };

  const getResponse = () => {
    setLoadingTable(true);
    let theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/dsp-revenue-type/table?search=${searchKey}&page=${page}&per_page=${rowsPerPage}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${theToken}`,
      },
    };

    axios
      .get(url, headers)
      .then(res => {
        setTotalData(res.data.meta.total);
        setResponse(res.data.data);
        setLoadingTable(false);
      })
      .catch(() => {
        setLoadingTable(false);
      });
  };

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };

  const handleChangeSearch = event => {
    setPage(1);
    setSearchKey(event.target.value);
  };

  const handleDelete = item => {
    const url = `${hardBaseUrl}/dsp-revenue-type/delete/${item.dsp_id}`;
    let theToken = localStorage.getItem("token");

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${theToken}`,
      },
    };

    axios
      .delete(url, headers)
      .then(res => {
        if (res.data.meta.http_status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Data has been deleted",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    getResponse();
  }, [rowsPerPage, page, searchKey]);

  const renderRevenueType = item => {
    let newArr = [];
    item.forEach(element => {
      if (element.revenue_type !== null) {
        let newName = element.revenue_type.name;
        newArr.push(newName);
      }
    });
    let showName = newArr.join(", ");

    return <React.Fragment>{showName}</React.Fragment>;
  };
  return (
    <Fragment>
      <Page className={classes.root} title="DSP Type Revenue">
        {loadingTable ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <Container maxWidth={false}>
            <Grid
              alignItems="flex-end"
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item>
                <ThemeProvider theme={fontInter}>
                  <Typography
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      lineHeight: "32px",
                    }}
                  >
                    Configuration
                  </Typography>
                </ThemeProvider>
              </Grid>
              <Grid item>
                <Breadcrumbs aria-label="breadcrumb">
                  <ThemeProvider theme={fontInter}>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        color: "#111827",
                      }}
                    >
                      Configuration
                    </Typography>
                  </ThemeProvider>
                  <ThemeProvider theme={fontInter}>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        color: "#687083",
                      }}
                    >
                      DSP Type Value
                    </Typography>
                  </ThemeProvider>
                </Breadcrumbs>
              </Grid>
            </Grid>
            <ButtonGroupTop
              items={topMenuButton}
              selectedButton={handleOnSelectedButton}
              selectedIndex={6}
            />

            <Box className={classes.content}>
              <hr />
              <Box
                display="flex"
                marginTop="20px"
                justifyContent="space-between"
              >
                <FormControl className={classes.margin} variant="outlined">
                  <TextField
                    id="input-with-icon-textfield"
                    variant="outlined"
                    className={classes.inputFields}
                    onChange={handleChangeSearch}
                    placeholder="Search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            alt="Logo"
                            src={
                              require("assets/image-public/images/search.svg")
                                .default
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <Button
                  href="/admin/konfigurasi/dsp-type-revenue/add"
                  variant="contained"
                  className={classes.buttonAdd}
                  startIcon={<AddIcon />}
                >
                  <ThemeProvider theme={fontInter}>
                    <Typography className={classes.textAdd}>
                      Add Configuration
                    </Typography>
                  </ThemeProvider>
                </Button>
              </Box>
              <Box marginTop="30px">
                <InnoTable
                  columns={[
                    {
                      name: "name",
                      title: "DSP Name",
                    },
                    {
                      name: "dsp_revenue_types",
                      title: "Revenue Type",
                      renderText: item => renderRevenueType(item),
                    },
                  ]}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleDelete={handleDelete}
                  handleEdit={item =>
                    history.push({
                      pathname: `/admin/konfigurasi/dsp-type-revenue/edit/${item.dsp_id}`,
                      state: { dsp: item },
                    })
                  }
                  idColumnName={"dsp_id"}
                  deleteName={"name"}
                  isLoading={loadingTable}
                  isHaveAction={true}
                  isUsingCheckbox={false}
                  isUsingDeleteConfirmation={true}
                  items={response}
                  loadingColor={""}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  totalPage={pagesCount}
                />
              </Box>
            </Box>
          </Container>
        )}
      </Page>
    </Fragment>
  );
}

export default MasterRevenueType;
