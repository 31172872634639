import {
  Backdrop,
  Breadcrumbs,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { Page, PrimaryButton } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { getCookie } from "../../../../utils/constants";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    margin: 0,
    marginBottom: "6px",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },
  mydateinput: {
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px",
      paddingBottom: "0px",
      height: "44px",
    },
  },
  autoComplete: {
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      height: "44px",
    },
  },
  backdrop1: {
    zIndex: 100,
    color: "#fff",
  },
  inputNum: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px 6px 6px 0px",
      height: "44px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderLeft: "1px solid #d1d5dc",
      borderRight: "1px solid #d1d5dc",
      borderTop: "1px solid #d1d5dc",
      borderBottom: "1px solid #d1d5dc",
    },
    "& .MuiOutlinedInput-input": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
    "& input.Mui-disabled": {
      color: "black",
    },
  },
  font24: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  font16: {
    fontSize: "16px",
  },
  textStyle: {
    marginTop: "5px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  cardParent: {
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
  },
  mt32: {
    marginTop: "32px",
  },
  btnCancel: {
    borderRadius: "6px",
    width: "84px",
    height: "40px",
    border: "1px solid #D1D5DC",
    textTransform: "none",
  },
  btnSave: {
    marginLeft: "10px",
    backgroundColor: "#111827",
    borderRadius: "6px",

    height: "40px",
    color: "#FFFFFF",
    textTransform: "none",
  },
}));

const EditExRate = props => {
  const classes = useStyles();
  const history = useHistory();
  const [submiting, setSubmiting] = useState(false);
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const [dataBody, setDataBody] = useState({
    currency_id: history.location.state.customer.currency_id,
    end_date: new Date(),
    start_date: new Date(),
    value: 0,
    publisher_id: userLogin.publisher.publisher_id,
  });

  const getComposerList = async () => {
    let token = localStorage.getItem("token");
    const urlClientList = `${hardBaseUrl}/exchange-rate/detail/${history.location.state.customer.id}`;
    await axios
      .get(urlClientList, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setDataBody({
          ...dataBody,
          start_date: res.data.data.start_date,
          end_date: res.data.data.end_date,
          value: res.data.data.value,
          currency_id: history.location.state.customer.currency_id,
        });
      })
      .catch(err => {
        new Error(err);
      });
  };

  useEffect(() => {
    getComposerList();
  }, []);

  const handleAdd = async () => {
    const body = Object.fromEntries(
      Object.entries(dataBody).filter(([, value]) => value !== "")
    );
    if (body.currency_id === null || body.currency_id === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Currency Can Not Null",
      });
      return;
    } else if (body.start_date === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please input Start Date",
      });
      return;
    } else if (body.end_date === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please input End Date",
      });
      return;
    } else if (body.value === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please input Value",
      });
      return;
    }
    setSubmiting(true);
    let token = localStorage.getItem("token");
    axios
      .put(
        hardBaseUrl +
          "/exchange-rate/update/" +
          history.location.state.customer.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(res => {
        setSubmiting(false);
        if (res.data.message === "success") {
          Swal.fire({
            title: "Success",
            confirmButtonText: "Ok",
          }).then(result => {
            if (result.value) {
              history.push({
                pathname: "/admin/konfigurasi/exchange-rate",
                state: {
                  selectedCurrency: history.location.state.selectedCurrency,
                },
              });
            }
          });
        }
      })
      .catch(err => {
        setSubmiting(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err.response.data.errors.message}.`,
        });
      });
  };
  const changeValueTotalClaim = e => {
    let newVal = e;
    let resultVal = e;
    if (newVal.includes(",")) {
      resultVal = Number(newVal.replaceAll(",", ""));
    } else {
      resultVal = Number(resultVal);
    }
    setDataBody({
      ...dataBody,
      value: resultVal,
    });
  };
  return (
    <Page className={classes.root} title="Edit Exchanges rate">
      <Backdrop className={classes.backdrop1} open={submiting}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography className={classes.font24}>
                Edit Exchanges rate
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Exchanges rate
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                Edit Exchanges rate
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <p className={classes.textStyle}>
                Feature for create exchanges rate
              </p>
            </ThemeProvider>
          </div>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card className={classes.cardParent}>
              <Grid container>
                <Grid item md={6} style={{ paddingRight: "8px" }}>
                  <label className={classes.label}>Start Date</label>
                  <KeyboardDatePicker
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    multiline
                    value={dataBody.start_date}
                    onChange={e =>
                      setDataBody({
                        ...dataBody,
                        start_date: e.format(),
                      })
                    }
                    inputVariant="outlined"
                    className={classes.mydateinput}
                  />
                </Grid>
                <Grid item md={6} style={{ paddingLeft: "8px" }}>
                  <label className={classes.label}>End Date</label>
                  <KeyboardDatePicker
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    multiline
                    value={dataBody.end_date}
                    onChange={e =>
                      setDataBody({
                        ...dataBody,
                        end_date: e.format(),
                      })
                    }
                    inputVariant="outlined"
                    className={classes.mydateinput}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "16px" }}>
                <Grid item md={6} style={{ paddingRight: "8px" }}>
                  <label className={classes.label}>Currency</label>
                  <FormControl fullWidth variant="outlined">
                    <OutlinedInput
                      disabled={true}
                      placeholder="USD"
                      id="outlined-adornment-amount"
                      className={classes.inputNum}
                      style={{
                        height: "44px",

                        borderRadius: "6px",
                      }}
                      value={history.location.state.currencyName}
                      inputProps={{
                        classes: {
                          input: classes.placeholder,
                        },
                      }}
                      fullWidth={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} style={{ paddingLeft: "8px" }}>
                  <label className={classes.label}>Value</label>
                  <Grid style={{ display: "flex" }}>
                    <Grid
                      style={{
                        width: "48px",
                        height: "44px",
                        marginRight: "-2px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "6px 0px 0px 6px",
                        backgroundColor: "#F9FAFB",
                        border: " 1px solid #D1D5DC",
                        color: "#9AA2B",
                        borderRight: "none",
                      }}
                    >
                      Rp
                    </Grid>
                    <NumberFormat
                      disabled={false}
                      customInput={TextField}
                      variant="outlined"
                      thousandSeparator={true}
                      onChange={e => changeValueTotalClaim(e.target.value)}
                      autoComplete="off"
                      className={classes.inputNum}
                      style={{
                        paddingLeft: "0px",
                        height: "44px",
                      }}
                      size="small"
                      fullWidth
                      value={dataBody.value}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Card>
            <Grid container justifyContent="flex-end" className={classes.mt32}>
              <PrimaryButton
                label="Cancel"
                onClick={() => {
                  history.push({
                    pathname: "/admin/konfigurasi/exchange-rate",
                    state: {
                      selectedCurrency: history.location.state.selectedCurrency,
                    },
                  });
                }}
              />

              <PrimaryButton label="Save Changes" onClick={handleAdd} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default EditExRate;
