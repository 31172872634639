import {
  Breadcrumbs,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Snackbar,
  Tooltip,
  Box,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { Add } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTable } from "inno-ui";
import moment from "moment";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { ButtonWithModalPerItem } from "../../../components/atoms/Button";
import { ButtonGroupTop } from "../../../components/atoms/Button";
import {
  AutoCompleteComponent,
  Page,
  SkeletonComponent,
  PrimaryButton,
} from "components";
import { hardBaseUrl, hardTypeWeb } from "../../../services/urlConstant";
import { getCookie } from "../../../utils/constants";
import { DateTimeDisplay } from "../../../components";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const interFont = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const topMenuButton = [
  {
    id: 0,
    title: "Personalization",
    link: "/admin/konfigurasi/personalisasi",
    selected: false,
  },
  {
    id: 1,
    title: "Payment",
    link: "/admin/konfigurasi/pembayaran",
    selected: false,
  },
  {
    id: 2,
    title: "Original Publisher",
    link: "/admin/konfigurasi/original-publisher",
    selected: false,
  },
  {
    id: 3,
    title: "Activity",
    link: "/admin/konfigurasi/aktifitas",
    selected: false,
  },
  {
    id: 4,
    title: "Invoice",
    link: "/admin/konfigurasi/invoice",
    selected: false,
  },
  {
    id: 5,
    title: "Reminder",
    link: "/admin/konfigurasi/contract-reminder",
    selected: false,
  },
  {
    id: 6,
    title: "Tax",
    link: "/admin/konfigurasi/tax",
    selected: true,
  },
  {
    id: 7,
    title: "Exchanges Rate",
    link: "/admin/konfigurasi/exchange-rate",
    selected: false,
  },
];

function ExchangesRate() {
  const history = useHistory();
  const isMpis = hardTypeWeb === "mpis";
  const initIndex = 0;
  const loadingDelete = false;
  const [openSnack, setOpenSnack] = useState(false);
  const typeSnackbar = "success";
  const message = "This is a message!";
  const [response, setResponse] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");

  const [openSendInvitation, setOpenSendInvitation] = useState(false);
  const [sortValue, setSortValue] = useState(0);
  const [currencyChosen, setCurrencyChosen] = useState(null);
  const [currencyName, setCurrencyName] = useState("");

  const [loadingTable, setLoadingTable] = useState(false);

  const [sortList, setSortList] = useState([]);

  const handleCloseSendInvitation = () => {
    setOpenSendInvitation(false);
  };

  const token = localStorage.getItem("token");
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const pagesCount = Math.ceil(totalData / rowsPerPage);
  const getResponse = async () => {
    setLoadingTable(true);
    const url = `${hardBaseUrl}/exchange-rate/datatable`;
    const params = {
      page,
      per_page: rowsPerPage,
      search: searchKey,
      currency_id: sortValue,
      publisher_id: !isMpis ? userLogin?.publisher?.publisher_id : "",
    };
    const config = {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then(res => {
        if (res.data.data !== null) {
          setResponse(res.data.data);
          setTotalData(res.data.meta.total);
          setPage(res.data.meta.page);
        }
        setLoadingTable(false);
      })
      .catch(() => {
        setLoadingTable(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };
  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };

  const handleChangeSearch = event => {
    setPage(1);
    setSearchKey(event.target.value);
  };

  const getCurrency = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/currency/datatable?per_page=99&page=1`;
      const resCurrency = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setSortList(resCurrency.data.data);
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  useEffect(() => {
    if (sortList) {
      getResponse();
    }
  }, [rowsPerPage, page, searchKey, sortValue]);

  useEffect(() => {
    if (typeof history.location.state !== "undefined") {
      if (history.location.state !== null) {
        setSortValue(history.location.state.selectedCurrency.id);
        setCurrencyName(
          history.location.state.selectedCurrency.currency_iso_code
        );
        setCurrencyChosen(history.location.state.selectedCurrency);
      }
    }
    getCurrency();
    if (searchKey) {
      getResponse();
    }
  }, []);

  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };

  const handleDelete = id => {
    let token = localStorage.getItem("token");

    const url = `${hardBaseUrl}/exchange-rate/delete/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .delete(url, config)
      .then(res => {
        if (res.data.message === "success") {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Success.",
          }).then(result => {
            if (result.isConfirmed === true) {
              getResponse();
            }
          });
        }
      })
      .catch(err => {
        new Error(err);
      });
  };

  const sendInvitation = () => {
    const url = `${hardBaseUrl}/publisher/composer/invitation`;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const payload = {};
    axios
      .post(url, payload, config)
      .then(res => {
        setOpenSendInvitation(false);
        setTimeout(() => {}, 60000);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.data,
        });
      })
      .catch(err => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.errors[0].message || "Error.",
        });
      });
  };
  const handleUploadBtn = () => {
    if (currencyChosen !== null) {
      history.push({
        pathname: isMpis
          ? "/admin/parameter/exchange-rate/add"
          : "/admin/konfigurasi/exchange-rate/add",
        state: { selectedCurrency: currencyChosen },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select Currency First",
      });
    }
  };

  const handleView = item => {
    const pathname = isMpis
      ? `/admin/parameter/exchange-rate/view/${item?.id}?currency=${currencyName}`
      : `/admin/konfigurasi/exchange-rate/view/${item?.id}?currency=${currencyName}`;

    const state = {
      customer: item,
      currencyName: currencyName,
      selectedCurrency: currencyChosen,
    };

    history.push({ pathname, state });
  };

  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    divider: {
      margin: theme.spacing(2, 0),
      borderTop: "1px solid #e1e1e1",
    },
    formControl: {},
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    results: {
      marginTop: theme.spacing(3),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    buttonAdd: {
      backgroundColor: " #111827",
      color: "white",
      padding: "10px 28px 10px 28px",
      height: "40px",
    },
    textAdd: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "500",
      fontStyle: "normal",
      textTransform: "none",
    },
    buttonCancel: {
      height: "40px",
      width: "90px",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
      color: "#111827",
      backgroundColor: "white",
      textTransform: "none",
    },
    buttonSend: {
      height: "40px",
      width: "90px",
      borderRadius: "6px",
      border: "0px solid #D1D5DC",
      color: "white",
      backgroundColor: "#111827",
      textTransform: "none",
    },
    textInvitation: {
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
    },
    searchKey: {
      width: "250px",
      height: "38px",
      border: "1px solid #D1D5DC",
      borderRadius: "6px",
    },
    pageSize: {
      height: "30px",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
      paddingLeft: "5px",
      paddingRight: "5px",
      marginTop: "5px",
    },
    colorSearch: {
      color: "#9AA2B1",
    },
    paginationText: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
    },
    breadcrumbs: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#111827",
    },
    breadCrumbsActive: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#687083",
    },
    placeholder: {
      "&::placeholder": {
        color: "#9AA2B1",
      },
    },
    select: {
      "& .MuiSelect-outlined": {
        paddingTop: "14px",
        paddingBottom: "14px",
        paddingLeft: "20px",
        paddingRight: "30px",
        width: "180px",
      },
    },
    btnDetail: {
      backgroundColor: "#111827",
      color: "white",
      width: "28px",
      height: "28px",
      padding: "6px",
      borderRadius: "6px",
      "&:hover, &.MuiIconButton": {
        background: "#374151 !important",
      },
    },
    rotate: {
      rotate: "45deg",
    },
    textStyle: {
      marginTop: "5px",
      color: "#8f8f8f",
      fontSize: "14px",
    },
    colorWhite: {
      color: "white",
    },
    font24: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "32px",
    },
  }));

  const classes = useStyles();

  const customAction = item => {
    const givenDate = moment(item?.end_date);
    const isAfter = givenDate.isAfter(moment().add(15, "minutes"));
    return (
      <>
        <div style={{ display: "flex" }}>
          <Tooltip title={"Details"} placement="bottom">
            <IconButton
              className={classes.btnDetail}
              onClick={() => handleView(item)}
            >
              <VisibilityOutlinedIcon
                style={{
                  filter: " brightness(0) invert(1)",
                }}
              />
            </IconButton>
          </Tooltip>
          {isAfter && (
            <ButtonWithModalPerItem
              userLogin={userLogin}
              item={item}
              tooltTipCaption="End Period"
              btnDelTitle={"Sure"}
              dialogTitle={"End Period Exchange Rate"}
              btnIcon={<Add className={classes.rotate} />}
              subTitle={
                "Are you sure you want to end period of this Exchange Rate"
              }
              handleDelete={() => {
                handleDelete(item?.id);
              }}
            />
          )}
        </div>
      </>
    );
  };

  const renderMoney = item => {
    return (
      <React.Fragment>
        <NumberFormat
          displayType="text"
          value={item.value}
          thousandSeparator={true}
          decimalScale={2}
          fixedDecimalScale={true}
          prefix="Rp "
        />
      </React.Fragment>
    );
  };

  const columnTable = [
    {
      name: "all",
      title: "Action",
      renderText: item => customAction(item),
    },
    {
      name: "currency_id",
      title: "Currency",
      renderText: item =>
        sortList.find(option => option.id === item)?.currency_iso_code || "-",
    },
    {
      name: "start_date",
      title: "Start Date",
      renderText: date => <DateTimeDisplay date={date} />,
    },
    {
      name: "end_date",
      title: "End Date",
      renderText: date => <DateTimeDisplay date={date} />,
    },
    {
      name: "all",
      title: "Exchange Rate",
      renderText: item => renderMoney(item),
    },
  ];

  const optionsList = sortList.map(item => ({
    label: item.currency_iso_code,
    id: item.id,
  }));

  const handleChangeSort = value => {
    setCurrencyChosen(sortList.find(option => option.id === value));
    setSortValue(value);
    setPage(1);
  };

  return (
    <Page className={classes.root} title="Exchanges Rate">
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
          {message}
        </Alert>
      </Snackbar>
      {loadingDelete ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <Typography className={classes.font24}>
                  Configuration
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography className={classes.breadCrumbs}>
                  {isMpis ? "Parameter" : "Home"}
                </Typography>

                <Typography className={classes.breadCrumbs}>
                  Exchanges Rate
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <ButtonGroupTop
            items={!isMpis && topMenuButton}
            selectedButton={value =>
              history.push(value.data[value.newButtonSelected].link)
            }
            selectedIndex={initIndex}
          />
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item md={6} alignItems="center">
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  placeholder="Search"
                  id="outlined-adornment-amount"
                  className={classes.searchKey}
                  value={searchKey}
                  onChange={event => handleChangeSearch(event)}
                  inputProps={{
                    classes: {
                      input: classes.placeholder,
                    },
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon className={classes.colorSearch} />
                    </InputAdornment>
                  }
                  fullWidth={false}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item md={5}>
                  <Box display="flex" justifyContent="flex-end">
                    <AutoCompleteComponent
                      size="small"
                      options={optionsList}
                      label="Select Currency"
                      value={
                        optionsList.find(option => option.id === sortValue) ||
                        null
                      }
                      onChange={value => handleChangeSort(value)}
                      width={250} // Optional, default is 300
                    />
                  </Box>
                </Grid>
                <Grid item md={5}>
                  <Box display="flex" justifyContent="flex-end">
                    <PrimaryButton
                      label="Create Exchanges Rate"
                      onClick={handleUploadBtn}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Dialog
            open={openSendInvitation}
            onClose={handleCloseSendInvitation}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <ThemeProvider theme={interFont}>
                <Typography className={classes.textInvitation}>
                  Send Invitation
                </Typography>
              </ThemeProvider>
            </DialogTitle>
            <DialogContent>Are you sure want to send invitation?</DialogContent>
            <DialogActions>
              <PrimaryButton
                label="Cancel"
                onClick={handleCloseSendInvitation}
              />
              <PrimaryButton label="Send" onClick={sendInvitation} />
            </DialogActions>
          </Dialog>
          <Divider className={classes.divider} />
          {response && (
            <InnoTable
              columns={columnTable}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              idColumnName={"performer_id"}
              isLoading={loadingTable}
              isUsingCheckbox={false}
              items={response}
              loadingColor={""}
              page={page}
              rowsPerPage={rowsPerPage}
              totalPage={pagesCount}
            />
          )}
        </Container>
      )}
    </Page>
  );
}

export default ExchangesRate;
