import React, { useState } from "react";
import ContractForm from "./Components/ContractForm";
import { hardBaseUrl } from "../../../services/urlConstant";
import axios from "axios";
import Swal from "sweetalert2";

function AddContract() {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: "Bearer " + token,
  };
  const urlContract = `${hardBaseUrl}/publisher/contract`;
  const urlDocuments = `${hardBaseUrl}/contract-document`;
  const formData = new FormData();
  const [loadingContract, setLoadingContract] = useState(false);

  const modalError = err =>
    Swal.fire({
      title: "Error",
      text: err?.response?.data?.errors?.message,
      icon: "error",
      confirmButtonText: "OK",
    });

  const modalSuccess = () =>
    Swal.fire({
      title: "Success",
      text: "Data has been saved",
      icon: "success",
      confirmButtonText: "OK",
    });

  const handlSubmitData = (payload, supportingDocumentFiles, contractType) => {
    setLoadingContract(true);
    const isSongContract = contractType === "Song Contract";
    const mainContractFormEndpoint = !isSongContract ? "/composer-main" : "";
    const mainContractDocumentsEndpoint = !isSongContract
      ? "/contract/composer-main"
      : "";

    axios
      .post(`${urlContract}${mainContractFormEndpoint}`, payload, {
        headers,
      })
      .then(res => {
        if (res.status === 200) {
          const contractId = res?.data?.data?.contract_id;
          formData.append("contract_id", contractId);
          formData.append("documents", supportingDocumentFiles[0]);
          axios
            .post(`${urlDocuments}${mainContractDocumentsEndpoint}`, formData, {
              headers,
            })
            .then(() => {
              setLoadingContract(false);
              modalSuccess().then(result => {
                if (result.isConfirmed) {
                  window.location.href = "/admin/kontrak-composer";
                }
              });
            })
            .catch(err => {
              modalError(err);
            });
        }
      })
      .catch(err => {
        modalError(err);
        setLoadingContract(false);
      })
      .finally(() => {
        setLoadingContract(false);
      });
  };
  return (
    <ContractForm
      title="Add Contract"
      handlSubmitData={handlSubmitData}
      loadingContract={loadingContract}
    />
  );
}

export default AddContract;
