import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import React from "react";

const interFont = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function ButtonWithModalPerItem({
  title,
  subTitle,
  open,
  onClose,
  onClick,
  type,
  buttonDisabled,
}) {
  const handleTextButton = type => {
    if (type === "approve") {
      return "Approve";
    } else if (type === "reject") {
      return "Reject";
    } else if (type === "confirm") {
      return "Confirm";
    }
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <ThemeProvider theme={interFont}>
            <Typography
              style={{
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              {title}
            </Typography>
          </ThemeProvider>
        </DialogTitle>
        <DialogContent>{subTitle}</DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            style={{
              height: "40px",
              width: "90px",
              borderRadius: "6px",
              border: "1px solid #D1D5DC",
              color: "#111827",
              backgroundColor: "white",
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onClick}
            autoFocus
            style={{
              height: "40px",
              width: "90px",
              borderRadius: "6px",
              border: "0px solid #D1D5DC",
              color: "white",
              backgroundColor: buttonDisabled ? "gray" : "#111827",
              textTransform: "none",
            }}
            disabled={buttonDisabled}
          >
            {handleTextButton(type)}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ButtonWithModalPerItem;
