import { Typography, Box } from "@mui/material";

const FormLabel = ({ label, required }) => {
  return (
    <Box display="flex" gap="5px">
      <Typography fontSize="14px" fontWeight={500} mb="6px">
        {label}
      </Typography>
      {required && (
        <Typography color="red">*</Typography>
      )
      }
    </Box>
    // <Typography fontSize="14px" fontWeight={500} mb="6px">
    //   {label} {required && <Typography color="red">*</Typography>}
    // </Typography>
  );
};

export default FormLabel;
