import { useEffect, useState } from "react";

const Timer = ({ time, onTimeEnd }) => {
  const [seconds, setSeconds] = useState(time);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      onTimeEnd();
    }
  }, [seconds]);

  const format = seconds => {
    const minutes = Math.floor(seconds / 60);

    const remainingSeconds = seconds % 60;

    if (minutes === 0 && remainingSeconds === 0) {
      onTimeEnd();
      return "00 : 00";
    }

    return `${minutes < 10 ? 0 : ""}${minutes}:${
      remainingSeconds < 10 ? 0 : ""
    }${remainingSeconds}`;
  };

  return (
    <div className="timer">
      <span>{format(seconds)}</span>
    </div>
  );
};

export default Timer;
