import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useState } from "react";
import TableEditBar from "../../../components/molecules/Table/TableEditBar";

const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
  },
  nameCell: {
    display: "flex",
    alignItems: "center",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  bodyTextGrey: {
    fontSize: "12px",
    fontWeight: "regular",
    color: "#687083",
    fontStyle: "normal",
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  textField: {
    width: "150px",
  },
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
  },
  ib: {
    padding: "4px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",

      boxShadow: "none",
    },
  },
}));

function ResultsAktivitas({
  className,
  handleChangePageSet,
  pageSizePagination,
  items,
  page,
  onChangePage,
  totalData,
  ...rest
}) {
  const classes = useStyles();

  const selectedCustomers = [];

  const [openImage, setOpenImage] = useState(false);
  const imageToOpen = null;

  const handleCloseImage = () => {
    setOpenImage(false);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <TableContainer component={Paper} className={classes.inner}>
        <Table
          style={{
            border: "0px solid black",
          }}
        >
          <TableHead
            style={{
              border: "0px solid black",
            }}
          >
            <TableRow
              style={{
                border: "0px solid black",
              }}
            >
              <TableCell>Date & Time</TableCell>
              <TableCell>Account</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Information</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.length > 0 ? (
              items.map(customer => (
                <TableRow
                  hover
                  key={customer.id}
                  selected={selectedCustomers.indexOf(customer.id) !== -1}
                >
                  <TableCell>
                    <Typography
                      variant="subtitle2"
                      className={classes.bodyTextBlack}
                    >
                      <div className={classes.bodyTextBlack}>
                        {customer.date}
                      </div>

                      <small className={classes.bodyTextGrey}>
                        {customer.time}
                      </small>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.bodyTextBlack}
                        >
                          <div className={classes.bodyTextBlack}>
                            {customer.created_by_name}
                          </div>

                          <small className={classes.bodyTextGrey}>
                            {customer.created_by_email}
                          </small>
                        </Typography>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      width: "20%",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        {customer.action}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        {customer.slug_action}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No data available
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        alignContent="space-between"
        container
        justifyContent="space-between"
        style={{
          marginTop: "16px",
        }}
      >
        <Grid item>
          <div style={{ display: "flex" }}>
            <div style={{ marginTop: "7px" }}>
              <ThemeProvider theme={theme}>
                <Typography className={classes.paginationText}>
                  Items per page
                </Typography>
              </ThemeProvider>
            </div>{" "}
            <div style={{ marginLeft: "24px" }}>{pageSizePagination}</div>
          </div>
        </Grid>

        <Grid item>
          <Pagination
            count={totalData}
            shape="rounded"
            className={classes.pagination}
            handlechangepage={onChangePage}
            page={page}
            onChange={handleChangePageSet}
          />
        </Grid>
      </Grid>
      <Dialog
        onClose={handleCloseImage}
        aria-labelledby="simple-dialog-title"
        open={openImage}
      >
        <Paper elevation={0} />
        <CancelIcon
          onClick={() => {
            setOpenImage(false);
          }}
          style={{
            color: "black",
            position: "absolute",
            right: 0,
          }}
        />
        <img src={imageToOpen} alt="" />
        <Paper />
      </Dialog>
      <TableEditBar selected={selectedCustomers} />
    </div>
  );
}

ResultsAktivitas.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array,
};

ResultsAktivitas.defaultProps = {
  customers: [],
};

export default ResultsAktivitas;
