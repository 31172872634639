import React, { useState, useEffect, useRef } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ChipComponent from "./ChipComponent";
import {
  Container,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardContent,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Breadcrumbs,
  IconButton,
  Chip,
  ListItemText,
} from "@material-ui/core";
import { InnoTable } from "inno-ui";
import _, { uniqWith } from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Page } from "components";
import updateIcon from "assets/img/pencilVector.svg";
import deleteIcon from "assets/img/trashVector.svg";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { useLocation, useParams, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { getErrors } from "utils";
import { API_ENDPOINTS } from "constants";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  thePercent: {
    fontSize: "30px",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  wrapperPercentage: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: 10,
  },
  btnSubmit: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    width: "106px",
    height: "40px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnPengaturanPencipta: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnPreview: {
    backgroundColor: "black",
    marginLeft: "10px",
    color: "white",
    padding: "10px 28px",
    width: "106px",
    height: "40px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnDialog: {
    backgroundColor: "black",
    marginLeft: "10px",
    color: "white",
    padding: "10px 19px",
    width: "150px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  dropdown: {
    color: "black",
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  btnEdit: {
    backgroundColor: "#111827",
    color: "white",
    width: "30px",
    height: "35px",
    padding: "6px",
    borderRadius: "6px",
    //set hover
    "&:hover": {
      backgroundColor: "#111827",
      color: "white",
    },
  },
  btnDelete: {
    backgroundColor: "#111827",
    color: "white",
    width: "35px",
    height: "35px",
    padding: "6px",
    borderRadius: "6px",
    marginLeft: "10px",
    cursor: "pointer",
  },
  required: {
    color: "red",
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function EditLagu(props) {
  const ref = useRef(null);
  const classes = useStyles();
  const { state } = useLocation();
  const history = useHistory();
  const { id } = useParams();

  const token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const [judul, setJudul] = useState();
  const [publisher, setPublisher] = useState();
  const [publisherList, setPublisherList] = useState([]);
  const [performerList, setPerformerList] = useState([]);
  const [penciptaData, setPenciptaData] = useState([]);
  const [penciptaList, setPenciptaList] = useState([]);
  const [writerRoleList, setWriterRole] = useState([]);
  const [openModalSelect, setOpenModalSelect] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [isrc, setIsrc] = useState(state?.customer.isrc_code);
  const [getColor, setGetColor] = useState("");
  const [relatedIsrc, setRelatedIsrc] = useState("");
  const [publisherShare, setPublisherShare] = useState(0);
  const [performerData, setPerformerData] = useState([]);
  const [statusDialog, setStatusDialog] = useState("add");
  const [performerDataTable, setPerformerDataTable] = useState([]);
  const [indexSel, setIndexSel] = useState("");
  const [failedNotif, setFailedNotif] = useState(false);
  const [openModalSelectPerformer, setOpenModalSelectPerformer] = useState(
    false
  );
  const [isComposerEditable, setIsComposerEditable] = useState(false);
  const [
    openModalSelectPerformerAlias,
    setOpenModalSelectPerformerAlias,
  ] = useState(false);
  const [statusDialogAlias, setStatusDialogAlias] = useState("add");
  const [aliasName, setAliasName] = useState("");
  const [delLabel, setDelLabel] = useState("");
  const [workcode, setWorkcode] = useState("");
  const [tableAliasName, setTableAliasName] = useState([]);
  const [confirmDel, setConfirmDel] = useState(false);
  const [position, setPosition] = useState(0);
  const [listboxNode, setListboxNode] = useState("");
  const [isLoadingList, setIsLoadingList] = useState({
    pencipta: false,
    performer: false,
  });
  const [paramsPencipta, setParamsPencipta] = useState({
    page: 1,
    limit: 10,
  });
  const [paramsPerformer, setParamsPerformer] = useState({
    page: 1,
    limit: 10,
  });
  const [metaList, setMetaList] = useState({
    penciptaMeta: {},
    performerMeta: {},
  });
  const [tableConfig, setTableConfig] = useState({
    size: 10,
    page: 1,
  });
  const [submitting, setSubmitting] = useState(false);
  const { size, page } = tableConfig;
  // Need index because following the legacy code :(
  const newPerformerDataTable = [...performerDataTable]
    .map((item, index) => ({
      ...item,
      index,
    }))
    .slice((page - 1) * size, page * size);

  const handlePageChange = (_, newPage) => {
    setTableConfig(prevConfig => ({
      ...prevConfig,
      page: newPage,
    }));
  };

  const handleChangeRowsPerPage = event => {
    const newSize = parseInt(event.target.value, 10);
    setTableConfig({
      size: newSize,
      page: 1,
    });
  };

  const getPenciptaList = async () => {
    setIsLoadingList({ ...isLoadingList, pencipta: true });
    try {
      const res = await axios.get(API_ENDPOINTS.GET_PUBLISHER_COMPOSER, {
        headers,
        params: {
          page: paramsPencipta?.search ? 1 : paramsPencipta.page,
          size: paramsPencipta.limit,
          search: paramsPencipta.search,
        },
      });
      if (res?.data?.meta?.http_status === 200) {
        setMetaList(prev => ({ ...prev, penciptaMeta: res.data.meta }));
        setPenciptaList(prev =>
          uniqWith([...prev, ...res.data.data], (arr, otherArr) => {
            return arr.composer_id === otherArr.composer_id;
          })
        );
      }
    } catch (error) {
      new Error(error);
    } finally {
      setIsLoadingList({ ...isLoadingList, pencipta: false });
    }
  };

  const getPerformerList = async () => {
    setIsLoadingList({ ...isLoadingList, performer: true });
    try {
      const res = await axios.get(API_ENDPOINTS.GET_PUBLISHER_PERFORMER, {
        headers,
        params: {
          page: paramsPerformer?.search ? 1 : paramsPerformer.page,
          size: paramsPerformer.limit,
          search: paramsPerformer.search,
        },
      });
      if (res?.data?.meta?.http_status === 200) {
        setMetaList(prev => ({ ...prev, performerMeta: res.data.meta }));
        setPerformerList(prev =>
          uniqWith([...prev, ...res.data.data], (arr, otherArr) => {
            return arr.performer_id === otherArr.performer_id;
          })
        );
      }
    } catch (error) {
      new Error(error);
    } finally {
      setIsLoadingList({ ...isLoadingList, performer: false });
    }
  };

  const getWriterRoleList = async () => {
    try {
      const res = await axios.get(API_ENDPOINTS.GET_WRITER_ROLE, {
        headers,
      });
      const data = [];
      res.data.data?.map(value => {
        data.push({
          code: value.writer_role_code,
          name: value.writer_role_name,
        });
      });
      setWriterRole(data);
    } catch (error) {
      new Error(error);
    }
  };

  const getResponse = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = hardBaseUrl + `/songs/${id}`;

      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPublisherShare(res.data.data.publisher_share_percentage);
      setRelatedIsrc(state.customer.isrc_code);
      setIsrc(res.data.data.iswc_code);
      setIsComposerEditable(res.data.data.is_song_composer_editable);
      setWorkcode(res.data.data.work_code);
      setPublisher(res.data.data.original_publisher_id);
      setJudul(res.data.data.song_title);
      const adjustData = res.data.data.song_composers.map(item => {
        return {
          ...item,
          composer_id: item.composer_id,
          percentage_ownership: parseFloat(item.composer_percentage),
          percentage_publisher: parseFloat(item.share_percentage),
          sure_name: item.composer_name,
          is_composer_editable:
            res.data.data.is_song_composer_editable || false,
          alias_name: item.composer_alias,
          type: writerRoleList,
          choosenType: item.composer_roles,
          is_editable: item?.is_editable || false,
        };
      });
      setPenciptaData(adjustData);
      setTableAliasName(res.data.data.song_aliases || []);
      if (res.data.data.performers !== null) {
        const dataPerformer = res.data.data.performers.map(item => ({
          ...item,
        }));
        setPerformerData(dataPerformer);
      }
      let tempSongRecIds = res.data.data.song_recordings;
      if (tempSongRecIds.length > 0) {
        let tempSongs = [];
        tempSongRecIds.forEach(element => {
          let newId = element.song_recording_id;
          tempSongs.push(newId);
        });
      }
      setPerformerDataTable(res.data.data.song_recordings);
    } catch (err) {
      new Error(err);
    }
  };
  const getPublisherList = async () => {
    try {
      const res = await axios.get(
        API_ENDPOINTS.GET_PUBLISHER_SONG_ORIGINAL_PUBLISHER,
        {
          headers,
        }
      );
      setPublisherList(res.data.data);
    } catch (error) {
      new Error(error);
    }
  };

  const getMainDsp = async () => {
    try {
      const res = await axios.get(API_ENDPOINTS.GET_ME, {
        headers,
      });
      setGetColor(res.data.data.publisher.theme_color);
    } catch (e) {
      new Error(e);
    }
  };

  useEffect(() => {
    getWriterRoleList();
    getPenciptaList();
    getPerformerList();
    getMainDsp();
    getPublisherList();
  }, []);

  useEffect(() => {
    getResponse();
  }, [writerRoleList]);

  useEffect(() => {
    if (listboxNode !== "" && position) {
      listboxNode.scrollTop = position;
    }
  }, [position, listboxNode]);
  const onChangeDialogPerformer = (e, data) => {
    const newData = data.map(item => ({
      performer_name: item.first_name,
      ...item,
    }));
    setPerformerData(newData);
  };
  const fetchMoreDataPencipta = () => {
    setParamsPencipta(prev => ({
      page: prev.page + 1,
      limit: prev.limit,
      search: prev.search,
    }));
  };
  const slicingDataListPerformer = () => {
    setParamsPerformer(prev => ({
      page: prev.page + 1,
      limit: prev.limit,
      search: prev.search,
    }));
  };
  const handleScrollListBox = (event, type) => {
    setListboxNode(event.currentTarget);
    const x = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - x <= 1) {
      setPosition(x);
      if (type === "performer" && !isLoadingList.performer) {
        const modulusPagePerformer =
          metaList.performerMeta?.total % metaList?.performerMeta?.limit;
        const remainingCountPermorfer =
          modulusPagePerformer >= 1
            ? Math.floor(
              metaList?.performerMeta?.total / metaList?.performerMeta?.limit
            ) + 1
            : metaList?.performerMeta?.total / metaList?.performerMeta?.limit;
        if (paramsPerformer.page < remainingCountPermorfer) {
          slicingDataListPerformer();
        }
      }
      if (type === "pencipta" && !isLoadingList.pencipta) {
        const modulusPagePencipta =
          metaList.penciptaMeta?.total % metaList?.penciptaMeta?.limit;
        const remainingCountPencipta =
          modulusPagePencipta >= 1
            ? Math.floor(
              metaList?.penciptaMeta?.total / metaList?.penciptaMeta?.limit
            ) + 1
            : metaList?.penciptaMeta?.total / metaList?.penciptaMeta?.limit;
        if (paramsPencipta.page < remainingCountPencipta) {
          fetchMoreDataPencipta();
        }
      }
    }
  };
  useEffect(() => {
    const sum = penciptaData.reduce(
      (a, b) => Number(a) + Number(b.percentage_ownership),
      0
    );
    setPercentage(sum);
  }, [penciptaData]);
  const handleChange = event => {
    const { name, value } = event.target;
    if (name === "judul") {
      setJudul(value);
    } else if (name === "publisher") {
      setPublisher(value);
    } else if (name === "relatedIsrc") {
      setRelatedIsrc(value);
    } else if (name === "isrc") {
      setIsrc(value);
    } else if (name === "aliasName") {
      setAliasName(value);
    } else if (name === "workcode") {
      setWorkcode(value);
    }
  };
  const chocho = number => {
    let arrTemp = penciptaData;
    if (typeof arrTemp[number] !== "undefined") {
      return arrTemp[number].choosenType;
    } else {
      return [];
    }
  };
  const onChangeDialog = (e, data) => {
    let newData = data.map((item, index) => ({
      ...item,
      percentage_ownership: item.percentage_ownership || 0,
      percentage_publisher: item.percentage_publisher || 0,
      choosenType: chocho(index),
      is_editable: true,
      type: writerRoleList,
    }));
    setPenciptaData(newData);
  };
  const onClickDialog = e => {
    e.preventDefault();
    setOpenModalSelect(false);
  };
  const handleChangePersentageValueTable = (e, idValue) => {
    const { value } = e.target;
    setPenciptaData(prevState =>
      prevState.map(item => ({
        ...item,
        percentage_ownership:
          item.composer_id === idValue ? value : item.percentage_ownership,
      }))
    );
  };
  const handleChangePublisherShare = (e, idValue) => {
    const { value } = e.target;
    setPenciptaData(prevState =>
      prevState.map(item => ({
        ...item,
        percentage_publisher:
          item.composer_id === idValue ? value : item.percentage_publisher,
      }))
    );
    //accumulate all value at item.percentage into setPercentage value
  };
  const handleResetDialog = () => {
    setPenciptaData([]);
    setOpenModalSelect(false);
  };
  const handleResetDialogPerformer = () => {
    setPerformerData([]);
    setOpenModalSelectPerformer(false);
  };
  const handleChangeTableType = (e, id) => {
    const newData = [...penciptaData];
    const index = newData.findIndex(item => item.composer_id === id);
    newData[index].choosenType = e.target.value;
    setPenciptaData(newData);
  };
  const onCloseModalSelectPerformer = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenModalSelectPerformer(false);
    }
  };
  const onClickDialogPerformer = e => {
    e.preventDefault();
    if (performerDataTable.length > 0) {
      let resFind = _.find(performerDataTable, function (o) {
        return o.isrc_code === relatedIsrc;
      });
      if (typeof resFind === "undefined") {
        let arrTable = {
          id: relatedIsrc,
          song_recording_id: 0,
          isrc: relatedIsrc,
          isrc_code: relatedIsrc,
          performers: performerData,
        };
        setPerformerDataTable([...performerDataTable, arrTable]);
        setOpenModalSelectPerformer(false);
      } else {
        setFailedNotif(true);
      }
    } else {
      let arrTable = {
        id: relatedIsrc,
        song_recording_id: 0,
        isrc: relatedIsrc,
        isrc_code: relatedIsrc,
        performers: performerData,
      };
      setPerformerDataTable([...performerDataTable, arrTable]);
      setOpenModalSelectPerformer(false);
    }
  };
  const onClickUpdateDialogPerformer = e => {
    e.preventDefault();
    let copyVal = performerDataTable;
    let copyValSelArr = performerDataTable[indexSel];
    copyValSelArr.id = relatedIsrc;
    copyValSelArr.isrc = relatedIsrc;
    copyValSelArr.isrc_code = relatedIsrc;
    let arrTable = {
      song_recording_id: copyValSelArr.song_recording_id,
      isrc_code: copyValSelArr.isrc_code,
      performers: performerData,
    };
    copyVal[indexSel] = arrTable;
    setPerformerDataTable(copyVal);
    setOpenModalSelectPerformer(false);
  };
  const reformatSongRec = () => {
    let newItemOuter = [];
    if (performerDataTable.length > 0) {
      let newVal = _.map(performerDataTable, item => {
        let thePerformers = item.performers;
        let insideMap = _.map(thePerformers, performer => {
          let performerItem = {
            performer_id: performer.performer_id,
            performer_name: `${performer.performer_name}`,
          };
          return performerItem;
        });
        let newItem = {
          song_recording_id: item.song_recording_id || 0,
          isrc_code: item.isrc_code,
          performers: insideMap,
        };
        return newItem;
      });
      newItemOuter = newVal;
    } else {
      newItemOuter = [];
    }
    return newItemOuter;
  };
  const handleButtonSimpan = async () => {
    let token = localStorage.getItem("token");
    const songComposerData = penciptaData.map(item => ({
      composer_id: item.composer_id,
      composer_percentage: parseFloat(item.percentage_ownership),
      composer_roles: item.choosenType,
      share_percentage: parseFloat(item.percentage_publisher),
    }));
    const songPerformerData = performerData.map(item => item.performer_id);
    let formatSongRec = reformatSongRec();
    let payload = {
      song_id: state?.customer.song_id,
      song_title: judul,
      iswc_code: isrc,
      alias_names: tableAliasName,
      song_aliases: tableAliasName,
      isrc_code: "",
      original_publisher_id: !publisher ? 0 : publisher,
      list_performer_id: songPerformerData,
      publisher_share_percentage: parseFloat(publisherShare),
      song_composers: songComposerData,
      song_recordings: formatSongRec,
      work_code: workcode,
    };
    let baseOfUrl = hardBaseUrl;
    let urlUse = baseOfUrl + `/songs/${state?.customer.song_id}`;
    setSubmitting(true);
    axios
      .put(urlUse, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "Song data was successfully updated",
          icon: "success",
          confirmButtonText: "Ok",
        });
        props.history.push("/admin/parameter/lagu");
      })
      .catch(err => {
        let errMessage = getErrors(err.response);
        setSubmitting(false);
        Swal.fire({
          title: "Error",
          text: errMessage,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };
  const onDel = e => {
    let newPerf = performerDataTable;
    let result = _.remove(newPerf, function (n) {
      return n.isrc_code !== e;
    });
    setPerformerDataTable(result);
  };
  const onEdit = (e, index) => {
    setStatusDialog("edit");
    let getVal = performerDataTable[index];
    setIndexSel(index);
    setRelatedIsrc(getVal.isrc_code);
    setPerformerData(getVal.performers);
    setOpenModalSelectPerformer(true);
  };
  const onClickAddPerformer = () => {
    setStatusDialog("add");
    setPerformerData([]);
    setRelatedIsrc("");
    setOpenModalSelectPerformer(true);
  };
  const onClickAddAlias = () => {
    setStatusDialogAlias("add");
    setAliasName("");
    setOpenModalSelectPerformerAlias(true);
  };
  const handleResetDialogPerformerAlias = () => {
    setOpenModalSelectPerformerAlias(false);
    setAliasName("");
  };
  const onClickDialogAlias = e => {
    e.preventDefault();
    if (aliasName !== "") {
      let newAlias = aliasName;
      let newArr = tableAliasName;
      newArr.push(newAlias);
      setTableAliasName(newArr);
      setOpenModalSelectPerformerAlias(false);
      setAliasName("");
    } else {
      setOpenModalSelectPerformerAlias(false);
      Swal.fire({
        title: "Error",
        text: "Please Fill Alias Name.",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then(result => {
        if (result.value) {
          setOpenModalSelectPerformerAlias(true);
        }
      });
    }
  };
  const goDelete = () => {
    let currentAlias = tableAliasName;
    let afterRemove = _.remove(currentAlias, function (n) {
      return n !== delLabel;
    });
    setTableAliasName(afterRemove);
    setConfirmDel(false);
  };
  const onDelAlias = e => {
    setDelLabel(e);
    setConfirmDel(true);
  };
  const onClickUpdateDialogAlias = e => {
    e.preventDefault();
    if (aliasName !== "") {
      setOpenModalSelectPerformerAlias(false);
    } else {
      setOpenModalSelectPerformerAlias(false);
      Swal.fire({
        title: "Error",
        text: "Please Fill Alias Name.",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then(result => {
        if (result.value) {
          setOpenModalSelectPerformerAlias(true);
        }
      });
    }
  };
  const onEditAlias = (e) => {
    setStatusDialogAlias("edit");
    setAliasName(e);
    setOpenModalSelectPerformerAlias(true);
  };
  return (
    <Page className={classes.root} title="Edit Song">
      <Container maxWidth={false}>
        <form ref={ref}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    lineHeight: "32px",
                  }}
                >
                  Edit Song
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography className={classes.breadCrumbs}>Home</Typography>
                <Typography className={classes.breadCrumbs}>
                  Parameter
                </Typography>
                <Typography className={classes.breadCrumbs}>Song</Typography>
                <Typography className={classes.breadCrumbsActive}>
                  Edit Song
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container>
            <div>
              <ThemeProvider theme={theme}>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Song Information
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  This feature is used to edit song data.
                </p>
              </ThemeProvider>
            </div>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={8} sm={12} lg={8} xl={8}>
              <Card
                style={{
                  boxShadow: "none",
                  borderRadius: "6px",
                  border: "1px solid #9AA2B1",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Song Title
                              <span className={classes.required}>*</span>
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {typeof judul !== "undefined" ? (
                            <>
                              <TextField
                                id="outlined-basic"
                                placeholder="Song Title"
                                variant="outlined"
                                fullWidth={true}
                                name="judul"
                                onChange={event => handleChange(event)}
                                value={judul}
                              />
                            </>
                          ) : null}
                        </div>
                        <div
                          style={{
                            marginTop: "17px",
                            marginBottom: "10px",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Original Publisher
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth={true}
                          >
                            <InputLabel
                              id="demo-simple-select-outlined-label"
                              shrink={false}
                            >
                              {typeof publisher !== "undefined"
                                ? null
                                : "Add Publisher"}
                            </InputLabel>
                            {typeof publisher !== "undefined" ? (
                              <>
                                <Select
                                  id="outlined-basic"
                                  variant="outlined"
                                  fullWidth={true}
                                  name="publisher"
                                  onChange={event => handleChange(event)}
                                  value={Number(publisher)}
                                >
                                  {publisherList?.length > 0 ? (
                                    publisherList.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={`${index}`}
                                          value={item.original_publisher_id}
                                        >
                                          {item.name}
                                        </MenuItem>
                                      );
                                    })
                                  ) : (
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                  )}
                                </Select>
                              </>
                            ) : null}
                          </FormControl>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "14px",
                            marginBottom: "4px",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              ISWC Code
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div
                          style={{
                            marginBottom: "20px",
                          }}
                        >
                          <TextField
                            id="isrc"
                            placeholder="ISWC"
                            value={isrc}
                            variant="outlined"
                            fullWidth={true}
                            name="isrc"
                            onChange={event => handleChange(event)}
                          />
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Work Code{" "}
                              <span className={classes.required}>*</span>
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <TextField
                            id="outlined-basic"
                            placeholder="Work Code"
                            variant="outlined"
                            fullWidth={true}
                            name="workcode"
                            value={workcode}
                            onChange={event => handleChange(event)}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Song Alias
                </Typography>
                <Button
                  className={classes.btnPengaturanPencipta}
                  style={{
                    backgroundColor: getColor,
                  }}
                  onClick={() => onClickAddAlias()}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      style={{
                        textTransform: "none",
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                      }}
                    >
                      Add Song Alias
                    </Typography>
                  </ThemeProvider>
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} md={8} sm={12} lg={8} xl={8}>
              <Card>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Action
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Song Alias
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableAliasName?.length > 0 ? (
                      tableAliasName.map((item, index) => {
                        return (
                          <TableRow key={`${index}`}>
                            <ThemeProvider theme={theme}>
                              <TableCell
                                style={{
                                  width: "10%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => onEditAlias(item, index)}
                                    className={classes.btnEdit}
                                    style={{
                                      backgroundColor: getColor || "black",
                                    }}
                                  >
                                    <div>
                                      <img
                                        src={updateIcon}
                                        alt="update-icon"
                                        style={{
                                          filter: " brightness(0) invert(1)",
                                        }}
                                      />
                                    </div>
                                  </IconButton>
                                  <IconButton
                                    onClick={() => onDelAlias(item)}
                                    className={classes.btnDelete}
                                    style={{
                                      backgroundColor: getColor || "black",
                                    }}
                                  >
                                    <div>
                                      <img
                                        src={deleteIcon}
                                        alt="delete-icon"
                                        style={{
                                          filter: " brightness(0) invert(1)",
                                        }}
                                      />
                                    </div>
                                  </IconButton>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "30%",
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {item}
                                </Typography>
                              </TableCell>
                            </ThemeProvider>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <div
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              style={{
                                color: "#687083",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              No Data
                            </Typography>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              marginTop: "20px",
            }}
          >
            <Grid item xs={12} md={8} sm={12} lg={8} xl={8}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      component="h1"
                      variant="h3"
                      className={classes.subTitle}
                    >
                      Performer
                    </Typography>
                  </ThemeProvider>
                  <ThemeProvider theme={theme}>
                    <span
                      style={{
                        color: "#8f8f8f",
                        fontSize: "14px",
                      }}
                    >
                      {"Add Performer list for song's information"}
                    </span>
                  </ThemeProvider>
                </div>
                <div>
                  <Button
                    className={classes.btnPengaturanPencipta}
                    style={{
                      backgroundColor: getColor,
                    }}
                    onClick={onClickAddPerformer}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          textTransform: "none",
                          color: "#FFFFFF",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "20px",
                        }}
                      >
                        Add Performer
                      </Typography>
                    </ThemeProvider>
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={8} sm={12} lg={8} xl={8}>
              <InnoTable
                columns={[
                  {
                    name: "all",
                    title: "Action",
                    renderText: item => (
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <IconButton
                          onClick={() => onEdit(item.isrc_code, item.index)}
                          className={classes.btnEdit}
                          style={{
                            backgroundColor: getColor || "black",
                          }}
                        >
                          <img
                            src={updateIcon}
                            alt="update-icon"
                            style={{
                              filter: " brightness(0) invert(1)",
                            }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => onDel(item.isrc_code)}
                          className={classes.btnDelete}
                          style={{
                            backgroundColor: getColor || "black",
                          }}
                        >
                          <div>
                            <img
                              src={deleteIcon}
                              alt="delete-icon"
                              style={{
                                filter: " brightness(0) invert(1)",
                              }}
                            />
                          </div>
                        </IconButton>
                      </div>
                    ),
                  },
                  {
                    name: "isrc_code",
                    title: "ISRC Code",
                  },
                  {
                    name: "performers",
                    title: "Performer",
                    renderText: (performers) => (
                      <ChipComponent items={performers} />
                    ),
                  },
                ]}
                handleChangePage={handlePageChange}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                idColumnName={"billing_id"}
                isLoading={false}
                items={newPerformerDataTable}
                loadingColor={""}
                page={page}
                rowsPerPage={size}
                totalPage={Math.ceil(performerDataTable?.length / size)}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <div>
                <ThemeProvider theme={theme}>
                  <Typography
                    component="h1"
                    variant="h3"
                    className={classes.subTitle}
                  >
                    Composer/Author
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <p
                    style={{
                      marginTop: "5px",
                      color: "#8f8f8f",
                      fontSize: "14px",
                    }}
                  >
                    This is the composer/author of the song
                  </p>
                </ThemeProvider>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              lg={12}
              xl={12}
              className={classes.wrapperPercentage}
            >
              {isComposerEditable ? (
                <div
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <Button
                    className={classes.btnPengaturanPencipta}
                    onClick={() => setOpenModalSelect(true)}
                    style={{
                      backgroundColor: getColor,
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          textTransform: "none",
                          color: "#FFFFFF",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "20px",
                        }}
                      >
                        COMPOSER/AUTHOR CONFIGURATION
                      </Typography>
                    </ThemeProvider>
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <Button
                    className={classes.btnPengaturanPencipta}
                    style={{
                      backgroundColor: "grey",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          textTransform: "none",
                          color: "#FFFFFF",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "20px",
                        }}
                      >
                        COMPOSER/AUTHOR CONFIGURATION
                      </Typography>
                    </ThemeProvider>
                  </Button>
                </div>
              )}
              <Typography>
                Percentage{" "}
                <span className={classes.thePercent}>{percentage}%</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card>
                <div
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Composer/Author Name
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Alias Name
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Ownership Percentage{" "}
                              <span className={classes.required}>*</span>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                              style={{ width: "200px" }}
                            >
                              Role <span className={classes.required}>*</span>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Publisher Percentage{" "}
                              <span className={classes.required}>*</span>
                            </Typography>
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {penciptaData?.length > 0 ? (
                        penciptaData.map((item, index) => {
                          return (
                            <TableRow key={`${item.composer_id}-${index}`}>
                              <ThemeProvider theme={theme}>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {item.sure_name}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.bodyTextBlack}
                                  >
                                    {item.alias_names.join(", ")}
                                  </Typography>
                                </TableCell>
                              </ThemeProvider>
                              <TableCell>
                                <FormControl
                                  variant="outlined"
                                  className={classes.formControl}
                                  fullWidth={false}
                                >
                                  <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    id={`${item.composer_id}`}
                                    disabled={
                                      item?.is_editable
                                        ? false
                                        : true || item?.is_composer_editable
                                    }
                                    name="composer"
                                    onChange={e =>
                                      handleChangePersentageValueTable(
                                        e,
                                        item.composer_id
                                      )
                                    }
                                    value={item.percentage_ownership}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <div>%</div>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  <FormControl
                                    variant="outlined"
                                    fullWidth={true}
                                    style={{}}
                                  >
                                    <Select
                                      className={classes.mypadding}
                                      id="demo-simple-select-outlined"
                                      disabled={
                                        item?.is_editable
                                          ? false
                                          : true || item?.is_composer_editable
                                      }
                                      name="year2"
                                      onChange={e =>
                                        handleChangeTableType(
                                          e,
                                          item.composer_id
                                        )
                                      }
                                      multiple
                                      renderValue={selected =>
                                        selected.join(", ")
                                      }
                                      autoWidth={true}
                                      value={item.choosenType}
                                      style={{
                                        paddingTop: "0px",
                                        paddingRight: "0px",
                                        paddingBottom: "0px",
                                        paddingLeft: "0px",
                                        width: "400px",
                                      }}
                                    >
                                      {writerRoleList.map((itemOne, idx) => (
                                        <MenuItem
                                          value={itemOne.code}
                                          key={idx}
                                        >
                                          <Checkbox
                                            checked={
                                              item.choosenType.indexOf(
                                                itemOne.code
                                              ) > -1
                                            }
                                            color="primary"
                                          />
                                          <ListItemText
                                            primary={itemOne.name}
                                          />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <FormControl
                                  variant="outlined"
                                  className={classes.formControl}
                                  fullWidth={false}
                                >
                                  <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    id={`${item.composer_id}`}
                                    disabled={
                                      item?.is_editable
                                        ? false
                                        : true || item?.is_composer_editable
                                    }
                                    name="composer"
                                    onChange={e =>
                                      handleChangePublisherShare(
                                        e,
                                        item.composer_id
                                      )
                                    }
                                    value={item.percentage_publisher}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <div>%</div>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <div
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                style={{
                                  color: "#687083",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                No Data
                              </Typography>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </Card>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container>
            <div
              style={{
                display: "flex",
              }}
            >
              <Button
                className={classes.btnSubmit}
                onClick={handleButtonSimpan}
                disabled={submitting}
                style={{
                  backgroundColor: submitting ? "grey" : getColor,
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    Save
                  </Typography>
                </ThemeProvider>
              </Button>
              <Button
                className={classes.btnPreview}
                variant="outlined"
                onClick={() => history.goBack(-1)}
                style={{
                  backgroundColor: getColor,
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    Back
                  </Typography>
                </ThemeProvider>
              </Button>
            </div>
          </Grid>
        </form>
      </Container>
      <Dialog
        open={openModalSelectPerformer}
        onClose={(event, reason) => onCloseModalSelectPerformer(event, reason)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {statusDialog === "add" ? <>{"Add"}</> : <>{"Edit"}</>} Performer
        </DialogTitle>
        <DialogContent dividers>
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography className={classes.label}>ISRC Code</Typography>
            </ThemeProvider>
          </div>
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {statusDialog === "add" ? (
              <>
                <TextField
                  id="add-isrc"
                  placeholder="ISRC"
                  variant="outlined"
                  fullWidth={true}
                  name="relatedIsrc"
                  onChange={event => handleChange(event)}
                  value={relatedIsrc}
                />
                {failedNotif ? (
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    {"ISRC Code is Exist."}
                  </span>
                ) : (
                  <span>{""}</span>
                )}
              </>
            ) : (
              <>
                <TextField
                  id="edit-isrc"
                  placeholder="ISRC"
                  variant="outlined"
                  fullWidth={true}
                  name="relatedIsrc"
                  onChange={event => handleChange(event)}
                  value={relatedIsrc}
                />
              </>
            )}
          </div>
          <div
            style={{
              marginTop: "33px",
              marginBottom: "10px",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography className={classes.label}>Performer Name</Typography>
            </ThemeProvider>
          </div>
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={performerList}
              disableCloseOnSelect
              getOptionLabel={option => option.first_name}
              onChange={(e, data) => onChangeDialogPerformer(e, data)}
              autoHighlight
              inputValue={paramsPerformer.search || ""}
              onInputChange={(e, value, reason) => {
                if (reason !== "reset") {
                  setParamsPerformer({
                    ...paramsPerformer,
                    search: e.target.value,
                  });
                }
              }}
              defaultValue={performerData}
              value={performerData}
              getOptionSelected={(option, value) =>
                option.first_name === value.performer_name
              }
              renderOption={(option, { selected }) => {
                return (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      value={option.id}
                    />
                    {option.first_name}
                  </React.Fragment>
                );
              }}
              style={{ width: 500 }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Performer Name"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoadingList?.performer ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={`${index}`}
                    variant="outlined"
                    label={option.performer_name}
                    {...getTagProps({ index })}
                  />
                ))
              }
              ListboxProps={{
                onScroll: e => handleScrollListBox(e, "performer"),
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleResetDialogPerformer()}
            className={classes.btnDialog}
            style={{
              backgroundColor: getColor,
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Cancel
              </Typography>
            </ThemeProvider>
          </Button>
          {statusDialog === "add" ? (
            <>
              <Button
                onClick={e => onClickDialogPerformer(e)}
                className={classes.btnDialog}
                style={{
                  backgroundColor: getColor,
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    Add
                  </Typography>
                </ThemeProvider>
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={e => onClickUpdateDialogPerformer(e)}
                className={classes.btnDialog}
                style={{
                  backgroundColor: getColor,
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    Update
                  </Typography>
                </ThemeProvider>
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      { }
      <Dialog
        open={openModalSelect}
        onClose={() => setOpenModalSelect(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Composer/Author</DialogTitle>
        <DialogContent dividers>
          { }
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={penciptaList}
            disableClearable={true}
            disableCloseOnSelect
            getOptionLabel={option => option.sure_name}
            onChange={(e, data) => onChangeDialog(e, data)}
            value={penciptaData}
            getOptionSelected={(option, value) =>
              option.sure_name === value.sure_name
            }
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  key={`${index}`}
                  label={option.sure_name}
                  {...getTagProps({ index })}
                  disabled={option.is_editable === false}
                />
              ))
            }
            autoHighlight
            inputValue={paramsPencipta.search || ""}
            onInputChange={(e, value, reason) => {
              if (reason !== "reset") {
                setParamsPencipta({
                  ...paramsPencipta,
                  search: e.target.value,
                });
              }
            }}
            renderOption={(option, { selected }) => {
              return (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    value={option.id}
                  />
                  {option.sure_name}
                </React.Fragment>
              );
            }}
            style={{ width: 500 }}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Composer/Author"
                placeholder="Composer/Author"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoadingList?.pencipta ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            ListboxProps={{
              onScroll: e => handleScrollListBox(e, "pencipta"),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleResetDialog()}
            className={classes.btnDialog}
            style={{
              backgroundColor: getColor,
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                RESET
              </Typography>
            </ThemeProvider>
          </Button>
          <Button
            onClick={e => onClickDialog(e)}
            className={classes.btnDialog}
            style={{
              backgroundColor: getColor,
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                SAVE
              </Typography>
            </ThemeProvider>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openModalSelectPerformerAlias}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {statusDialogAlias === "add" ? "Add" : "Edit"} Song Alias
        </DialogTitle>
        <DialogContent dividers>
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Typography className={classes.label}>Song Alias</Typography>
          </div>
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <TextField
              id="outlined-basic"
              placeholder="Song Alias"
              variant="outlined"
              fullWidth={true}
              name="aliasName"
              onChange={event => handleChange(event)}
              value={aliasName}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleResetDialogPerformerAlias()}
            className={classes.btnDialog}
            style={{
              backgroundColor: getColor,
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Cancel
              </Typography>
            </ThemeProvider>
          </Button>
          <Button
            onClick={e => {
              const isAdding = statusDialogAlias === "add";
              if (isAdding) {
                onClickDialogAlias(e);
              } else {
                onClickUpdateDialogAlias(e);
              }
            }}
            className={classes.btnDialog}
            style={{
              backgroundColor: getColor,
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                {statusDialogAlias === "add" ? "Add Song Alias" : "Update"}
              </Typography>
            </ThemeProvider>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent dividers>
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography className={classes.label}>
                Are You Sure want to Delete {delLabel} ?
              </Typography>
            </ThemeProvider>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDel(false)}
            className={classes.btnDialog}
            style={{
              backgroundColor: getColor,
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Cancel
              </Typography>
            </ThemeProvider>
          </Button>
          <Button
            onClick={() => goDelete()}
            className={classes.btnDialog}
            style={{
              backgroundColor: getColor,
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Yes
              </Typography>
            </ThemeProvider>
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
export default EditLagu;
