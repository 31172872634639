import { CurrencyDisplay, PrimaryButton } from "components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { chartStyles } from "../style";
import { Fragment } from "react";
import { Box } from "@mui/material";

const DashboardTable = ({ tableDashboard, handleRincian }) => {
  const classes = chartStyles();

  const columnTableDashboard = [
    {
      name: "",
      title: "",
    },
    {
      name: "",
      title: "",
    },
    {
      name: "all",
      title: "DSP",
    },
    {
      name: "all",
      title: "Product Type",
    },
    {
      name: "all",
      title: "Traffic",
    },
    {
      name: "all",
      title: "Original Currency",
    },
    {
      name: "all",
      title: "Base Currency",
    },
    {
      name: "all",
      title: "Advance DSP",
    },
  ];
  return (
    <Table size="small" className={classes?.tableBorder}>
      <TableHead>
        <TableRow>
          {columnTableDashboard?.map((item, index) => (
            <TableCell key={index} className={classes?.headText}>
              {item?.title}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableDashboard && tableDashboard.length > 0 ? (
          tableDashboard.map((customer, index) => (
            <Fragment key={index}>
              <TableRow>
                <TableCell
                  rowSpan={
                    customer?.summaries ? customer.summaries.length + 1 : 1
                  }
                >
                  <PrimaryButton
                    label="See Details"
                    onClick={e => handleRincian(e, customer)}
                    width={107}
                  />
                </TableCell>
                <TableCell
                  rowSpan={
                    customer?.summaries ? customer.summaries.length + 1 : 1
                  }
                >
                  <Box bgcolor={customer?.dsp?.color} height={20} width={20} />
                </TableCell>
                <TableCell
                  rowSpan={
                    customer?.summaries ? customer.summaries.length + 1 : 1
                  }
                >
                  <Typography
                    variant="subtitle2"
                    className={classes?.bodyTextBlack}
                  >
                    {customer?.dsp?.name}
                  </Typography>
                </TableCell>
                {!customer?.summaries && (
                  <>
                    <TableCell>{customer?.product_type || "-"}</TableCell>
                    <TableCell>
                      {<CurrencyDisplay value={customer?.listener} />}
                    </TableCell>
                    <TableCell>
                      {
                        <CurrencyDisplay
                          value={customer?.original_currency_revenue}
                          decimalScale={2}
                          prefix={`${customer?.currency_symbol_code} `}
                        />
                      }
                    </TableCell>
                    <TableCell>
                      {
                        <CurrencyDisplay
                          value={customer?.base_currency_revenue}
                          decimalScale={2}
                          prefix="Rp "
                        />
                      }
                    </TableCell>
                    <TableCell>
                      {
                        <CurrencyDisplay
                          value={customer?.advance}
                          decimalScale={2}
                          prefix="Rp"
                        />
                      }
                    </TableCell>
                  </>
                )}
              </TableRow>
              {customer?.summaries?.map((el, subIndex) => (
                <TableRow key={subIndex}>
                  <TableCell>{el?.product_type || "-"}</TableCell>
                  <TableCell>
                    {<CurrencyDisplay value={el?.listener} />}
                  </TableCell>
                  <TableCell>
                    {
                      <CurrencyDisplay
                        value={el?.original_currency_revenue}
                        decimalScale={2}
                        prefix={`${el?.currency_symbol_code} `}
                      />
                    }
                  </TableCell>
                  <TableCell>
                    {
                      <CurrencyDisplay
                        value={el?.base_currency_revenue}
                        decimalScale={2}
                        prefix="Rp "
                      />
                    }
                  </TableCell>
                  <TableCell>
                    {
                      <CurrencyDisplay
                        value={el?.advance}
                        decimalScale={2}
                        prefix="Rp "
                      />
                    }
                  </TableCell>
                </TableRow>
              ))}
            </Fragment>
          ))
        ) : (
          <TableRow key="0">
            <TableCell colSpan={12}>
              <div className={classes?.justifyCennterContainer}>
                <div className={classes?.marginVertical20}>Empty Data</div>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default DashboardTable;
