import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Cancel, CheckCircle, ExpandMore, Warning } from "@material-ui/icons";
import { InnoTableV2 } from "inno-ui";

const ReportModalWithTable = ({ modalVisible, onClose, detailReport }) => {
  const { status, message, status_details } = detailReport;
  const classes = useStyles({ status });
  
  return (
    <Dialog open={modalVisible} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container justifyContent="center">
          <Grid item>
            {status === "Success" ? (
              <CheckCircle className={classes?.statusIcon} />
            ) : status === "Partial Success" ? (
              <Warning className={classes?.statusIcon} />
            ) : (
              <Cancel className={classes?.statusIcon} />
            )}
          </Grid>
        </Grid>
        <Typography
          variant="h3"
          align="center"
          className={classes?.reportMessage}
        >
          {message}
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h5" align="center">
              See Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes?.acordionDetails}>
            <InnoTableV2
              columns={columnTable}
              items={status_details || []}
              isLoading={false}
            />
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button className={classes?.buttonBlack} onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  dialogActions: {
    padding: "8px 24px",
  },
  buttonBlack: {
    padding: "6px 16px",
    backgroundColor: "#111827",
    color: "white",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "#111827",
      color: "white",
    },
  },
  statusIcon: props => ({
    color:
      props?.status === "Success"
        ? "#27e65a"
        : props?.status === "Partial Success"
        ? "#f0ec05"
        : "red",
    fontSize: 140,
  }),
  acordionDetails: {
    display: "block",
  },
  reportMessage: {
    margin: "12px 0 20px",
  },
}));

const columnTable = [
  {
    name: "song_title",
    title: "Song Title",
  },
  {
    name: "status",
    title: "Status",
  },
  {
    name: "message",
    title: "Message",
  },
];

export default ReportModalWithTable;
