import { Container, Divider } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { Card, Grid, styled, Typography } from "@mui/material";
import axios from "axios";
import {
  AutoCompleteComponent,
  CurrencyDisplay,
  ModalError,
  Page,
  PrimaryButton,
  SearchTextInput,
  SelectInput,
  SkeletonComponent,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { emptyText, getErrors, textCapitalization } from "utils";
import DollarIcon from "../../../assets/image-public/images/icon_images/ri_money-dollar-circle-fill.svg";
import { hardBaseUrl } from "../../../services/urlConstant";

const AdvancedPageFinance = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const typeWeb = localStorage.getItem("typeWeb");
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");
  const paramsDSP = urlParams.get("dsp");
  const paramsStatus = urlParams.get("status");
  const isMpis = typeWeb === "mpis";

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: Number(paramsPage) || 1,
    size: Number(paramsSize) || 10,
    search: paramsSearch || "",
    dsp_id: Number(paramsDSP) || "",
    status: paramsStatus || "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [optionDSP, setOptionDSP] = useState([]);
  const [dataSummary, setDataSummary] = useState({});

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleChangeSearch = event => {
    const value = event?.target?.value;
    handleChangeQueryParams(value, "search");
    handleChangePageParams(value, "search");
  };
  const handleChangePage = page => {
    handleChangeQueryParams(page, "page");
    handleChangePageParams(page, "page");
  };
  const handleChangePageSize = size => {
    handleChangeQueryParams(size, "size");
    handleChangePageParams(size, "size");
  };
  const handleChangeDSP = dsp_id => {
    handleChangeQueryParams(dsp_id, "dsp_id");
    handleChangePageParams(dsp_id, "dsp");
  };
  const handleChangeStatus = status => {
    handleChangeQueryParams(status, "status");
    handleChangePageParams(status, "status");
  };

  const getOptionDSP = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/master/all-client`, {
        headers,
      });

      const { data } = res?.data;
      const modifiedData = [
        {
          id: "",
          label: "All Client",
        },
        ...data?.map(item => ({
          ...item,
          id: item?.dsp_id,
          label: item?.dsp_name,
        })),
      ];
      setOptionDSP(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/transaction/advanced`,
        {
          headers,
          params: queryParams,
        }
      );

      const { data, meta } = res?.data;
      const modifiedData = data?.map(({ period, ...item }) => {
        const [startMonth, endMonth] = period.split(" - ");
        const formattedPeriod = `${formatDatePeriod(
          startMonth
        )} - ${formatDatePeriod(endMonth)}`;
        return {
          period: formattedPeriod,
          ...item,
        };
      });
      setDataTable(modifiedData);
      const pageCount = getTotalPage(meta?.total, queryParams?.size || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getDataSummary = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/transaction/summary/advanced`,
        {
          headers,
          params: {
            dsp_id: queryParams?.dsp_id,
            search: queryParams?.search,
          },
        }
      );
      setDataSummary(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
      getDataSummary();
    }, 500),
    [queryParams]
  );

  useEffect(() => {
    getOptionDSP();
  }, []);
  useEffect(() => {
    debounceDataTable();

    if (queryParams?.page !== paramsPage) {
      handleChangePageParams(queryParams?.page, "page");
    }
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Advance Transaction">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Advance Transaction"
            breadcrumbData={breadcrumbData}
          />
          <Divider className={classes.divider} />
          <AutoCompleteComponent
            options={optionDSP}
            label={queryParams?.dsp_id === "" ? "All Client" : "Client"}
            value={
              optionDSP.find(
                option => option.id === Number(queryParams?.dsp_id)
              ) || null
            }
            onChange={handleChangeDSP}
            size="small"
            width={250}
          />
          <BalanceCard>
            <Grid container alignItems="center" columnSpacing={2} mb={1}>
              <Grid item>
                <img src={DollarIcon} alt="$" />
              </Grid>
              <Grid item>
                <Typography fontFamily="Inter" color="#9AA2B1" fontSize="14px">
                  Balance
                </Typography>
                <Typography
                  fontFamily="Inter"
                  color="white"
                  fontSize="24px"
                  fontWeight={600}
                >
                  <CurrencyDisplay
                    value={dataSummary.revenue}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix="Rp "
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography fontFamily="Inter" color="#9AA2B1" fontSize="14px">
                  {dataSummary?.dsp_name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography fontFamily="Inter" color="#9AA2B1" fontSize="14px">
                  {dataSummary?.period}
                </Typography>
              </Grid>
            </Grid>
          </BalanceCard>
          <Grid container justifyContent="space-between" my="16px">
            <Grid item>
              <SearchTextInput
                placeholder="Search"
                value={queryParams?.search}
                onChange={handleChangeSearch}
              />
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1} alignItems="center">
                <Grid item>
                  <SelectInput
                    label="Status"
                    options={optionStatus}
                    optionKey="key"
                    optionLabel="value"
                    value={queryParams?.status || ""}
                    onChange={e => handleChangeStatus(e?.target?.value)}
                    placeholder="All"
                    width={200}
                  />
                </Grid>
                <Grid item>
                  <PrimaryButton
                    label="Create Transaction"
                    startIcon={<Add />}
                    onClick={() => history.push("/admin/advance/add")}
                    size="large"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <InnoTableV2
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) => handleChangePage(page)}
            handleChangeRowsPerPage={e =>
              handleChangePageSize(e?.target?.value)
            }
            isHaveAction={true}
            handleView={item =>
              history.push({
                pathname: `/${isMpis ? "admin" : "finance"}/advance/${
                  item?.publisher_transaction_id
                }`,
                state: { customer: item },
              })
            }
            idColumnName={"performer_id"}
          />
        </Container>
      )}
    </Page>
  );
};

const formatDatePeriod = date => moment(date, "MMMM YYYY").format("MMM YYYY");
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: "16px 0",
    borderTop: "1px solid #e1e1e1",
  },
}));
const BalanceCard = styled(Card)(() => ({
  backgroundColor: "#111827",
  width: "fit-content",
  padding: "16px",
  borderRadius: "10px",
  minWidth: 400,
  marginTop: 16,
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Transaction",
    link: "/admin/advance",
  },
  {
    label: "Advance Transaction",
    active: true,
  },
];
const optionStatus = [
  { key: "paid", value: "Paid" },
  { key: "unpaid", value: "Unpaid" },
];
const columnTable = [
  {
    name: "dsp_name",
    title: "DSP",
  },
  {
    name: "period",
    title: "Period",
  },
  {
    name: "song_title",
    title: "Song",
    renderText: item => emptyText(item),
  },
  {
    name: "all",
    title: "Type",
    renderText: item => textCapitalization(item?.type),
  },
  {
    name: "all",
    title: "Exchange Rate",
    renderText: item => (
      <CurrencyDisplay
        value={item?.exchange_rate}
        decimalScale={2}
        prefix="Rp "
      />
    ),
  },
  {
    name: "all",
    title: "Base Currency Fee",
    renderText: item => (
      <CurrencyDisplay
        value={item?.base_currency_revenue}
        decimalScale={2}
        prefix="Rp "
      />
    ),
  },
  {
    name: "all",
    title: "Origin Currency Fee",
    renderText: item => (
      <CurrencyDisplay
        value={item?.original_currency_revenue}
        decimalScale={2}
        prefix={`${item?.currency_symbol_code} `}
      />
    ),
  },
  {
    name: "status",
    title: "Status",
    renderText: item => textCapitalization(item),
  },
];
export default AdvancedPageFinance;
