import {
  Backdrop,
  Card,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router";
import {
  FormLabel,
  ModalError,
  ModalSuccess,
  Page,
  TextInput,
  DateTimePicker,
  CurrencyNumberInput,
  PrimaryButton,
} from "components";
import { hardBaseUrl, hardTypeWeb } from "../../../../services/urlConstant";
import { getCookie } from "../../../../utils/constants";
import { HeaderTitle } from "layouts";
import { getErrors } from "utils";

const AddExRate = () => {
  const classes = useStyles();
  const history = useHistory();
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const isMpis = hardTypeWeb === "mpis" ? true : false;
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const eightYearsAgo = moment().subtract(8, "years");
  const oneHourLater = moment().add(1, "hour");

  const [loadingPage, setLoadingPage] = useState(false);
  const [payload, setPayload] = useState({
    currency_id: history.location.state.selectedCurrency.id,
    start_date: null,
    end_date: null,
    value: 0,
    ...(!isMpis && {
      publisher_id: userLogin?.publisher?.publisher_id,
    }),
  });

  const handleChangePayload = (value, key) =>
    setPayload(prev => ({
      ...prev,
      [key]: value,
    }));
  const handleStartDateChange = newStartDate => {
    let newEndDate = payload.end_date;
    if (newEndDate && moment(newEndDate).isBefore(moment(newStartDate))) {
      newEndDate = moment(newStartDate).add(1, "minute");
    }
    if (moment(newEndDate).isBefore(oneHourLater)) {
      newEndDate = oneHourLater;
    }

    setPayload(prevPayload => ({
      ...prevPayload,
      start_date: newStartDate,
      end_date: newEndDate,
    }));
  };

  const handleEndDateChange = newEndDate => {
    if (moment(newEndDate).isBefore(oneHourLater)) {
      newEndDate = oneHourLater;
    }
    if (
      payload.start_date &&
      moment(newEndDate).isSameOrBefore(moment(payload.start_date))
    ) {
      newEndDate = moment(payload.start_date).add(1, "minute");
    }

    setPayload(prevPayload => ({
      ...prevPayload,
      end_date: newEndDate,
    }));
  };

  const handleSubmit = () => {
    const validations = [
      { condition: !payload?.start_date, message: "Please Input Start Date" },
      { condition: !payload?.end_date, message: "Please Input End Date" },
      { condition: !payload?.value, message: "Please Input Value" },
    ];
    for (const { condition, message } of validations) {
      if (condition) {
        ModalError(message, "Oops...");
        return;
      }
    }

    setLoadingPage(true);
    axios
      .post(`${hardBaseUrl}/exchange-rate/create`, payload, {
        headers,
      })
      .then(() => {
        ModalSuccess("Successfully Add Exchange Rate").then(result => {
          if (result?.isConfirmed) {
            const isMpis = localStorage.getItem("typeWeb") === "mpis";
            history.push({
              pathname: `/admin${
                isMpis ? "/parameter" : "/konfigurasi"
              }/exchange-rate`,
              state: {
                selectedCurrency: history.location.state.selectedCurrency,
              },
            });
          }
        });
      })
      .catch(error => {
        ModalError(getErrors(error?.response));
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };

  return (
    <Page className={classes.root} title="Create Exchanges rate">
      <Backdrop className={classes.backdrop} open={loadingPage}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <HeaderTitle
          title="Create Exchange Rate"
          breadcrumbData={breadcrumbData}
        />
        <Divider className={classes.divider} />
        <Typography variant="subtitle2" className={classes?.pageDescription}>
          Feature for create exchanges rate
        </Typography>

        <Grid container>
          <Grid item xs={12} md={8}>
            <Card className={classes?.card}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormLabel label="Start Date" required />
                  <DateTimePicker
                    value={payload?.start_date}
                    onChange={handleStartDateChange}
                    minDate={eightYearsAgo}
                    defaultValue={moment()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormLabel label="End Date" required />
                  <DateTimePicker
                    value={payload?.end_date}
                    onChange={handleEndDateChange}
                    minDate={eightYearsAgo}
                    defaultValue={moment()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormLabel label="Currency" required />
                  <FormControl fullWidth variant="outlined">
                    <TextInput
                      value={
                        history.location.state.selectedCurrency
                          .currency_iso_code
                      }
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormLabel label="Value" required />
                  <CurrencyNumberInput
                    value={payload?.value}
                    onChange={e =>
                      handleChangePayload(Number(e.target.value), "value")
                    }
                  />
                </Grid>
              </Grid>
            </Card>
            <Grid
              container
              justifyContent="flex-end"
              spacing={1}
              className={classes?.buttonContainer}
            >
              <Grid item>
                <PrimaryButton
                  label="Cancel"
                  onClick={() => history.goBack()}
                />
              </Grid>
              <Grid item>
                <PrimaryButton label="Add" onClick={handleSubmit} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  backdrop: {
    zIndex: 100,
    color: "#fff",
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  card: {
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "16px",
  },
  buttonBlack: {
    backgroundColor: "#111827",
    color: "white",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "#111827",
      color: "white",
    },
    "&.MuiButton-root.Mui-disabled": {
      backgroundColor: "#9ca3af",
      color: "white",
    },
  },
  buttonWhite: {
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    textTransform: "none",
    whiteSpace: "nowrap",
  },
  buttonContainer: {
    marginTop: 16,
  },
  pageDescription: {
    margin: "16px 0",
  },
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Exchange Rate",
    link: "/admin/parameter/exchange-rate",
  },
  {
    label: "Create Exchange Rate",
    active: true,
  },
];
export default AddExRate;
