import { Button, Divider, Typography, Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BackupOutlined } from "@material-ui/icons";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { makeStyles } from "@material-ui/styles";
import { useRef } from "react";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    cursor: "pointer",
    width: "12px",
    height: "12px",
    marginTop: "8px",
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  dialogTitleText: {
    fontSize: "16px",
    fontWeight: 700,
  },
  typographyText: {
    fontSize: "14px",
    fontWeight: 500,
  },
  iconStyle: {
    color: "grey",
    fontSize: "40px",
  },
  uploadFileList: {
    listStyle: "none",
    marginTop: "20px",
  },
  btnSubmit: {
    border: "1px solid lightgrey",
  },
  btnBack: {
    backgroundColor: "#25383c",
    color: "#fff",
    marginLeft: "10px",
    marginRight: "20px",
  },
  actionButtonsContainer: {
    display: "flex",
    justifyContent: "end",
    marginBottom: "20px",
  },
}));


const DialogImportDokumen = ({
  open,
  onClose,
  onSubmit,
  getRootProps,
  getInputProps,
  isDragActive,
  files,
}) => {
  const classes = useStyles();
  const dialogRef = useRef();

  return (
    <Dialog
      size="small"
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      ref={dialogRef}
    >
      <DialogTitle id="responsive-dialog-title">
        <Box className={classes.dialogHeader}>
          <ThemeProvider theme={theme}>
            <Typography className={classes.dialogTitleText}>
              Import Document
            </Typography>
          </ThemeProvider>
          <CloseOutlinedIcon onClick={onClose} className={classes.closeButton} />
        </Box>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent>
        <DialogContentText>
          <ThemeProvider theme={theme}>
            <Typography className={classes.typographyText}>
              Upload Document
            </Typography>
          </ThemeProvider>
        </DialogContentText>
        <Box {...getRootProps}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <>
              <BackupOutlined className={classes.iconStyle} />
              <p>Drop the files here ...</p>
            </>
          ) : (
            <>
              <BackupOutlined className={classes.iconStyle} />
              <Typography component="p" variant="h6">
                <p>
                  <b>Search File</b>, Drag Document Here
                </p>
              </Typography>
            </>
          )}
        </Box>
        <Box className={classes.uploadFileList}>
          <aside>
            <ThemeProvider theme={theme}>
              <Typography className={classes.typographyText}>
                Uploaded File
              </Typography>
              <ul>{files}</ul>
            </ThemeProvider>
          </aside>
        </Box>
      </DialogContent>
      <Divider className={classes.divider} />
      <Box className={classes.actionButtonsContainer}>
        <Button className={classes.btnSubmit} variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button className={classes.btnBack} onClick={onSubmit}>
          Upload
        </Button>
      </Box>
    </Dialog>
  );
};

export default DialogImportDokumen;
