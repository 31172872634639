import React, { useState, useRef, useEffect } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router";
import { makeStyles } from "@material-ui/styles";
import {
  Container,
  Divider,
  Grid,
  Button,
  Typography,
  Breadcrumbs,
  CircularProgress,
  TextField,
  FormControl,
  Box,
  Select,
  MenuItem,
  Backdrop,
} from "@material-ui/core";
import Swal from "sweetalert2";
import { Page } from "components";
import { InsertDriveFile, Description, Delete } from "@material-ui/icons";
import { Editor } from "react-draft-wysiwyg";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import moment from "moment";
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { DateTimePicker } from "@material-ui/pickers";
import axios from "axios";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    padding: "10px 28px 10px 28px",
    backgroundColor: "black",
    color: "white",
    width: "213px",
    height: "40px",
    borderRadius: "6px",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  selectPadding: {
    height: "80px",
    "& .MuiInputBase-fullWidth": {
      height: "55px",
    },
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",
    marginRight: "8px",
    position: "relative",
  },
  backdrop1: {
    zIndex: 100,
    color: "#fff",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
  },
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#E4E7EB",
    borderRadius: "25px",
    border: "0px solid #606060",
    height: "auto",
    opacity: 1,
  },
  contentFiles: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
  },
}));

const optionsType = [
  {
    type: "web",
    title: "Website Announcement Content",
  },
  {
    type: "email",
    title: "Email Announcement",
  },
];

const optionsRecipient = [
  {
    id: "all",
    title: "All",
  },
  {
    id: "publisher",
    title: "Publisher",
  },
  {
    id: "composer",
    title: "Composer",
  },
  {
    id: "association",
    title: "Association",
  },
];

const CreateOrEditAnnouncementManagement = () => {
  const history = useHistory();
  const classes = useStyles();
  const { id, typeAnnouncement } = useParams();
  const refFile = useRef();
  const theToken = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: `Bearer ${theToken}`,
    },
  };
  const [textEditor, setTextEditor] = useState(() => EditorState.createEmpty());
  const [loading, setLoading] = useState(false);
  const [valueForm, setValueForm] = useState({
    email_delivery_time: null,
    email_recipient: "",
    subject: "",
    files: [],
    type: "email",
    imagePreview: "",
    web_start_date: null,
    web_end_date: null,
  });
  const {
    email_delivery_time,
    email_recipient,
    subject,
    files,
    type,
    web_start_date,
    web_end_date,
  } = valueForm;

  const isEmail = type === "email";

  const uploadCallback = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const dataURL = reader.result;
        const truncatedDataURL = dataURL.substring(10, 30) + "..."; // set the maximum length of the truncated string
        resolve({ data: { link: dataURL }, link: { url: truncatedDataURL } });
      };
      reader.onerror = error => {
        reject(error);
      };
    });

  const onChangeFile = e => {
    const data = e.target.files[0];
    if (data) {
      setValueForm(state => ({
        ...state,
        files: [...state.files, data],
      }));
    }
  };
  const configEditor = {
    options: ["inline", "list", "textAlign", "link", "image", "emoji"],
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ["bold", "italic", "underline"],
    },
    image: {
      uploadCallback: uploadCallback,
      previewImage: true,
    },
  };

  const saveData = async e => {
    e.preventDefault();
    setLoading(true);
    const content = stateToHTML(textEditor.getCurrentContent());
    const formData = new FormData();
    // Append common parameters
    formData.append("type", type);
    formData.append("subject", subject);
    formData.append("content", content);
    if (isEmail) {
      formData.append(
        "email_delivery_time",
        moment(email_delivery_time).format("YYYY-MM-DDTHH:mm:ssZ")
      );
      formData.append("email_recipient", email_recipient);
    } else {
      formData.append(
        "web_start_date",
        moment(web_start_date).format("YYYY-MM-DDTHH:mm:ssZ")
      );
      formData.append(
        "web_end_date",
        moment(web_end_date).format("YYYY-MM-DDTHH:mm:ssZ")
      );
    }
    files.forEach(file => formData.append("files", file));
    if (id) {
      const url = `${hardBaseUrl}/admin/annoucement/edit/${id}`;
      try {
        axios.put(url, formData, headers);
        Swal.fire({
          title: "Success",
          text: "Edit Annoucement Berhasil.",
          icon: "success",
          confirmButtonText: "Ok",
        });
        history.push("/admin/konfigurasi/announcement-management");
        setLoading(false);
      } catch (err) {
        Swal.fire({
          title: "Failed",
          text: err.message || "",
          icon: "error",
          confirmButtonText: "Oke",
        });
        setLoading(false);
      }
    } else {
      const url = `${hardBaseUrl}/admin/annoucement/create`;
      axios
        .post(url, formData, headers)
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "Add Annoucement Berhasil.",
            icon: "success",
            confirmButtonText: "Ok",
          });
          history.push("/admin/konfigurasi/announcement-management");
          setLoading(false);
        })
        .catch(err => {
          Swal.fire({
            title: "Failed",
            text: err.message || "",
            icon: "error",
            confirmButtonText: "Oke",
          });
          setLoading(false);
        });
    }
  };

  const getDetail = id => {
    const url = `${hardBaseUrl}/admin/annoucement/detail/${id}`;
    setLoading(true);
    axios
      .get(url, headers)
      .then(async response => {
        const { data } = response.data;
        const listFiles = data.announcement_files.map(
          async ({ file_path, file_name }) => {
            try {
              const fileResponse = await axios.get(file_path, {
                responseType: "arraybuffer",
              });
              const base64 = Buffer.from(fileResponse.data, "binary").toString(
                "base64"
              );
              const blob = new Blob([Buffer.from(base64, "base64")], {
                type: fileResponse.headers["content-type"],
              });
              setLoading(false);
              return new File([blob], file_name, {
                lastModified: new Date().getTime(),
                lastModifiedDate: new Date().toString(),
                type: fileResponse.headers["content-type"],
              });
            } catch (error) {
              new Error(error);
              setLoading(false);
              // Handle the error appropriately
              return null; // or any default value indicating an error
            }
          }
        );
        try {
          const values = await Promise.all(listFiles);
          setLoading(false);
          setValueForm(state => ({
            ...state,
            type: data.type,
            web_start_date: data.web_start_date,
            web_end_date: data.web_end_date,
            subject: data.subject,
            files: values, // Assign the resolved values directly
            email_recipient: data.email_recipient,
            email_delivery_time: data.email_delivery_time,
          }));

          const blocksFromHTML = convertFromHTML(data.content);
          const contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );
          setTextEditor(EditorState.createWithContent(contentState));
        } catch (error) {
          setLoading(false);
          new Error(error);
          // Handle the error appropriately
        }
      })
      .catch(err => {
        new Error(err);
        // Handle error appropriately
      });
  };

  const removeFiles = selectedIndex => {
    const filterFiles = files.filter((_, index) => index !== selectedIndex);
    setValueForm(state => ({
      ...state,
      files: filterFiles,
    }));
  };

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, [id]);

  useEffect(() => {
    if (typeAnnouncement) {
      setValueForm(state => ({
        ...state,
        type: typeAnnouncement,
      }));
    }
  }, [typeAnnouncement]);
  return (
    <Page
      className={classes.root}
      title={`${id ? "Edit" : "Add"} Announcement`}
    >
      <Backdrop className={classes.backdrop1} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                {id ? "Edit" : "Create"} Announcement
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs>
              <Typography className={classes.breadCrumbs}>
                Administration
              </Typography>

              <Typography className={classes.breadCrumbs}>
                Announcement Management
              </Typography>

              <Typography className={classes.breadCrumbsActive}>
                {id ? "Edit" : "Create"} Announcement
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={12} md={8} sm={12} lg={8} xl={8}>
            <Box sx={{ marginBottom: "24px" }}>
              <Typography
                style={{
                  fontSize: "14px",
                  marginTop: "16px",
                }}
              >
                Select Announcement Management Type
              </Typography>
              <FormControl
                variant="outlined"
                style={{ maxWidth: "329px" }}
                fullWidth
              >
                <Select
                  displayEmpty
                  value={type}
                  onChange={({ target }) =>
                    setValueForm(state => ({
                      ...state,
                      type: target.value,
                    }))
                  }
                  defaultValue={""}
                >
                  {optionsType.map(({ title, type }) => (
                    <MenuItem key={type} value={type}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Grid
              container
              spacing={1}
              style={{
                border: "1px solid #D1D5DC",
                padding: "16px",
                rowGap: "16px",
                borderRadius: "8px",
              }}
            >
              {isEmail ? (
                <>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography> Email Delivery Time</Typography>
                    <DateTimePicker
                      fullWidth
                      variant="outlined"
                      format="DD-MM-YYYY HH:mm"
                      minDate={!id && moment().toDate()}
                      multiline
                      value={email_delivery_time}
                      onChange={date =>
                        setValueForm(prev => ({
                          ...prev,
                          email_delivery_time: date,
                        }))
                      }
                      inputVariant="outlined"
                      placeholder=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography>Recipient</Typography>
                    <Select
                      displayEmpty
                      variant="outlined"
                      value={email_recipient}
                      style={{ width: "100%" }}
                      onChange={({ target }) =>
                        setValueForm(state => ({
                          ...state,
                          email_recipient: target.value,
                        }))
                      }
                      defaultValue=""
                    >
                      {optionsRecipient.map(({ title, id }) => (
                        <MenuItem key={id} value={id}>
                          {title}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography>Start Date</Typography>
                    <DateTimePicker
                      fullWidth
                      variant="outlined"
                      format="DD-MM-YYYY HH:mm"
                      minDate={!id && moment().toDate()}
                      multiline
                      value={web_start_date}
                      onChange={date =>
                        setValueForm(prev => ({
                          ...prev,
                          web_start_date: date,
                        }))
                      }
                      inputVariant="outlined"
                      placeholder=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography>End Date</Typography>
                    <DateTimePicker
                      fullWidth
                      variant="outlined"
                      format="DD-MM-YYYY HH:mm"
                      minDate={!id && moment().toDate()}
                      multiline
                      value={web_end_date}
                      onChange={date =>
                        setValueForm(prev => ({ ...prev, web_end_date: date }))
                      }
                      inputVariant="outlined"
                      placeholder=""
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} md={12} sm={12} lg={6} xl={6}>
                <Box display="flex" flexDirection="column">
                  <Typography>Subject</Typography>
                  <TextField
                    variant="outlined"
                    placeholder="Subject"
                    value={subject}
                    onChange={({ target }) =>
                      setValueForm(prev => ({ ...prev, subject: target.value }))
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <Box display="flex" flexDirection="column" gridColumnGap={16}>
                  <Typography>Upload File / Image</Typography>
                  <input
                    type="file"
                    ref={refFile}
                    accept="image/png, image/jpeg"
                    hidden
                    onChange={onChangeFile}
                  />
                  <Box
                    htmlFor="upload-file"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // maxHeight={111}
                    height="100%"
                    padding="24px 16px"
                    gridGap={10}
                    border="1px dashed #9AA2B1"
                    borderRadius={8}
                    flexDirection="column"
                    marginBottom="1rem"
                    style={{ cursor: "pointer" }}
                    onClick={() => refFile?.current?.click()}
                  >
                    <InsertDriveFile />
                    <Typography>Upload File Search File</Typography>
                  </Box>
                  {/* <Grid container direction="column"> */}
                  {files.map(({ ID, file_name, name, file_path, size }, id) => {
                    const formatedSize = `${(size / 1024).toFixed(2)} Kb`;
                    const lastIndex = id === files.length - 1;
                    return (
                      // <Grid item style={{ alignItems: "center", display: "flex" }} key={ID || id} xs={12} md={12} sm={12} lg={12} xl={12}>
                      <Grid
                        container
                        spacing={2}
                        key={ID || id}
                        style={!lastIndex ? { marginBottom: "0.5rem" } : {}}
                      >
                        <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                          <Box display="flex" alignItems="center">
                            <Description />
                            <Box
                              marginLeft="0.8rem"
                              style={{ wordBreak: "break-all" }}
                            >
                              <Typography>{file_name || name}</Typography>
                              <Typography>{formatedSize}</Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                          <Box>
                            {file_path && (
                              <Box onClick={() => window.open(file_path)}>
                                <Typography style={{ cursor: "pointer" }}>
                                  See
                                </Typography>
                              </Box>
                            )}
                            <Box onClick={() => removeFiles(id)}>
                              <Delete style={{ cursor: "pointer" }} />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      // </Grid>
                    );
                  })}
                  {/* </Grid> */}
                </Box>
              </Grid>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography>Email Content</Typography>
                  <Editor
                    toolbar={configEditor}
                    editorState={textEditor}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class input_description"
                    editorStyle={{
                      height: "60vH",
                      backgroundColor: "white",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                    toolbarClassName="toolbar-class"
                    wrapperStyle={{
                      border: "1px solid #ccc",
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                    onEditorStateChange={setTextEditor}
                  />
                </Box>
              </Grid>
            </Grid>

            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{
                  borderRadius: "6px",
                  width: "129px",
                  height: "40px",
                  border: "1px solid #D1D5DC",
                  textTransform: "none",
                }}
                href="/admin/konfigurasi/announcement-management"
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                style={{
                  textTransform: "none",
                  backgroundColor: "black",
                  color: "white",
                  width: "129px",
                  height: "40px",
                  marginLeft: "10px",
                }}
                onClick={e => saveData(e)}
              >
                <Typography
                  style={{
                    color: "white",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  Save
                </Typography>
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CreateOrEditAnnouncementManagement;
