import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Typography, Button, ButtonGroup, Box } from "@material-ui/core";
import { useHistory } from "react-router";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(() => ({
  root: {
    padding: "10px 0px",
    overflow: "hidden",
  },
  buttonSelect: {
    backgroundColor: "#E4E7EB",
    color: "black",
    "&:hover": {
      backgroundColor: "#E4E7EB",
      color: "black",
    },
  },
  scrollContainer: {
    display: "flex",
    overflowX: "auto",
    whiteSpace: "nowrap",
    "-webkit-overflow-scrolling": "touch",
    scrollbarWidth: "thin", // Menampilkan scrollbar di Firefox
    paddingBottom: "10px",
    "&::-webkit-scrollbar": {
      height: "8px", // Tinggi scrollbar di Safari dan Chrome
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888", // Warna scrollbar di Safari dan Chrome
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555", // Warna scrollbar saat hover di Safari dan Chrome
    },
  },
}));

const listMenu = [
  {
    id: 0,
    title: "Publisher",
    link: "/admin/parameter/publisher",
    selected: false,
  },
  {
    id: 1,
    title: "User",
    link: "/admin/parameter/user-admin",
    selected: false,
  },
  {
    id: 2,
    title: "User Publisher",
    link: "/admin/parameter/user-publisher",
    selected: false,
  },
  {
    id: 3,
    title: "User Composer",
    link: "/admin/parameter/user-pencipta",
    selected: false,
  },
  {
    id: 4,
    title: "Composer",
    link: "/admin/parameter/pencipta-admin",
    selected: false,
  },
  {
    id: 5,
    title: "Song",
    link: "/admin/parameter/lagu-admin",
    selected: false,
  },
  {
    id: 6,
    title: "DSP",
    link: "/admin/parameter/dsp-admin",
    selected: false,
  },
  {
    id: 7,
    title: "Association",
    link: "/admin/parameter/association",
    selected: false,
  },
  {
    id: 8,
    title: "Currency",
    link: "/admin/parameter/admin-currency",
    selected: true,
  },
  {
    id: 10,
    title: "Country",
    link: "/admin/parameter/admin-country",
    selected: false,
  },
  {
    id: 11,
    title: "Exchange Rate",
    link: "/admin/parameter/exchange-rate",
    selected: false,
  },
  {
    id: 12,
    title: "Revenue Type",
    link: "/admin/parameter/admin-revenue-type",
    selected: false,
  },
  {
    id: 13,
    title: "Territory",
    link: "/admin/parameter/territory",
    selected: false,
  },
  {
    id: 14,
    title: "Society",
    link: "/admin/parameter/society",
    selected: false,
  },
  {
    id: 15,
    title: "User Society",
    link: "/admin/parameter/user-society",
    selected: false,
  },
];

function GroupButtonTop({ items, onSelected, selectedPage }) {
  const history = useHistory();
  const classes = useStyles();
  const usedItems = items?.length ? items : listMenu;
  return (
    <Box
      alignItems="flex-end"
      justifyContent="space-between"
      spacing={3}
      className={clsx(classes.root)}
    >
      <Box className={classes.scrollContainer}>
        <ButtonGroup aria-label="outlined primary button group">
          {usedItems?.map(({ title, id, link }) => {
            const selected =
              link === history.location.pathname || selectedPage === id;
            return (
              <Button
                className={selected ? classes.buttonSelect : ""}
                style={{
                  textTransform: "none",
                  border: "1px solid #D1D5DC",
                  padding: "10px 16px",
                }}
                key={id}
                id={id}
                onClick={() => {
                  if (!link && onSelected) {
                    onSelected(id);
                  } else {
                    history.push(link);
                  }
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      lineHeight: "20px",
                      fontSize: "0.95em",
                      fontWeight: "500",
                    }}
                  >
                    {title}
                  </Typography>
                </ThemeProvider>
              </Button>
            );
          })}
        </ButtonGroup>
      </Box>
    </Box>
  );
}

GroupButtonTop.propTypes = {
  items: PropTypes.array,
};

export default GroupButtonTop;
