export const BASE_URL = process.env.REACT_APP_API_URL;
export const TOKEN = localStorage.getItem("token");
export const CONFIG = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + TOKEN,
  },
};

export const API_ENDPOINTS = {
  GET_VERSION: `${BASE_URL}/version`,
  GET_FAVICON: `${BASE_URL}/icon/mpis`,
  GET_ME: `${BASE_URL}/me`,
  GET_WRITER_ROLE: `${BASE_URL}/writer-roles`,

  GET_PUBLISHER: `${BASE_URL}/publisher`,
  GET_PUBLISHER_COMPOSER: `${BASE_URL}/publisher/composer`,
  GET_PUBLISHER_PERFORMER: `${BASE_URL}/publisher/performer`,
  GET_PUBLISHER_SONG_ORIGINAL_PUBLISHER: `${BASE_URL}/songs/originalpublisher`,

  // POST OR PUT
  PUBLISHER_SONG: `${BASE_URL}/publisher/song/create-or-update`,
};
