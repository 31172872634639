import { Container, Tooltip, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SettingsIcon from "@material-ui/icons/Settings";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import moment from "moment";
import { InnoTable } from "inno-ui";
import React, { useCallback, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory, useLocation } from "react-router";
import Swal from "sweetalert2";
import updateIcon from "../../../assets/img/pencilVector.svg";
import {
  ButtonWithModalPerItem,
  PrimaryIconButton,
  SecondaryButton,
} from "../../../components/atoms/Button";
import {
  AutoCompleteComponent,
  ModalError,
  MonthYearRangePicker,
  Page,
  PrimaryButton,
  SearchTextInput,
  SelectInput,
  SkeletonComponent,
} from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import { HeaderTitle } from "layouts";
import { Card, Divider, Grid, styled } from "@mui/material";
import DollarIcon from "../../../assets/image-public/images/icon_images/ri_money-dollar-circle-fill.svg";
import { formatPeriodDate, getErrors, textCapitalization } from "utils";
import { getTotalPage } from "lib";
import { debounce } from "lodash";

const ClaimAndUsage = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");
  const paramsStatus = urlParams.get("status");
  const paramsDSP = urlParams.get("dsp");
  const paramsStart = urlParams.get("startPeriod");
  const paramsEnd = urlParams.get("endPeriod");

  const [getColor, setGetColor] = useState("");
  const [optionDSP, setOptionDSP] = useState([]);
  const [transactionBalance, setTransactionBalance] = useState(0);
  const [loadingTable, setLoadingTable] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    dsp_id: Number(paramsDSP) || 0,
    page: Number(paramsPage) || 1,
    size: Number(paramsSize) || 10,
    search: paramsSearch || "",
    status: paramsStatus || "",
    start_date: paramsStart ? getFilterDate(paramsStart) : null,
    end_date: paramsEnd ? getFilterDate(paramsEnd) : null,
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };

  const handleChangePage = page => {
    handleChangeQueryParams(page, "page");
    handleChangePageParams(page, "page");
  };
  const handleResetPage = () => handleChangePage(1);
  const handleChangePageSize = size => {
    handleResetPage();
    handleChangeQueryParams(size, "size");
    handleChangePageParams(size, "size");
  };
  const handleChangeSearch = ({ target }) => {
    let value = target.value || "";
    handleResetPage();
    handleChangeQueryParams(value, "search");
    handleChangePageParams(value, "search");
  };
  const handleChangeStatusFilter = ({ target }) => {
    let value = target.value || "";
    handleChangeQueryParams(value, "status");
    handleChangePageParams(value, "status");
  };
  const handleChangeDspFilter = value => {
    handleChangeQueryParams(Number(value), "dsp_id");
    handleChangePageParams(Number(value) || "", "dsp");
  };
  const handleChangeStartDate = date => {
    handleChangeQueryParams(date, "startDate");
    handleChangePageParams(formatFilterDate(date), "startPeriod");
  };
  const handleChangeEndDate = date => {
    handleChangeQueryParams(date, "endDate");
    handleChangePageParams(formatFilterDate(date), "endPeriod");
  };

  const getMainDsp = async () => {
    try {
      setLoadingTable(true);
      const res = await axios.get(`${hardBaseUrl}/me`, { headers });
      const { data } = res?.data;
      setGetColor(data?.publisher?.theme_color);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingTable(false);
    }
  };
  const getClientList = async () => {
    await axios
      .get(`${hardBaseUrl}/master/all-client`, { headers })
      .then(res => {
        const { data } = res?.data;
        const modifiedData = [
          {
            id: 0,
            label: "All Client",
          },
          ...data?.map(item => ({
            ...item,
            id: item?.dsp_id,
            label: item?.dsp_name,
          })),
        ];
        setOptionDSP(modifiedData);
      })
      .catch(error => {
        ModalError(getErrors(error?.response));
      })
      .finally(() => setLoadingTable(false));
  };
  const getTransactionSummary = () => {
    const url = `${hardBaseUrl}/publisher/transaction/summary/claim`;
    axios
      .get(url, {
        headers,
        ...(queryParams?.dsp_id && {
          params: { dsp_id: queryParams?.dsp_id || "" },
        }),
      })
      .then(res => {
        setTransactionBalance(res.data.data.revenue);
      })
      .catch(() => {});
  };
  const getDataTable = () => {
    setLoadingTable(true);
    axios
      .get(`${hardBaseUrl}/publisher/transaction/claim`, {
        headers,
        params: queryParams,
      })
      .then(res => {
        const { data, meta } = res?.data;
        setDataTable(data);
        const pageCount = getTotalPage(meta?.total, queryParams?.size || 1);
        setTableTotalPage(pageCount);
      })
      .catch(error => {
        ModalError(getErrors(error?.response));
      })
      .finally(() => setLoadingTable(false));
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );

  useEffect(() => {
    getMainDsp();
    getClientList();
    getTransactionSummary();
  }, []);
  useEffect(() => {
    if (queryParams?.dsp_id) {
      getTransactionSummary();
    }
    debounceDataTable();
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  const handleDelete = item => {
    axios
      .delete(`${hardBaseUrl}/publisher/transaction/${item}`, { headers })
      .then(res => {
        if (res.data.message === "success") {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Data berhasil dihapus",
          }).then(result => {
            if (result.isConfirmed === true) {
              debounceDataTable();
            }
          });
        }
      })
      .catch(() => {});
  };

  const renderAction = customer => {
    const isPaid = customer?.status === "paid";
    const transactionId = customer.publisher_transaction_id;
    const previewLink = `/admin/invoice/${transactionId}`;
    const editLink = `/admin/invoice/edit/${transactionId}`;

    const renderIconButton = (tooltipTitle, link, icon) => (
      <Tooltip title={tooltipTitle} placement="bottom">
        <Grid item>
          <PrimaryIconButton
            icon={icon}
            color={getColor}
            onClick={() =>
              history.push(link, {
                customer: customer,
              })
            }
          />
        </Grid>
      </Tooltip>
    );

    return (
      <Grid container direction="row" columnSpacing={1} sx={{ width: 120 }}>
        {renderIconButton("View", previewLink, <VisibilityOutlinedIcon />)}
        {!isPaid &&
          renderIconButton(
            "Update",
            editLink,
            <img src={updateIcon} alt="update-icon" />
          )}
        {!isPaid && (
          <Grid item sx={{ ml: "-8px" }}>
            <Tooltip title="Delete" placement="bottom">
              <ButtonWithModalPerItem
                item={customer}
                dialogTitle="Delete Claim"
                subTitle={`Are you sure you want to delete this data: ${customer.dsp_name}`}
                handleDelete={() =>
                  handleDelete(customer.publisher_transaction_id)
                }
              />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Page className={classes.root} title="Invoice">
      {loadingTable ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Invoice" breadcrumbData={breadcrumbData} />
          <Divider
            sx={{
              my: 2,
              borderTop: "1px solid #e1e1e1",
            }}
          />

          <AutoCompleteComponent
            label="Client Name"
            options={optionDSP}
            value={
              optionDSP.find(option => option.id === queryParams?.dsp_id) ||
              null
            }
            onChange={value => handleChangeDspFilter(value)}
            size="small"
          />

          <BalanceCard>
            <Grid container alignItems="center" columnSpacing={2} mb={1}>
              <Grid item>
                <img src={DollarIcon} alt="$" />
              </Grid>
              <Grid item>
                <BalanceTitle>Balance</BalanceTitle>
                <BalanceValue>
                  <NumberFormat
                    displayType="text"
                    value={transactionBalance}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix="Rp "
                  />
                </BalanceValue>
              </Grid>
            </Grid>
            <BalanceTitle>
              {optionDSP.find(dsp => dsp.id === queryParams?.dsp_id)?.label}
            </BalanceTitle>
          </BalanceCard>
          <Grid container justifyContent="space-between" my={2}>
            <Grid item>
              <Grid container columnSpacing={1} alignItems="center">
                <Grid item>
                  <SearchTextInput
                    placeholder="Search"
                    value={queryParams?.search}
                    onChange={handleChangeSearch}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1} alignItems="center">
                <Grid item>
                  <MonthYearRangePicker
                    label="Period"
                    startDate={queryParams?.start_date}
                    handleChangeStartDate={handleChangeStartDate}
                    endDate={queryParams?.end_date}
                    handleChangeEndDate={handleChangeEndDate}
                  />
                </Grid>
                <Grid item>
                  <SelectInput
                    label="Status"
                    placeholder="None"
                    value={queryParams?.status}
                    onChange={handleChangeStatusFilter}
                    options={listStatus}
                    optionKey="value"
                    optionLabel="name"
                    width={200}
                  />
                </Grid>
                <Grid item>
                  <SecondaryButton
                    label="Invoice Setting"
                    onClick={() => history.push("/admin/konfigurasi/invoice")}
                    size="large"
                    startIcon={<SettingsIcon />}
                  />
                </Grid>
                <Grid item>
                  <PrimaryButton
                    label="Add Invoice"
                    onClick={() => history.push("/admin/invoice/add")}
                    size="large"
                    startIcon={<AddIcon />}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider
            sx={{
              my: 2,
              borderTop: "1px solid #e1e1e1",
            }}
          />
          {dataTable && (
            <InnoTable
              columns={columnTable}
              handleChangePage={(_, data) => handleChangePage(data)}
              handleChangeRowsPerPage={handleChangePageSize}
              renderAction={renderAction}
              isLoading={loadingTable}
              isHaveAction={true}
              items={dataTable}
              page={queryParams?.page}
              rowsPerPage={queryParams?.size}
              totalPage={tableTotalPage}
            />
          )}
        </Container>
      )}
    </Page>
  );
};

const DisplayNumberFormat = (value, prefix) => (
  <NumberFormat
    displayType="text"
    value={value}
    thousandSeparator={true}
    decimalScale={2}
    fixedDecimalScale={true}
    prefix={`${prefix || "Rp"} `}
  />
);
const formatFilterDate = date => date.format("YYYY-MM-DD");
const getFilterDate = date => moment(date, "YYYY-MM-DD");

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));
const BalanceCard = styled(Card)(() => ({
  backgroundColor: "#111827",
  width: "fit-content",
  padding: "16px",
  borderRadius: "10px",
  minWidth: 400,
  marginTop: 16,
}));
const BalanceTitle = styled(Typography)(() => ({
  fontFamily: "Inter",
  color: "#9AA2B1",
  fontSize: 14,
}));
const BalanceValue = styled(Typography)(() => ({
  fontFamily: "Inter",
  color: "white",
  fontSize: 24,
  fontWeight: 600,
  marginTop: 16,
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Transaction",
    link: "/admin/invoice",
  },
  {
    label: "Incvoice",
    active: true,
  },
];
const listStatus = [
  {
    name: "Waiting for Approval",
    value: "waiting_for_approval",
  },
  {
    name: "Paid",
    value: "paid",
  },
  {
    name: "Unpaid",
    value: "unpaid",
  },
  {
    name: "Rejected",
    value: "rejected",
  },
];
const columnTable = [
  {
    name: "invoice_date",
    title: "Invoice Date",
  },
  {
    name: "transaction_number",
    title: "Invoice Number",
  },
  {
    name: "dsp_name",
    title: "Client Name",
  },
  {
    name: "period",
    title: "Period",
    renderText: item => {
      const [start, end] = item.split(" - ");
      const startDate = moment(start, "DD MMMM YYYY");
      const endDate = moment(end, "DD MMMM YYYY");
      return formatPeriodDate(startDate, endDate);
    },
  },
  {
    name: "type",
    title: "Type",
    renderText: item => textCapitalization(item),
  },
  {
    name: "exchange_rate",
    title: "Exchange Rate",
    renderText: item => DisplayNumberFormat(item),
  },
  {
    name: "base_currency_revenue",
    title: "Base Currency Fee",
    renderText: item => DisplayNumberFormat(item),
  },
  {
    name: "all",
    title: "Origin Currency Fee",
    renderText: item =>
      DisplayNumberFormat(
        item.original_currency_revenue,
        item?.currency_symbol_code
      ),
  },
  {
    name: "all",
    title: "Status",
    renderText: item => {
      const status = item?.status;
      const statusUpdate = item?.status_update;
      if (statusUpdate === "rejected") {
        return "Rejected";
      } else if (status === "waiting_for_approval") {
        return "Waiting for approval";
      } else {
        return textCapitalization(status);
      }
    },
  },
];
export default ClaimAndUsage;
