import {
  Backdrop,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ArrowDropDown } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { DatePicker, Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { HeaderTitle } from "layouts";
import { Divider, styled, Typography } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    margin: 0,
    marginBottom: "6px",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },
  mydateinput: {
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px",
      paddingBottom: "0px",
      height: "44px",
    },
  },
  autoComplete: {
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      height: "44px",
    },
  },
  backdrop1: {
    zIndex: 100,
    color: "#fff",
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",
    marginRight: "8px",
    position: "relative",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
  },
  checked: {},
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#E4E7EB",
    borderRadius: "25px",
    border: "0px solid #606060",
    height: "auto",
    opacity: 1,
  },
  required: {
    color: "red",
  },
}));

const AddPayment = () => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const config = { headers };
  const classes = useStyles();
  const history = useHistory();
  const [submiting, setSubmiting] = useState(false);
  const [dataTax, setDataTax] = useState([]);
  const [dataComposer, setDataComposer] = useState({
    data: [],
    meta: {
      total: "",
    },
  });
  const [payload, setPayload] = useState({
    transaction_name: "payment",
    composer_id: null,
    invoice_date: moment(),
  });
  const [money, setMoney] = useState("");
  const [taxOnSelect] = useState([]);
  const [selectedTax, setSelectedTax] = useState([]);
  const [checkedVal, setCheckedVal] = useState(false);
  const listInvoiceType = ["Claim", "Usage"];
  const [invoiceType, setInvoiceType] = useState("Claim");
  const [openMenu, setOpenMenu] = useState(null);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [originalListInvoice, setOriginalListInvoice] = useState([]);
  const [listInvoice, setListInvoice] = useState([]);

  const getComposerList = async () => {
    const urlClientList = `${hardBaseUrl}/publisher/transaction-composer`;
    await axios
      .get(urlClientList, config)
      .then(res => {
        setDataComposer(res.data);
      })
      .catch(err => {
        new Error(err);
      });
  };
  const getTaxList = async () => {
    const urlClientList = `${hardBaseUrl}/publisher/transaction/payment/taxes`;
    await axios
      .get(urlClientList, config)
      .then(res => {
        setDataTax(res.data.data);
      })
      .catch(err => {
        new Error(err);
      });
  };
  const getInvoiceList = () => {
    const urlClientList = `${hardBaseUrl}/publisher/transaction/invoice/usage`;
    const params = {
      composer_id: payload?.composer_id,
    };
    const options = {
      headers,
      params,
    };
    axios.get(urlClientList, options).then(res => {
      const listTransaction = res?.data?.data;
      const transformedData = listTransaction.flatMap(item =>
        item.songs.map(song => ({
          transaction_id: item.transaction_id,
          transaction_number: item.transaction_number,
          client_name: item.client_name,
          ...song,
        }))
      );
      setListInvoice(transformedData);
      setOriginalListInvoice(transformedData);
    });
  };
  const handlSearchTransaction = e => {
    const searchTerm = e.target.value.toLowerCase();
    setListInvoice(prevList => {
      if (searchTerm.trim() === "") {
        return originalListInvoice;
      }
      return prevList.filter(item =>
        item?.transaction_number.toLowerCase().includes(searchTerm)
      );
    });
  };

  const handleAdd = () => {
    const showError = text => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text,
      });
    };

    if (!payload.composer_id) {
      showError("Please select composer");
    } else if (
      !payload.invoice_date ||
      payload.invoice_date === "Invalid date"
    ) {
      showError(
        payload.invoice_date === "Invalid date"
          ? "Format Date is not valid"
          : "Please input Date"
      );
    } else if (invoiceType === "Claim" && (!money || money === "0")) {
      showError(money === "0" ? "Money cannot be 0" : "Please input money");
    } else if (checkedVal && selectedTax.length === 0) {
      showError("Please select Tax");
    } else {
      const modifiedPayload = {
        transaction_name: modifiedPayload?.transaction_name,
        composer_id: modifiedPayload?.composer_id,
        invoice_date: modifiedPayload?.invoice_date.format("DD MMM YYYY"),
        type: invoiceType.toLowerCase(),
        ...(checkedVal &&
          selectedTax.length > 0 && {
            taxes: selectedTax,
          }),
        ...(invoiceType === "Usage"
          ? {
              usage_transaction_id: selectedInvoices?.map(
                item => item?.transaction_id
              ),
              usage_description: selectedInvoices?.reduce((acc, invoice) => {
                acc[invoice?.transaction_id] = invoice?.description;
                return acc;
              }, {}),
            }
          : { revenue: money ? Number(money.replace(/[^0-9.]+/g, "")) : 0 }),
      };

      setSubmiting(true);
      axios
        .post(hardBaseUrl + "/publisher/transaction", modifiedPayload, { headers })
        .then(res => {
          setSubmiting(false);
          Swal.fire({
            icon: res?.data?.message === "success" ? "success" : "error",
            title: res?.data?.message === "success" ? "Success" : "Error",
            text: res?.data?.message,
          }).then(() => {
            setSubmiting(false);
            history.push("/admin/payment");
          });
        })
        .catch(err => {
          setSubmiting(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.response.data.errors[0].message,
          });
        });
    }
  };

  const getDataSummary = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/balance/composer?composer_id=${payload?.composer_id ||
      ""}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch(err => {
        new Error(err);
      });
  };

  useEffect(() => {
    getDataSummary();
    getInvoiceList();
  }, [payload?.composer_id]);

  useEffect(() => {
    getComposerList();
    getTaxList();
  }, []);

  const handleClick = (_, id) => {
    const selectedIndex = selectedTax.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTax, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTax.slice(1));
    } else if (selectedIndex === selectedTax.length - 1) {
      newSelected = newSelected.concat(selectedTax.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTax.slice(0, selectedIndex),
        selectedTax.slice(selectedIndex + 1)
      );
    }
    setSelectedTax(newSelected);
  };

  const handleChecked = e => {
    setCheckedVal(e.target.checked);
  };

  const onChangeDate = date => {
    setPayload({
      ...payload,
      invoice_date: date,
    });
  };

  const handleChangeInvoiceType = e => {
    setInvoiceType(e.target.value);
  };
  const clickMenu = () => {
    setOpenMenu(!openMenu);
  };
  const handleClickAway = () => {
    setOpenMenu(false);
  };

  const handleChangeCheckBox = (event, index) => {
    const updatedInvoice = [...listInvoice];
    updatedInvoice[index].checked = event.target.checked;
    setListInvoice(updatedInvoice);

    const newlySelectedInvoices = updatedInvoice
      .filter(inv => inv.checked)
      .map(selectedInvoice => selectedInvoice);
    setSelectedInvoices(newlySelectedInvoices);
  };
  const formatInvoices = invoice => {
    const maxCharCount = 40;
    if (invoice?.length === 0) {
      return "Choose Transaction";
    } else {
      const roleString = invoice
        .map(selectedRole => selectedRole.transaction_number)
        .join(", ");
      if (roleString.length <= maxCharCount) {
        return roleString;
      } else {
        return roleString.substring(0, maxCharCount) + "...";
      }
    }
  };
  const renderMoney = item => {
    return (
      <NumberFormat
        displayType="text"
        value={item}
        thousandSeparator={true}
        decimalScale={2}
        fixedDecimalScale={true}
        prefix="Rp "
      />
    );
  };

  const renderPercentage = item => {
    return <>{item} %</>;
  };
  const columnTable = [
    {
      name: "transaction_number",
      title: "Invoice",
    },
    {
      name: "title",
      title: "Song",
    },
    {
      name: "publisher_percentage",
      title: "Publisher Percentage",
      renderText: "percentage",
    },
    {
      name: "fee",
      title: "Fee",
      renderText: "money",
    },
    {
      name: "value",
      title: "Value",
      renderText: "money",
    },
    {
      name: "description",
      title: "Description",
    },
  ];

  return (
    <Page className={classes.root} title="Payment">
      <Backdrop className={classes.backdrop1} open={submiting}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <HeaderTitle
          title="Payment Transaction"
          breadcrumbData={breadcrumbData}
        />
        <Divider
          sx={{
            my: 2,
            borderTop: "1px solid #e1e1e1",
          }}
        />
        <TableTitle>Create Transaction</TableTitle>
        <Typography variant="body2" mb={2}>
          Feature for creating invoices for song usage claims
        </Typography>

        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <label className={classes.label}>Transaction Type</label>
        </div>
        <div
          style={{
            marginTop: "-10px",
            marginBottom: "10.5px",
            maxWidth: 200,
          }}
        >
          <FormControl variant="outlined" fullWidth={true}>
            <Select
              className={classes.mypadding}
              id="demo-simple-select-outlined"
              value={invoiceType}
              onChange={handleChangeInvoiceType}
              autoWidth={true}
              style={{
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
              }}
            >
              {listInvoiceType?.map((item, idx) => (
                <MenuItem
                  value={item}
                  key={idx}
                  style={{ textTransform: "capitalize" }}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
                padding: "16px",
              }}
            >
              <Grid container>
                <Grid item md={6} style={{ paddingRight: "8px" }}>
                  <label className={classes.label}>
                    Date <span className={classes.required}>*</span>
                  </label>
                  <DatePicker
                    value={payload?.invoice_date}
                    onChange={onChangeDate}
                  />
                </Grid>
                <Grid item md={6} style={{ paddingLeft: "8px" }}>
                  <label className={classes.label}>
                    Composer/Author Name{" "}
                    <span className={classes.required}>*</span>
                  </label>
                  <Autocomplete
                    onChange={(_, b) => {
                      setPayload({
                        ...payload,
                        composer_id: b ? b.composer_id : null,
                      });
                    }}
                    options={dataComposer?.data}
                    getOptionLabel={option => option?.composer_name}
                    className={classes.autoComplete}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Search Composer/Author"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {invoiceType === "Usage" ? (
                <>
                  <Grid container style={{ marginTop: "16px" }}>
                    <Grid item md={6} style={{ paddingRight: "8px" }}>
                      <label className={classes.label}>
                        Client Transaction
                      </label>
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <div>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            size="small"
                            onClick={clickMenu}
                            style={{
                              width: "100%",
                              marginRight: "20px",
                              height: "44px",
                              border: "1px solid #D1D5DC",
                              borderRadius: "6px",
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                {formatInvoices(selectedInvoices)}
                              </InputAdornment>
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <ArrowDropDown
                                  onClick={clickMenu}
                                  style={{ cursor: "pointer" }}
                                />
                              </InputAdornment>
                            }
                            fullWidth={false}
                            readOnly={true}
                          />
                          {openMenu && (
                            <Paper
                              variant="outlined"
                              style={{
                                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                display: "flex",
                                flexDirection: "column",
                                background: "white",
                                position: "absolute",
                                "& > *": {
                                  margin: theme.spacing(1),
                                  width: theme.spacing(16),
                                  height: theme.spacing(16),
                                },
                                zIndex: "1000",
                              }}
                            >
                              <FormControl
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  margin: "10px 10px 0",
                                }}
                              >
                                <TextField
                                  autoFocus={true}
                                  className={classes.inputFields}
                                  variant="outlined"
                                  placeholder="Search"
                                  onChange={event =>
                                    handlSearchTransaction(event)
                                  }
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <img
                                          alt="Logo"
                                          src={
                                            require("assets/image-public/images/search.svg")
                                              .default
                                          }
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </FormControl>
                              <Container
                                style={{
                                  width: "300px",
                                  minHeight: "100px",
                                  maxHeight: "300px",
                                  marginTop: "10px",
                                  borderTop: "1px solid #E4E7EB",
                                  overflow: "scroll",
                                }}
                              >
                                {listInvoice?.length > 0 ? (
                                  <FormControl>
                                    {listInvoice?.map((item, index) => (
                                      <FormControlLabel
                                        key={index}
                                        control={
                                          <Checkbox
                                            className={classes.myCheckBox}
                                            key={index}
                                            checked={item?.checked}
                                            name={item?.transaction_number}
                                            value={item?.transaction_number}
                                            onChange={e =>
                                              handleChangeCheckBox(e, index)
                                            }
                                          />
                                        }
                                        label={item?.transaction_number}
                                      />
                                    ))}
                                  </FormControl>
                                ) : (
                                  <center>No Data</center>
                                )}
                              </Container>
                            </Paper>
                          )}
                        </div>
                      </ClickAwayListener>
                    </Grid>
                  </Grid>
                  {selectedInvoices?.length > 0 && (
                    <Card
                      style={{
                        border: "1px solid #9AA2B1",
                        borderRadius: "6px",
                        boxShadow: "none",
                        marginTop: "32px",
                      }}
                    >
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <ThemeProvider theme={theme}>
                              {columnTable?.map(column => {
                                return (
                                  <TableCell key={column?.name}>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.headText}
                                    >
                                      {column.title}
                                    </Typography>
                                  </TableCell>
                                );
                              })}
                            </ThemeProvider>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedInvoices?.length > 0 ? (
                            selectedInvoices?.map((item, index) => (
                              <TableRow key={index}>
                                {columnTable?.map(column => (
                                  <TableCell key={column?.name}>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.headText}
                                    >
                                      {column.renderText === "percentage"
                                        ? renderPercentage(item?.[column?.name])
                                        : column.renderText === "money"
                                        ? renderMoney(item?.[column?.name])
                                        : item?.[column?.name] || "-"}
                                    </Typography>
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={12}>
                                <center>
                                  <ThemeProvider theme={theme}>
                                    <Typography
                                      variant="h6"
                                      style={{
                                        fontWeight: 400,
                                        color: "#687083",
                                        fontSize: "14px",
                                      }}
                                    >
                                      No Data
                                    </Typography>
                                  </ThemeProvider>
                                </center>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Card>
                  )}
                </>
              ) : (
                <>
                  <Grid container style={{ marginTop: "16px" }}>
                    <Grid item md={6} style={{ paddingRight: "8px" }}>
                      <label className={classes.label}>
                        Value <span className={classes.required}>*</span>
                      </label>
                      <Grid style={{ display: "flex" }}>
                        <Grid
                          style={{
                            width: "48px",
                            height: "38px",
                            marginRight: "-2px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "6px 0px 0px 6px",
                            backgroundColor: "#F9FAFB",
                            border: " 1px solid #D1D5DC",
                            color: "#9AA2B",
                            borderRight: "none",
                          }}
                        >
                          Rp
                        </Grid>
                        <NumberFormat
                          customInput={TextField}
                          variant="outlined"
                          thousandSeparator={true}
                          onChange={e => setMoney(e.target.value)}
                          autoComplete="off"
                          className={classes.Input}
                          style={{
                            paddingLeft: "0px",
                            height: "44px",
                            borderRadius: "0px 6px 6px 0px",
                          }}
                          size="small"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: "16px" }}>
                    <Grid item md={12} style={{ paddingRight: "8px" }}>
                      <label className={classes.label}>Description</label>
                      <TextField
                        fullWidth
                        variant="outlined"
                        multiline
                        onChange={e =>
                          setPayload({ ...payload, note: e.target.value })
                        }
                        inputProps={{
                          style: {
                            height: "44px",
                            padding: 0,
                            paddingLeft: "14px",
                            paddingRight: "14px",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <div
              style={{
                marginLeft: "10px",
                marginTop: "12px",
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      color={"default"}
                      focusVisibleClassName={classes.focusVisible}
                      disableRipple
                      classes={{
                        root: classes.rootSwitch,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked,
                      }}
                      value={checkedVal}
                      onChange={e => handleChecked(e)}
                      name="use-taxes"
                    />
                  }
                  label="Use taxes in this Transaction"
                />
              </FormGroup>
            </div>
          </Grid>
        </Grid>
        {checkedVal && (
          <>
            <Grid container>
              <Grid item xs={12} md={8} lg={8} xl={8}>
                <Card
                  style={{
                    border: "1px solid #9AA2B1",
                    borderRadius: "6px",
                    boxShadow: "none",
                    marginTop: "32px",
                  }}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <ThemeProvider theme={theme}>
                          <TableCell
                            style={{
                              width: "10%",
                            }}
                          ></TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Code
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Description
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Rate
                            </Typography>
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataTax?.length > 0 ? (
                        dataTax.map((customer, idx) => (
                          <TableRow
                            hover
                            key={`${customer.id}`}
                            selected={taxOnSelect.indexOf(customer.id) !== -1}
                            onClick={event =>
                              handleClick(event, customer.id, customer)
                            }
                          >
                            <TableCell>
                              <div>
                                <>
                                  <Checkbox
                                    inputProps={{
                                      "aria-labelledby": `enhanced-table-checkbox-${idx}`,
                                    }}
                                    style={{
                                      color: "#111827",
                                    }}
                                  />
                                </>
                              </div>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {customer.code}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                align="left"
                                className={classes.bodyTextBlack}
                              >
                                {customer.description}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                align="left"
                                className={classes.bodyTextBlack}
                              >
                                {customer.rate} %
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <center>
                              <ThemeProvider theme={theme}>
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: 400,
                                    color: "#687083",
                                    fontSize: "14px",
                                  }}
                                >
                                  no data
                                </Typography>
                              </ThemeProvider>
                            </center>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
            </Grid>
          </>
        )}

        <Grid
          container
          style={{
            marginTop: "32px",
          }}
        >
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() => {
                  history.push("/admin/payment");
                }}
                style={{
                  borderRadius: "6px",
                  width: "84px",
                  height: "40px",
                  border: "1px solid #D1D5DC",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  marginLeft: "10px",
                  backgroundColor: submiting ? "gray" : "#111827",
                  borderRadius: "6px",
                  width: "84px",
                  height: "40px",
                  color: "#FFFFFF",
                  textTransform: "none",
                }}
                disabled={submiting}
                onClick={handleAdd}
              >
                Add
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

const TableTitle = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: 600,
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Transaction",
    link: "/admin/payment",
  },
  {
    label: "Create Transaction",
    active: true,
  },
];

export default AddPayment;
