import { makeStyles } from "@material-ui/styles";

export const chartStyles = makeStyles(() => ({
  root: {
    padding: "24px 0",
  },
  divider: {
    margin: "16px 0",
    borderTop: "1px solid #e1e1e1",
  },
  groupButton: {
    textTransform: "none",
    border: "1px solid #D1D5DC",
    color: "#111827",
    height: 42,
    "&:hover": {
      color: "#111827",
    },
  },
  groupButtonSelected: {
    backgroundColor: "#D1D5DC",
    color: "#111827",
    textTransform: "none",
    border: "1px solid #D1D5DC",
    height: 42,
    "&:hover": {
      backgroundColor: "#D1D5DC",
      color: "#111827",
    },
  },
  chartCard: {
    border: "1px solid #ebebeb",
    borderRadius: "5px",
    padding: "50px 20px",
  },
  marginVertical20: {
    margin: "20px 0",
  },
  buttonSeeMore: {
    textTransform: "none",
    color: "#111827",
    fontSize: 14,
  },
  highlightText: {
    fontWeight: "bold",
  },
  tableBorder: {
    border: "1px solid #ebebeb",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  justifyCennterContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));
