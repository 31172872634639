import {
  AppBar,
  Button,
  Card,
  CardContent,
  Dialog,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import SearchIcon from "@material-ui/icons/Search";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../../../services/urlConstant";
import DialogImportDokumen from "../DialogImportDokumen";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#303030",
  borderStyle: "thin",
  backgroundColor: "lightgray",
  color: "black",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  conTextBlack: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    marginBottom: "10px",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
    marginBottom: "5px",
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  bodyText: {
    whiteSpace: "nowrap",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
    fontStyle: "normal",
  },
  btnDetail: {
    backgroundColor: "#111827",
    color: "white",
    width: "30px",
    height: "30px",
    padding: "6px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  cardContent: {
    backgroundColor: "white",
    boxShadow: "none",
    borderRadius: "6px",
    border: "1px solid #E5E5E5",
  },
  insertDriveIcon: {
    color: "#9AA2B1",
    width: "13.33px",
    height: "15px",
    marginTop: "3px",
  },
  textInsertIcon: {
    fontSize: "14px",
    fontWeight: 400,
    marginLeft: "5px",
  },
  textCloseIcon: {
    color: "#364052",
    width: "12px",
    height: "12px",
    marginTop: "3px",
    cursor: "pointer",
  },
  buttonImport: {
    backgroundColor: " #111827",
    color: "white",
    textTransform: "none",
  },
  textSelect: {
    marginTop: "-5px",
    marginLeft: "10px",
  },
  inputSelect: {
    width: "200px",
    height: "44px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  search: {
    height: "44px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
  },
  cardTable: {
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
  },
  searchIcon: {
    color: "#9AA2B1"
  },
  boxNoData: {
    marginTop: "10px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
  },
  iconVisibility: {
    filter: " brightness(0) invert(1)",
  },
  iFrame: {
    width: "100%",
    height: "100%"
  },
  appBar: {
    position: "relative",
  },
}));

const DokumenPendukung = idKontrak => {
  const classes = useStyles();

  const [type, setType] = useState("-created_at");
  const [dokumenList, setDokumenList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dokumen, setDokumen] = useState([]);

  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [searchKey, setSearchKey] = useState("");

  const handleChangeSelectType = event => {
    setType(event.target.value);
  };
  const handleChangeSearchKey = e => {
    setSearchKey(e.target.value);
  };

  const getOpenDialogDetail = (url, name) => {
    setFileUrl(url);
    setFileName(name);
    setOpenDialogDetail(true);
  };
  const getCloseDialogDetail = () => {
    setOpenDialogDetail(false);
  };

  const onDrop = useCallback(acceptedFiles => {
    setDokumen(acceptedFiles);
  }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: ".pdf",
  });
  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };
  const handleRemoveFile = idx => {
    setDokumen(dokumen.filter((_, i) => i !== idx));
    acceptedFiles.splice(idx, 1);
  };
  const files = acceptedFiles.map((file, i) => {
    return (
      <li key={file.name}>
        <Card className={classes.cardContent}>
          <CardContent>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex">
                <InsertDriveFileOutlinedIcon className={classes.insertDriveIcon} />
                <Typography className={classes.textInsertIcon}>
                  {truncate(file.name, 20)}
                </Typography>
              </Box>
              <Box>
                <CloseOutlinedIcon
                  onClick={() => handleRemoveFile(i)}
                  className={classes.textCloseIcon} />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </li>
    );
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  const handleOpenImportDialog = () => setOpenDialog(true);
  const handleCloseImportDialog = () => setOpenDialog(false);

  const handleImportDokumen = e => {
    e.preventDefault();
    console.log({ e });
    const formData = new FormData();
    const url = `${hardBaseUrl}/contract-document/original-publisher`;

    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    formData.append("documents", dokumen[0]);
    formData.append("contract_id", idKontrak.idKontrak);

    axios
      .post(url, formData, config)
      .then(() => {
        setOpenDialog(false);
        Swal.fire({
          title: "Success",
          text: "Document has been uploaded",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(result => {
          if (result.value) {
            window.location.reload();
          }
        });
      })
      .catch(() => {
        Swal.fire({
          title: "Error",
          text: "Document failed to upload",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const getDokumentList = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/contract-document/original-publisher/${idKontrak.idKontrak}?&sort=${type}&search=${searchKey}`;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setDokumenList(res.data.data);
      })
      .catch(() => { });
  };

  useEffect(() => {
    getDokumentList();
  }, [searchKey, type]);
  return (
    <Box>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={4} md={3}>
          <FormControl fullWidth className={classes.margin} variant="outlined">
            <OutlinedInput
              placeholder="Search"
              id="outlined-adornment-amount"
              className={classes.search}
              value={searchKey}
              onChange={event => handleChangeSearchKey(event)}
              inputProps={{
                classes: {
                  input: classes.placeholder,
                },
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              }
              fullWidth={false}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  shrink={false}
                  className={classes.textSelect}
                ></InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={type}
                  onChange={handleChangeSelectType}
                  className={classes.inputSelect}
                >
                  <MenuItem value="-created_at">Latest</MenuItem>
                  <MenuItem value="created_at">Oldest</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                onClick={handleOpenImportDialog}
                className={classes.buttonImport}
                variant="contained"
                size="large"
                startIcon={<AddIcon />}
              >
                Import Document
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box marginTop="20px">
        <Grid md={12} lg={12} xl={12}>
          <Card className={classes.cardTable}>
            <Table size="small">
              <TableHead>
                <ThemeProvider theme={theme}>
                  <TableRow>
                    <TableCell width="100px">
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Action
                      </Typography>
                    </TableCell>
                    <TableCell aligm="left">
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Document Title
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Upload At
                      </Typography>
                    </TableCell>
                  </TableRow>
                </ThemeProvider>
              </TableHead>
              {dokumenList?.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box className={classes.boxNoData}>
                        <Typography>No Data</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {dokumenList.map(row => (
                    <TableRow key={row.composer_id}>
                      <TableCell>
                        <Box display="flex">
                          <Tooltip title={"Detail"} placement="bottom">
                            <IconButton
                              className={classes.btnDetail}
                              onClick={() => getOpenDialogDetail(row.url, row.filename)}
                            >
                              <VisibilityOutlinedIcon className={classes.iconVisibility} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.bodyText}
                        >
                          {row.filename}-
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          className={classes.bodyText}
                        >
                          <Box>{row.created_date}</Box>
                          <Box fontSize="12px" color="#9AA2B1">
                            {row.created_time}
                          </Box>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            <Dialog
              fullScreen
              open={openDialogDetail}
              onClose={getCloseDialogDetail}
            >
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={getCloseDialogDetail}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    {fileName}
                  </Typography>
                </Toolbar>
              </AppBar>
              <Box width="100%" height="100%">
                <iframe
                  src={fileUrl}
                  className={classes.iFrame}
                />
              </Box>
            </Dialog>
          </Card>
        </Grid>
      </Box>
      <DialogImportDokumen
        open={openDialog}
        onClose={handleCloseImportDialog}
        getInputProps={getInputProps}
        getRootProps={getRootProps({ style })}
        isDragActive={isDragActive}
        files={files}
        onSubmit={handleImportDokumen}
      />
    </Box>
  );
};

export default DokumenPendukung;
