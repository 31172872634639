import {
  Container,
  FormControl,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import Swal from "sweetalert2";
import HeaderPage from "../../../layouts/Header/HeaderPage";
import { Page } from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import Results from "./Results";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  content: {
    marginTop: "20px",
  },
  contentAdd: {
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    fontFamily: ["Helvetica Neue"].join(","),
  },

  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",

    "& .MuiOutlinedInput-root": {
      width: "312px",
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  selectPadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
      width: "160px",
    },
  },
  inputFilter: {
    width: "160px",
    height: "44px",
    borderRadius: "4px",
  },
  listMenu: {
    "& .MuiMenu-list": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  myCheckBox: {
    "&.MuiCheckbox-root": {
      color: "black",
      "&.Mui-checked": {
        color: "black",
      },
    },
  },
  ib: {
    paddingRight: "7px",
    paddingLeft: "6px",
    paddingTop: "1px",
    paddingBottom: "2px",
    backgroundColor: "#FCF3F2",
    border: "1px solid #F1A69E",
    "&:focus": {
      color: "blue",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "pink",
      borderColor: "#005cbf",
    },
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
}));

function List() {
  const classes = useStyles();
  const [response, setResponse] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [publisherId, setPublisherId] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);
  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const getlistKontrak = () => {
    setLoadingTable(true);
    let theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/composer/contract?page=${page}&size=${rowsPerPage}&search=${searchKey}&publisher_id=${publisherId}`;

    const headers = {
      headers: {
        Authorization: `Bearer ${theToken}`,
      },
    };
    axios
      .get(url, headers)
      .then(res => {
        setResponse(res.data.data);
        setTotalData(res.data.meta.total);
        setPage(res.data.meta.page);
        setLoadingTable(false);
      })
      .catch(() => {
        setLoadingTable(false);
      });
  };

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };

  const handleChangeSearch = event => {
    setPage(1);
    setSearchKey(event.target.value);
  };

  const onEdit = () => { };

  const handleDelete = id => {
    const url = `${hardBaseUrl}/publisher/${id}`;
    let theToken = localStorage.getItem("token");

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${theToken}`,
      },
    };

    axios
      .delete(url, headers)
      .then(res => {
        if (res.data.message === "success") {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Data berhasil dihapus",
          }).then(result => {
            if (result.isConfirmed === true) {
              getlistKontrak();
            }
          });
        }
      })
      .catch(() => { });
  };

  useEffect(() => {
    const getMe = async () => {
      try {
        let theToken = localStorage.getItem("token");
        let resultMe = await axios.get(hardBaseUrl + "/me", {
          headers: { Authorization: `Bearer ${theToken}` },
        });
        setPublisherId(resultMe.data.data.publisher.publisher_id);
      } catch (err) {
        new Error(err);
      }
    };
    getMe();
  }, []);
  useEffect(() => {
    getlistKontrak();
  }, [rowsPerPage, page, searchKey, publisherId]);

  return (
    <Fragment>
      <Page className={classes.root} title="Kontrak">
        <Container maxWidth={false}>
          <HeaderPage title="Contract" breadcrumbs={["Home", "Contract"]} />
          <div className={classes.content}>
            <hr />
            <div style={{ display: "flex", marginTop: "20px" }}>
              <FormControl className={classes.margin} variant="outlined">
                <TextField
                  id="input-with-icon-textfield"
                  variant="outlined"
                  style={{
                    marginLeft: "8px",
                  }}
                  className={classes.inputFields}
                  onChange={handleChangeSearch}
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          alt="Logo"
                          src={
                            require("assets/image-public/images/search.svg")
                              .default
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
            <div style={{ marginTop: "30px" }}>
              <Results
                newLoading={loadingTable}
                className={classes.results}
                items={response}
                pageSizePagination={pageSizePagination()}
                handleEdit={onEdit}
                totalData={pagesCount}
                page={page}
                handleChangePageSet={handleChangePage}
                handleDelete={handleDelete}
              />
            </div>
          </div>
        </Container>
      </Page>
    </Fragment>
  );
}

export default List;
