import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import Swal from "sweetalert2";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    padding: "10px 28px 10px 28px",
    backgroundColor: "black",
    color: "white",
    width: "213px",
    height: "40px",
    borderRadius: "6px",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  selectPadding: {
    height: "80px",
    "& .MuiInputBase-fullWidth": {
      height: "55px",
    },
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",

    marginRight: "8px",
    position: "relative",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
  },
  checked: {},
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#E4E7EB",
    borderRadius: "25px",
    border: "0px solid #606060",
    height: "auto",
    opacity: 1,
  },
}));

const CreateOrEditCountry = () => {
  const { state } = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const [valueForm, setValueForm] = useState({
    name: state?.country?.country_name || "",
    code: state?.country?.country_iso_code || "",
    currency: state?.country?.currency || null,
  });
  const [currencyList, setCurrencyList] = useState([]);
  const handleChange = e => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === "name") {
      setValueForm(prev => ({ ...prev, name: value }));
    } else if (name === "code") {
      setValueForm(prev => ({ ...prev, code: value }));
    }
  };
  const getListCurrency = async () => {
    try {
      const { data, status } = await axios(
        `${hardBaseUrl}/currency/datatable?page=1&per_page=10&search=`,
        {
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      );
      if (status === 200) {
        setCurrencyList(data.data);
      }
    } catch (error) {}
  };
  const saveData = e => {
    e.preventDefault();
    if (!valueForm.code) {
      Swal.fire({
        icon: "error",
        title: "Oops…",
        text: "Country Code can't be empty",
      });
      return;
    }
    if (!valueForm.name) {
      Swal.fire({
        icon: "error",
        title: "Oops…",
        text: "Country Name can't be empty",
      });
      return;
    }
    if (!valueForm.currency) {
      Swal.fire({
        icon: "error",
        title: "Oops…",
        text: "Currency can't be empty",
      });
      return;
    }
    if (valueForm.currency && valueForm.code && valueForm.name) {
      const data = {
        country_iso_code: valueForm.code,
        country_name: valueForm.name,
        currency_id: valueForm.currency.id,
      };

      const url = `${hardBaseUrl}/country/${id ? "/update/" + id : "create"}`;
      const token = localStorage.getItem("token");

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      axios({ url, method: id ? "PUT" : "POST", data, ...config })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `Country ${id ? "updated" : "created"} successfully`,
          }).then(result => {
            if (result.isConfirmed) {
              history.push("/admin/parameter/admin-country");
            }
          });
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            title: "Oops…",
            text: err?.response?.data?.errors?.[0]?.message,
          });
        });
    }
  };
  useEffect(() => {
    getListCurrency();
  }, []);
  return (
    <Page className={classes.root} title={`${id ? "Edit" : "Add"} Country`}>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                {id ? "Edit" : "Add"} Country
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Parameter</Typography>
              <Typography className={classes.breadCrumbs}>Country</Typography>
              <Typography className={classes.breadCrumbsActive}>
                {id ? "Edit" : "Add"} Country
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={4} md={4} sm={4} lg={6} xl={6}>
            <ThemeProvider theme={theme}>
              <Typography variant="h3" className={classes.subtitle}>
                Country
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Customize and manage Country for transaction.
              </p>
            </ThemeProvider>

            <Grid
              container
              spacing={1}
              style={{
                border: "1px solid #D1D5DC",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <Grid item xs={6} md={12} sm={12} lg={12} xl={12}>
                <div>
                  <ThemeProvider theme={theme}>
                    <label>Country Code</label>
                  </ThemeProvider>

                  <TextField
                    variant="outlined"
                    onChange={handleChange}
                    name="code"
                    id="formatted-numberformat-input"
                    margin="dense"
                    fullWidth={true}
                    value={valueForm.code}
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={12} sm={12} lg={12} xl={12}>
                <div>
                  <ThemeProvider theme={theme}>
                    <label>Country Name</label>
                  </ThemeProvider>

                  <TextField
                    variant="outlined"
                    onChange={handleChange}
                    name="name"
                    size="medium"
                    id="formatted-numberformat-input"
                    margin="dense"
                    fullWidth={true}
                    value={valueForm.name}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <div>
                  <ThemeProvider theme={theme}>
                    <label>Currency</label>
                  </ThemeProvider>

                  <Autocomplete
                    value={valueForm.currency}
                    onChange={(event, newValue) => {
                      setValueForm(prev => ({ ...prev, currency: newValue }));
                    }}
                    fullWidth={true}
                    id="controllable-states-demo"
                    options={currencyList}
                    name="currency"
                    getOptionLabel={option => option?.currency_name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Currency"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </Grid>
            </Grid>

            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{
                  borderRadius: "6px",
                  width: "129px",
                  height: "40px",
                  border: "1px solid #D1D5DC",
                  textTransform: "none",
                }}
                href="/admin/parameter/admin-country"
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                style={{
                  textTransform: "none",
                  backgroundColor: "black",
                  color: "white",
                  width: "129px",
                  height: "40px",
                  marginLeft: "10px",
                }}
                onClick={e => saveData(e)}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      color: "white",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    Save
                  </Typography>
                </ThemeProvider>
              </Button>
            </div>
          </Grid>

          <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CreateOrEditCountry;
