import {
  Button,
  ButtonGroup,
  Container,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { Page, PrimaryButton } from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import moment from "moment";
import { getCookie } from "../../../utils/constants";
import MainContractDetail from "./Components/MainContractDetail";
import SongContractDetail from "./Components/SongContractDetail";
import DeactivateContractDialog from "./Components/DeactivateContractDialog";
import SupportingDocuments from "./Components/SupportingDocuments";
import { HeaderTitle } from "layouts";
import { useHistory } from "react-router";
import { Grid, Tooltip } from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  button: {
    textTransform: "none",
    border: "1px solid #D1D5DC",
    padding: "10px 16px",
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  breadCrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  buttonSelect: {
    backgroundColor: "#E4E7EB",
    color: "black",
    "&:hover": {
      backgroundColor: "#E4E7EB",
      color: "black",
    },
  },
}));

const ContractDetail = () => {
  const { state } = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const [valueTab, setValueTab] = useState(1);
  const [dataComposer, setDataComposer] = useState([]);
  const [dataComposerSong] = useState([]);
  const [idPerformer, setIdPerformer] = useState(null);
  const [performerName, setPerformerName] = useState("");
  const [performerNameSong] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment().toString());
  const [radioDateValue, setRadioDateValue] = useState(null);
  const [contractNumber, setContractNumber] = useState("");
  const [contractNumberSong, setContractNumberSong] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState("");
  const [startDateSong, setStartDateSong] = useState("");
  const [endDateSong, setEndDateSong] = useState("");
  const [status, setStatus] = useState("");
  const [statusSong, setStatusSong] = useState("");
  const [publisherName, setPublisherName] = useState("");
  const [publisherNameSong, setPublisherNameSong] = useState("");
  const [idPublisher, setIdPublisher] = useState("");
  const [idPublisherSong, setIdPublisherSong] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionSong, setDescriptionSong] = useState("");
  const [songTitle] = useState("");
  const [songTitleSong] = useState("");
  const [ownershipPercentage] = useState("");
  const [ownershipPercentageSong] = useState("");
  const [iswcCode] = useState("");
  const [iswcCodeSong] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [approvalStatusSong, setApprovalStatusSong] = useState("");
  const [approvalStatusUpdate, setApprovalStatusUpdate] = useState("");
  const [approvalStatusUpdateSong, setApprovalStatusUpdateSong] = useState("");
  const [agentList, setAgentList] = useState([]);
  const [agentListSong, setAgentListSong] = useState([]);
  const [advanceList] = useState([]);
  const [advanceListSong] = useState([]);
  const [costTypeList, setCostTypeList] = useState([]);
  const [costTypeListSong] = useState([]);
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const publisherId = userLogin?.publisher?.publisher_id;
  const [contractType] = useState(
    state.customer.category === "song" ? "Song Contract" : "Main Contract"
  );
  const [penciptaList] = useState([]);
  const [penciptaListSong, setPenciptaListSong] = useState([]);
  const [advanceValueList, setAdvanceValueList] = useState([]);
  const [advanceValueListSong, setAdvanceValueListSong] = useState([]);
  const [advanceFilterValueListSong, setAdvanceFilterValueListSong] = useState(
    []
  );
  const [ownershipShareList] = useState([]);
  const [ownershipShareListSong, setOwnershipShareListSong] = useState([]);
  const [ownershipShareDropdownList] = useState([]);
  const [
    ownershipShareDropdownListSong,
    setOwnershipDropdownListSong,
  ] = useState([]);
  const [publisherShareDropdownList] = useState([]);
  const [publisherShareDropdownListSong] = useState([]);
  const [agentFilterValueList, setAgentFilterValueList] = useState([]);
  const [publisherShareList] = useState([]);
  const [publisherShareListSong, setPublisherShareListSong] = useState([]);
  const [composerName, setComposerName] = useState("");
  const [publisherSharePercentage, setPublisherSharePercentage] = useState("");
  const [
    publisherShareFilterListSong,
    setPublisherShareFilterListSong,
  ] = useState([]);
  const [perpuityContractSong, setPerpuityContractSong] = useState(false);
  const [perpuityContract, setPerpuityContract] = useState(false);
  const getDetailSongContract = () => {
    const url = `${hardBaseUrl}/publisher/contract/${state.id}`;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then(res => {
        const newDataPenciptaList = res.data.data.songs.map(data => ({
          ...data,
          title_song: data.title,
        }));
        setPenciptaListSong(newDataPenciptaList);
        setPublisherShareListSong(
          res.data.data.songs.map(el => {
            const mappingPublisherShare = el.composers?.map(data => ({
              song_title: el.title,
              song_id: el.song_id,
              ...data,
            }));
            return mappingPublisherShare;
          })
        );
        setAdvanceValueListSong(
          res.data.data.songs.map(el => {
            const mappingSongBalance = el.composers?.map(data => ({
              song_title: el.title,
              song_id: el.song_id,
              ...data,
            }));
            return mappingSongBalance;
          })
        );
        setAdvanceFilterValueListSong(res.data.data.composers);
        setPublisherShareFilterListSong(res.data.data.composers);
        setAgentFilterValueList(res.data.data.composers);
        setOwnershipDropdownListSong(res.data.data.songs);
        setOwnershipShareListSong(res.data.data.songs);
        setAgentListSong(res.data.data.agents);
        setContractNumberSong(res.data.data.contract.contract_number);
        setStartDateSong(res.data.data.contract.start_date);
        setEndDateSong(res.data.data.contract.end_date);
        setPublisherNameSong(res.data.data.contract.publisher);
        setIdPublisherSong(res.data.data.contract.id_publisher);
        setDescriptionSong(res.data.data.contract.description);
        setApprovalStatusSong(res.data.data.contract.approval_status);
        setApprovalStatusUpdateSong(
          res.data.data.contract.approval_status_update
        );
        setStatusSong(res.data.data.contract.is_active_flag);
        setPerpuityContractSong(res.data.data.contract.is_endless_period);
      })
      .catch(() => { });
  };
  useEffect(() => {
    getDetailSongContract();
  }, []);
  const handleCloseNonAKtifDialog = () => setOpenDialog(false);
  const handleChangeDateNonAktif = event => {
    if (event.target.value === "3day") {
      setRadioDateValue("3day");
      setCurrentDate(new Date().getTime() + 3 * 24 * 60 * 60 * 1000);
    } else if (event.target.value === "1week") {
      setRadioDateValue("1week");
      setCurrentDate(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
    } else if (event.target.value === "2week") {
      setRadioDateValue("2week");
      setCurrentDate(new Date().getTime() + 14 * 24 * 60 * 60 * 1000);
    }
  };
  const handleChangeDate = date => {
    setCurrentDate(date);
  };
  const onSubmitNonAktif = () => {
    const date = new Date(currentDate);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const dateString = `${year}-${month}-${day}`;
    const payload = {
      is_active_flag: false,
      inactive_at: dateString,
    };
    const url = `${hardBaseUrl}/publisher/contract/status/${state.customer.contract_id}`;
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .put(url, payload, { headers })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "Status Kontrak Berhasil Diubah",
          icon: "success",
        });
        handleCloseNonAKtifDialog();
      })
      .catch(() => {
        Swal.fire({
          title: "Error",
          text: "Terjadi Kesalahan",
          icon: "error",
        });
      });
  };
  const handleClickTab1 = () => {
    setValueTab(1);
  };
  const handleClickTab2 = () => {
    setValueTab(2);
  };
  const getSongResponse = () => {
    const url = `${hardBaseUrl}/publisher/song`;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then(res => {
        const dataComposer = res.data.data.filter(
          composer => composer.song_id === state.customer.song.song_id
        );
        setIdPerformer(dataComposer[0].performer_id);
        setDataComposer(dataComposer[0].song_composer);
      })
      .catch(() => { });
  };
  const getPerformerList = () => {
    const url = `${hardBaseUrl}/publisher/performer`;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then(res => {
        const dataPerformer = res.data.data.filter(
          performer => performer.performer_id === idPerformer
        );
        setPerformerName(dataPerformer[0].first_name);
      })
      .catch(() => { });
  };
  useEffect(() => {
    getSongResponse();
    getPerformerList();
  }, [dataComposer, idPerformer]);
  const getCostTypeList = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/cost-type/publisher/${publisherId}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then(res => {
        const newData = res.data.data.map(item => ({
          cost_type_id: item.cost_type_id,
          fee_type_code: item.fee_type_code,
          fee_type_name: item.fee_type_name,
          description: item.description,
          cost_bearer: "Publisher",
        }));
        setCostTypeList(newData);
      })
      .catch(() => { });
  };
  useEffect(() => {
    getCostTypeList();
  }, [publisherId]);
  const getDetailMainContract = () => {
    const token = localStorage.getItem("token");
    const url = hardBaseUrl + `/publisher/contract/composer-main/${state.id}`;
    const options = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    axios
      .get(url, options)
      .then(res => {
        setContractNumber(res.data.data.contract.contract_number);
        setApprovalStatus(res.data.data.contract.approval_status);
        setApprovalStatusUpdate(res.data.data.contract.approval_status_update);
        setStatus(res.data.data.contract.is_active_flag);
        const getDateFormat1 = date => {
          let dateFormat = new Date(date);
          let day = dateFormat.getDate();
          let month = dateFormat.getMonth();
          let year = dateFormat.getFullYear();
          let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Agu",
            "Sept",
            "Okt",
            "Nov",
            "Des",
          ];
          return day + " " + months[month] + " " + year;
        };
        setStartDate(getDateFormat1(res.data.data.contract.start_date));
        setEndDate(getDateFormat1(res.data.data.contract.end_date));
        setPublisherName(res.data.data.contract.publisher);
        setIdPublisher(res.data.data.contract.id_publisher);
        setDescription(res.data.data.contract.description);
        setComposerName(res.data.data.composer.sure_name);
        setPublisherSharePercentage(
          res.data.data.contract.publisher_share_percentage
        );
        setPerpuityContract(res.data.data.contract.is_endless_period);
        const newAgentList = {
          agent_id: res.data.data.contract.contract_composer_agent.agent_id,
          agent_name: res.data.data.contract.contract_composer_agent.agent_name,
          fee_type: res.data.data.contract.contract_composer_agent.fee_type,
          composer_name: res.data.data.composer.sure_name,
          agent_fee_formatted:
            res.data.data.contract.contract_composer_agent.agent_fee_formatted,
        };
        setAgentList([newAgentList]);
        const getDateFormat2 = date => {
          let dateFormat = new Date(date);
          let day = dateFormat.getDate();
          let month = dateFormat.getMonth();
          let year = dateFormat.getFullYear();
          let months = [
            "January",
            "February",
            "March",
            "April",
            "Mei",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          return day + " " + months[month] + " " + year;
        };
        const newAdvanceValueList = {
          advance_value:
            res.data.data.contract.contract_composer_balance.balance,
          start_period: getDateFormat2(
            res.data.data.contract.contract_composer_balance.start_period
          ),
          end_period: getDateFormat2(
            res.data.data.contract.contract_composer_balance.end_period
          ),
        };
        setAdvanceValueList([newAdvanceValueList]);
      })
      .catch(() => { });
  };
  useEffect(() => {
    getDetailMainContract();
  }, []);
  const handleContractWithoutPayload = e => {
    e.preventDefault();
    const url = `${hardBaseUrl}/publisher/contract/set-inactive/${state.id}`;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .put(url, {}, config)
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "Status has been updated",
          icon: "success",
        }).then(result => {
          if (result.isConfirmed) {
            window.location.href = "/admin/kontrak-composer";
          }
        });
      })
      .catch(() => {
        Swal.fire({
          title: "Error",
          text: "Error ",
          icon: "error",
        });
      });
  };
  return (
    <Page className={classes.root} title="Contract Detail">
      <Container maxWidth={false}>
        <HeaderTitle title="Contract Detail" breadcrumbData={breadcrumbData} backButton />
        <Grid container justifyContent="space-between" mt="20px">
          <Grid item>
            <ButtonGroup aria-label="outlined primary button group">
              <Button
                className={`${valueTab === 1 ? classes.buttonSelect : ""} ${classes.button}`}
                onClick={handleClickTab1}
              >
                Detail
              </Button>
              <Button
                className={`${valueTab === 1 ? classes.buttonSelect : ""} ${classes.button}`}
                onClick={handleClickTab2}
              >
                Supporting Document
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item>
            <Grid container columnSpacing="16px">
              <Grid item>
                {state?.customer?.is_active_flag && (
                  <PrimaryButton
                    label="Deactivate"
                    onClick={e => handleContractWithoutPayload(e)}
                    width={160}
                  />
                )}
              </Grid>
              <Tooltip
                title={
                  !state?.customer?.is_editable && "Contract is not editable"
                }
              >
                <Grid item>
                  <PrimaryButton
                    label="Edit Contract"
                    onClick={() =>
                      history.push({
                        pathname: `/admin/kontrak-composer/edit/${state?.customer?.contract_id}`,
                        state: {
                          id: state?.customer.contract_id,
                          customer: state?.customer,
                        },
                      })
                    }
                    disabled={!state?.customer?.is_editable}
                    width={160}
                  />
                </Grid>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        {valueTab === 1 ? (
          contractType === "Song Contract" ? (
            <SongContractDetail
              noKontrak={contractNumberSong}
              tglMulai={startDateSong}
              publisher={publisherNameSong}
              status={statusSong}
              tglSelesai={endDateSong}
              idPublisher={idPublisherSong}
              keterangan={descriptionSong}
              judul={songTitleSong}
              kepemilikan={ownershipPercentageSong}
              performer={performerNameSong}
              dataPencipta={dataComposerSong}
              approvalStatus={approvalStatusSong}
              approvalStatusUpdate={approvalStatusUpdateSong}
              agentList={agentListSong}
              advanceList={advanceListSong}
              advanceFilterValueList={advanceFilterValueListSong}
              costTypeList={costTypeListSong}
              kodeIsrc={iswcCodeSong}
              penciptaList={penciptaListSong}
              advanceValue={advanceValueListSong}
              ownershipShareList={ownershipShareListSong}
              ownershipShareDropdownList={ownershipShareDropdownListSong}
              publisherShareDropdownList={publisherShareDropdownListSong}
              publisherShareList={publisherShareListSong}
              agentFilterValueList={agentFilterValueList}
              publisherShareFilterList={publisherShareFilterListSong}
              perpuityContract={perpuityContractSong}
              userLogin={userLogin}
            />
          ) : (
            <MainContractDetail
              noKontrak={contractNumber}
              tglMulai={startDate}
              publisher={publisherName}
              publisherSharePercentage={publisherSharePercentage}
              status={status}
              tglSelesai={endDate}
              idPublisher={idPublisher}
              keterangan={description}
              judul={songTitle}
              kepemilikan={ownershipPercentage}
              performer={performerName}
              dataPencipta={dataComposer}
              approvalStatus={approvalStatus}
              approvalStatusUpdate={approvalStatusUpdate}
              agentList={agentList}
              advanceList={advanceList}
              costTypeList={costTypeList}
              kodeIsrc={iswcCode}
              penciptaList={penciptaList}
              advanceValue={advanceValueList}
              ownershipShareList={ownershipShareList}
              ownershipShareDropdownList={ownershipShareDropdownList}
              publisherShareDropdownList={publisherShareDropdownList}
              publisherShareList={publisherShareList}
              composerName={composerName}
              perpuityContract={perpuityContract}
              userLogin={userLogin}
            />
          )
        ) : (
          <SupportingDocuments
            idKontrak={state.customer.contract_id}
            contractType={state.customer.category}
          />
        )}
      </Container>
      <DeactivateContractDialog
        open={openDialog}
        onClose={handleCloseNonAKtifDialog}
        dateValue={currentDate}
        handleChange={handleChangeDateNonAktif}
        radioValue={radioDateValue}
        onSubmit={onSubmitNonAktif}
        handleChangeDate={handleChangeDate}
        userLogin={userLogin}
      />
    </Page>
  );
};
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Contract",
    link: "/admin/kontrak-composer",
  },
  {
    label: "Contract Detail",
    active: true,
  },
];

export default ContractDetail;
