import { Card, CardContent, Typography } from "@material-ui/core";
import {
  BackupOutlined,
  CloseOutlined,
  InsertDriveFileOutlined,
} from "@material-ui/icons";
import React, { useCallback, useMemo } from "react";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#303030",
  borderStyle: "solid",
  backgroundColor: "white",
  color: "black",
  outline: "none",
  transition: "border .24s ease-in-out",
};
const activeStyle = {
  borderColor: "#2196f3",
};
const acceptStyle = {
  borderColor: "#00e676",
};
const rejectStyle = {
  borderColor: "#ff1744",
};

function SupportingDocumentForm({
  supportingDocumentFiles,
  setSupportingDocumentFiles,
  classes,
}) {
  const handleDrop = useCallback(acceptedFiles => {
    if (acceptedFiles[0].size > 2000000) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "File size cannot be more than 2mb",
      });
    } else {
      setSupportingDocumentFiles(acceptedFiles);
    }
  }, []);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: ".pdf",
    onDrop: handleDrop,
  });
  const truncate = (str, n) =>
    str?.length > n ? str.substr(0, n - 1) + "..." : str;
  const handleRemoveFile = idx => {
    setSupportingDocumentFiles(
      supportingDocumentFiles?.filter((_, index) => index !== idx)
    );
    acceptedFiles.splice(idx, 1);
  };
  const files =
    acceptedFiles[0]?.size < 2000000 &&
    acceptedFiles?.map((file, i) => (
      <Card key={file.name} className={classes?.singleFileContainer}>
        <CardContent>
          <div className={classes?.containerSpaceBetween}>
            <div className={classes?.containerFlexRow}>
              <InsertDriveFileOutlined className={classes?.singleFileIcon} />
              <Typography className={classes?.singleFileCaptions}>
                {truncate(file.name, 20)}
              </Typography>
            </div>
            <div>
              <CloseOutlined
                className={classes?.singleFileRemoveIcon}
                onClick={() => handleRemoveFile(i)}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    ));
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  return (
    <Card className={classes?.card}>
      <CardContent>
        <Typography variant="subtitle2" className={classes?.label}>
          Upload Document
        </Typography>
        <div className={classes?.sectionContainer}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <BackupOutlined className={classes?.uploadIcon} />
            <Typography variant="h6">
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>
                  <b>Search File</b>, Drag document here
                </p>
              )}
            </Typography>
          </div>
          {files && (
            <div className={classes?.sectionContainer}>
              <aside>
                <Typography component="h1" variant="h5">
                  Files :
                </Typography>
                <ul>
                  <Typography
                    component="h1"
                    variant="h5"
                    className={classes?.sectionContainer}
                  >
                    {files}
                  </Typography>
                </ul>
              </aside>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

export default SupportingDocumentForm;
