import { Container, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  CurrencyDisplay,
  ModalError,
  Page,
  PrimaryButton,
  SongUsageTrendChart,
  SongUsageTrendReportCard,
} from "components";
import { Box, Grid, Typography } from "@mui/material";
import { InnoTableV2 } from "inno-ui";
import moment from "moment-timezone";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  cardSongDetail: {
    marginTop: "20px",
    marginBottom: "60px",
    width: "100%",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
  },
  label: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#364052",
  },
  labelValue: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#111827",
  },
  labelValueComposers: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  reportCard: {
    borderLeft: "1px solid gray",
    padding: "20px",
    maxWidth: 300,
  },
  reportText: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#687083",
    marginBottom: "15px",
  },
  reportValue: {
    fontSize: "24px",
    fontWeight: 500,
    color: "#111827",
  },
}));

const PrintReportDetailReview = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const {
    song,
    queryParams,
    songDetailList,
    chartData,
    chartReport,
    dataTable,
  } = state;

  const inputRef = useRef(null);
  const [loadingButton, setLoadingButton] = useState(false);

  const handlePrintPDF = () => {
    html2canvas(inputRef.current)
      .then(canvas => {
        setLoadingButton(true);
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "px", "a4");
        pdf.height = "600";
        pdf.width = "300";
        pdf.addImage(imgData, "PNG", 30, 30, 400, 450);
        pdf.save("download.pdf");
      })
      .catch(error => ModalError(error))
      .finally(() => setLoadingButton(false));
  };
  return (
    <div>
      <Page className={classes.root} title="Review of Song Usage Detail">
        <div ref={inputRef}>
          <Container maxWidth={false}>
            <Typography fontSize="24px">
              Song Title:{" "}
              <Typography component="span" fontSize="24px" fontWeight={700}>
                {song?.title}
              </Typography>
            </Typography>
            <Typography fontWeight={400} fontSize="18px">
              {`Year of Song Usage: ${queryParams?.year}`}
            </Typography>
            <Divider className={classes.divider} />
            <Box border="1px solid #D1D5DC" borderRadius="6px" p="24px">
              <div>
                {songDetailList?.map((item, index) => (
                  <Grid container key={index}>
                    <Grid item xs={2}>
                      <Typography>{item?.title}</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Box display="flex">
                        {item?.title === "Composer"
                          ? (item?.name || [])?.map((composer, index) => (
                              <Typography
                                key={index}
                                fontWeight={
                                  composer?.is_on_this_publisher && 700
                                }
                              >
                                {`${index > 0 ? "," : ""} ${composer?.name}`}
                              </Typography>
                            ))
                          : item?.name || "-"}
                      </Box>
                    </Grid>
                  </Grid>
                ))}
              </div>
            </Box>
            <Typography fontSize="24px" fontWeight={500} my="24px">
              Trend of Song Usage
            </Typography>

            <Box py="20px" border="1px solid #D1D5DC" borderRadius="6px">
              <SongUsageTrendChart chartData={chartData} preview />
            </Box>
            <SongUsageTrendReportCard chartReport={chartReport} />
            <InnoTableV2
              columns={columnTable}
              items={dataTable}
              isLoading={false}
            />
          </Container>
          <Box justifyContent="flex-end" display="flex" m={3}>
            <PrimaryButton
              label={loadingButton ? "Please Wait ..." : "Print Report"}
              loading={loadingButton}
              disabled={loadingButton}
              onClick={handlePrintPDF}
            />
          </Box>
        </div>
      </Page>
    </div>
  );
};

const typeWeb = localStorage.getItem("typeWeb");
const isCaris = typeWeb === "caris";
const columnTable = [
  {
    name: "date",
    title: "Month",
    renderText: value => moment(value).format("MMMM"),
  },
  {
    name: "date",
    title: "Year",
    renderText: value => moment(value).format("YYYY"),
  },
  {
    name: "summaries",
    title: "Traffic",
    renderText: summaries => (
      <div className="double-column">
        {summaries
          ? summaries.map((item, index) => (
              <>
                {index == 1 && <div className="tab-dotted" />}
                <CurrencyDisplay value={item?.listener} />
              </>
            ))
          : "0"}
      </div>
    ),
  },
  {
    name: "summaries",
    title: "Revenue",
    renderText: summaries => (
      <div className="double-column">
        {summaries ? (
          summaries.map((item, index) => (
            <>
              {index == 1 && <div className="tab-dotted" />}
              <CurrencyDisplay
                value={item.base_currency_revenue}
                decimalScale={2}
                prefix="Rp "
              />
            </>
          ))
        ) : (
          <CurrencyDisplay value={0} prefix="Rp " />
        )}
      </div>
    ),
  },
  ...(isCaris
    ? [
        {
          name: "summaries",
          title: "Composer Revenue",
          renderText: summaries => (
            <div className="double-column">
              {summaries ? (
                summaries.map((item, index) => (
                  <>
                    {index == 1 && <div className="tab-dotted" />}
                    <CurrencyDisplay
                      value={item.base_currency_composer_revenue}
                      decimalScale={2}
                      prefix="Rp "
                    />
                  </>
                ))
              ) : (
                <CurrencyDisplay value={0} prefix="Rp " />
              )}
            </div>
          ),
        },
        {
          name: "summaries",
          title: "Publisher Revenue",
          renderText: summaries => (
            <div className="double-column">
              {summaries ? (
                summaries.map((item, index) => (
                  <>
                    {index == 1 && <div className="tab-dotted" />}
                    <CurrencyDisplay
                      value={item.base_currency_publisher_revenue || 0}
                      decimalScale={2}
                      prefix="Rp "
                    />
                  </>
                ))
              ) : (
                <CurrencyDisplay value={0} prefix="Rp " />
              )}
            </div>
          ),
        },
      ]
    : []),
  {
    name: "summaries",
    title: "Product Type",
    renderText: summaries => (
      <div className="double-column">
        {summaries
          ? summaries.map((item, index) => (
              <>
                {index == 1 && <div className="tab-dotted" />}
                <span>{item?.product_type || "-"}</span>
              </>
            ))
          : "-"}
      </div>
    ),
  },
];
export default PrintReportDetailReview;
