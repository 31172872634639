import {
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Popper,
  Switch,
  TextField,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import {
  ArrowDropDown,
  ArrowDropUp,
  CalendarToday,
  Cancel,
} from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";

const useStyles = makeStyles(theme => ({
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
    margin: "10px 0 5px 0",
  },
  rootSwitch: {
    marginLeft: "10px",
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",
    marginRight: "8px",
    position: "relative",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
  },
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#E4E7EB",
    borderRadius: "25px",
    border: "0px solid #606060",
    height: "auto",
    opacity: 1,
  },
  required: { color: "red" },
  formContainer: {
    margin: "0 0 10px",
    maxHeight: 42,
  },
  filterFormContainer: {
    maxHeight: 42,
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  emptyData: {
    fontWeight: 400,
    color: "#687083",
    fontSize: "14px",
  },
  selectContainer: {
    maxWidth: 300,
    marginTop: 5,
  },
  currecnyPrefix: {
    width: "48px",
    height: "37.63px",
    marginRight: "-2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "6px 0px 0px 6px",
    backgroundColor: "#F9FAFB",
    border: " 1px solid #D1D5DC",
    color: "#9AA2B",
    borderRight: "none",
  },
  containerFlexRow: {
    display: "flex",
    alignItems: "start",
  },
  bankAccountImage: {
    width: "48px",
    height: "21px",
    objectFit: "contain",
  },
  flexContainer: {
    display: "flex",
  },
  flexSpaceBetweenContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textAreaContainer: {
    borderRadius: "6px",
    borderColor: "#D1D5DC",
    width: "100px",
    padding: 5,
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  datePickImage: {
    width: 20,
    height: 20,
    cursor: "pointer",
  },
  paperContainer: {
    padding: "10px 20px",
  },
  monthPeriodForm: {
    width: 130,
  },
  yearPeriodForm: {
    width: 100,
  },
  filterMenuContainer: {
    width: 250,
    padding: 0,
    maxHeight: "520px",
    overflowY: "auto",
    zIndex: theme.zIndex.modal,
  },
  filterMenuListItem: {
    padding: "0 20px",
  },
  filterCheckbox: {
    "&.MuiCheckbox-root": {
      color: "black",
      "&.Mui-checked": {
        color: "black",
      },
    },
  },
  emptyPlaceholder: {
    margin: "16px",
    textAlign: "center",
    fontStyle: "italic",
  },
  searchFilterContainer: {
    margin: "16px 0",
  },
  cursorPointer: { cursor: "pointer" },
  buttonBlack: {
    backgroundColor: "#111827",
    color: "white",
    textTransform: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "#111827",
      color: "white",
    },
  },
  buttonWhite: {
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    textTransform: "none",
    padding: "6px 8px",
  },
  buttonDisabled: {
    backgroundColor: "lightgray",
    color: "white",
    textTransform: "none",
    "&.MuiButton-root.Mui-disabled": {
      color: "gray",
    },
  },
  loadingButton: {
    color: "white",
    margin: "0 10px",
  },
  selctionsLayoutPaper: {
    // maxHeight: 350,
  },
  customCountBadge: {
    backgroundColor: "#FCF3F2",
    border: "1px solid #F1A69E",
    fontSize: 12,
    color: "#A63124",
    width: 24,
    height: 24,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  filterSelectedChipCard: {
    border: "1px solid #E4E7EB",
    boxShadow: "none",
    // height: "120px",
    width: "100%",
    padding: 12,
  },
  hoverPointer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  grayChip: {
    backgroundColor: "#D1D5DC",
    color: "#364052",
    fontSize: "14px",
    fontWeight: "500",
  },
  grayChipIndicator: {
    color: "#364052",
    fontSize: "12px",
  },
  greenChip: {
    backgroundColor: "#EFFCF4",
    border: "solid 0.5px #8DE5AB",
    color: "#34774C",
    fontSize: "14px",
    fontWeight: "500",
  },
  greenChipIndicator: {
    color: "#54B371",
    fontSize: "12px",
  },
  redChip: {
    backgroundColor: "#FCF3F2",
    border: "solid 0.5px #F1A69E",
    color: "#A63124",
    fontSize: "14px",
    fontWeight: "500",
  },
  redChipIndicator: {
    color: "#DE5242",
    fontSize: "12px",
  },
  yellowChip: {
    backgroundColor: "#FEF8E7",
    border: "solid 0.5px #F6C962",
    color: "#A84D20",
    fontSize: "14px",
    fontWeight: "500",
  },
  yellowChipIndicator: {
    color: "#EA9437",
    fontSize: "12px",
  },
}));
export const monthData = [
  { id: 1, shortName: "Jan", fullName: "January" },
  { id: 2, shortName: "Feb", fullName: "February" },
  { id: 3, shortName: "Mar", fullName: "March" },
  { id: 4, shortName: "Apr", fullName: "April" },
  { id: 5, shortName: "May", fullName: "May" },
  { id: 6, shortName: "Jun", fullName: "June" },
  { id: 7, shortName: "Jul", fullName: "July" },
  { id: 8, shortName: "Aug", fullName: "August" },
  { id: 9, shortName: "Sep", fullName: "September" },
  { id: 10, shortName: "Oct", fullName: "October" },
  { id: 11, shortName: "Nov", fullName: "November" },
  { id: 12, shortName: "Dec", fullName: "December" },
];
const thisYear = new Date().getFullYear();
export const yearList = Array.from(
  { length: thisYear - 2000 + 1 },
  (_, index) => thisYear - index
);
export const PredefinedButton = ({
  label,
  onClick,
  disabled,
  isWhite,
  endIcon,
  loading,
}) => {
  const classes = useStyles();
  return (
    <Button
      className={
        disabled
          ? classes?.buttonDisabled
          : isWhite
          ? classes?.buttonWhite
          : classes?.buttonBlack
      }
      disabled={disabled || loading}
      onClick={onClick}
      endIcon={endIcon ? endIcon : null}
      startIcon={
        loading ? (
          <CircularProgress className={classes?.loadingButton} size={16} />
        ) : null
      }
    >
      {label}
    </Button>
  );
};
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props?.name,
            value: values?.value,
          },
        });
      }}
      isNumericString
    />
  );
}
export const SelectionsLayout = ({
  onChange,
  selectionValue,
  emptyPlaceHolder,
  selectionsList,
  selectionKey,
  selectionLabel,
  disabled,
  isArrayOfNumber,
  textFieldContainerClass,
  placeholder,
  blankPlaceHolder,
  renderValue,
  renderValuePlaceholder,
}) => {
  const classes = useStyles();
  const getNestedValue = (obj, path) => {
    return path?.split(".")?.reduce((acc, part) => acc && acc[part], obj);
  };
  return (
    <TextField
      className={textFieldContainerClass ?? classes?.formContainer}
      fullWidth={true}
      size="small"
      onChange={onChange}
      value={selectionValue || 0}
      placeholder={placeholder}
      select
      variant="outlined"
      disabled={disabled}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        className: classes?.placeholder,
      }}
      SelectProps={{
        renderValue: renderValue,
        displayEmpty: true,
        MenuProps: {
          classes: {
            paper: classes.selctionsLayoutPaper,
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        },
      }}
    >
      {!blankPlaceHolder && (
        <MenuItem value={0}>{emptyPlaceHolder || "None"}</MenuItem>
      )}
      {selectionsList?.length > 0 &&
        selectionsList?.map((option, index) => (
          <MenuItem
            key={index}
            value={isArrayOfNumber ? option : option?.[selectionKey]}
          >
            {!renderValuePlaceholder && renderValue
              ? renderValue(option?.[selectionKey])
              : isArrayOfNumber
              ? option
              : getNestedValue(option, selectionLabel)}
          </MenuItem>
        ))}
    </TextField>
  );
};
export const AutocompleteSelectionsLayout = ({
  onChange,
  value,
  selectionsList,
  selectionLabel,
  searchPlaceHolder,
  searchValue,
  handleSearch,
}) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <Autocomplete
        onChange={(event, newValue) => onChange(newValue)}
        options={selectionsList}
        value={value}
        getOptionLabel={option => option?.[selectionLabel]}
        inputValue={searchValue}
        onInputChange={handleSearch}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={searchPlaceHolder}
          />
        )}
      />
    </FormControl>
  );
};
export const SwitchFormLayout = ({ isChecked, onChange, label, disabled }) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      control={
        <Switch
          checked={isChecked}
          onChange={onChange}
          color="primary"
          classes={{
            root: classes?.rootSwitch,
            switchBase: classes?.switchBase,
            thumb: classes?.thumb,
            track: classes?.track,
          }}
          disabled={disabled}
        />
      }
      label={label}
    />
  );
};
export const TextFormLayout = ({
  placeholder,
  onChange,
  value,
  disabled,
  multiline,
  customProps,
  dense,
}) => {
  const classes = useStyles();
  return (
    <TextField
      className={dense ? classes?.formLayout : classes?.formContainer}
      placeholder={placeholder}
      value={value !== 0 ? value : disabled && 0}
      variant="outlined"
      onChange={onChange}
      margin="dense"
      required
      fullWidth
      disabled={disabled}
      multiline={multiline}
      minRows={multiline ? 10 : 1}
      InputProps={{
        classes: {
          input: classes?.placeholder,
        },
        ...(customProps && {
          inputComponent: NumberFormatCustom,
          endAdornment: (
            <InputAdornment position="end">{customProps}</InputAdornment>
          ),
        }),
      }}
    />
  );
};
export const TextAreaLayout = ({ placeholder, onChange, value }) => {
  const classes = useStyles();
  return (
    <TextareaAutosize
      onChange={onChange}
      className={classes?.textAreaContainer}
      minRows={1.7}
      variant="outlined"
      defaultValue={value}
      placeholder={placeholder}
    />
  );
};
export const DateFormLayout = ({
  placeholder,
  onChange,
  value,
  disabled,
  noValue,
  minDate,
}) => {
  const classes = useStyles();
  return (
    <KeyboardDatePicker
      className={classes?.formContainer}
      variant="outlined"
      onChange={onChange}
      value={noValue ? null : value || new Date()}
      required
      format="DD MMMM YYYY"
      inputVariant="outlined"
      fullWidth
      minDate={minDate ? new Date() : 0}
      margin="dense"
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};
export const DateRangeFormLayout = ({
  placeholder,
  disabled,
  periodType,
  startValue,
  endValue,
  onChangetStartValue,
  onChangeEndValue,
}) => {
  const classes = useStyles();
  const monthPeriod = periodType === "monthPeriod";

  const [anchorMenu, setAnchorMenu] = useState(null);
  const [periodMonthStart, setPeriodMonthStart] = useState({
    month: 0,
    year: 0,
  });
  const [periodMonthEnd, setPeriodMonthEnd] = useState({
    month: 0,
    year: 0,
  });

  const formatPeriodMonthStart = ({ year, month }) => {
    const formattedMonth = String(month).padStart(2, "0");
    return `${year}-${formattedMonth}`;
  };
  const handleOpenMenu = event => setAnchorMenu(event.currentTarget);
  const handleCloseMenu = () => setAnchorMenu(null);
  const handleChangePeriodMonthStart = (value, key) => {
    setPeriodMonthStart(currentState => {
      const updatedState = {
        ...currentState,
        [key]: value,
      };
      const formattedDate = formatPeriodMonthStart(updatedState);
      onChangetStartValue(formattedDate);
      return updatedState;
    });
  };
  const handleChangePeriodMonthEnd = (value, key) => {
    setPeriodMonthEnd(currentState => {
      const updatedState = {
        ...currentState,
        [key]: value,
      };
      const formattedDate = formatPeriodMonthStart(updatedState);
      onChangeEndValue(formattedDate);
      return updatedState;
    });
  };
  const setPreload = () => {
    const [startYear, startMonth] = startValue?.split("-");
    const [endYear, endMonth] = endValue?.split("-");
    setPeriodMonthStart({
      month: parseInt(startMonth, 10),
      year: startYear,
    });
    setPeriodMonthEnd({
      month: parseInt(endMonth, 10),
      year: endYear,
    });
  };

  const valuePeriod = () => {
    const monthName = monthId =>
      monthData?.find(month => month.id === monthId)?.shortName;
    const emptyStart =
      periodMonthStart?.month === 0 || periodMonthStart?.year === 0;
    const emptyEnd = periodMonthEnd?.month === 0 || periodMonthEnd?.year === 0;
    const formatDate = date => moment(date).format("DD/MM/YYYY");

    if (monthPeriod) {
      return `${
        emptyStart
          ? ""
          : monthName(periodMonthStart?.month) + "/" + periodMonthStart?.year
      } - ${
        emptyEnd
          ? ""
          : monthName(periodMonthEnd?.month) + "/" + periodMonthEnd?.year
      }`;
    } else {
      return `${startValue !== null ? formatDate(startValue) : ""} - ${
        endValue !== null ? formatDate(endValue) : ""
      }`;
    }
  };

  useEffect(() => {
    if (monthPeriod && startValue && endValue) {
      setPreload();
    }
  }, [periodType]);

  return (
    <>
      <OutlinedInput
        readOnly
        fullWidth
        className={classes?.formContainer}
        variant="outlined"
        id="outlined-adornment-amount"
        onClick={handleOpenMenu}
        size="small"
        disabled={disabled}
        value={valuePeriod()}
        placeholder={placeholder}
        endAdornment={
          <InputAdornment position="end">
            <IconButton disabled={disabled} onClick={handleOpenMenu}>
              <CalendarToday />
            </IconButton>
          </InputAdornment>
        }
      />
      <Menu
        id="invoice-period"
        anchorEl={anchorMenu}
        keepMounted={false}
        open={Boolean(anchorMenu)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={classes?.paperContainer}>
          <Typography variant="subtitle2" className={classes?.label}>
            Start Period
          </Typography>
          {monthPeriod ? (
            <Grid
              container
              className={classes?.monthPeriodContainer}
              spacing={1}
            >
              <Grid item>
                <Typography variant="body1">Month</Typography>
                <SelectionsLayout
                  selectionValue={periodMonthStart?.month}
                  selectionKey="id"
                  selectionLabel="fullName"
                  selectionsList={monthData}
                  onChange={event =>
                    handleChangePeriodMonthStart(event?.target?.value, "month")
                  }
                  textFieldContainerClass={classes?.monthPeriodForm}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1">Year</Typography>
                <SelectionsLayout
                  selectionValue={periodMonthStart?.year}
                  selectionsList={yearList}
                  onChange={event =>
                    handleChangePeriodMonthStart(event?.target?.value, "year")
                  }
                  isArrayOfNumber
                  textFieldContainerClass={classes?.yearPeriodForm}
                />
              </Grid>
            </Grid>
          ) : (
            <DateFormLayout
              value={startValue}
              onChange={onChangetStartValue}
              placeholder="Start Period"
            />
          )}
          <Typography variant="subtitle2" className={classes?.label}>
            End Period
          </Typography>

          {monthPeriod ? (
            <Grid
              container
              className={classes?.monthPeriodContainer}
              spacing={1}
            >
              <Grid item>
                <Typography variant="body1">Month</Typography>
                <SelectionsLayout
                  selectionValue={periodMonthEnd?.month}
                  selectionKey="id"
                  selectionLabel="fullName"
                  selectionsList={monthData}
                  onChange={event =>
                    handleChangePeriodMonthEnd(event?.target?.value, "month")
                  }
                  textFieldContainerClass={classes?.monthPeriodForm}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1">Year</Typography>
                <SelectionsLayout
                  selectionValue={periodMonthEnd?.year}
                  selectionsList={yearList}
                  onChange={event =>
                    handleChangePeriodMonthEnd(event?.target?.value, "year")
                  }
                  isArrayOfNumber
                  textFieldContainerClass={classes?.yearPeriodForm}
                />
              </Grid>
            </Grid>
          ) : (
            <DateFormLayout
              value={endValue}
              onChange={onChangeEndValue}
              placeholder="Start Period"
            />
          )}
        </div>
      </Menu>
    </>
  );
};
export const NumberFormLayout = ({
  value,
  handleChangeValue,
  thousandSeparator,
  allowLeadingZeros,
  placeholder,
  disabled,
  custonClasses,
  fullWidth,
  customProps,
  suffix,
  prefix,
  readOnly,
}) => {
  const classes = useStyles();
  return (
    <NumberFormat
      displayType={readOnly ? "text" : "input"}
      customInput={TextField}
      variant="outlined"
      className={custonClasses}
      thousandSeparator={thousandSeparator}
      allowLeadingZeros={allowLeadingZeros}
      getInputRef={inputRef =>
        inputRef && inputRef.setAttribute("inputmode", "numeric")
      }
      onValueChange={values => handleChangeValue(values.value)}
      autoComplete="off"
      value={
        value !== undefined && value !== 0 && value !== null
          ? value?.toString()
          : ""
      }
      placeholder={placeholder || "0"}
      size="small"
      disabled={disabled}
      fullWidth={fullWidth}
      suffix={suffix}
      prefix={prefix}
      InputProps={{
        classes: {
          input: classes?.placeholder,
        },
        ...(customProps && {
          endAdornment: (
            <InputAdornment position="end">{customProps}</InputAdornment>
          ),
        }),
      }}
    />
  );
};
export const CurrencyNumberFormLayout = ({
  value,
  handleChangeValue,
  currecnyPrefix,
  placeholder,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <div className={classes?.containerFlexRow}>
      <div className={classes?.currecnyPrefix}>{currecnyPrefix || "Rp"}</div>
      <NumberFormLayout
        value={value}
        handleChangeValue={handleChangeValue}
        thousandSeparator
        placeholder={placeholder}
        disabled={disabled}
        fullWidth
      />
    </div>
  );
};
const BankAccountSelect = ({
  selectionValue,
  selectionKey,
  selectionsList,
  selectionLabel,
  selectionImage,
  onChange,
  emptyPlaceHolder,
  placeholder,
  blankPlaceHolder,
}) => {
  const classes = useStyles();
  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };
  const renderSelectedValue = id => {
    let value = selectionsList?.find(bank => bank?.account_number === id);
    return (
      <>
        {value ? (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <img
                    className={classes?.bankAccountImage}
                    src={getNestedValue(value, selectionImage)}
                    alt={"Bank"}
                  />
                </Grid>
                <Grid item>
                  <Typography>
                    {getNestedValue(value, selectionLabel)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography>{getNestedValue(value, selectionKey)}</Typography>
            </Grid>
          </Grid>
        ) : (
          "Account Number"
        )}
      </>
    );
  };
  return (
    <SelectionsLayout
      onChange={onChange}
      selectionValue={selectionValue}
      selectionsList={selectionsList}
      selectionKey={selectionKey}
      selectionLabel={selectionLabel}
      selectionImage={selectionImage}
      renderValue={renderSelectedValue}
      emptyPlaceHolder={emptyPlaceHolder}
      placeholder={placeholder}
      blankPlaceHolder={blankPlaceHolder}
      isBankAccountSelect
    />
  );
};
export const SingleFormLayout = ({
  label,
  required,
  placeholder,
  onChange,
  value,
  disabled,
  type,
  multiline,
  customProps,
  noValue,
  selectionsList,
  selectionKey,
  selectionLabel,
  emptyPlaceHolder,
  textFieldContainerClass,
  blankPlaceHolder,
  currecnyPrefix,
  minDate,
  selectionImage,
  periodType,
  startValue,
  endValue,
  onChangetStartValue,
  onChangeEndValue,
  renderValuePlaceholder,
  renderValue,
  isArrayOfNumber,
  labelClass,
}) => {
  const classes = useStyles();
  return (
    <>
      {label && (
        <Typography
          variant="subtitle2"
          className={labelClass ?? classes?.label}
        >
          {label} {required && <span className={classes?.required}>*</span>}
        </Typography>
      )}
      {type === "date" ? (
        <DateFormLayout
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          disabled={disabled}
          noValue={noValue}
          minDate={minDate}
        />
      ) : type === "dateRange" ? (
        <DateRangeFormLayout
          placeholder={placeholder}
          disabled={disabled}
          noValue={noValue}
          minDate={minDate}
          periodType={periodType}
          startValue={startValue}
          endValue={endValue}
          onChangetStartValue={onChangetStartValue}
          onChangeEndValue={onChangeEndValue}
        />
      ) : type === "currency" ? (
        <CurrencyNumberFormLayout
          value={value}
          handleChangeValue={onChange}
          currecnyPrefix={currecnyPrefix}
          disabled={disabled}
        />
      ) : type === "bankSelect" ? (
        <BankAccountSelect
          selectionValue={value}
          selectionsList={selectionsList}
          selectionKey={selectionKey}
          selectionLabel={selectionLabel}
          selectionImage={selectionImage}
          emptyPlaceHolder={emptyPlaceHolder}
          onChange={onChange}
          placeholder={placeholder}
          blankPlaceHolder={blankPlaceHolder}
        />
      ) : type === "select" ? (
        <SelectionsLayout
          selectionValue={value}
          selectionsList={selectionsList}
          selectionKey={selectionKey}
          selectionLabel={selectionLabel}
          emptyPlaceHolder={emptyPlaceHolder}
          onChange={onChange}
          placeholder={placeholder}
          textFieldContainerClass={textFieldContainerClass}
          blankPlaceHolder={blankPlaceHolder}
          renderValuePlaceholder={renderValuePlaceholder}
          renderValue={renderValue}
          isArrayOfNumber={isArrayOfNumber}
        />
      ) : (
        <TextFormLayout
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          disabled={disabled}
          multiline={multiline}
          customProps={customProps}
        />
      )}
    </>
  );
};

export const CountBadge = ({ value }) => {
  const classes = useStyles();
  return <div className={classes?.customCountBadge}>{value}</div>;
};
export const FilterSelectedChip = ({
  selectionsList,
  value,
  handleDelete,
  customTextSize,
}) => {
  const truncateLabel = (label, maxLength) => {
    if (!label || label.length <= maxLength) return label;
    return `${label.substring(0, maxLength)}...`;
  };
  return (
    <Card className={useStyles().filterSelectedChipCard}>
      <Grid container spacing={1}>
        {selectionsList?.map((item, index) => (
          <Grid key={index} item>
            <Chip
              label={truncateLabel(item?.[value], 18)}
              onDelete={() => handleDelete(item)}
              style={{
                fontSize: customTextSize,
                backgroundColor: item?.color ? item?.color + "10" : "#eceff1",
                color: item?.color || "#000",
              }}
              deleteIcon={
                <Cancel
                  style={{
                    color: item?.color || "#000",
                  }}
                />
              }
            />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};
export const CheckboxFilterMenu = ({
  filterAnchor,
  selectionValue,
  selectionsList,
  selectionKey,
  selectionLabel,
  isSearchFilter,
  searchValue,
  handleSearch,
  handleChangeCheckBoxFilter,
  loadingState,
  searchPlaceHolder,
  renderChip,
  handleCloseMenu,
}) => {
  const classes = useStyles();
  return (
    <ClickAwayListener onClickAway={handleCloseMenu}>
      <Popper
        open={Boolean(filterAnchor)}
        anchorEl={filterAnchor}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <div className={classes?.filterMenuContainer}>
                <List>
                  {renderChip && (
                    <div className={classes?.filterMenuListItem}>
                      {renderChip}
                    </div>
                  )}
                  {isSearchFilter && (
                    <ListItem className={classes?.filterMenuListItem}>
                      <div className={classes?.searchFilterContainer}>
                        <TextField
                          size="small"
                          type="text"
                          variant="outlined"
                          autoFocus
                          onChange={handleSearch}
                          placeholder={searchPlaceHolder || "Search"}
                          value={searchValue}
                          InputProps={{
                            classes: {
                              input: classes?.searchPlaceholder,
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon className={classes?.searchIcon} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </ListItem>
                  )}
                  {loadingState ? (
                    <CircularProgress />
                  ) : selectionsList?.length ? (
                    selectionsList.map((option, index) => (
                      <ListItem
                        key={index}
                        className={classes?.filterMenuListItem}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              className={classes?.filterCheckbox}
                              checked={selectionValue?.some(
                                selected =>
                                  selected?.[selectionKey] ===
                                  option?.[selectionKey]
                              )}
                              name={option?.[selectionLabel]}
                              value={option?.[selectionKey]}
                              onChange={e =>
                                handleChangeCheckBoxFilter(e, option)
                              }
                            />
                          }
                          label={option?.[selectionLabel]}
                        />
                      </ListItem>
                    ))
                  ) : (
                    <Typography className={classes?.emptyPlaceholder}>
                      No Result
                    </Typography>
                  )}
                </List>
              </div>
            </Paper>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
};
export const CustomCheckboxFilterForm = ({
  label,
  loadingState,
  handleChangeCheckBoxFilter,
  value,
  selectionValue,
  selectionsList,
  selectionLabel,
  selectionKey,
  isSearchFilter,
  searchValue,
  handleSearch,
  placeholder,
  prefix,
  textFieldContainerClass,
  customEndAdorment,
  renderChip,
  searchPlaceHolder,
  labelClass,
  disabled,
}) => {
  const classes = useStyles();
  const [filterAnchor, setFilterAnchor] = useState(null);
  const handleShowMenu = event => setFilterAnchor(event.currentTarget);
  const handleCloseMenu = () => setFilterAnchor(null);
  return (
    <>
      {label && (
        <Typography className={labelClass ?? classes?.label}>
          {label}
        </Typography>
      )}
      <OutlinedInput
        readOnly
        disabled={disabled}
        variant="outlined"
        onClick={!disabled ? handleShowMenu : null}
        fullWidth
        size="small"
        className={textFieldContainerClass ?? classes?.filterFormContainer}
        style={{ cursor: "pointer" }}
        value={value}
        placeholder={placeholder}
        startAdornment={prefix}
        endAdornment={
          <InputAdornment position="end" className={classes?.cursorPointer}>
            {customEndAdorment && customEndAdorment}
            {filterAnchor ? (
              <ArrowDropUp onClick={!disabled ? handleCloseMenu : null} />
            ) : (
              <ArrowDropDown onClick={!disabled ? handleShowMenu : null} />
            )}
          </InputAdornment>
        }
      />
      <>
        {Boolean(filterAnchor) && (
          <CheckboxFilterMenu
            selectionValue={selectionValue}
            selectionsList={selectionsList}
            selectionKey={selectionKey}
            selectionLabel={selectionLabel}
            filterAnchor={filterAnchor}
            isSearchFilter={isSearchFilter}
            handleChangeCheckBoxFilter={handleChangeCheckBoxFilter}
            handleCloseMenu={handleCloseMenu}
            handleSearch={handleSearch}
            searchValue={searchValue}
            loadingState={loadingState}
            renderChip={renderChip}
            searchPlaceHolder={searchPlaceHolder}
          />
        )}
      </>
    </>
  );
};
