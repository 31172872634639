import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Dialog,
  DialogActions,
  Box,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import moment from "moment";
import MuiAlert from "@material-ui/lab/Alert";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router";
import Swal from "sweetalert2";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import gradients from "../../../../theme/gradients";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: "100%",
    overflow: "visible",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  selectSong: {
    color: "#687083"
  },
  avatar_large: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
  buttonDefault: {
    textTransform: "none",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    textDecoration: "none",
  },
  cardTime: {
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
  },
  loginForm: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  person: {
    marginTop: theme.spacing(2),
    display: "flex",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },

  tambahLagu: {
    backgroundColor: " #111827",
    color: "white",
    width: "32px",
    height: "29px",
    borderRadius: "6px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  textTambahLagu: {
    marginLeft: "10px",
    marginTop: "5px",
    fontSize: "14px",
    color: "#687083",
  },
  textP: {
    marginTop: "5px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  btnDialog: {
    backgroundColor: "black",

    color: "white",
    padding: "10px 28px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  textNoData: {
    fontWeight: 400,
    color: "#687083",
    fontSize: "14px",
  },
  btnSubmit: {
    backgroundColor: " #111827",
    color: "white",
    padding: "10px 28px",
    height: "40px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  updateContract: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
  },
  tabContainer: {
    maxHeight: 200
  },
  paperModalLagu: {
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#F9FAFB",
    cursor: "pointer",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
  },
  textAction: {
    textTransform: "none",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    textDecoration: "none",
  },
  tableRow: {
    height: 20,
  },
  formSelect: {
    minWidth: 120
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  textUpdateSubmit: {
    textTransform: "none",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  dropdown: {
    color: "black",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  btnTimePeriod: {
    backgroundColor: "#111827",
    marginRight: "10px",
    borderColor: "#FFFFFF",
    border: "1px solid #9AA2B1",
  },
  btnNotTimePeriod: {
    backgroundColor: "#FFFFFF",
    marginRight: "10px",
    borderColor: "black",
    border: "1px solid #9AA2B1",
  },
  textTimePeriod: {
    textTransform: "none",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    textDecoration: "none",
  },
  textNotTimePeriod: {
    textTransform: "none",
    color: "#111827",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    textDecoration: "none",
  },
  btnYearPeriod: {
    backgroundColor: " #111827",
    borderColor: "#FFFFFF",
    border: "1px solid #9AA2B1",
  },
  btnNotYearPeriod: {
    backgroundColor: "#FFFFFF",
    borderColor: "black",
    border: "1px solid #9AA2B1",
  },
  textYearPeriod: {
    textTransform: "none",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    textDecoration: "none",
  },
  textNotYearPeriod: {
    textTransform: "none",
    color: " #111827",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    textDecoration: "none",
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  cardTable: {
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
}));

function EditKontrak() {
  const classes = useStyles();
  const state = useLocation();
  const [open, setOpen] = useState(false);
  const [songList, setSongList] = useState([]);

  const checked = false;
  const [penciptaList, setPenciptaList] = useState([]);

  const [songId, setSongId] = useState(null);
  const [startDate, setStartDate] = useState(moment().toString());
  const [endDate, setEndDate] = useState(moment().toString());
  const [dateRange, setDateRange] = useState(0);

  const [keterangan, setKeterangan] = useState("");
  const [openSnack, setOpenSnack] = useState(false);

  const [typeSnackbar, setTypeSnackbar] = useState("success");
  const [message, setMessage] = useState("This is a message!");
  const [wordsSong, setWordsSong] = useState("");
  const [getColor, setGetColor] = useState("");
  const [timePeriod, setTimePeriod] = useState("1");

  const [isLoadSong, setIsLoadSong] = useState(true);

  const openModalLagu = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = () => {
    setPenciptaList([]);
    const newData = songList.map(item => {
      item.checked = false;
      return item;
    });
    setSongList(newData);
  };

  const getDataEdit = () => {
    const token = localStorage.getItem("token");
    const id = state.state.id;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${hardBaseUrl}/publisher/contract/original-publisher/${id}`, config)
      .then(res => {
        let startingDate = res.data.data.contract.start_date.split(" ");
        let startingDate2 = startingDate[0].split("-");
        let startingDate3 =
          startingDate2[1] + "/" + startingDate2[0] + "/" + startingDate2[2];
        let endingDate = res.data.data.contract.end_date.split(" ");
        let endingDate2 = endingDate[0].split("-");
        let endingDate3 =
          endingDate2[1] + "/" + endingDate2[0] + "/" + endingDate2[2];

        let startingDate4 = new Date(startingDate3);
        let endingDate4 = new Date(endingDate3);

        setStartDate(startingDate4);
        setEndDate(endingDate4);
        const removePercentageSymbolAndReturnToNumber = string => {
          return parseFloat(string.replace(/%/g, ""));
        };
        const newData = res.data.data.songs[0].composers.map(item => ({
          composer: {
            sure_name: item.composer_name,
            iswc_code: item.iswc_code,
          },
          title_song: res.data.data.songs[0].title,
          percentage_ownership: removePercentageSymbolAndReturnToNumber(
            item.ownership_percentage
          ),
        }));

        setSongId(res.data.data.songs[0].song_id);
        setPenciptaList(newData);
        setKeterangan(res.data.data.contract.description);
      })
      .catch(() => { });
  };
  useEffect(() => {
    getDataEdit();
  }, []);

  const tableRowClick = id => {
    songList.map(item => {
      if (item.song_id === id) {
        item.checked = !item.checked;
        const newData = item.song_composer.map(item2 => ({
          ...item2,
          title_song: item.title_song,
        }));
        setPenciptaList(newData);
        setSongId(item.song_id);
      } else {
        item.checked = false;
      }
    });
  };

  const getResponse = async () => {
    try {
      const token = localStorage.getItem("token");
      const url =
        hardBaseUrl + "/publisher/song?page=1&size=10&search=" + wordsSong;

      const options = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const res = await axios.get(url, options);

      const newData = res.data.data.map(item => ({
        ...item,
        checked: false,
      }));
      setSongList(newData);

      setIsLoadSong(false);
    } catch (error) {
      new Error(error);
      setIsLoadSong(false);
    }
  };

  useEffect(() => {
    getResponse();
    const getMainDsp = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${hardBaseUrl}/me`;
        const res = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setGetColor(res.data.data.publisher.theme_color);
      } catch (e) {
        new Error(e);
      }
    };
    getMainDsp();
  }, [checked, startDate, endDate, wordsSong]);

  const listPencipta = data => {
    let pencipta = data;

    if (pencipta === null || undefined || "") {
      return <div>-</div>;
    } else if (pencipta.length <= 3) {
      return pencipta.map((pencipta, index) => (
        <div key={index}>
          <ThemeProvider theme={theme}>
            <Chip
              key={index}
              label={pencipta.composer.sure_name}
              className={classes.tableChip}
              size="small"
            />
          </ThemeProvider>
        </div>
      ));
    } else if (pencipta.length > 3) {
      let pencipta1 = pencipta.slice(0, 3);
      let pencipta2 = pencipta.slice(3, pencipta.length);
      return (
        <div>
          <ThemeProvider theme={theme}>
            {pencipta1.map((pencipta, index) => (
              <Chip
                key={index}
                label={pencipta.composer.sure_name}
                className={classes.tableChip}
                size="small"
              />
            ))}
            <Chip
              label={"+" + pencipta2.length}
              className={classes.tableChip}
              size="small"
            />
          </ThemeProvider>
        </div>
      );
    }
  };

  const handleDateChangeStartDate = date => {
    if (date > endDate) {
      setEndDate(date);
      setStartDate(endDate);
      setMessage("Start date must be less than end date");
      setTypeSnackbar("error");
      setOpenSnack(true);
    } else {
      setStartDate(date);
    }
  };

  const handleDateChangeEndDate = date => {
    if (date < startDate) {
      setStartDate(date);
      setMessage("End date must be greater than start date");
      setTypeSnackbar("error");
      setOpenSnack(true);
    } else {
      setEndDate(date);
    }
  };

  const handleDateRangeChange = event => {
    setEndDate(
      new Date(startDate.getTime() + event.target.value * 24 * 60 * 60 * 1000)
    );

    setDateRange(event.target.value);
  };

  const handleDateRangeChangeYear = event => {
    setEndDate(
      new Date(
        startDate.getTime() + event.target.value * 365 * 24 * 60 * 60 * 1000
      )
    );
    setDateRange(event.target.value);
  };

  const getDateFormatForFilter = date => {
    let dateFormat = new Date(date);
    let day = dateFormat
      .getDate()
      .toString()
      .padStart(2, "0");
    let month = (dateFormat.getMonth() + 1).toString().padStart(2, "0");
    let year = dateFormat.getFullYear();
    let dateString = `${year}-${month}-${day}`;
    return dateString;
  };
  const handleButtonSimpan = async e => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");

      if (songId === null) {
        setTypeSnackbar("error");
        setMessage("lagu Tidak Boleh Kosong.");
        setOpenSnack(true);
      }
      if (startDate === "" || null || undefined) {
        setTypeSnackbar("error");
        setMessage("Tanggal Mulai Tidak Boleh Kosong.");
        setOpenSnack(true);
      }
      if (endDate === "" || null || undefined) {
        setTypeSnackbar("error");
        setMessage("Tanggal Selesai Tidak Boleh Kosong.");
        setOpenSnack(true);
      }

      if (songId !== null && startDate !== null && endDate !== null) {
        const formData = new FormData();
        formData.append("song_ids", songId);
        formData.append("date_periode", Number(dateRange));
        formData.append("start_date", getDateFormatForFilter(startDate));
        formData.append("end_date", getDateFormatForFilter(endDate));
        formData.append("description", keterangan);

        let baseOfUrl = hardBaseUrl;
        const id = state.state.id;
        let urlUse = baseOfUrl + `/publisher/contract/original-publisher/${id}`;

        let resultAdd = await axios.put(urlUse, formData, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        if (
          resultAdd.data.message === "success" &&
          resultAdd.data.meta.http_status === 200
        ) {
          Swal.fire({
            title: "Success",
            text: "Data Berhasil Disimpan",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then(result => {
            if (result.value) {
              window.location.reload();
            }
          });

          setTimeout(() => { }, 3000);
        } else {
          setTypeSnackbar("error");
          setMessage("Tambah Kontrak Gagal.");
          setOpenSnack(true);
        }
      }
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: "Error.",
        icon: "error",
      }).then(() => { });
    }
  };

  const handleChangeText = e => {
    setKeterangan(e.target.value);
  };
  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };

  const handleTimePeriodYear = () => {
    setTimePeriod("2");
    setDateRange(0);
  };

  const handleTimePeriodUnlimited = () => {
    setTimePeriod("1");
    getDataEdit();
    const totalDistance = (endDate - startDate) / (1000 * 60 * 60 * 24);

    let totalDistanceEven =
      totalDistance % 2 === 0 ? totalDistance : totalDistance + 1;
    const totalEven = Math.floor(totalDistanceEven);
    setDateRange(totalEven);
  };

  return (
    <Page className={classes.root} title="Tambah Kontrak">
      <Container maxWidth={false}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
            {message}
          </Alert>
        </Snackbar>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography className={classes.updateContract}>
                Update Contract
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>Contract</Typography>
              <Typography className={classes.breadCrumbsActive}>
                Update Contract
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Song
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p className={classes.textP}>
                This feature can add multiple songs in one contract
              </p>
            </ThemeProvider>
          </div>
        </Grid>

        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Grid container>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <Box margin="5px 0px">
                  <Box margin="10px 0px">
                    <Paper
                      className={classes.paperModalLagu}
                      onClick={openModalLagu}
                    >
                      <Container>
                        <Box display="flex">
                          <IconButton
                            onClick={openModalLagu}
                            className={classes.tambahLagu}
                          >
                            <AddIcon />
                          </IconButton>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="subtitle2"
                              className={classes.textTambahLagu}
                            >
                              Click to add song
                            </Typography>
                          </ThemeProvider>
                        </Box>
                      </Container>
                    </Paper>
                    <Dialog
                      fullWidth
                      maxWidth="md"
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="max-width-dialog-title"
                    >
                      <DialogTitle id="max-width-dialog-title">
                        Search Songs
                      </DialogTitle>
                      <Grid container>
                        <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                          <Container>
                            <Box display="flex">
                              <FormControl
                                variant="outlined"
                                className={classes.formSelect}
                              >
                                <Select
                                  native
                                  value="name"
                                  disabled
                                  inputProps={{
                                    name: "age",
                                    id: "outlined-age-native-simple",
                                  }}
                                >
                                  <option
                                    value="name"
                                    className={classes.selectSong}
                                  >
                                    Song Title
                                  </option>
                                </Select>
                              </FormControl>
                              <FormControl variant="outlined" fullWidth={true}>
                                <OutlinedInput
                                  id="outlined-adornment-password"
                                  placeholder="Search"
                                  value={wordsSong}
                                  onChange={e => {
                                    setWordsSong(e.target.value);
                                  }}
                                />
                              </FormControl>
                            </Box>

                            <Grid container>
                              <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
                                <Divider className={classes.divider} />
                              </Grid>
                            </Grid>

                            <TableContainer className={classes.tabContainer}>
                              <Table stickyHeader size="small">
                                <TableHead>
                                  <TableRow>
                                    <ThemeProvider theme={theme}>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.headText}
                                        >
                                          ISWC Code
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.headText}
                                        >
                                          Song Title
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.headText}
                                        >
                                          Composer/Author Name
                                        </Typography>
                                      </TableCell>
                                    </ThemeProvider>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {isLoadSong ? (
                                    <>
                                      <TableRow>
                                        <TableCell align="center" colSpan={3}>
                                          <CircularProgress />
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  ) : (
                                    <>
                                      {songList.map(customer => (
                                        <TableRow
                                          hover
                                          key={customer.song_id}
                                          onClick={() => {
                                            tableRowClick(
                                              customer.song_id,
                                              customer.song_composer
                                            );
                                          }}
                                        >
                                          <ThemeProvider theme={theme}>
                                            <TableCell>
                                              <Typography
                                                variant="subtitle2"
                                                className={classes.bodyTextGrey}
                                              >
                                                {customer.isrc_code}
                                              </Typography>
                                            </TableCell>
                                            <TableCell>
                                              <Typography
                                                variant="subtitle2"
                                                className={
                                                  classes.bodyTextBlack
                                                }
                                              >
                                                {customer.title_song}
                                              </Typography>
                                            </TableCell>

                                            <TableCell>
                                              <Typography
                                                variant="subtitle2"
                                                className={
                                                  classes.bodyTextBlack
                                                }
                                              >
                                                {listPencipta(
                                                  customer.song_composer
                                                )}
                                              </Typography>
                                            </TableCell>
                                          </ThemeProvider>
                                          <TableCell>
                                            {customer.checked === true ? (
                                              <CheckIcon />
                                            ) : (
                                              ""
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Container>
                        </Grid>
                      </Grid>
                      <DialogActions>
                        <Button
                          onClick={handleReset}
                          className={classes.btnDialog}
                          style={{
                            backgroundColor: getColor,
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.textAction}>
                              Cancel
                            </Typography>
                          </ThemeProvider>
                        </Button>
                        <Button
                          onClick={handleClose}
                          className={classes.btnDialog}
                          variant="contained"
                          style={{
                            backgroundColor: getColor,
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.textAction}>
                              Choose
                            </Typography>
                          </ThemeProvider>
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Composer
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p className={classes.textP}>
                Composer Information
              </p>
            </ThemeProvider>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Grid container>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <Card className={classes.cardTable}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <ThemeProvider theme={theme}>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Song
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Composer/Author Name
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              ISWC Code
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              className={classes.headText}
                            >
                              Ownership Percentage
                            </Typography>
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {penciptaList !== null ? (
                        penciptaList.length > 0 ? (
                          penciptaList.map(customer => (
                            <TableRow hover key={customer.composer_id}>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer.title_song}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer.composer.sure_name}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextGrey}
                                >
                                  {customer.composer.iswc_code}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.bodyTextBlack}
                                >
                                  {customer.percentage_ownership}%
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4}>
                              <center>
                                <ThemeProvider theme={theme}>
                                  <Typography
                                    variant="h6"
                                    className={classes.textNoData}
                                  >
                                    no data
                                  </Typography>
                                </ThemeProvider>
                              </center>
                            </TableCell>
                          </TableRow>
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <center>
                              <ThemeProvider theme={theme}>
                                <Typography
                                  variant="h6"
                                  className={classes.textNoData}
                                >
                                  no data
                                </Typography>
                              </ThemeProvider>
                            </center>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>

        <Grid container>
          <Box>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Information Contract
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p className={classes.textP}>
                Form to fill out information data related to the contract to be
                made
              </p>
            </ThemeProvider>
          </Box>
        </Grid>
        <Box marginBottom="20px">
          <Button
            onClick={handleTimePeriodUnlimited}
            className={`${classes.btnDialog} ${timePeriod === "1" ? classes.btnTimePeriod : classes.btnNotTimePeriod}`}
          >
            <ThemeProvider theme={theme}>
              <Typography className={timePeriod === "1" ? classes.textTimePeriod : classes.textNotTimePeriod}>
                Unlimited
              </Typography>
            </ThemeProvider>
          </Button>
          <Button
            onClick={handleTimePeriodYear}
            className={`${classes.btnDialog} ${timePeriod === "2" ? classes.btnYearPeriod : classes.btnNotYearPeriod}`}
            variant="contained"
          >
            <ThemeProvider theme={theme}>
              <Typography className={`${timePeriod === "2" ? classes.textYearPeriod : classes.textNotYearPeriod}`}
              >
                Year
              </Typography>
            </ThemeProvider>
          </Button>
        </Box>

        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Card className={classes.cardTime}>
              <CardContent>
                <Grid container>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <Box margin="5px 0px">
                      <Box margin="10px 0px">
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Time Period
                          </Typography>
                        </ThemeProvider>
                      </Box>
                      <Box marginTop="-10px" marginBottom="10px">
                        <TextField
                          defaultValue={0}
                          variant="outlined"
                          value={dateRange}
                          onChange={
                            timePeriod === "1"
                              ? handleDateRangeChange
                              : handleDateRangeChangeYear
                          }
                          name="numberformat"
                          id="formatted-numberformat-input"
                          margin="dense"
                          fullWidth={true}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            endAdornment: (
                              <InputAdornment position="end">
                                {timePeriod === "1" ? "Day" : "Year"}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <Box margin="5px 0px">
                      <Box margin="10px 0px">
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Start Date
                          </Typography>
                        </ThemeProvider>
                      </Box>
                      <Box marginTop="-10px" marginBottom="10px">
                        <KeyboardDatePicker
                          id="outlined-basic"
                          variant="outlined"
                          onChange={handleDateChangeStartDate}
                          value={startDate}
                          name="startDate"
                          format="YYYY-MM-DD"
                          inputVariant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <Box margin="5px 0px">
                      <Box margin="10px 0px">
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            End Date
                          </Typography>
                        </ThemeProvider>
                      </Box>
                      <Box marginTop="-10px" marginBottom="10px">
                        <KeyboardDatePicker
                          id="outlined-basic"
                          value={endDate}
                          onChange={handleDateChangeEndDate}
                          format="YYYY-MM-DD"
                          name="endDate"
                          inputVariant="outlined"
                          fullWidth={true}
                          margin="dense"
                          placeholder="Tanggal Selesai"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                    <Box margin="5px 0px">
                      <Box margin="10px 0px">
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Description
                          </Typography>
                        </ThemeProvider>
                      </Box>
                      <Box marginTop="-10px" marginBottom="10px">
                        <TextField
                          multiline={true}
                          minRows={10}
                          id="outlined-basic"
                          placeholder="Tulis keterangan disini..."
                          value={keterangan}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          name="keterangan"
                          onChange={e => handleChangeText(e)}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
        </Grid>

        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container>
          <Button
            variant="contained"
            onClick={e => handleButtonSimpan(e)}
            className={classes.btnSubmit}
          >
            <ThemeProvider theme={theme}>
              <Typography className={classes.textUpdateSubmit}>
                Update Contract
              </Typography>
            </ThemeProvider>
          </Button>
        </Grid>
      </Container>
    </Page>
  );
}

export default EditKontrak;
