import { SearchTextInput } from "components";
import {
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  FormControlLabel,
  Grow,
  List,
  ListItem,
  makeStyles,
  Paper,
  Popper,
} from "@material-ui/core";
import Typography from "theme/typography";

const CheckboxListWithSearchMenu = ({
  filterAnchor,
  handleCloseMenu,
  arrayChip,
  loadingState,
  onChangeSearch,
  isSearchFilter,
  searchValue,
  searchPlaceholder,
  selectionsList,
  selectionValue,
  selectionLabel,
  selectionKey,
  onChangeCheckbox,
  width,
  drawerHeight,
  checkboxLabel,
}) => {
  const classes = useStyles({ width, drawerHeight });
  return (
    <ClickAwayListener onClickAway={handleCloseMenu}>
      <Popper
        open={Boolean(filterAnchor)}
        anchorEl={filterAnchor}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <div className={classes?.inputClass}>
                <List>
                  <div className={classes?.selectHeader}>
                    {arrayChip && (
                      <div className={classes?.arrayChipContainer}>
                        {arrayChip}
                      </div>
                    )}
                    {isSearchFilter && (
                      <ListItem className={classes?.filterMenuListItem}>
                        <div className={classes?.searchFilterContainer}>
                          <SearchTextInput
                            placeholder={searchPlaceholder}
                            value={searchValue}
                            onChange={onChangeSearch}
                            width="100%"
                          />
                        </div>
                      </ListItem>
                    )}
                  </div>
                  {loadingState ? (
                    <CircularProgress />
                  ) : selectionsList?.length ? (
                    selectionsList.map((option, index) => (
                      <ListItem
                        key={index}
                        className={classes?.filterMenuListItem}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              className={classes?.filterCheckbox}
                              checked={selectionValue?.some(
                                selected =>
                                  selected?.[selectionKey] ===
                                  option?.[selectionKey]
                              )}
                              name={option?.[selectionLabel]}
                              value={option?.[selectionKey]}
                              onChange={e => onChangeCheckbox(e, option)}
                            />
                          }
                          label={
                            checkboxLabel
                              ? checkboxLabel(option) || "-"
                              : option?.[selectionLabel]
                          }
                        />
                      </ListItem>
                    ))
                  ) : (
                    <Typography className={classes?.emptyPlaceholder}>
                      No Result
                    </Typography>
                  )}
                </List>
              </div>
            </Paper>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

const useStyles = makeStyles(theme => ({
  inputClass: props => ({
    padding: 0,
    minWidth: props?.width || 223,
    maxHeight: props.drawerHeight || 520,
    overflowY: "auto",
    zIndex: theme.zIndex.modal,
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
  filterMenuListItem: {
    padding: "0 20px",
  },
  arrayChipContainer: props => ({
    padding: "0 20px",
    maxWidth: props?.width || 300,
    maxHeight: 150,
    overflowY: "auto",
    zIndex: theme.zIndex.modal,
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
  selectHeader: {
    position: "sticky",
    top: 0,
    left: 0,
    backgroundColor: "white",
    zIndex: 1,
  },
  searchFilterContainer: {
    margin: "16px 0",
  },
  filterCheckbox: {
    "&.MuiCheckbox-root": {
      color: "black",
      "&.Mui-checked": {
        color: "black",
      },
    },
  },
}));

export default CheckboxListWithSearchMenu;
