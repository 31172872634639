import { Button, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});

const DialogDokumen = ({ open, onClose, item }) => {
  return (
    <div>
      <Dialog
        fullWidth={true}
        fullScreen={true}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            height: "fit-content",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                height: "fit-content",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#111827",
                }}
              >
                {"Document Details"}
              </Typography>
            </div>
            <div>
              <CloseIcon onClick={onClose} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            height: "100%",
          }}
        >
          <iframe
            src={item.url}
            style={{
              height: "100%",
              width: "100%",
            }}
          ></iframe>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={onClose}
            variant="outlined"
            style={{
              backgroundColor: "black",
              color: "white",
              textTransform: "none",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  color: "white",
                }}
              >
                Close
              </Typography>
            </ThemeProvider>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogDokumen;
