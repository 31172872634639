import { Button, CircularProgress, styled } from "@mui/material";

const PrimaryTextButton = ({
  label,
  onClick,
  startIcon,
  endIcon,
  loading,
  disabled,
  width,
  height,
  size,
  textColor,
  textDecoration,
  ...rest
}) => {
  return (
    <TextButton
      variant="text"
      onClick={onClick}
      disabled={disabled}
      customWidth={width}
      textColor={textColor}
      customHeight={height}
      textDecoration={textDecoration}
      size={size}
      startIcon={loading ? <CircularProgress size={16} /> : startIcon}
      endIcon={endIcon}
      {...rest}
    >
      {label}
    </TextButton>
  );
};

const TextButton = styled(Button)(
  ({ textColor, iconColor, customWidth, customHeight, textDecoration }) => ({
    width: customWidth || "auto",
    height: customHeight || "auto",
    "&.MuiButton-root": {
      textTransform: "none",
      color: textColor || "#263238",
      textDecoration: textDecoration,
      justifyContent: "start",
    },
    "& .MuiSvgIcon-root": {
      color: iconColor || "#9545EB",
    },
  })
);
export default PrimaryTextButton;
