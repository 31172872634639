import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Box,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Create, DeleteOutline } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ButtonWithModalPerItem } from "../../../../components/atoms/Button";
import TableEditBar from "../../../../components/molecules/Table/TableEditBar";
import { hardBaseUrl } from "../../../../services/urlConstant";
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function Results({ className, handleDelete, items, handleEditMode, ...rest }) {
  const [getColor, setGetColor] = useState("");
  const getMainDsp = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/me`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setGetColor(res.data.data.publisher.theme_color);
    } catch (e) {
      new Error(e);
    }
  };
  useEffect(() => {
    getMainDsp();
  }, []);

  const useStyles = makeStyles(theme => ({
    root: {
      padding: 0,
    },
    content: {
      padding: 0,
      border: "1px solid #e0e0e0",
      width: "100%",
    },
    inner: {
      minWidth: 700,
    },
    nameCell: {
      display: "flex",
      alignItems: "center",
    },
    avatar: {
      height: 42,
      width: 42,
      marginRight: theme.spacing(1),
    },
    actions: {
      padding: theme.spacing(1),
      justifyContent: "flex-end",
    },
    mypadding: {
      "& .MuiSelect-outlined": {
        paddingTop: "14px",
        paddingBottom: "14px",

        paddingRight: "30px",
      },
    },
    headText: {
      fontSize: "12px",
      color: "#687083",
      fontWeight: "bold",
    },
    bodyTextBlack: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#111827",
      fontStyle: "normal",
    },
    bodyTextGrey: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#687083",
      fontStyle: "normal",
    },
    tableRow: {
      height: 20,
    },
    conTextBlack: {
      fontSize: "14px",
      fontWeight: "bold",
    },
    dropdown: {
      color: "black",
    },
    subtitle: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "28px",
    },
    btnEdit: {
      backgroundColor: "#111827",
      color: "white",
      width: "1.5rem",
      height: "1.5rem",
      padding: "0.8rem",
      borderRadius: "6px",
      "&:hover, &.MuiIconButton": {
        background: "#374151 !important",
      },
    },
    btnDelete: {
      backgroundColor: "#111827",
      color: "white",
      width: "1.5rem",
      height: "1.5rem",
      padding: "0.8rem",
      borderRadius: "6px",
      marginLeft: "10px",
      cursor: "pointer",
      "&:hover, &.MuiIconButton": {
        background: "#374151 !important",
      },
    },
    images: { width: "30%", marginRight: "10px" },
    filter: {
      filter: " brightness(0) invert(1)",
    },
  }));

  const classes = useStyles();

  return (
    <Container {...rest} maxWidth={false}>
      <Grid className={classes.content}>
        <PerfectScrollbar>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <ThemeProvider theme={theme}>
                  <TableCell>Action</TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle2"
                      className={classes.headText}
                    >
                      Bank
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle2"
                      className={classes.headText}
                    >
                      Account Number
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle2"
                      className={classes.headText}
                    >
                      Name
                    </Typography>
                  </TableCell>
                </ThemeProvider>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map(customer => (
                <TableRow hover key={customer.publisher_bank_id}>
                  <ThemeProvider theme={theme}>
                    <TableCell align="right">
                      <Box style={{ display: "flex" }}>
                        <Tooltip title="Update" placement="bottom">
                          <IconButton
                            className={classes.btnEdit}
                            onClick={e =>
                              handleEditMode(
                                e,
                                customer.publisher_bank_id,
                                customer
                              )
                            }
                          >
                            <Create
                              alt="update-icon"
                              className={classes.filter}
                            />
                          </IconButton>
                        </Tooltip>
                        <ButtonWithModalPerItem
                          item={customer}
                          title="Hapus Pembayaran"
                          dialogTitle="Hapus Pembayaran"
                          subTitle="Apakah Anda yakin akan menghapus data ini"
                          className={classes.btnDelete}
                          handleDelete={() => {
                            handleDelete(customer.publisher_bank_id);
                          }}
                          btnIcon={
                            <DeleteOutline
                              alt="delete-icon"
                              className={classes.filter}
                            />
                          }
                        />
                      </Box>
                    </TableCell>
                    <TableCell component="th" scope="row" width={220}>
                      <Typography
                        variant="subtitle2"
                        className={classes.bodyTextBlack}
                      >
                        <img
                          alt="bankName"
                          src={customer.bank.profile_image}
                          className={classes.images}
                        />
                        <span>{customer.bank.name}</span>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.bodyTextBlack}
                      >
                        {customer.account_number}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.bodyTextBlack}
                      >
                        {customer.account_name}
                      </Typography>
                    </TableCell>
                  </ThemeProvider>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Grid>
      <TableEditBar selected={[]} />
    </Container>
  );
}
Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array,
};
Results.defaultProps = {
  customers: [],
};
export default Results;
