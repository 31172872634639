import { Container, Divider } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTableV2 } from "inno-ui";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SelectInput,
  SkeletonComponent,
  StatusChip,
} from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import { formatDate, getErrors, textCapitalization } from "utils";
import moment from "moment";
import { HeaderTitle } from "layouts";
import { debounce } from "lodash";
import { getTotalPage } from "lib";
import { Grid } from "@mui/material";

function OriginalPublisherContract() {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    v: 2,
    page: 1,
    offset: 0,
    limit: 10,
    composer: "",
    search: "",
    category: "",
    status: "",
    approval: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const { page, limit, search, category, status, approval } = queryParams;

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleView = item =>
    history.push({
      pathname: `/admin/kontrak-composer/${item.contract_id}`,
      state: {
        customer: item,
        id: item.contract_id,
      },
    });
  const handleEdit = item =>
    history.push({
      pathname: `/admin/kontrak-composer/edit/${item.contract_id}`,
      state: {
        customer: item,
        id: item.contract_id,
        is_editable: item.is_editable,
      },
    });

  const getDataTable = async () => {
    const options = {
      headers,
      params: queryParams,
    };
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/publisher/contract`, options);
      const { data, meta } = res?.data;
      setDataTable(data?.contracts);
      const pageCount = getTotalPage(meta?.total, limit);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleDelete = async item => {
    const { category, contract_id } = item;
    const mainContract = category === "main";
    const url = `${hardBaseUrl}/publisher/contract${
      mainContract ? "/composer-main" : ""
    }/${contract_id}`;
    setLoadingPage(true);
    await axios
      .delete(url, { headers })
      .then(() =>
        ModalSuccess("Delete Contract Success").then(result => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })
      )
      .catch(error =>
        ModalError(getErrors(error?.response)).then(result => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })
      )
      .finally(() => setLoadingPage(false));
  };
  useEffect(() => {
    debounceDataTable();
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Kontrak">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Contract Composer/Author"
            breadcrumbData={breadcrumbData}
          />
          <Divider className={classes.divider} />
          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item>
              <SearchTextInput
                label="Search"
                placeholder="Search"
                value={search}
                onChange={e =>
                  handleChangeQueryParams(e?.target?.value, "search")
                }
              />
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1} alignItems="center">
                <Grid item>
                  <SelectInput
                    placeholder="None"
                    label="Category"
                    value={category}
                    onChange={e =>
                      handleChangeQueryParams(e?.target?.value, "category")
                    }
                    options={categoryList}
                    optionKey="value"
                    optionLabel="label"
                    width={150}
                  />
                </Grid>
                <Grid item>
                  <SelectInput
                    placeholder="None"
                    label="Status"
                    value={status}
                    onChange={e =>
                      handleChangeQueryParams(e?.target?.value, "status")
                    }
                    options={statusList}
                    optionKey="value"
                    optionLabel="label"
                    width={150}
                  />
                </Grid>
                <Grid item>
                  <SelectInput
                    placeholder="None"
                    label="Approval Status"
                    value={approval}
                    onChange={e =>
                      handleChangeQueryParams(e?.target?.value, "approval")
                    }
                    options={approvalStatusList}
                    optionKey="value"
                    optionLabel="label"
                    width={200}
                  />
                </Grid>
                <Grid item>
                  <PrimaryButton
                    label="Create Contract"
                    onClick={() =>
                      history.push("/admin/kontrak-composer/tambah")
                    }
                    startIcon={<AddIcon />}
                    size="large"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          {dataTable && (
            <InnoTableV2
              isLoading={loadingPage}
              columns={columnTable}
              items={dataTable}
              page={page}
              rowsPerPage={limit}
              totalPage={tableTotalPage}
              handleChangePage={(_, page) =>
                handleChangeQueryParams(page, "page")
              }
              handleChangeRowsPerPage={e =>
                handleChangeQueryParams(e?.target?.value, "limit")
              }
              isHaveAction={true}
              handleEdit={handleEdit}
              handleView={handleView}
              isUsingDeleteConfirmation={true}
              handleDelete={handleDelete}
              deleteName={"contract_number"}
            />
          )}
        </Container>
      )}
    </Page>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Contract Composer/Author",
    active: true,
  },
];
const categoryList = [
  {
    label: "Main",
    value: "main",
  },
  {
    label: "Song",
    value: "song",
  },
];
const statusList = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Non-active",
    value: "non-active",
  },
];
const approvalStatusList = [
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Waiting for Approval",
    value: "waiting_for_approval",
  },
  {
    label: "Waiting for Editing Approval",
    value: "waiting_for_editing_approval",
  },
];
const columnTable = [
  {
    name: "contract_number",
    title: "Contract Number",
    componentType: "text",
  },
  {
    name: "category",
    title: "Category",
    componentType: "text",
    renderText: item => textCapitalization(item, "category"),
  },
  {
    name: "songs",
    title: "Song Title",
    componentType: "chip",
    selectedKeyInside: "song_title",
  },
  {
    name: "composers",
    title: "Composer / Author",
    componentType: "text",
    selectedKeyInside: "sure_name",
  },
  {
    name: "start_date",
    title: "Start Date",
    componentType: "text",
    renderText: item =>
      item ? formatDate(moment(item, "DD-MM-YYYY HH:mm")) : "-",
  },
  {
    name: "end_date",
    title: "End Date",
    componentType: "text",
    renderText: item =>
      item ? formatDate(moment(item, "DD-MM-YYYY HH:mm")) : "-",
  },
  {
    name: "is_active_flag",
    title: "Contract Status",
    componentType: "text",
    renderText: status => (
      <StatusChip
        type={status && "success"}
        label={status ? "Active" : "Inactive"}
      />
    ),
  },
  {
    name: "updated_at",
    title: "Last Update",
    componentType: "text",
    renderText: item =>
      item ? formatDate(moment(item, "DD-MMM-YYYY")) : "-",
  },
  {
    name: "approval_status",
    title: "Approval Status",
    componentType: "text",
    renderText: status =>
      status === "waiting_for_approval"
        ? "Waiting"
        : approvalStatusList?.find(item => item.value === status)?.label || "-",
  },
];
export default OriginalPublisherContract;
