import {
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip as TooltipMUI,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import moment from "moment";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecordRounded";
import Pagination from "@material-ui/lab/Pagination";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { CSVLink } from "react-csv";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import {
  Page,
  PrimaryButton,
  SearchTextInput,
  SelectInput,
  YearPicker,
} from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import useDebounce from "./use-debounce";
import { HeaderTitle } from "layouts";
import { Box } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const ReviewLagu = () => {
  const history = useHistory();
  const [selectedCustomers] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [tableData, setTableData] = useState([]);
  const [optionDsp, setOptionDsp] = useState([]);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(1, "months"));
  const [openModalCal, setOpenModalCal] = useState(false);
  const [dspInfo, setDspInfo] = useState();
  const [optionOriginalPublisher, setOptionOriginalPublisher] = useState([]);
  const [dspId, setDspId] = useState(
    localStorage.getItem("idDsp") ? localStorage.getItem("idDsp") : ""
  );
  const [nameOfDsp] = useState("");
  const [publisherId, setPublisherId] = useState(
    localStorage.getItem("publisher_id") === null
      ? ""
      : localStorage.getItem("publisher_id")
  );
  const [typeRevenue] = useState(
    localStorage.getItem("type_revenue") === null
      ? ""
      : localStorage.getItem("type_revenue")
  );
  const [trafficListenerPerMonth] = useState(0);
  const [royaltyPerMonth] = useState(0);
  const [trafficListenerAll, setTrafficListenerAll] = useState(0);
  const [royaltyAll, setRoyaltyAll] = useState(0);
  const [getColor, setGetColor] = useState("");
  const [csvArray, setCsvArray] = useState([]);
  const [monthList] = useState([
    {
      id: 1,
      name: "January",
    },
    {
      id: 2,
      name: "February",
    },
    {
      id: 3,
      name: "March",
    },
    {
      id: 4,
      name: "April",
    },
    {
      id: 5,
      name: "May",
    },
    {
      id: 6,
      name: "June",
    },
    {
      id: 7,
      name: "July",
    },
    {
      id: 8,
      name: "August",
    },
    {
      id: 9,
      name: "September",
    },
    {
      id: 10,
      name: "October",
    },
    {
      id: 11,
      name: "November",
    },
    {
      id: 12,
      name: "December",
    },
  ]);
  const [monthName, setMonthName] = useState("");
  const [monthId, setMonthId] = useState(0);
  const [getMonth] = useState("-");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [getYear, setGetYear] = useState(
    localStorage.getItem("year") !== null ? localStorage.getItem("year") : ""
  );
  const [dspColor] = useState(
    localStorage.getItem("dspColor") === null
      ? "purple"
      : localStorage.getItem("dspColor")
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [sortValue, setSortValue] = useState("song_title");
  const [sortList] = useState([
    {
      id: 0,
      title: "Song Title ASC",
      value: "song_title",
    },
    {
      id: 1,
      title: "Song Title DSC",
      value: "song_title-",
    },
    {
      id: 2,
      title: "Revenue ASC",
      value: "revenue",
    },
    {
      id: 3,
      title: "Revenue DSC",
      value: "revenue-",
    },
  ]);
  const [dataSet, setDataSet] = useState({
    labels: [],
    datasets: [],
  });

  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const getOptionDashboard = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/association/publishers`;
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(url, headers);
      const { data } = res?.data;
      const modifiedData = [
        {
          publisher_id: "",
          name: "None",
        },
        ...data,
      ];
      setOptionOriginalPublisher(modifiedData);
    } catch (error) {
      new Error(error);
    }
  };
  const getOptionDsp = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/association/dsps`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { headers })
      .then(res => {
        setOptionDsp(res.data.data);
      })
      .catch(err => {
        new Error(err);
      });
  };
  const getChartData = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/association/dashboard?dsp_id=${dspId}&year=${getYear}&publisher_id=${publisherId}`;

      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(url, headers);
      let newFind = await _.find(res.data.data, function (o) {
        return o.publisher.publisher_id === Number(publisherId);
      });
      let newArrFind = [];
      newArrFind.push(newFind);
      setDspInfo(newFind.publisher.name);
      let tempSelectedChart = await _.find(newFind.chart, function (o) {
        return o.type === "total";
      });
      let getTotalListener = tempSelectedChart.listener;
      const sumEveryListener = getTotalListener;

      setTrafficListenerAll(formatter2.format(sumEveryListener));

      let getTotalRoyalty = tempSelectedChart.royalty;
      const sumEveryRoyalty = getTotalRoyalty;

      setRoyaltyAll(formatter.format(sumEveryRoyalty));

      const date = newFind.chart.map(data => data.date);

      const month = date.map(data => data.split("-")[1]);

      const year = date.map(data => data.split("-")[0]);
      const yearNow = getYear;
      const year2 = year.map(data => data.slice(-2));

      const getDateOld = monthCode => {
        const monthMap = {
          "01": "Jan",
          "02": "Feb",
          "03": "Mar",
          "04": "Apr",
          "05": "May",
          "06": "Jun",
          "07": "Jul",
          "08": "Aug",
          "09": "Sep",
          "10": "Oct",
          "11": "Nov",
          "12": "Dec",
        };
        return monthMap[monthCode] || "Jan";
      };

      let lengthOfData = newFind.chart.length;
      let last = lengthOfData - 1;
      const monthYearArray = month.map((data, index) => {
        let checkName = "";
        if (index === last) {
          checkName = `Total ${yearNow}`;
        } else {
          checkName = `${getDateOld(data)} ${year2[index]}`;
        }
        return checkName;
      });
      let resultFromType = [];
      if (typeRevenue === "revenue") {
        resultFromType = newFind.chart.map(data => {
          return data.royalty;
        });
      } else {
        resultFromType = newFind.chart.map(data => {
          return data.listener;
        });
      }

      const data = {
        labels: monthYearArray,
        datasets: [
          {
            label: dspInfo,
            data: resultFromType,
            borderColor: dspColor,
            backgroundColor: dspColor,
            fill: false,
            lineTension: 0.2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#54B371",
            pointBorderColor: "#54B371",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "#54B371",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
          },
        ],
      };
      setDataSet(data);
    } catch (error) {
      new Error(error);
    }
  };
  const getDataTable = async () => {
    try {
      setIsSearching(true);
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/publisher/song-usage?dsp_id=${dspId}&publisher_id=${publisherId}&month=${monthId}&year=${getYear}&page=${page}&size=${rowsPerPage}&sort=${sortValue}`;

      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(url, headers);
      setTotalData(res.data.meta.total);
      setPage(res.data.meta.page);
      const newData = res.data.data.map(item => ({
        ...item,
        year: getYear,
        data: [
          {
            listener: item.listener,
            royalty: item.royalty,
            time: item.time,
          },
        ],
      }));
      setTableData(newData);
      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);
    }
  };
  useEffect(() => {
    getOptionDsp();
  }, []);
  useEffect(() => {
    getChartData();
    getDataTable();
  }, [dspId, publisherId, getYear, monthId]);

  const options = {
    parsing: {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      delimiter: ",",
    },
    responsive: true,

    interaction: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },

    plugins: {
      datalabels: {
        formatter: function () {
          return "";
        },
      },
      layout: {},
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      subtitle: {
        display: false,
        text: "Custom Chart Subtitle",
      },
      tooltip: {},
    },
  };

  const getMainDsp = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/me`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setGetColor(res.data.data.publisher.theme_color);
    } catch (e) {
      new Error(e);
    }
  };

  useEffect(() => {
    getOptionDashboard();
    getDataTable();
    getChartData();
    getDataTable();
    getMainDsp();
  }, [rowsPerPage, page, searchKey]);

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const pageSizePagination = () => {
    const classes = useStyles();
    return (
      <Box className={classes.pageSize}>
        <select
          className={classes.textPagination}
          value={rowsPerPage}
          onChange={e => handleChangeRowsPerPage(e)}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </Box>
    );
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };

  const listPencipta = data => {
    let pencipta = data;
    const getRoleName = data => {
      if (data === "composer") {
        return "Composer";
      } else if (data === "author") {
        return "Author";
      } else if (data === "composer_author") {
        return "Composer/Author";
      }
    };

    if (pencipta === null) {
      return <Box>-</Box>;
    } else if (pencipta?.length <= 3) {
      return pencipta.map((pencipta, index) => (
        <TooltipMUI key={index} title={getRoleName(pencipta.role)}>
          <Chip
            label={pencipta.name}
            className={classes.tableChip}
            size="small"
          />
        </TooltipMUI>
      ));
    } else if (pencipta?.length > 3) {
      let pencipta1 = pencipta.slice(0, 3);
      let pencipta2 = pencipta.slice(3, pencipta?.length);
      let tempArr = [];

      pencipta2.map(item => {
        return tempArr.push(`${item.name} - ${getRoleName(item.role)}`);
      });
      return (
        <Box>
          {pencipta1.map((pencipta, index) => (
            <TooltipMUI key={index} title={getRoleName(pencipta.role)}>
              <Chip
                label={pencipta.name}
                className={classes.tableChip}
                size="small"
              />
            </TooltipMUI>
          ))}
          <TooltipMUI title={tempArr.join(", ")}>
            <Chip
              label={"+" + pencipta2?.length}
              className={classes.tableChip}
              size="small"
            />
          </TooltipMUI>
        </Box>
      );
    }
  };

  function searchCharacters(search) {
    setIsSearching(true);
    setSearchKey(search);
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    };
    return fetch(
      `${hardBaseUrl}/publisher/song-usage?dsp_id=${dspId}&publisher_id=${publisherId}&year=${getYear}&month=${monthId}&page=${page}&size=${rowsPerPage}&search=${search}&sort=${sortValue}`,
      requestOptions
    )
      .then(r => r.json())
      .then(r => {
        const newData = r.data.map(item => ({
          ...item,
          year: getYear,
          data: [
            {
              listener: item.listener,
              royalty: item.royalty,
              time: item.time,
            },
          ],
        }));
        setTableData(newData);
        setIsSearching(false);
      })
      .catch(() => {
        setIsSearching(false);
        return [];
      });
  }
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  useEffect(() => {
    if (debouncedSearchTerm) {
      searchCharacters(debouncedSearchTerm).then(() => {
        setIsSearching(false);
      });
    } else {
      searchCharacters(debouncedSearchTerm).then(() => {
        setIsSearching(false);
      });

      setIsSearching(false);
    }
  }, [debouncedSearchTerm]);
  function sortCharacters(sortValue) {
    setIsSearching(true);
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    };
    return fetch(
      `${hardBaseUrl}/publisher/song-usage?dsp_id=${dspId}&publisher_id=${publisherId}&year=${getYear}&month=${monthId}&page=${page}&size=${rowsPerPage}&search=${searchTerm}&sort=${sortValue}`,
      requestOptions
    )
      .then(r => r.json())
      .then(r => {
        const newData = r.data.map(item => ({
          ...item,
          year: getYear,
          data: [
            {
              listener: item.listener,
              royalty: item.royalty,
              time: item.time,
            },
          ],
        }));
        setTableData(newData);
        setIsSearching(false);
      })
      .catch(() => {
        setIsSearching(false);
        return [];
      });
  }
  const debouncedSortTerm = useDebounce(sortValue, 0);
  useEffect(() => {
    if (debouncedSortTerm) {
      sortCharacters(debouncedSortTerm).then(() => {
        setIsSearching(false);
      });
    } else {
      sortCharacters(debouncedSortTerm).then(() => {
        setIsSearching(false);
      });

      setIsSearching(false);
    }
  }, [debouncedSearchTerm]);
  useEffect(() => {
    getDataTable();
  }, [sortValue]);
  const handleChangeSort = event => {
    setSortValue(event.target.value);
    getDataTable();
  };
  const filterList = event => {
    setIsSearching(true);
    setSearchTerm(event.target.value);
  };


  const handleChangeSelectDsp = event => {
    setDspId(event.target.value);
  };

  const handleChangeSelectPublisher = event => {
    setPublisherId(event.target.value);
    localStorage.setItem("publisher_id", event.target.value);
  };

  const handleChangeYear = value => {
    setGetYear(value);
    localStorage.setItem("song_usage_year", value);
  };
  const handleChangeMonth = event => {
    if (event.target.value) {
      setMonthId(event.target.value);
    } else {
      setMonthId(0);
    }
    setMonthName(event.target.value);
  };
  const formatter = new Intl.NumberFormat("us-US", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  const formatter2 = new Intl.NumberFormat("us-US", {
    minimumFractionDigits: 0,
  });

  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    inputFilter: {
      width: "250px",
      height: "44px",
      border: "1px solid #D1D5DC",
      borderRadius: "6px",
      marginRight: "10px",
    },
    myCheckBox: {
      "&.MuiCheckbox-root": {
        color: "black",
        "&.Mui-checked": {
          color: "black",
        },
      },
    },
    scrollingCard: {
      "&::-webkit-scrollbar": {
        width: 1,
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "lightgrey",
        outline: "1px solid slategrey",
      },
    },
    divider: {
      margin: theme.spacing(2, 0),
      borderTop: "1px solid #e1e1e1",
    },
    card: {
      borderLeft: "1px solid gray",
      boxShadow: "none",
      borderRadius: "0px",
    },
    insideCard: {
      padding: "20px",
    },
    cardArea: {
      marginTop: "20px",
      marginBottom: "20px",
      justifyContent: "space-between",
    },
    filterArea: {
      marginTop: "20px",
      marginBottom: "20px",
      display: "flex",
    },
    chipArea: {
      marginTop: "20px",
      marginBottom: "20px",
    },
    textPagination: {
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      marginTop: "4px",
    },
    spotifyChip: {
      border: "1px solid #1db954",
      backgroundColor: "#ccffaa",
      color: "#1db954",
    },
    spotifyChipDot: {
      color: "#1db954",
    },
    tableChip: {
      marginRight: "10px",
      marginTop: "10px",
    },
    pagination: {
      "& .Mui-selected": {
        backgroundColor: getColor,
        color: "white",
      },
    },
    pageSize: {
      height: "30px",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
      paddingLeft: "5px",
      paddingRight: "5px",
      marginTop: "5px",
    },
    paginationText: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      color: "#687083",
    },
    textField: {
      width: "335px",
      height: "44px",
      backgroundColor: "white",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
    },
    textField2: {
      width: "249px",
      height: "44px",
      backgroundColor: "white",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
    },
    cellLoading: {
      height: "50px",
      textAlign: "center"
    },
    textFieldMonth: {
      width: "200px",
      height: "40px",
      backgroundColor: "white",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
    },
    cancel: {
      height: "40px",
      width: "90px",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
      color: "#111827",
      backgroundColor: "white",
      textTransform: "none",
      marginRight: "8px",
    },
    seeDetail: {
      color: "white",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "24px",
      textTransform: "none",
      whiteSpace: "nowrap",
    },
    textNoData: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "400",
      fontStyle: "normal",
      color: "#687083",
    },
    iconFiberManualRecordIcon: {
      width: "10px"
    },
    chipIconFiberManualRecordIcon: {
      marginRight: "10px"
    },
    apply: {
      height: "40px",
      width: "90px",
      borderRadius: "6px",
      border: "0px solid #D1D5DC",
      color: "white",
      backgroundColor: "#111827",
      textTransform: "none",
    },
    btnCariLaporan: {
      backgroundColor: "black",
      color: "white",
      width: "156px",
      height: "40px",
      marginTop: "25px",
      marginLeft: "10px",
      marginRight: "10px",

      textTransform: "none",
      borderRadius: "6px",
      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    headText: {
      fontSize: "12px",
      color: "#687083",
      fontWeight: "bold",
    },
    bodyTextBlack: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#111827",
      fontStyle: "normal",
    },
    bodyTextGrey: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#687083",
      fontStyle: "normal",
    },
    tableRow: {
      height: 20,
    },
    conTextBlack: {
      fontSize: "14px",
      fontWeight: "bold",
    },
    textTrend: {
      fontSize: "18px",
      lineHeight: "28px",
      fontWeight: "700",
      fontStyle: "normal",
    },

    dropdown: {
      color: "black",
    },
    subtitle: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "28px",
    },
    btnRincian: {
      backgroundColor: "black",
      color: "white",
      padding: "10px 28px",
      borderRadius: "6px",
      width: "140px",
      height: "36px",

      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    reportText: {
      fontSize: "14px",
      fontWeight: 500,
      color: "#687083",
      marginBottom: "15px",
      width: "300px",
    },
    reportValue: {
      fontSize: "24px",
      fontWeight: 500,
      color: "#111827",
    },
    breadcrumbs: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#111827",
    },
    breadCrumbsActive: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#687083",
    },
    containerFilter: {
      backgroundColor: "#F9FAFB",
    },
    label: {
      color: "#364052",
      fontSize: "14px",
      fontWeight: 500,
      fontStyle: "normal",
      lineHeight: "20px",
      marginBottom: "5px",
    },
    formControl: {
      height: "41px",
      backgroundColor: "#F7F7F7",
    },
    dialogActions: {
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: "24px",
      paddingRight: "24px",
    },
    typography: {
      fontSize: "14px",
      fontWeight: "400",
      color: "#687083",
    },
    filterFormColor: {
      backgroundColor: "#F7F7F7",
    },
    placeholder: {
      "&::placeholder": {
        color: "#9AA2B1",
      },
    },
    table: {
      border: "1px solid #D1D5DC",
      borderRadius: "6px",
    },
    excelbutton: {
      marginBottom: "5px",
      marginTop: "10px",
      marginLeft: "10px",
      backgroundColor: "#34774C",
      color: "white",
      width: "auto",
      height: "35px",
      "&:hover": {
        color: "white",
        backgroundColor: "#34774C",
      },
    },
  }));

  const getCsvArr = () => {
    const url = `${hardBaseUrl}/publisher/song-usage/download?year=${getYear}&dsp_id=${dspId}&size=${rowsPerPage}&page=${page}&publisher_id=${publisherId}&search&month=${monthId}`;
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { headers })
      .then(res => {
        if (res.data.data !== null) {
          setCsvArray(res.data.data);
        } else {
          setCsvArray([]);
        }
      })
      .catch(err => {
        new Error(err);
      });
  };
  useEffect(() => {
    getCsvArr();
  }, [getYear, dspId, publisherId, monthId, page, rowsPerPage]);
  const classes = useStyles();
  const onClickApplyDate = () => {
    setOpenModalCal(false);
  };
  const getDate = date => {
    return new Date(date).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };
  const handleDateChangeStartDate = date => {
    let formatTheDate = getDateFormatForFilter(date);
    setStartDate(formatTheDate);
  };
  const handleDateChangeEndDate = date => {
    let formatTheDate = getDateFormatForFilter(date);
    setEndDate(formatTheDate);
  };
  const getDateFormatForFilter = date => {
    let dateFormat = new Date(date);
    let day = dateFormat
      .getDate()
      .toString()
      .padStart(2, "0");
    let month = (dateFormat.getMonth() + 1).toString().padStart(2, "0");
    let year = dateFormat.getFullYear();
    let dateString = `${year}-${month}-${day}`;
    return dateString;
  };
  const moveToPageDownload = e => {
    history.push({
      pathname: "/admin/review-lagu-association/print-report",
      state: e.state,
    });
  };
  return (
    <Box className="review-lagu">
      <Page className={classes.root} title="Song Usage Review">
        <Container maxWidth={false}>
          <HeaderTitle title="Song Usage" breadcrumbData={breadcrumbData} />
          <Divider className={classes.divider} />
          <Card className={classes.containerFilter}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item>
                  <SelectInput
                    label="DSP"
                    value={dspId}
                    placeholder="None"
                    onChange={handleChangeSelectDsp}
                    options={optionDsp}
                    optionKey="dsp_id"
                    optionLabel="name"
                    width={300}
                  />
                </Grid>
                <Grid item>
                  <SelectInput
                    label="Publisher"
                    value={publisherId}
                    onChange={handleChangeSelectPublisher}
                    options={optionOriginalPublisher}
                    optionKey="publisher_id"
                    optionLabel="name"
                    width={350}
                  />
                </Grid>
                <Grid item>
                  <YearPicker
                    label="Year"
                    onChange={handleChangeYear}
                    value={getYear}
                    width={300}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Box margin="20px 0px">
            <ThemeProvider theme={theme}>
              <Typography
                component="span"
                className={classes.textTrend}
              >
                Trend of Song Usage
              </Typography>
            </ThemeProvider>
          </Box>
          <Grid container>
            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <Box border="1px solid #ebebeb" borderRadius="5px" padding="50px 20px">
                <Box display="flex" justifyContent="start" marginBottom="20px">
                  {nameOfDsp && (
                    <Chip
                      size="small"
                      label={nameOfDsp}
                      avatar={
                        <FiberManualRecordIcon
                          className={classes.iconFiberManualRecordIcon}
                          style={{ color: dspColor }}
                        />
                      }
                      className={classes.chipIconFiberManualRecordIcon}
                      style={{
                        color: dspColor,
                        backgroundColor: `${dspColor}10`,
                      }}
                    />)}
                </Box>
                <Bar options={options} data={dataSet} plugins={[]} />
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.cardArea}>
            <Grid container spacing={2}>
              <Grid item xs={3} md={3}>
                <Card className={classes.card}>
                  <Box className={classes.insideCard}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.reportText}
                    >{`${getMonth} traffic`}</Typography>
                    <Typography
                      component="h2"
                      variant="h3"
                      className={classes.reportValue}
                    >
                      {trafficListenerPerMonth}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={3} md={3}>
                <Card className={classes.card}>
                  <Box className={classes.insideCard}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.reportText}
                    >{`${getMonth} Revenue`}</Typography>
                    <Typography
                      component="h2"
                      variant="h3"
                      className={classes.reportValue}
                    >
                      {`${royaltyPerMonth},-`}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={3} md={3}>
                <Card className={classes.card}>
                  <Box className={classes.insideCard}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.reportText}
                    >
                      Total Traffic
                    </Typography>
                    <Typography
                      component="h2"
                      variant="h3"
                      className={classes.reportValue}
                    >
                      <NumberFormat
                        displayType="text"
                        value={trafficListenerAll}
                        thousandSeparator={true}
                        decimalScale={2}
                      />
                    </Typography>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={3} md={3}>
                <Card className={classes.card}>
                  <Box className={classes.insideCard}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.reportText}
                    >
                      Total Revenue
                    </Typography>
                    <Typography
                      component="h2"
                      variant="h3"
                      className={classes.reportValue}
                    >
                      <NumberFormat
                        displayType="text"
                        value={royaltyAll}
                        thousandSeparator={true}
                        decimalScale={2}
                        prefix="Rp "
                      />
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Box mb={2}>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid item>
                <SearchTextInput
                  placeholder="Song title, Composer"
                  value={searchTerm}
                  onChange={filterList}
                />
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <SelectInput
                      label="Month"
                      value={monthName}
                      placeholder="None"
                      onChange={handleChangeMonth}
                      options={monthList}
                      optionKey="id"
                      optionLabel="name"
                      width={200}
                    />
                  </Grid>
                  <Grid item>
                    <SelectInput
                      label="Sort"
                      value={sortValue}
                      placeholder="None"
                      onChange={handleChangeSort}
                      options={sortList}
                      optionKey="value"
                      optionLabel="title"
                      width={200}
                    />
                  </Grid>
                  <Grid item>
                    <PrimaryButton
                      height="40px"
                      label="Download PDF"
                      onClick={() =>
                        moveToPageDownload({
                          state: {
                            getMonth: getMonth,
                            getYear: getYear,
                            trafficListenerPerMonth: trafficListenerPerMonth,
                            trafficListenerAll: trafficListenerAll,
                            royaltyPerMonth: royaltyPerMonth,
                            royaltyAll: royaltyAll,
                            nameOfDsp: nameOfDsp,
                            chartData: dataSet,
                            tableData: tableData,
                            dspColor: dspColor,
                          },
                        })
                      }
                    />
                  </Grid>
                  <Grid item>
                    <CSVLink data={csvArray} filename="song_usage">
                      <PrimaryButton height="40px" label="Download CSV" />
                    </CSVLink>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <ThemeProvider theme={theme}>
                    <TableCell align="center"></TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Song Title
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        ISWC
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Composer/Author
                      </Typography>
                    </TableCell>

                    <TableCell align="right">
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Traffic
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                        align="right"
                      >
                        Revenue
                      </Typography>
                    </TableCell>
                  </ThemeProvider>
                </TableRow>
              </TableHead>
              <TableBody>
                {isSearching ? (
                  <>
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        className={classes.cellLoading}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {tableData?.length > 0 ? (
                      tableData.map(lagu => (
                        <TableRow
                          hover
                          key={lagu.song_id}
                          selected={
                            selectedCustomers.indexOf(lagu.song_id) !== -1
                          }
                        >
                          <TableCell align="center">
                            <>
                              <Button
                                variant="contained"
                                className={classes.btnRincian}
                                style={{
                                  backgroundColor: getColor,
                                }}
                                component={Link}
                                to={{
                                  pathname: `/admin/review-lagu-association/${lagu.song_id}`,
                                  state: {
                                    lagu: lagu,
                                    dsp_id: dspId,
                                  },
                                }}
                              >
                                <ThemeProvider theme={theme}>
                                  <Typography
                                    className={classes.seeDetail}
                                  >
                                    See Detail
                                  </Typography>
                                </ThemeProvider>{" "}
                              </Button>
                            </>
                          </TableCell>
                          <ThemeProvider theme={theme}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {lagu.title}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {lagu.iswc_code}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {listPencipta(lagu.composers)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                <NumberFormat
                                  displayType="text"
                                  value={lagu.listener}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                <NumberFormat
                                  displayType="text"
                                  value={lagu.base_currency_revenue}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  prefix="Rp "
                                />
                              </Typography>
                            </TableCell>
                          </ThemeProvider>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <ThemeProvider theme={theme}>
                              <Typography
                                component="span"
                                className={classes.textNoData}
                              >
                                No Data
                              </Typography>
                            </ThemeProvider>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
            <Box className={classes.paginationArea}>
              {tableData?.length > 0 && (
                <Grid
                  alignItems="flex-end"
                  container
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Grid item>
                    <ThemeProvider theme={theme}>
                      <Box display="flex">
                        <Box marginTop="7px">
                          <Typography className={classes.paginationText}>
                            Item per halaman:
                          </Typography>
                        </Box>
                        <Box marginLeft="10px">
                          {pageSizePagination()}
                        </Box>
                      </Box>
                    </ThemeProvider>
                  </Grid>
                  <Grid item>
                    <Pagination
                      shape="rounded"
                      className={classes.pagination}
                      page={page}
                      count={pagesCount}
                      onChange={handleChangePage}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        </Container>
        <Dialog
          open={openModalCal}
          maxWidth={"xl"}
          onClose={() => setOpenModalCal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <Box display="flex">
              <DatePicker
                disableToolbar
                autoOk
                orientation="portrait"
                variant="static"
                openTo="date"
                value={startDate}
                onChange={handleDateChangeStartDate}
              />
              <DatePicker
                disableToolbar
                autoOk
                orientation="portrait"
                variant="static"
                openTo="date"
                value={endDate}
                minDate={startDate}
                onChange={handleDateChangeEndDate}
              />
            </Box>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <ThemeProvider theme={fontInter}>
              <Typography className={classes.typography}>
                {`${getDate(startDate)} - ${getDate(endDate)}`}
              </Typography>
            </ThemeProvider>
            <Button
              onClick={() => setOpenModalCal(false)}
              className={classes.cancel}
            >
              Cancel
            </Button>
            <Button
              onClick={onClickApplyDate}
              className={classes.apply}
            >
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </Page>
    </Box>
  );
};
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard-association",
  },
  {
    label: "Song Usage",
    active: true,
  },
];

export default ReviewLagu;
