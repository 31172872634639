import { FormLabel, CheckboxListWithSearchMenu } from "../../../components";
import { InputAdornment, makeStyles, OutlinedInput } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { useState } from "react";

const CheckboxSelectInput = ({
  label,
  required,
  labelClass,
  disabled,
  inputClass,
  value,
  placeholder,
  startAdornment,
  endAdornment,
  arrayChip,
  loadingState,
  isSearchFilter,
  onChangeSearch,
  searchValue,
  searchPlaceholder,
  selectionsList,
  selectionValue,
  selectionLabel,
  selectionKey,
  onChangeCheckbox,
  width,
  checkboxLabel,
}) => {
  const classes = useStyles({ width });
  const [filterAnchor, setFilterAnchor] = useState(null);
  const handleShowMenu = event => setFilterAnchor(event.currentTarget);
  const handleCloseMenu = () => setFilterAnchor(null);

  return (
    <div>
      {label && (
        <FormLabel label={label} required={required} labelClass={labelClass} />
      )}
      <OutlinedInput
        readOnly
        disabled={disabled}
        variant="outlined"
        onClick={!disabled ? handleShowMenu : null}
        fullWidth
        className={inputClass ?? classes?.textField}
        value={value}
        placeholder={placeholder}
        startAdornment={startAdornment}
        endAdornment={
          <InputAdornment position="end" className={classes?.cursorPointer}>
            {endAdornment && endAdornment}
            {filterAnchor ? (
              <ArrowDropUp onClick={!disabled ? handleCloseMenu : null} />
            ) : (
              <ArrowDropDown onClick={!disabled ? handleShowMenu : null} />
            )}
          </InputAdornment>
        }
      />
      {filterAnchor && (
        <CheckboxListWithSearchMenu
          filterAnchor={filterAnchor}
          handleCloseMenu={handleCloseMenu}
          arrayChip={arrayChip}
          loadingState={loadingState}
          isSearchFilter={isSearchFilter}
          onChangeSearch={onChangeSearch}
          searchValue={searchValue}
          searchPlaceholder={searchPlaceholder}
          selectionsList={selectionsList}
          selectionValue={selectionValue}
          selectionLabel={selectionLabel}
          selectionKey={selectionKey}
          onChangeCheckbox={onChangeCheckbox}
          width={width}
          checkboxLabel={checkboxLabel}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  textField: props => ({
    width: props?.width,
    color: "#9AA2B1",
    "&::placeholder": {
      color: "#9AA2B1",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 0 10.5px 14px",
    },
    "&.Mui-disabled .MuiOutlinedInput-input": {
      color: "red",
    },
  }),
}));
export default CheckboxSelectInput;
