import React from "react";
import BigLogoMpis from "../../../assets/img/newMpisLogo.webp";
import BigLogoCaris from "../../../assets/img/newCarisLogo.webp";
import CircularProgress from "@material-ui/core/CircularProgress";

const SplashScreen = () => {
  const typeWeb = localStorage.getItem("typeWeb") || "mpis";
  const splashScreenImage = () => {
    if (typeWeb === "mpis") {
      return (
        <img
          src={BigLogoMpis}
          alt="logo"
          style={{
            marginBottom: "50px",
          }}
        />
      );
    } else {
      return (
        <img
          src={BigLogoCaris}
          alt="logo"
          style={{
            marginBottom: "50px",
            width: "1000px",
          }}
        />
      );
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {splashScreenImage()}
        <CircularProgress />
      </div>
    </div>
  );
};

export default SplashScreen;
