import {
  Button,
  ButtonGroup,
  Container,
  Divider,
  Typography,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import axios from "axios";
import { debounce, isEqual } from "lodash";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import {
  ModalError,
  ArrayChip,
  Page,
  TableWithTitle,
  CurrencyDisplay,
  ArrayTagChip,
  YearPicker,
  PrimaryButton,
  MultipleSelectInputWithTags,
  SelectInput,
  SongUsageTrendChart,
  SkeletonComponent,
} from "components";
import { hardBaseUrl, hardTypeWeb } from "../../../services/urlConstant";
import { getCookie } from "../../../utils/constants";
import { chartStyles } from "./style";
import "./style.scss";
import {
  arrayToCommaSeparatedString,
  createAllFilterOption,
  fetchAndFilterOptions,
  filterArrayByKeyValue,
  getErrors,
  handleChangeFilterAllAfterSearch,
} from "utils";
import { HeaderTitle } from "layouts";
import DashboardTable from "./Components/DashboardTable";
import { Box, Grid } from "@mui/material";
import moment from "moment-timezone";
import { InnoTableV2 } from "inno-ui";
import { getTotalPage } from "lib";
const useStyles = chartStyles;

function Dashboard() {
  const classes = useStyles();
  const history = useHistory();
  const userRole = localStorage?.getItem("role");
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const roleSociety = userRole === "society";
  const rolePublisher = userRole === "publisher";
  const isCaris = hardTypeWeb === "caris";
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [selectedMenuChart, setSelectedMenuChart] = useState(1);
  const [optionDsp, setOptionDsp] = useState([]);
  const [optionPublisher, setOptionPublisher] = useState([]);
  const [optionOriginalPublisher, setOptionOriginalPublisher] = useState([]);
  const [optionTypeRevenue, setOptionTypeRevenue] = useState();
  const [selectedDSP, setSelectedDSP] = useState([]);
  const [selectedPublisher, setSelectedPublisher] = useState([]);
  const [selectedOriginalPublisher, setSelectedOriginalPublisher] = useState(
    []
  );
  const [selectedRevenue, setSelectedRevenue] = useState([]);
  const [queryParams, setQueryParams] = useState({
    dsp_id: "",
    year: 0,
    ...(isCaris && {
      revenue_type: "all",
    }),
    ...(roleSociety && {
      right_type_id: "",
    }),
  });
  const [tableParams, setTableParams] = useState({
    page: 1,
    size: 10,
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [optionDspParams, setOptionDspParams] = useState({
    page: 1,
    search: "",
  });
  const [optionPublisherParams, setOptionPublisherParams] = useState({
    page: 1,
    search: "",
  });
  const [
    optionOriginalPublisherParams,
    setOptionOriginalPublisherParams,
  ] = useState({
    publisher_id: "",
    page: 1,
    size: 10,
    search: "",
  });
  const [tableDashboard, setTableDashboard] = useState([]);
  const [tableTraffic, setTableTraffic] = useState([]);
  const [tableRevenue, setTableRevenue] = useState([]);
  const [tableDataComposer, setTableDataComposer] = useState([]);

  const [getMainDspId, setGetMainDspId] = useState(null);
  const [publisherName, setPublisherName] = useState("");
  const [csvArray, setCsvArray] = useState([]);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const prevDspPagination = usePrevious(optionDspParams.search);
  const prevPublisherPagination = usePrevious(optionPublisherParams.search);
  const prevOriginalPublisherPagination = usePrevious(
    optionOriginalPublisherParams.search
  );
  const societyPublisher = selectedMenuChart === 1;

  const loadReminder = () => {
    const role = userLogin?.role?.type;
    const reminder = localStorage.getItem("reminder");
    if (reminder !== null) {
      if (hardTypeWeb === "mpis") {
        if (role !== "publisher") {
          localStorage.removeItem("reminder");
        }
      }
    }
  };
  const loadMainDSP = async () => {
    if (userLogin !== null) {
      if (userLogin.publisher !== null) {
        setGetMainDspId(userLogin.publisher.publisher_id);
        setPublisherName(userLogin.publisher.name);
        localStorage.setItem("mainPublisher", userLogin.publisher);
      }
    }
  };
  const handleRincian = (e, customer) => {
    e.preventDefault();
    const { dsp_id, name, color } = customer?.dsp;
    localStorage.setItem("idDsp", dsp_id);
    localStorage.setItem("year", queryParams.year);
    localStorage.setItem("mainDsp", getMainDspId);
    localStorage.setItem("dspName", name);
    localStorage.setItem("dspColor", color);
    window.location.href = "/admin/review-lagu";
  };
  const handleRincianComp = (e, compId, compName) => {
    e.preventDefault();
    localStorage.setItem("compId", compId);
    localStorage.setItem("compName", compName);
    window.location.href = "/admin/review-lagu";
  };
  const deleteLocalStorageDSP = () => {
    localStorage?.removeItem("local_dsp_selected");
  };
  const goToComposerTrendPage = e => {
    e.preventDefault();
    history.push({
      pathname: "/admin/composer-trend",
      state: { dsp_id: queryParams.dsp_id },
    });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeTableParams = (value, key) => {
    setTableParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleChangeFilterDSP = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedDSP(
      handleChangeFilterAllAfterSearch({
        selectedList: selectedDSP,
        checked,
        option,
        key: "dsp_id",
        list: optionDsp,
        handleChangeQueryParams,
        params: optionDspParams,
      })
    );
  };
  const handleSearchFilterDSP = value => {
    setOptionDspParams(prev => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };
  const handleChangeFilterPublisher = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedPublisher(
      handleChangeFilterAllAfterSearch({
        selectedList: selectedPublisher,
        checked,
        option,
        key: "publisher_id",
        list: optionPublisher,
        handleChangeQueryParams,
        params: optionPublisherParams,
      })
    );
  };
  const handleSearchFilterPublisher = value => {
    setOptionPublisherParams(prev => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };
  const handleChangeFilterOriginalPublisher = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedOriginalPublisher(
      handleChangeFilterAllAfterSearch({
        selectedList: selectedOriginalPublisher,
        checked,
        option,
        key: "original_publisher_id",
        list: optionOriginalPublisher,
        handleChangeQueryParams: handleChangeQueryParams,
        params: optionPublisherParams,
      })
    );
  };
  const handleSearchFilterOriginalPublisher = value => {
    setOptionOriginalPublisherParams(prev => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };
  const handleChangeFilterRevenue = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedRevenue(prev => {
      const isAllOption = option?.right_type_id === "all";
      const selectedFilter = checked
        ? [...prev, option]
        : filterArrayByKeyValue(prev, option, "right_type_id");

      if (isAllOption) {
        if (checked) {
          const filterParams = arrayToCommaSeparatedString(
            optionTypeRevenue.filter(item => item?.right_type_id !== "all"),
            "right_type_id"
          );
          handleChangeQueryParams(filterParams, "right_type_id");
          return [...optionTypeRevenue];
        } else {
          handleChangeQueryParams("", "right_type_id");
          return [];
        }
      } else {
        const filteredWithoutAll = selectedFilter.filter(
          item => item?.right_type_id !== "all"
        );
        const allOptionsSelected = optionTypeRevenue
          .filter(item => item?.right_type_id !== "all")
          .every(opt =>
            filteredWithoutAll.some(
              selected => selected.right_type_id === opt.right_type_id
            )
          );
        const finalSelection = allOptionsSelected
          ? [...filteredWithoutAll, { right_type_id: "all" }]
          : filteredWithoutAll;
        const filterParams = arrayToCommaSeparatedString(
          finalSelection.filter(item => item?.right_type_id !== "all"),
          "right_type_id"
        );
        handleChangeQueryParams(filterParams, "right_type_id");

        return finalSelection;
      }
    });
  };

  const debounceOptionDSP = useCallback(
    debounce(() => {
      fetchAndFilterOptions({
        headers,
        endpoint: "/dashboard/options/dsp",
        params: optionDspParams,
        label: "name",
        key: "dsp_id",
        setLoading: setLoadingFilter,
        paramsCondition: optionDspParams.search,
        prevPagination: prevDspPagination,
        setOptions: setOptionDsp,
        selectedOptions: selectedDSP,
        setSelectedOptions: setSelectedDSP,
      });
    }, 500),
    [optionDspParams]
  );
  const getPublisherList = async () => {
    try {
      setLoadingFilter(true);
      const res = await axios.get(
        `${hardBaseUrl}/dashboard/options/publisher`,
        {
          headers,
          params: optionPublisherParams,
        }
      );
      const { data } = res?.data;
      const paramsCondition = optionPublisherParams.search;
      const updatedOptionsList = paramsCondition
        ? data
        : [
            createAllFilterOption({ label: "name", key: "publisher_id" }),
            ...data,
          ];
      setOptionPublisher(updatedOptionsList);

      if (!isEqual(prevPublisherPagination, paramsCondition)) {
        if (!selectedPublisher.some(item => item?.publisher_id === "all")) {
          const filteredOptions = selectedPublisher.filter(
            item => item?.publisher_id !== "all"
          );
          setSelectedPublisher(filteredOptions);
        }
      }
      setGetMainDspId(data?.[0]?.original_publisher_id);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingFilter(false);
    }
  };
  const debounceOptionPublisher = useCallback(
    debounce(() => {
      getPublisherList();
    }, 500),
    [optionPublisherParams]
  );
  const getOriginalPublisherList = () =>
    fetchAndFilterOptions({
      headers,
      endpoint: "/dashboard/options/original_publisher",
      params: optionOriginalPublisherParams,
      label: "name",
      key: "original_publisher_id",
      setLoading: setLoadingFilter,
      paramsCondition: optionOriginalPublisherParams.search,
      prevPagination: prevOriginalPublisherPagination,
      setOptions: setOptionOriginalPublisher,
      selectedOptions: selectedOriginalPublisher,
      setSelectedOptions: setSelectedOriginalPublisher,
    });
  const debounceOptionOriginalPublisher = useCallback(
    debounce(() => {
      getOriginalPublisherList();
    }, 500),
    [optionOriginalPublisherParams]
  );
  const getChartData = async () => {
    const url = `${hardBaseUrl}/publisher/dashboard/chart`;
    const options = {
      headers,
      params: queryParams,
    };
    try {
      setLoadingPage(true);
      const res = await axios.get(url, options);
      const { data } = res?.data || [];

      if (data?.length > 0) {
        const labels = data[0].chart.map(item =>
          moment(item.date).format("MMM YY")
        );
        const datasets = data.map(item => ({
          label: item?.dsp?.name,
          data: item?.chart.map(chartItem => chartItem?.base_currency_revenue),
          listener: item?.chart.map(chartItem => chartItem?.listener),
          color: item?.dsp?.color,
          showMark: false,
        }));
        setChartData({
          labels,
          datasets,
        });
      } else {
        setChartData({
          labels: [],
          datasets: [],
        });
      }
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getDashboardTableData = async () => {
    const url = `${hardBaseUrl}/publisher/dashboard`;
    const options = {
      headers,
      params: queryParams,
    };
    try {
      setLoadingPage(true);
      const res = await axios.get(url, options);
      setTableDashboard(res?.data?.data || []);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getSongUsage = async () => {
    const url = `${hardBaseUrl}/publisher/song-usage`;
    const optionsTraffic = {
      headers,
      params: { ...queryParams, ...tableParams, sort: "stream-" },
    };
    const optionsRevenue = {
      headers,
      params: { ...queryParams, ...tableParams, sort: "revenue-" },
    };
    try {
      setLoadingPage(true);
      const resTraffic = await axios.get(url, optionsTraffic);
      const resRevenue = await axios.get(url, optionsRevenue);
      const resDataTraffic = resTraffic?.data?.data;
      const resDataRevenue = resRevenue?.data?.data;
      setTableTraffic(resDataTraffic);
      setTableRevenue(resDataRevenue);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getTableTrendComposer = async () => {
    const url = `${hardBaseUrl}/publisher/trend-of-using-composer-songs`;
    const options = {
      headers,
      params: { ...queryParams, ...tableParams, composer_id: "none" },
    };
    try {
      const res = await axios.get(url, options);
      const resData = res?.data?.data;
      setTableDataComposer(resData);
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
      ModalError(error);
    }
  };
  const getCsvArray = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/dashboard/download`,
        {
          headers,
          params: queryParams,
        }
      );
      setCsvArray(res?.data?.data || []);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getSocietyReport = async () => {
    const url = `${hardBaseUrl}/dashboard/report/${
      societyPublisher ? "publisher" : "composer"
    }`;
    const { right_type_id, year, dsp_id } = queryParams;
    const options = {
      headers,
      params: {
        ...tableParams,
        right_type_id,
        year,
        dsp_id,
      },
    };
    try {
      setLoadingPage(true);
      const res = await axios.get(url, options);
      const { data, meta } = res?.data;
      if (data?.length > 0) {
        const labels = data[0].chart.map(item =>
          moment(item.date).format("MMM YY")
        );
        const modifiedData = data?.map(item => ({
          ...item,
          color: getRandomColor(),
        }));
        setTableDashboard(modifiedData || []);
        const pageCount = getTotalPage(meta?.total, tableParams?.size || 1);
        setTableTotalPage(pageCount);
        const datasets = modifiedData.map(item => ({
          label: societyPublisher ? item?.publisher_name : item?.composer_name,
          data: item?.chart.map(chartItem => chartItem?.base_currency_revenue),
          listener: item?.chart.map(chartItem => chartItem?.traffic),
          color: item?.color,
          showMark: false,
        }));
        setChartData({
          labels,
          datasets,
        });
      } else {
        setChartData({
          labels: [],
          datasets: [],
        });
      }
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getOptionRevenue = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/right_types `, { headers });
      const { data } = res?.data;
      const updatedOptionsList = [
        createAllFilterOption({
          label: "right_type_name",
          key: "right_type_id",
        }),
        ...data?.map(item => ({
          ...item,
          right_type_id: item?.id,
        })),
      ];
      setOptionTypeRevenue(updatedOptionsList);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  const filterConfig = [
    ...(isCaris
      ? [revenueTypeFilterConfig(queryParams, handleChangeQueryParams)]
      : []),
    ...(roleSociety
      ? [
          revenueSocietyFilterConfig(
            optionTypeRevenue,
            selectedRevenue,
            handleChangeFilterRevenue
          ),
        ]
      : []),
    dspFilterConfig({
      value: selectedDSP,
      options: optionDsp,
      onChange: handleChangeFilterDSP,
      params: optionDspParams,
      onChangeSearch: handleSearchFilterDSP,
    }),
    ...(!roleSociety
      ? [
          publisherFilterConfig({
            value: selectedPublisher,
            options: optionPublisher,
            onChange: handleChangeFilterPublisher,
            params: optionPublisherParams,
            onChangeSearch: handleSearchFilterPublisher,
            rolePublisher,
          }),
          originalPublisherFilterConfig({
            value: selectedOriginalPublisher,
            options: optionOriginalPublisher,
            onChange: handleChangeFilterOriginalPublisher,
            params: optionOriginalPublisherParams,
            onChangeSearch: handleSearchFilterOriginalPublisher,
            watchParams: selectedPublisher,
          }),
        ]
      : []),
    yearFilterConfig(queryParams, handleChangeQueryParams),
  ];

  useEffect(() => {
    loadMainDSP();
    loadReminder();
    deleteLocalStorageDSP();
    if (roleSociety) {
      getOptionRevenue();
    }
  }, []);
  useEffect(() => {
    if (roleSociety) {
      getSocietyReport();
    } else {
      getChartData();
      getDashboardTableData();
      getSongUsage();
      getCsvArray();
    }
    if (queryParams?.publisher_id) {
      setOptionOriginalPublisherParams(prev => ({
        ...prev,
        publisher_id: queryParams?.publisher_id,
      }));
    }
    if (isCaris) {
      getTableTrendComposer();
    }
  }, [queryParams, selectedMenuChart, tableParams]);
  useEffect(() => {
    debounceOptionDSP();
    return () => {
      debounceOptionDSP.cancel();
    };
  }, [optionDspParams, debounceOptionDSP]);
  useEffect(() => {
    if (!roleSociety) {
      debounceOptionPublisher();
      return () => {
        debounceOptionPublisher.cancel();
      };
    }
  }, [optionPublisherParams, debounceOptionPublisher]);
  useEffect(() => {
    if (optionOriginalPublisherParams?.publisher_id) {
      if (optionOriginalPublisherParams?.search) {
        debounceOptionOriginalPublisher();
        return () => {
          debounceOptionOriginalPublisher.cancel();
        };
      } else {
        getOriginalPublisherList();
      }
    }
  }, [
    optionOriginalPublisherParams?.publisher_id,
    optionOriginalPublisherParams,
    debounceOptionOriginalPublisher,
  ]);
  useEffect(() => {
    if (!selectedPublisher.length) {
      setSelectedOriginalPublisher([]);
      return;
    }

    const isPaginationEqual = isEqual(
      prevOriginalPublisherPagination,
      optionOriginalPublisherParams?.search
    );

    if (!isPaginationEqual) return;

    if (!optionOriginalPublisherParams?.search) {
      const filteredOptions = optionOriginalPublisher.filter(
        item => item?.original_publisher_id !== "all"
      );
      const allOptionsSelected = filteredOptions.every(option =>
        selectedOriginalPublisher.some(
          selected =>
            selected?.original_publisher_id === option?.original_publisher_id
        )
      );
      const updatedSelected = selectedOriginalPublisher
        .filter(item => item?.original_publisher_id !== "all")
        .filter(item =>
          filteredOptions.some(
            option =>
              option.original_publisher_id === item.original_publisher_id
          )
        );
      setSelectedOriginalPublisher(
        allOptionsSelected
          ? [...updatedSelected, { original_publisher_id: "all" }]
          : updatedSelected
      );
    }
  }, [
    selectedPublisher,
    optionOriginalPublisherParams,
    optionOriginalPublisher,
  ]);

  return (
    <Page
      className={classes.root}
      title="Dashboard"
      data-testid="data-test-page-dashboard"
    >
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title={`Dashboard ${publisherName && " | " + publisherName}`}
            breadcrumbData={breadcrumbData}
          />
          <Divider className={classes?.divider} />
          {!roleSociety && (
            <Typography variant="h4" gutterBottom>
              Trend of Song Usage
            </Typography>
          )}
          <Grid container justifyContent="space-between" columnSpacing={1}>
            {roleSociety && (
              <Grid item>
                <ButtonGroup>
                  {menuChart?.map(({ title, id }) => {
                    const selected = id === selectedMenuChart;
                    return (
                      <Button
                        className={
                          selected
                            ? classes.groupButtonSelected
                            : classes?.groupButton
                        }
                        key={id}
                        onClick={() => {
                          setSelectedMenuChart(id);
                        }}
                      >
                        {title}
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </Grid>
            )}
            <Grid item>
              <Grid
                container
                columnSpacing={1}
                direction="row"
                justifyContent={roleSociety ? "end" : "start"}
              >
                {filterConfig?.map((item, index) => (
                  <Grid item key={index} maxWidth={220}>
                    {item?.type === "checkbox" ? (
                      <MultipleSelectInputWithTags
                        label={item?.label}
                        placeholder={item?.placeholder}
                        value={item?.value}
                        textValue={item?.textValue}
                        options={item?.options}
                        optionKey={item?.optionKey}
                        optionLabel={item?.optionLabel}
                        onChange={item?.onChange}
                        handleRemove={item?.handleRemove}
                        search={item?.search}
                        searchValue={item?.searchValue}
                        onChangeSearch={item?.onChangeSearch}
                        loading={loadingFilter}
                        disabled={item?.disabled || loadingFilter}
                        group={item?.group}
                      />
                    ) : item?.type === "yearPicker" ? (
                      <YearPicker
                        label={item?.label}
                        onChange={item?.onChange}
                        value={item?.value}
                      />
                    ) : (
                      <SelectInput
                        label={item?.label}
                        value={item?.value}
                        placeholder={item?.placeholder}
                        onChange={item?.onChange}
                        options={item?.options}
                        optionKey={item?.optionKey}
                        optionLabel={item?.optionLabel}
                        width={180}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Box my={3}>
            <div className={classes?.chartCard}>
              <Box mb={2}>
                {chartData?.datasets.length > 0 && selectedDSP?.length > 0 && (
                  <ArrayTagChip
                    selectionsList={
                      selectedDSP.some(item => item.dsp_id === "all")
                        ? [{ dsp_id: "all", name: "All" }]
                        : selectedDSP
                    }
                    value="name"
                  />
                )}
              </Box>
              <Box width="100%">
                <SongUsageTrendChart chartData={chartData} />
              </Box>
            </div>
          </Box>
          {!roleSociety && (
            <Grid container spacing={1} justifyContent="flex-end" my={2}>
              <Grid item>
                <PrimaryButton
                  label="Download PDF"
                  onClick={() =>
                    history.push({
                      pathname: "/admin/dashboard/print-report",
                      state: {
                        chartData: chartData,
                        selectedDSP: selectedDSP,
                        tableData: tableDashboard,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item>
                <CSVLink data={csvArray} filename="dashboard">
                  <PrimaryButton label="Download CSV" />
                </CSVLink>
              </Grid>
            </Grid>
          )}
          {roleSociety ? (
            <InnoTableV2
              isLoading={false}
              columns={columnTableSociety(societyPublisher, handleRincianComp)}
              items={tableDashboard}
              page={tableParams?.page}
              rowsPerPage={tableParams?.size}
              totalPage={tableTotalPage}
              handleChangePage={(_, page) =>
                handleChangeTableParams(page, "page")
              }
              handleChangeRowsPerPage={e =>
                handleChangeTableParams(e?.target?.value, "size")
              }
            />
          ) : (
            <Fragment>
              <DashboardTable
                handleRincian={handleRincian}
                tableDashboard={tableDashboard}
              />
              <TableWithTitle
                label="Top 10 Chart Song Based On Traffic"
                columns={columnTableTopTen(classes)}
                items={tableTraffic}
                isLoading={false}
              />
              <TableWithTitle
                label="Top 10 Chart Song Based On Revenue"
                columns={columnTableTopTen(classes)}
                items={tableRevenue}
                isLoading={false}
              />
            </Fragment>
          )}
          {isCaris && (
            <>
              <TableWithTitle
                label="Trend of Using Composer/Author Songs"
                columns={columnTableTrendComposer}
                isHaveAction={true}
                renderAction={item => (
                  <Grid container columnSpacing={1}>
                    <PrimaryButton
                      label="See Details"
                      onClick={e => handleRincianComp(e, item)}
                      width={107}
                    />
                    <Box bgcolor={item?.color} height={20} width={20} />
                  </Grid>
                )}
                items={tableDataComposer}
                isLoading={false}
              />
              <Grid
                container
                justifyContent="flex-end"
                className={classes?.marginVertical20}
              >
                <Grid item>
                  <Button
                    variant="text"
                    onClick={e => {
                      goToComposerTrendPage(e);
                    }}
                    className={classes?.buttonSeeMore}
                    endIcon={<ArrowForwardIcon />}
                  >
                    See More
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      )}
    </Page>
  );
}

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Dashboard",
    active: true,
  },
];
const optionRevenue = [
  {
    value: "all",
    name: "All Revenue",
  },
  {
    value: "publisher",
    name: "Publisher Revenue",
  },
  {
    value: "composer",
    name: "Composer Revenue",
  },
];
const menuChart = [
  {
    id: 1,
    title: "Publisher",
  },
  {
    id: 2,
    title: "Author/Composer",
  },
];
const columnTableTopTen = classes => [
  {
    name: "title",
    title: "Song Title",
  },
  {
    name: "all",
    title: "Composer/Author",
    renderText: item => {
      const listValue = item.composers?.map(
        ({ name, is_on_this_publisher }) => ({
          name,
          className: is_on_this_publisher ? classes?.highlightText : "",
        })
      );
      return <ArrayChip list={listValue} />;
    },
  },
  {
    name: "listener",
    title: "Traffic",
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "base_currency_revenue",
    title: "Revenue",
    renderText: item => (
      <CurrencyDisplay value={item} decimalScale={2} prefix="Rp " />
    ),
  },
];
const columnTableTrendComposer = [
  {
    name: "composer_name",
    title: "Composer/Author",
  },
  {
    name: "listener",
    title: "Traffic",
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "royalty",
    title: "Revenue",
    renderText: item => (
      <CurrencyDisplay value={item} decimalScale={2} prefix="Rp " />
    ),
  },
  {
    name: "advance",
    title: "Advance Composer/Author",
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "balance",
    title: "Balance",
    renderText: item => (
      <CurrencyDisplay value={item} decimalScale={2} prefix="Rp " />
    ),
  },
];
const columnTableSociety = (societyPublisher, handleRincianComp) => [
  {
    name: "all",
    title: "",
    renderText: item => (
      <Grid container columnSpacing="16px" alignItems="center">
        <Grid item>
          <PrimaryButton
            label="See Details"
            onClick={e => handleRincianComp(e, item)}
            width={107}
          />
        </Grid>
        <Grid item>
          <Box bgcolor={item?.color} height={20} width={20} />
        </Grid>
      </Grid>
    ),
  },
  {
    name: societyPublisher ? "publisher_name" : "composer_name",
    title: societyPublisher ? "Publisher" : "Composer",
  },
  {
    name: "traffic",
    title: "Traffic",
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "base_currency_revenue",
    title: "Revenue",
    renderText: item => (
      <CurrencyDisplay value={item} decimalScale={2} prefix="Rp " />
    ),
  },
  {
    name: "advance_revenue",
    title: "Advance DSP",
    renderText: item => (
      <CurrencyDisplay value={item} decimalScale={2} prefix="Rp " />
    ),
  },
];
const revenueTypeFilterConfig = (queryParams, handleChangeQueryParams) => ({
  value: queryParams?.revenue_type,
  options: optionRevenue,
  optionKey: "value",
  optionLabel: "name",
  onChange: event =>
    handleChangeQueryParams(event?.target?.value, "revenue_type"),
});
const revenueSocietyFilterConfig = (
  optionTypeRevenue,
  selectedRevenue,
  handleChangeFilterRevenue
) => ({
  // label: "Revenue",
  textValue: "Revenue",
  value: selectedRevenue,
  // textValue: arrayToCommaSeparatedString(selectedMonthFilter, "shortName"),
  options: optionTypeRevenue,
  optionKey: "right_type_id",
  optionLabel: "right_type_name",
  onChange: handleChangeFilterRevenue,
  type: "checkbox",
});
const dspFilterConfig = ({
  value,
  options,
  onChange,
  params,
  onChangeSearch,
}) => ({
  textValue: "DSP",
  value,
  options,
  optionKey: "dsp_id",
  optionLabel: "name",
  onChange,
  search: true,
  onChangeSearch,
  searchValue: params?.search,
  type: "checkbox",
});
const publisherFilterConfig = ({
  value,
  options,
  onChange,
  params,
  onChangeSearch,
  rolePublisher,
}) => ({
  textValue: "Publisher",
  value,
  options,
  optionKey: "publisher_id",
  optionLabel: "name",
  onChange,
  search: true,
  onChangeSearch,
  searchValue: params?.search,
  type: "checkbox",
  group: rolePublisher,
});
const originalPublisherFilterConfig = ({
  value,
  options,
  onChange,
  params,
  onChangeSearch,
  watchParams,
}) => ({
  textValue: "Original Publisher",
  value,
  options,
  optionKey: "original_publisher_id",
  optionLabel: "name",
  onChange,
  search: true,
  onChangeSearch,
  searchValue: params?.search,
  type: "checkbox",
  disabled: watchParams?.length === 0,
});
const yearFilterConfig = (queryParams, handleChangeQueryParams) => ({
  label: "Year",
  value: queryParams?.year,
  onChange: year => handleChangeQueryParams(year, "year"),
  type: "yearPicker",
});

export default Dashboard;
