import {
  Backdrop,
  Button,
  Card,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecordRounded";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import NumberFormat from "react-number-format";
import { Link, NavLink as RouterLink } from "react-router-dom";
import EyeVector from "../../../assets/img/eye.png";
import { Page, SelectInput, YearPicker } from "components";
import { hardBaseUrl } from "../../../services/urlConstant";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const DashboardPencipta = () => {
  const getColor = "#000";
  const [totalSaldoBalance, setTotalSaldoBalance] = useState(0);
  const [totalPembayaran, setTotalPembayaran] = useState(0);
  const [totalRoyalty, setTotalRoyalty] = useState(0);
  const [yearList, setYearList] = useState([]);
  const [getYear, setGetYear] = useState(null);
  const [totalLagu, setTotalLagu] = useState(0);
  const [totalTrafik, setTotalTrafik] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(1);
  const [getTableData, setGetTableData] = useState(false);
  const [getChart, setGetChart] = useState(false);
  const [composerName, setComposerName] = useState("");
  const [newDataForChart, setNewDataForChart] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        borderColor: "#6ceb98",
        backgroundColor: "#6ceb98",
        fill: false,
        lineTension: 0.2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: "#6ceb98",
        pointBorderColor: "#6ceb98",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#6ceb98",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ],
  });
  const [publisherId, setPublisherId] = useState(0);

  const [invoiceData, setInvoiceData] = useState([]);
  const [totalDataInvoice, setTotalDataInvoice] = useState(10);
  const [rowPerPageInvoice, setRowPerPageInvoice] = useState(10);
  const [pageInvoice, setPageInvoice] = useState(1);
  const [monthFilter, setMonthFilter] = useState(0);
  const monthList = [
    {
      id: 1,
      name: "January",
    },
    {
      id: 2,
      name: "February",
    },
    {
      id: 3,
      name: "March",
    },
    {
      id: 4,
      name: "April",
    },
    {
      id: 5,
      name: "May",
    },
    {
      id: 6,
      name: "June",
    },
    {
      id: 7,
      name: "July",
    },
    {
      id: 8,
      name: "August",
    },
    {
      id: 9,
      name: "September",
    },
    {
      id: 10,
      name: "October",
    },
    {
      id: 11,
      name: "November",
    },
    {
      id: 12,
      name: "December",
    },
  ];
  const [dspFilter, setDspFilter] = useState(0);
  const [dspList, setDspList] = useState([]);
  const [loadingCheck, setLoadingCheck] = useState(false);

  const handleChangeMonthFilter = e => {
    setMonthFilter(e.target.value);
    setGetChart(true);

    setGetTableData(true);
  };

  const handleChangeDspFilter = e => {
    setDspFilter(e.target.value);
    localStorage.setItem("idDsp", e.target.value);
    setGetChart(true);

    setGetTableData(true);
  };

  const getDspList = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/dashboard/option?dsp_flag=ACTIVE`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setDspList(res.data.data.dsp);
      })
      .catch(() => { });
  };

  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const getInvoiceTable = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/composer/transaction/paymentclaim?page=${pageInvoice}&per_page=${rowPerPageInvoice}`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setInvoiceData(res.data.data.payment_claim);
        setTotalDataInvoice(res.data.data.query.total_page);
        setPageInvoice(res.data.data.query.page);
        setRowPerPageInvoice(res.data.data.query.per_page);
      })
      .catch(() => { });
  };

  useEffect(() => {
    getInvoiceTable();
    getDspList();
  }, [pageInvoice, rowPerPageInvoice]);

  const getDetailComposer = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/me`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setComposerName(res.data.data.name);
      })
      .catch(() => { });
  };

  useEffect(() => {
    setGetYear(moment().year());
    if (localStorage.getItem("idDsp") !== null) {
      setDspFilter(localStorage.getItem("idDsp"));
    }
    getDetailComposer();
  }, []);

  const getDashboardOptions = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/user-composer/originalpublisher`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setPublisherId();

        let arrDspId = [];
        res.data.data.forEach(item => {
          arrDspId.push(item.original_publisher_id);
        });

        setGetChart(true);
        setGetTableData(true);
      })
      .catch(() => { });
  };

  useEffect(() => {
    const getChartData = async () => {
      try {
        setLoadingCheck(true);

        const token = localStorage.getItem("token");
        let arrToStringSubPub = dspFilter.toString();

        const url = `${hardBaseUrl}/composer/dashboard/chart?year=${getYear}&dsp_id=${arrToStringSubPub}&month=${monthFilter}`;

        const res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const getDate = data => {
          switch (data) {
            case "01":
              return "Jan";
            case "02":
              return "Feb";
            case "03":
              return "Mar";
            case "04":
              return "Apr";
            case "05":
              return "May";
            case "06":
              return "Jun";
            case "07":
              return "Jul";
            case "08":
              return "Aug";
            case "09":
              return "Sep";
            case "10":
              return "Oct";
            case "11":
              return "Nov";
            case "12":
              return "Dec";
            default:
              return "Jan";
          }
        };

        let monthYearArray = null;
        if (res.data.data !== null) {
          const date = res.data.data[0].chart.map(data => data.date);

          const month = date.map(data => data.split("-")[1]);

          const year = date.map(data => data.split("-")[0]);

          const year2 = year.map(data => data.slice(-2));

          monthYearArray = month.map((data, index) => {
            return `${getDate(data)} ${year2[index]}`;
          });
        } else {
          let yearString = `${getYear}`;
          let result = yearString.substring(2);
          let month = [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
          ];

          monthYearArray = month.map(data => {
            return `${getDate(data)} ${result}`;
          });
        }

        let theArrData = [];

        if (res.data.data !== null) {
          res.data.data.forEach(theItem => {
            let setTheItem = {
              label: theItem.dsp.name,
              dataListener: theItem.chart.map(data => {
                return data.listener;
              }),
              data: theItem.chart.map(data => {
                return data.royalty;
              }),

              borderColor: "#6ceb98",
              backgroundColor: "#6ceb98",
              pointBackgroundColor: "#6ceb98",
              pointBorderColor: "#6ceb98",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "#6ceb98",

              fill: false,
              lineTension: 0.2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
            };
            theArrData.push(setTheItem);
          });
        } else {
          for (let index = 0; index < 12; index++) {
            let setTheItem = {
              label: "",

              borderColor: "#6ceb98",
              backgroundColor: "#6ceb98",
              pointBackgroundColor: "#6ceb98",
              pointBorderColor: "#6ceb98",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "#6ceb98",
              fill: false,
              lineTension: 0.2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
            };
            theArrData.push(setTheItem);
          }
        }

        const theDataForChart = {
          labels: monthYearArray,
          datasets: theArrData,
        };

        setNewDataForChart(theDataForChart);

        if (res.data.data !== null) {
          const sumListener = res.data.data[0].chart.reduce(
            (a, b) => a + b.listener,
            0
          );
          const sumRoyalty = res.data.data[0].chart.reduce(
            (a, b) => a + b.royalty,
            0
          );

          setTotalTrafik(sumListener);
          setTotalRoyalty(sumRoyalty);
        }

        setGetChart(false);
        setTimeout(() => {
          setLoadingCheck(false);
        }, 800);
      } catch (e) {
        setGetChart(false);
        setLoadingCheck(false);
      }
    };
    if (getChart) {
      getChartData();
    }
  }, [getChart]);

  const getBalance = async () => {
    try {
      let theToken = localStorage.getItem("token");
      let baseOfUrl = hardBaseUrl;
      const url = baseOfUrl + "/composer/transaction/payment/get-balance";
      const options = {
        headers: {
          Authorization: "Bearer " + theToken,
        },
      };
      const response = await axios.get(url, options);

      setTotalSaldoBalance(response.data.data.balance_number);
      setTotalPembayaran(response.data.data.paid_amount);
    } catch (error) {
      new Error(error);
    }
  };
  useEffect(() => {
    getDashboardOptions();
    getBalance();
  }, [publisherId, getYear]);
  useEffect(() => {
    const getDashboardTableData = async () => {
      try {
        const token = localStorage.getItem("token");
        let arrToStringSubPub = dspFilter.toString();
        const url = `${hardBaseUrl}/composer/dashboard/table?year=${getYear}&dsp_id=${arrToStringSubPub}&month=${monthFilter}&page=${page}&size=${rowsPerPage}`;
        const res = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        setTableData(res.data.data);

        setGetTableData(false);
        setTotalLagu(res.data.meta.total);
        setTotalData(res.data.meta.total);
        setPage(res.data.meta.page);
      } catch (e) {
        setGetTableData(false);
      }
    };

    getDashboardTableData();
  }, [getTableData, rowsPerPage, page]);

  const options = {
    parsing: {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      delimiter: ",",
      complete: function () { },
    },
    responsive: true,

    interaction: {
      mode: "index",
      intersect: false,
    },

    plugins: {
      datalabels: {
        formatter: function () {
          return "";
        },
      },
      layout: {},
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      subtitle: {
        display: false,
        text: "Custom Chart Subtitle",
      },
      tooltip: {
        enabled: false,
        external: context => {
          let tooltipEl = document.getElementById("chartjs-tooltip");

          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<table></table>";
            document.body.appendChild(tooltipEl);
          }

          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
          }

          tooltipEl.classList.remove("below", "no-transform");

          const month = context.tooltip.title[0];

          let monthName = month.substring(0, 3);

          if (monthName === "Jan") {
            monthName = "JANUARY";
          } else if (monthName === "Feb") {
            monthName = "FEBRUARY";
          } else if (monthName === "Mar") {
            monthName = "MARCH";
          } else if (monthName === "Apr") {
            monthName = "APRIL";
          } else if (monthName === "May") {
            monthName = "MAY";
          } else if (monthName === "Jun") {
            monthName = "JUNE";
          } else if (monthName === "Jul") {
            monthName = "JULY";
          } else if (monthName === "Aug") {
            monthName = "AUGUST";
          } else if (monthName === "Sep") {
            monthName = "SEPTEMBER";
          } else if (monthName === "Oct") {
            monthName = "OCTOBER";
          } else if (monthName === "Nov") {
            monthName = "NOVEMBER";
          } else if (monthName === "Dec") {
            monthName = "DECEMBER";
          }

          if (tooltipModel.body) {
            const dataMap = data => {
              let dataMapping = data
                .map(item => {
                  const getDataListener = data => {
                    if (monthName === "JANUARY") {
                      return data.dataListener[0];
                    } else if (monthName === "FEBRUARY") {
                      return data.dataListener[1];
                    } else if (monthName === "MARCH") {
                      return data.dataListener[2];
                    } else if (monthName === "APRIL") {
                      return data.dataListener[3];
                    } else if (monthName === "MAY") {
                      return data.dataListener[4];
                    } else if (monthName === "JUNE") {
                      return data.dataListener[5];
                    } else if (monthName === "JULY") {
                      return data.dataListener[6];
                    } else if (monthName === "AUGUST") {
                      return data.dataListener[7];
                    } else if (monthName === "SEPTEMBER") {
                      return data.dataListener[8];
                    } else if (monthName === "OCTOBER") {
                      return data.dataListener[9];
                    } else if (monthName === "NOVEMBER") {
                      return data.dataListener[10];
                    } else if (monthName === "DECEMBER") {
                      return data.dataListener[11];
                    }
                  };

                  const getDataRoyalty = data => {
                    if (monthName === "JANUARY") {
                      return data.data[0];
                    } else if (monthName === "FEBRUARY") {
                      return data.data[1];
                    } else if (monthName === "MARCH") {
                      return data.data[2];
                    } else if (monthName === "APRIL") {
                      return data.data[3];
                    } else if (monthName === "MAY") {
                      return data.data[4];
                    } else if (monthName === "JUNE") {
                      return data.data[5];
                    } else if (monthName === "JULY") {
                      return data.data[6];
                    } else if (monthName === "AUGUST") {
                      return data.data[7];
                    } else if (monthName === "SEPTEMBER") {
                      return data.data[8];
                    } else if (monthName === "OCTOBER") {
                      return data.data[9];
                    } else if (monthName === "NOVEMBER") {
                      return data.data[10];
                    } else if (monthName === "DECEMBER") {
                      return data.data[11];
                    }
                  };
                  const formatter = new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  });

                  const formatter2 = new Intl.NumberFormat("id-ID", {
                    minimumFractionDigits: 0,
                  });

                  return `
                      <div style="margin-bottom:10px; ">
                        <div style="display: flex; padding-left: 1.2rem; ">
                          <div style="display: flex; margin-right: 10px;align-items: center;  ">
                            <div style="border-radius: 100%; background-color: ${item.dataset.backgroundColor
                    }; height: 6px; width: 6px;"></div>
                            </div>
                      <div style="display: flex;  flex-direction: column;  font-family: 'Inter'; font-size: 12px; font-weight: bold">
                        ${item.dataset.label}
                      </div>
                      </div>
                      <div style="display: flex; flex-direction: row;  padding-left: 1.2rem;">
                      <div style="color:#687083; font-size:12px; font-weight:400; font-family: 'Inter';display: flex; margin-right: 1.2rem;align-items: center;">Trafik:</div>
                      <div style="color:#364052; font-size:12px; font-weight:500; font-family: 'Inter';display: flex;  flex-direction: column;  font-family: 'Inter';  margin-left: 5px; ">${formatter2.format(
                      getDataListener(item.dataset)
                    )}</div>
                      </div>
    
                      <div style="display: flex; flex-direction: row;  padding-left: 1.2rem;">
                      <div style="color:#687083; font-size:12px; font-weight:400; font-family: 'Inter'; display: flex; flex-direction: column; margin-right: 1.2rem;align-items: center;">Royalti:</div>
                      <div style="color:#364052; font-size:12px; font-weight:500; display: flex;  flex-direction: column;  font-family: 'Inter'; ">${formatter.format(
                      getDataRoyalty(item.dataset)
                    )},-</div>
                      </div>
    
                      </div>
                  `;
                })
                .join("");
              return dataMapping;
            };

            const innerHtml = `
                          <div style="background-color: white;padding-bottom: 10px;width: 200px;  border-radius: 10px; box-shadow: 0 6px 12px rgba(0,0,0,.175);">
    
                              <div style=" padding-top: 5px; padding-bottom: 10px; padding-left: 1.2rem; color: #364052; font-family: 'Inter'; font-size: 12px; font-weight; 500;">
                                 ${monthName} ${getYear}
                              </div>
                              ${dataMap(context.tooltip._tooltipItems)}
                           </div>
                      `;

            tooltipEl.querySelector("table").innerHTML = innerHtml;
          }

          const position = context.chart.canvas.getBoundingClientRect();

          if (monthName === "NOVEMBER" || monthName === "DECEMBER") {
            tooltipEl.style.opacity = "1";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.right =
              position.right + window.pageXOffset + tooltipModel.caretX + "px";
            tooltipEl.style.top =
              position.top + window.pageYOffset + tooltipModel.caretY + "px";
            tooltipEl.style.padding =
              tooltipModel.padding + "px " + tooltipModel.padding + "px";
            tooltipEl.style.pointerEvents = "none";
          } else {
            tooltipEl.style.opacity = "1";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.left =
              position.left + window.pageXOffset + tooltipModel.caretX + "px";
            tooltipEl.style.top =
              position.top + window.pageYOffset + tooltipModel.caretY + "px";
            tooltipEl.style.padding =
              tooltipModel.padding + "px " + tooltipModel.padding + "px";
            tooltipEl.style.pointerEvents = "none";
          }
        },
        backgroundColor: "#fff",
        titleFontColor: "#333",
        bodyColor: "#333",
        titleColor: "#333",
        footerColor: "#333",
        titleMarginBottom: 10,
        boxWidth: 10,
        boxHeight: 10,
        boxPadding: 10,
        caretPadding: 50,
        mode: "index",
        intersect: false,
        bodySpacing: 5,
        borderColor: "#333",
        borderWidth: 1,
        titleFont: {
          size: 17,
          weight: "bold",
        },
        bodyFont: {
          size: 10,
        },
        padding: {
          right: 45,
          left: 15,
          top: 15,
          bottom: 15,
        },
      },
    },
  };

  useEffect(() => {
    const thisYear = moment().year();
    for (let i = thisYear; i >= 2000; i--) {
      setYearList(yearList => [...yearList, i]);
    }
  }, []);
  const handleChangeYear = value => {
    setGetYear(value);
    setGetChart(true);

    setGetTableData(true);
  };

  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  const formatter2 = new Intl.NumberFormat("id-ID", {
    minimumFractionDigits: 0,
  });

  const listPencipta = data => {
    let pencipta = data;

    if (pencipta === null) {
      return <div>-</div>;
    } else if (pencipta.length < 3) {
      return pencipta.map((pencipta, i) => (
        <Chip
          key={i}
          label={pencipta}
          className={classes.tableChip}
          size="small"
        />
      ));
    } else if (pencipta.length > 3) {
      let pencipta1 = pencipta.slice(0, 3);
      let pencipta2 = pencipta.slice(3, pencipta.length);
      return (
        <div>
          {pencipta1.map((pencipta, i) => (
            <Chip
              key={i}
              label={pencipta}
              className={classes.tableChip}
              size="small"
            />
          ))}
          <Chip
            label={"+" + pencipta2.length}
            className={classes.tableChip}
            size="small"
          />
        </div>
      );
    }
  };
  const handleChangePage = (event, data) => {
    setPage(data);
  };
  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);
    setPage(1);
  };
  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={e => handleChangeRowsPerPage(e)}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleChangePageInvoice = (event, data) => {
    event.preventDefault();
    setPageInvoice(data);
    setPage(1);
  };
  const handleChangeRowsPerPageInvoice = event => {
    event.preventDefault();
    setRowPerPageInvoice(event.target.value);
    setPageInvoice(1);
  };
  const pageSizePaginationInvoice = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowPerPageInvoice}
          onChange={e => handleChangeRowsPerPageInvoice(e)}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    divider: {
      margin: theme.spacing(2, 0),
      borderTop: "1px solid #e1e1e1",
    },
    card: {
      borderLeft: "2px solid #9545EB",
      boxShadow: "none",
      borderRadius: "0px",
      height: "100%",
    },
    insideCard: {
      paddingLeft: "10px",
    },
    cardArea: {
      marginTop: "20px",
      marginBottom: "20px",
      justifyContent: "space-between",
    },
    filterArea: {
      marginTop: "20px",
      marginBottom: "20px",
      display: "flex",
    },
    chipArea: {
      marginTop: "20px",
      marginBottom: "20px",
    },
    spotifyChip: {
      border: "1px solid #1db954",
      backgroundColor: "#ccffaa",
      color: "#1db954",
    },
    spotifyChipDot: {
      color: "#1db954",
    },
    tableChip: {
      marginRight: "10px",
    },
    pagination: {
      "& .Mui-selected": {
        backgroundColor: getColor,
        color: "white",
      },
    },
    pageSize: {
      height: "30px",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
      paddingLeft: "5px",
      paddingRight: "5px",
      marginTop: "5px",
    },
    paginationText: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      color: "#687083",
    },
    textField: {
      width: "290px",
      height: "44px",
      backgroundColor: "white",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
    },
    btnCariLaporan: {
      backgroundColor: "black",
      color: "white",
      width: "156px",
      height: "40px",
      marginTop: "25px",
      marginLeft: "10px",
      marginRight: "10px",

      textTransform: "none",
      borderRadius: "6px",
      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    headText: {
      fontSize: "12px",
      color: "#687083",
      fontWeight: "bold",
    },
    bodyTextBlack: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#111827",
      fontStyle: "normal",
    },
    bodyTextGrey: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#687083",
      fontStyle: "normal",
    },
    tableRow: {
      height: 20,
    },
    conTextBlack: {
      fontSize: "14px",
      fontWeight: "bold",
    },
    dropdown: {
      color: "black",
    },
    subtitle: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "28px",
    },
    btnRincian: {
      backgroundColor: "black",

      color: "white",
      padding: "10px 28px",
      borderRadius: "6px",
      width: "140px",
      height: "36px",

      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    reportText: {
      fontSize: "14px",
      fontWeight: 500,
      color: "#687083",
      marginBottom: "5px",

      width: "300px",
    },
    reportValue: {
      fontSize: "24px",
      fontWeight: 500,
      color: "#111827",
    },
    breadcrumbs: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#111827",
    },
    breadCrumbsActive: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#687083",
    },
    label: {
      color: "#364052",
      fontSize: "14px",
      fontWeight: 500,
      fontStyle: "normal",
      lineHeight: "20px",
      marginBottom: "5px",
    },
    formControl: {
      height: "44px",
      width: "193px",
      backgroundColor: "white",
      border: "1px solid #D1D5DC",
      borderRadius: "6px",
    },
    filterFormColor: {
      backgroundColor: "#F7F7F7",
    },
    placeholder: {
      "&::placeholder": {
        color: "#9AA2B1",
      },
    },
    table: {
      border: "1px solid #D1D5DC",
      borderRadius: "6px",
    },
    ib: {
      padding: "4px",
      color: "white",
      backgroundColor: "black",
      borderRadius: "6px",
      "&:focus": {
        color: "white",
        backgroundColor: "black",
      },
      "&:active": {
        boxShadow: "none",
        color: "white",
        backgroundColor: "black",
      },
      "&:hover": {
        color: "white",
        backgroundColor: "black",

        boxShadow: "none",
      },
    },
    myCheckBox: {
      "&.MuiCheckbox-root": {
        color: "black",

        "&.Mui-checked": {
          color: "black",
        },
      },
    },
    scrollingCard: {
      "&::-webkit-scrollbar": {
        width: 1,
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "lightgrey",
        outline: "1px solid slategrey",
      },
    },
    inputFilter: {
      width: "211px",
      height: "44px",
      border: "1px solid #D1D5DC",
      borderRadius: "6px",
      marginRight: "10px",
    },
    mypadding2: {
      "& .MuiSelect-outlined": {
        paddingTop: "15.5px",
        paddingBottom: "10.5px",
      },
      width: "200px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer - 999,
      color: "blue",
    },
  }));

  const classes = useStyles();

  const goToThePaymentPage = () => {
    window.location.href = "/pencipta/payment";
  };

  const getRouting = data => {
    if (data.transaction_name === "payment") {
      return `/pencipta/payment/${data.publisher_transaction_id}`;
    } else if (data.transaction_name === "claim") {
      return "";
    } else {
      return "";
    }
  };

  return (
    <Page className={classes.root} title="Dashboard">
      {loadingCheck ? (
        <>
          <Backdrop className={classes.backdrop} open={loadingCheck}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      ) : (
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    lineHeight: "32px",
                  }}
                >
                  Dashboard | {composerName}
                </Typography>
              </ThemeProvider>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <div className={classes.cardArea}>
            <ThemeProvider theme={theme}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <Card className={classes.card}>
                    <div className={classes.insideCard}>
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.reportText}
                      >
                        Total Balance
                      </Typography>
                      <Typography
                        component="h2"
                        variant="h3"
                        className={classes.reportValue}
                      >
                        <NumberFormat
                          displayType="text"
                          value={totalSaldoBalance}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          prefix="Rp "
                        />
                      </Typography>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Card className={classes.card}>
                    <div className={classes.insideCard}>
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.reportText}
                      >
                        Paid Amount
                      </Typography>
                      <Typography
                        component="h2"
                        variant="h3"
                        className={classes.reportValue}
                      >
                        <NumberFormat
                          displayType="text"
                          value={totalPembayaran}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          prefix="Rp "
                        />
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "5px",
                          cursor: "pointer",
                        }}
                        onClick={goToThePaymentPage}
                      >
                        <Typography
                          style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "#111827",
                          }}
                        >
                          View Summary
                        </Typography>

                        <NavigateNextIcon
                          style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "#111827",
                            marginTop: "4px",
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </ThemeProvider>
          </div>
          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item>
              <SelectInput
                label="DSP"
                placeholder="Select DSP"
                value={dspFilter || ""}
                onChange={handleChangeDspFilter}
                options={dspList}
                optionKey="dsp_id"
                optionLabel="name"
                width={210}
              />
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <SelectInput
                    label="Month"
                    value={monthFilter || ""}
                    placeholder="All Month"
                    onChange={handleChangeMonthFilter}
                    options={monthList}
                    optionKey="id"
                    optionLabel="name"
                    width={150}
                  />
                </Grid>
                <Grid item>
                  <YearPicker
                    label="Year"
                    onChange={handleChangeYear}
                    value={getYear}
                    width={150}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "25px" }}>
            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <div
                style={{
                  border: "1px solid #ebebeb",
                  borderRadius: "5px",

                  paddingBottom: "50px",
                  paddingTop: "50px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "20px",
                  }}
                >
                  {dspFilter === 0
                    ? null
                    : dspList.map((item, index) => {
                        if (item.dsp_id === dspFilter) {
                          return (
                            <Chip
                              key={index}
                              size="small"
                              label={item.name}
                              avatar={
                                <FiberManualRecordIcon
                                  style={{ width: "10px", color: item.color }}
                                />
                              }
                              style={{
                                marginRight: "10px",
                                color: item.color,
                                backgroundColor: `${item.color}10`,

                                borderColor: item.color,
                                border: "1px thin",
                              }}
                            />
                          );
                        }
                        return null;
                      })}
                </div>

                <Paper className={classes.fixedHeightPaper}>
                  <Line options={options} data={newDataForChart} plugins={[]} />
                </Paper>
              </div>
            </Grid>
          </Grid>

          <div className={classes.cardArea}>
            <ThemeProvider theme={theme}>
              <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                  <Card className={classes.card}>
                    <div className={classes.insideCard}>
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.reportText}
                      >
                        Total Songs
                      </Typography>
                      <Typography
                        component="h2"
                        variant="h3"
                        className={classes.reportValue}
                      >
                        {formatter2.format(totalLagu)}
                      </Typography>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Card className={classes.card}>
                    <div className={classes.insideCard}>
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.reportText}
                      >
                        Total Listener Traffic
                      </Typography>
                      <Typography
                        component="h2"
                        variant="h3"
                        className={classes.reportValue}
                      >
                        {formatter2.format(totalTrafik)}
                      </Typography>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Card className={classes.card}>
                    <div className={classes.insideCard}>
                      <Typography
                        component="p"
                        variant="caption"
                        className={classes.reportText}
                      >
                        Total Revenue
                      </Typography>
                      <Typography
                        component="h2"
                        variant="h3"
                        className={classes.reportValue}
                      >
                        {formatter.format(totalRoyalty)}
                      </Typography>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </ThemeProvider>
          </div>

          <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <ThemeProvider theme={theme}>
                    <TableCell align="center" className={classes.headText}>
                      Action
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Song title
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Composer/Author
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Listener Traffic
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                        align="right"
                      >
                        Revenue
                      </Typography>
                    </TableCell>
                  </ThemeProvider>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.length > 0 ? (
                  tableData.map((lagu, index) => (
                    <TableRow hover key={index}>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          className={classes.btnRincian}
                          style={{
                            backgroundColor: getColor,
                          }}
                          component={Link}
                          to={{
                            pathname: `/pencipta/dashboard-pencipta/detail-lagu/${lagu.song_id}`,
                            state: {
                              lagu: lagu,
                              year: getYear,
                              month: monthFilter,
                              dspFilter: dspFilter,
                            },
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography
                              style={{
                                color: "white",
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "24px",
                                textTransform: "none",
                                whiteSpace: "nowrap",
                              }}
                            >
                              See Details
                            </Typography>
                          </ThemeProvider>{" "}
                        </Button>
                      </TableCell>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyTextBlack}
                          >
                            {lagu.title}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyTextBlack}
                          >
                            {listPencipta(lagu.composer_names)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyTextBlack}
                          >
                            <NumberFormat
                              displayType="text"
                              value={lagu.listener}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyTextBlack}
                          >
                            <NumberFormat
                              displayType="text"
                              value={lagu.base_currency_revenue}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              prefix="Rp "
                            />
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} style={{ height: "50px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            component="span"
                            style={{
                              fontSize: "14px",
                              lineHeight: "20px",
                              fontWeight: "400",
                              fontStyle: "normal",
                              color: "#687083",
                            }}
                          >
                            Data not found
                          </Typography>
                        </ThemeProvider>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <div className={classes.paginationArea}>
              {tableData.length > 0 ? (
                <Grid
                  alignItems="flex-end"
                  container
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Grid item>
                    <div style={{ display: "flex" }}>
                      <div style={{ marginTop: "7px" }}>Items per page:</div>{" "}
                      <div style={{ marginLeft: "10px" }}>
                        {pageSizePagination()}
                      </div>
                    </div>
                  </Grid>

                  <Grid item>
                    <Pagination
                      shape="rounded"
                      className={classes.pagination}
                      page={page}
                      count={pagesCount}
                      onChange={handleChangePage}
                    />
                  </Grid>
                </Grid>
              ) : (
                <div></div>
              )}
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            sm={12}
            lg={12}
            xl={12}
            style={{
              marginTop: "20px",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                component="span"
                style={{
                  fontSize: "18px",
                  lineHeight: "28px",
                  fontWeight: "700",
                  fontStyle: "normal",
                }}
              >
                Invoice List
              </Typography>
            </ThemeProvider>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <ThemeProvider theme={theme}>
                    <TableCell align="center" className={classes.headText}>
                      Action
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Date
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Description
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                      >
                        Value
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        className={classes.headText}
                        align="right"
                      >
                        Status
                      </Typography>
                    </TableCell>
                  </ThemeProvider>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceData.length > 0 ? (
                  invoiceData.map((lagu, index) => (
                    <TableRow hover key={index}>
                      <TableCell align="center">
                        <IconButton
                          classes={{
                            root: classes.ib,
                          }}
                        >
                          <RouterLink
                            to={{
                              pathname: getRouting(lagu),
                              state: {
                                customer: lagu,
                              },
                            }}
                          >
                            <img src={EyeVector} alt={"EyeVector"} />
                          </RouterLink>
                        </IconButton>
                      </TableCell>
                      <ThemeProvider theme={theme}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyTextBlack}
                          >
                            {lagu.date}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyTextBlack}
                          >
                            {lagu.note}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyTextBlack}
                          >
                            <NumberFormat
                              displayType="text"
                              value={lagu.value}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              prefix="Rp "
                            />
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyTextBlack}
                          >
                            {lagu?.status === "waiting_for_approval"
                              ? "Waiting for Approval"
                              : lagu?.status === "approved"
                                ? "Approved"
                                : lagu?.status === "rejected"
                                  ? "Rejected"
                                  : lagu?.status === "paid"
                                    ? "Paid"
                                    : lagu?.status}
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} style={{ height: "50px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            component="span"
                            style={{
                              fontSize: "14px",
                              lineHeight: "20px",
                              fontWeight: "400",
                              fontStyle: "normal",
                              color: "#687083",
                            }}
                          >
                            Data not found
                          </Typography>
                        </ThemeProvider>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <div className={classes.paginationArea}>
              {invoiceData.length > 0 ? (
                <Grid
                  alignItems="flex-end"
                  container
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Grid item>
                    <div style={{ display: "flex" }}>
                      <div style={{ marginTop: "7px" }}>Items per page:</div>{" "}
                      <div style={{ marginLeft: "10px" }}>
                        {pageSizePaginationInvoice()}
                      </div>
                    </div>
                  </Grid>

                  <Grid item>
                    <Pagination
                      shape="rounded"
                      className={classes.pagination}
                      page={pageInvoice}
                      count={totalDataInvoice}
                      onChange={handleChangePageInvoice}
                    />
                  </Grid>
                </Grid>
              ) : (
                <div></div>
              )}
            </div>
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default DashboardPencipta;
