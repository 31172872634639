import {
  Backdrop,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
    h5: {
      fontWeight: 700,
    },
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  colorPickerArea: {},
  button: {
    textTransform: "none",
    marginTop: "16px",
    backgroundColor: "black",
    color: "white",
    width: "135px",
    height: "40px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  imgPreview: {
    maxHeight: "120px",
  },
  fileUploadArea: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#6e6e6e",
  },
  formColor: {},
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  inputFieldsUrl: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderRight: "1px solid #D1D5DC",
  },
  inputFieldsTlp: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderLeft: "1px solid #D1D5DC",
    paddingLeft: "14px",
  },
  myTextField: {
    height: "44px",
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  label: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "500px",
    fontSize: "14px",
    lineHeight: "20px",
    width: "140px",
    height: "20px",
  },
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const EditAsosiasi = props => {
  const { state } = useLocation();
  const [loadingPage] = useState(false);
  const [name, setName] = useState("");
  const [pic, setPic] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [typeSnackbar] = useState("success");
  const [message] = useState("This is a message!");
  const classes = useStyles();
  const onChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "name") {
      setName(value);
    } else if (name === "pic") {
      setPic(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "email") {
      setEmail(value);
    }
  };
  const getDetail = () => {
    const urlBase = `${hardBaseUrl}/association/detail/${state.customer.association_id}`;
    const theToken = localStorage.getItem("token");
    const headers = {
      headers: {
        Authorization: `Bearer ${theToken}`,
      },
    };
    axios
      .get(urlBase, headers)
      .then(res => {
        setName(res.data.data.name);
        setPic(res.data.data.pic);
        setPhone(res.data.data.phone_number);
        setEmail(res.data.data.email);
      })
      .catch(err => {
        return new Error(err);
      });
  };
  useEffect(() => {
    getDetail();
  }, []);
  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };
  const notifError = text =>
    Swal.fire({
      title: "Oops…",
      icon: "error",
      text: text,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    }).then(result => {
      if (result.isConfirmed) {
      }
    });
  const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const onSubmit = e => {
    e.preventDefault();
    if (name === "") {
      return notifError("Association name can't be empty");
    }
    if (pic === "") {
      return notifError("PIC name can't be empty");
    }
    if (email === "") {
      return notifError("Email can't be empty");
    }
    if (phone === "") {
      return notifError("Phone number can't be empty");
    }
    if (!regexEmail.test(email)) {
      return notifError(
        "Please enter your email address in format: yourname@example.com"
      );
    }
    if (name && pic && email && phone && regexEmail.test(email)) {
      const urlBase = `${hardBaseUrl}/association/update/${state.customer.association_id}`;
      const theToken = localStorage.getItem("token");
      const headers = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${theToken}`,
        },
      };
      const payload = {
        email: email,
        name: name,
        phone_number: phone,
        pic: pic,
      };
      axios
        .put(urlBase, payload, headers)
        .then(res => {
          if (
            res.data.message === "success" &&
            res.data.meta.http_status === 200
          ) {
            Swal.fire({
              title: "Success.",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            }).then(result => {
              if (result.isConfirmed === true) {
                props.history.push("/admin/parameter/association");
              }
            });
          }
        })
        .catch(err => {
          Swal.fire({
            title: "Oops…",
            icon: "error",
            text: err?.response?.data?.errors[0]?.message,
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(() => {});
        });
    }
  };
  useEffect(() => {
    let tagArr = document.getElementsByTagName("input");
    for (let i = 0; i < tagArr.length; i++) {
      tagArr[i].autocomplete = "off";
    }
  }, []);
  return (
    <Page className={classes.root} title="Edit Association">
      <Backdrop className={classes.backdrop} open={loadingPage}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
          {message}
        </Alert>
      </Snackbar>
      <Container maxWidth={false}>
        <div>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={fontInter}>
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    lineHeight: "32px",
                  }}
                >
                  Edit Association
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <a href="/admin/parameter/publisher">Master</a>
                <a href="/admin/parameter/association">Association</a>
                <Typography>Edit Association</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <form onSubmit={onSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <ThemeProvider theme={fontInter}>
                  <Typography variant="h5" className={classes.title}>
                    Association Profile
                  </Typography>
                  <Typography
                    variant="caption"
                    className={classes.subTitle}
                    style={{
                      marginTop: "8px",
                    }}
                  >
                    Customize association profile and URL to Share
                  </Typography>
                </ThemeProvider>
                <div
                  style={{
                    border: "1px solid #9AA2B1",
                    padding: "16px",
                    borderRadius: "8px",
                    marginTop: "16px",
                  }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <ThemeProvider theme={fontInter}>
                        <Typography
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          Association Name
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        style={{
                          marginTop: "6px",
                        }}
                        className={classes.myTextField}
                        fullWidth
                        onChange={e => onChange(e)}
                        id={"name"}
                        name="name"
                        value={name}
                        type="text"
                        variant="outlined"
                        InputProps={{
                          classes: { input: classes.inputFields },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ThemeProvider theme={fontInter}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            marginTop: "16px",
                          }}
                        >
                          PIC
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        style={{
                          marginTop: "6px",
                        }}
                        className={classes.myTextField}
                        fullWidth
                        onChange={onChange}
                        id="pic"
                        name="pic"
                        value={pic}
                        type="text"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ThemeProvider theme={fontInter}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            marginTop: "16px",
                          }}
                        >
                          Email
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        style={{
                          marginTop: "6px",
                        }}
                        className={classes.myTextField}
                        fullWidth
                        onChange={onChange}
                        id={"email"}
                        name="email"
                        autoComplete="new-password"
                        value={email}
                        type="text"
                        variant="outlined"
                        InputProps={{
                          classes: { input: classes.inputFields },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ThemeProvider theme={fontInter}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            marginTop: "16px",
                          }}
                        >
                          Phone Number
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        style={{
                          marginTop: "6px",
                        }}
                        className={classes.myTextField}
                        fullWidth
                        onChange={onChange}
                        id="phone"
                        name="phone"
                        value={phone}
                        margin="normal"
                        type="tel"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
            <Button
              variant="contained"
              type="submit"
              className={classes.button}
            >
              <ThemeProvider theme={fontInter}>
                <Typography
                  style={{
                    color: "white",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  Save
                </Typography>
              </ThemeProvider>
            </Button>
          </form>
        </div>
      </Container>
    </Page>
  );
};
export default EditAsosiasi;
