import { Container } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import { Divider, Grid, styled } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SkeletonComponent,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

function Performer({ userRole }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const typeWeb = localStorage.getItem("typeWeb");
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: Number(paramsPage) || 1,
    size: Number(paramsSize) || 10,
    search: paramsSearch || "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleChangeSearch = event => {
    const value = event?.target?.value;
    handleChangeQueryParams(value, "search");
    handleChangePageParams(value, "search");
  };
  const handleChangePage = page => {
    handleChangeQueryParams(page, "page");
    handleChangePageParams(page, "page");
  };
  const handleChangePageSize = size => {
    handleChangeQueryParams(size, "size");
    handleChangePageParams(size, "size");
  };
  const handleEdit = item => {
    history.push({
      pathname: `/admin/parameter/performer/ubah/${item.performer_id}`,
      state: { customer: item },
    });
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/publisher/performer`, {
        headers,
        params: queryParams,
      });

      const { data, meta } = res?.data;
      setDataTable(data);
      const pageCount = getTotalPage(meta?.total, queryParams?.size || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleDelete = ({ performer_id }) => {
    setLoadingPage(false);
    axios
      .delete(`${hardBaseUrl}/publisher/performer/${performer_id}`, { headers })
      .then(res => {
        if (res.data.message === "success") {
          ModalSuccess("Data Succesfully Deleted").then(result => {
            if (result.isConfirmed === true) {
              getDataTable();
            }
          });
        }
      })
      .catch(error => {
        ModalError(getErrors(error?.response));
      })
      .finally(() => setLoadingPage(false));
  };

  const conditionGroupButton =
    (userRole === "association" || userRole === "publisher") &&
    typeWeb === "mpis"
      ? mpisMasterTopMenu
      : carisMasterTopMenu;
  const conditionMenu = {
    publisher: conditionGroupButton,
    association: [...conditionGroupButton, menuForAssosiate],
    society: menuForSociety,
  };
  const usedMenu = conditionMenu[userRole] || [];

  useEffect(() => {
    debounceDataTable();
    if (queryParams?.page !== paramsPage) {
      handleChangePageParams(queryParams?.page, "page");
    }
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Performer">
      <Container maxWidth={false}>
        <HeaderTitle title="Parameter" breadcrumbData={breadcrumbData} />
        <ButtonGroupTop items={usedMenu} />
        <CustomDivider />
        <Grid container justifyContent="space-between" mb={2}>
          <SearchTextInput
            placeholder="Search"
            value={queryParams?.search}
            onChange={handleChangeSearch}
          />
          <PrimaryButton
            label="Create Performer"
            startIcon={<AddIcon />}
            onClick={() => history.push("/admin/parameter/performer/tambah")}
          />
        </Grid>
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <InnoTableV2
            columns={[
              {
                name: "nik",
                title: "NIK",
              },
              {
                name: "first_name",
                title: "Performer Name",
              },
              {
                name: "publisher_names",
                title: "Publisher",
                renderText: publisher_names => {
                  return (
                    <Grid container direction="column">
                      {publisher_names?.map((item, key) => (
                        <Grid item key={key}>
                          {item}
                        </Grid>
                      ))}
                    </Grid>
                  );
                },
              },
            ]}
            handleChangePage={(_, page) => handleChangePage(page)}
            handleChangeRowsPerPage={e =>
              handleChangePageSize(e?.target?.value)
            }
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            deleteName={"first_name"}
            isLoading={false}
            isHaveAction={true}
            isUsingDeleteConfirmation={true}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tableTotalPage}
          />
        )}
      </Container>
    </Page>
  );
}

const CustomDivider = styled(Divider)(() => ({
  marginBottom: 16,
  borderTop: "1px solid #e1e1e1",
}));
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const menuForAssosiate = {
  id: 3,
  title: "Publisher",
  link: "/admin/parameter/publisher",
  selected: false,
};
const menuForSociety = [
  {
    id: 0,
    title: "Song",
    link: "/admin/parameter/lagu",
    selected: false,
  },
  {
    id: 1,
    title: "Composer/Author",
    link: "/admin/parameter/pencipta",
    selected: false,
  },
  {
    id: 2,
    title: "Performer",
    link: "/admin/parameter/performer",
    selected: false,
  },
  {
    id: 3,
    title: "Publisher",
    link: "/admin/parameter/publisher",
    selected: false,
  },
];
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Parameter",
    link: "/admin/parameter/performer",
  },
  {
    label: "Performer",
    active: true,
  },
];
const mpisMasterTopMenu = [
  {
    id: 0,
    title: "Song",
    link: "/admin/parameter/lagu",
    selected: false,
  },
  {
    id: 1,
    title: "Composer/Author",
    link: "/admin/parameter/pencipta",
    selected: false,
  },
  {
    id: 2,
    title: "Performer",
    link: "/admin/parameter/performer",
    selected: true,
  },
];
const carisMasterTopMenu = [
  {
    id: 0,
    title: "Composer/Author",
    link: "/admin/parameter/pencipta",
    selected: false,
  },
  {
    id: 1,
    title: "Song",
    link: "/admin/parameter/lagu",
    selected: false,
  },
  {
    id: 2,
    title: "Performer",
    link: "/admin/parameter/performer",
    selected: true,
  },

  {
    id: 3,
    title: "Client",
    link: "/admin/parameter/dsp",
    selected: false,
  },
  {
    id: 4,
    title: "User",
    link: "/admin/parameter/user",
    selected: false,
  },
  {
    id: 5,
    title: "Agent",
    link: "/admin/parameter/agent",
    selected: false,
  },
  {
    id: 6,
    title: "Group",
    link: "/admin/parameter/group",
    selected: false,
  },
  {
    id: 7,
    title: "Client Classification",
    link: "/admin/parameter/client-classification",
    selected: false,
  },
];

export default Performer;
