import {
  Backdrop,
  Chip,
  CircularProgress,
  Container,
  Divider,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTable } from "inno-ui";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import moment from "moment";
import Swal from "sweetalert2";
import AddSongModal from "../../../../components/molecules/Modal/AddSongModal";
import {
  DatePicker,
  FormLabel,
  ModalError,
  MonthRangePicker,
  Page,
  PrimaryButton,
  PrimaryIconButton,
  SecondaryButton,
  SelectInput,
} from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { formatFileSize, getErrors } from "../../../../utils";
import { HeaderTitle } from "layouts";
import { Grid, Typography } from "@mui/material";
import TrashVector from "../../../../assets/img/trashVector.svg";
import DocumentVector from "../../../../assets/image-public/images/vector-doc-icon.png";
import UploadVector from "../../../../assets/image-public/images/vector-upload-trans.png";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  backdrop: {
    zIndex: 100,
    color: "#fff",
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  required: { color: "red" },

  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  formDatePicker: {
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px",
      paddingBottom: "0px",
      height: "44px",
    },
  },
  pageTitle: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  pageSubTitle: {
    marginTop: "5px",
    color: "#8f8f8f",
    fontSize: "14px",
    margin: "5px 0 14px",
  },
  formSelect: {
    width: "340px",
    height: "44px",
    border: "0px solid #D1D5DC",
    borderRadius: "6px",
    margin: "6px 0 16px",
  },
  cardForm: {
    marginTop: 16,
    padding: 24,
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
    maxWidth: 1080,
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  flexCenterContainer: {
    display: "flex",
    justifyContent: "center",
  },
  flexEndContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  flexSpaceBetweenContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cancelButton: {
    borderRadius: "6px",
    width: "84px",
    height: "40px",
    border: "1px solid #D1D5DC",
    textTransform: "none",
    marginRight: 10,
  },
  submitButton: {
    marginLeft: "10px",
    backgroundColor: "#111827",
    borderRadius: "6px",
    width: "84px",
    height: "40px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#111827",
      color: "white",
    },
  },
  submitButtonDisabled: {
    marginLeft: "10px",
    backgroundColor: "gray",
    borderRadius: "6px",
    width: "84px",
    height: "40px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#111827",
      color: "white",
    },
  },
  labelDocuments: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#111827",
  },
  updloadDocuementContainer: {
    background: "#F9FAFB",
    border: "1px dashed #D1D5DC",
    borderRadius: "8px",
    padding: "16px",
    margin: "16px 0",
    position: "relative",
  },
  updloadDocuementLabel: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#111827",
  },
  inputUploadDocuments: {
    opacity: 0.0,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
  listDocumentsRow: {
    margin: "6px 0",
  },
  listDocumentsName: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#111827",
  },
  listDocumentsSize: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#687083",
  },
  listDocumentsIcon: {
    height: 18,
    marginRight: 15,
  },
  tableContainer: {
    marginTop: 16,
  },
  currecnyPrefix: {
    width: "48px",
    height: "38px",
    marginRight: "-2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "6px 0px 0px 6px",
    backgroundColor: "#F9FAFB",
    border: " 1px solid #D1D5DC",
    color: "#9AA2B",
    borderRight: "none",
  },
}));

const AddAdvancedFinance = () => {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [selectedSongs, setSelectedSongs] = useState([]);
  const [client, setClient] = useState([]);
  const [modalSong, setModalSong] = useState(false);
  const [files, setFiles] = useState(null);
  const [submiting, setSubmiting] = useState(false);
  const [selectedDSP, setSelectedDSP] = useState({});
  const [payload, setPayload] = useState({
    transaction_name: "advanced",
    transaction_number: "",
    dsp_id: 0,
    type: "flat",
    start_date: null,
    end_date: null,
    revenue: 0,
    exchange_rate: 0,
    song_id: [],
    is_all_song: false,
    invoice_date: moment(),
  });
  const isForeignCurrency =
    selectedDSP?.dsp_currency !== null &&
    selectedDSP?.dsp_currency?.currency_iso_code !== "IDR";

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };

  const getClientList = async () => {
    const urlClientList = `${hardBaseUrl}/master/all-client`;
    await axios
      .get(urlClientList, { headers })
      .then(res => {
        const resClientList = res?.data?.data;
        const choosenDSP = resClientList?.find(
          el => el?.dsp_id === resClientList?.[0]?.dsp_id
        );
        const dspCurrencyId = choosenDSP?.dsp_currency?.id;
        setClient(resClientList);
        handleChangePayload(resClientList?.[0]?.dsp_id, "dsp_id");
        setSelectedDSP(choosenDSP);
        if (isForeignCurrency) {
          getCurrency(dspCurrencyId);
        }
      })
      .catch(err => {
        new Error(err);
      });
  };

  const getCurrency = id => {
    const urlCurrency = `${hardBaseUrl}/exchange-rate/code`;
    const params = {
      currency_id: id,
      upload_date: new Date().toISOString().split("T")[0],
    };
    axios
      .get(urlCurrency, {
        headers,
        params,
      })
      .then(res => {
        handleChangePayload(res?.data?.data.value, "exchange_rate");
      })
      .catch(err => {
        new Error(err);
      });
  };

  const getInvoiceNumber = async () => {
    const urlTransactionNumber = `${hardBaseUrl}/publisher/transaction-number/advanced`;
    const params = {
      type: payload?.type,
    };
    await axios
      .get(urlTransactionNumber, {
        headers,
        params,
      })
      .then(res => {
        handleChangePayload(res?.data?.data, "transaction_number");
      })
      .catch(err => {
        new Error(err);
      });
  };
  useEffect(() => {
    getClientList();
  }, []);

  const handleChangeType = async e => {
    handleChangePayload(e.target?.value, "type");
    await getInvoiceNumber();
  };

  const handleChangeDSP = async e => {
    const selectedDspId = e?.target.value;
    const choosenDSP = client?.find(el => el?.dsp_id === selectedDspId);
    const getForeignCurrency =
      choosenDSP?.dsp_currency !== null &&
      choosenDSP?.dsp_currency?.currency_iso_code !== "IDR";
    const dspCurrencyId = choosenDSP?.dsp_currency?.id;
    handleChangePayload(selectedDspId, "dsp_id");
    setSelectedDSP(choosenDSP);
    if (getForeignCurrency) {
      getCurrency(dspCurrencyId);
    }
  };
  const handleChangeStartDate = date => {
    handleChangePayload(date, "start_date");
  };

  const handleChangeEndDate = date => {
    handleChangePayload(date, "end_date");
  };

  const handleFiles = async e => {
    e.preventDefault();

    if (e.target.files.length > 0) {
      const data = [];
      Array.from(e.target.files).forEach(file => {
        data.push(file);
      });
      setFiles(data);
    } else {
      setFiles(null);
    }
  };
  const handleClickFiles = e => {
    const { target = {} } = e || {};
    target.value = "";
  };
  const handleAdd = async () => {
    const formatPayloadPeriod = date => date.format("YYYY-MM");
    const formatPayloadDate = date => date.format("DD MMM YYYY");
    try {
      setSubmiting(true);
      const urlTransactionNumber = `${hardBaseUrl}/publisher/transaction`;
      const songIds = selectedSongs?.map(item => item.song_id);
      const cusotomPayload = {
        ...payload,
        song_id: songIds,
        start_date: formatPayloadPeriod(payload?.start_date),
        end_date: formatPayloadPeriod(payload?.end_date),
        invoice_date: formatPayloadDate(payload?.invoice_date),
        revenue: Number(payload?.revenue.replaceAll(",", "")),
        exchange_rate: isForeignCurrency
          ? Number(payload?.exchange_rate.replaceAll(",", ""))
          : 1,
      };
      const resSubmit = await axios.post(urlTransactionNumber, cusotomPayload, {
        headers,
      });

      let message = "Error";
      let icon = "error";

      if (resSubmit.data.message === "success") {
        message = "Success";
        icon = "success";

        if (files?.length > 0) {
          const formData = new FormData();
          formData.append(
            "publisher_transaction_id",
            resSubmit.data.data.publisher_transaction_id
          );
          files.forEach(file => formData.append("documents", file));

          await axios.post(
            `${hardBaseUrl}/publisher/transaction/document`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
        }
      }

      await Swal.fire({
        icon,
        title: message,
        text: resSubmit.data.message,
      });

      window.location.href = "/finance/advance";
    } catch (err) {
      ModalError(getErrors(err?.response));
    } finally {
      setSubmiting(false);
    }
  };

  const renderChip = (item, type) => {
    if (!item || (Array.isArray(item) && item.length === 0)) {
      return "-";
    }
    const itemsArray = Array.isArray(item) ? item : [item];
    return (
      <div>
        {itemsArray.map((chipItem, idx) => {
          const label = type ? chipItem[type] : chipItem;
          if (typeof label === "object") {
            return Object.keys(label).map((key, index) => (
              <Chip
                key={`${idx}-${index}`}
                label={label[key] || "-"}
                className={classes?.tableChip}
                size="small"
              />
            ));
          } else {
            return (
              <Chip
                key={idx}
                label={label || "-"}
                className={classes?.tableChip}
                size="small"
              />
            );
          }
        })}
      </div>
    );
  };

  const CustomAction = item => {
    const handleDelete = () => {
      setSelectedSongs(prevItems =>
        prevItems.filter(val => val.song_id !== item.song_id)
      );
    };
    return (
      <PrimaryIconButton
        onClick={handleDelete}
        icon={<img alt="delete" src={TrashVector} />}
      />
    );
  };

  const columnTableSongDetail = [
    {
      name: "song_title",
      title: "Song Title",
    },
    {
      name: "all",
      title: "Composer/Author Name",
      renderText: item => renderChip(item?.composer_names),
    },
    {
      name: "all",
      title: "Alias Name",
      renderText: item => renderChip(item?.composers, "alias_name"),
    },
    {
      name: "all",
      title: "Action",
      renderText: item => CustomAction(item),
    },
  ];

  return (
    <Page className={classes?.root} title="Add Advance Transaction">
      <Backdrop className={classes?.backdrop} open={submiting}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <HeaderTitle
          title="Advance Transaction"
          breadcrumbData={breadcrumbData}
        />
        <Divider className={classes?.divider} />
        <Typography variant="body2" mb={2}>
          Feature for creating song&apos;s advanced transaction
        </Typography>
        <FormLabel label="Client Name" required />
        <SelectInput
          value={payload?.dsp_id}
          onChange={handleChangeDSP}
          options={client}
          optionKey="dsp_id"
          optionLabel="dsp_name"
          width={350}
        />
        <div className={classes?.cardForm}>
          <Grid container columnSpacing={2} rowSpacing={1}>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <FormLabel label="Date" required />
              <DatePicker
                value={payload?.invoice_date}
                onChange={date => handleChangePayload(date, "invoice_date")}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <FormLabel label="Type" required />
              <SelectInput
                value={payload?.type}
                onChange={handleChangeType}
                options={selectionsType}
                optionKey="id"
                optionLabel="label"
                width="100%"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <FormLabel label="Period" required />
              <MonthRangePicker
                startDate={payload?.start_date}
                handleChangeStartDate={handleChangeStartDate}
                endDate={payload?.end_date}
                handleChangeEndDate={handleChangeEndDate}
                width="100%"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <FormLabel label="Revenue Value" required />
              <div className={classes?.flexContainer}>
                <div className={classes?.currecnyPrefix}>
                  {isForeignCurrency
                    ? selectedDSP?.dsp_currency?.currency_symbol_code
                    : "Rp"}
                </div>
                <NumberFormat
                  customInput={TextField}
                  variant="outlined"
                  thousandSeparator={true}
                  allowLeadingZeros={false}
                  onChange={e => handleChangePayload(e.target.value, "revenue")}
                  autoComplete="off"
                  value={
                    payload?.revenue !== undefined && payload?.revenue !== 0
                      ? payload?.revenue
                      : ""
                  }
                  placeholder={0}
                  className={classes.Input}
                  size="small"
                  fullWidth
                />
              </div>
            </Grid>
            {isForeignCurrency && (
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <FormLabel label="Exchange Rate" required />
                <div className={classes?.flexContainer}>
                  <div className={classes?.currecnyPrefix}>Rp</div>
                  <NumberFormat
                    customInput={TextField}
                    variant="outlined"
                    thousandSeparator={true}
                    allowLeadingZeros={false}
                    onChange={e =>
                      handleChangePayload(e.target.value, "exchange_rate")
                    }
                    autoComplete="off"
                    value={
                      payload?.exchange_rate !== undefined &&
                      payload?.exchange_rate !== 0
                        ? payload?.exchange_rate
                        : ""
                    }
                    className={classes.Input}
                    size="small"
                    fullWidth
                  />
                </div>
              </Grid>
            )}
          </Grid>
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <Grid item>
              <FormLabel label="Song Detail" />
            </Grid>
            <Grid item>
              <PrimaryButton
                label="Add Song"
                onClick={() => setModalSong(true)}
              />
            </Grid>
          </Grid>

          {modalSong && (
            <AddSongModal
              open={modalSong}
              handleClose={val => {
                setModalSong(false);
                setSelectedSongs(val);
              }}
              items={selectedSongs}
              body={payload}
              dsp_id={payload.dsp_id}
              startDate={payload.start_date}
              endDate={payload.end_date}
            />
          )}
          <div className={classes?.tableContainer}>
            <InnoTable
              columns={columnTableSongDetail}
              isLoading={false}
              isUsingCheckbox={false}
              items={selectedSongs || []}
              loadingColor={""}
            />
          </div>
          <div className={classes?.updloadDocuementContainer}>
            <input
              type="file"
              multiple
              onChange={e => handleFiles(e)}
              onClick={e => {
                handleClickFiles(e);
              }}
              className={classes?.inputUploadDocuments}
            />
            <Grid container justifyContent="center">
              <img alt="Logo" src={UploadVector} />
            </Grid>
            <Typography variant="body2" textAlign="center" mt={2}>
              Upload proof of transfer,<b>Search File</b>
            </Typography>
          </div>
          <FormLabel label="Documents Attached" required />
          {files?.map((res, index) => {
            return (
              <Grid key={index} container justifyContent="space-between" my={2}>
                <Grid item>
                  <Grid item container columnSpacing={2} alignItems="center">
                    <Grid item>
                      <img alt="Logo" src={DocumentVector} />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">{res.name}</Typography>
                      <Typography variant="body2">
                        {formatFileSize(res.size)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <PrimaryIconButton
                    onClick={() => setFiles(files.filter(dev => dev !== res))}
                    icon={<img alt="delete" src={TrashVector} />}
                  />
                </Grid>
              </Grid>
            );
          })}
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <SecondaryButton
                label="Cancel"
                onClick={() => history.push("/admin/advance")}
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                label="Add"
                onClick={handleAdd}
                disabled={submiting}
              />
            </Grid>
          </Grid>
        </div>
      </Container>
    </Page>
  );
};

const selectionsType = [
  { id: "recoupment", label: "Recoupment" },
  { id: "flat", label: "Flat" },
];

const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Transaction",
    link: "/admin/advance",
  },
  {
    label: "Advance",
    link: "/admin/advance",
  },
  {
    label: "Create Transaction",
    active: true,
  },
];
export default AddAdvancedFinance;
