import { Button, Divider, Typography, Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { BackupOutlined } from "@material-ui/icons";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { makeStyles } from "@material-ui/styles";
import React, { useRef } from "react";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    color: "#9AA2B1",
    cursor: "pointer",
    width: "12px",
    height: "12px",
    marginTop: "8px",
  },
  btnBack: {
    backgroundColor: "#25383c",
    color: "#fff",
    marginLeft: "10px",
    marginRight: "20px",
  },
  btnSubmit: {
    border: "1px solid lightgrey",
  },
  title: {
    fontSize: "16px",
    fontWeight: 700,
  },
  sectionTitle: {
    fontSize: "14px",
    fontWeight: 500,
  },
  uploadedFileList: {
    listStyle: "none",
    marginTop: "20px",
  },
  dropzone: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "grey",
  },
  dropzoneIcon: {
    fontSize: "40px",
  },
  actionContainer: {
    display: "flex",
    justifyContent: "end",
    marginBottom: "20px",
  },
}));

const DocumentImportDialog = ({
  open,
  onClose,
  onSubmit,
  getRootProps,
  getInputProps,
  isDragActive,
  files,
}) => {
  const classes = useStyles();
  const dialogRef = useRef();

  return (
    <div>
      <Dialog
        size="small"
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
        ref={dialogRef}
      >
        <DialogTitle id="responsive-dialog-title">
          <Box display="flex" justifyContent="space-between">
            <ThemeProvider theme={theme}>
              <Typography className={classes.title}>
                Import Document
              </Typography>
            </ThemeProvider>
            <CloseOutlinedIcon onClick={onClose} className={classes.closeButton} />
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            <ThemeProvider theme={theme}>
              <Typography className={classes.sectionTitle}>
                Upload Document
              </Typography>
            </ThemeProvider>
          </DialogContentText>
          <div {...getRootProps()} className={classes.dropzone}>
            <input {...getInputProps()} />
            <BackupOutlined className={classes.dropzoneIcon} />
            {isDragActive ? (
              <Typography>Drop the files here ...</Typography>
            ) : (
              <Typography><b>Search File</b>, Drag Document Here</Typography>
            )}
          </div>
          <aside className={classes.uploadedFileList}>
            <ThemeProvider theme={theme}>
              <Typography className={classes.sectionTitle}>
                Uploaded File
              </Typography>
              <ul>{files}</ul>
            </ThemeProvider>
          </aside>
        </DialogContent>
        <Divider className={classes.divider} />
        <div className={classes.actionContainer}>
          <Button
            className={classes.btnSubmit}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button className={classes.btnBack} onClick={onSubmit}>
            Upload
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default DocumentImportDialog;
