import { makeStyles } from "@material-ui/core";
import { Clear, Search } from "@material-ui/icons";
import { IconButton } from "@mui/material";
import { TextInput } from "components";

const SearchTextInput = ({
  placeholder,
  value,
  onChange,
  label,
  clear,
  onClear,
  width,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <TextInput
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      startAdornment={<Search className={classes?.searchIcon} />}
      endAdornment={
        value &&
        clear && (
          <IconButton onClick={onClear}>
            <Clear />
          </IconButton>
        )
      }
      width={width}
      {...rest}
    />
  );
};

const useStyles = makeStyles(() => ({
  searchIcon: {
    color: "#9AA2B1",
  },
}));
export default SearchTextInput;
