import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./LoginAdmin.css";
import mpisLogo from "../../../assets/img/newMpisLogo.webp";
import {
  MailOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import Axios from "axios";
import Swal from "sweetalert2";
import { hardBaseUrl, hardTypeWeb } from "../../../services/urlConstant";
import backgroundLogin from "../../../assets/background/background_login.webp";
import { Box, Button, Grid, styled, Typography } from "@mui/material";
import { PrimaryButton, TextInput } from "components";
import { makeStyles } from "@material-ui/core";
import { LockOutlined } from "@material-ui/icons";

export default function LoginAdmin() {
  const classes = useStyles();
  let history = useHistory();
  const message = useSelector(state => state.auth.message);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);
  const [dataLogin, setDataLogin] = useState({
    email: "",
    password: "",
  });
  const [loginDisabled, setLoginDisabled] = useState(false);
  const toggleShowPassword = () =>
    setShowPassword(showPassword => !showPassword);
  useEffect(() => {
    if (hardTypeWeb === "caris") {
      history.push({
        pathname: "/login",
      });
    }
    localStorage.removeItem("auth");
    localStorage.removeItem("role");
    localStorage.removeItem("role_id");
  }, []);

  useEffect(() => {
    if (message !== null) {
      window.location.reload();
    }
  }, [message]);

  const handleLogin = async e => {
    e.preventDefault();
    setLoginDisabled(true);
    let newDataLogin = {
      email: dataLogin.email,
      password: dataLogin.password,
      subdomain: "",
      remember_me: false,
    };
    try {
      let resultLogin = await Axios.post(
        hardBaseUrl + "/auth/login",
        newDataLogin,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (
        resultLogin.data.meta.http_status === 200 &&
        resultLogin.data.message === "success"
      ) {
        if (typeof resultLogin.data.data !== "object") {
          localStorage.setItem("url_login", history.location.pathname);
          history.push("/otp", { email: dataLogin.email });
        } else {
          localStorage.setItem("url_login", history.location.pathname);
          let resultMe = await Axios.get(hardBaseUrl + "/me", {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + resultLogin.data.data.access_token,
            },
          });
          if (
            resultMe.data.meta.http_status === 200 &&
            resultMe.data.message === "success"
          ) {
            if (resultMe.data.data.role.type === "publisher") {
              if (resultMe.data.data.publisher.theme_color === "") {
                localStorage.setItem("themeColor", "#9545eb");
              } else {
                localStorage.setItem(
                  "themeColor",
                  resultMe.data.data.publisher.theme_color
                );
              }
              localStorage.setItem("reminder", "reminder");
              history.push("/redirect/" + resultLogin.data.data.access_token);
            } else {
              localStorage.setItem("themeColor", "#9545eb");
              history.push("/redirect/" + resultLogin.data.data.access_token);
            }
          }
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: error.response.data.errors[0].message || "Login Failed.",
        showCloseButton: false,
      }).then(() => {
        setLoginDisabled(false);
      });
    }
  };

  const handleForgot = async e => {
    e.preventDefault();
    try {
      await Axios.post(hardBaseUrl + "/auth/forgot", {
        email: dataLogin.email,
      });
    } catch (error) {
      new Error(error);
    }
    setTimeout(() => {
      setForgotPassword(false);
      setSentEmail(true);
    }, 500);
  };

  return (
    <Grid container columns={5}>
      <Grid item xs={5} md={2} data-testid="data-test-page-login-mpis">
        <FormContainer>
          <Box maxWidth="600px" mx="auto">
            <Box
              mb="24px"
              textAlign={forgotPassword || sentEmail ? "center" : "left"}
            >
              <img src={mpisLogo} alt="logo" className="logo" width={"140px"} />
            </Box>
            <Box>
              <Typography
                fontFamily="inter"
                fontSize="36px"
                fontWeight={700}
                textAlign={forgotPassword || sentEmail ? "center" : "left"}
              >
                {sentEmail
                  ? "Email Successfully Sent"
                  : forgotPassword
                  ? "Forgot the password"
                  : "Welcome Back !"}
              </Typography>
              <Typography
                fontFamily="inter"
                color="#687083"
                fontSize="16px"
                textAlign={forgotPassword || sentEmail ? "center" : "left"}
                mb="24px"
              >
                {sentEmail
                  ? "You will receive a password recovery link at your email address."
                  : forgotPassword
                  ? "Enter the registered e-mail or mobile number. We will send you a verification code to reset your password."
                  : "Let's start manage and setting your music, composer and label easily with us "}
              </Typography>

              {sentEmail ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography fontFamily="inter" color="#687083">
                    Haven&apos;t received an email yet
                  </Typography>
                  <TextButton
                    onClick={() => setSentEmail(false)}
                    variant="text"
                  >
                    Resend
                  </TextButton>
                </Box>
              ) : (
                <Fragment>
                  <Box my="10px">
                    {!forgotPassword && (
                      <Typography
                        fontFamily="inter"
                        fontSize="14px"
                        fontWeight={600}
                        mb="8px"
                      >
                        Email/ Phone Number
                      </Typography>
                    )}
                    <TextInput
                      placeholder="Email/ Phone Number"
                      onChange={e =>
                        setDataLogin({ ...dataLogin, email: e.target.value })
                      }
                      value={dataLogin.email}
                      startAdornment={<MailOutlined />}
                      disabled={loginDisabled}
                      width="100%"
                    />
                  </Box>
                  {!forgotPassword && (
                    <Box my="10px">
                      <Typography
                        fontFamily="inter"
                        fontSize="14px"
                        fontWeight={600}
                        mb="8px"
                      >
                        Password
                      </Typography>
                      <TextInput
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        onChange={e =>
                          setDataLogin({
                            ...dataLogin,
                            password: e.target.value,
                          })
                        }
                        value={dataLogin.password}
                        startAdornment={
                          <LockOutlined className={classes?.lockIcon} />
                        }
                        endAdornment={
                          showPassword ? (
                            <EyeTwoTone onClick={toggleShowPassword} />
                          ) : (
                            <EyeInvisibleOutlined
                              onClick={toggleShowPassword}
                            />
                          )
                        }
                        disabled={loginDisabled}
                        width="100%"
                      />
                    </Box>
                  )}
                  <Box my="10px">
                    {!forgotPassword && (
                      <Box display="flex" justifyContent="end">
                        <TextButton
                          disabled={loginDisabled}
                          onClick={() => setForgotPassword(true)}
                          variant="text"
                        >
                          Forgot password?
                        </TextButton>
                      </Box>
                    )}
                  </Box>
                  <Box my="10px">
                    <PrimaryButton
                      label={
                        loginDisabled
                          ? "Logging In"
                          : forgotPassword
                          ? "Continue"
                          : "Login"
                      }
                      disabled={loginDisabled}
                      loading={loginDisabled}
                      onClick={forgotPassword ? handleForgot : handleLogin}
                      width="100%"
                    />
                  </Box>
                  <Box mt="10px">
                    {forgotPassword && (
                      <TextButton
                        onClick={() => setForgotPassword(false)}
                        variant="text"
                        customColor="#D1D5DC"
                        width="100%"
                      >
                        Return
                      </TextButton>
                    )}
                  </Box>
                </Fragment>
              )}
            </Box>
          </Box>
        </FormContainer>
      </Grid>
      <Grid xs={0} md={3}>
        <ImageContainer />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  lockIcon: {
    width: "16px",
  },
}));
const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "100vh",
  padding: "0 100px",
});
const ImageContainer = styled(Box)({
  width: "100%",
  height: "100%",
  backgroundImage: `url(${backgroundLogin})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
});
const TextButton = styled(Button)(({ customColor, width }) => ({
  color: customColor || "#9545EB",
  textTransform: "none",
  width,
}));
