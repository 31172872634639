import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import axios from "axios";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import DialogImportDokumen from "./DialogImportDokumen";
import ModalConfirm from "./ModalConfirm";
import "./style.scss";
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  tableChip: {
    marginTop: "5px",
  },
}));
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#303030",
  borderStyle: "thin",
  backgroundColor: "lightgray",
  color: "black",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};
const activeStyle = {
  borderColor: "#2196f3",
};
const acceptStyle = {
  borderColor: "#00e676",
};
const rejectStyle = {
  borderColor: "#ff1744",
};
const DetailClaimAndUsage = props => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const { state } = useLocation();
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [dspName, setDspName] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [notes, setNotes] = useState("-");
  const [profileImage, setProfileImage] = useState("");
  const [claimStatus, setClaimStatus] = useState("");
  const [subject, setSubject] = useState("");
  const [revenue, setRevenue] = useState("");
  const [period, setPeriod] = useState("");
  const [modalApprove, setModalApprove] = useState(false);
  const [modalReject, setModalReject] = useState(false);
  const [dokumen, setDokumen] = useState([]);
  const [modalDocument, setModalDocument] = useState(false);
  const [type, setType] = useState("");
  const [songData, setSongData] = useState([]);
  const [taxVal, setTaxVal] = useState([]);
  const [totalAfterTax, setTotalAfterTax] = useState("");
  const [numberClient, setNumberClient] = useState({
    accountNumber: "",
    invoiceNumber: "",
    poNumber: "",
    jobNumber: "",
  });
  const [dspId, setDspId] = useState("");
  const [dspSelected, setDspSelected] = useState("");
  const [listBankAccount, setListAccountBank] = useState([]);
  const [transactionPaymentDetail, setTransactionPaymentDetail] = useState({});
  const [invoiceDetail, setInvoiceDetail] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const onDrop = useCallback(acceptedFiles => {
    setDokumen(prevFiles => [...prevFiles, ...acceptedFiles]);
  }, []);
  const [exchangeRate, setExchangeRate] = useState(0);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
  });
  const handleRemoveFile = idx => {
    setDokumen(dokumen.filter((_, i) => i !== idx));
    acceptedFiles.splice(idx, 1);
  };
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  const handleChangeExchangeRate = value => setExchangeRate(!value ? 0 : value);
  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };
  const files = dokumen.map((file, i) => {
    return (
      <li key={file.name}>
        <Card
          style={{
            backgroundColor: "white",
            boxShadow: "none",
            borderRadius: "6px",
            border: "1px solid #E5E5E5",
            marginTop: "5px",
          }}
        >
          <CardContent>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                <InsertDriveFileOutlinedIcon
                  style={{
                    color: "#9AA2B1",
                    width: "13.33px",
                    height: "15px",
                    marginTop: "3px",
                  }}
                />
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    marginLeft: "5px",
                  }}
                >
                  {truncate(file.name, 20)}
                </Typography>
              </div>
              <div>
                <CloseOutlinedIcon
                  onClick={() => handleRemoveFile(i)}
                  style={{
                    color: "#364052",
                    width: "12px",
                    height: "12px",
                    marginTop: "3px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </li>
    );
  });
  const handleOpenModalApprove = () => {
    setModalApprove(true);
  };
  const handleOpenModalReject = () => {
    setModalReject(true);
  };
  const handleOpenModalDocument = () => {
    setModalDocument(true);
  };
  const handleCloseModalApprove = () => {
    setModalApprove(false);
  };
  const handleCloseModalReject = () => {
    setModalReject(false);
  };
  const handleCloseModalDocument = () => {
    setModalDocument(false);
  };
  const periodTime = (time) => {
    // Split the input string into start and end dates
    const [startDate, endDate] = time.split(" - ");

    // Parse and format each date
    const formattedStartDate = moment(startDate.trim(), "D MMMM YYYY").format("DD MMM YYYY");
    const formattedEndDate = moment(endDate.trim(), "D MMMM YYYY").format("DD MMM YYYY");

    // Combine the formatted dates into the final result
    return `${formattedStartDate} - ${formattedEndDate}`;
  };
  const getResponse = async () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/transaction/detail/${state.customer.publisher_transaction_id}`;
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setCompanyName(res.data.data.publisher.name);
        setAddress(res.data.data.publisher.address);
        setDspName(res.data.data.dsp_name);
        setInvoiceDate(moment(res.data.data.invoice_date).format("DD MMM YYYY"));
        setProfileImage(res.data.data.publisher.profile_image);
        setClaimStatus(res.data.data.status);
        setSubject(res.data.data.type);
        setRevenue(res.data.data.revenue);
        setPeriod(periodTime(res.data.data.period));
        setNotes(res.data.data.note);
        setType(res.data.data.type);
        setSongData(res.data.data.songs);
        setTaxVal(res.data.data.taxes);
        setDspId(res.data.data.dsp_id);
        setTransactionPaymentDetail(res.data.data.transaction_payment_detail);
        setInvoiceDetail(res?.data?.data);
        setNumberClient({
          accountNumber: res.data.data.account_number,
          jobNumber: res.data.data.job_number,
          invoiceNumber:
            res.data.data.invoice_number || res.data.data.transaction_number,
          poNumber: res.data.data.po_number,
        });
        if (res.data.data.type === "claim") {
          let changForm = res.data.data.revenue;
          const tempTaxes = res.data.data.transaction_payment_detail.taxes?.map(
            el => {
              return {
                ...el,
                afterCountTax: el.value,
              };
            }
          );
          setTaxVal(tempTaxes);
          let sumAllTaxes =
            Number(
              tempTaxes?.reduce(
                (acc, crrnt) =>
                  crrnt.method === "addition"
                    ? acc + Number(crrnt.afterCountTax)
                    : acc - Number(crrnt.afterCountTax),
                0
              )
            ) + Number(changForm);
          let newRes = sumAllTaxes;
          setRevenue(res.data.data.revenue);
          setTotalAfterTax(
            res.data.data.transaction_payment_detail?.taxes?.length
              ? newRes
              : res.data.data.revenue
          );
        } else {
          let theSongs = res.data.data.songs;
          let tempNum = [];
          theSongs.forEach(item => {
            let formatNum = Number(item.revenue) * Number(item?.qty);
            tempNum.push(formatNum);
          });
          let resSum = tempNum.reduce((a, b) => a + b, 0);
          const tempTaxes = res.data.data.transaction_payment_detail.taxes?.map(
            el => {
              return {
                ...el,
                afterCountTax: el.value,
              };
            }
          );
          setTaxVal(tempTaxes);
          let newRes = resSum || res.data.data.revenue;
          setRevenue(newRes);
          let aft =
            Number(
              tempTaxes?.reduce(
                (acc, crrnt) =>
                  crrnt.method === "addition"
                    ? acc + Number(crrnt.afterCountTax)
                    : acc - Number(crrnt.afterCountTax),
                0
              )
            ) + (resSum || res.data.data.revenue);
          let aftRes = aft;
          setTotalAfterTax(
            res.data.data.transaction_payment_detail?.taxes?.length
              ? aftRes
              : res.data.data.revenue
          );
          const temArr = [];
          temArr.push(res.data.data);
        }
      })
      .catch(() => { });
  };
  const getClientList = useCallback(async () => {
    const token = localStorage.getItem("token");
    const urlClientList = `${hardBaseUrl}/master/all-client`;
    await axios
      .get(urlClientList, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setDspSelected(res.data.data.find(dsp => dsp.dsp_id === dspId));
      })
      .catch(() => { });
  }, [dspId]);
  useEffect(() => {
    getResponse();
    getListBankAccount();
    getClientList();
  }, []);
  useEffect(() => {
    getClientList();
  }, [getClientList]);
  useEffect(() => { }, [dspSelected]);
  const getListBankAccount = async () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/bank`;
    try {
      const listBank = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (listBank.data.meta.http_status === 200) {
        setListAccountBank(listBank.data.data);
      }
    } catch (err) {
      new Error(err);
    }
  };
  const chipColor = item => {
    const status = item?.status;
    const statusUpdate = item?.status_update;
    if (
      statusUpdate === "rejected" ||
      status === "rejected" ||
      status === "unpaid"
    ) {
      return "#ff000010";
    } else if (status === "waiting_for_approval") {
      return "#F6C962";
    } else {
      return "#8DE5AB";
    }
  };
  const chipTextColor = item => {
    const status = item?.status;
    const statusUpdate = item?.status_update;
    if (
      statusUpdate === "rejected" ||
      status === "rejected" ||
      status === "unpaid"
    ) {
      return "#A63124";
    } else if (status === "waiting_for_approval") {
      return "#A84D20";
    } else {
      return "#34774C";
    }
  };
  const chipText = item => {
    const status = item?.status;
    const statusUpdate = item?.status_update;
    if (statusUpdate === "rejected" || status === "rejected") {
      return "Rejected";
    } else if (status === "waiting_for_approval") {
      return "Waiting for approval";
    } else {
      return <div style={{ textTransform: "capitalize" }}>{status}</div>;
    }
  };
  const handlePrintPDF = () => {
    const date = new Date(invoiceDate);
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const newDate = date.toLocaleDateString("en-GB", options);
    const newDate2 = newDate.replaceAll("/", "");
    html2canvas(inputRef.current, { useCORS: true }).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`${dspName}_${type}_${newDate2}.pdf`);
    });
  };
  const showButton = () => {
    if (claimStatus === "waiting_for_approval") {
      return (
        <>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <>
              <Button
                style={{
                  marginRight: "14px",
                  backgroundColor: "white",
                  color: "#111827",
                  border: "1px solid #111827",
                  borderRadius: "6px",
                }}
                variant="contained"
                size="large"
                onClick={handleOpenModalReject}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    component="span"
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "500",
                      fontStyle: "normal",
                      textTransform: "none",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Reject
                  </Typography>
                </ThemeProvider>
              </Button>
            </>
            <>
              <Button
                style={{
                  backgroundColor: "#111827",
                  color: "white",
                  padding: "10px 28px 10px 28px",
                  borderRadius: "6px",
                }}
                variant="contained"
                size="large"
                disabled={buttonDisabled}
                onClick={handleOpenModalApprove}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    component="span"
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "500",
                      fontStyle: "normal",
                      textTransform: "none",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Approve
                  </Typography>
                </ThemeProvider>
              </Button>
            </>
          </div>
        </>
      );
    } else if (
      claimStatus === "unpaid" &&
      invoiceDetail?.status_update !== "rejected"
    ) {
      return (
        <>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <>
              <Button
                style={{
                  marginRight: "14px",
                  backgroundColor: "white",
                  color: "#111827",
                  border: "1px solid #111827",
                  borderRadius: "6px",
                }}
                variant="contained"
                size="large"
                disabled={buttonDisabled}
                onClick={handleOpenModalReject}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    component="span"
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "500",
                      fontStyle: "normal",
                      textTransform: "none",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Reject
                  </Typography>
                </ThemeProvider>
              </Button>
            </>
            <>
              <Button
                style={{
                  backgroundColor: "#111827",
                  color: "white",
                  padding: "10px 28px 10px 28px",
                  borderRadius: "6px",
                }}
                variant="contained"
                size="large"
                disabled={buttonDisabled}
                onClick={handleOpenModalDocument}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    component="span"
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "500",
                      fontStyle: "normal",
                      textTransform: "none",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Confirm
                  </Typography>
                </ThemeProvider>
              </Button>
            </>
          </div>
        </>
      );
    }
  };
  const myGet = item => {
    let arrChip = [];
    if (item.length > 0) {
      let myArray = item;
      myArray.forEach(newItem => {
        arrChip.push(newItem);
      });
    }
    return (
      <>
        <div>
          <ThemeProvider theme={theme}>
            {arrChip.map((newitem, idx) => {
              return (
                <div key={idx}>
                  <div key={idx} className={classes.tableChip} size="small">
                    {newitem}{" "}
                  </div>
                </div>
              );
            })}
          </ThemeProvider>
        </div>
      </>
    );
  };
  const handleChangeStatus = (e, status) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    setButtonDisabled(true);
    const url = `${hardBaseUrl}/transaction/proof/${state.customer.publisher_transaction_id}`;
    const formData = new FormData();
    formData.append("status", status);
    axios
      .put(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setModalApprove(false);
        setModalReject(false);
        setButtonDisabled(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Status has been changed",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(() => {
        setModalApprove(false);
        setButtonDisabled(false);
      });
  };
  const handleUploadDocument = (e, status) => {
    e.preventDefault();
    setButtonDisabled(true);
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/transaction/proof/${state.customer.publisher_transaction_id}`;
    const formData = new FormData();
    formData.append("status", status);
    for (let i = 0; i < dokumen.length; i++) {
      formData.append("file_name", dokumen[i]);
    }
    if (
      dspSelected?.dsp_currency?.currency_symbol_code &&
      dspSelected?.dsp_currency?.currency_symbol_code !== "Rp"
    )
      formData.append("exchange_rate", exchangeRate);
    axios
      .put(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setModalDocument(false);
        setButtonDisabled(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Status has been changed",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(() => {
        setModalDocument(false);
        setButtonDisabled(false);
      });
  };
  return (
    <Page className={classes.root} title="Claim Detail">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Invoice
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>
              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>
              <Typography className={classes.breadCrumbsActive}>
                Detail Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <div>
                  <ThemeProvider theme={theme}>
                    <Typography
                      component="h1"
                      variant="h3"
                      className={classes.subTitle}
                    >
                      Transaction Detail
                    </Typography>
                  </ThemeProvider>
                  <ThemeProvider theme={theme}>
                    <p
                      style={{
                        marginTop: "5px",
                        color: "#8f8f8f",
                        fontSize: "14px",
                      }}
                    >
                      View details of the transaction.
                    </p>
                  </ThemeProvider>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Chip
                    label={chipText(invoiceDetail)}
                    style={{
                      backgroundColor: chipColor(invoiceDetail),
                      border: "1px thin #fff",
                      color: chipTextColor(invoiceDetail),
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
              ref={inputRef}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <img
                      src={profileImage}
                      alt="img"
                      style={{
                        width: "155.52px",
                        height: "50px",
                        objectFit: "contain",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <div>
                        <ThemeProvider theme={theme}>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "20px",
                            }}
                          >
                            {companyName}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "17px",
                              color: "#6F6F84",
                              wordWrap: "break-word",
                            }}
                          >
                            {address}
                          </Typography>
                        </ThemeProvider>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "16.94px",
                        }}
                      >
                        Claim to :
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "16.94px",
                        }}
                      >
                        {dspName}
                      </Typography>
                    </ThemeProvider>
                    <div
                      style={{
                        marginTop: "14px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Subject :
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "16.94px",
                            textTransform: "capitalize",
                          }}
                        >
                          {subject}
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "14px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Invoice Number :
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "16.94px",
                            textTransform: "capitalize",
                          }}
                        >
                          {numberClient?.invoiceNumber}
                        </Typography>
                      </ThemeProvider>
                    </div>
                    {numberClient?.jobNumber && (
                      <div
                        style={{
                          marginTop: "14px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "16.94px",
                            }}
                          >
                            Job Number :
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "16.94px",
                              textTransform: "capitalize",
                            }}
                          >
                            {numberClient?.jobNumber}
                          </Typography>
                        </ThemeProvider>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <ThemeProvider theme={theme}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          Date :
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16.94px",
                          }}
                        >
                          {invoiceDate}
                        </Typography>
                      </div>
                    </ThemeProvider>
                    <div
                      style={{
                        marginTop: "14px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "16.94px",
                              display: "none",
                            }}
                          >
                            Type :
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "16.94px",
                              display: "none",
                            }}
                          >
                            {type}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "16.94px",
                            }}
                          >
                            Usage Period :
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "16.94px",
                            }}
                          >
                            {period}
                          </Typography>
                        </div>
                        <div
                          style={{
                            marginTop: "14px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "16.94px",
                            }}
                          >
                            PO Number :
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "16.94px",
                            }}
                          >
                            {numberClient?.poNumber}
                          </Typography>
                        </div>
                        {numberClient?.accountNumber && (
                          <div
                            style={{
                              marginTop: "14px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <ThemeProvider theme={theme}>
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "16.94px",
                                }}
                              >
                                Account Number :
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  lineHeight: "16.94px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {`${numberClient?.accountNumber} ${listBankAccount.find(
                                  el =>
                                    el.account_number ===
                                    numberClient?.accountNumber
                                )?.bank?.name
                                  }`}
                              </Typography>
                            </ThemeProvider>
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "14px",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "16.94px",
                            }}
                          >
                            Fee :
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "16.94px",
                            }}
                          >
                            <NumberFormat
                              displayType="text"
                              value={revenue}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              prefix={
                                (dspSelected?.dsp_currency
                                  ?.currency_symbol_code || "Rp") + " "
                              }
                              suffix=",-"
                            />
                          </Typography>
                        </div>
                      </ThemeProvider>
                    </div>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                {type === "claim" ? (
                  <>
                    <Card
                      style={{
                        border: "1px solid #9AA2B1",
                        borderRadius: "6px",
                        boxShadow: "none",
                        marginTop: "14px",
                        marginBottom: "14px",
                      }}
                    >
                      <Table id="table-claim" size="small">
                        <TableHead>
                          <TableRow>
                            <ThemeProvider theme={theme}>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Fee
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Notes
                                </Typography>
                              </TableCell>
                            </ThemeProvider>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                <NumberFormat
                                  displayType="text"
                                  value={revenue}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  prefix={
                                    (dspSelected?.dsp_currency
                                      ?.currency_symbol_code || "Rp") + " "
                                  }
                                  suffix=",-"
                                />
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {notes}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Card>
                  </>
                ) : (
                  <>
                    <Card
                      style={{
                        border: "1px solid #9AA2B1",
                        borderRadius: "6px",
                        boxShadow: "none",
                        marginTop: "14px",
                        marginBottom: "14px",
                      }}
                    >
                      <Table id="table-usage" size="small">
                        <TableHead>
                          <TableRow>
                            <ThemeProvider theme={theme}>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Song
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Composer/Author
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Publisher Percentage
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Type
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Fee
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Qty
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Total Fee
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.headText}
                                >
                                  Notes
                                </Typography>
                              </TableCell>
                            </ThemeProvider>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {songData !== null ? (
                            songData.length > 0 ? (
                              songData.map(customer => (
                                <TableRow hover key={customer.song_id}>
                                  <TableCell>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.bodyTextBlack}
                                    >
                                      {customer.song_title}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.bodyTextGrey}
                                    >
                                      {customer?.composers.length &&
                                        customer?.composers.map(composer => {
                                          return (
                                            <Tooltip
                                              key={composer?.composer_id}
                                              title={composer?.sure_name}
                                              placement="bottom"
                                            >
                                              <Typography
                                                variant="subtitle2"
                                                className={classes.bodyTextGrey}
                                                style={{
                                                  height: "37.63px",
                                                  display: "-webkit-box",
                                                  overflow: "hidden",
                                                  WebkitBoxOrient: "vertical",
                                                  WebkitLineClamp: 2,
                                                  WebkitAlignItems: "top",
                                                  verticalAlign: "top",
                                                  alignItems: "top",
                                                  textAlign: "top",
                                                }}
                                              >
                                                {composer?.sure_name}
                                              </Typography>
                                            </Tooltip>
                                          );
                                        })}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.bodyTextBlack}
                                    >
                                      {customer?.composers.length &&
                                        customer?.composers.map(composer => {
                                          return (
                                            <Tooltip
                                              key={composer?.composer_id}
                                              title={composer?.sure_name}
                                              placement="bottom"
                                            >
                                              <Typography
                                                variant="subtitle2"
                                                className={classes.bodyTextGrey}
                                                style={{
                                                  height: "37.63px",
                                                  display: "-webkit-box",
                                                  overflow: "hidden",
                                                  WebkitBoxOrient: "vertical",
                                                  WebkitLineClamp: 2,
                                                  WebkitAlignItems: "top",
                                                  verticalAlign: "top",
                                                  alignItems: "top",
                                                  textAlign: "top",
                                                }}
                                              >
                                                {`${composer?.publisher_share_percentage} %`}
                                              </Typography>
                                            </Tooltip>
                                          );
                                        })}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.bodyTextBlack}
                                    >
                                      {customer.type}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.bodyTextBlack}
                                    >
                                      <NumberFormat
                                        displayType="text"
                                        value={customer.revenue}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        prefix={
                                          (dspSelected?.dsp_currency
                                            ?.currency_symbol_code || "Rp") +
                                          " "
                                        }
                                        suffix=",-"
                                      />
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.bodyTextBlack}
                                    >
                                      {customer?.qty}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.bodyTextBlack}
                                    >
                                      <NumberFormat
                                        displayType="text"
                                        value={customer.revenue * customer.qty}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        prefix={
                                          (dspSelected?.dsp_currency
                                            ?.currency_symbol_code || "Rp") +
                                          " "
                                        }
                                        suffix=",-"
                                      />
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.bodyTextBlack}
                                    >
                                      {customer.notes}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={5}>
                                  <center>
                                    <ThemeProvider theme={theme}>
                                      <Typography
                                        variant="h6"
                                        style={{
                                          fontWeight: 400,
                                          color: "#687083",
                                          fontSize: "14px",
                                        }}
                                      >
                                        There&apos;s no data available.
                                      </Typography>
                                    </ThemeProvider>
                                  </center>
                                </TableCell>
                              </TableRow>
                            )
                          ) : (
                            <TableRow>
                              <TableCell colSpan={5}>
                                <center>
                                  <ThemeProvider theme={theme}>
                                    <Typography
                                      variant="h6"
                                      style={{
                                        fontWeight: 400,
                                        color: "#687083",
                                        fontSize: "14px",
                                      }}
                                    >
                                      There&apos;s no data available.
                                    </Typography>
                                  </ThemeProvider>
                                </center>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Card>
                  </>
                )}
                <div
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <>
                    <Card
                      style={{
                        border: "0px solid #9AA2B1",
                        borderRadius: "6px",
                        boxShadow: "none",
                        marginBottom: "10px",
                        backgroundColor: "#fafafa",
                        padding: "14px",
                        marginTop: "28px",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={4} xl={4}>
                          Fee
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} xl={4}>
                          {""}
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} xl={4}>
                          <div
                            style={{
                              textAlign: "right",
                              fontSize: "18px",
                            }}
                          >
                            { }
                            <NumberFormat
                              displayType="text"
                              value={revenue}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              prefix={
                                (dspSelected?.dsp_currency
                                  ?.currency_symbol_code || "Rp") + " "
                              }
                              suffix=",-"
                            />
                          </div>
                        </Grid>
                      </Grid>
                      {taxVal?.length ? (
                        <Grid container spacing={3}>
                          {taxVal.map(dataTax => {
                            return (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  xl={4}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div>
                                    {dataTax.code + ` (${dataTax.rate}%)`}
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  xl={4}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    textAlign: "center",
                                  }}
                                >
                                  { }
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  xl={4}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      textAlign: "right",
                                      fontSize: "18px",
                                    }}
                                  >
                                    { }
                                    <NumberFormat
                                      displayType="text"
                                      value={dataTax?.afterCountTax}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      prefix={
                                        (dspSelected?.dsp_currency
                                          ?.currency_symbol_code || "Rp") + " "
                                      }
                                      suffix=",-"
                                    />
                                  </div>
                                </Grid>
                              </>
                            );
                          })}
                        </Grid>
                      ) : null}
                      <Divider className={classes.divider} />
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={4} xl={4}>
                          <div
                            style={{
                              fontSize: "18px",
                            }}
                          >
                            Total Fee
                          </div>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
                        <Grid item xs={12} md={4} lg={4} xl={4}>
                          <div
                            style={{
                              textAlign: "right",
                              fontSize: "22px",
                            }}
                          >
                            <NumberFormat
                              displayType="text"
                              style={{ fontWeight: "bold" }}
                              value={totalAfterTax}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              prefix={
                                (dspSelected?.dsp_currency
                                  ?.currency_symbol_code || "Rp") + " "
                              }
                              suffix=",-"
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                          <div
                            style={{
                              textAlign: "right",
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            {dspSelected?.domicile === "overseas"
                              ? transactionPaymentDetail?.total_revenue_in_words
                                ?.en
                              : transactionPaymentDetail?.total_revenue_in_words
                                ?.id}
                          </div>
                        </Grid>
                      </Grid>
                    </Card>
                  </>
                  {taxVal?.length === 0 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                textAlign: "right",
                              }}
                            >
                              Total Fee:
                            </Typography>
                          </ThemeProvider>
                        </div>
                        <div>
                          <ThemeProvider theme={theme}>
                            <Typography
                              style={{
                                fontSize: "24px",
                                fontWeight: "600",
                                textAlign: "right",
                              }}
                            >
                              { }
                              <NumberFormat
                                displayType="text"
                                value={totalAfterTax}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={
                                  (dspSelected?.dsp_currency
                                    ?.currency_symbol_code + " " || "Rp") + " "
                                }
                                suffix=",-"
                              />
                            </Typography>
                          </ThemeProvider>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
                { }
                <div className="claim-usage-footer">
                  <div className="claim-usage-footer-sign">
                    <h5>Sign From : </h5>
                    <h5>Sign To : </h5>
                  </div>
                  <div className="claim-usage-footer-value">
                    <h4>{companyName}</h4>
                    <h4>{dspName}</h4>
                  </div>
                </div>
                { }
              </CardContent>
            </Card>
            {props.rolesFor === "finance" ? (
              <>{showButton()}</>
            ) : claimStatus === "paid" ? (
              <></>
            ) : (
              <></>
            )}
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#111827",
                  color: "white",
                  padding: "10px 28px 10px 28px",
                  borderRadius: "6px",
                }}
                variant="contained"
                size="large"
                onClick={handlePrintPDF}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    component="span"
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "500",
                      fontStyle: "normal",
                      textTransform: "none",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Download As PDF
                  </Typography>
                </ThemeProvider>
              </Button>
            </div>
            <ModalConfirm
              open={modalApprove}
              onClose={handleCloseModalApprove}
              title="Approve Claim Transaction"
              subTitle={
                "Are you sure you want to approve this claim transaction?"
              }
              type="approve"
              onClick={e => handleChangeStatus(e, "unpaid")}
              buttonDisabled={buttonDisabled}
            />
            <ModalConfirm
              open={modalReject}
              onClose={handleCloseModalReject}
              title="Reject Claim Transaction"
              subTitle={
                "Are you sure you want to reject this claim transaction?"
              }
              type="reject"
              onClick={e => handleChangeStatus(e, "rejected")}
              buttonDisabled={buttonDisabled}
            />
            {dspSelected && (
              <DialogImportDokumen
                open={modalDocument}
                onClose={handleCloseModalDocument}
                getInputProps={getInputProps}
                getRootProps={getRootProps({ style })}
                isDragActive={isDragActive}
                files={files}
                onSubmit={e => handleUploadDocument(e, "paid")}
                dsp={dspSelected}
                exchangeRate={exchangeRate}
                handleChangeExchangeRate={handleChangeExchangeRate}
                buttonDisabled={buttonDisabled}
              />
            )}
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};
export default DetailClaimAndUsage;
