import {
  Backdrop,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { Page } from "components";
import { hardBaseUrl, hardToken } from "../../../../../services/urlConstant";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: ["Helvetica Neue"].join(","),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  tree: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  formTitle: {
    fontFamily: ["Helvetica Neue"].join(","),
    marginBottom: 5,
  },
  formControl: { marginBottom: 10 },
  inputFields: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingLeft: "14px",
      height: "44px",
    },
  },
  pageTitle: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
    textTransform: "capitalize",
  },
  breadcrumbs: {
    marginRight: "16px",
    padding: "12px",
    textTransform: "capitalize",
  },
  title: {
    fontSize: "18px",
    fontWeight: 700,
  },
  cardContainer: {
    border: "1px solid #9AA2B1",
    borderRadius: "8px",
    padding: "16px",
    marginTop: "16px",
    marginRight: "15px",
  },
  btnSubmit: {
    textTransform: "none",
    backgroundColor: "black",
    color: "white",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
    marginTop: 15,
  },
}));

function TambahRole(props) {
  const classes = useStyles();
  const history = useHistory();
  const typeRole = props.match.params.type;
  const listApplication = ["MPIS", "CARIS"];
  const [values, setValues] = React.useState({
    role_name: "",
    application: "",
    menus: [],
  });
  const [loadingPage, setLoadingPage] = useState(true);
  const [dataItemsRole, setDataItemsRole] = useState([]);
  const [arrSelected, setArrSelected] = useState([]);

  const toTitleCase = str => str.replace(/\b\w/g, char => char.toUpperCase());
  const modalSuccess = content =>
    Swal.fire({
      title: "Success",
      text: content,
      icon: "success",
      confirmButtonText: "OK",
    });
  const modalError = content =>
    Swal.fire({
      title: "Oops...",
      icon: "error",
      text: content,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  const DragIcons = () => (
    <svg
      width="10"
      height="22"
      viewBox="0 0 10 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: 10 }}
    >
      <circle cx="2" cy="2" r="2" fill="#D1D5DC" />
      <circle cx="8" cy="2" r="2" fill="#D1D5DC" />
      <circle cx="2" cy="8" r="2" fill="#D1D5DC" />
      <circle cx="8" cy="8" r="2" fill="#D1D5DC" />
      <circle cx="2" cy="14" r="2" fill="#D1D5DC" />
      <circle cx="8" cy="14" r="2" fill="#D1D5DC" />
      <circle cx="2" cy="20" r="2" fill="#D1D5DC" />
      <circle cx="8" cy="20" r="2" fill="#D1D5DC" />
    </svg>
  );

  const getParams = param => {
    if (param === "association") {
      return "admin";
    } else {
      return param;
    }
  };
  const fetchDataRole = async () => {
    try {
      let baseOfUrl = hardBaseUrl;
      const url =
        baseOfUrl + "/permission?type=" + getParams(props.match.params.type);
      const options = {
        headers: {
          Authorization: "Bearer " + hardToken,
        },
      };
      const response = await axios.get(url, options);
      setDataItemsRole(response?.data?.data);
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    setLoadingPage(false);
    fetchDataRole();
  }, []);
  const handleChangeValues = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const onTambahkan = async () => {
    if (values.nameOfRole === "") {
      modalError("User role can't be empty.").then(result => {
        if (result.isConfirmed === true) {
          //
        }
      });
    } else if (arrSelected.length === 0) {
      modalError("Access can't be empty.").then(result => {
        if (result.isConfirmed === true) {
          //
        }
      });
    } else {
      setLoadingPage(true);
      try {
        let payload = {
          name: values.nameOfRole,
          permission_ids: arrSelected,
          role_type: props.match.params.type,
        };
        let baseOfUrl = hardBaseUrl;
        const url = baseOfUrl + "/role";
        const options = {
          headers: {
            Authorization: "Bearer " + hardToken,
          },
        };
        const response = await axios.post(url, payload, options);
        if (
          response.data.message === "success" &&
          response.data.meta.http_status === 200
        ) {
          setLoadingPage(false);
          modalSuccess("Success Add Data").then(result => {
            if (result.isConfirmed === true) {
              history.push("/admin/konfigurasi/role-user");
            }
          });
        }
      } catch (error) {
        setLoadingPage(false);
      }
    }
  };

  const handleDragEnd = result => {
    if (!result.destination) return;

    const { source, destination, type } = result;
    const newItems = [...dataItemsRole];

    if (type === "GROUP") {
      const [movedGroup] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, movedGroup);
    } else if (type === "CHILD") {
      const sourceGroupIndex = parseInt(source.droppableId.split("-")[1]);
      const destinationGroupIndex = parseInt(
        destination.droppableId.split("-")[1]
      );

      if (sourceGroupIndex === destinationGroupIndex) {
        // Reorder children within the same group
        const sourceChildren = newItems[sourceGroupIndex].child;
        const [movedChild] = sourceChildren.splice(source.index, 1);
        sourceChildren.splice(destination.index, 0, movedChild);
      } else {
        // Move child to a different group
        const [movedChild] = newItems[sourceGroupIndex].child.splice(
          source.index,
          1
        );
        newItems[destinationGroupIndex].child.splice(
          destination.index,
          0,
          movedChild
        );
      }
    }

    setDataItemsRole(newItems);
  };

  return (
    <Page className={classes.root} title={`Add ${toTitleCase(typeRole)} Role`}>
      <Backdrop className={classes.backdrop} open={loadingPage}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography className={classes?.pageTitle}>
              Add {typeRole} Role
            </Typography>
          </Grid>
          <Breadcrumbs aria-label="breadcrumb" className={classes?.breadcrumbs}>
            <Typography color="textPrimary">Configuration</Typography>
            <Typography color="textPrimary">
              <a href="/admin/konfigurasi/role-user">{typeRole} Role</a>
            </Typography>
            <Typography color="textPrimary">Add {typeRole} Role</Typography>
          </Breadcrumbs>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sm={12} lg={6} xl={6}>
            <Typography component="span" className={classes?.title}>
              User
            </Typography>
            <div className={classes?.cardContainer}>
              <Typography className={classes.formTitle}>
                Role Username
              </Typography>
              <FormControl
                fullWidth
                className={classes.formControl}
                variant="outlined"
              >
                <OutlinedInput
                  value={values.nameOfRole}
                  className={classes.inputFields}
                  onChange={handleChangeValues("role_name")}
                  fullWidth
                />
              </FormControl>
              <Typography className={classes.formTitle}>
                Aplikasi Tujuan
              </Typography>
              {typeRole === "publisher" || typeRole === "association" ? (
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    size="small"
                    className={classes.select}
                    value={values?.application || ""}
                    onChange={handleChangeValues("application")}
                    select
                    variant="outlined"
                    placeholder="Pilih Aplikasi Tujuan"
                    SelectProps={{
                      displayEmpty: true,
                      renderValue: value =>
                        value ? value : "Pilih Aplikasi Tujuan",
                    }}
                  >
                    {listApplication?.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              ) : (
                <OutlinedInput
                  value={
                    typeRole === "composer" || typeRole === "society"
                      ? "CARIS"
                      : "MPIS"
                  }
                  className={classes.inputFields}
                  disabled
                />
              )}
            </div>
            <Button
              variant="contained"
              onClick={onTambahkan}
              className={classes?.btnSubmit}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={12} md={6} sm={12} lg={6} xl={6}>
            <Typography component="span" className={classes?.title}>
              Access Rights
            </Typography>
            <div className={classes?.cardContainer}>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable" type="GROUP">
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {dataItemsRole.map((group, groupIndex) => (
                        <Draggable
                          key={groupIndex}
                          draggableId={`draggable-${groupIndex}`}
                          index={groupIndex}
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "top",
                                  }}
                                >
                                  <DragIcons />
                                  <h2>{group.group_text}</h2>
                                </div>
                                <Droppable
                                  droppableId={`droppable-${groupIndex}`}
                                  type="CHILD"
                                >
                                  {provided => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {group.child.map((child, childIndex) => (
                                        <Draggable
                                          key={child.permission_id}
                                          draggableId={`draggable-${child.permission_id}-${childIndex}`}
                                          index={childIndex}
                                          type="CHILD"
                                        >
                                          {provided => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={{
                                                display: "flex",
                                                marginLeft: 20,
                                              }}
                                            >
                                              <DragIcons />
                                              <p>{child.name}</p>
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
export default TambahRole;
