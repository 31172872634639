// Shitty code, need to fix this page next time

import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import PencilVector from "../../assets/img/pencilVector.svg";
import TrashVector from "../../assets/img/trashVector.svg";
import { Page } from "components";
import { hardBaseUrl } from "../../services/urlConstant";
import gradients from "../../theme/gradients";
import { getCookie } from "../../utils/constants";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: "100%",
    overflow: "visible",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  avatar_large: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  icon: {
    backgroundImage: gradients.green,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
  loginForm: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  person: {
    marginTop: theme.spacing(2),
    display: "flex",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  tambahLagu: {
    backgroundColor: "black",
    color: "white",
    width: "32px",
    height: "29px",
    borderRadius: "6px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  textTambahLagu: {
    marginLeft: "10px",
    marginTop: "5px",
    fontSize: "14px",
    color: "#687083",
  },
  btnDialog: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnSubmit: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    height: "40px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
  },
  bodyTextGrey: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#687083",
  },
  tableRow: {
    height: 20,
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  dropdown: {
    color: "black",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  defaultButton: {
    width: "90px",
    height: "40px",
    color: "grey",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
  },
  activeButton: {
    width: "90px",
    height: "40px",
    backgroundColor: "black",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
  },
  disabledText: {
    color: "grey",
    textTransform: "none",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },
  activeText: {
    color: "white",
    textTransform: "none",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },
  ib: {
    padding: "6px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      boxShadow: "none",
    },
  },
  formControl: {
    width: "350px",
  },
}));
function ProfilePencipta() {
  const ref = useRef(null);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [typeSnackbar, setTypeSnackbar] = useState("success");
  const [message, setMessage] = useState("This is a message!");
  const [getColor, setGetColor] = useState("");
  //state caris pencipta profile
  const [sureName, setSureName] = useState("");
  const [aliasName, setAliasName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [npwp, setNpwp] = useState("");
  const [nik, setNik] = useState("");
  const [tempBank, setTempBank] = useState([]);
  const [bankOptions, setBankOptions] = useState([]);
  const [bankName, setBankName] = useState(null);
  const [rekening, setRekening] = useState("");
  const [atasNama, setAtasNama] = useState("");
  const [composerBankId, setComposerBankId] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [bankItemSelected, setBankItemSelected] = useState(null);
  const [publisherId, setPublisherId] = useState("");
  const [publisherList, setPublisherList] = useState([]);
  const handleChangePublisher = event => {
    setPublisherId(event.target.value);
  };
  const getCurrentPublisher = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/current/publisher`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { headers })
      .then(res => {
        setPublisherId(res.data.data.publisher_id);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const getPublisherList = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/list/publisher`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { headers })
      .then(res => {
        setPublisherList(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCurrentPublisher();
    getPublisherList();
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setAtasNama("");
    setRekening("");
    setBankName("");
    setOpen(false);
  };
  const handleChangeSelectBank = e => {
    setBankName(e.target.value);
  };
  const handleCloseEdit = () => {
    setAtasNama("");
    setRekening("");
    setBankName("");
    setOpenEdit(false);
  };
  const handleDeleteBank = item => {
    setBankItemSelected(item);
    setOpenModalDelete(true);
  };
  // const handleCloseDelete = () => { };
  const onClickDelete = id => {
    setOpenModalDelete(false);
    let urlUse = `${hardBaseUrl}/composer/profile/composer-bank/${id}`;
    let token = localStorage.getItem("token");
    axios.delete(urlUse, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    Swal.fire({
      title: "Bank deleted successfully",
      text: "",
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
    }).then(result => {
      if (result.value) {
        window.location.reload();
      }
    });
  };
  const handleEditModeBank = item => {
    setOpenEdit(true);
    setComposerBankId(item.composer_bank_id);
    setBankName(item.bank.bank_id);
    setRekening(item.account_number);
    setAtasNama(item.account_name);
  };
  const getResponse = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = hardBaseUrl + "/composer/get-profile";
      const options = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const res = await axios.get(url, options);
      if (res.data.data.composer_banks) {
        setTempBank(res.data.data.composer_banks);
      }
      setSureName(res.data.data.sure_name);
      setAliasName(res.data.data.alias_name);
      setEmail(res.data.data.email);
      setPhone(res.data.data.phone);
      setNpwp(res.data.data.npwp);
      setNik(res.data.data.nik);
      localStorage.setItem(
        "composer_bank",
        JSON.stringify(res.data.data.composer_banks)
      );
    } catch (error) {
      new Error(error);
    }
  };
  const getBank = () => {
    const url = `${hardBaseUrl}/composer/get-profile/bank-option`;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then(res => {
        setBankOptions(res.data.data.banks);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const handleChange = e => {
    const { name, value } = e.target;
    if (name === "sure_name") {
      setSureName(value);
    } else if (name === "alias_name") {
      setAliasName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "npwp") {
      setNpwp(value);
    } else if (name === "nik") {
      setNik(value);
    } else if (name === "atasnama") {
      setAtasNama(value);
    } else if (name === "rekening") {
      setRekening(value);
    }
  };
  useEffect(() => {
    getResponse();
    getBank();
    const getMainDsp = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${hardBaseUrl}/me`;
        const res = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setGetColor(res.data.data.publisher.theme_color);
      } catch (e) {
        console.log("error main dsp", e);
      }
    };
    getMainDsp();
  }, []);
  const createBank = e => {
    e.preventDefault();
    const data = {
      account_name: atasNama,
      account_number: rekening,
      bank_id: bankName,
    };
    let urlUse = `${hardBaseUrl}/composer/profile/composer-bank`;
    let token = localStorage.getItem("token");
    axios
      .post(urlUse, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        if (response.status === 200) {
          setTypeSnackbar("success");
          Swal.fire({
            title: "Success",
            text: "You added a new bank",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then(result => {
            if (result.value) {
              setBankName(null);
              getResponse();
            }
          });
        } else {
          setTypeSnackbar("error");
          setMessage("Add bank account fail.");
          setOpenSnack(true);
        }
      });
    handleClose();
  };
  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };
  const handleEditBank = e => {
    e.preventDefault();
    const data = {
      account_name: atasNama,
      account_number: rekening,
      bank_id: bankName,
    };
    let urlUse = `${hardBaseUrl}/composer/profile/composer-bank/${composerBankId}`;
    let token = localStorage.getItem("token");
    axios
      .put(urlUse, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        if (response.status === 200) {
          setTypeSnackbar("success");
          Swal.fire({
            title: "Success",
            text: "Update Bank Success",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then(result => {
            if (result.value) {
              setBankName(null);
              getResponse();
            }
          });
        } else {
          setTypeSnackbar("error");
          setMessage("Add bank account fail.");
          setOpenSnack(true);
        }
      });
    handleCloseEdit();
  };
  const handleButtonSimpan = async () => {
    //remove key profile_image from bank array object
    const data = tempBank.map(item => {
      delete item.profile_image;
      return item;
    });
    const data_bank = (data || []).map(
      ({ bank, account_name, account_number }) => ({
        account_name,
        account_number,
        bank_id: bank?.bank_id,
      })
    );
    let payload = {
      sure_name: sureName,
      email: email,
      phone_number: phone,
      alias_name: aliasName,
      npwp: npwp,
      nik: nik,
      bank: data_bank,
    };
    let urlUse = `${hardBaseUrl}/composer/update-profile`;
    let token = localStorage.getItem("token");
    let resultAdd = await axios.put(urlUse, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (
      resultAdd.data.message === "success" &&
      resultAdd.data.meta.http_status === 200
    ) {
      setTypeSnackbar("success");
      Swal.fire({
        title: "Berhasil",
        text: "Data Modified Successfully",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then(result => {
        if (result.value) {
          window.location.reload();
        }
      });
    } else {
      setTypeSnackbar("error");
      setMessage("Edit profile Fail.");
      setOpenSnack(true);
    }
  };
  const handleButtonSimpanPublisher = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/change/publisher`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const payload = {
      publisher_id: publisherId,
    };
    axios
      .put(url, payload, { headers })
      .then(res => {
        if (
          res.data.message === "success" &&
          res.data.meta.http_status === 200
        ) {
          Swal.fire({
            title: "Success",
            text: "Data Modified Successfully",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then(result => {
            if (result.value) {
              window.location.reload();
            }
          });
        }
      })
      .catch(err => {
        console.log("err", err);
      });
  };

  const feVersion = getCookie(
    "authVersion",
    `${process.env.REACT_APP_NAME}_fe`
  );

  const beVersion = getCookie(
    "authVersion",
    `${process.env.REACT_APP_NAME}_be`
  );

  const disableSaveBank = !bankName || !rekening || !atasNama;
  return (
    <Page className={classes.root} title="Profil">
      <Container maxWidth={false}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
            {message}
          </Alert>
        </Snackbar>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: "700",
                lineHeight: "32px",
              }}
            >
              Profile
            </Typography>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>
              <Typography className={classes.breadCrumbsActive}>
                Composer Profile
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Grid container>
              <div>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Change Publisher
                </Typography>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Change your Publisher.
                </p>
              </div>
            </Grid>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
                marginBottom: "10px",
              }}
            >
              <CardContent>
                <div
                  style={{
                    marginBottom: "5px",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <Typography variant="subtitle2" className={classes.label}>
                      Publisher
                    </Typography>
                  </div>
                  <FormControl size="small" variant="outlined" fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      className={classes.formControl}
                      onChange={e => handleChangePublisher(e)}
                      value={publisherId}
                      autoWidth
                    >
                      {/* <MenuItem
                        value=""
                        style={{
                          width: '300px',
                        }}
                      >
                        <em>None</em>
                      </MenuItem> */}
                      {publisherList.map((item, index) => (
                        <MenuItem key={index} value={item.publisher_id}>
                          {item.name}
                        </MenuItem>
                      ))}
                      { }
                    </Select>
                  </FormControl>
                </div>
              </CardContent>
            </Card>
            <div>
              <Button
                variant="contained"
                onClick={handleButtonSimpanPublisher}
                className={classes.btnSubmit}
                style={{
                  backgroundColor: getColor,
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <Typography
                  style={{
                    textTransform: "none",
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Save
                </Typography>
              </Button>
            </div>
            <Divider className={classes.divider} />
            <Grid container>
              <div>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Composer Profil
                </Typography>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Customize content to remind creators with time the contract
                  will expire.
                </p>
              </div>
            </Grid>
            <Card
              style={{
                border: "1px solid #9AA2B1",
                borderRadius: "6px",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <form ref={ref}>
                  <Grid container>
                    <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Composer Name
                          </Typography>
                        </div>
                        <div
                          style={{
                            marginTop: "-10px",
                            marginBottom: "10px",
                            marginRight: "10px",
                          }}
                        >
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            name="sure_name"
                            value={sureName}
                            onChange={e => handleChange(e)}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Alias Name
                          </Typography>
                        </div>
                        <div
                          style={{
                            marginTop: "-10px",
                            marginBottom: "10px",
                            marginRight: "10px",
                          }}
                        >
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            name="alias_name"
                            value={aliasName}
                            onChange={e => handleChange(e)}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Email
                          </Typography>
                        </div>
                        <div
                          style={{
                            marginTop: "5px",
                            marginBottom: "10px",
                            display: "flex",
                          }}
                        >
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            name={"email"}
                            value={email}
                            onChange={e => handleChange(e)}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "20px",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Phune Number
                          </Typography>
                        </div>
                        <div
                          style={{
                            marginTop: "-10px",
                            marginBottom: "10px",
                            display: "flex",
                          }}
                        >
                          <Input
                            disabled
                            size="small"
                            value="+62"
                            inputProps={{
                              min: 0,
                              style: { textAlign: "center" },
                            }}
                            style={{
                              width: "40px",
                              margin: "0px",
                              padding: "0px",
                              border: "1px solid #D1D5DC",
                              backgroundColor: "#D1D5DC",
                              borderRadius: "5px 0px 0px 5px",
                            }}
                          />
                          <FormControl
                            variant="standard"
                            size="small"
                            fullWidth
                          >
                            <OutlinedInput
                              variant="outlined"
                              id="input-with-icon-adornment"
                              name="phone"
                              value={phone}
                              onChange={e => handleChange(e)}
                              style={{
                                borderRadius: "0px 5px 5px 0px",
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            NPWP
                          </Typography>
                        </div>
                        <div
                          style={{
                            marginTop: "5px",
                            marginBottom: "10px",
                            display: "flex",
                          }}
                        >
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            name="npwp"
                            value={npwp}
                            onChange={e => handleChange(e)}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "20px",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            NIK
                          </Typography>
                        </div>
                        <div
                          style={{
                            marginTop: "-10px",
                            marginBottom: "10px",
                            display: "flex",
                          }}
                        >
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            name="nik"
                            value={nik}
                            onChange={e => handleChange(e)}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
          <Button
            variant="contained"
            onClick={handleButtonSimpan}
            className={classes.btnSubmit}
            style={{
              backgroundColor: getColor,
              marginTop: "20px",
            }}
          >
            <Typography
              style={{
                textTransform: "none",
                color: "#FFFFFF",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
              }}
            >
              Save
            </Typography>
          </Button>
        </Grid>
        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Divider className={classes.divider} />
            <div>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Bank Account
              </Typography>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Customize composer bank account
              </p>
              <Button
                variant="contained"
                onClick={handleOpen}
                className={classes.btnSubmit}
                style={{
                  backgroundColor: getColor,
                  marginTop: "10px",
                }}
              >
                <Typography
                  style={{
                    textTransform: "none",
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  + Add Bank Account
                </Typography>
              </Button>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Card
                style={{
                  marginTop: "16px",
                }}
              >
                <div style={{}}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Action</TableCell>
                        <TableCell>Bank</TableCell>
                        <TableCell>Account Number</TableCell>
                        <TableCell>On behalf of</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tempBank.length > 0 ? (
                        tempBank.map((value, index) => (
                          <TableRow hover key={index}>
                            <TableCell
                              style={{
                                width: "20%",
                              }}
                            >
                              <IconButton
                                classes={{
                                  root: classes.ib,
                                }}
                                onClick={() => {
                                  handleEditModeBank(value);
                                }}
                              >
                                <img
                                  alt="pncl_vector"
                                  src={PencilVector}
                                  style={{}}
                                />
                              </IconButton>
                              <IconButton
                                style={{
                                  marginLeft: "5px",
                                }}
                                classes={{
                                  root: classes.ib,
                                }}
                                onClick={() => {
                                  handleDeleteBank(value);
                                }}
                              >
                                <img alt="trsh_vctr" src={TrashVector} />
                              </IconButton>
                              <Dialog
                                open={openModalDelete}
                                // onClose={handleCloseDelete}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              >
                                <DialogTitle id="alert-dialog-title">
                                  <Typography
                                    style={{
                                      fontWeight: 700,
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    Delete Bank Account
                                  </Typography>
                                </DialogTitle>
                                <DialogContent>
                                  {bankItemSelected !== null ? (
                                    <DialogContentText id="alert-dialog-description">
                                      {`Apakah Anda yakin akan menghapus data ini ${bankItemSelected.account_name} ?`}
                                    </DialogContentText>
                                  ) : (
                                    <DialogContentText id="alert-dialog-description">
                                      `Are you sure you want to delete this data
                                      ?`
                                    </DialogContentText>
                                  )}
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={() => {
                                      setOpenModalDelete(false);
                                    }}
                                    style={{
                                      height: "40px",
                                      width: "90px",
                                      borderRadius: "6px",
                                      border: "1px solid #D1D5DC",
                                      color: "#111827",
                                      backgroundColor: "white",
                                      textTransform: "none",
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      onClickDelete(
                                        bankItemSelected.composer_bank_id
                                      );
                                    }}
                                    autoFocus
                                    style={{
                                      height: "40px",
                                      width: "90px",
                                      borderRadius: "6px",
                                      border: "0px solid #D1D5DC",
                                      color: "white",
                                      backgroundColor: "#111827",
                                      textTransform: "none",
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </TableCell>
                            <TableCell component="th" scope="row" width={150}>
                              { }
                              <div>
                                <img
                                  alt="bank_logo"
                                  src={value?.bank?.profile_image}
                                  style={{
                                    width: "40%",
                                    height: "40p%",
                                    marginRight: "10px",
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell>{value.account_number}</TableCell>
                            <TableCell>{value.account_name}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <div
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Typography>No data yet</Typography>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </Card>
            </div>
            <Divider className={classes.divider} />
            <div>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Change Password
              </Typography>
              <p
                style={{
                  marginTop: "5px",
                  color: "#8f8f8f",
                  fontSize: "14px",
                }}
              >
                Change the password of your account.
              </p>
            </div>
          </Grid>
          <Grid container>
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Grid container>
                    <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            New Password
                          </Typography>
                        </div>
                        <div
                          style={{
                            marginTop: "-10px",
                            marginBottom: "10px",
                            marginRight: "10px",
                          }}
                        >
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            placeholder="Kata Sandi"
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.label}
                          >
                            Confirm New Password
                          </Typography>
                        </div>
                        <div
                          style={{
                            marginTop: "-10px",
                            marginBottom: "10px",
                            marginRight: "10px",
                          }}
                        >
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            placeholder="Ulang Kata Sandi"
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container>
              <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
                <div
                  style={{
                    marginTop: "24px",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "28px",
                      color: "#111827",
                    }}
                  >
                    CARIS Version
                  </Typography>
                </div>
                <div
                  style={{
                    border: "1px solid #D1D5DC",
                    boxSizing: "border-box",
                    borderRadius: "6px",
                    padding: "0px",
                    marginTop: "16px",
                  }}
                >
                  <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                    <Grid container>
                      <Table
                        size="medium"
                      // className={classes.contentTable}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="left"
                              width="30%"
                              style={{
                                borderTopLeftRadius: "6px",
                              }}
                            >
                              Version
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                borderTopRightRadius: "6px",
                              }}
                            ></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left">
                              <b>Front End Version</b>
                            </TableCell>
                            <TableCell align="left">{feVersion}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              align="left"
                              style={{
                                borderBottomLeftRadius: "6px",
                              }}
                            >
                              <b>Back End Version</b>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                borderBottomRightRadius: "6px",
                              }}
                            >
                              {beVersion}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
            <Button
              variant="contained"
              onClick={e => handleButtonSimpan(e)}
              className={classes.btnSubmit}
              style={{
                backgroundColor: getColor,
                marginTop: "20px",
              }}
            >
              <Typography
                style={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Save
              </Typography>
            </Button>
            <Dialog
              fullWidth={true}
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <form onSubmit={e => createBank(e)}>
                <DialogTitle id="responsive-dialog-title">
                  {"Tambah Akun Bank"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>Bank</DialogContentText>
                  <div>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        shrink={false}
                      >
                        {bankName !== null ? null : "Bank"}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={handleChangeSelectBank}
                        autoWidth
                        value={bankName}
                        fullWidth
                      >
                        {bankOptions.map((bank, index) => (
                          <MenuItem key={index} value={bank.bank_id}>
                            {bank.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </DialogContent>
                <DialogContent>
                  <DialogContentText>Nomor Rekening</DialogContentText>
                  <div>
                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-amount"
                        onChange={handleChange}
                        name="rekening"
                        type="number"
                        value={rekening}
                        fullWidth={false}
                      />
                    </FormControl>
                  </div>
                </DialogContent>
                <DialogContent>
                  <DialogContentText>Atas Nama</DialogContentText>
                  <div>
                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-amount"
                        onChange={handleChange}
                        name="atasnama"
                        value={atasNama}
                        fullWidth={true}
                      />
                    </FormControl>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    style={{
                      width: "90px",
                      height: "40px",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #D1D5DC",
                      borderRadius: "6px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "black",
                        textTransform: "none",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      Batal
                    </Typography>
                  </Button>
                  <Button
                    className={
                      !disableSaveBank
                        ? classes.activeButton
                        : classes.defaultButton
                    }
                    type="submit"
                    variant="outlined"
                    disabled={disableSaveBank}
                  >
                    <Typography
                      className={
                        disableSaveBank
                          ? classes.disabledText
                          : classes.activeText
                      }
                    >
                      Simpan
                    </Typography>
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
            <Dialog
              fullWidth={true}
              open={openEdit}
              onClose={handleCloseEdit}
              aria-labelledby="responsive-dialog-title"
            >
              <form onSubmit={e => handleEditBank(e)}>
                <DialogTitle id="responsive-dialog-title">
                  {"Edit Akun Bank"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>Bank</DialogContentText>
                  <div>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        shrink={false}
                      >
                        {bankName !== null ? null : "Bank"}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={handleChangeSelectBank}
                        value={bankName}
                        autoWidth
                        fullWidth
                      >
                        {bankOptions.map((bank, index) => (
                          <MenuItem key={index} value={bank.bank_id}>
                            {bank.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </DialogContent>
                <DialogContent>
                  <DialogContentText>Nomor Rekening</DialogContentText>
                  <div>
                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-amount"
                        onChange={handleChange}
                        name="rekening"
                        type="number"
                        fullWidth={false}
                        value={rekening}
                      />
                    </FormControl>
                  </div>
                </DialogContent>
                <DialogContent>
                  <DialogContentText>Atas Nama</DialogContentText>
                  <div>
                    <FormControl
                      fullWidth
                      className={classes.margin}
                      variant="outlined"
                    >
                      <OutlinedInput
                        id="outlined-adornment-amount"
                        onChange={handleChange}
                        name="atasnama"
                        fullWidth={true}
                        value={atasNama}
                      />
                    </FormControl>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleCloseEdit}
                    style={{
                      width: "90px",
                      height: "40px",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #D1D5DC",
                      borderRadius: "6px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "black",
                        textTransform: "none",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      Batal
                    </Typography>
                  </Button>
                  <Button
                    className={
                      !disableSaveBank
                        ? classes.activeButton
                        : classes.defaultButton
                    }
                    type="submit"
                    variant="outlined"
                    disabled={disableSaveBank}
                  >
                    <Typography
                      className={
                        disableSaveBank
                          ? classes.disabledText
                          : classes.activeText
                      }
                    >
                      Simpan
                    </Typography>
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
export default ProfilePencipta;
