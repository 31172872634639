import { Container, Divider } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SkeletonComponent,
} from "components";
import { topMenuParameter } from "constants";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

function Lagu() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: Number(paramsPage) || 1,
    per_page: Number(paramsSize) || 10,
    search: paramsSearch || "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleChangeSearch = event => {
    const value = event?.target?.value;
    handleChangeQueryParams(value, "search");
    handleChangePageParams(value, "search");
  };
  const handleChangePage = page => {
    handleChangeQueryParams(page, "page");
    handleChangePageParams(page, "page");
  };
  const handleChangePageSize = size => {
    handleChangeQueryParams(size, "per_page");
    handleChangePageParams(size, "size");
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/publisher/agent`, {
        headers,
        params: queryParams,
      });

      const { data, total_page } = res?.data?.data;
      setDataTable(data);
      setTableTotalPage(total_page);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleDelete = async id => {
    try {
      setLoadingPage(true);
      const res = await axios.delete(`${hardBaseUrl}/publisher/agent/${id}`, {
        headers,
      });
      if (res.data.message === "success") {
        ModalSuccess("Data Deleted Successfully").then(result => {
          if (result.isConfirmed === true) {
            getDataTable();
          }
        });
      }
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    debounceDataTable();
    if (queryParams?.page !== paramsPage) {
      handleChangePageParams(queryParams?.page, "page");
    }
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Agent">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Parameter" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop items={topMenuParameter} />
          <Divider className={classes.divider} />
          <Grid container justifyContent="space-between" my="16px">
            <SearchTextInput
              placeholder="Search"
              value={queryParams?.search}
              onChange={handleChangeSearch}
            />
            <PrimaryButton
              label="Add Agent"
              startIcon={<AddIcon />}
              onClick={() => history.push("/admin/parameter/agent/tambah")}
            />
          </Grid>
          <InnoTable
            isLoading={false}
            columns={[
              {
                name: "name",
                title: "Agent Name",
              },
              {
                name: "contact_number",
                title: "Telephone Number",
              },
              {
                name: "email",
                title: "Email",
              },
              {
                name: "npwp",
                title: "NPWP",
              },
            ]}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.per_page}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) => handleChangePage(page)}
            handleChangeRowsPerPage={e =>
              handleChangePageSize(e?.target?.value)
            }
            handleDelete={handleDelete}
            handleEdit={item => {
              history.push({
                pathname: `/admin/parameter/agent/edit/${item.agent_id}`,
                state: {
                  customer: item,
                },
              });
            }}
            isHaveAction={true}
            isUsingDeleteConfirmation={true}
            deleteName={"name"}
          />
        </Container>
      )}
    </Page>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    borderTop: "1px solid #e1e1e1",
  },
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Parameter",
    link: "/admin/parameter/agent",
  },
  {
    label: "Agent",
    active: true,
  },
];
export default Lagu;
