import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Divider,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import * as FileSaver from "file-saver";
import { InnoTableV2 } from "inno-ui";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import * as XLSX from "xlsx";
import {
  ModalError,
  monthData,
  Page,
  ArrayTagChip,
  SearchTextInput,
  YearPicker,
  FormLabel,
  PrimaryButton,
  MultipleSelectInputWithTags,
  SelectInput,
  ArrayChip,
  MultipleSelectInput,
  SongUsageTrendChart,
  SongUsageTrendReportCard,
  SkeletonComponent,
} from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import "./style.scss";
import { getTotalPage } from "lib";
import {
  arrayToCommaSeparatedString,
  createAllFilterOption,
  fetchAndFilterOptions,
  filterArrayByKeyValue,
  getErrors,
  handleChangeFilterAll,
  handleChangeFilterAllAfterSearch,
} from "utils";
import { Box, Grid } from "@mui/material";
import { HeaderTitle } from "layouts";
import { debounce, isEqual } from "lodash";
import moment from "moment-timezone";

const ReviewLagu = () => {
  const history = useHistory();
  const classes = useStyles();
  const typeWeb = localStorage.getItem("typeWeb");
  const userRole = localStorage?.getItem("role");
  const isCaris = typeWeb === "caris";
  const roleSociety = userRole === "society";
  const rolePublisher = userRole === "publisher";
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [queryParams, setQueryParams] = useState({
    dsp_id: dspId ?? "",
    original_publisher_id: 0,
    month: "",
    year: 0,
    composer_id: "",
    product_type: "",
  });
  const [sorting, setSorting] = useState({
    sortBy: "song_title",
    sort: "asc",
  });
  const [tableParams, setTableParams] = useState({
    page: 1,
    size: 10,
    search: "",
  });
  const [optionDspParams, setOptionDspParams] = useState({
    page: 1,
    search: "",
  });
  const [optionComposerParams, setOptionComposerParams] = useState({
    page: 1,
    size: 30,
    search: "",
  });
  const [optionPublisherParams, setOptionPublisherParams] = useState({
    page: 1,
    search: "",
  });
  const [
    optionOriginalPublisherParams,
    setOptionOriginalPublisherParams,
  ] = useState({
    page: 1,
    search: "",
  });
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [selectedMenuChart, setSelectedMenuChart] = useState(1);
  const [selectedDSP, setSelectedDSP] = useState([]);
  const [selectedComposer, setSelectedComposer] = useState([]);
  const [selectedPublisher, setSelectedPublisher] = useState([]);
  const [selectedOriginalPublisher, setSelectedOriginalPublisher] = useState(
    []
  );
  const [selectedMonthFilter, setSelectedMonthFilter] = useState([]);
  const [selectedRevenue, setSelectedRevenue] = useState([]);
  const [optionDsp, setOptionDsp] = useState([]);
  const [optionPublisher, setOptionPublisher] = useState([]);
  const [optionOriginalPublisher, setOptionOriginalPublisher] = useState([]);
  const [optionComposer, setOptionComposer] = useState([]);
  const [optionTypeRevenue, setOptionTypeRevenue] = useState();
  const [tableData, setTableData] = useState([]);
  const [tablePageCount, setTablePageCount] = useState(0);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [chartReport, setChartReport] = useState({
    revenue: 0,
    traffic: 0,
    month: "",
    totalRevenue: 0,
    totalTraffic: 0,
  });

  const prevQueryParams = usePrevious(queryParams);
  const prevDspPagination = usePrevious(optionDspParams.search);
  const prevPublisherPagination = usePrevious(optionPublisherParams.search);
  const prevOriginalPublisherPagination = usePrevious(
    optionOriginalPublisherParams.search
  );
  const roleSocietyPublisher = roleSociety && selectedMenuChart === 1;
  const { sort, sortBy } = sorting;
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeTableParams = (value, key) => {
    setTableParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleChangeChartReport = (value, key) => {
    setChartReport(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeFilterDSP = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedDSP(
      handleChangeFilterAllAfterSearch({
        selectedList: selectedDSP,
        checked,
        option,
        key: "dsp_id",
        list: optionDsp,
        handleChangeQueryParams,
        params: optionDspParams,
      })
    );
  };
  const handleSearchFilterDSP = value => {
    setOptionDspParams(prev => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };
  const handleChangeFilterPublisher = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedPublisher(
      handleChangeFilterAllAfterSearch({
        selectedList: selectedPublisher,
        checked,
        option,
        key: "publisher_id",
        list: optionPublisher,
        handleChangeQueryParams,
        params: optionPublisherParams,
      })
    );
  };
  const handleSearchFilterPublisher = value => {
    setOptionPublisherParams(prev => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };
  const handleChangeFilterOriginalPublisher = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedOriginalPublisher(
      handleChangeFilterAllAfterSearch({
        selectedList: selectedOriginalPublisher,
        checked,
        option,
        key: "original_publisher_id",
        list: optionOriginalPublisher,
        handleChangeQueryParams,
        params: optionOriginalPublisherParams,
      })
    );
  };
  const handleSearchFilterOriginalPublisher = value => {
    setOptionOriginalPublisherParams(prev => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };
  const handleChangeFilterComposer = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedComposer(prev => {
      const selectedFilter = checked
        ? [...prev, option]
        : filterArrayByKeyValue(prev, option, "composer_id");
      const filterParams = arrayToCommaSeparatedString(
        selectedFilter,
        "composer_id"
      );
      handleChangeQueryParams(filterParams, "composer_id");
      return selectedFilter;
    });
  };
  const handleSearchFilterComposer = value => {
    setOptionComposerParams(prev => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };
  const handleChangeFilterMonth = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedMonthFilter(prev => {
      const newSelectedMonthFilter = checked
        ? [...prev, option]
        : prev.filter(month => month.id !== option.id);
      handleChangeQueryParams(
        newSelectedMonthFilter.map(month => month.id).join(","),
        "month"
      );
      return newSelectedMonthFilter;
    });
  };
  const handleChangeFilterRevenue = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedRevenue(
      handleChangeFilterAll({
        selectedList: selectedRevenue,
        checked,
        option,
        key: "right_type_id",
        list: optionTypeRevenue,
        handleChangeQueryParams,
      })
    );
  };
  const onClickChartAxis = data => {
    if (chartData?.datasets.length > 0) {
      const { dataIndex, axisValue } = data;
      let monthTotalTraffic = 0;
      let monthTotalRevenue = 0;

      chartData?.datasets?.forEach(dataset => {
        monthTotalTraffic += dataset?.listener?.[dataIndex] || 0;
        monthTotalRevenue += dataset?.data?.[dataIndex] || 0;
      });

      const monthName = moment(axisValue, "MMM YY").format("MMMM");

      handleChangeChartReport(monthTotalTraffic, "traffic");
      handleChangeChartReport(monthTotalRevenue, "revenue");
      handleChangeChartReport(monthName, "month");
    }
  };

  const debounceOptionDSP = useCallback(
    debounce(() => {
      fetchAndFilterOptions({
        headers,
        endpoint: "/dashboard/options/dsp",
        params: optionDspParams,
        label: "name",
        key: "dsp_id",
        setLoading: setLoadingFilter,
        paramsCondition: optionDspParams.search,
        prevPagination: prevDspPagination,
        setOptions: setOptionDsp,
        selectedOptions: selectedDSP,
        setSelectedOptions: setSelectedDSP,
      });
    }, 500),
    [optionDspParams]
  );
  const debounceOptionPublisher = useCallback(
    debounce(() => {
      fetchAndFilterOptions({
        headers,
        endpoint: "/dashboard/options/publisher",
        params: optionPublisherParams,
        label: "name",
        key: "publisher_id",
        setLoading: setLoadingFilter,
        paramsCondition: optionPublisherParams.search,
        prevPagination: prevPublisherPagination,
        setOptions: setOptionPublisher,
        selectedOptions: selectedPublisher,
        setSelectedOptions: setSelectedPublisher,
      });
    }, 500),
    [optionPublisherParams]
  );
  const getOriginalPublisherList = () =>
    fetchAndFilterOptions({
      headers,
      endpoint: "/dashboard/options/original_publisher",
      params: optionOriginalPublisherParams,
      label: "name",
      key: "original_publisher_id",
      setLoading: setLoadingFilter,
      paramsCondition: optionOriginalPublisherParams.search,
      prevPagination: prevOriginalPublisherPagination,
      setOptions: setOptionOriginalPublisher,
      selectedOptions: selectedOriginalPublisher,
      setSelectedOptions: setSelectedOriginalPublisher,
    });
  const debounceOptionOriginalPublisher = useCallback(
    debounce(() => {
      getOriginalPublisherList();
    }, 500),
    [optionOriginalPublisherParams]
  );
  const getComposerList = async () => {
    const url = `${hardBaseUrl}/dashboard/options/composer`;
    const params = optionComposerParams;
    try {
      setLoadingFilter(true);
      const res = await axios.get(url, { headers, params });
      const resData = res?.data?.data;
      setOptionComposer(resData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingFilter(false);
    }
  };
  const debounceOptionComposer = useCallback(
    debounce(() => {
      getComposerList();
    }, 500),
    [optionComposerParams]
  );
  const getDataTable = async () => {
    const params = {
      ...queryParams,
      ...tableParams,
      sort: `${sortBy} ${sort}`,
    };
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/publisher/song-usage`, {
        headers,
        params,
      });
      setTableData(res?.data?.data || []);
      const pageCount = getTotalPage(res?.data?.meta?.total, tableParams?.size);
      setTablePageCount(pageCount);
      setLoadingPage(false);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams, sorting, tableParams]
  );
  const getChartData = async () => {
    const url = `${hardBaseUrl}/publisher/dashboard/chart`;
    const options = {
      headers,
      params: queryParams,
    };
    try {
      setLoadingPage(true);
      const res = await axios.get(url, options);
      const { data } = res?.data || [];
      if (data) {
        const labels = data[0].chart.map(item =>
          moment(item.date).format("MMM YY")
        );
        const datasets = data.map(item => ({
          label: item?.dsp?.name,
          data: item?.chart.map(chartItem => chartItem?.royalty),
          listener: item?.chart.map(chartItem => chartItem?.listener),
          color: item?.dsp?.color,
          showMark: false,
        }));
        setChartData({
          labels,
          datasets,
        });

        const totals = data.reduce(
          (acc, item) => {
            acc.revenue += item.revenue;
            acc.traffic += item.traffic;
            return acc;
          },
          { revenue: 0, traffic: 0 }
        );
        setChartReport(currentState => ({
          ...currentState,
          totalRevenue: totals.revenue,
          totalTraffic: totals.traffic,
        }));
      } else {
        setChartData({
          labels: [],
          datasets: [],
        });
        setChartReport({
          revenue: 0,
          traffic: 0,
          month: "",
          totalRevenue: 0,
          totalTraffic: 0,
        });
      }
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getDownloadSongUsage = async type => {
    try {
      setLoadingPage(true);
      const params = {
        ...queryParams,
        ...tableParams,
        sort: `${sortBy} ${sort}`,
        size: -1,
        type: type,
      };
      const options = {
        headers,
        params,
      };
      const res = await axios.get(
        `${hardBaseUrl}/publisher/song-usage/download`,
        options
      );
      return res?.data?.data || [];
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getOptionRevenue = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/right_types `, { headers });
      const { data } = res?.data;
      const updatedOptionsList = [
        createAllFilterOption({
          label: "right_type_name",
          key: "right_type_id",
        }),
        ...data?.map(item => ({
          ...item,
          right_type_id: item?.id,
        })),
      ];
      setOptionTypeRevenue(updatedOptionsList);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleDownloadPDF = () => {
    getDownloadSongUsage("pdf").then(result =>
      history.push({
        pathname: "/admin/review-lagu/print-report",
        state: {
          dataTable: result,
          chartData,
          chartReport,
          selectedDSP,
          roleSocietyPublisher,
          isCaris,
        },
      })
    );
  };
  const handleDownloadXLSX = async () => {
    const fileName = `Report Song Usage ${selectedDSP?.name ||
      ""} ${queryParams?.year || ""}`;
    const dataToPrint = await getDownloadSongUsage("csv");
    const reConstructData = (dataToPrint || [])?.map(item => ({
      "Song Title": item?.SongTitle,
      ISWC: item?.ISWC,
      Composer: item?.Composer,
      Month: item?.Month,
      Traffic: item?.Traffic,
      Revenue: item?.OriginalCurrencyRevenue,
      ...(isCaris && {
        "Composer Revenue": item?.BaseCurrencyComposerRevenue,
        "Publisher Revenue": item?.BaseCurrencyPublisherRevenue,
      }),
      "Product Type": item?.ProductType,
    }));
    const ws = XLSX.utils.json_to_sheet(reConstructData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  const RenderSummaries = (summaries, key, isCurrency) => {
    const getDefaultValue = value => (typeof value === "string" ? "-" : 0);
    return (
      <div className="double-column">
        {summaries?.length > 0 &&
          (summaries || []).map((item, index) => (
            <div key={index}>
              {index == 1 && <div className="tab-dotted" />}
              {isCurrency ? (
                <NumberFormat
                  displayType="text"
                  value={item?.[key] || 0}
                  thousandSeparator={true}
                  prefix="Rp "
                />
              ) : (
                <span>{item?.[key] || getDefaultValue(item?.[key])}</span>
              )}
            </div>
          ))}
      </div>
    );
  };

  const filterConfig = [
    ...(roleSociety
      ? [
          revenueSocietyFilterConfig(
            optionTypeRevenue,
            selectedRevenue,
            handleChangeFilterRevenue
          ),
        ]
      : []),
    dspFilterConfig({
      value: selectedDSP,
      options: optionDsp,
      onChange: handleChangeFilterDSP,
      params: optionDspParams,
      onChangeSearch: handleSearchFilterDSP,
    }),
    ...(isCaris ? [ousideDspFilterConfig] : []),
    ...(roleSociety && selectedMenuChart === 2
      ? [
          authorComposerFilterConfig({
            value: selectedComposer,
            options: optionComposer,
            onChange: handleChangeFilterComposer,
            params: optionComposerParams,
            onChangeSearch: handleSearchFilterComposer,
          }),
        ]
      : [
          publisherFilterConfig({
            value: selectedPublisher,
            options: optionPublisher,
            onChange: handleChangeFilterPublisher,
            params: optionPublisherParams,
            onChangeSearch: handleSearchFilterPublisher,
            rolePublisher,
          }),
        ]),
    ...(roleSociety
      ? [monthFilterConfig(selectedMonthFilter, handleChangeFilterMonth)]
      : [
          originalPublisherFilterConfig({
            value: selectedOriginalPublisher,
            options: optionOriginalPublisher,
            onChange: handleChangeFilterOriginalPublisher,
            params: optionOriginalPublisherParams,
            onChangeSearch: handleSearchFilterOriginalPublisher,
            watchParams: selectedPublisher,
          }),
        ]),
    yearFilterConfig(queryParams, handleChangeQueryParams),
    ...(roleSociety
      ? []
      : [
          authorComposerFilterConfig({
            value: selectedComposer,
            options: optionComposer,
            onChange: handleChangeFilterComposer,
            params: optionComposerParams,
            onChangeSearch: handleSearchFilterComposer,
          }),
          monthFilterConfig(selectedMonthFilter, handleChangeFilterMonth),
          productTypeFilterConfig(queryParams, handleChangeQueryParams),
        ]),
  ];
  const columnTable = [
    {
      name: "all",
      title: "",
      renderText: item => (
        <PrimaryButton
          label="See Detail"
          onClick={() =>
            history.push({
              pathname: `/admin/review-lagu/${item?.song_id}`,
              state: {
                song: item,
                queryParams,
              },
            })
          }
          width={110}
        />
      ),
    },
    {
      name: "title",
      title: "Song Title",
      useSort: true,
      sort_key: "song_title",
    },
    {
      name: "iswc_code",
      title: "ISWC",
      useSort: true,
      sort_key: "iswc_code",
    },
    {
      name: roleSocietyPublisher ? "publisher_name" : "all",
      title: roleSocietyPublisher ? "Publisher" : "Composer/Author",
      renderText: item => {
        if (roleSocietyPublisher) {
          return (
            <ArrayChip
              list={item?.map(publisher => publisher?.publisher_name)}
            />
          );
        } else {
          const listValue = item?.composers?.map(
            ({ name, is_on_this_publisher }) => ({
              name,
              className: is_on_this_publisher ? classes.highlightText : "",
            })
          );
          return <ArrayChip list={listValue} />;
        }
      },
    },
    {
      name: "summaries",
      title: "Traffic",
      renderText: summaries => RenderSummaries(summaries, "listener"),
      useSort: true,
      sort_key: "stream",
    },
    {
      name: "summaries",
      title: "Revenue",
      sort_key: "base_currency_revenue",
      useSort: true,
      renderText: summaries =>
        RenderSummaries(summaries, "base_currency_revenue", true),
    },
    ...(isCaris
      ? [
          {
            name: "summaries",
            title: "Composer Revenue",
            useSort: true,
            sort_key: "base_currency_composer_revenue",
            renderText: summaries =>
              RenderSummaries(
                summaries,
                "base_currency_composer_revenue",
                true
              ),
          },
          {
            name: "summaries",
            title: "Publisher Revenue",
            useSort: true,
            sort_key: "base_currency_publisher_revenue",
            renderText: summaries =>
              RenderSummaries(
                summaries,
                "base_currency_publisher_revenue",
                true
              ),
          },
        ]
      : []),
    {
      name: "summaries",
      title: "Product Type",
      renderText: summaries => RenderSummaries(summaries, "product_type"),
    },
  ];
  const downloadButtonList = [
    {
      caption: "PDF",
      onClick: handleDownloadPDF,
    },
    {
      caption: "XLSX",
      onClick: handleDownloadXLSX,
    },
  ];

  useEffect(() => {
    if (roleSociety) {
      getOptionRevenue();
    }
  }, []);
  useEffect(() => {
    debounceOptionDSP();
    return () => {
      debounceOptionDSP.cancel();
    };
  }, [optionDspParams, debounceOptionDSP]);
  useEffect(() => {
    debounceOptionPublisher();
    return () => {
      debounceOptionPublisher.cancel();
    };
  }, [optionPublisherParams, debounceOptionPublisher]);
  useEffect(() => {
    if (optionOriginalPublisherParams?.publisher_id) {
      if (optionOriginalPublisherParams?.search) {
        debounceOptionOriginalPublisher();
        return () => {
          debounceOptionOriginalPublisher.cancel();
        };
      } else {
        getOriginalPublisherList();
      }
    }
  }, [
    optionOriginalPublisherParams?.publisher_id,
    optionOriginalPublisherParams,
    debounceOptionOriginalPublisher,
  ]);
  useEffect(() => {
    debounceOptionComposer();
    return () => {
      debounceOptionComposer.cancel();
    };
  }, [optionComposerParams, debounceOptionComposer]);
  useEffect(() => {
    if (prevQueryParams && !isEqual(prevQueryParams, queryParams)) {
      getChartData();
      debounceDataTable();
      if (queryParams?.publisher_id) {
        setOptionOriginalPublisherParams(prev => ({
          ...prev,
          publisher_id: queryParams?.publisher_id,
        }));
      }
    } else {
      debounceDataTable();
    }
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, tableParams, sorting, debounceDataTable]);
  useEffect(() => {
    if (!selectedPublisher.length) {
      setSelectedOriginalPublisher([]);
      return;
    }

    const isPaginationEqual = isEqual(
      prevOriginalPublisherPagination,
      optionOriginalPublisherParams?.search
    );

    if (!isPaginationEqual) return;

    if (!optionOriginalPublisherParams?.search) {
      const filteredOptions = optionOriginalPublisher.filter(
        item => item?.original_publisher_id !== "all"
      );
      const allOptionsSelected = filteredOptions.every(option =>
        selectedOriginalPublisher.some(
          selected =>
            selected?.original_publisher_id === option?.original_publisher_id
        )
      );
      const updatedSelected = selectedOriginalPublisher
        .filter(item => item?.original_publisher_id !== "all")
        .filter(item =>
          filteredOptions.some(
            option =>
              option.original_publisher_id === item.original_publisher_id
          )
        );
      setSelectedOriginalPublisher(
        allOptionsSelected
          ? [...updatedSelected, { original_publisher_id: "all" }]
          : updatedSelected
      );
    }
  }, [
    selectedPublisher,
    optionOriginalPublisherParams,
    optionOriginalPublisher,
  ]);

  return (
    <Page className={classes?.root} title="Song Usage Review">
      <Container maxWidth={false}>
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <Fragment>
            <HeaderTitle title="Song Usage" breadcrumbData={breadcrumbData} />
            <Divider className={classes?.divider} />
            {roleSociety && (
              <Box mb={2}>
                <ButtonGroup>
                  {menuChart?.map(({ title, id }) => {
                    const selected = id === selectedMenuChart;
                    return (
                      <Button
                        className={
                          selected
                            ? classes?.groupButtonSelected
                            : classes?.groupButton
                        }
                        key={id}
                        onClick={() => {
                          setSelectedMenuChart(id);
                        }}
                      >
                        {title}
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </Box>
            )}
            <Card className={classes?.filterCard}>
              <div className={classes?.filterArea}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={2}
                  maxWidth={1084}
                >
                  {filterConfig?.map((item, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      key={index}
                      maxWidth={250}
                    >
                      <FormLabel label={item?.label} />
                      {item?.type === "checkboxWithTag" ? (
                        <MultipleSelectInputWithTags
                          placeholder={item?.placeholder}
                          value={item?.value}
                          textValue={item?.textValue}
                          options={item?.options}
                          optionKey={item?.optionKey}
                          optionLabel={item?.optionLabel}
                          onChange={item?.onChange}
                          handleRemove={item?.handleRemove}
                          search={item?.search}
                          searchValue={item?.searchValue}
                          onChangeSearch={item?.onChangeSearch}
                          loading={loadingFilter}
                          disabled={item?.disabled || loadingFilter}
                          group={item?.group}
                        />
                      ) : item?.type === "checkbox" ? (
                        <MultipleSelectInput
                          placeholder={item?.placeholder}
                          value={item?.value}
                          textValue={item?.textValue}
                          options={item?.options}
                          optionKey={item?.optionKey}
                          optionLabel={item?.optionLabel}
                          onChange={item?.onChange}
                          disabled={
                            item?.disabled ? item?.disabled : loadingFilter
                          }
                          width={255}
                        />
                      ) : item?.type === "yearPicker" ? (
                        <YearPicker
                          onChange={item?.onChange}
                          value={item?.value}
                        />
                      ) : (
                        <SelectInput
                          value={item?.value}
                          placeholder={item?.placeholder}
                          onChange={item?.onChange}
                          options={item?.options}
                          optionKey={item?.optionKey}
                          optionLabel={item?.optionLabel}
                          width={255}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Card>
            <Typography className={classes?.pageSubTitle}>
              Trend of Song Usage
            </Typography>
            <div className={classes?.chartCard}>
              <Box mb={2}>
                {chartData?.datasets.length > 0 && selectedDSP?.length > 0 && (
                  <ArrayTagChip
                    selectionsList={
                      selectedDSP.some(item => item.dsp_id === "all")
                        ? [{ dsp_id: "all", name: "All" }]
                        : selectedDSP
                    }
                    value="name"
                  />
                )}
              </Box>
              <SongUsageTrendChart
                chartData={chartData}
                onAxisClick={(_, data) => onClickChartAxis(data)}
              />
            </div>
            <SongUsageTrendReportCard chartReport={chartReport} />
            <Grid container justifyContent="space-between" my={2}>
              <Grid item>
                <SearchTextInput
                  placeholder="Song title, Composer"
                  onChange={e =>
                    handleChangeTableParams(e?.target?.value, "search")
                  }
                  value={tableParams?.search}
                />
              </Grid>
              <Grid item>
                <Grid container columnSpacing={2}>
                  {downloadButtonList?.map((item, key) => (
                    <Grid item key={key}>
                      <PrimaryButton
                        label={`Download ${item?.caption}`}
                        onClick={item?.onClick}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        )}
        <Box position="relative" display={loadingPage ? "none" : "block"}>
          <InnoTableV2
            columns={columnTable}
            onRequestSort={(sortBy, sort) => setSorting({ sortBy, sort })}
            items={tableData || []}
            isLoading={false}
            page={tableParams?.page}
            rowsPerPage={tableParams?.size}
            totalPage={tablePageCount}
            handleChangePage={(_, data) =>
              handleChangeTableParams(data, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeTableParams(e?.target?.value, "size")
            }
          />
        </Box>
      </Container>
    </Page>
  );
};

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
const useStyles = makeStyles(() => ({
  root: {
    padding: "24px 0",
  },
  pageSubTitle: {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: "700",
    fontStyle: "normal",
    margin: "20px 0",
  },
  divider: {
    margin: "16px 0",
    borderTop: "1px solid #e1e1e1",
  },
  filterArea: {
    backgroundColor: "#F9FAFB",
    padding: "20px 10px",
  },
  chartCard: {
    border: "1px solid #ebebeb",
    borderRadius: "5px",
    padding: "50px 20px",
  },
  highlightText: {
    fontWeight: "bold",
  },
  reportCard: {
    borderLeft: "1px solid gray",
    padding: "20px",
    maxWidth: 300,
  },
  reportText: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#687083",
    marginBottom: "15px",
  },
  reportValue: {
    fontSize: "24px",
    fontWeight: 500,
    color: "#111827",
  },
  groupButton: {
    textTransform: "none",
    border: "1px solid #D1D5DC",
    color: "#111827",
    height: 42,
    "&:hover": {
      color: "#111827",
    },
  },
  groupButtonSelected: {
    backgroundColor: "#D1D5DC",
    color: "#111827",
    textTransform: "none",
    border: "1px solid #D1D5DC",
    height: 42,
    "&:hover": {
      backgroundColor: "#D1D5DC",
      color: "#111827",
    },
  },
}));
const dspId = localStorage.getItem("idDsp");
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Song Usage",
    active: true,
  },
];
const productTypeList = [
  {
    value: 0,
    name: "None",
  },
  {
    value: "flat",
    name: "Flat",
  },
  {
    value: "recoupable",
    name: "Recoupable",
  },
];
const menuChart = [
  {
    id: 1,
    title: "Publisher",
  },
  {
    id: 2,
    title: "Author/Composer",
  },
];
const dspFilterConfig = ({
  value,
  options,
  onChange,
  params,
  onChangeSearch,
}) => ({
  label: "DSP",
  textValue: "DSP",
  value,
  options,
  optionKey: "dsp_id",
  optionLabel: "name",
  onChange,
  search: true,
  onChangeSearch,
  searchValue: params?.search,
  type: "checkboxWithTag",
});
const authorComposerFilterConfig = ({
  value,
  options,
  onChange,
  params,
  onChangeSearch,
}) => ({
  label: "Author/Composer",
  textValue: "Author/Composer",
  value,
  options,
  optionKey: "composer_id",
  optionLabel: "sure_name",
  onChange,
  search: true,
  onChangeSearch,
  searchValue: params?.search,
  type: "checkboxWithTag",
});
const publisherFilterConfig = ({
  value,
  options,
  onChange,
  params,
  onChangeSearch,
  rolePublisher,
}) => ({
  label: "Publisher",
  textValue: "Publisher",
  value,
  options,
  optionKey: "publisher_id",
  optionLabel: "name",
  onChange,
  search: true,
  onChangeSearch,
  searchValue: params?.search,
  type: "checkboxWithTag",
  group: rolePublisher,
});
const originalPublisherFilterConfig = ({
  value,
  options,
  onChange,
  params,
  onChangeSearch,
  watchParams,
}) => ({
  label: "Original Publisher",
  textValue: "Original Publisher",
  value,
  options,
  optionKey: "original_publisher_id",
  optionLabel: "name",
  onChange,
  search: true,
  onChangeSearch,
  searchValue: params?.search,
  type: "checkboxWithTag",
  disabled: watchParams?.length === 0,
});
const ousideDspFilterConfig = {
  label: "Outside DSP",
  value: 0,
  options: [{ id: 0, label: "None" }],
  optionKey: "id",
  optionLabel: "label",
};
const monthFilterConfig = (selectedMonthFilter, handleChangeFilterMonth) => ({
  label: "Month",
  placeholder: "Month",
  value: selectedMonthFilter,
  textValue: arrayToCommaSeparatedString(selectedMonthFilter, "shortName"),
  options: monthData,
  optionKey: "id",
  optionLabel: "fullName",
  onChange: handleChangeFilterMonth,
  type: "checkbox",
});
const yearFilterConfig = (queryParams, handleChangeQueryParams) => ({
  label: "Year",
  value: queryParams?.year,
  onChange: year => handleChangeQueryParams(year, "year"),
  type: "yearPicker",
});
const productTypeFilterConfig = (queryParams, handleChangeQueryParams) => ({
  label: "Product Type",
  value: queryParams?.product_type || 0,
  options: productTypeList,
  optionKey: "value",
  optionLabel: "name",
  onChange: event => {
    const value = event?.target?.value || "";
    handleChangeQueryParams(value, "product_type");
  },
});
const revenueSocietyFilterConfig = (
  optionTypeRevenue,
  selectedRevenue,
  handleChangeFilterRevenue
) => ({
  label: "Revenue",
  textValue: "Revenue",
  value: selectedRevenue,
  options: optionTypeRevenue,
  optionKey: "right_type_id",
  optionLabel: "right_type_name",
  onChange: handleChangeFilterRevenue,
  type: "checkbox",
});

export default ReviewLagu;
