import React from "react";
import axios from "axios";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../../../services/urlConstant";
import FormRole from "./Components/FormRole";

function EditRole(props) {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: "Bearer " + token,
  };
  const typeRole = props?.match?.params?.type;
  const roleId = props?.match?.params?.role_id;

  const modalError = err =>
    Swal.fire({
      title: "Error",
      text: err?.response?.data?.errors?.message,
      icon: "error",
      confirmButtonText: "OK",
    });

  const modalSuccess = () =>
    Swal.fire({
      title: "Success",
      text: "Success Add Data",
      icon: "success",
      confirmButtonText: "OK",
    });

  const handleSubmitData = payload => {
    axios
      .put(`${hardBaseUrl}/role-menu`, payload, {
        headers,
      })
      .then(res => {
        if (res.status === 200) {
          modalSuccess().then(result => {
            if (result.isConfirmed) {
              history.push("/admin/konfigurasi/role-user");
            }
          });
        }
      })
      .catch(err => {
        modalError(err);
      });
  };

  return (
    <FormRole
      title="Edit"
      role={typeRole}
      handleSubmitData={handleSubmitData}
      roleId={roleId}
    />
  );
}
export default EditRole;
