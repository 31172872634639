import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import React from "react";
import { useHistory } from "react-router-dom";
import LandingFooter from "../../../components/templates/LandingPage/LandingFooter";
import LandingHero from "../../../components/templates/LandingPage/LandingHero";
import bgImage from "../../../assets/background/background_about_illustrative.webp";
import bgShade from "../../../assets/background/background_blog_shade.webp";
import { useMediaQuery, useTheme } from "@material-ui/core";
import "../../../components/templates/LandingPage/LandingStyle.css";
import { blogList } from "./DummyBlog";

const interFont = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const Blog = () => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const heroBackground = () => {
    return (
      <>
        <img
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
          alt="background-image"
          src={bgImage}
        />
        <img
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
          alt="background-shade"
          src={bgShade}
        />
      </>
    );
  };

  const heroContent = () => {
    return (
      <ThemeProvider theme={interFont}>
        <Typography
          style={{
            fontWeight: 800,
            fontSize: isMobile ? "60px" : "100px",
            color: "white",
            maxWidth: "540px",
          }}
        >
          Blog
        </Typography>
        <Typography
          style={{
            fontWeight: 400,
            color: "white",
            fontSize: isMobile ? "16px" : "24px",
            marginTop: "20px",
            lineHeight: "32px",
            maxWidth: "550px",
          }}
        >
          We write about the latest trends, the best practices, and the inside
          scoop on how to get paid what you&apos;re worth. Whether you&apos;re a
          songwriter or publisher, we have something for you.
        </Typography>
      </ThemeProvider>
    );
  };
  const handleDetail = id => {
    history.push(`/blog/${id}`);
  };
  return (
    <>
      <LandingHero heroBackground={heroBackground} heroContent={heroContent} />
      <div className="landing-content">
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "center",
          }}
        >
          {blogList?.map((item, index) => (
            <div
              key={index}
              style={{
                position: "relative",
                margin: isMobile ? "auto" : index === 1 ? "0 24px" : "",
                height: "925px",
                maxWidth: isMobile ? "360px" : "386px",
                marginBottom: isMobile && "40px",
              }}
            >
              <div
                style={{
                  position: "relative",
                }}
              >
                <img
                  key={index}
                  alt={"illustrative" + (index + 1)}
                  src={item?.image}
                  style={{
                    maxWidth: isMobile ? "360px" : "386px",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background:
                      "linear-gradient(67.45deg, #652D8D 25.08%, #E80088 112.47%)",
                    pointerEvents: "none",
                    opacity: "50%",
                  }}
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "500px",
                  left: "50%",
                  transform: "translate(-50%)",
                }}
              >
                <div
                  style={{
                    border: "3px solid #E80088",
                    width: "256px",
                    background: "white",
                  }}
                >
                  <ThemeProvider theme={interFont}>
                    <Typography
                      style={{
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "28px",
                        color: "white",
                        background: "#E80088",
                        textAlign: "center",
                        width: "250px",
                        height: "116px",
                        padding: "16px",
                      }}
                    >
                      {item?.title}
                    </Typography>
                  </ThemeProvider>
                  <div
                    style={{
                      padding: "32px",
                    }}
                  >
                    <ThemeProvider theme={interFont}>
                      <Typography
                        style={{
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#E80088",
                          textAlign: "center",
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 12,
                        }}
                      >
                        {item?.content}
                      </Typography>
                    </ThemeProvider>
                  </div>
                </div>
              </div>

              <IconButton
                onClick={() => handleDetail(item?.id)}
                style={{
                  position: "absolute",
                  top: "895px",
                  left: "50%",
                  transform: "translate(-50%)",
                }}
              >
                <img
                  alt="button-detail"
                  src={require("assets/image-public/images/Button-right-purple.png")}
                  style={{
                    zIndex: 2,
                  }}
                />
              </IconButton>
            </div>
          ))}
        </div>
      </div>
      <LandingFooter captions="Solutions for the music industry to transparent royalty system" />
    </>
  );
};

export default Blog;
