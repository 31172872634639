import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  useHistory,
} from "react-router-dom";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { tokenInfo } from "../redux/actions/auth";
import { SplashScreen, Page } from "components";
import { hardBaseUrl } from "../services/urlConstant";
import Axios from "axios";
import * as Sentry from "@sentry/react";
import { getErrors } from "../utils";

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: "center",
    verticalAlign: "middle",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function RedirectPage(props) {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = props.match.params.token;
  const auth = useSelector(state => state.auth);
  const message = useSelector(state => state.auth.message);
  const [loadingDelete, setLoadingDelete] = React.useState(true);

  useEffect(() => {
    localStorage.setItem("token", props.match.params.token);
    const loadData = async token => {
      try {
        await dispatch(tokenInfo(token));
      } catch (error) {
        Sentry.captureMessage(
          "Error response status newGetMe : " + error.response.status
        );
      }
    };
    loadData(props.match.params.token);
  }, [dispatch, token]);

  useEffect(() => {
    setLoadingDelete(true);
    setTimeout(() => {
      setLoadingDelete(false);
      if (message) {
        if (auth.user.role !== null) {
          if (auth.user.role.type === "admin") {
            if (auth.user.role.name === "Admin APMINDO") {
              history.push("/admin/master/lagu-admin");
            } else {
              // "Superadmin"
              history.push("/admin/dashboard");
            }
          }
          if (auth.user.role.type === "association") {
            history.push("/admin/dashboard-association");
          }
          if (auth.user.role.type === "publisher") {
            history.push("/admin/dashboard");
          }
          if (auth.user.role.type === "society") {
            history.push("/admin/dashboard");
          }
          if (auth.user.role.type === "composer") {
            try {
              let token = props.match.params.token;
              const url = "/composer-manager/composers";

              const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              };
              Axios.get(hardBaseUrl + url, { headers })
                .then(res => {
                  let resultLength = res.data.data.length;
                  if (resultLength === 1) {
                    history.push("/pencipta/dashboard-pencipta");
                  } else if (resultLength === 0) {
                    history.push("/pencipta/dashboard-pencipta");
                  } else {
                    history.push("/manage-composer");
                  }
                })
                .catch(err => {
                  let errMessage = getErrors(err.response);
                  Sentry.captureMessage(
                    "Error catch redirect page : " + errMessage
                  );
                });
            } catch (error) {
              let errMessage = getErrors(error.response);
              Sentry.captureMessage(
                "Error catch /composer-manager/composers : " + errMessage
              );
            }
          }
        }
      }
    }, 1500);
  }, [message]);

  return (
    <>
      {loadingDelete ? (
        <SplashScreen />
      ) : (
        <>
          <Page className={classes.root} title="Redirect">
            <Backdrop
              className={classes.backdrop}
              open={loadingDelete}
            // onClick={handleCloseLoadingBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Page>
        </>
      )}
    </>
  );
}

export default RedirectPage;
