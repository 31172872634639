import { Container, Divider } from "@material-ui/core";
import { VisibilityOutlined } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  AutoCompleteComponent,
  ButtonGroupTop,
  ModalError,
  ModalSuccess,
  ModalWarning,
  Page,
  PrimaryButton,
  PrimaryIconButton,
  SectionLabel,
  SelectInput,
  SkeletonComponent,
  StatusChip,
  SwitchInput,
} from "components";
import { topMenuButtonCaris, topMenuButtonMPIS } from "constants";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { formatDate, getCookie, getErrors, wordCapitalize } from "utils";
import pencilVector from "../../../../assets/img/pencilVector.svg";
import trashVector from "../../../../assets/img/trashVector.svg";
import { hardBaseUrl } from "../../../../services/urlConstant";
import DialogForm from "./DialogForm";

function SubReporting() {
  const classes = useStyles();
  const history = useHistory();
  const typeWeb = localStorage.getItem("typeWeb");
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    search: "",
    dsp_id: "",
    status: "",
    order: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [optionDsp, setOptionDsp] = useState([]);
  const [optionPublisher, setOptionPublisher] = useState([]);
  const [preloadId, setpreloadId] = useState(0);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const openModal = id => {
    setpreloadId(id || null);
    setModalVisible(true);
  };
  const handleDelete = id =>
    ModalWarning(
      "Delete Sub Reporting",
      "Are You sure to delete this data ?"
    ).then(res => res?.isConfirmed && deleteSubReporting(id));

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/sub-reporting/list`,
        {
          headers,
          params: queryParams,
        }
      );

      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.limit || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getOptionDSP = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publishers/list/${userLogin?.publisher?.publisher_id}/dsps`,
        { headers }
      );
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        id: item?.dsp_id,
        label: item?.name,
      }));
      setOptionDsp(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getOptionPublisher = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/list-publisher?is_active_flag=true`,
        { headers }
      );
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        id: item?.publisher_id,
        label: item?.name,
      }));
      setOptionPublisher(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleSwitchStatus = async (event, id) => {
    const { checked } = event?.target;

    try {
      await axios.put(
        `${hardBaseUrl}/publisher/sub-reporting/change-status/${id}`,
        {
          is_active: checked,
        },
        { headers }
      );
      ModalSuccess(
        `Succesfully ${checked ? "Activate" : "Deactivate"} Sub Reporting`
      ).then(res => res?.isConfirmed && getDataTable());
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const deleteSubReporting = async id => {
    try {
      await axios.delete(`${hardBaseUrl}/publisher/sub-reporting/${id}`, {
        headers,
      });
      ModalSuccess("Succesfully Delete Sub Reporting").then(
        res => res?.isConfirmed && getDataTable()
      );
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };

  useEffect(() => {
    getOptionDSP();
    getOptionPublisher();
  }, []);
  useEffect(() => {
    getDataTable();
  }, [queryParams]);

  return (
    <Page className={classes.root} title="Sub Reporting">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Configuration" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop
            items={
              typeWeb === "mpis" ? topMenuButtonMPIS() : topMenuButtonCaris
            }
          />
          <Divider className={classes.divider} />
          <SectionLabel
            title="Sub Reporting"
            subTitle="This feature serves to leave DSP reports to other publishers"
          />
          <Grid
            container
            justifyContent="right"
            alignItems="center"
            columnSpacing={2}
            my="16px"
          >
            <Grid item>
              <AutoCompleteComponent
                options={optionDsp}
                label="DSP"
                value={
                  optionDsp.find(option => option.id === queryParams?.dsp_id) ||
                  null
                }
                onChange={value => handleChangeQueryParams(value, "dsp_id")}
                size="small"
                width={250}
              />
            </Grid>
            <Grid item>
              <SelectInput
                label="Status"
                value={queryParams?.status}
                onChange={e =>
                  handleChangeQueryParams(e?.target?.value, "status")
                }
                options={listStatus}
                optionKey="value"
                optionLabel="name"
                placeholder="None"
                width={250}
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                label="Add Sub Reporting"
                startIcon={<AddIcon />}
                onClick={() => openModal()}
                size="large"
              />
            </Grid>
          </Grid>
          <InnoTableV2
            isLoading={false}
            columns={columnTable({ handleSwitchStatus })}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.limit}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "limit")
            }
            isHaveAction
            renderAction={item => {
              const { publisher_sub_reporting_id, status } = item;
              return (
                <Grid container columnSpacing={1} width="116px">
                  <Grid item>
                    <PrimaryIconButton
                      tooltipTitle="View"
                      icon={<VisibilityOutlined />}
                      onClick={() =>
                        history.push({
                          pathname: `/admin/konfigurasi/sub-reporting/${publisher_sub_reporting_id}`,
                          state: { customer: item },
                        })
                      }
                    />
                  </Grid>
                  {status === "waiting" && (
                    <>
                      <Grid item>
                        <PrimaryIconButton
                          tooltipTitle="Update"
                          icon={<img src={pencilVector} alt="update-icon" />}
                          onClick={() => openModal(publisher_sub_reporting_id)}
                        />
                      </Grid>
                      <Grid item>
                        <PrimaryIconButton
                          tooltipTitle="Delete"
                          icon={<img src={trashVector} alt="update-icon" />}
                          onClick={() =>
                            handleDelete(publisher_sub_reporting_id)
                          }
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              );
            }}
          />
          {modalVisible && (
            <DialogForm
              open={modalVisible}
              onClose={() => setModalVisible(false)}
              optionPublisher={optionPublisher}
              optionDsp={optionDsp}
              preloadId={preloadId}
            />
          )}
        </Container>
      )}
    </Page>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Configuration",
    link: "/admin/konfigurasi/sub-reporting",
  },
  {
    label: "Sub Reporting",
    active: true,
  },
];
const listStatus = [
  {
    name: "Waiting for Approval",
    value: "waiting",
  },
  {
    name: "Approved / Inactive",
    value: "approved",
  },
  {
    name: "Rejected",
    value: "rejected",
  },
];
const columnTable = ({ handleSwitchStatus }) => [
  {
    name: "all",
    title: "Status",
    renderText: item => (
      <SwitchInput
        checked={item?.status === "waiting" ? false : item.is_active}
        disabled={item?.status === "waiting"}
        onChange={e => handleSwitchStatus(e, item?.publisher_sub_reporting_id)}
      />
    ),
  },
  {
    name: "sub_reporting_publisher_name",
    title: "Publisher",
  },
  {
    name: "dsp_name",
    title: "DSP",
  },
  {
    name: "start_date",
    title: "Start Date",
    renderText: item => formatDate(item),
  },
  {
    name: "end_date",
    title: "End Date",
    renderText: item => formatDate(item),
  },
  {
    name: "status",
    title: "Approval Status",
    renderText: status => (
      <StatusChip
        type={
          status === "approved"
            ? "success"
            : status === "rejected"
            ? "danger"
            : status === "waiting"
            ? "warning"
            : ""
        }
        label={
          status == "waiting" ? "Waiting for Approval" : wordCapitalize(status)
        }
      />
    ),
  },
];

export default SubReporting;
