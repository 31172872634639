import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import PreviewPhoto from "../../../../assets/img/previewph.png";
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
  },
  header: {
    fontSize: "40px",
    fontWeight: "bold",
    color: "#000",
  },
  writerName: {
    fontSize: "20px",
    color: "gray",
    marginTop: "10px",
    marginBottom: "10px",
  },
  date: {
    fontSize: "20px",
    color: "gray",
  },
  img: {
    objectFit: "contain",
    width: "100%",
  },
  mainText: {
    fontSize: "20px",
    color: "#687083",
    marginTop: "10px",
    marginBottom: "10px",
    textAlign: "justify",
    lineHeight: "30px",
  },
  appBar: {
    backgroundColor: "#f5f5f5",
    color: "#000",
    boxShadow: "none",
    borderBottom: "1px solid #e0e0e0",
  },
  textHeader: {
    fontSize: "25px",

    marginLeft: "10px",
  },
  iconHeader: {
    fontSize: "29px",
    marginTop: "5px",
  },
  backButton: {
    cursor: "pointer",
    "&:hover": {
      color: "gray",
    },
  },
  displayFlex: {
    display: "flex",
    width: "100%",
  },
  previewPhoto: {
    width: "120px",
    height: "120px",
  },
  card: {
    border: "none",
    boxShadow: "none",
    display: "flex",
    marginBottom: "10px",
  },
  cardDetail: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  menuTitle: {
    fontSize: "20px",
    color: "#687083",
    fontWeight: 700,
    marginBottom: "20px",
  },
}));

const PreviewBerita = props => {
  const [title, setTitle] = useState("");
  const [recentDate, setRecentDate] = useState("");
  const [publisherName, setPublisherName] = useState("");
  const [gambar, setGambar] = useState("");
  const [type, setType] = useState("");

  const classes = useStyles();
  const theme = createTheme({
    typography: {
      fontFamily: ["Inter"].join(","),
    },
  });
  useEffect(() => {
    const title = localStorage.getItem("title");
    const recentDate = localStorage.getItem("recentDate");
    const publisherName = localStorage.getItem("publisherName");
    const type =
      localStorage
        .getItem("type")
        .charAt(0)
        .toUpperCase() + localStorage.getItem("type").slice(1);
    document.getElementById("contentAsHTML").innerHTML = localStorage.getItem(
      "content"
    );

    if (localStorage.getItem("image_logo") !== null) {
      setGambar(localStorage.getItem("image_logo"));
    } else {
      setGambar("");
    }

    setTitle(title);
    setRecentDate(recentDate);
    setPublisherName(publisherName);
    setType(type);
  }, []);
  const handleBack = e => {
    e.preventDefault();
    props.history.push("/admin/berita-publisher/tambah");
  };
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <ThemeProvider theme={theme}>
            <Typography
              className={classes.backButton}
              variant="h6"
              onClick={e => handleBack(e)}
            >
              <div className={classes.displayFlex}>
                <CloseIcon className={classes.iconHeader} />
                <div className={classes.textHeader}>Preview</div>
              </div>
            </Typography>
          </ThemeProvider>
        </Toolbar>
      </AppBar>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <ThemeProvider theme={theme}>
              <Typography variant="h1" className={classes.header}>
                {title}
              </Typography>
              <div>
                <Typography variant="h6" className={classes.writerName}>
                  {publisherName}
                  <FiberManualRecordIcon style={{ fontSize: "10" }} />
                  <span className={classes.date}> {recentDate}</span>{" "}
                  <span>
                    <FiberManualRecordIcon style={{ fontSize: "10" }} /> {type}
                  </span>
                </Typography>{" "}
              </div>
            </ThemeProvider>

            <img className={classes.img} src={gambar} id="imgData"></img>
            <ThemeProvider theme={theme}>
              <Typography variant="body2" className={classes.mainText}>
                <div id="contentAsHTML"></div>
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item xs={4} md={4} sm={4} lg={4} xl={4}>
            <div>
              <div style={{ marginLeft: "30px", marginBottom: "50px" }}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h6" className={classes.menuTitle}>
                    EDUCATION
                  </Typography>
                  <div>
                    <Card className={classes.card}>
                      <>
                        <CardMedia
                          image={PreviewPhoto}
                          className={classes.previewPhoto}
                        />
                      </>
                      <div className={classes.details}>
                        <CardContent className={classes.content}>
                          <div>
                            <Skeleton variant="text" width={80} height={30} />
                          </div>
                          <div>
                            <Skeleton variant="text" width={150} height={30} />
                          </div>
                          <div style={{ display: "flex" }}>
                            <Skeleton variant="text" width={50} height={10} />{" "}
                            <Skeleton
                              variant="circle"
                              width={5}
                              height={5}
                              style={{
                                marginLeft: "5px",
                                marginTop: "5px",
                                marginRight: "5px",
                              }}
                            />{" "}
                            <Skeleton variant="text" width={50} height={10} />
                          </div>
                        </CardContent>
                      </div>
                    </Card>
                  </div>
                  <div>
                    <Card className={classes.card}>
                      <>
                        <CardMedia
                          image={PreviewPhoto}
                          className={classes.previewPhoto}
                        />
                      </>
                      <div className={classes.details}>
                        <CardContent className={classes.content}>
                          <div>
                            <Skeleton variant="text" width={80} height={30} />
                          </div>
                          <div>
                            <Skeleton variant="text" width={150} height={30} />
                          </div>
                          <div style={{ display: "flex" }}>
                            <Skeleton variant="text" width={50} height={10} />{" "}
                            <Skeleton
                              variant="circle"
                              width={5}
                              height={5}
                              style={{
                                marginLeft: "5px",
                                marginTop: "5px",
                                marginRight: "5px",
                              }}
                            />{" "}
                            <Skeleton variant="text" width={50} height={10} />
                          </div>
                        </CardContent>
                      </div>
                    </Card>
                  </div>

                  <div>
                    <Card className={classes.card}>
                      <>
                        <CardMedia
                          image={PreviewPhoto}
                          className={classes.previewPhoto}
                        />
                      </>
                      <div className={classes.details}>
                        <CardContent className={classes.content}>
                          <div>
                            <Skeleton variant="text" width={80} height={30} />
                          </div>
                          <div>
                            <Skeleton variant="text" width={150} height={30} />
                          </div>
                          <div style={{ display: "flex" }}>
                            <Skeleton variant="text" width={50} height={10} />{" "}
                            <Skeleton
                              variant="circle"
                              width={5}
                              height={5}
                              style={{
                                marginLeft: "5px",
                                marginTop: "5px",
                                marginRight: "5px",
                              }}
                            />{" "}
                            <Skeleton variant="text" width={50} height={10} />
                          </div>
                        </CardContent>
                      </div>
                    </Card>
                  </div>
                </ThemeProvider>
              </div>
            </div>

            <div style={{ marginLeft: "30px", marginBottom: "50px" }}>
              <ThemeProvider theme={theme}>
                <Typography variant="h6" className={classes.menuTitle}>
                  TOP NEWS
                </Typography>
                <div>
                  <Card className={classes.card}>
                    <>
                      <CardMedia
                        image={PreviewPhoto}
                        className={classes.previewPhoto}
                      />
                    </>
                    <div className={classes.details}>
                      <CardContent className={classes.content}>
                        <div>
                          <Skeleton variant="text" width={80} height={30} />
                        </div>
                        <div>
                          <Skeleton variant="text" width={150} height={30} />
                        </div>
                        <div style={{ display: "flex" }}>
                          <Skeleton variant="text" width={50} height={10} />{" "}
                          <Skeleton
                            variant="circle"
                            width={5}
                            height={5}
                            style={{
                              marginLeft: "5px",
                              marginTop: "5px",
                              marginRight: "5px",
                            }}
                          />{" "}
                          <Skeleton variant="text" width={50} height={10} />
                        </div>
                      </CardContent>
                    </div>
                  </Card>
                </div>
                <div>
                  <Card className={classes.card}>
                    <>
                      <CardMedia
                        image={PreviewPhoto}
                        className={classes.previewPhoto}
                      />
                    </>
                    <div className={classes.details}>
                      <CardContent className={classes.content}>
                        <div>
                          <Skeleton variant="text" width={80} height={30} />
                        </div>
                        <div>
                          <Skeleton variant="text" width={150} height={30} />
                        </div>
                        <div style={{ display: "flex" }}>
                          <Skeleton variant="text" width={50} height={10} />{" "}
                          <Skeleton
                            variant="circle"
                            width={5}
                            height={5}
                            style={{
                              marginLeft: "5px",
                              marginTop: "5px",
                              marginRight: "5px",
                            }}
                          />{" "}
                          <Skeleton variant="text" width={50} height={10} />
                        </div>
                      </CardContent>
                    </div>
                  </Card>
                </div>

                <div>
                  <Card className={classes.card}>
                    <>
                      <CardMedia
                        image={PreviewPhoto}
                        className={classes.previewPhoto}
                      />
                    </>
                    <div className={classes.details}>
                      <CardContent className={classes.content}>
                        <div>
                          <Skeleton variant="text" width={80} height={30} />
                        </div>
                        <div>
                          <Skeleton variant="text" width={150} height={30} />
                        </div>
                        <div style={{ display: "flex" }}>
                          <Skeleton variant="text" width={50} height={10} />{" "}
                          <Skeleton
                            variant="circle"
                            width={5}
                            height={5}
                            style={{
                              marginLeft: "5px",
                              marginTop: "5px",
                              marginRight: "5px",
                            }}
                          />{" "}
                          <Skeleton variant="text" width={50} height={10} />
                        </div>
                      </CardContent>
                    </div>
                  </Card>
                </div>
              </ThemeProvider>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default PreviewBerita;
