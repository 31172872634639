import {
  Breadcrumbs,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router-dom";
import { Page, PrimaryButton } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  label: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#364052",
    fontStyle: "normal",
    lineHeight: "20px",
    marginBottom: "10px",
  },
  body: {
    fontWeight: 400,
    fontSize: "16px",
    color: "#111827",
    lineHeight: "24px",
    marginBottom: "10px",
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadCrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  button: {
    marginBottom: "20px",
    marginTop: "20px",
    backgroundColor: "black",
    color: "white",
    width: "135px",
    height: "40px",
    textTransform: "none",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  font24: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  hr: {
    border: "1px solid #E4E7EB",
    marginTop: "32px",
  },
  mt32: {
    marginTop: "32px",
  },
  textStyle: {
    marginTop: "5px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  card: {
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
  },
  divContainer: {
    width: "48px",
    height: "24px",
    backgroundColor: color,
    marginTop: "5px",
    marginBottom: "10px",
  },
  imgStyle: {
    width: "160px",
    height: "160px",
    objectFit: "cover",
    border: "1px solid #D1D5DC",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  mt45: {
    marginTop: "45px",
  },
  mt20: { marginTop: "20px" },
}));
export default function DetailDSP() {
  const classes = useStyles();
  const dataDSP = useLocation().state;
  const token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const [clientName, setClientName] = useState("");
  const [logo, setLogo] = useState("");
  const [color, setColor] = useState("");
  const [penanggungJawab, setPenanggungJawab] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [publisherId, setPublisherId] = useState("");
  const [clientType, setClientType] = useState("");
  const [clientTypeList, setClientTypeList] = useState([]);
  const [domicile, setDomicile] = useState("");
  const [clientClassification, setClientClassification] = useState("");
  const [address, setAddress] = useState("");
  const [trans_fee, setTrans_fee] = useState(0);
  const [curr_name, setCurr_name] = useState("");
  const getDetail = () => {
    const url = `${hardBaseUrl}/dsp/${dataDSP.row?.master_client_id}`;
    axios
      .get(url, { headers })
      .then(res => {
        setClientName(res.data.data.name);
        setDomicile(res?.data?.data?.domicile);
        setLogo(res.data.data.profile_image);
        setColor(res.data.data.color);
        setClientType(
          res.data.data.client_classification.client_classification_id
        );
        setPenanggungJawab(res.data.data.person_name);
        setEmail(res.data.data.person_email);
        setClientType(
          res.data.data.client_classification.client_classification_id
        );
        setPhone(res.data.data.person_phone);
        setPublisherId(res.data.data.publisher_id);
        setClientClassification(
          res.data.data.client_classification.classification_name
        );
        setAddress(res.data.data.address);
        setTrans_fee(res.data.data.transaction_fee);
        setCurr_name(res?.data?.data?.fee_currency?.currency_iso_code);
        // const currency_url = `${hardBaseUrl}/currency/datatable`;
        // const params = {
        //   per_page: 99,
        //   page: 1,
        // };
        // axios.get(currency_url, { headers, params }).then(resCurr => {
        //   let namCurr = _.find(resCurr.data.data, function(o) {
        //     return o.id === res.data.data.currency_id;
        //   });
        //   if (typeof namCurr !== "undefined") {
        //     setCurr_name(namCurr.code);
        //   }
        // });
      })
      .catch(() => {});
  };
  const getListClient = () => {
    const url = `${hardBaseUrl}/publisher/classification/index`;
    axios
      .get(url, { headers })
      .then(res => {
        setClientTypeList(res.data.data);
      })
      .catch(() => {});
  };
  useEffect(() => {
    getListClient();
    getDetail();
  }, []);
  const getClientTypeName = id => {
    const data = clientTypeList.find(
      item => item.client_classification_id === id
    );
    return data ? data.classification_name : "-";
  };
  return (
    <Page className={classes.root} title="Personalisasi">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography className={classes.font24}>Client Detail</Typography>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>
              <Typography className={classes.breadCrumbs}>Parameter</Typography>
              <Typography className={classes.breadCrumbs}>Client</Typography>
              <Typography className={classes.breadCrumbsActive}>
                Client Detail
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <hr className={classes.hr} />
        <Grid container spacing={2} className={classes.mt32}>
          <Grid item xs={4} sm={6} md={6} lg={6} xl={6}>
            <div>
              <Typography variant="h5" className={classes.subTitle}>
                Information DSP
              </Typography>
              <p className={classes.textStyle}>
                Information about DSP that has been joined to us
              </p>
            </div>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div>
                    <Typography variant="subtitle2" className={classes.label}>
                      Client Type
                    </Typography>
                    <Typography variant="body1" className={classes.body}>
                      {getClientTypeName(clientType)}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Typography variant="subtitle2" className={classes.label}>
                    Client Classification
                  </Typography>
                  <Typography variant="body1" className={classes.body}>
                    {clientClassification !== "" ? clientClassification : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="subtitle2" className={classes.label}>
                        Client Name
                      </Typography>
                      <Typography variant="body1" className={classes.body}>
                        {clientName || "-"}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.label}>
                        Identity Color
                      </Typography>
                      <div className={classes.divContainer} />
                      <Typography variant="subtitle2" className={classes.label}>
                        Logo
                      </Typography>
                      <a href={dataDSP.link}>
                        <img src={logo} alt="" className={classes.imgStyle} />
                      </a>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="subtitle2" className={classes.label}>
                        Domicile
                      </Typography>
                      <Typography variant="body1" className={classes.body}>
                        {domicile || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              {publisherId === 0 ? <></> : <></>}
            </div>
            <div className={classes.flexEnd}>
              <PrimaryButton
                label="Close"
                onClick={() => {
                  window.location.href = "/admin/parameter/dsp";
                }}
              />
            </div>
          </Grid>
          <Grid item xs={4} sm={6} md={6} lg={6} xl={6}>
            <div>
              <Typography variant="h5" className={classes.subTitle}>
                Person Responsible
              </Typography>
              <p className={classes.textStyle}>
                Information about the person responsible
              </p>
            </div>
            <div>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="subtitle2" className={classes.label}>
                        Person Responsible
                      </Typography>
                      <Typography variant="body1" className={classes.body}>
                        {penanggungJawab}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.label}>
                        Email
                      </Typography>
                      <Typography variant="body1" className={classes.body}>
                        {email}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.label}>
                        Address
                      </Typography>
                      <Typography variant="body1" className={classes.body}>
                        {address}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="subtitle2" className={classes.label}>
                        Phone Number
                      </Typography>
                      <Typography variant="body1" className={classes.body}>
                        {phone}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
            <div className={classes.mt45}>
              <Typography variant="h5" className={classes.subTitle}>
                Transfer & Fee Currency
              </Typography>
              <p className={classes.textStyle}>
                To Settings Transfer Fee & Currency of Client
              </p>
            </div>
            <Card className={(classes.card, classes.mt20)}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="subtitle2" className={classes.label}>
                      Currency
                    </Typography>
                    <Typography variant="subtitle2" className={classes.label}>
                      {curr_name || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant="subtitle2" className={classes.label}>
                      Transfer Fee
                    </Typography>
                    <NumberFormat
                      value={trans_fee}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      thousandsGroupStyle="thousand"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
