import {
  Backdrop,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Container,
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTable } from "inno-ui";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import PencilVector from "../../../../assets/img/pencilVector.svg";
import RefreshVector from "../../../../assets/img/refresh-cw.svg";
import { ButtonWithModalPerItem } from "../../../../components/atoms/Button";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { Page, SkeletonComponent } from "components";
import RoleChips from "../../../../components/atoms/Chip/RoleChips";
import { hardBaseUrl } from "../../../../services/urlConstant";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  title: {
    marginTop: theme.spacing(3),
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  subTitle: {
    color: "#687083",
    fontSize: "14px",
    margin: "6px 0 10px",
  },
  buttonAdd: {
    padding: "10px 16px",
    textTransform: "none",
    backgroundColor: "black",
    color: "white",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
    marginTop: "16px",
    alignSelf: "end",
  },
  pageTitle: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#687083",
  },
  buttonActions: {
    padding: "5px",
    color: "white",
    width: "28px",
    height: "28px",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      boxShadow: "none",
    },
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  actionButtonIndentation: {
    marginLeft: 8,
  },
  chipStatus: {
    border: "1px solid #D1D5DC",
  },
  flexContainer: {
    display: "flex",
  },
}));

function RoleUser() {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const urlRoleList = `${hardBaseUrl}/role`;
  const headers = {
    Authorization: "Bearer " + token,
  };
  const topMenuButton = [
    {
      id: 0,
      title: "System",
      link: "/admin/konfigurasi/sistem",
      selected: false,
    },
    {
      id: 1,
      title: "Notification Template",
      link: "/admin/konfigurasi/template-notifikasi",
      selected: false,
    },
    {
      id: 2,
      title: "Tax",
      link: "/admin/konfigurasi/pajak",
      selected: false,
    },
    {
      id: 3,
      title: "Role",
      link: "/admin/konfigurasi/role-user",
      selected: true,
    },
    {
      id: 4,
      title: "Activity",
      link: "/admin/konfigurasi/aktifitas-admin",
      selected: false,
    },
    {
      id: 5,
      title: "Billing Configuration",
      link: "/admin/konfigurasi/billing-configuration",
      selected: false,
    },
    {
      id: 6,
      title: "DSP Type Revenue",
      link: "/admin/konfigurasi/dsp-type-revenue",
      selected: false,
    },
    {
      id: 7,
      title: "Announcement Management",
      link: "/admin/konfigurasi/announcement-management",
      selected: false,
    },
  ];
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTableAdmin, setDataTableAdmin] = useState([]);
  const [dataTablePublisher, setDataTablePublisher] = useState([]);
  const [dataTableComposer, setDataTableComposer] = useState([]);
  const [dataTableAssociation, setDataTableAssociation] = useState([]);
  const [dataTableSociety, setDataTableSociety] = useState([]);

  const modalError = err =>
    Swal.fire({
      title: "Error",
      text: err?.response?.data?.errors?.message,
      icon: "error",
      confirmButtonText: "OK",
    });

  const modalSuccess = text =>
    Swal.fire({
      title: "Success",
      text: text,
      icon: "success",
      confirmButtonText: "OK",
    });

  const getRoleList = async () => {
    setLoadingPage(true);
    try {
      for (const item of formSkeleton) {
        const { roleType } = item;
        const response = await axios.get(urlRoleList, {
          headers,
          params: { type: roleType },
        });
        switch (roleType) {
          case "admin":
            setDataTableAdmin(response?.data?.data);
            break;
          case "publisher":
            setDataTablePublisher(response?.data?.data);
            break;
          case "composer":
            setDataTableComposer(response?.data?.data);
            break;
          case "association":
            setDataTableAssociation(response?.data?.data);
            break;
          case "society":
            setDataTableSociety(response?.data?.data);
            break;
          default:
            break;
        }
      }
      setLoadingPage(false);
    } catch {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getRoleList();
  }, []);

  const handleOnSelectedButton = value => {
    history.push(value.data[value.newButtonSelected].link);
  };

  const formSkeleton = [
    {
      title: "User Role",
      subTitle:
        "Displays a list of roles available in the MPIS application. Roles are made for the needs of grouping users based on work functions.",
      table: dataTableAdmin,
      roleType: "admin",
    },
    {
      title: "Publisher User Role",
      subTitle:
        "Displays a list of roles available in the MPIS application. Roles are made for the needs of grouping users based on work functions.",
      table: dataTablePublisher,
      roleType: "publisher",
    },
    {
      title: "Composer User Role",
      subTitle:
        "Displays a list of roles available in the MPIS application. Roles are created for the needs of grouping users based on the work function of the composer user.",
      table: dataTableComposer,
      roleType: "composer",
    },
    {
      title: "Association User Role",
      subTitle: "Displays a list of roles available in the MPIS association.",
      table: dataTableAssociation,
      roleType: "association",
    },
    {
      title: "Society User Role",
      subTitle: "Displays a list of roles available in the MPIS society user",
      table: dataTableSociety,
      roleType: "society",
    },
  ];

  const handleActivateRole = async item => {
    const isActive = item?.is_active;
    const action = isActive ? "Deactivate" : "Restore";
    const payload = {
      is_active: !isActive,
    };
    const urlActivateRole = `${hardBaseUrl}/role/${item.role_id}/status`;

    try {
      const response = await axios.put(urlActivateRole, payload, { headers });
      if (
        response.data.message === "success" &&
        response.data.meta.http_status === 200
      ) {
        await modalSuccess(`Successfully ${action} Role`);
        history.go(0);
      }
    } catch (error) {
      modalError(error);
    }
  };

  const CustomAction = item => {
    const {
      role_id: roleId,
      type: roleType,
      is_active: isActive,
      selected_applications,
    } = item;
    const handleEdit = () =>
      history.push(
        `/admin/konfigurasi/role-user/edit/${roleType}/${roleId}/${selected_applications}`
      );
    const modalTitle = `${isActive ? "Delete" : "Restore"} Role`;
    const modalConfirmation = `Are you sure to ${
      isActive ? "deactivate" : "restore"
    } role`;
    const buttonCaptions = isActive ? "Delete" : "Restore";

    return (
      <div className={classes?.flexContainer}>
        <IconButton
          classes={{
            root: classes.buttonActions,
          }}
          onClick={handleEdit}
        >
          <img src={PencilVector} alt="edit" />
        </IconButton>
        <ButtonWithModalPerItem
          item={item}
          title={modalTitle}
          dialogTitle={modalTitle}
          className={classes.buttonActions}
          subTitle={modalConfirmation}
          btnIcon={!isActive && <img src={RefreshVector} alt="refresh" />}
          btnDelTitle={buttonCaptions}
          handleDelete={() => handleActivateRole(item, isActive)}
        />
      </div>
    );
  };

  const RenderActiveChip = item => {
    const isActive = item?.is_active;
    return (
      <Chip
        label={isActive ? "Active" : "Inactive"}
        className={classes?.chipStatus}
      />
    );
  };

  const columnTable = [
    {
      name: "all",
      title: "Actions",
      renderText: item => CustomAction(item),
    },
    {
      name: "all",
      title: "Status",
      renderText: item => RenderActiveChip(item),
    },
    {
      name: "name",
      title: "Role Name",
    },
    {
      name: "all",
      title: "Role Menu",
      renderText: item => <RoleChips items={item?.permissions} />,
    },
  ];

  return (
    <Page className={classes.root} title="User Role">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography className={classes?.pageTitle}>
                Configuration
              </Typography>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography>Configuration</Typography>
                <Typography className={classes?.breadCrumbsActive}>
                  User Role
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <ButtonGroupTop
            items={topMenuButton}
            selectedButton={handleOnSelectedButton}
            selectedIndex={3}
          />
          <Divider className={classes.divider} />
          {formSkeleton?.map((item, index) => (
            <Box key={index}>
              <Typography variant="h5" className={classes.title}>
                {item?.title}
              </Typography>
              <Typography className={classes?.subTitle}>
                {item?.subTitle}
              </Typography>
              <InnoTable
                columns={columnTable}
                isLoading={false}
                isUsingCheckbox={false}
                items={item?.table || []}
                loadingColor={""}
              />
              <Box justifyContent="flex-end" display="flex" width="100%">
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  href={`/admin/konfigurasi/role-user/tambah/${item?.roleType}`}
                  className={classes?.buttonAdd}
                >
                  Add {item?.title}
                </Button>
                {index < formSkeleton?.length - 1 && (
                  <Divider className={classes.divider} />
                )}
              </Box>
            </Box>
          ))}
        </Container>
      )}
    </Page>
  );
}

export default RoleUser;
