import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Divider, Tooltip } from "@material-ui/core";
import axios from "axios";
import {
  AutoCompleteComponent,
  ButtonGroupTop,
  PrimaryButton,
  Page,
  SearchTextInput,
  SkeletonComponent,
  ModalError,
  ModalSuccess,
  SecondaryButton,
  ModalWarning,
  SelectInput,
} from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { InnoTable, InnoTableV2 } from "inno-ui";
import { breadcrumbData, topMenuButton } from "../Components/SongClaimMenu";
import { getCookie, getErrors } from "utils";
import { HeaderTitle } from "layouts";
import { Box, Checkbox, Grid, styled } from "@mui/material";
import { getTotalPage } from "lib";
import { debounce } from "lodash";

function DoubleClaim() {
  const classes = useStyles();
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const token = localStorage.getItem("token");
  const userRole = localStorage?.getItem("role");
  const roleSuperAdmin = userRole === "admin";
  const roleAssociation = userRole === "association";
  const songClaimPermission = userLogin?.permissions?.find(
    item => item.group_text === "Song Claim"
  );
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [unclaimPermission, setUnclaimPermission] = useState(false);
  const [optionPublisher, setOptionPublisher] = useState([]);
  const [optionComposer, setOptionComposer] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
    publisher_id: "",
    composer_id: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [selectedSong, setSelectedSong] = useState([]);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleSelectSong = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedSong(prev =>
      checked
        ? [...prev, option]
        : prev.filter(item => item.song_id !== option.song_id)
    );
  };
  const handleVerifySong = (value, option) => {
    setDataTable(prev =>
      prev.map(song =>
        song?.song_id === option?.song_id
          ? { ...song, publisher_id: value }
          : song
      )
    );
    setSelectedSong(prev =>
      prev.map(song =>
        song?.song_id === option?.song_id
          ? { ...song, publisher_id: value }
          : song
      )
    );
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/double-claim/index`, {
        headers,
        params: queryParams,
      });

      const { data, meta } = res?.data;
      setDataTable(data);
      const pageCount = getTotalPage(meta?.total, queryParams?.size || 1);
      setTableTotalPage(pageCount);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const getOptionPublisher = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/double-claim/publisher`, {
        headers,
      });
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        id: item?.publisher_id,
        label: item?.name,
      }));

      setOptionPublisher(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const getOptionComposer = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/publisher/composer`, {
        headers,
        params: {
          page: 1,
          ...(roleSuperAdmin || roleAssociation
            ? { size: 50, sort: 0 }
            : { "double-claim": true }),
        },
      });
      const modifiedData = res?.data?.data?.map(item => ({
        ...item,
        id: item?.composer_id,
        label: item?.sure_name,
      }));

      setOptionComposer(modifiedData);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleDownloadDocument = async () => {
    try {
      setLoadingButton(true);
      const { data } = await axios.post(
        `${hardBaseUrl}/double-claim/export`,
        {
          publisher_id: queryParams?.publisher_id || 0,
          composer_id: queryParams?.composer_id || 0,
        },
        { headers }
      );
      ModalSuccess(data?.message, "Data is being processed").then(
        res => res.isConfirmed && window.location.reload()
      );
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingButton(false);
    }
  };
  const handleSubmit = async () => {
    const payload = selectedSong?.map(item => ({
      song_id: item?.song_id,
      publisher_id: item?.publisher_id,
    }));
    try {
      setLoadingPage(true);
      const { data } = await axios.post(
        `${hardBaseUrl}/double-claim/verify`,
        payload,
        { headers }
      );
      ModalSuccess(data?.message, "Data is being processed").then(
        res => res.isConfirmed && window.location.reload()
      );
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getOptionComposer();
    getOptionPublisher();
  }, []);
  useEffect(() => {
    debounceDataTable();
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);
  useEffect(() => {
    setUnclaimPermission(
      songClaimPermission?.child?.some(
        permission => permission.name === "Unclaim"
      ) || false
    );
  }, [userLogin]);

  return (
    <Page className={classes.root} title="Song Claim">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Song Claim" breadcrumbData={breadcrumbData} />
          <Divider className={classes.divider} />
          <ButtonGroupTop
            items={topMenuButton(unclaimPermission, roleSuperAdmin)}
          />
          <Box mb="20px">
            <Grid container justifyContent="space-between">
              <Grid item>
                <SearchTextInput
                  placeholder="Search"
                  value={queryParams?.search}
                  onChange={e =>
                    handleChangeQueryParams(e?.target?.value, "search")
                  }
                />
              </Grid>
              <Grid item>
                <Grid container columnSpacing={1} alignItems="center">
                  <Grid item>
                    <AutoCompleteComponent
                      options={optionPublisher}
                      size="small"
                      label="Publisher"
                      value={
                        optionPublisher.find(
                          option => option.id === queryParams?.publisher_id
                        ) || null
                      }
                      onChange={value =>
                        handleChangeQueryParams(value, "publisher_id")
                      }
                      width={300}
                    />
                  </Grid>
                  <Grid item>
                    <AutoCompleteComponent
                      options={optionComposer}
                      size="small"
                      label="Composer"
                      value={
                        optionComposer.find(
                          option => option.id === queryParams?.composer_id
                        ) || null
                      }
                      onChange={value =>
                        handleChangeQueryParams(value, "composer_id")
                      }
                      width={300}
                    />
                  </Grid>
                  <Tooltip title="Download Document">
                    <Grid item>
                      <PrimaryButton
                        label="Download"
                        disabled={loadingButton}
                        loading={loadingButton}
                        onClick={handleDownloadDocument}
                        size="large"
                      />
                    </Grid>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <InnoTableV2
            isLoading={false}
            columns={columnTable({
              handleVerifySong,
              selectedSong,
              roleAssociation,
              roleSuperAdmin,
            })}
            items={dataTable || []}
            page={queryParams?.page}
            rowsPerPage={queryParams?.size}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "size")
            }
            isHaveAction={roleAssociation}
            renderAction={item => {
              return (
                <CustomCheckbox
                  checked={selectedSong?.some(
                    selected => selected?.song_id === item?.song_id
                  )}
                  onChange={e => handleSelectSong(e, item)}
                />
              );
            }}
          />
          {selectedSong?.length > 0 && (
            <Grid container justifyContent="right" columnSpacing={1} mt="16px">
              <Grid item>
                <SecondaryButton
                  label="Cancel"
                  onClick={() => window.location.reload()}
                />
              </Grid>
              <Grid item>
                <PrimaryButton
                  label="Confirm"
                  onClick={() =>
                    ModalWarning(
                      "Are you sure to confirm Double Claim this song ?",
                      "Confirm Double Claim"
                    ).then(res => res?.isConfirmed && handleSubmit())
                  }
                />
              </Grid>
            </Grid>
          )}
        </Container>
      )}
    </Page>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
}));
const CustomCheckbox = styled(Checkbox)(({ customColor }) => ({
  "&.MuiCheckbox-root": {
    color: customColor || "#111827",
    "&.Mui-checked": {
      color: customColor || "#111827",
    },
  },
}));
const columnTable = ({
  selectedSong,
  handleVerifySong,
  roleAssociation,
  roleSuperAdmin,
}) => [
  {
    name: "publisher_name",
    title: "Publisher Name",
    renderText: item => item || "-",
  },
  ...(roleAssociation || roleSuperAdmin
    ? []
    : [
        {
          name: "publishers",
          title: "Other Publisher",
          renderText: item =>
            item?.map(publisher => publisher?.name)?.join("; ") || "-",
        },
      ]),
  {
    name: "song_title",
    title: "Song Title",
    renderText: item => (item ? item?.replace(/, /g, " ; ") : "-"),
  },
  {
    name: "composer_name",
    title: "Composer/Author",
    renderText: item => (item ? item?.replace(/, /g, " ; ") : "-"),
  },
  {
    name: "iswc_code",
    title: "ISWC",
    renderText: item => (item ? item?.replace(/, /g, " ; ") : "-"),
  },
  {
    name: "isrc_code",
    title: "ISRC",
    renderText: item => (item ? item?.replace(/, /g, " ; ") : "-"),
  },
  {
    name: "workcodes",
    title: "Workcode",
    renderText: item =>
      item ? (item?.length > 1 ? item?.join(" ; ") : item) : "-",
  },
  {
    name: "ownership_percentage",
    title: "Ownership Percentage",
    renderText: item =>
      item && item?.length > 1
        ? item.map(el => el + "%").join(" ; ")
        : item + "%",
  },
  ...(roleAssociation
    ? [
        {
          name: "all",
          title: "Verified",
          renderText: item => {
            const selected = selectedSong?.some(
              selected => selected?.song_id === item?.song_id
            );
            return selected ? (
              <SelectInput
                disabled={
                  !selectedSong?.some(
                    selected => selected?.song_id === item?.song_id
                  )
                }
                label={item?.publisher_id ? null : "Verified"}
                value={item?.publisher_id}
                onChange={e => handleVerifySong(e.target.value, item)}
                options={item?.publishers}
                optionKey="publisher_id"
                optionLabel="name"
                width={180}
              />
            ) : (
              <Box width={180} />
            );
          },
        },
      ]
    : []),
];
export default DoubleClaim;
