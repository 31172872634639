import {
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import { VisibilityOutlined } from "@material-ui/icons"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useState } from "react";
import { useHistory } from "react-router";
// import EyeVector from "../../../assets/img/";
import TableEditBar from "../../../components/molecules/Table/TableEditBar";
import TableLoading from "../../../components/molecules/Table/TableLoading";

const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
  },
  nameCell: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  textField: {
    width: "150px",
  },
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
  },
  ib: {
    backgroundColor: "#111827",
    color: "white",
    width: "28px",
    height: "28px",
    padding: "6px",
    borderRadius: "6px",
    "&:hover, &.MuiIconButton": { background: "#374151 !important" }
  },
}));

function Results({
  newLoading,
  className,
  handleChangePageSet,
  pageSizePagination,
  items,
  page,
  onChangePage,
  totalData,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();
  const selectedCustomers = [];

  const [openImage, setOpenImage] = useState(false);
  const imageToOpen = null;

  const handleEditSelect = item => {
    history.push({
      pathname: `/pencipta/detail-kontrak/${item.contract_id}`,
    });
  };

  const handleCloseImage = () => {
    setOpenImage(false);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <TableLoading
        open={newLoading}
        backdropStyle={{ color: "#fff", zIndex: 100, position: "absolute" }}
      />
      <TableContainer component={Paper} className={classes.inner}>
        <Table
          style={{
            border: "0px solid black",
          }}
        >
          <TableHead
            style={{
              border: "0px solid black",
            }}
          >
            <TableRow
              style={{
                border: "0px solid black",
              }}
            >
              <TableCell>Action</TableCell>
              <TableCell>Contract number</TableCell>
              <TableCell>Song title</TableCell>
              <TableCell>Publisher</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.length > 0 ? (
              items.map(customer => (
                <TableRow
                  hover
                  key={customer.contract_id}
                  selected={
                    selectedCustomers.indexOf(customer.contract_id) !== -1
                  }
                >
                  <TableCell>
                    <IconButton
                      classes={{
                        root: classes.ib,
                      }}
                      onClick={() => {
                        handleEditSelect(customer);
                      }}
                    >
                      <VisibilityOutlined />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        {customer.contract_number}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      width: "20%",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        {customer.song_title}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        {customer.publisher_name}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>{customer.start_date}</TableCell>
                  <TableCell>{customer.end_date}</TableCell>
                  <TableCell>
                    {customer.status === "Aktif" ? (
                      <Chip
                        size="small"
                        label={"Active"}
                        avatar={
                          <FiberManualRecordIcon
                            style={{ width: "10px", color: "#54B371" }}
                          />
                        }
                        style={{
                          marginRight: "10px",
                          color: "#34774C",
                          backgroundColor: "#EFFCF4",
                          border: "1px solid #8DE5AB",
                        }}
                      />
                    ) : (
                      <Chip
                        size="small"
                        label={"Not active"}
                        avatar={
                          <FiberManualRecordIcon
                            style={{ width: "10px", color: "#9AA2B1" }}
                          />
                        }
                        style={{
                          marginRight: "10px",
                          color: "#364052",
                          backgroundColor: "#F9FAFB",
                          border: "1px solid #D1D5DC",
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h6">No data available</Typography>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {items.lenght > 0 ? (
        <Grid
          alignContent="space-between"
          container
          justifyContent="space-between"
          style={{
            marginTop: "16px",
          }}
        >
          <Grid item>
            <div style={{ display: "flex" }}>
              <div style={{ marginTop: "7px" }}>
                <ThemeProvider theme={theme}>
                  <Typography className={classes.paginationText}>
                    Items per page:
                  </Typography>
                </ThemeProvider>
              </div>{" "}
              <div style={{ marginLeft: "24px" }}>{pageSizePagination}</div>
            </div>
          </Grid>

          <Grid item>
            <Pagination
              count={totalData}
              shape="rounded"
              className={classes.pagination}
              handlechangepage={onChangePage}
              page={page}
              onChange={handleChangePageSet}
            />
          </Grid>
        </Grid>
      ) : (
        <div></div>
      )}

      <Dialog
        onClose={handleCloseImage}
        aria-labelledby="simple-dialog-title"
        open={openImage}
      >
        <Paper elevation={0} />
        <CancelIcon
          onClick={() => {
            setOpenImage(false);
          }}
          style={{
            color: "black",
            position: "absolute",
            right: 0,
          }}
        />
        <img src={imageToOpen} alt="" />
        <Paper />
      </Dialog>
      <TableEditBar selected={selectedCustomers} />
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array,
};

Results.defaultProps = {
  customers: [],
};

export default Results;
