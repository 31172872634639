import {
  Button,
  Checkbox,
  Chip,
  DialogActions,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  BackupOutlined,
  CheckCircle,
  CheckCircleOutline,
  HighlightOff,
} from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoTableV2 } from "inno-ui";
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import TrashVectorGrey from "../../../../assets/img/trashVectorGrey.svg";
import {
  ModalError,
  SelectionsLayout,
  ErrorChip,
  SuccessChip,
} from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { getTotalPage } from "lib";

const token = localStorage.getItem("token");
const useStyles = makeStyles(() => ({
  modalTitle: {
    fontSize: 16,
    fontWeight: 700,
  },
  buttonUpload: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "50px",
    borderWidth: 2,
    borderRadius: 8,
    borderColor: "#303030",
    borderStyle: "thin",
    backgroundColor: "lightgray",
    color: "black",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
    marginBottom: 5,
  },
  active: {
    borderColor: "#2196f3",
  },
  accept: {
    borderColor: "#00e676",
  },
  reject: {
    borderColor: "#ff1744",
  },
  iconUpload: {
    color: "grey",
    fontSize: "40px",
  },
  buttondDownloadContainer: {
    margin: "14px 0",
  },
  uploadLabel: {
    color: "gray",
  },
  dialogActions: {
    padding: "10px 20px",
  },
  dialogTable: {
    maxHeight: 630,
  },
  tableSelectForm: {
    maxWidth: 150,
  },
  required: {
    color: "red",
  },
  greenColor: {
    color: "green",
  },
  modalUploadResultContainer: {
    textAlign: "center",
  },
  uploadSuccessIcon: {
    fontSize: 100,
    color: "#4caf50",
  },
  uploadSuccessCaption: {
    margin: 15,
    fontSize: 21,
    fontWeight: 400,
  },
}));

const RenderStatusChip = status => {
  const classes = useStyles();
  const isSuccess = status === "success";
  return isSuccess ? (
    <CheckCircleOutline className={classes?.greenColor} />
  ) : (
    <HighlightOff className={classes?.required} />
  );
};
const RenderStatusMessageChip = item => {
  const isSuccess = item?.status === "success";
  const statusMessage = item?.message;
  return isSuccess ? (
    <SuccessChip chipLabel={statusMessage} />
  ) : (
    <ErrorChip chipLabel={statusMessage} />
  );
};
const columnTableSongResults = [
  {
    name: "song_title",
    title: "Song",
  },
  {
    name: "status",
    title: "Status",
    renderText: item => RenderStatusChip(item),
  },
  {
    name: "all",
    title: "Message",
    renderText: item => RenderStatusMessageChip(item),
  },
  {
    name: "line_number",
    title: "Line Number",
  },
];

export const ModalBulkAdd = ({ open, onClose, onSubmit, importClasses }) => {
  const classes = useStyles();
  const dialogRef = useRef();
  const [files, setFiles] = useState([]);

  const handleDrop = item => {
    if (item?.length === 1) {
      setFiles(item);
    }
  };
  const handleDownloadTemplate = async () => {
    const url = `${hardBaseUrl}/publisher/transaction-song/template`;
    try {
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "template.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      onClose;
      ModalError("Error downloading the template");
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: handleDrop,
    accept: [".xls", ".xlsx"],
  });
  const combinedClassName = `${classes.buttonUpload} ${isDragActive ? classes.active : ""
    } ${isDragAccept ? classes.accept : ""} ${isDragReject ? classes.reject : ""
    }`;
  const handleSubmit = () => {
    onSubmit(files);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={true}
      ref={dialogRef}
    >
      <DialogTitle>
        <Typography className={classes?.modalTitle}>Upload Song</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <div {...getRootProps({ className: combinedClassName })}>
          <input {...getInputProps()} />
          <BackupOutlined className={classes?.iconUpload} />
          <Typography component="p" variant="h6">
            {isDragActive ? (
              <>Drop the files here ...</>
            ) : (
              <>
                Upload Invoice File <b>Search File</b>
              </>
            )}
          </Typography>
        </div>
        <Typography className={classes?.uploadLabel}>
          File format must be .xlsx
        </Typography>
        <div className={classes?.buttondDownloadContainer}>
          <Button
            className={importClasses?.buttonBlack}
            onClick={handleDownloadTemplate}
          >
            Download Template
          </Button>
        </div>
        <Typography>Document Attached :</Typography>
        {files &&
          files?.map((item, index) => (
            <Grid
              key={index}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <img
                      alt="Logo"
                      src={
                        require("assets/image-public/images/vector-doc-icon.png")
                          .default
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Typography>{item?.name}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <IconButton onClick={() => setFiles([])}>
                  <img alt="LogoTrash" src={TrashVectorGrey} />
                </IconButton>
              </Grid>
            </Grid>
          ))}
      </DialogContent>
      <Divider />
      <DialogActions className={classes?.dialogActions}>
        <Button className={importClasses?.buttonWhite} onClick={onClose}>
          Cancel
        </Button>
        <Button className={importClasses?.buttonBlack} onClick={handleSubmit}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ModalAddSong = ({
  open,
  onClose,
  onSubmit,
  preload,
  importClasses,
}) => {
  const classes = useStyles();
  const dialogRef = useRef();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const royaltyType = [
    "Sync Royalty",
    "Mechanical Royalty",
    "Performing",
    "Mech-Sync Royalty",
  ];

  const [tableData, setTableData] = useState([]);
  const [selectedSongs, setSelectedSongs] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [tablePageCount, setTablePageCount] = useState(0);
  const [tablePagination, setTablePagination] = useState({
    page: 1,
    size: 10,
    search: "",
  });

  const handleChangeTablePagination = (value, key) => {
    setTablePagination(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangePageSize = event => {
    event.preventDefault();
    handleChangeTablePagination(event?.target?.value, "size");
    handleChangeTablePagination(1, "page");
  };
  const handleSearchTable = event => {
    handleChangeTablePagination(1, "page");
    handleChangeTablePagination(event?.target?.value, "search");
  };
  const handleChangeTypeTableData = (song_id, value) => {
    setTableData(currentState =>
      currentState.map(item =>
        item.song_id === song_id ? { ...item, type: value } : item
      )
    );
    setSelectedSongs(prev =>
      prev.map(song =>
        song.song_id === song_id ? { ...song, type: value } : song
      )
    );
  };
  const handleChangeCheckboxTable = (event, item) => {
    const { checked } = event.target;
    setSelectedSongs(prev => {
      const newSelectedSong = checked
        ? [...prev, item]
        : prev.filter(selected => selected.song_id !== item.song_id);
      return newSelectedSong;
    });
  };

  const RenderTableCheckbox = item => (
    <div className={importClasses?.tableCheckboxContainer}>
      <Checkbox
        className={importClasses?.tableCheckbox}
        checked={selectedSongs?.some(
          selected => selected?.song_id === item?.song_id
        )}
        onChange={e => handleChangeCheckboxTable(e, item)}
      />
    </div>
  );
  const RenderChip = (item, fromComposer) => {
    const classes = useStyles();
    const arrChip =
      item && item.length > 0
        ? fromComposer
          ? item.map(newItem => newItem.alias_name || "-")
          : item
        : [];

    return (
      <div>
        {arrChip?.map((newitem, idx) => (
          <Chip
            key={idx}
            label={newitem}
            className={classes?.tableChip}
            size="small"
          />
        ))}
      </div>
    );
  };
  const RenderSelectType = item => {
    const selectedSong = selectedSongs.find(
      song => song.song_id === item.song_id
    );
    const typeValue = selectedSong ? selectedSong.type : item.type;

    return (
      <SelectionsLayout
        selectionsList={royaltyType}
        selectionValue={typeValue}
        onChange={event =>
          handleChangeTypeTableData(item.song_id, event?.target?.value)
        }
        isArrayOfNumber
        blankPlaceHolder
        textFieldContainerClass={classes?.tableSelectForm}
      />
    );
  };

  const getTableData = async () => {
    const url = `${hardBaseUrl}/publisher/transaction-song`;
    const options = {
      headers,
      params: tablePagination,
    };
    setLoadingTable(true);
    try {
      const res = await axios.get(url, options);
      const resData = res?.data?.data;
      const modifiedTable = resData?.map(item => ({
        ...item,
        type: "Sync Royalty",
      }));

      setTableData(modifiedTable);
      const pageCount = getTotalPage(
        res?.data?.meta?.total,
        tablePagination?.size
      );
      setTablePageCount(pageCount);
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
      new Error(error);
    }
  };

  const columnTable = [
    {
      name: "song_title",
      title: "Song",
    },
    {
      name: "composer_names",
      title: "Composer/ Author",
      renderText: item => RenderChip(item),
    },
    {
      name: "composers",
      title: "Alias Name",
      renderText: item => RenderChip(item, true),
    },
    {
      name: "all",
      title: "Type",
      renderText: item => RenderSelectType(item),
    },
  ];
  useEffect(() => {
    getTableData();
  }, [tablePagination]);

  useEffect(() => {
    setSelectedSongs(preload);
  }, [preload]);
  const handleSubmit = () => {
    onSubmit(selectedSongs);
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={true}
      ref={dialogRef}
    >
      <DialogTitle>
        <TextField
          size="small"
          type="text"
          variant="outlined"
          onChange={handleSearchTable}
          placeholder="Search"
          InputProps={{
            classes: {
              input: classes?.searchPlaceholder,
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes?.searchIcon} />
              </InputAdornment>
            ),
          }}
        />
      </DialogTitle>
      <DialogContent className={classes?.dialogTable}>
        <InnoTableV2
          isLoading={loadingTable}
          columns={columnTable}
          items={tableData || []}
          page={tablePagination?.page}
          rowsPerPage={tablePagination?.size}
          totalPage={tablePageCount}
          handleChangePage={(_, data) =>
            handleChangeTablePagination(data, "page")
          }
          handleChangeRowsPerPage={event => handleChangePageSize(event)}
          isHaveAction
          renderAction={item => RenderTableCheckbox(item)}
        />
      </DialogContent>
      <DialogActions className={classes?.dialogActions}>
        <Button className={importClasses?.buttonWhite} onClick={onClose}>
          Cancel
        </Button>
        <Button className={importClasses?.buttonBlack} onClick={handleSubmit}>
          Single Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ModalSongResults = ({ open, onClose, importClasses, preload }) => {
  const classes = useStyles();
  const dialogRef = useRef();
  const [tableData, setTableData] = useState([]);

  const processPreload = () => {
    if (preload) {
      const invalidSongs = preload?.invalid_songs
        ? preload.invalid_songs?.map(item => ({
          ...item,
          status: "invalid",
        }))
        : [];
      const validSongs = preload?.valid_songs
        ? preload.valid_songs?.map(item => ({
          ...item,
          status: "success",
          message: "Success",
          line_number: "-",
        }))
        : [];
      const modifiedTable = [...invalidSongs, ...validSongs];
      setTableData(modifiedTable);
    }
  };

  useEffect(() => {
    processPreload();
  }, [preload]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={true}
      ref={dialogRef}
    >
      <DialogTitle>
        <Typography className={classes?.modalTitle}>Results</Typography>
      </DialogTitle>
      <DialogContent className={classes?.dialogTable}>
        <InnoTableV2
          isLoading={false}
          columns={columnTableSongResults}
          items={tableData || []}
        />
      </DialogContent>
      <DialogActions className={classes?.dialogActions}>
        <Button className={importClasses?.buttonBlack} onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ModalUploadResults = ({
  open,
  onClose,
  importClasses,
  preload,
}) => {
  const classes = useStyles();
  const dialogRef = useRef();
  const [tableData, setTableData] = useState([]);

  const processPreload = () => {
    if (preload) {
      const invalidSongs = preload?.invalid_songs
        ? preload?.invalid_songs.map(item => ({
          ...item,
          status: "invalid",
        }))
        : [];
      setTableData(invalidSongs);
    }
  };

  useEffect(() => {
    processPreload();
  }, [preload]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={true}
      ref={dialogRef}
    >
      <DialogContent className={classes?.dialogTable}>
        <Grid container justifyContent="center">
          <Grid item className={classes?.modalUploadResultContainer}>
            <CheckCircle className={classes?.uploadSuccessIcon} />
            <Typography variant="h2">Success</Typography>
            <Typography className={classes?.uploadSuccessCaption}>
              Document has been uploaded
            </Typography>
          </Grid>
        </Grid>
        {tableData?.length > 0 && (
          <div className={importClasses?.tableContainer}>
            <InnoTableV2
              isLoading={false}
              columns={columnTableSongResults}
              items={tableData || []}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions className={classes?.dialogActions}>
        <Button className={importClasses?.buttonBlack} onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
