import {
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import PencilVector from "../../../../assets/img/pencilVector.svg";
import RefreshVector from "../../../../assets/img/refresh-cw.svg";
import { ButtonWithModalPerItem } from "../../../../components/atoms/Button";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import HeaderPage from "../../../../layouts/Header/HeaderPage";
import { Page, SkeletonComponent } from "components";
import TableLoading from "../../../../components/molecules/Table/TableLoading";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { getCookie } from "../../../../utils/constants";
import DialogImportPencipta from "./DialogImportPencipta";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#303030",
  borderStyle: "thin",
  backgroundColor: "lightgray",
  color: "black",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const theme = createTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
    ].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  inner: {
    minWidth: 700,
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
    marginTop: "20px",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  tableArea: {
    marginTop: "20px",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  inputFields: {
    "& .MuiOutlinedInput-root": {
      width: "312px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0px",
      height: "44px",
    },
  },
  ib: {
    padding: "4px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      boxShadow: "none",
    },
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  ibRefresh: {
    padding: "4px",
    color: "white",
    marginLeft: "8px",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      boxShadow: "none",
    },
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  inputAddOn: {},
}));

function Pencipta() {
  const classes = useStyles();
  const history = useHistory();
  const masterMenu = useSelector(state => state.masterMenu);
  const [response, setResponse] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [openImportModal, setOpenImportModal] = useState(false);
  const [afterCheckTopMenu, setAfterCheckTopMenu] = useState(false);
  const [newLoading, setNewLoading] = useState(false);
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);

  const [csvFile, setCsvFile] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    setCsvFile(acceptedFiles);
  }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  const files = acceptedFiles.map(file => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const handleCloseImportModal = () => setOpenImportModal(false);
  const handleChangeSearch = event => {
    setPage(1);
    setSearchKey(event.target.value);
  };

  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const getResponse = () => {
    setNewLoading(true);
    const url = `${hardBaseUrl}/publisher/composer?page=${page}&size=${rowsPerPage}&search=${searchKey}`;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(url, config)
      .then(res => {
        setResponse(res.data.data);
        setTotalData(res.data.meta.total);
        setPage(res.data.meta.page);
        setNewLoading(false);
      })
      .catch(() => setNewLoading(false));
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };

  useEffect(() => {
    getResponse();
  }, [rowsPerPage, page, searchKey]);

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleDelete = id => {
    let theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/composer/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${theToken}`,
      },
    };
    axios.delete(url, config).then(res => {
      if (res.data.message === "success") {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data deleted successfully",
        }).then(result => {
          if (result.isConfirmed === true) {
            getResponse();
          }
        });
      }
    });
  };

  const handleRecovery = id => {
    let theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/composer-recovery/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${theToken}`,
      },
    };
    const formData = new FormData();
    axios.put(url, formData, config).then(res => {
      if (res.data.message === "success") {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data recovery successfully",
        }).then(result => {
          if (result.isConfirmed === true) {
            getResponse();
          }
        });
      }
    });
  };

  const handleImport = e => {
    e.preventDefault();
    const url = `${hardBaseUrl}/publisher/composer`;
    let token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const formData = new FormData();
    formData.append("composer_file", csvFile[0]);
    axios.post(url, formData, config).then(res => {
      if (res.data.message === "success") {
        setOpenImportModal(false);
        Swal.fire("Success!", "Your file has been imported.", "success");
        getResponse();
      } else {
        Swal.fire("Error!", "Your file is not imported.", "error");
      }
    });
  };

  const handleDownload = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/composer/download`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    };

    axios.get(url, config).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "composer.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  useEffect(() => {
    if (masterMenu.masterMenu !== null) {
      setAfterCheckTopMenu(true);
    }
  }, [masterMenu]);

  return (
    <Page className={classes.root} title="Composer">
      {newLoading ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderPage
            title="Parameter"
            breadcrumbs={["Parameter", "Composer"]}
          />
          {afterCheckTopMenu ? <ButtonGroupTop /> : <>{""}</>}
          <div style={{ marginTop: "20px" }}>
            <hr style={{ border: "1px solid #E4E7EB" }} />
            <div style={{ display: "flex", marginTop: "20px" }}>
              <FormControl className={classes.margin} variant="outlined">
                <TextField
                  id="input-with-icon-textfield"
                  className={classes.inputFields}
                  variant="outlined"
                  onChange={e => handleChangeSearch(e)}
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          alt="Logo"
                          src={
                            require("assets/image-public/images/search.svg")
                              .default
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <div style={{ width: "100%", textAlign: "right" }}>
                <DialogImportPencipta
                  open={openImportModal}
                  onClose={handleCloseImportModal}
                  getInputProps={getInputProps}
                  getRootProps={getRootProps({ style })}
                  isDragActive={isDragActive}
                  files={files}
                  onSubmit={handleImport}
                />
                <Button
                  onClick={handleDownload}
                  variant="contained"
                  style={{
                    height: "44px",
                    width: "107px",
                    backgroundColor: "#111827",
                    textTransform: "none",
                    borderRadius: "6px",
                  }}
                >
                  <ThemeProvider theme={fontInter}>
                    <Typography
                      style={{
                        color: "white",
                      }}
                    >
                      Export
                    </Typography>
                  </ThemeProvider>
                </Button>
              </div>
            </div>
            {newLoading ? (
              <>
                <TableContainer className={classes.inner}>
                  <Table>
                    <TableLoading
                      open={newLoading}
                      backdropStyle={{
                        color: "#fff",
                        zIndex: 100,
                        position: "relative",
                      }}
                    />
                  </Table>
                </TableContainer>
              </>
            ) : (
              <>
                <TableContainer className={classes.inner}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Action</TableCell>
                        <TableCell>Composer ID</TableCell>
                        <TableCell>ISWC Code</TableCell>
                        <TableCell>Composer Name</TableCell>
                        <TableCell>Alias Name</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Email</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {response?.length > 0 ? (
                        response.map((customer, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <IconButton
                                classes={{
                                  root: classes.ib,
                                }}
                                onClick={() => {
                                  history.push({
                                    pathname:
                                      "/admin/parameter/pencipta-admin/" +
                                      customer.composer_id,
                                    state: { customer: customer },
                                  });
                                }}
                              >
                                <img alt="pencil_edit" src={PencilVector} />
                              </IconButton>

                              {customer.is_active_flag === true ? (
                                <ButtonWithModalPerItem
                                  userLogin={userLogin}
                                  item={customer}
                                  className={classes.ib}
                                  title={"Delete Composer"}
                                  dialogTitle={"Delete Composer"}
                                  subTitle={
                                    "Are you sure you want to delete this data"
                                  }
                                  handleDelete={() => {
                                    handleDelete(customer.composer_id);
                                  }}
                                />
                              ) : (
                                <IconButton
                                  classes={{
                                    root: classes.ibRefresh,
                                  }}
                                  onClick={() => {
                                    handleRecovery(customer.composer_id);
                                  }}
                                >
                                  <img alt="pencil_edit" src={RefreshVector} />
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell>{customer.composer_id}</TableCell>
                            <TableCell>{customer.iswc_code}</TableCell>
                            <TableCell>{customer.sure_name}</TableCell>
                            <TableCell>{customer.alias_name}</TableCell>
                            <TableCell>{customer.phone_number}</TableCell>
                            <TableCell>{customer.email}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <div
                              style={{
                                textAlign: "center",
                              }}
                            >
                              Data not found
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            <Grid
              alignItems="flex-end"
              container
              justifyContent="space-between"
              style={{
                marginTop: "16px",
              }}
            >
              <Grid item>
                <ThemeProvider theme={theme}>
                  <div className={classes.paginationText}>
                    <div style={{ display: "flex" }}>
                      <p style={{ marginTop: "7px" }}>Items Per Page:</p>{" "}
                      <div style={{ marginLeft: "10px" }}>
                        {pageSizePagination()}
                      </div>
                    </div>
                  </div>
                </ThemeProvider>
              </Grid>

              <Grid item>
                <Pagination
                  count={pagesCount}
                  shape="rounded"
                  className={classes.pagination}
                  onChange={handleChangePage}
                  page={page}
                />
              </Grid>
            </Grid>
          </div>
        </Container>
      )}
    </Page>
  );
}

export default Pencipta;
