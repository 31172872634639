import { InputAdornment, styled, TextField } from "@mui/material";

const TextInput = ({
  placeholder,
  onChange,
  value,
  disabled,
  multiline,
  startAdornment,
  endAdornment,
  inputMode,
  rows,
  label,
  required,
  width,
  ...rest
}) => {
  return (
    <CustomTextField
      customWidth={width}
      size="small"
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      InputProps={{
        autoComplete: "off",
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
        endAdornment: endAdornment && (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ),
        inputMode,
      }}
      InputLabelProps={{
        shrink: value ? true : undefined,
      }}
      label={label}
      required={required}
      {...rest}
      fullWidth
    />
  );
};
const CustomTextField = styled(TextField)(({ customWidth }) => ({
  width: customWidth,
}));

export default TextInput;
