import {
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  Box,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router-dom";
import HeaderPage from "../../../../layouts/Header/HeaderPage";
import { Page } from "components";
import TableLoading from "../../../../components/molecules/Table/TableLoading";
import { hardBaseUrl } from "../../../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  inner: {
    minWidth: 700,
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
    boxShadow: "none",
    marginTop: "16px"
  },
  select: {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    marginTop: "4px",
  },
  button: {
    marginBottom: "20px",
    backgroundColor: "black",
    color: "white",
    width: "auto",
    height: "35px",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  colorBox: {
    width: "30px",
    height: "30px",
  },
  tableArea: {
    marginTop: "20px",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  inputFields: {
    "& .MuiOutlinedInput-input": {
      padding: "0px",
      height: "44px",
      width: "200px",
    },
  },
  ib: {
    padding: "4px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      boxShadow: "none",
    },
  },
  ibRefresh: {
    padding: "4px",
    color: "white",
    marginLeft: "8px",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",

      boxShadow: "none",
    },
  },
  inputFilter: {
    width: "211px",
    height: "44px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
  },
}));

function DetailReport(props) {
  const classes = useStyles();
  const { state } = useLocation();
  const [response, setResponse] = useState([]);
  const [getYear, setGetYear] = useState(state.year);
  const [yearList, setYearList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [nowMonth, setNowMonth] = useState(state.month);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const month = [
    {
      id: 0,
      name: "None",
    },
    {
      id: 1,
      name: "January",
    },
    {
      id: 2,
      name: "February",
    },
    {
      id: 3,
      name: "March",
    },
    {
      id: 4,
      name: "April",
    },
    {
      id: 5,
      name: "May",
    },
    {
      id: 6,
      name: "June",
    },
    {
      id: 7,
      name: "July",
    },
    {
      id: 8,
      name: "August",
    },
    {
      id: 9,
      name: "September",
    },
    {
      id: 10,
      name: "October",
    },
    {
      id: 11,
      name: "November",
    },
    {
      id: 12,
      name: "Desember",
    },
  ];

  const pageSizePagination = () => {
    return (
      <Box className={classes.pageSize}>
        <select
          className={classes.select}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </Box>
    );
  };

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleChangePage = (_, data) => {
    setPage(data);
  };

  const getResponse = () => {
    setIsLoading(true);

    let theId = props.match.params.id;
    let theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/report/${theId}?page=${page}&size=${rowsPerPage}&&search=${searchKey}&month=${nowMonth}&year=${getYear}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        let newArr = [];
        if (res.data.meta) {
          setPageCount(Math.ceil(res.data.meta.total / res.data.meta.limit));
        }
        if (res.data.data.data !== null) {
          (res.data.data.data || []).forEach(element => {
            let newObj = {
              song_id: element.song_id,
              song_title: element.song_title,
              usage_on_dsp: element.usage_on_dsp,
              length_of_usage: element.usage_on_dsp.length + 1,
            };
            element.usage_on_dsp.forEach((dsp, i) => {
              newObj.usage_on_dsp[i] = {
                ...newObj.usage_on_dsp[i],
                length_of_summaries: dsp.summaries.length + 1,
              };
              newObj.length_of_usage =
                newObj.length_of_usage + dsp.summaries.length;
            });
            newArr.push(newObj);
          });

          setResponse(newArr || []);
          setIsLoading(false);
        } else {
          setResponse([]);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleChangeSearch = event => {
    setPage(1);
    setSearchKey(event.target.value);
  };

  useEffect(() => {
    const thisYear = moment().year();
    for (let i = thisYear; i >= 2000; i--) {
      setYearList(yearList => [...yearList, i]);
    }
  }, []);

  useEffect(() => {
    getResponse();
  }, [rowsPerPage, page, searchKey, nowMonth, getYear]);


  const handleChangeYear = event => {
    setGetYear(event.target.value);
  };
  const handleChangeMonth = event => {
    setNowMonth(event.target.value);
    setPage(1);
  };
  return (
    <Page className={classes.root} title="Detail Report">
      <Container maxWidth={false} id={"container-detail-report"}>
        <HeaderPage
          title="Detail Report"
          breadcrumbs={["Home", "Report", "Detail Report"]}
        />

        <Divider className={classes.divider} />
        <Grid
          container
          justifyContent="space-between"
        >
          <Grid item>
            <FormControl>
              <TextField
                id="fliterText"
                name="filterText"
                className={classes.inputFields}
                type="text"
                variant="outlined"
                placeholder="Search"
                onChange={event => handleChangeSearch(event)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        alt="Logo"
                        src={
                          require("assets/image-public/images/search.svg").default
                        }
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <FormControl
                  size="small"
                  variant="outlined"
                  fullWidth={true}
                >
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    className={classes.inputFilter}
                    onChange={e => handleChangeMonth(e)}
                    value={nowMonth}
                    autoWidth={true}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box>Month :</Box>
                      </InputAdornment>
                    }
                  >
                    {month.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl
                  size="small"
                  variant="outlined"
                  fullWidth={true}
                >
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    className={classes.inputFilter}
                    onChange={e => handleChangeYear(e)}
                    value={getYear}
                    autoWidth={true}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box>Year :</Box>
                      </InputAdornment>
                    }
                  >
                    <MenuItem
                      value=""
                    >
                      <em>None</em>
                    </MenuItem>
                    {yearList.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <TableLoading
          open={isLoading}
          backdropStyle={{
            color: "#fff",
            zIndex: 100,
            position: "absolute",
            height:
              document?.getElementById("container-detail-report")
                ?.offsetHeight ?? "100vh",
          }}
        />
        <TableContainer
          className={classes.inner}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Song</TableCell>
                <TableCell>DSP</TableCell>
                <TableCell>Traffic </TableCell>
                <TableCell>Income</TableCell>
                <TableCell>Market Share (Traffic)</TableCell>
                <TableCell>Market Share (Revenue)</TableCell>
                <TableCell>Product Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response?.length > 0 ? (
                response.map((item, key) => {
                  return (
                    <Fragment key={item?.song_id || key}>
                      <TableRow>
                        <TableCell rowSpan={item.length_of_usage}>
                          {item.song_title}
                        </TableCell>
                      </TableRow>
                      {(item?.usage_on_dsp || [])?.map((dsp, index) => {
                        return (
                          <Fragment key={index}>
                            <TableRow key={`${dsp.dsp_id}`}>
                              <TableCell
                                align="left"
                                rowSpan={dsp.length_of_summaries}
                              >
                                {dsp.dsp_name}
                              </TableCell>
                            </TableRow>
                            {dsp?.summaries?.length ? (
                              dsp?.summaries?.map((summary, index) => (
                                <TableRow key={index}>
                                  <TableCell align="left">
                                    <NumberFormat
                                      displayType="text"
                                      value={summary.traffic}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                    />
                                  </TableCell>
                                  <TableCell align="left">
                                    <NumberFormat
                                      displayType="text"
                                      value={summary.revenue}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      prefix="Rp "
                                    />
                                  </TableCell>
                                  <TableCell align="left">
                                    {summary?.traffic_percentage}
                                  </TableCell>
                                  <TableCell align="left">
                                    {summary?.revenue_percentage}
                                  </TableCell>
                                  <TableCell align="left">
                                    {summary?.product_type}
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell align="left">
                                  <NumberFormat
                                    displayType="text"
                                    value={dsp.traffic}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  <NumberFormat
                                    displayType="text"
                                    value={dsp.revenue}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    prefix="Rp "
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  {dsp.traffic_percentage}
                                </TableCell>
                                <TableCell align="left">
                                  {dsp.revenue_percentage}
                                </TableCell>
                                <TableCell align="left">{"-"}</TableCell>
                              </TableRow>
                            )}
                          </Fragment>
                        );
                      })}
                    </Fragment>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No Data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box marginTop="16px">
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Box display="flex">
                <Box marginTop="7px">
                  <ThemeProvider theme={theme}>
                    <Typography className={classes.paginationText}>
                      Items Per Page
                    </Typography>
                  </ThemeProvider>
                </Box>{" "}
                <Box marginLeft="24px">{pageSizePagination()}</Box>
              </Box>
            </Grid>

            <Grid item>
              <Pagination
                count={pageCount}
                shape="rounded"
                className={classes.pagination}
                onChange={handleChangePage}
                page={page}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page >
  );
}

export default DetailReport;
