import { Container, Divider } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { makeStyles } from "@material-ui/styles";
import { Button, Grid, styled } from "@mui/material";
import axios from "axios";
import {
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SkeletonComponent,
} from "components";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { formatDate, getErrors, textCapitalization } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";

function CostAllocation() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: Number(paramsPage) || 1,
    per_page: Number(paramsSize) || 10,
    search: paramsSearch || "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handleChangeSearch = event => {
    const value = event?.target?.value;
    handleChangeQueryParams(value, "search");
    handleChangePageParams(value, "search");
  };
  const handleChangePage = page => {
    handleChangeQueryParams(page, "page");
    handleChangePageParams(page, "page");
  };
  const handleChangePageSize = size => {
    handleChangeQueryParams(size, "per_page");
    handleChangePageParams(size, "size");
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/publisher/cost-allocation`, {
        headers,
        params: queryParams,
      });

      const { data, total_page } = res?.data?.data;
      setDataTable(data);
      setTableTotalPage(total_page);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleDelete = async id => {
    setLoadingPage(true);
    axios
      .delete(`${hardBaseUrl}/publisher/cost-allocation/${id}`, { headers })
      .then(() =>
        ModalSuccess("Cost Allocation has been deleted").then(result => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })
      )
      .catch(error => {
        ModalError(getErrors(error?.response));
      })
      .finally(() => setLoadingPage(false));
  };

  useEffect(() => {
    debounceDataTable();
    if (queryParams?.page !== paramsPage) {
      handleChangePageParams(queryParams?.page, "page");
    }
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Cost Allocation">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Cost Allocation"
            breadcrumbData={breadcrumbData}
          />
          <Divider className={classes.divider} />
          <Grid container justifyContent="space-between">
            <SearchTextInput
              placeholder="Search"
              value={queryParams?.search}
              onChange={handleChangeSearch}
            />
            <PrimaryButton
              label="Create Tresshold"
              startIcon={<AddIcon />}
              onClick={() => history.push("/admin/create-tresshold")}
              size="large"
            />
          </Grid>
          <TextButton
            variant="text"
            endIcon={<ArrowForwardIcon />}
            onClick={() => history.push("/admin/default-tresshold")}
          >
            Set Default Tresshold
          </TextButton>
          <InnoTable
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.per_page}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) => handleChangePage(page)}
            handleChangeRowsPerPage={e =>
              handleChangePageSize(e?.target?.value)
            }
            isHaveAction={true}
            handleView={item =>
              history.push({
                pathname: `/admin/detail-cost-allocation/${item?.cost_allocation_id}`,
                state: { customer: item },
              })
            }
            handleDelete={handleDelete}
            isUsingDeleteConfirmation={true}
            deleteName={"composer_name"}
          />
        </Container>
      )}
    </Page>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
}));
const TextButton = styled(Button)(() => ({
  margin: "16px 0",
  textTransform: "none",
  color: "black",
  "& .MuiSvgIcon-root": {
    color: "#9545EB",
  },
}));
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Transaction",
    link: "/admin/cost-allocation",
  },
  {
    label: "Cost Allocation",
    active: true,
  },
];
const columnTable = [
  {
    name: "composer_name",
    title: "Composer/Author Name",
  },
  {
    name: "date",
    title: "Date",
    renderText: date => (date ? formatDate(moment(date, "D MMMM YYYY")) : "-"),
  },
  {
    name: "description",
    title: "Description",
  },
  {
    name: "tresshold",
    title: "Tresshold",
  },
  {
    name: "value",
    title: "Value Cost Allocation",
  },
  {
    name: "value_allocated",
    title: "Allocated",
  },
  {
    name: "status",
    title: "Status",
    renderText: item => textCapitalization(item),
  },
];
export default CostAllocation;
