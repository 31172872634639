import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  ThemeProvider,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { InnoImage } from "inno-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Editor } from "react-draft-wysiwyg";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import UnggahLogo from "../../../../assets/img/searchFile.svg";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
const theToken = localStorage.getItem("token");
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  btnSubmit: {
    backgroundColor: "black",
    marginRight: "10px",
    color: "white",
    padding: "10px 28px",
    width: "150px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnPreview: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    width: "150px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  error: {
    color: "red",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
}));
const BlackRadio = withStyles({
  root: {
    color: "#303030",
    "&$checked": {
      color: "#000000",
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#303030",
  borderStyle: "solid",
  backgroundColor: "white",
  color: "black",
  outline: "none",
  transition: "border .24s ease-in-out",
};
const activeStyle = {
  borderColor: "#2196f3",
};
const acceptStyle = {
  borderColor: "#00e676",
};
const rejectStyle = {
  borderColor: "#ff1744",
};
const img = {
  display: "block",
  width: "auto",
  height: "100%",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: "6px",
};
const imgFirst = {
  display: "block",
  width: "auto",
  height: "100%",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: "6px",
  padding: "70px",
};
function TambahBerita(props) {
  const classes = useStyles();
  const blocksFromHtml = htmlToDraft(
    localStorage.getItem("content") === null
      ? ""
      : localStorage.getItem("content")
  );
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const editorState = EditorState.createWithContent(contentState);
  const [selectedValue, setSelectedValue] = useState("berita");
  const [textEditor, setTextEditor] = useState(editorState);
  const [judul, setJudul] = useState(localStorage.getItem("title"));
  const [gambar, setGambar] = useState([]);
  const [publisherName, setPublisherName] = useState("");
  const [adminName, setAdminName] = useState("");
  const [error, setError] = useState({
    judul: "",
    publisherName: "",
    gambar: "",
    textEditor: "",
  });
  const [imagesPrev, setImagesPrev] = useState("");
  const [files, setFiles] = useState([]);
  const handleDrop = useCallback(async acceptedFiles => {
    setGambar(acceptedFiles);
    let tesRes = await agetBase64(acceptedFiles[0]);
    let resAfter = tesRes.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
    localStorage.setItem("image_logo_bin_64_nofront", resAfter);
    setImagesPrev(tesRes);
    setFiles(
      acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: "image/*", onDrop: handleDrop });
  const agetBase64 = file => {
    return new Promise(resolve => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  useEffect(() => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  const handleChangeText = e => {
    const name = e.target.name;
    const value = e.target.value;
    if (name) {
      setJudul(value);
    }
  };
  const handleChange = event => {
    setSelectedValue(event.target.value);
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (judul === "") {
      setError({
        ...error,
        judul: "Judul tidak boleh kosong",
      });
    } else if (textEditor.getCurrentContent().hasText() === false) {
      setError({
        ...error,
        textEditor: "Isi tidak boleh kosong",
      });
    } else if (
      gambar?.length === 0 ||
      localStorage.getItem("image_logo") !== null
    ) {
      setError({
        ...error,
        gambar: "Gambar tidak boleh kosong",
      });
    }
    if (
      (judul !== "" &&
        textEditor.getCurrentContent().hasText() &&
        gambar?.length !== 0) ||
      localStorage.getItem("image_logo") !== null
    ) {
      const rawContentState = convertToRaw(textEditor.getCurrentContent());
      const markup = draftToHtml(rawContentState);
      const formData = new FormData();
      formData.append("title", judul);
      formData.append("type", selectedValue);
      formData.append("content", markup);
      if (gambar?.length > 0) {
        formData.append("image_logo", gambar[0]);
      } else if (gambar?.length === 0) {
        //convert base64 string from localstorage.getItem("image_logo") to file object
        const image_logo = localStorage.getItem("image_logo");
        const image_logo_file = new File([image_logo], "image_logo.jpg", {
          type: "image/jpeg",
        });
        formData.append("image_logo", image_logo_file);
      }
      const url = `${hardBaseUrl}/article`;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${theToken}`,
        },
      };
      axios
        .post(url, formData, config)
        .then(() => {
          Swal.fire({
            title: "Berhasil",
            text: "Berita & Iklan berhasil ditambahkan",
            icon: "success",
            confirmButtonText: "Oke",
          });
          localStorage.removeItem("title");
          localStorage.removeItem("content");
          localStorage.removeItem("image_logo");
          localStorage.removeItem("raw_img");
          props.history.push("/admin/berita-publisher");
          setJudul("");
          setTextEditor(EditorState.createEmpty());
          setGambar([]);
          setSelectedValue("berita");
        })
        .catch(err => {
          new Error(err);
        });
    } else {
      if (gambar?.length === 0) {
        Swal.fire({
          title: "Error",
          text: "Banner Harus di isi.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Berita & Iklan gagal ditambahkan",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    }
  };
  const handlePreview = async () => {
    if (judul === "") {
      setError({
        ...error,
        judul: "Judul tidak boleh kosong",
      });
    } else if (textEditor.getCurrentContent().hasText() === false) {
      setError({
        ...error,
        textEditor: "Isi tidak boleh kosong",
      });
    } else if (
      gambar?.length === 0 ||
      localStorage.getItem("image_logo") !== null
    ) {
      Swal.fire({
        title: "Error",
        text: "Banner Tidak Boleh Kosong.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
    if (
      (judul !== "" &&
        textEditor.getCurrentContent().hasText() &&
        gambar?.length !== 0) ||
      localStorage.getItem("image_logo") !== null
    ) {
      const recentDate = moment().toString();
      const date = recentDate.getDate();
      const month = recentDate.toLocaleString("default", { month: "long" });
      const year = recentDate.getFullYear();
      const dateString = `${date} ${month} ${year}`;
      const rawContentState = convertToRaw(textEditor.getCurrentContent());
      const markup = draftToHtml(rawContentState);
      localStorage.setItem("title", judul);
      localStorage.setItem("type", selectedValue);
      localStorage.setItem("content", markup);
      localStorage.setItem(
        "raw content",
        convertToRaw(textEditor.getCurrentContent())
      );
      localStorage.setItem("recentDate", dateString);
      localStorage.setItem("publisherName", publisherName);
      localStorage.setItem("adminName", adminName);
      if (gambar?.length > 0 && localStorage.getItem("image_logo") === null) {
        const convertFileToBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        const database64 = await convertFileToBase64(gambar[0]);
        localStorage.setItem("image_logo", database64);
        localStorage.setItem(
          "raw_img",
          `${gambar[0].name} - ${gambar[0].size} bytes`
        );
      } else if (
        gambar?.length === 0 &&
        localStorage.getItem("image_logo") !== null
      ) {
        const image_logo = localStorage.getItem("image_logo");
        localStorage.setItem("image_logo", image_logo);
      } else if (
        gambar?.length > 0 &&
        localStorage.getItem("image_logo") !== null
      ) {
        const convertFileToBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        const database64 = await convertFileToBase64(gambar[0]);
        localStorage.setItem("image_logo", database64);
        localStorage.setItem(
          "raw_img",
          `${gambar[0].name} - ${gambar[0].size} bytes`
        );
      }
      window.location.href = "/admin/berita-publisher/tambah/preview";
    }
  };
  const getPublisherName = () => {
    const url = `${hardBaseUrl}/me`;
    const config = {
      headers: {
        Authorization: `Bearer ${theToken}`,
      },
    };
    axios
      .get(url, config)
      .then(res => {
        setAdminName(res.data.data.role.name);
        setPublisherName(res.data.data.publisher.name);
      })
      .catch(err => {
        new Error(err);
      });
  };
  useEffect(() => {
    getPublisherName();
    let localJudul = localStorage.getItem("title");
    if (localJudul !== null) {
      setJudul(localJudul);
    }
    let localImg = localStorage.getItem("image_logo");
    if (localImg !== null) {
      setImagesPrev(localImg);
    }
  }, []);
  return (
    <Page className={classes.root} title="Add News">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                {"Add News"}
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>
              <Typography className={classes.breadCrumbs}>{"News"}</Typography>
              <Typography className={classes.breadCrumbsActive}>
                {"Add News"}
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <form onSubmit={e => handleSubmit(e)}>
          <Grid container>
            <div>
              <ThemeProvider theme={theme}>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Cover
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Add cover/banner for news
                </p>
              </ThemeProvider>
            </div>
          </Grid>
          <Grid container>
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <div
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "8px",
                  padding: "16px",
                  marginTop: "16px",
                }}
              >
                <div
                  style={{
                    marginBottom: "6px",
                  }}
                >
                  <span>Banner *</span>
                </div>
                <div
                  {...getRootProps({ style })}
                  style={{
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    position: "relative",
                    height: "200px",
                    backgroundColor: "#F9FAFB",
                    border: "1px solid #E4E7EB",
                    borderRadius: "6px",
                  }}
                >
                  {imagesPrev !== "" ? (
                    <div
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        display: "contents",
                        textAlign: "center",
                        width: "335px",
                        height: "185px",
                        border: "0px solid #E4E7EB",
                      }}
                    >
                      <InnoImage
                        src={imagesPrev}
                        styleImage={{
                          height: "100%",
                          width: "auto",
                        }}
                        styleImageContainer={{
                          boxShadow: "none",
                          padding: 0,
                          ...img,
                          backgroundColor: "transparent",
                        }}
                        fill={true}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        display: "contents",
                        textAlign: "center",
                        width: "335px",
                        height: "185px",
                        border: "0px solid #E4E7EB",
                      }}
                    >
                      <InnoImage
                        src={UnggahLogo}
                        alt="Preview"
                        styleImageContainer={{
                          boxShadow: "none",
                          padding: 0,
                          ...imgFirst,
                          backgroundColor: "transparent",
                        }}
                        styleImage={{
                          height: "100%",
                          width: "auto",
                        }}
                        fill={true}
                      />
                    </div>
                  )}
                  <input {...getInputProps()} />
                </div>
                <aside>
                  <Typography
                    component="span"
                    style={{
                      fontSize: "12px",
                      color: "#687083",
                    }}
                  >
                    Cover format can be images and videos, less than 1MB, 1000 x
                    500
                  </Typography>
                </aside>
                <div
                  style={{
                    marginTop: "6px",
                  }}
                ></div>
              </div>
            </Grid>
            <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
          </Grid>
          <Grid container style={{ marginTop: "20px" }}>
            <div>
              <ThemeProvider theme={theme}>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Content *
                </Typography>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <p
                  style={{
                    marginTop: "5px",
                    color: "#8f8f8f",
                    fontSize: "14px",
                  }}
                >
                  Type content of the news here
                </p>
              </ThemeProvider>
            </div>
          </Grid>
          <Grid container>
            <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
              <Card
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Grid>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Title *
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          onChange={handleChangeText}
                          name="judul"
                          value={judul}
                        />
                      </div>
                      {judul === "" && (
                        <Typography
                          component="h1"
                          variant="h5"
                          className={classes.error}
                        >
                          {error.judul}
                        </Typography>
                      )}
                    </div>
                  </Grid>
                  <Grid container>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Type
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <FormControlLabel
                          value="end"
                          control={
                            <BlackRadio
                              checked={selectedValue === "berita"}
                              onChange={handleChange}
                              value="berita"
                              name="berita"
                              inputProps={{ "aria-label": "A" }}
                            />
                          }
                          label="News"
                        />
                        <FormControlLabel
                          value="end"
                          control={
                            <BlackRadio
                              checked={selectedValue === "edukasi"}
                              onChange={handleChange}
                              value="edukasi"
                              name="edukasi"
                              inputProps={{ "aria-label": "C" }}
                            />
                          }
                          label="Education"
                        />
                      </div>
                    </div>
                  </Grid>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Typography>Contents</Typography>
                      </ThemeProvider>
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Editor
                        editorState={textEditor}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class input_description"
                        editorStyle={{
                          height: "60vH",
                          backgroundColor: "white",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                        toolbarClassName="toolbar-class"
                        wrapperStyle={{
                          border: "1px solid #ccc",
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                        toolbar={{
                          options: [
                            "inline",
                            "blockType",
                            "fontSize",
                            "list",
                            "textAlign",
                            "colorPicker",
                            "link",
                            "remove",
                            "history",
                          ],
                        }}
                        onEditorStateChange={setTextEditor}
                      />
                      {textEditor.getCurrentContent().hasText() === false && (
                        <Typography
                          component="h1"
                          variant="h5"
                          className={classes.error}
                        >
                          {error.textEditor}
                        </Typography>
                      )}
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Divider className={classes.divider} />
              <Button
                className={classes.btnSubmit}
                type="submit"
                style={{
                  backgroundColor: " #111827",
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    Apply
                  </Typography>
                </ThemeProvider>
              </Button>
              <Button
                className={classes.btnPreview}
                onClick={handlePreview}
                style={{
                  backgroundColor: " #111827",
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    Preview
                  </Typography>
                </ThemeProvider>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Page>
  );
}
export default TambahBerita;
