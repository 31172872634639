import { Visibility, VisibilityOff } from "@material-ui/icons";
import { IconButton } from "@mui/material";
import { TextInput } from "components";
import { useState } from "react";

const PasswordInput = ({
  placeholder,
  onChange,
  value,
  disabled,
  label,
  ...rest
}) => {
  const [passwordToggle, setPasswordToggle] = useState(false);

  return (
    <TextInput
      label={label}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      disabled={disabled}
      type={passwordToggle ? "text" : "password"}
      endAdornment={
        <IconButton onClick={() => setPasswordToggle(!passwordToggle)}>
          {passwordToggle ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      }
      {...rest}
    />
  );
};

export default PasswordInput;
