import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
} from "@ant-design/icons";
import { Button, Input } from "antd";
import Axios from "axios";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { hardBaseUrl } from "../../../services/urlConstant";
import "../Login/Login.css";

export default function NewPassword() {
  let history = useHistory();
  const [body, setBody] = useState({
    token: useParams().token,
    old_password: "",
    new_password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () =>
    setShowPassword(showPassword => !showPassword);
  const handleForgot = async e => {
    e.preventDefault();

    try {
      if (body.old_password !== body.new_password) {
        Swal.fire({
          icon: "error",
          title: "Mohon masukan password dan confirm password dengan benar",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        const response = await Axios.post(
          hardBaseUrl + "/auth/reset-password/forgot",
          {
            token: body.token,
            new_password: body.new_password,
          },
          {
            headers: {
              Authorization: "Bearer " + body.token,
            },
          }
        );
        if (response.data.message === "success") {
          Swal.fire({
            icon: "success",
            title: "Berhasil merubah password anda!",
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            history.push("/login");
          }, 2000);
        }
      }
    } catch (error) {
      new Error(error);
    }
  };

  return (
    <div className="login">
      <div className="content">
        <div className="header">
          { }
          <h1 style={{ fontSize: "30px", marginTop: "40px" }}>
            Buat Password Baru
          </h1>
          <h3 style={{ color: "#687083" }}>
            Password baru Anda harus berbeda dengan password Anda sebelumnya.
          </h3>
        </div>
        <form className="input-form" onSubmit={handleForgot}>
          <Input
            type={showPassword ? "text" : "password"}
            prefix={
              showPassword ? (
                <EyeTwoTone
                  style={{ marginRight: "10px" }}
                  onClick={toggleShowPassword}
                />
              ) : (
                <EyeInvisibleOutlined
                  style={{ marginRight: "10px" }}
                  onClick={toggleShowPassword}
                />
              )
            }
            placeholder="password"
            onChange={e => setBody({ ...body, old_password: e.target.value })}
            style={{ height: "50px", borderRadius: "5px", marginTop: "20px" }}
          />
          <Input
            placeholder="confirm password"
            type="password"
            onChange={e => setBody({ ...body, new_password: e.target.value })}
            prefix={<LockOutlined style={{ marginRight: "10px" }} />}
            style={{
              height: "50px",
              borderRadius: "5px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          ></Input>
          <Button
            type="primary"
            style={{
              backgroundColor: "black",
              border: "none",
              width: "100%",
              height: 44,
              borderRadius: 5,
              color: "white",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            htmlType="submit"
          >
            Ubah Password
          </Button>
        </form>
      </div>
    </div>
  );
}
