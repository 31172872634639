import {
  Backdrop,
  Breadcrumbs,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoImage } from "inno-ui";
import _ from "lodash";
import { useMemo, useRef, useState } from "react";
import { ChromePicker } from "react-color";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { Page } from "components";
import DialogUploadDokumen from "../../../../../pages/Admin/MasterAdmin/Dsp/TambahDsp/DialogUploadDokumen";

import { hardBaseUrl } from "../../../../../services/urlConstant";
const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 101,
    color: "#fff",
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  colorPickerArea: {
    marginTop: "6px",
  },
  button: {
    marginBottom: "20px",
    marginTop: "20px",
    backgroundColor: "black",
    color: "white",
    width: "135px",
    height: "40px",
    textTransform: "none",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  buttonSetting: {
    backgroundColor: "black",
    color: "white",
    width: "130px",
    height: "30px",
    textTransform: "none",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  imgPreview: {
    maxWidth: "150px",
    maxHeight: "150px",
  },
  fileUploadArea: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  subTitle: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#6e6e6e",
  },
  label: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "500px",
    fontSize: "14px",
    lineHeight: "20px",
  },
  inputFieldsTlp: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderLeft: "1px solid #D1D5DC",
    paddingLeft: "14px",
  },
  myTextField: {
    height: "44px",
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  inputFieldsUrl: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderRight: "1px solid #D1D5DC",
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",
    marginRight: "8px",
    position: "relative",
    backgroundColor: "green",
    opacity: 1,
  },
  "&.MuiSwitch-colorSecondary.Mui-checked": {
    backgroundColor: "black",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
    "&.MuiSwitch-colorSecondary.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
  },
  checked: {
    border: "none",
    color: "white",
    backgroundColor: "#E4E7EB",
    opacity: 1,
  },
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#9c9c9c",
    opacity: 1,
  },
  colorSecondary: {
    "&.Mui-checked + .MuiSwitch-track": {},
    "&.Mui-checked": {
      color: "white",
    },
  },
  colorPrimary: {
    backgroundColor: "black",
  },
  selectFormControl: {
    "& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
      width: "320px",
      padding: "14px 14px",
    },
  },
  autoComplete: {
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      height: "44px",
    },
    "& .MuiOutlinedInput-root input.MuiInputBase-input.MuiOutlinedInput-input": {
      paddingLeft: "14px",
    },
  },
}));
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#303030",
  borderStyle: "thin",
  backgroundColor: "lightgray",
  color: "black",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};
const activeStyle = {
  borderColor: "#2196f3",
};
const acceptStyle = {
  borderColor: "#00e676",
};
const rejectStyle = {
  borderColor: "#ff1744",
};
const EditDsp = () => {
  const { state } = useLocation();
  const history = useHistory();
  const [color, setColor] = useState(state.row.color);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [file, setFile] = useState(state.row.profile_image);
  const [dsp, setDsp] = useState(state.row.name);
  const [nama, setNama] = useState(state.row.person_name);
  const [url, setUrl] = useState(state.row.link);
  const [telepon, setTelepon] = useState(state.row.person_phone);
  const [email, setEmail] = useState(state.row.person_email);
  const [checkedVal, setCheckedVal] = useState(false);
  const [openModalCal, setOpenModalCal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const inputFile = useRef(null);
  const [dokumenObj, setDokumenObj] = useState([]);
  const [files, setFiles] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [currency, setCurrency] = useState(null);
  const [currencyList, setCurrencyList] = useState([]);
  const [processMethod, setProcessMethod] = useState("integration");
  const processMethodList = [
    {
      id: "integration",
      name: "Integration",
    },
    {
      id: "upload",
      name: "Upload",
    },
  ];
  const classes = useStyles();
  const onRemoveFile = idx => {
    setFiles(files.filter((_, i) => i !== idx));
    acceptedFiles.splice(idx, 1);
  };

  const handleDrop = async items => {
    if (files !== null) {
      if (files?.length > 0) {
        const data = [];
        items.forEach(item => {
          data.push(item);
        });
        let newFiles = files;
        const children = newFiles.concat(data);
        setFiles(children);
      } else {
        const data = [];
        items.forEach(item => {
          data.push(item);
        });
        setFiles(data);
      }
    } else {
      const data = [];
      items.forEach(item => {
        data.push(item);
      });
      setFiles(data);
    }
  };
  const handleImportDokumen = e => {
    e.preventDefault();
    setOpenBackdrop(true);
    const formData = new FormData();
    const url = `${hardBaseUrl}/upload-dsrf/example/create`;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    formData.append("dsp_id", state.row.dsp_id);
    files.map(val => {
      return formData.append("files", val);
    });
    axios
      .post(url, formData, config)
      .then(() => {
        setOpenDialog(false);
        setOpenBackdrop(false);
        Swal.fire({
          title: "Success",
          text: "Document has been uploaded",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(result => {
          if (result.value) {
            window.location.reload();
          }
        });
      })
      .catch(() => {
        setOpenBackdrop(false);
        setOpenDialog(false);
        Swal.fire({
          title: "Error",
          text: "Document failed to upload",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: handleDrop,
    accept: [".xlsx", "xls"],
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  const colorPreview = makeStyles(() => ({
    changeColor: {
      backgroundColor: color,
      padding: "10px",
      width: "48px",
      height: "24px",
    },
  }));
  const colorPrev = colorPreview();
  const onChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "dsp":
        setDsp(value);
        break;
      case "nama":
        setNama(value);
        break;
      case "color":
        setColor(value);
        break;
      case "url":
        setUrl(value);
        break;
      case "telepon":
        setTelepon(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "address":
        setAddress(value);
        break;
      default:
        break;
    }
  };
  const onChangeFile = e => {
    const name = e.target.name;
    const data = e.target.files[0];
    if (name === "photo") {
      setFile(data);
    }
  };
  const notifError = (text = () => {}) =>
    Swal.fire({
      title: "Oops…",
      icon: "error",
      text: text,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    });
  const onSubmit = e => {
    e.preventDefault();
    if (dsp === "") {
      return notifError("DSP name can't be empty");
    }
    if (!file) {
      return notifError("DSP logo can't be empty");
    }
    if (color === "") {
      return notifError("Identity color can't be empty");
    }
    if (url === "") {
      return notifError("URL endpoint can't be empty");
    }
    if (nama === "") {
      return notifError("The name of the person in charge can’t be empty");
    }
    if (email === "") {
      return notifError("Email can’t be empty");
    }
    if (telepon === "") {
      return notifError("Phone number can’t be empty");
    }
    if (country === null) {
      return notifError("Country number can’t be empty");
    }
    if (currency === null) {
      return notifError("Currency number can’t be empty");
    }
    if (address === "") {
      return notifError("Address can’t be empty");
    }
    if (
      dsp !== "" &&
      color !== "" &&
      url !== "" &&
      nama !== "" &&
      telepon !== "" &&
      email !== "" &&
      address !== "" &&
      currency !== "" &&
      country !== "" &&
      file
    ) {
      const urlAddress = `${hardBaseUrl}/dsp/${state.row.dsp_id}`;
      const headers = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.token}`,
        },
      };
      const formData = new FormData();
      formData.append("name", dsp);
      formData.append("person_name", nama);
      formData.append("color", color);
      formData.append("link", url);
      formData.append("person_phone", telepon);
      formData.append("person_email", email);
      formData.append("image_logo", file);
      formData.append("is_pay_service", checkedVal);
      formData.append("address", address);
      formData.append("process_method", processMethod);
      formData.append("country_id", country?.id);
      formData.append("dsp_currency_id", currency?.id);
      axios
        .put(urlAddress, formData, headers)
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "Data changed successfully",
            icon: "success",
          }).then(result => {
            if (result.isConfirmed) {
              history.push("/admin/parameter/dsp-admin");
            }
          });
        })
        .catch(err => {
          Swal.fire({
            title: "Error.",
            icon: "error",
            text: `${err.response.data.errors[0].message}`,
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        });
    }
  };
  const handleChangePhotoButton = e => {
    e.preventDefault();
    inputFile.current.click();
  };
  const getDetailDoc = () => {
    const urlAddress = `${hardBaseUrl}/dsp/detail?dsp_id=${state.row.dsp_id}`;
    const token = localStorage.getItem("token");
    axios
      .get(urlAddress, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        if (typeof res.data.data.url_detail !== "undefined") {
          setFiles(res.data.data.url_detail);
        }
        let newArr = [];
        let anana = res.data.data.url_detail;
        if (typeof anana !== "undefined") {
          anana.forEach(async item => {
            let name = /[^(/|\\)]*$/.exec(item.url)[0];
            const myArray = name.split("?");
            const myNewArray = myArray[0].split(".");
            let newObj = {
              name: myNewArray[0],
              type: myNewArray[1],
              id: item.id,
              url: item.url,
            };
            newArr.push(newObj);
          });
        }
        setDokumenObj(newArr);
      })
      .catch(() => {});
  };
  const getDetail = () => {
    const urlAddress = `${hardBaseUrl}/dsp/${state.row.dsp_id}`;
    const urlCurr = `${hardBaseUrl}/currency/datatable?page=1&per_page=99&search=&sort=`;
    const urlCountries = `${hardBaseUrl}/country/table?page=1&per_page=99&search=&sort=`;
    const token = localStorage.getItem("token");
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios.get(urlCountries, headers).then(resCountries => {
      setCountryList(resCountries.data.data);
      axios.get(urlCurr, headers).then(resCurr => {
        setCurrencyList(resCurr.data.data);
        axios.get(urlAddress, headers).then(res => {
          let theResultCountry = _.find(resCountries.data.data, function(r) {
            return r.id === res.data.data.country_id;
          });
          let theResultCurrency = _.find(resCurr.data.data, function(o) {
            return o.id === res.data.data.dsp_currency_id;
          });
          if (theResultCountry !== "undefined") {
            setCurrency(theResultCurrency);
          }
          if (theResultCurrency !== "undefined") {
            setCountry(theResultCountry);
          }
          setAddress(res.data.data.address);
          setProcessMethod(res.data.data.process_method);
        });
      });
    });
  };
  useState(() => {
    getDetailDoc();
    getDetail();
    setCheckedVal(state.row.is_pay_service);
  }, []);
  const handleChecked = () => {
    setCheckedVal(prev => !prev);
  };
  const onClickApplyDate = () => {};
  const handleCloseImportDialog = () => setOpenDialog(false);
  const clickDel = item => {
    setOpenBackdrop(true);
    const urlAddress = `${hardBaseUrl}/upload-dsrf/delete/${item}`;
    const theToken = localStorage.getItem("token");
    axios
      .delete(urlAddress, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        setOpenBackdrop(false);
        if (res.data.message === "success") {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Data deleted successfully",
          }).then(result => {
            if (result.isConfirmed === true) {
              window.location.reload();
            }
          });
        }
      })
      .catch(() => {});
  };
  const handleChangeProcessMethod = e => {
    setProcessMethod(e.target.value);
  };
  return (
    <div className={classes.root}>
      <Page title="Edit DSP">
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    lineHeight: "32px",
                  }}
                >
                  Edit DSP
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <a href="/admin/parameter/publisher">Master</a>
                <a href="/admin/parameter/dsp-admin">DSP</a>
                <Typography>Edit DSP</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <form onSubmit={onSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h5" className={classes.title}>
                    DSP Information
                  </Typography>
                </ThemeProvider>
                <div
                  style={{
                    border: "1px solid #9AA2B1",
                    borderRadius: "8px",
                    padding: "16px",
                    marginTop: "16px",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <label className={classes.label} htmlFor={"dsp"}>
                      DSP Name
                    </label>
                  </ThemeProvider>
                  <TextField
                    style={{
                      marginTop: "6px",
                    }}
                    className={classes.myTextField}
                    fullWidth
                    onChange={onChange}
                    id={"dsp"}
                    name="dsp"
                    value={dsp}
                    type="text"
                    variant="outlined"
                    InputProps={{
                      classes: { input: classes.inputFields },
                    }}
                  />
                  <div
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <label
                        className={classes.label}
                        htmlFor={"photo"}
                        style={{
                          marginTop: "16px",
                        }}
                      >
                        DSP Logo
                      </label>
                    </ThemeProvider>
                  </div>
                  <Grid
                    style={{
                      marginTop: "6px",
                    }}
                  >
                    <Grid item xs={8}>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        {file === null ? (
                          <div
                            style={{
                              width: "160px",
                              height: "160px",
                              border: "1px solid #D1D5DC",
                              borderRadius: "6px",
                            }}
                          >
                            <InnoImage
                              src={file}
                              alt="Preview"
                              className={classes.imgPreview}
                              styleImageContainer={{
                                position: "relative",
                                top: "45px",
                                left: "35px",
                                boxShadow: "none",
                                padding: 0,
                              }}
                              styleImage={{
                                maxWidth: "150px",
                                maxHeight: "150px",
                              }}
                            />
                          </div>
                        ) : (
                          <InnoImage
                            src={file}
                            alt="Preview"
                            className={classes.imgPreview}
                            styleImageContainer={{
                              border: "1px solid #D1D5DC",
                              borderRadius: "6px",
                              boxShadow: "none",
                              padding: 0,
                            }}
                            styleImage={{
                              maxWidth: "150px",
                              maxHeight: "150px",
                            }}
                          />
                        )}
                        <input
                          onChange={onChangeFile}
                          ref={inputFile}
                          style={{ display: "none" }}
                          type="file"
                          name="photo"
                        />
                        <Button
                          onClick={handleChangePhotoButton}
                          variant="outlined"
                          style={{
                            textTransform: "none",
                            backgroundColor: "black",
                            color: "white",
                            width: "129px",
                            height: "40px",
                            marginLeft: "16px",
                          }}
                        >
                          <ThemeProvider theme={fontInter}>
                            <Typography
                              style={{
                                color: "white",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "20px",
                              }}
                            >
                              Change Logo
                            </Typography>
                          </ThemeProvider>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <label className={classes.label} htmlFor="theme">
                        Identity Color
                      </label>
                    </ThemeProvider>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      borderRadius: "6px",
                      marginTop: "6px",
                    }}
                  >
                    <Grid>
                      <Grid item xs={8}>
                        <div className={classes.colorPickerArea}>
                          <TextField
                            fullWidth={true}
                            name="color"
                            onChange={onChange}
                            value={color}
                            variant="outlined"
                            disabled
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <div className={colorPrev.changeColor}></div>
                                  <Divider
                                    className={classes.colorDivider}
                                    orientation="vertical"
                                  />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ExpandMore />
                                </InputAdornment>
                              ),
                            }}
                            onClick={() =>
                              setShowColorPicker(
                                showColorPicker => !showColorPicker
                              )
                            }
                          />
                          {showColorPicker && (
                            <ChromePicker
                              color={color}
                              onChange={updateColor =>
                                setColor(updateColor.hex)
                              }
                            />
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <label className={classes.label} htmlFor="url">
                        URL Endpoint
                      </label>
                    </ThemeProvider>
                  </div>
                  <TextField
                    style={{
                      marginTop: "6px",
                    }}
                    fullWidth
                    onChange={onChange}
                    id="url"
                    name="url"
                    value={url}
                    type="text"
                    variant="outlined"
                    className={classes.myTextField}
                    InputProps={{
                      classes: { input: classes.inputFieldsUrl },
                    }}
                  />
                  <div
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <label className={classes.label} htmlFor="url">
                        Process Method Flag
                      </label>
                    </ThemeProvider>
                  </div>
                  <div>
                    <FormControl
                      variant="outlined"
                      className={classes.selectFormControl}
                    >
                      <Select
                        labelId="label-select-process-method"
                        id="select-process-method"
                        value={processMethod}
                        onChange={handleChangeProcessMethod}
                      >
                        {processMethodList.map((item, index) => {
                          return (
                            <MenuItem
                              key={`${item.id}-${index}`}
                              value={`${item.id}`}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <label className={classes.label} htmlFor="url">
                        Country
                      </label>
                    </ThemeProvider>
                  </div>
                  <div>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                    >
                      <Autocomplete
                        onChange={(event, newValue) => {
                          setCountry(newValue);
                        }}
                        value={country}
                        options={countryList}
                        getOptionLabel={option => option?.country_name}
                        className={classes.autoComplete}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Select Country"
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                  <div
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <label className={classes.label} htmlFor="url">
                        Currency
                      </label>
                    </ThemeProvider>
                  </div>
                  <div>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                    >
                      <Autocomplete
                        onChange={(event, newValue) => {
                          setCurrency(newValue);
                        }}
                        value={currency}
                        options={currencyList}
                        getOptionLabel={option => option?.currency_iso_code}
                        className={classes.autoComplete}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Select Currency"
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h5" className={classes.title}>
                    Person In Charge
                  </Typography>
                </ThemeProvider>
                <div
                  style={{
                    border: "1px solid #9AA2B1",
                    borderRadius: "8px",
                    padding: "16px",
                    marginTop: "16px",
                  }}
                >
                  <Grid item xs={12}>
                    <ThemeProvider theme={theme}>
                      <label className={classes.label} htmlFor="nama">
                        The name of the person in charge
                      </label>
                    </ThemeProvider>
                    <TextField
                      style={{
                        marginTop: "6px",
                      }}
                      fullWidth
                      onChange={onChange}
                      id="nama"
                      name="nama"
                      value={nama}
                      type="text"
                      variant="outlined"
                      className={classes.myTextField}
                      InputProps={{
                        classes: { input: classes.inputFields },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        marginTop: "16px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <label className={classes.label} htmlFor="email">
                          Email
                        </label>
                      </ThemeProvider>
                    </div>
                    <TextField
                      style={{
                        marginTop: "6px",
                      }}
                      fullWidth
                      onChange={onChange}
                      id="email"
                      name="email"
                      value={email}
                      type="email"
                      variant="outlined"
                      className={classes.myTextField}
                      InputProps={{
                        classes: { input: classes.inputFields },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        marginTop: "16px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <label className={classes.label} htmlFor="telepon">
                          Phone Number
                        </label>
                      </ThemeProvider>
                    </div>
                    <TextField
                      style={{
                        marginTop: "6px",
                      }}
                      fullWidth
                      className={classes.myTextField}
                      onChange={onChange}
                      id="telepon"
                      name="telepon"
                      value={telepon}
                      type="tel"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        marginTop: "16px",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <label className={classes.label} htmlFor="telepon">
                          Address
                        </label>
                      </ThemeProvider>
                    </div>
                    <TextField
                      style={{
                        marginTop: "6px",
                      }}
                      fullWidth
                      onChange={onChange}
                      value={address}
                      id="address"
                      name="address"
                      margin="normal"
                      type="text"
                      variant="outlined"
                      rows={4}
                      multiline
                    />
                  </Grid>
                  <div
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <FormGroup
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            disableRipple
                            classes={{
                              root: classes.rootSwitch,
                              switchBase: classes.switchBase,
                              thumb: classes.thumb,
                              track: classes.track,
                              checked: classes.checked,
                              colorSecondary: classes.colorSecondary,
                              colorPrimary: classes.colorPrimary,
                            }}
                            checked={checkedVal}
                            onChange={handleChecked}
                          />
                        }
                        label="DSP pay for service"
                      />
                    </FormGroup>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.button}
                >
                  <ThemeProvider theme={fontInter}>
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "14px",
                      }}
                    >
                      Save
                    </Typography>
                  </ThemeProvider>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h5" className={classes.title}>
                    DSP Template
                  </Typography>
                </ThemeProvider>
                <div
                  style={{
                    border: "1px solid #9AA2B1",
                    borderRadius: "8px",
                    padding: "16px",
                    marginTop: "16px",
                  }}
                >
                  {dokumenObj?.map((res, index) => {
                    return (
                      <Grid
                        key={`${index}`}
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "12px",
                            border: "1px solid grey",
                            padding: "8px",
                            borderRadius: "8px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                alt="Logo"
                                src={
                                  require("assets/image-public/images/vector-doc-icon.png")
                                    .default
                                }
                              />
                            </div>
                            <div
                              style={{
                                marginLeft: "15px",
                              }}
                            >
                              <div>
                                <span
                                  style={{
                                    fontFamily: "Inter",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    color: "#111827",
                                  }}
                                >
                                  {res.name}.{res.type}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <div
                              onClick={() => clickDel(res.id)}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                marginLeft: "14px",
                                cursor: "pointer",
                              }}
                            >
                              <CloseIcon />
                            </div>
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                  <Button
                    onClick={() => setOpenDialog(true)}
                    style={{
                      marginTop: "16px",
                      backgroundColor: "#111827",
                      borderRadius: "6px",
                      height: "40px",
                      color: "#FFFFFF",
                      textTransform: "none",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <Typography
                        component="span"
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          fontWeight: "500",
                          fontStyle: "normal",
                          textTransform: "none",
                        }}
                      >
                        Upload Template
                      </Typography>
                    </ThemeProvider>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
          <DialogUploadDokumen
            open={openDialog}
            onClose={handleCloseImportDialog}
            getInputProps={getInputProps}
            getRootProps={getRootProps({ style })}
            isDragActive={isDragActive}
            files={files}
            onSubmit={handleImportDokumen}
            handleRemoveFile={onRemoveFile}
          />
          <Dialog
            open={openModalCal}
            onClose={() => {
              setOpenModalCal(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <div>
                <span
                  style={{
                    color: "black",
                    fontSize: "18px",
                  }}
                >
                  <b>View Transaction DSP Publisher</b>
                </span>
              </div>
              <div
                style={{
                  marginTop: "12px",
                }}
              >
                <span
                  style={{
                    color: "#687083",
                  }}
                >
                  Feature to set view DSP Publisher
                </span>
              </div>
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: "block",
                  width: "415px",
                }}
              ></div>
              <div
                style={{
                  border: "1px solid #9AA2B1",
                  borderRadius: "8px",
                  padding: "16px",
                  marginTop: "16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>Spotify</div>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            disableRipple
                            classes={{
                              root: classes.rootSwitch,
                              switchBase: classes.switchBase,
                              thumb: classes.thumb,
                              track: classes.track,
                              checked: classes.checked,
                              colorSecondary: classes.colorSecondary,
                              colorPrimary: classes.colorPrimary,
                            }}
                            checked={checkedVal}
                            onChange={handleChecked}
                          />
                        }
                        label=""
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "right",
                paddingRight: "24px",
              }}
            >
              <div>
                <Button
                  onClick={() => {
                    setOpenModalCal(false);
                  }}
                  style={{
                    height: "40px",
                    width: "90px",
                    borderRadius: "6px",
                    border: "1px solid #D1D5DC",
                    color: "#111827",
                    backgroundColor: "white",
                    textTransform: "none",
                    marginRight: "8px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    onClickApplyDate();
                  }}
                  style={{
                    height: "40px",
                    width: "90px",
                    borderRadius: "6px",
                    border: "0px solid #D1D5DC",
                    color: "white",
                    backgroundColor: "#111827",
                    textTransform: "none",
                  }}
                >
                  Save
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </Container>
      </Page>
    </div>
  );
};
export default EditDsp;
