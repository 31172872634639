import {
  Backdrop,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import axios from "axios";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router-dom";
import { Page } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { useStyles } from "./style";
import { InnoTable } from "inno-ui";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const TrendComposer = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const [tableDataComposer, setTableDataComposer] = useState([]);
  const [compOptions, setCompOptions] = useState([]);
  const [getYearComposer, setGetYearComposer] = useState(moment().year());
  const [openMenuPubComp, setOpenMenuPubComp] = useState(false);
  const [chipSubPubArrayComp, setChipSubPubArrayComp] = useState([]);
  const [selectedSubPubComp, setSelectedSubPubComp] = useState([]);
  const [arrSubPubIdComp, setArrSubPubIdComp] = useState([]);
  const [
    optionOriginalPublisherComp,
    setOptionOriginalPublisherComp,
  ] = useState([]);
  const [compOptionValue, setCompOptionValue] = useState("none");
  const [optionOriginalPublisher, setOptionOriginalPublisher] = useState([]);
  const [chipSubPubArray, setChipSubPubArray] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(1);
  const [openMenuComp, setOpenMenuComp] = useState(false);
  const [composerSearch, setComposerSearch] = useState("");
  const [compOptionValuename, setCompOptionValuename] = useState("none");
  const [composerLoading, setComposerLoading] = useState(false);
  const [newLoading, setNewLoading] = useState(false);

  const handleCloseMenuComp = () => {
    setOpenMenuComp(false);
  };

  const clickMenuComp = event => {
    setOpenMenuComp(event.currentTarget);
    setComposerSearch("");
  };

  const filterComposerList = event => {
    setComposerSearch(event.target.value);
  };
  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const getCompOptionValue = (e, id, name) => {
    e.preventDefault();
    setCompOptionValue(id);
    setCompOptionValuename(name);
    handleCloseMenuComp();
  };

  const pagesCount = Math.ceil(totalData / rowsPerPage);

  const getComposer = async () => {
    setComposerLoading(true);
    try {
      const url = `${hardBaseUrl}/publisher/composer?page=1&size=50&search=${composerSearch}&sort=0`;
      const res = await axios.get(url, { headers });
      setCompOptions(res.data.data);
      setComposerLoading(false);
    } catch (e) {
      new Error(e);
    }
  };
  useEffect(() => {
    setNewLoading(true);
    const composerTrenData = setTimeout(() => {
      const dspString = state?.dspId?.toString() || "";
      let arrToStringSubPubComp = arrSubPubIdComp.toString();
      const url = `${hardBaseUrl}/publisher/trend-of-using-composer-songs?&page=${page}&size=${rowsPerPage}&dsp_id=${dspString}&original_publisher_id=${arrToStringSubPubComp}&composer_id=${compOptionValue}&year=${getYearComposer}`;
      axios
        .get(url, { headers })
        .then(res => {
          setTableDataComposer(res.data.data || []);
          setTotalData(res.data.meta.total);
          setPage(res.data.meta.page);
          setRowsPerPage(res.data.meta.limit);
        })
        .catch(() => {})
        .finally(() => {
          setNewLoading(false);
        });
    }, 3000);
    return () => clearTimeout(composerTrenData);
  }, [
    getYearComposer,
    arrSubPubIdComp,
    compOptionValue,
    page,
    rowsPerPage,
    state?.dspId,
  ]);

  useEffect(() => {
    getComposer();
  }, [composerSearch]);

  const clickMenuPublisherComp = event => {
    setOpenMenuPubComp(event.currentTarget);
  };
  const handleCloseMenuPublisherComp = () => {
    setOpenMenuPubComp(false);
  };

  const handleChangeYearComposer = event => {
    setGetYearComposer(event.target.value);
    setPage(1);
  };
  const getFillSubPub = item => {
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            marginLeft: "10px",
            color: "black",
            display: "flex",
            justifyContent: "center",
            fontSize: "14px",
          }}
        >
          {item?.length > 0 && (
            <>
              {item[0].name.substring(0, 6)}
              {" ..."}
            </>
          )}
        </div>
      </div>
    );
  };

  const getOptionDashboard = () => {
    let newData = [];
    const url = `${hardBaseUrl}/publisher/dashboard/option`;

    axios
      .get(url, { headers })
      .then(res => {
        const newDataSubPub = res.data.data.original_publisher.map(item => ({
          ...item,

          checked: true,
        }));
        if (res.data.data.dsp) {
          newData = res.data.data.dsp.map((item, i) => {
            if (i < 3) {
              return {
                ...item,
                checked: true,
              };
            } else {
              return {
                ...item,
                checked: false,
              };
            }
          });
        }

        setOptionOriginalPublisher(newDataSubPub);
        setOptionOriginalPublisherComp(newDataSubPub);
        setChipSubPubArray(newDataSubPub);
        setChipSubPubArrayComp(newDataSubPub);

        let arrSubPubId = [];
        res.data.data.original_publisher.forEach(item => {
          arrSubPubId.push(item.original_publisher_id);
        });
        setArrSubPubIdComp(arrSubPubId);

        let arrDspId = [];
        newData.forEach(item => {
          if (item.checked === true) {
            arrDspId.push(item.dsp_id);
          }
        });
      })
      .catch(() => {});
  };

  const makeCheckPubComp = async (checked, id) => {
    let listCheckPub = optionOriginalPublisherComp;
    let newArr = [];
    let newItem = null;

    listCheckPub.forEach(item => {
      if (item.original_publisher_id === Number(id)) {
        newItem = {
          ...item,
          checked: checked,
        };
      } else {
        newItem = {
          ...item,
        };
      }
      newArr.push(newItem);
    });
    setOptionOriginalPublisherComp(newArr);
    setOptionOriginalPublisherComp(newArr);
  };

  const handleChangeCheckBoxPublisherComp = async (event, item, index) => {
    const { id, name, checked } = event.target;
    if (checked === false) {
      makeCheckPubComp(checked, id, name, item, index);
      setChipSubPubArrayComp(
        chipSubPubArrayComp.filter(chip => chip.name !== name)
      );
      setSelectedSubPubComp(
        chipSubPubArrayComp.filter(chip => chip.name !== name)
      );
      const newDataSubPub = arrSubPubIdComp.filter(
        item => Number(item) !== Number(id)
      );
      setArrSubPubIdComp(newDataSubPub);
    } else {
      makeCheckPubComp(checked, id, name, item, index);

      let currentSubPub = arrSubPubIdComp;
      let newSubPubArr = [...currentSubPub, id];
      setArrSubPubIdComp(newSubPubArr);

      setChipSubPubArrayComp([...chipSubPubArrayComp, item]);
    }
  };

  const handleChipDeletePublisherComp = chipToDelete => () => {
    setChipSubPubArrayComp(
      chipSubPubArrayComp.filter(chip => chip.name !== chipToDelete.name)
    );
    const newData = optionOriginalPublisher.map(item => {
      if (item.name === chipToDelete.name) {
        item.checked = false;
      }
      return item;
    });
    setOptionOriginalPublisherComp(newData);
    setSelectedSubPubComp(
      selectedSubPubComp.filter(chip => chip.name !== chipToDelete.name)
    );
    const newDataSubPub = arrSubPubIdComp.filter(
      item => Number(item) !== chipToDelete.original_publisher_id
    );
    setArrSubPubIdComp(newDataSubPub);
  };

  useEffect(() => {
    const thisYear = moment().year();
    for (let i = thisYear; i >= 2000; i--) {
      setYearList(yearList => [...yearList, i]);
    }
  }, []);

  useEffect(() => {
    getOptionDashboard();
  }, []);
  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };

  const handleView = item => {
    e.preventDefault();
    localStorage.setItem("compId", item.composer_id);
    window.location.href = "/admin/review-lagu";
  };

  return (
    <Page className={classes.root} title="Composer Trend">
      <Backdrop className={classes.backdrop} open={newLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <div
                style={{
                  display: "flex",
                }}
              >
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                  }}
                >
                  Dashboard
                </Typography>
              </div>
            </ThemeProvider>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <div>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
            style={{
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Grid item xs={12} md={12} sm={12} lg={4} xl={4}>
              <ThemeProvider theme={theme}>
                <Typography
                  component="span"
                  style={{
                    fontSize: "18px",
                    lineHeight: "28px",
                    fontWeight: "700",
                    fontStyle: "normal",
                  }}
                >
                  Trend of Using Composer Songs
                </Typography>
              </ThemeProvider>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              lg={8}
              xl={8}
              className={classes.filterContainer}
            >
              <Grid>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                >
                  <OutlinedInput
                    variant="outlined"
                    id="outlined-adornment-amount"
                    size="small"
                    onClick={clickMenuComp}
                    className={classes.inputFilter}
                    startAdornment={
                      <InputAdornment position="start">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <p> Composer:</p>
                          <p
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            {truncate(compOptionValuename, 7)}
                          </p>
                        </div>
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <ArrowDropDownIcon
                          onClick={clickMenuComp}
                          style={{ cursor: "pointer" }}
                        />
                      </InputAdornment>
                    }
                    fullWidth={false}
                  />

                  <Menu
                    id="simple-menu"
                    anchorEl={openMenuComp}
                    keepMounted
                    style={{ marginTop: "100px", cursor: "pointer" }}
                    open={Boolean(openMenuComp)}
                    onClose={handleCloseMenuComp}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Container
                        style={{
                          width: "300px",
                          height: "70px",
                        }}
                      >
                        <TextField
                          id="fliterText"
                          name="filterText"
                          size="small"
                          style={{
                            width: "255px",
                            border: "1px solid #D1D5DC",
                            borderRadius: "6px",
                            marginBottom: "20px",
                          }}
                          onChange={e => filterComposerList(e)}
                          value={composerSearch}
                          margin="normal"
                          type="text"
                          variant="outlined"
                          placeholder="Author/Composer"
                          InputProps={{
                            classes: {
                              input: classes.placeholder,
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon style={{ color: "#9AA2B1" }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Container>

                      <MenuItem
                        onClick={e => getCompOptionValue(e, "none", "none")}
                      >
                        <em>None</em>
                      </MenuItem>

                      {compOptions.map((item, index) => (
                        <MenuItem
                          key={index}
                          onClick={e =>
                            getCompOptionValue(
                              e,
                              item.composer_id,
                              item.sure_name
                            )
                          }
                        >
                          {item.sure_name}
                        </MenuItem>
                      ))}
                    </div>
                  </Menu>
                </FormControl>
              </Grid>

              <Grid>
                <>
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    <OutlinedInput
                      variant="outlined"
                      id="outlined-adornment-amount"
                      size="small"
                      onClick={clickMenuPublisherComp}
                      className={classes.inputFilter}
                      startAdornment={
                        <InputAdornment position="start">
                          Publisher:
                          <div>{getFillSubPub(chipSubPubArray)}</div>
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <ArrowDropDownIcon
                            onClick={clickMenuPublisherComp}
                            style={{ cursor: "pointer" }}
                          />
                        </InputAdornment>
                      }
                      fullWidth={false}
                    />

                    <Menu
                      id="simple-menu"
                      anchorEl={openMenuPubComp}
                      keepMounted
                      style={{ marginTop: "100px", cursor: "pointer" }}
                      open={Boolean(openMenuPubComp)}
                      onClose={handleCloseMenuPublisherComp}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Container style={{ width: "300px", height: "100px" }}>
                          <Card
                            style={{
                              border: "1px solid #E4E7EB",
                              boxShadow: "none",
                              height: "auto",
                              minHeight: "120px",
                              overflow: "auto",
                              display: "flex",
                              margin: "auto",
                            }}
                            className={classes.scrollingCard}
                          >
                            <div>
                              <div
                                style={{
                                  width: "auto",
                                  height: "123px",
                                }}
                              >
                                {chipSubPubArrayComp.map((item, index) => (
                                  <Chip
                                    key={index}
                                    label={item.name}
                                    onDelete={handleChipDeletePublisherComp(
                                      item
                                    )}
                                    style={{
                                      margin: "5px",
                                    }}
                                  />
                                ))}
                              </div>
                            </div>
                          </Card>
                        </Container>

                        <Container
                          style={{
                            width: "300px",
                            marginTop: "40px",
                            borderTop: "1px solid #E4E7EB",
                          }}
                        >
                          <div>
                            <FormControl>
                              {optionOriginalPublisherComp.map(
                                (item, index) => {
                                  return (
                                    <FormControlLabel
                                      key={index}
                                      control={
                                        <Checkbox
                                          className={classes.myCheckBox}
                                          id={`${item.original_publisher_id}`}
                                          key={index}
                                          checked={item.checked}
                                          name={item.name}
                                          value={item.original_publisher_id}
                                          onChange={e =>
                                            handleChangeCheckBoxPublisherComp(
                                              e,
                                              item,
                                              index
                                            )
                                          }
                                        />
                                      }
                                      label={item.name}
                                    />
                                  );
                                }
                              )}
                            </FormControl>
                          </div>
                        </Container>
                      </div>
                    </Menu>
                  </FormControl>
                </>
              </Grid>
              <Grid item xs={12} md={12} sm={12} lg={2} xl={2}>
                <>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth={true}
                  >
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      className={classes.inputFilter}
                      onChange={e => handleChangeYearComposer(e)}
                      value={getYearComposer}
                      autoWidth={true}
                      startAdornment={
                        <InputAdornment position="start">
                          <div>Year:</div>
                        </InputAdornment>
                      }
                    >
                      <MenuItem
                        value=""
                        style={{
                          width: "300px",
                        }}
                      >
                        <em>None</em>
                      </MenuItem>
                      {yearList.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                      {}
                    </Select>
                  </FormControl>
                </>
              </Grid>
            </Grid>
          </Grid>
          <CSVLink
            data={tableDataComposer}
            filename="Trend of Using Compser Songs"
          >
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor: "#000",
                color: "white",

                boxShadow: "none",
                marginBottom: "20px",

                width: "136px",
                height: "36px",
                borderRadius: "6px",
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    color: "white",
                    whiteSpace: "nowrap",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {}
                  Download Report
                </Typography>
              </ThemeProvider>
            </Button>
          </CSVLink>

          <InnoTable
            columns={[
              {
                name: "composer_name",
                title: " Composer/Author",
              },
              {
                name: "listener",
                title: "Traffic",
                renderText: item => (
                  <NumberFormat
                    displayType="text"
                    value={item}
                    thousandSeparator={true}
                    decimalScale={2}
                  />
                ),
              },
              {
                name: "royalty",
                title: "Revenue",
                renderText: item => (
                  <NumberFormat
                    displayType="text"
                    value={item}
                    thousandSeparator={true}
                    decimalScale={2}
                  />
                ),
              },
              {
                name: "advance",
                title: "Advance Composer/Author",
                renderText: item => (
                  <NumberFormat
                    displayType="text"
                    value={item}
                    thousandSeparator={true}
                    decimalScale={2}
                  />
                ),
              },
              {
                name: "balance",
                title: "Balance",
                renderText: item => (
                  <NumberFormat
                    displayType="text"
                    value={item}
                    thousandSeparator={true}
                    decimalScale={2}
                  />
                ),
              },
            ]}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleView={handleView}
            idColumnName={"id"}
            isLoading={newLoading}
            isHaveAction={true}
            isUsingCheckbox={false}
            items={tableDataComposer}
            loadingColor={""}
            page={page}
            rowsPerPage={rowsPerPage}
            totalPage={pagesCount}
          />
        </div>
      </Container>
    </Page>
  );
};

export default TrendComposer;
