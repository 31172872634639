import { Button, styled } from "@mui/material";

const SecondaryButton = ({
  label,
  onClick,
  startIcon,
  endIcon,
  disabled,
  width,
  size,
}) => {
  return (
    <CustomButton
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
      customWidth={width}
      startIcon={startIcon}
      endIcon={endIcon && endIcon}
      size={size}
    >
      {label}
    </CustomButton>
  );
};

const CustomButton = styled(Button)(({ customWidth }) => ({
  textTransform: "none",
  whiteSpace: "nowrap",
  width: customWidth,
  minWidth: 80,
  border: "1px solid #111827",
  color: "#111827",
  "&.MuiButton-root.Mui-disabled": {
    border: "1px solid #D1D5DC",
    color: "#D1D5DC",
  },
  "&:hover": {
    border: "1px solid #111827",
    color: "#111827",
  },
}));

export default SecondaryButton;
