import {
  Breadcrumbs,
  Button,
  Card,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { Page, SkeletonComponent } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";

const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  mypadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
  },
  btnRoot: {
    borderRadius: "0px",
  },
  ib: {
    padding: "4px",
    color: "white",
    backgroundColor: "black",
    borderRadius: "6px",
    "&:focus": {
      color: "white",
      backgroundColor: "black",
    },
    "&:active": {
      boxShadow: "none",
      color: "white",
      backgroundColor: "black",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "black",

      boxShadow: "none",
    },
  },
  myTextField: {
    height: "44px",
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  inputFieldsUrl: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderRight: "1px solid #D1D5DC",
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",

    "& .MuiOutlinedInput-root": {
      width: "312px",
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
}));

function BillingKonfigurasi() {
  const classes = useStyles();
  const history = useHistory();
  const [valuePerc, setValuePerc] = useState("");
  const [loadingPageSkeleton, setLoadingPageSkeleton] = useState(false);

  const topMenuButton = [
    {
      id: 0,
      title: "System",
      link: "/admin/konfigurasi/sistem",
      selected: false,
    },
    {
      id: 1,
      title: "Notification Template",
      link: "/admin/konfigurasi/template-notifikasi",
      selected: false,
    },
    {
      id: 2,
      title: "Tax",
      link: "/admin/konfigurasi/pajak",
      selected: false,
    },
    {
      id: 3,
      title: "Role",
      link: "/admin/konfigurasi/role-user",
      selected: false,
    },
    {
      id: 4,
      title: "Activity",
      link: "/admin/konfigurasi/aktifitas-admin",
      selected: false,
    },
    {
      id: 5,
      title: "Billing Configuration",
      link: "/admin/konfigurasi/billing-configuration",
      selected: true,
    },
    {
      id: 6,
      title: "DSP Type Revenue",
      link: "/admin/konfigurasi/dsp-type-revenue",
      selected: false,
    },
    {
      id: 7,
      title: "Announcement Management",
      link: "/admin/konfigurasi/announcement-management",
      selected: false,
    },
  ];
  useEffect(() => {
    const getCof = async () => {
      setLoadingPageSkeleton(true);
      try {
        let theUrl = hardBaseUrl + "/config/billing";

        let theToken = localStorage.getItem("token");
        let resultGet = await axios.get(theUrl, {
          headers: {
            Authorization: `Bearer ${theToken}`,
          },
        });
        setLoadingPageSkeleton(false);
        setValuePerc(resultGet.data.data.value_percentage);
      } catch (err) {
        setLoadingPageSkeleton(false);
        new Error(err);
      }
    };
    getCof();
  }, []);
  const handleOnSelectedButton = value => {
    history.push(value.data[value.newButtonSelected].link);
  };

  const handleChangeSearch = e => {
    setValuePerc(e.target.value);
  };
  const onSave = async () => {
    try {
      let theUrl = hardBaseUrl + "/config/billing";
      let payload = {
        value_percentage: Number(valuePerc),
      };

      let theToken = localStorage.getItem("token");
      let resultUpdate = await axios.post(theUrl, payload, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      });
      if (
        resultUpdate.data.message === "success" &&
        resultUpdate.data.meta.http_status === 200
      ) {
        Swal.fire({
          icon: "success",
          title: "Sukses",
          text: "Change Data is successful.",
        });
      }
    } catch (err) {
      new Error(err);
    }
  };
  return (
    <Page className={classes.root} title="Billing Configuration">
      {loadingPageSkeleton ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={fontInter}>
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: 700,
                    lineHeight: "32px",
                  }}
                >
                  Administration
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <ThemeProvider theme={fontInter}>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      color: "#111827",
                    }}
                  >
                    Administration
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={fontInter}>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      color: "#687083",
                    }}
                  >
                    Billing
                  </Typography>
                </ThemeProvider>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <ButtonGroupTop
            items={topMenuButton}
            selectedButton={handleOnSelectedButton}
            selectedIndex={2}
          />

          <Divider className={classes.divider} />

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ThemeProvider theme={theme}>
                <Typography variant="h5" className={classes.title}>
                  Billing
                </Typography>
              </ThemeProvider>
              <div
                style={{
                  marginTop: "6px",
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      color: "#687083",
                      fontSize: "14px",
                    }}
                  >
                    This feature is used to set the percentage of billing.
                  </Typography>
                </ThemeProvider>
              </div>

              <Card
                style={{
                  marginTop: "16px",
                  border: "1px solid #9AA2B1",
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <div
                      style={{
                        padding: "16px",
                      }}
                    >
                      <div>Billing Percentage</div>
                      <div
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <FormControl
                          className={classes.margin}
                          variant="outlined"
                        >
                          <TextField
                            id="input-with-icon-textfield"
                            variant="outlined"
                            value={valuePerc}
                            className={classes.inputFields}
                            onChange={handleChangeSearch}
                            placeholder="Billing Percentage"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </div>
                      <div>
                        <Button
                          onClick={() => onSave()}
                          variant="outlined"
                          style={{
                            marginTop: "16px",
                            backgroundColor: "black",
                            color: "white",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Typography
                              style={{
                                textTransform: "none",
                                fontWeight: "500",
                                fontSize: "14px",
                                lineHeight: "20px",
                              }}
                            >
                              Save
                            </Typography>
                          </ThemeProvider>
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
}

export default BillingKonfigurasi;
